import { UseMutationOptions } from 'react-query'
import { smartVidConroller } from 'api/controllers'
import {
    SMART_VIDS_QUERY,
    VIDEO_BRANCH_QUERY,
    SMART_VIDS_STEPS_QUERY,
    VIDEOS_QUERY,
    UPDATE_SMART_VID_GLOBAL_SETTINGS_QUERY,
    GLOBAL_SETTINGS_QUERY,
    SMART_VID_DETAILS_QUERY,
} from 'api/constants'
import { queryClient } from 'api/queries'
import { Id } from 'types'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import {
    UpdateSmartVidRequest,
    UpdateSmartVidResponse,
} from 'api/contracts/smartVids/endpoints/updateSmartVid.contract'

export const useUpdateSmartVidGlobalSettingsMutation = (
    id: Id,
    options?: UseMutationOptions<UpdateSmartVidRequest, unknown, UpdateSmartVidResponse>,
) => {
    const key = UPDATE_SMART_VID_GLOBAL_SETTINGS_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<UpdateSmartVidRequest, UpdateSmartVidResponse>(
        key,
        async (payload: UpdateSmartVidRequest) =>
            mutate(smartVidConroller.smartVid.update(id, payload), apiLocale.smartVids.globalSetting.update),
        {
            ...options,
            invalidate: [
                VIDEO_BRANCH_QUERY,
                SMART_VIDS_QUERY,
                SMART_VIDS_STEPS_QUERY,
                VIDEOS_QUERY,
                SMART_VID_DETAILS_QUERY,
            ],
            onSuccess: (response, variables, context) => {
                if (options?.onSuccess) {
                    options.onSuccess(response, variables, context)
                }

                queryClient.setQueryData([GLOBAL_SETTINGS_QUERY, id], response)
            },
        },
    )
}
