import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button, Stack, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { openSubscriptionModal } from 'design/templates/Modal/modalTemplates/SubscriptionModal'
import { Feature } from 'design/templates/Modal/modalTemplates/SubscriptionModal/Feature'

import style from './Features.style'

interface FeaturesProps {
    planName?: string
    features?: string[]
    containerSx?: SxProps
    handleClose?: () => void
}

const Features = ({ planName = 'Plan', features, containerSx, handleClose }: FeaturesProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleSeeAllPlans = () => {
        dispatch(openSubscriptionModal())
        handleClose?.()
    }

    return (
        <Stack gap={4} sx={containerSx}>
            <Typography variant="subtitle1" fontWeight={600}>
                {t(`Best ${planName} Features:`)}
            </Typography>

            <Stack gap={2}>
                {features?.map((feature) => (
                    <Feature key={feature} featureTitle={feature} typographyVariant="subtitle2" />
                ))}
            </Stack>

            <Button
                sx={style.button}
                variant="text"
                color="tertiary"
                size="small"
                endIcon={<ChevronRightIcon />}
                onClick={handleSeeAllPlans}
            >
                {t('See All Plans')}
            </Button>
        </Stack>
    )
}

export default Features
