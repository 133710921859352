import { Box, Button, Radio, Stack, Typography } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { useTranslation } from 'react-i18next'
import { pxToRem } from 'styles/theme/typography'
import { useCustomerSubscriptionQuery, usePaymentProfileQuery } from 'api/queries'
import { CreditCardLogo } from 'design/molecules/CreditCardLogo/CreditCardLogo'
import { useMemo } from 'react'
import { useActivatePaymentProfileMutation } from 'api/mutations'
import style from './CustomerCards.style'

type CustomerCardsProps = {
    openCardForm: boolean
    onAddCard: () => void
}

const CustomerCards = ({ openCardForm, onAddCard }: CustomerCardsProps) => {
    const { t } = useTranslation()
    const { data: subscription } = useCustomerSubscriptionQuery()
    const { data: paymentProfile } = usePaymentProfileQuery()
    const { mutate: activatePaymentProfile } = useActivatePaymentProfileMutation()

    const { cards, active } = useMemo(() => {
        const cards = paymentProfile?.cards
        const active = paymentProfile?.cards.find((card) => card.isActive)?.id

        return { cards, active }
    }, [paymentProfile])

    if (openCardForm || !subscription) {
        return null
    }

    return (
        <Stack gap={3} maxHeight={415} overflow="auto">
            <Stack gap={2}>
                {cards?.map((card) => (
                    <Stack
                        key={card.id}
                        sx={[style.card, active === card.id && style.activeCard]}
                        onClick={() => activatePaymentProfile(card.id)}
                    >
                        <Stack direction="row" gap={4}>
                            <CreditCardLogo
                                type={card.type}
                                propsStack={{
                                    sx: {
                                        width: pxToRem(58),
                                        height: pxToRem(40),
                                    },
                                }}
                                fallbackProps={{
                                    sx: { fontSize: pxToRem(58), color: (theme) => theme.palette.action.active },
                                }}
                            />

                            <Stack>
                                <Typography variant="subtitle3" fontWeight={500}>
                                    {(card.type ?? '').charAt(0).toUpperCase() + (card.type ?? '').slice(1)} ending in{' '}
                                    {card.cardNumber.slice(-4)}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {t('Expiry')} {card.expirationMonth}/{card.expirationYear}
                                </Typography>

                                <Button variant="text" color="primary" sx={style.editButton}>
                                    {t('Edit')}
                                </Button>
                            </Stack>
                        </Stack>

                        <Box>
                            <Radio sx={style.radio} checked={active === card.id} size="large" />
                        </Box>
                    </Stack>
                ))}
            </Stack>
            <Button
                variant="text"
                color="tertiary"
                startIcon={<AddRoundedIcon />}
                sx={style.addCardButton}
                onClick={onAddCard}
            >
                {t('Add New Payment Method')}
            </Button>
        </Stack>
    )
}

export default CustomerCards
