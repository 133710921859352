import distanceInWords from 'date-fns/formatDistance'

export const getDateAgoInWords = (date: string) => {
    if (!date) return null
    const d = new Date(date)
    const localTime = d.setHours(d.getHours() + d.getTimezoneOffset() / -60)
    return distanceInWords(localTime, new Date(), { addSuffix: true })
}

export const getDateAgoWithRightTimezone = (date: string) =>
    date ? distanceInWords(new Date(date), new Date(), { addSuffix: true }) : null

export const getPricePoint = (pricepoint: string) => {
    switch (pricepoint) {
        case 'onemonthtrial':
            return '1 month'
        default:
            return null
    }
}

export const getBillingPeriod = (billingPeriod?: string, short?: boolean) => {
    switch (billingPeriod) {
        case '1 month':
            return short ? 'mo' : 'month'
        case '12 months':
            return short ? 'y' : 'year'
        case '12 month':
            return short ? 'y' : 'year'
        default:
            return billingPeriod
    }
}

export const secondsToTime = (seconds: number, hasHours?: boolean): string => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    const formattedSeconds = remainingSeconds.toString().padStart(2, '0')
    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')

    if (hasHours || hours > 0) {
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    }

    return `${formattedMinutes}:${formattedSeconds}`
}

export const timeToSeconds = (timeString: string) => {
    const timeParts = timeString.split(':').map(Number)

    if (timeParts.length === 3) {
        const [hours, minutes, seconds] = timeParts
        return hours * 3600 + minutes * 60 + seconds
    }

    if (timeParts.length === 2) {
        const [minutes, seconds] = timeParts
        return minutes * 60 + seconds
    }

    return 0
}

export const normalizeTimeFormat = (time: string): string => {
    const parts = time.split(':').map((part) => part.padStart(2, '0'))

    return parts?.join(':') || ''
}

export const formatDateToUs = (dateStr: string) => {
    if (!dateStr) return ''
    const date = new Date(dateStr)

    const formatter = new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    })

    return formatter.format(date)
}
