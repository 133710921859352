export default {
    title: 'Flow Navigation',
    controls: {
        goBack: {
            label: 'Go Back Button',
            placeholder: 'Type here...',
        },
        startOver: {
            label: 'Replay Button',
            placeholder: 'Type here...',
        },
    },
}
