import { useQuery, UseQueryOptions } from 'react-query'

import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_IS_CLIENT } from 'api/constants'
import { IsAgencyClientResponse } from 'api/contracts/agency/endpoints/isAgencyClient.contract'
import { useApiErrorToast } from 'api/hooks'
import { ApiError } from 'api/contracts/utilitary/error'

export const useIsAgencyClientQuery = (
    options?: Omit<
        UseQueryOptions<IsAgencyClientResponse, ApiError, IsAgencyClientResponse>,
        'queryKey' | 'queryFn' | 'onError'
    >,
) => {
    const { showApiErrorToast } = useApiErrorToast()
    const isAgencyClientQuery = useQuery<IsAgencyClientResponse, ApiError>(
        AGENCY_IS_CLIENT,
        () => agencyController.client.hasAgency(),
        {
            onError: (e) => showApiErrorToast(e),
            ...options,
        },
    )

    return { hasAgency: isAgencyClientQuery.data?.belongsToAgency, isAgencyClientQuery }
}
