import {
    THEME_SECTION_INITIAL_VALUES,
    CONTROLS_SECTION_INITIAL_VALUES,
    PLAYBACK_SECTION_INITIAL_VALUES,
    SMART_PAUSE_INITIAL_VALUES,
    RESUME_PLAY_INITIAL_VALUES,
    CALLS_TO_ACTION_SECTION_INITIAL_VALUES,
    PLAY_GATES_SECTION_INITIAL_VALUES,
    TAGS_SECTION_INITIAL_VALUES,
    THUMBNAILS_SECTION_INITIAL_VALUES,
    CLOSED_CAPTIONS_SECTION_INITIAL_VALUES,
    EXPIRE_VIDEO_SECTION_INITIAL_VALUES,
    END_VIDEO_ACTION_INITIAL_VALUES,
    VIDEO_STARTUP_QUALITY_INITIAL_VALUES,
    PLAY_OPTIONS_INITIAL_VALUES,
    FOCUSED_FULLSCREEN_SECTION_INITIAL_VALUES,
} from 'design/pages/VidSettings/constants'

export interface ChaptersDetails {
    time: number
    title: string
}

export type ThemeSectionFormData = typeof THEME_SECTION_INITIAL_VALUES
export type ControlsSectionFormData = Omit<typeof CONTROLS_SECTION_INITIAL_VALUES, 'ui_controlBar_settings'>
export type FocusedFullscreenSectionFormData = typeof FOCUSED_FULLSCREEN_SECTION_INITIAL_VALUES
export type ThumbnailsSectionFormData = typeof THUMBNAILS_SECTION_INITIAL_VALUES
export type ChaptersSectionFormData = ChaptersDetails
export type ClosedCaptionsSectionFormData = typeof CLOSED_CAPTIONS_SECTION_INITIAL_VALUES

export type PlaybackSectionFormData = typeof PLAYBACK_SECTION_INITIAL_VALUES
export type ResumePlayFormData = typeof RESUME_PLAY_INITIAL_VALUES
export type SmartPauseFormData = typeof SMART_PAUSE_INITIAL_VALUES
export type EndOfVideoActionFormData = typeof END_VIDEO_ACTION_INITIAL_VALUES
export type VideoStartupQualityFormData = typeof VIDEO_STARTUP_QUALITY_INITIAL_VALUES
export type ExpireVideoSectionFormData = typeof EXPIRE_VIDEO_SECTION_INITIAL_VALUES

export type PlayOptionsFormData = typeof PLAY_OPTIONS_INITIAL_VALUES

export type CallsToActionsSectionFormData = typeof CALLS_TO_ACTION_SECTION_INITIAL_VALUES
export type PlayGatesSectionFormData = typeof PLAY_GATES_SECTION_INITIAL_VALUES
export type TagsSectionFormData = typeof TAGS_SECTION_INITIAL_VALUES

export enum ThumbnailSource {
    image = 'image',
    video = 'video',
}

export enum MobileThumbnailInputType {
    ui_thumbnail_mobile_from = 'ui_thumbnail_mobile_from',
    ui_thumbnail_mobile_to = 'ui_thumbnail_mobile_to',
}

export enum DefaultThumbnailInputType {
    ui_thumbnail_default_from = 'ui_thumbnail_default_from',
    ui_thumbnail_default_to = 'ui_thumbnail_default_to',
}

export type ThumbnailInputType = MobileThumbnailInputType | DefaultThumbnailInputType

export type ThumbnailTimeValues = {
    [key in ThumbnailInputType]: number
}
