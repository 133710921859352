import _isBoolean from 'lodash/isBoolean'
import { CustomerSubscriptionPlanTier } from 'types/Customer'
import featuresAccess, { FeatureKey } from 'constants/featuresAccess.constants'
import { FeatureGroup } from 'constants/keys/featuresAccess.constants'

export const getIsLockedByTierAndGroup =
    (tier?: CustomerSubscriptionPlanTier) => (group: FeatureGroup) => (feature?: FeatureKey) => {
        // !!! We consider agency as free tier !!!
        const tierToUse = tier === CustomerSubscriptionPlanTier.agency ? CustomerSubscriptionPlanTier.free : tier

        // Return true if tier is not configured in featuresAccess for such group
        if (!tierToUse || !(tierToUse in featuresAccess[group])) {
            return false
        }

        const sectionAccessByTier = featuresAccess[group][tierToUse]

        // Return true if such group and tier is not configured featuresAccess
        if (!sectionAccessByTier) {
            return false
        }

        // Handles cases where a tier has no restrictions for a specific group
        // Only one property (lockedByDefault) is configured, which applies to the entire group
        // This allows for simplified configuration when all features in a group share the same access status
        if (_isBoolean(sectionAccessByTier.lockedByDefault)) {
            return sectionAccessByTier.lockedByDefault
        }

        if (sectionAccessByTier.locked) {
            if (!feature) return false

            return sectionAccessByTier.locked.includes(feature)
        }

        if (sectionAccessByTier.unlocked) {
            if (!feature) return true

            return !sectionAccessByTier.unlocked.includes(feature)
        }

        return false
    }
