import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { UI_COLOR_BACKGROUND, UI_COLOR_FORCE, UI_COLOR_FOREGROUND } from 'design/pages/VidSettings/constants'
import { ColorPicker } from 'design/atoms/Form'
import { ThemeSectionFormData } from 'types/VidSettings'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const PlayerThemeColors = ({ isSmartVidGlobalSettings }: Props) => {
    const { t } = useTranslation()
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )
    const { isLoading, onChange } = useSectionContext<ThemeSectionFormData>()
    const commonProps = {
        afterChange: onChange,
        disabled: isLoading,
        switchProps: { size: 'small' },
    } as const

    return (
        <Section title={t('Default colors')} indent>
            <ColorPicker name={UI_COLOR_FOREGROUND} label={t('Icons and Text')} {...commonProps} prefixed />
            <ColorPicker name={UI_COLOR_BACKGROUND} label={t('Background')} {...commonProps} prefixed />
            <Box mt={2}>
                <SwitchForm
                    sx={style.container}
                    name={UI_COLOR_FORCE}
                    label={<Typography variant="body2">{t('Apply on All Settings')}</Typography>}
                    helpInfo={t(
                        'Turning this option on will use the colors above for ALL the settings of your video, like the Resume Play and Redirect Message.',
                    )}
                    labelPlacement="start"
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    {...commonProps}
                />
            </Box>
        </Section>
    )
}

export default PlayerThemeColors
