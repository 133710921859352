import { useAppSelector } from 'App'
import {
    AgencyLockedFeaturesModal,
    CancelAccountModal,
    ConversionConfirmDeleteModal,
    CreateClientModal,
    EditClientModal,
    RemoveClientModal,
    ResendCancellationEmailModal,
    ResendInvitationModal,
    RevokeAgencyAccessModal,
    RevokeClientCancellationModal,
    selectModalControllerState,
    WithdrawClientInvitationModal,
} from 'design/templates/Modal'
import { MyVidsConfirmDeleteModal } from 'design/templates/Modal/modalTemplates/MyVids/MyVidsConfirmDeleteModal'
import { MyVidsRenameFolderOrVideo } from 'design/templates/Modal/modalTemplates/MyVids/MyVidsRenameFolderOrVideo/MyVidsRenameFoldreOrVideo'

export const ModalController = () => {
    const { type } = useAppSelector(selectModalControllerState)

    switch (type) {
        case 'Cancel account':
            return <CancelAccountModal />
        case 'Agency locked features':
            return <AgencyLockedFeaturesModal />
        case 'Create agency client modal':
            return <CreateClientModal />
        case 'Resend cancellation email':
            return <ResendCancellationEmailModal />
        case 'Remove client':
            return <RemoveClientModal />
        case 'Resend invitation':
            return <ResendInvitationModal />
        case 'Revoke agency access':
            return <RevokeAgencyAccessModal />
        case 'Withdraw client invitation':
            return <WithdrawClientInvitationModal />
        case 'Revoke client cancellation':
            return <RevokeClientCancellationModal />
        case 'Edit user':
            return <EditClientModal />
        case 'Confirm delete conversion':
            return <ConversionConfirmDeleteModal />
        case 'Confirm delete myVids':
            return <MyVidsConfirmDeleteModal />
        case 'RenameFolderOrVideo':
            return <MyVidsRenameFolderOrVideo />
    }

    return null
}
