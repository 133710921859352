import { Cookies } from 'react-cookie'

import getCookiesAsArray from 'utils/cookie/getCookiesAsArray'
import { parseJwt } from 'utils/helpers/helpers'
import { ENVIRONMENT, PRODUCTION } from 'constants/environment.constants'

export default function chooseAuthCookie(key: string) {
    const all = getCookiesAsArray()
    const tokens = all?.filter((cookie) => cookie.key === key)?.map((cookie) => cookie.value)

    if (tokens?.length < 2) {
        return new Cookies().get(key)
    }

    const tokensWithIss = tokens?.map((value) => {
        const parsed = parseJwt(value)
        return {
            value,
            iss: parsed?.iss,
            dt: parsed?.iat,
        }
    })

    let filtered = tokensWithIss?.filter((token) => !token.iss?.includes('dev')) || null
    if (ENVIRONMENT !== PRODUCTION) {
        filtered = tokensWithIss?.filter((token) => token.iss?.includes('dev')) || null
    }

    const reduced = filtered.reduce((latest, item) => (new Date(item.dt) > new Date(latest.dt) ? item : latest))

    return reduced?.value || null
}
