import { MouseEvent, useMemo, useState } from 'react'
import { Column, defaultOrderByFn, Row, TableInstance } from 'react-table'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useToast } from 'design/organisms/ToastProvider'

import Actions from 'design/atoms/Table/Toolbar/Actions'
import { ExpandedVideoOrFolder, ExpandedVideosWithFolders } from 'design/pages/MyVids/hooks/useVideosWithFolders'
import { FOLDER, HEADLINE, SEARCH } from 'design/atoms/Table/constants'
import { NEW_FOLDER_LABEL, PLAYS, TITLE } from 'design/pages/MyVids'
import useUpgradeCallback from 'hooks/user/useUpgradeCallback'
import { DropHandler } from 'design/atoms/Table/Row'
import { Modal, MoveFolderModal } from 'design/templates/Modal'
import Table, { OrderByFn } from 'design/atoms/Table'
import { Folder } from 'types/Video'
import {
    useAddFolderMutation,
    useDeleteFoldersMutation,
    useDeleteVideosMutation,
    useDuplicateVideoMutation,
    useFolderNameChangeMutation,
    useMoveFolderToFolderMutation,
    useMoveToFolderMutation,
    usePublishVideoMutation,
} from 'api/mutations'
import { VIDEO_FOLDERS_QUERY, VIDEOS_QUERY } from 'api/constants'
import { Id } from 'types'
import { Button, Typography } from '@mui/material'
import { SORTING_KEY } from 'api/constants/sorting.constants'
import { route } from 'constants/routes'
import { COLUMN } from 'constants/table.constants'
import { ROOT } from 'constants/api.constants'
import { CustomContent } from './CustomContent/CustomContent'
import style from './VidsTable.style'

type MyVidsProps = {
    data?: ExpandedVideosWithFolders
    isLoading: boolean
}

type RecordsWithIds = {
    records: ExpandedVideoOrFolder[]
    ids: Id[]
}

enum ModalActions {
    DeleteActionModal = 'Delete',
    MoveToFolder = 'MoveToFolder',
}

type GetVideosAndFoldersResult = {
    videos: RecordsWithIds
    folders: RecordsWithIds
    processedVideos: RecordsWithIds
    modalAction: ModalActions | null
}

const initialRecordsWithIds = { records: [], ids: [] }
const initialStructuralRecords: GetVideosAndFoldersResult = {
    videos: initialRecordsWithIds,
    folders: initialRecordsWithIds,
    processedVideos: initialRecordsWithIds,
    modalAction: null,
}

const FILTER_HIDDEN_ROWS = 'FILTER_HIDDEN_ROWS'

export const getFolderTitle = (data?: { title: string }[]) => {
    let title = ''
    let count = 0

    const foldersWithCommonNaming = data?.filter((folder) => folder.title.startsWith(NEW_FOLDER_LABEL))

    while (!title) {
        const ending = count > 0 ? ` ${count}` : ''
        const titleCandidate = `${NEW_FOLDER_LABEL}${ending}`
        const doesTitleExist = foldersWithCommonNaming?.find((folder) => folder.title === titleCandidate)

        if (doesTitleExist) {
            count++
        } else {
            title = titleCandidate
        }
    }

    return title
}

export const VidsTable = ({ data, isLoading }: MyVidsProps) => {
    const [selectedRecords, setSelectedRecords] = useState(initialStructuralRecords)
    const moveToFolderMutation = useMoveToFolderMutation()
    const moveFolderToFolderMutation = useMoveFolderToFolderMutation()
    const publishVideoMutation = usePublishVideoMutation()
    const duplicateVideoMutation = useDuplicateVideoMutation()
    const deleteVideosMutation = useDeleteVideosMutation()
    const deleteFoldersMutation = useDeleteFoldersMutation()
    const addFolderMutation = useAddFolderMutation()
    const folderNameChangeMutation = useFolderNameChangeMutation()
    const queryClient = useQueryClient()
    const upgradeCallback = useUpgradeCallback()
    const navigate = useNavigate()
    const { guid } = useParams()
    const { showToast } = useToast()

    const resetModalData = () => () => {
        setSelectedRecords(initialStructuralRecords)
    }

    const getVideosAndFolders = (
        records: ExpandedVideoOrFolder[],
        result: GetVideosAndFoldersResult = initialStructuralRecords,
        takeOnlyRoot = false,
    ): GetVideosAndFoldersResult =>
        records.reduce((acc, record) => {
            if (record.type === FOLDER) {
                const newAcc = {
                    ...acc,
                    folders: {
                        records: [...acc.folders.records, record],
                        ids: [...acc.folders.ids, record.guid],
                    },
                }

                if (takeOnlyRoot || !record.children.length) {
                    return newAcc
                }

                return { ...getVideosAndFolders(record.children, newAcc, takeOnlyRoot) }
            }

            const shouldBeExcluded = 'isReady' in record && !record.isReady

            return {
                ...acc,
                processedVideos: {
                    records: shouldBeExcluded ? acc.processedVideos.records : [...acc.processedVideos.records, record],
                    ids: shouldBeExcluded ? acc.processedVideos.ids : [...acc.processedVideos.ids, record.guid],
                },
                videos: {
                    records: [...acc.videos.records, record],
                    ids: [...acc.videos.ids, record.guid],
                },
            }
        }, result)

    const handlePublish = (selected: Row<ExpandedVideoOrFolder>[]) => {
        upgradeCallback(() =>
            publishVideoMutation.mutate(getVideosAndFolders(selected.map((row) => row.original)).videos.ids),
        )({} as MouseEvent)
    }

    const handleDelete = async () => {
        const records = selectedRecords

        setSelectedRecords(initialStructuralRecords)

        if (records.videos.ids.length) {
            await deleteVideosMutation.mutateAsync(records.videos.ids)
        }
        if (records.folders.ids.length) {
            deleteFoldersMutation.mutate(records.folders.ids)
        }
    }

    const handleDrop = async (records: GetVideosAndFoldersResult, targetId: string) => {
        if (records.folders.ids.length) {
            await moveFolderToFolderMutation.mutateAsync(records.folders.ids.map((id) => ({ itemId: id, targetId })))
        }
        if (records.videos.ids.length) {
            await moveToFolderMutation.mutateAsync(records.videos.ids.map((id) => ({ itemId: id, targetId })))
        }

        Promise.all([VIDEOS_QUERY, VIDEO_FOLDERS_QUERY].map((k) => queryClient.invalidateQueries(k)))
    }

    const onTableDrop: DropHandler = (target, items) => {
        const records = getVideosAndFolders(
            items.map((row) => row.original),
            initialStructuralRecords,
            true,
        )

        handleDrop(records, target.original.guid)
    }

    const onModalDrop = async (target: string) => {
        setSelectedRecords(initialStructuralRecords)
        await handleDrop(selectedRecords, target)
    }

    const setSelectedRecordsModal = (
        selected: Row<ExpandedVideoOrFolder>[],
        action: ModalActions,
        takeOnlyRoot?: boolean,
    ) => {
        const stucturedSelectedItemsWithChildrens = getVideosAndFolders(
            selected.map((row) => row.original),
            initialStructuralRecords,
            takeOnlyRoot,
        )

        setSelectedRecords({ ...stucturedSelectedItemsWithChildrens, modalAction: action })
    }

    const handleDuplicate = (selected: Row<ExpandedVideoOrFolder>[]) => {
        const vidsWithFolders = getVideosAndFolders(selected.map((s) => s.original))

        if (selected.some((row) => 'isReady' in row.original && !row.original.isReady)) {
            const notReadyVideos = selected
                .filter((row) => !('isReady' in row.original && row.original.isReady))
                .map((row) => row.original.title)

            showToast({
                message:
                    notReadyVideos.length > 1
                        ? `Videos: ${notReadyVideos.join(', ')} are in processing`
                        : `Video: ${notReadyVideos} is in processing`,
                type: 'warning',
            })
        }

        if (vidsWithFolders.processedVideos.ids.length) {
            duplicateVideoMutation.mutate(vidsWithFolders.processedVideos.ids)
        }
    }

    const actionsMenuOptions = (selected: Row<ExpandedVideoOrFolder>[]) => {
        const vidsWithFolders = getVideosAndFolders(selected.map((s) => s.original))
        return [
            {
                label: `Publish ${vidsWithFolders.processedVideos.ids.length}`,
                onClick: handlePublish,
                disabled:
                    !vidsWithFolders.videos.ids.length ||
                    vidsWithFolders.videos.records.some((v) => 'isReady' in v && !v.isReady),
            },
            {
                label: `Delete ${selected.length}`,
                onClick: (selected: Row<ExpandedVideoOrFolder>[]) => {
                    setSelectedRecordsModal(selected, ModalActions.DeleteActionModal)
                },
                disabled: !selected.length,
            },
            {
                label: `Duplicate ${vidsWithFolders.processedVideos.ids.length}`,
                onClick: handleDuplicate,
                disabled: Boolean(vidsWithFolders.folders.ids.length || !vidsWithFolders.processedVideos.ids.length),
            },
            {
                label: 'Move to folder...',
                onClick: (selected: Row<ExpandedVideoOrFolder>[]) => {
                    setSelectedRecordsModal(selected, ModalActions.MoveToFolder, true)
                },
                disabled: !vidsWithFolders.folders.ids.length && !vidsWithFolders.videos.ids.length,
            },
        ]
    }

    const handleAddFolder = () =>
        addFolderMutation.mutate({ folder: { parentFolderGuid: guid || ROOT, title: getFolderTitle(data) } })

    const columns = useMemo<Column<ExpandedVideoOrFolder>[]>(
        () => [
            {
                Header: (props: TableInstance<ExpandedVideoOrFolder>) => (
                    <Actions<ExpandedVideoOrFolder>
                        {...props}
                        actionsMenuOptions={actionsMenuOptions}
                        searchByIds={[SEARCH]}
                        searchByLabel="Search by Name or Embed Code ID"
                        onAdd={handleAddFolder}
                    />
                ),
                accessor: TITLE,
                id: HEADLINE,
                width: '100%',
                CustomCell: CustomContent,
                onFolderClick: (id: string) => {
                    navigate(route.video.byId({ guid: id }))
                },
                onFolderNameChange: (folder: Folder) => {
                    folderNameChangeMutation.mutate({ folder })
                },
                colSpans: {
                    header: 2,
                },
            },
            {
                accessor: COLUMN.DATE_CREATED,
                Header: '',
            },
            {
                accessor: COLUMN.DATE_UPDATED,
                Header: '',
            },
            {
                /* eslint-disable-next-line */
                accessor: FILTER_HIDDEN_ROWS as any,
                Header: '',
                /* eslint-disable-next-line */
                filter: (rows: Row<ExpandedVideoOrFolder>[], _, filterValue: any) =>
                    rows.filter((row) => {
                        const node = row.original

                        if (filterValue?.searchValue || !row.values?.[HEADLINE]) {
                            return rows
                        }

                        const parentId = 'parentFolderGuid' in node ? node.parentFolderGuid : node.folderGuid

                        return parentId === (filterValue?.guid || 'root')
                    }),
            },
            {
                accessor: SEARCH,
                Header: '',
            },
            {
                /* eslint-disable-next-line */
                accessor: PLAYS as any,
                Header: '',
            },
        ],
        [handleAddFolder],
    )

    const initialState = useMemo(
        () => ({
            hiddenColumns: [COLUMN.DATE_CREATED, COLUMN.DATE_UPDATED, FILTER_HIDDEN_ROWS, SEARCH, PLAYS],
            sortBy: [{ id: COLUMN.DATE_CREATED, desc: true }],
            filters: [{ id: FILTER_HIDDEN_ROWS, value: { guid } }],
        }),
        [guid],
    )

    const sortByConfiguration = {
        operations: [
            { label: 'Name', id: HEADLINE },
            { label: 'Plays', id: PLAYS },
            { label: 'Created', id: COLUMN.DATE_CREATED },
            { label: 'Updated', id: COLUMN.DATE_UPDATED },
        ],
    }

    const orderByFn: OrderByFn<ExpandedVideoOrFolder> = (arr, funcs, dirs) => {
        const splitedVideosWithFolders = arr.reduce(
            (acc, item) => {
                if (item.original.type === FOLDER) {
                    acc.folders.push(item)
                } else {
                    acc.videos.push(item)
                }
                return acc
            },
            { videos: [] as Row<ExpandedVideoOrFolder>[], folders: [] as Row<ExpandedVideoOrFolder>[] },
        )

        return [...splitedVideosWithFolders.folders, ...defaultOrderByFn(splitedVideosWithFolders.videos, funcs, dirs)]
    }

    const title = useMemo(() => {
        return selectedRecords.folders.ids.length
            ? 'Do you really want to delete this folder and all its videos?'
            : `Do you really want to delete ${selectedRecords.videos.ids.length > 1 ? 'these videos' : 'this video'}?`
    }, [selectedRecords])

    return (
        <>
            <Table<ExpandedVideoOrFolder>
                columns={columns}
                data={data}
                loading={isLoading}
                initialState={initialState}
                sortBy={sortByConfiguration}
                sortByInside
                noDataText="No Vids"
                noFilteredDataText="No video or folder"
                orderByFn={orderByFn}
                // @ts-ignore
                onDrop={onTableDrop}
                sortingKey={SORTING_KEY.VIDEO}
                useVirtualization
            />
            <MoveFolderModal
                open={selectedRecords.modalAction === ModalActions.MoveToFolder}
                onClose={resetModalData()}
                onDrop={onModalDrop}
            />

            <Modal
                open={
                    selectedRecords.modalAction === ModalActions.DeleteActionModal &&
                    Boolean(selectedRecords.videos.ids.length || selectedRecords.folders.ids.length)
                }
                onClose={resetModalData()}
            >
                <Modal.Header title={title} onClose={resetModalData()} />
                <Modal.Body>
                    <Typography>
                        {selectedRecords.folders.ids.length
                            ? 'All vids inside of it will be permanently deleted'
                            : 'All data will be lost. This action is permanent and cannot be undone.'}
                    </Typography>
                </Modal.Body>
                <Modal.Actions sx={style.modal.buttons}>
                    <Button
                        variant="outlined"
                        onClick={resetModalData()}
                        data-testid="cancelDeleteRecordsHandler"
                        color="tertiary"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        data-testid="confirmDeleteRecordsHandler"
                    >
                        Delete
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}
