import { Typography, Stack } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { useFormikContext } from 'formik'

import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import { GO_BACK, START_OVER, SHOW, TEXT } from 'design/pages/VidSettings/constants'
import { ControlsSectionFormData } from 'types/VidSettings'

import { SwitchForm } from 'design/atoms/Switch'
import { Input } from 'design/atoms/Form'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import locale from './FlowNavigation.locale'

export const FlowNavigation = () => {
    const {
        formik: { values, errors },
        isLoading,
        onChange,
    } = useGlobalSettingsFormContext<ControlsSectionFormData>()
    const { validateForm, submitForm } = useFormikContext()

    const handleSubmit = async () => {
        await validateForm()
        await submitForm()

        if (!Object.keys(errors).length) {
            onChange()
        }
    }

    const commonProps = {
        afterChange: handleSubmit,
        labelTypography: { variant: 'body2' as Variant },
        switchProps: { size: 'small' },
    } as const

    return (
        <Section title={locale.title} indent>
            <Stack gap={4}>
                <Stack gap={2}>
                    <SwitchForm
                        sx={style.container}
                        name={`${GO_BACK}.${SHOW}`}
                        label={<Typography variant="body2">{locale.controls.goBack.label}</Typography>}
                        disabled={isLoading || !values[GO_BACK][TEXT]}
                        {...commonProps}
                    />
                    <Input
                        name={`${GO_BACK}.${TEXT}`}
                        variant="outlined"
                        placeholder={locale.controls.goBack.placeholder}
                        disabled={isLoading || !values[GO_BACK][SHOW]}
                        onBlur={handleSubmit}
                    />
                </Stack>

                <Stack gap={2}>
                    <SwitchForm
                        sx={style.container}
                        name={`${START_OVER}.${SHOW}`}
                        label={<Typography variant="body2">{locale.controls.startOver.label}</Typography>}
                        disabled={isLoading || !values[START_OVER][TEXT]}
                        {...commonProps}
                    />
                    <Input
                        name={`${START_OVER}.${TEXT}`}
                        variant="outlined"
                        placeholder={locale.controls.startOver.placeholder}
                        disabled={isLoading || !values[START_OVER][SHOW]}
                        onBlur={handleSubmit}
                    />
                </Stack>
            </Stack>
        </Section>
    )
}
