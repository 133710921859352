import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useCustomerQuery } from 'api/queries'
import { Link } from 'design/atoms/Link'
import usePlanUsageInfo from 'design/organisms/Account/usePlanUsageInfo'
import {
    OnDowngrade,
    useCurrentPlan,
    useHandleOpenSubscriptionModal,
} from 'design/templates/Modal/modalTemplates/SubscriptionModal/SelectPlanStep/hooks'
import { ExtendedPlan } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SelectPlanStep/usePlans'
import useUserStatuses from 'hooks/user/useUserStatuses'
import { PlanStatus } from 'types/Auth'
import { CustomerStatus, CustomerSubscriptionPlanTier } from 'types/Customer'
import { extractNumber } from 'utils/strings'
import { useActivatePlanMutation } from 'api/mutations'
import route from 'constants/routes'
import { isEnterprisePlan } from 'constants/plan.constants'

interface ActionProps {
    plan: ExtendedPlan
    activateNow: boolean
    onDowngrade: OnDowngrade
}

export const Action = ({ plan, activateNow, onDowngrade }: ActionProps) => {
    const { t } = useTranslation()
    const { isFree, isAgency } = usePlanUsageInfo()
    const { data: customer } = useCustomerQuery()
    const { isUserInactive, isUserOnTrial } = useUserStatuses()
    const { currentPlan, currentPlanIndex } = useCurrentPlan()
    const { mutate: activatePlan } = useActivatePlanMutation(undefined, false)

    const { handleOpenSubscriptionModal } = useHandleOpenSubscriptionModal()

    // 1. Loading state if we have no current plan data
    if (!currentPlan) {
        return <LoadingButton variant="contained" color="accent" fullWidth loading />
    }

    // 2. Handle enterprise plan
    if (
        plan.tier.includes(CustomerSubscriptionPlanTier.enterprise) &&
        (currentPlan.tier.includes(CustomerSubscriptionPlanTier.enterprise) ? !isUserOnTrial : true) &&
        !Number(plan.price)
    ) {
        return (
            <Link to={route.static.letsChat} external target="_blank" rel="noopener noreferrer">
                <Button variant="contained" color="accent" fullWidth>
                    {t('Let’s Talk')}
                </Button>
            </Link>
        )
    }

    // 3. Handle agency user
    if (isAgency) {
        return (
            <Button variant="contained" color="accent" fullWidth onClick={() => handleOpenSubscriptionModal(plan)}>
                {t('Upgrade Now')}
            </Button>
        )
    }

    // 4. Reactivate plan if user is inactive (and plan not archived)
    if (
        isUserInactive &&
        currentPlan.status !== PlanStatus.archive &&
        (plan.apiHandle === currentPlan.apiHandle || currentPlan.name.toLowerCase().includes(plan.name.toLowerCase()))
    ) {
        return (
            <Button
                variant="contained"
                color="accent"
                fullWidth
                onClick={() => handleOpenSubscriptionModal(plan, { reactivate: true })}
            >
                {t('Reactivate My Plan')}
            </Button>
        )
    }

    // 5. Start trial if available
    if (plan.hasTrial && isFree && customer && customer.status !== CustomerStatus.trial) {
        return (
            <LoadingButton
                variant="contained"
                color="accent"
                fullWidth
                onClick={() => {
                    activatePlan({
                        apiHandle: plan.apiHandle,
                    })
                    handleOpenSubscriptionModal(plan, { isTrialStartedMode: true })
                }}
            >
                {t('startYourNDaysTrial', { days: extractNumber(plan.trialPeriod || '') })}
            </LoadingButton>
        )
    }

    // 6. Immediate activation
    if (activateNow) {
        return (
            <Button variant="contained" color="accent" fullWidth onClick={() => handleOpenSubscriptionModal(plan)}>
                {t('Activate Now')}
            </Button>
        )
    }

    // 7. Check if this is a “downgrade” scenario
    if (
        plan.index < currentPlanIndex ||
        (plan.tier !== currentPlan?.tier && plan.index === currentPlanIndex && isEnterprisePlan(plan))
    ) {
        return (
            <Button variant="outlined" color="tertiary" fullWidth onClick={() => onDowngrade(plan)}>
                {t('Downgrade')}
            </Button>
        )
    }

    // 8. Current plan
    if (plan.id === currentPlan.id) {
        return (
            <Button variant="text" sx={{ color: 'text.primary', pointerEvents: 'none' }} fullWidth>
                {t('Current Plan')}
            </Button>
        )
    }

    // 9. Default (upgrade)
    return (
        <Button variant="contained" color="accent" fullWidth onClick={() => handleOpenSubscriptionModal(plan)}>
            {t('Upgrade Now')}
        </Button>
    )
}
