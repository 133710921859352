import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import {
    AnalyticsMetricKeys,
    AnalyticsLineVisibilityState,
    SetAnalyticsVisibilityPayload,
} from 'design/organisms/StatsTable/types'

const initialState: AnalyticsLineVisibilityState = {
    [AnalyticsMetricKeys.dateCreated]: false,
    [AnalyticsMetricKeys.dateUpdated]: false,
    [AnalyticsMetricKeys.plays]: true,
    [AnalyticsMetricKeys.playRate]: false,
    [AnalyticsMetricKeys.impressions]: true,
    [AnalyticsMetricKeys.playsUnique]: true,
    [AnalyticsMetricKeys.unmuteRate]: true,
    [AnalyticsMetricKeys.engagement]: true,
    [AnalyticsMetricKeys.conversionCount]: true,
    [AnalyticsMetricKeys.conversionRate]: false,
    [AnalyticsMetricKeys.revenueAverage]: false,
    [AnalyticsMetricKeys.pgOptInRate]: false,
    [AnalyticsMetricKeys.revenue]: true,
    [AnalyticsMetricKeys.revenuePerViewer]: false,
    [AnalyticsMetricKeys.bounceRate]: false,
}

export const analyticsTableColumnsMenuSlice = createSlice({
    name: 'analyticsTableColumnsMenu',
    initialState,
    reducers: {
        setMetricVisibility: (state, action: PayloadAction<SetAnalyticsVisibilityPayload>) => {
            const { key, value } = action.payload

            state[key] = value
        },
        setMetricsVisibility: (_state, action: PayloadAction<AnalyticsLineVisibilityState>) => action.payload,
    },
})

export const { setMetricVisibility, setMetricsVisibility } = analyticsTableColumnsMenuSlice.actions

export const selectAnalyticsTableColumnsState = (state: RootState) => state.analyticsTableColumnsMenu

export const analyticsTableColumnsMenu = analyticsTableColumnsMenuSlice.reducer
