import { useCookies } from 'react-cookie'
import mixpanel from 'mixpanel-browser'
import { queryClient, useCustomerQuery, useCustomerSubscriptionQuery } from 'api/queries'

import { selectUploaderState, setFiles } from 'design/pages/VideoUpload/uploader.slice'
import { getUploadingLockStatus, UPLOADING_BEFORE_UNLOAD_MESSAGE } from 'hooks/system/useUploadingWarning'
import { PROM_REF, UPSELL_TOKEN_COOKIE } from 'design/organisms/SignUp/constants'
import { useAppSelector, useAppDispatch } from 'App'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import { CUSTOMER_QUERY } from 'api/constants'
import { clearAgencyAccountStatus } from 'design/organisms/Account/account.slice'
import { useClientAccount } from 'design/pages/AgencyAccount/AgencyAccount.hooks'
import { CustomerStatus } from 'types/Customer'
import {
    setFiltersValue,
    setIntervalMenuValue,
    setMetricMenuValue,
    setSelectedPrepopulate,
    setURLTypeValue,
} from 'design/pages/Analytics/analyticsActions.slice'
import { UPLOAD_TO_FOLDER_KEY } from 'design/pages/NewVideoUpload/constants'
import { resetBanner } from 'design/organisms/BannerController/banner.slice'
import { initialSelectedFilters, MetricIntervals, MetricKeys } from 'design/pages/Analytics/types'
import { ACCESS_TOKEN, REFRESH_TOKEN, COOKIES_OPTIONS, COOKIES_OPTIONS_AUTH } from 'constants/cookies.constants'

function useLogout() {
    const [, , removeCookie] = useCookies()
    const dispatch = useAppDispatch()
    const { jumpOut } = useClientAccount()
    const { files } = useAppSelector(selectUploaderState)
    const { data: customer } = useCustomerQuery()
    const { data: subscription } = useCustomerSubscriptionQuery()

    return () => {
        if (getUploadingLockStatus(files)) {
            if (!window.confirm(UPLOADING_BEFORE_UNLOAD_MESSAGE)) {
                return
            }

            files.forEach((f) => f.state?.cancelTokenSource?.cancel())
            dispatch(setFiles([]))
        }

        removeCookie(ACCESS_TOKEN, COOKIES_OPTIONS_AUTH)
        removeCookie(REFRESH_TOKEN, COOKIES_OPTIONS_AUTH)
        removeCookie(UPSELL_TOKEN_COOKIE, COOKIES_OPTIONS)
        removeCookie(PROM_REF, COOKIES_OPTIONS)
        localStorage.removeItem('lastOpenedStat')
        localStorage.removeItem('thumbnailSource')
        localStorage.removeItem('ANALYTICS_DATA_RANGE_SHORTCUTS')
        localStorage.removeItem(UPLOAD_TO_FOLDER_KEY)

        if (subscription?.plan) {
            mixpanel.track(MIXPANEL_EVENTS.SIGN_OUT, {
                user_id: customer?.guid,
                subscription_tier: subscription.plan.tier,
                subscription_id: subscription.plan.apiHandle,
                subscription_status: subscription.status,
                subscription_istrial: subscription.status === CustomerStatus.trial,
                subscription_plan: subscription.plan.name,
            })

            mixpanel.reset()
        }

        jumpOut()
        dispatch(clearAgencyAccountStatus())

        dispatch(resetBanner())

        queryClient.setQueryData(CUSTOMER_QUERY, null)
        // analytics logout actions
        dispatch(setURLTypeValue(''))
        dispatch(setFiltersValue(initialSelectedFilters))
        dispatch(setMetricMenuValue(MetricKeys.plays))
        dispatch(setIntervalMenuValue(MetricIntervals.day))
        dispatch(setSelectedPrepopulate([]))
        dispatch(setFiles([]))
    }
}

export default useLogout
