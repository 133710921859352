import { useFingerprint } from 'hooks/user/useFingerprint'
import { useCookies } from 'react-cookie'
import { useVdly } from 'thirdPartyServices/vdly'
import { VerifyEmailPayload } from 'types/Auth'
import { getMarketingParamsAll } from 'utils'
import { REFERRER } from 'constants/cookies.constants'

export const useVerifyEmailPayload = (email: string): VerifyEmailPayload => {
    const [cookies] = useCookies()
    const vdly = useVdly()
    const fingerprint = useFingerprint()
    const { utm_source, utm_medium, utm_campaign, utm_content, utm_term, fbclid, _fbp, _fbc } =
        getMarketingParamsAll(cookies) || {}

    return {
        email,
        refererUrl: cookies[REFERRER],
        vdly,
        us: utm_source,
        um: utm_medium,
        uca: utm_campaign,
        uco: utm_content,
        ut: utm_term,
        fc: _fbc,
        fp: _fbp,
        fid: fbclid,
        f: fingerprint,
    }
}
