import { useMemo } from 'react'

import BeenhereRoundedIcon from '@mui/icons-material/BeenhereRounded'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import WbTwilightRoundedIcon from '@mui/icons-material/WbTwilightRounded'

import { useAppDispatch } from 'App'
import { useAgencyAccess } from 'design/pages/AgencyAccount/AgencyAccount.hooks'
import { ConversionsLockedContent } from 'design/pages/Conversions'
import { openAgencyLockedFeaturesModal } from 'design/templates/Modal/ModalController/modal-controller.slice'
import {
    openSubscriptionModal,
    setPlan,
    SubscriptionCtaSource,
} from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import { closeModal, ModalType, openModal } from 'design/templates/Modal/ModalTypes/modal.slice'
import useTariffPlans from 'hooks/user/useTariffPlans'
import { VidSettingsKey } from 'constants/keys/featuresAccess.constants'

import CallsToActionsSection from './CallsToActionsSection'
import PlayGatesSection from './PlayGatesSection'
import TagsSection from './TagsSection'

const useMarketingSettingsMenu = () => {
    const { agencyAccessLocked } = useAgencyAccess()
    const dispatch = useAppDispatch()
    const { pro: proPlan } = useTariffPlans()

    const onUpgradeClick = () => {
        dispatch(closeModal())
        dispatch(setPlan({ plan: proPlan }))
        dispatch(
            openSubscriptionModal({
                ctaSource: SubscriptionCtaSource.VID_SETTINGS,
            }),
        )
    }

    return useMemo(
        () => [
            { label: 'Calls to Action', Icon: WbTwilightRoundedIcon, Children: CallsToActionsSection },
            {
                label: 'Play Gates',
                Icon: EmailRoundedIcon,
                Children: PlayGatesSection,
                forPro: true,
                featureKey: VidSettingsKey.PlayGates,
                onUpgradeClick: () => {
                    // TODO: [VID-8409] FN TO BE REMOVED ONCE FF IS ENABLED
                    if (agencyAccessLocked) {
                        return dispatch(openAgencyLockedFeaturesModal({ fallback: false }))
                    }

                    dispatch(
                        openModal({
                            type: ModalType.OLD_FEATURE_LOCKED,
                            title: 'Upgrade to Unlock Play Gates on Your Videos!',
                            description:
                                'You could have an opt-in right on top of your video and be collecting your viewers’ names, emails and phone numbers. Yes, right now, with a simple click... Heck, you can even collect those if they try to leave your video... What do you say? 😏',
                            proPlan,
                            onUpgradeClick,
                            children: <ConversionsLockedContent />,
                        }),
                    )
                },
            },
            {
                label: 'Tags',
                Icon: BeenhereRoundedIcon,
                Children: TagsSection,
                forPro: true,
                featureKey: VidSettingsKey.Tags,
                onUpgradeClick: () => {
                    // TODO: [VID-8409] FN TO BE REMOVED ONCE FF IS ENABLED
                    if (agencyAccessLocked) {
                        return dispatch(openAgencyLockedFeaturesModal({ fallback: false }))
                    }

                    dispatch(
                        openModal({
                            type: ModalType.OLD_FEATURE_LOCKED,
                            title: 'Upgrade to Unlock Tagging on Your Videos!',
                            description:
                                'Tag your viewers in your CRM, email or SMS platform when they watch to a specific point in one of your videos... So your messaging is personalized specifically for what they have already seen. 🎯',
                            proPlan,
                            onUpgradeClick,
                            children: <ConversionsLockedContent />,
                        }),
                    )
                },
            },
        ],
        [agencyAccessLocked, proPlan, onUpgradeClick],
    )
}

export default useMarketingSettingsMenu
