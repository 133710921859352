import { createSvgIcon } from '@mui/material'

const AnalyticsSidebarIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M12.1 5.2998C12.7043 5.2998 13.2 5.79549 13.2 6.3998V17.5998C13.2 18.2041 12.7043 18.6998 12.1 18.6998C11.4957 18.6998 11 18.2041 11 17.5998V6.3998C11 5.79549 11.4957 5.2998 12.1 5.2998Z"
            fill="currentColor"
        />
        <path
            d="M6.5998 9.5H6.3998C5.79549 9.5 5.2998 9.99569 5.2998 10.6V17.6C5.2998 18.2043 5.79549 18.7 6.3998 18.7H6.5998C7.20412 18.7 7.6998 18.2043 7.6998 17.6V10.6C7.6998 9.99569 7.20412 9.5 6.5998 9.5Z"
            fill="currentColor"
        />
        <path
            d="M18.7 14.3998C18.7 13.7955 18.2043 13.2998 17.6 13.2998C16.9957 13.2998 16.5 13.7955 16.5 14.3998V17.5998C16.5 18.2041 16.9957 18.6998 17.6 18.6998C18.2043 18.6998 18.7 18.2041 18.7 17.5998V14.3998Z"
            fill="currentColor"
        />
    </svg>,
    'AnalyticsSidebarIcon',
)

export default AnalyticsSidebarIcon
