import { Box } from '@mui/material'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppSelector } from 'App'
import { useCustomerQuery, useCustomerSubscriptionQuery } from 'api/queries'
import { DISPLAY_PROMO_BADGE_PERCENTAGE } from 'design/organisms/Account/AccountMyAccount/UsageInfoGrid/UsageInfoGrid'
import { ActingAs, LockedAccount, TrialExpiration, UploadLimit } from 'design/organisms/BannerController'
import usePlanUsageInfo from 'design/organisms/Account/usePlanUsageInfo'
import { selectAccount } from 'design/organisms/Account/account.slice'
import useIsLockedAccount from 'hooks/user/useIsLockedAccount'
import { CustomerStatus } from 'types/Customer'
import { useUploadLimit } from 'hooks/user/useUploadLimit'
import { selectBanner } from 'design/organisms/BannerController/banner.slice'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { route } from 'constants/routes'
import DowngradeSuccessMessage from './banners/DowngradeSuccessMessage'
import ProgressSuccessBanner from './banners/ProgressSuccessBanner'

export const BannerController = () => {
    const { isAgency } = useAppSelector(selectAccount)
    const { pathname } = useLocation()

    const { data: customer } = useCustomerQuery()
    const { data: subscription } = useCustomerSubscriptionQuery()
    const { updateChangePlanUIFlow } = useFeatureFlags()
    const isLockedAccount = useIsLockedAccount()
    const { spaceProgress } = usePlanUsageInfo()
    const { uploadLimitReached } = useUploadLimit(false)
    const { banner } = useAppSelector(selectBanner)

    const { displayDowngradeSuccessMessage } = banner || {}

    const displayedOnlyOnAccountPage = useMemo(() => pathname === route.account.index, [pathname])

    const showTrialExpirationBanner = useMemo(
        () => displayedOnlyOnAccountPage && customer?.status === CustomerStatus.trial && !subscription?.creditCard,
        [displayedOnlyOnAccountPage, subscription?.creditCard, customer?.status],
    )

    const showProgressSuccessBanner = useMemo(
        () => updateChangePlanUIFlow && displayedOnlyOnAccountPage && spaceProgress >= DISPLAY_PROMO_BADGE_PERCENTAGE,
        [spaceProgress, displayedOnlyOnAccountPage, updateChangePlanUIFlow],
    )

    const enableUploadLimitBanner = useMemo(
        () => uploadLimitReached && pathname === route.upload && isAgency,
        [isAgency, uploadLimitReached, pathname],
    )

    const content = useMemo(() => {
        const result = []

        if (showProgressSuccessBanner) result.push(<ProgressSuccessBanner key="progress success banner" />)
        if (isLockedAccount) result.push(<LockedAccount key="locked account" />)
        if (enableUploadLimitBanner) result.push(<UploadLimit key="upload limit" />)
        if (showTrialExpirationBanner) result.push(<TrialExpiration key="trial expiration" />)
        if (displayDowngradeSuccessMessage) result.push(<DowngradeSuccessMessage key="downgrade success message" />)
        if (isAgency && pathname !== route.agency.dashboard) result.push(<ActingAs key="acting as client" />)

        return result
    }, [
        showTrialExpirationBanner,
        isLockedAccount,
        isAgency,
        pathname,
        enableUploadLimitBanner,
        displayDowngradeSuccessMessage,
        showProgressSuccessBanner,
    ])

    if (content.length === 0) return null

    return <Box>{content}</Box>
}
