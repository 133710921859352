import { Row, TableInstance } from 'react-table'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import SortOutlinedIcon from '@mui/icons-material/SortOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

import Menu, { MenuOptionHandler } from 'design/atoms/Menu'
import Toolbar, { ToolbarProps } from 'design/atoms/Table/Toolbar'
import HelperButton from 'design/atoms/Table/Toolbar/HelperButton'
import SearchField from 'design/atoms/Table/Toolbar/SearchField'
import { useLayout } from 'hooks/utilities/useLayout'
import { getCurrentSortingMode, SortByOption } from 'design/atoms/Table/Toolbar/SortBy'

import './index.scss'

export type ActionsOption<T extends object = {}> = {
    label: string
    onClick: (selectedRows: Row<T>[]) => void
    disabled?: boolean
}

type ActionsProps<T extends object = {}> = TableInstance<T> & {
    searchOnly?: boolean
    actionsOnly?: boolean
    helperButton?: boolean
    toolbarConfig?: ToolbarProps
    actionsMenuOptions?: (selectedRows: Row<T>[]) => ActionsOption<T>[]
    onAdd?: () => void
}

export default function Actions<T extends object = {}>({
    selectedFlatRows,
    searchByIds,
    searchByLabel,
    searchOnly,
    actionsOnly,
    helperButton,
    toolbarConfig,
    actionsMenuOptions,
    onAdd,
    setAllFilters,
}: ActionsProps<T>) {
    const { isTablet } = useLayout()

    const handler =
        (option: ActionsOption<T>): MenuOptionHandler =>
        (setOpen) =>
        () => {
            if (selectedFlatRows.length) {
                option.onClick(selectedFlatRows)
            }

            setOpen(false)
        }

    const renderHelperButton = () => {
        if (!helperButton || !toolbarConfig?.button) return null

        return <HelperButton button={toolbarConfig.button} />
    }

    const renderActionsMenu = () => {
        if (!actionsMenuOptions) return null
        return (
            <Menu
                id="table-actions"
                label="Actions"
                placement={isTablet ? 'auto' : undefined}
                options={actionsMenuOptions(selectedFlatRows).map((option) => ({
                    label: option.label,
                    onClick: handler(option),
                    disabled: option.disabled,
                }))}
            />
        )
    }

    const renderAddButton = () => {
        if (!onAdd) return null
        return (
            <Button className="addButton" color="primary" onClick={onAdd} variant="outlined" data-testid="addButton">
                <AddOutlinedIcon color="primary" />
                New folder
            </Button>
        )
    }

    const renderSortByMenu = () => {
        if (!toolbarConfig) return null

        const handleClick = (id: SortByOption['id']) => () => () => {
            toolbarConfig.toggleSortBy(id)
        }

        return (
            <Menu
                id="sort-by-action"
                label="Sort By"
                placement="bottom"
                options={
                    toolbarConfig?.sortBy?.operations.map((operation) => {
                        const sortingMode = getCurrentSortingMode(operation, toolbarConfig.state.sortBy)

                        return {
                            label: (
                                <>
                                    <span>{operation.label}</span>
                                    {sortingMode && <SortOutlinedIcon className={sortingMode} />}
                                </>
                            ),
                            onClick: handleClick(operation.id),
                        }
                    }) || []
                }
            />
        )
    }

    if (isTablet && searchOnly) {
        return (
            <Box className={`actionsContainer`} gap={4}>
                <SearchField searchByIds={searchByIds} searchByLabel={searchByLabel} setAllFilters={setAllFilters} />
            </Box>
        )
    }

    if (isTablet && actionsOnly) {
        return (
            <Box className="actionsContainer" gap={4}>
                {renderHelperButton()}
                {renderActionsMenu()}
                {renderAddButton()}
                {renderSortByMenu()}
            </Box>
        )
    }

    return (
        <Box className="Actions">
            <Box className="actionsContainer" gap={4}>
                {renderActionsMenu()}
                {renderAddButton()}

                <SearchField searchByIds={searchByIds} searchByLabel={searchByLabel} setAllFilters={setAllFilters} />
            </Box>
            {toolbarConfig && <Toolbar {...toolbarConfig} />}
        </Box>
    )
}
