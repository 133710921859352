import { ExtendedPlan } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SelectPlanStep/usePlans'
import { PlanStatus } from 'types/Auth'
import { ApiHandle, BillingMode, CustomerSubscriptionPlanTier, ExtraPlaysUnit, PlanName } from 'types/Customer'
import { PlanIndexByTier } from 'constants/plan.constants'

export const ENTERPRISE_PLAN: ExtendedPlan = {
    id: 9999,
    status: PlanStatus.active,
    chargifyId: 6758158,
    apiHandle: ApiHandle.Enterprise,
    name: PlanName.enterprise,
    subtitle: 'Extra space, volume & tools for teams scaling with video',
    features: [
        '1 TB+ Bandwidth',
        'Scalable Video Storage',
        'Unlimited Users',
        'Unlimited Video Analytics',
        'Concierge Service',
        'Dedicated Account Manager',
        'Advanced Customizations',
    ],
    price: '',
    trialPrice: '0.00',
    creditCardRequired: true,
    setupFeeInCents: null,
    hasTrial: false,
    isFree: false,
    isPublished: true,
    billingPeriod: '1 month',
    billingMode: BillingMode.bandWidth,
    trialPeriod: null,
    bandwidth: 0,
    videoSpace: 50,
    plays: 20000,
    minutes: null,
    encoding: 'premium',
    extraBandwidthPrice: 0,
    extraBandwidthUnit: 'GB',
    extraVideoSpacePrice: null,
    extraPlaysPrice: 0,
    extraPlaysUnit: ExtraPlaysUnit.view,
    extraMinutesPrice: null,
    extraMinutesUnit: null,
    tier: CustomerSubscriptionPlanTier.enterprise,
    upsellPage: null,
    upsellMainProducts: null,
    skipBillingPageAtSignup: false,

    index: PlanIndexByTier[CustomerSubscriptionPlanTier.enterprise],
}

export const PLAYS_BASED_ENTERPRISE_PLAN = {
    ...ENTERPRISE_PLAN,
    features: [
        '100 000+ Plays',
        'Scalable Video Storage',
        'Unlimited Users',
        'Unlimited Video Analytics',
        'Concierge Service',
        'Dedicated Account Manager',
        'Advanced Customizations',
    ],
    id: 9998,
    billingMode: BillingMode.play,
    index: PlanIndexByTier[CustomerSubscriptionPlanTier.enterprisePlays],
}
