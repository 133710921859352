import { Button, ButtonProps } from '@mui/material'

import UpgradeBadgeTooltip from 'design/molecules/UpgradeBadge/UpgradeBadgeTooltip'
import { UpgradeBadgeTooltipProps } from 'design/molecules/UpgradeBadge/UpgradeBadgeTooltip/UpgradeBadgeTooltip'
import { getSx } from 'styles/theme/utils'

import style, { SupportedIconBtnSize } from './IconButton.style'

export type IconButtonProps = {
    size?: SupportedIconBtnSize
    tooltipProps?: Omit<UpgradeBadgeTooltipProps, 'children'>
}

export interface Props extends IconButtonProps {
    buttonProps: Omit<ButtonProps, 'size'>
}

const IconButton = ({ size = 'small', tooltipProps, buttonProps }: Props) => {
    return (
        <UpgradeBadgeTooltip placement="top" {...tooltipProps}>
            <Button {...buttonProps} size={size} sx={getSx(style.iconButton(size), buttonProps.sx)} />
        </UpgradeBadgeTooltip>
    )
}

export default IconButton
