import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

export default {
    root: {
        position: 'relative',
        p: 0,
        m: 4,
        minHeight: 180,

        '& > .MuiBox-root': {
            p: 0,
            m: 0,
        },
    },
    videoWrap: {
        width: 1,
        minHeight: 180,
        maxHeight: 'calc(100vh - 24px)',
        backgroundColor: 'black',
    },
    video: {
        width: 1,
        maxWidth: 1,
        margin: '0 auto',
    },
    close: {
        position: 'absolute',
        top: 16,
        right: 16,
        zIndex: 1,
        borderRadius: 50,
        maxWidth: 32,
        maxHeight: 32,
    },
    text: (theme: Theme) => ({
        maxWidth: 600,
        margin: '0 auto',
        padding: '32px 32px 0',

        [theme.breakpoints.down('tablet')]: {
            padding: '24px 16px 0',
        },
    }),
    controls: (theme: Theme) => ({
        width: 1,
        padding: '24px 32px',

        [theme.breakpoints.down('tablet')]: {
            padding: '24px 16px',
        },
    }),
    btn: (theme: Theme) => ({
        maxHeight: 60,

        [theme.breakpoints.down('tablet')]: {
            width: 1,
        },
    }),
} satisfies Record<string, SxProps<Theme>>
