import { UseMutationOptions, UseMutationResult } from 'react-query'
import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import {
    CREDIT_CARD_UPDATE_MUTATION,
    CUSTOMER_QUERY,
    CUSTOMER_SUBSCRIPTION_QUERY,
    PAYMENT_PROFILE_QUERY,
} from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { UpdateCardInfoByTokenPayload } from 'types/Customer'
import { useEffect } from 'react'
import { useShowErrorAddCard } from 'hooks/chargify.hooks'
import { ApiError } from 'api/contracts/utilitary/error'

export const useUpdateCreditCardByTokenMutation = (
    options?: UseMutationOptions<null, unknown, UpdateCardInfoByTokenPayload>,
): UseMutationResult<null, ApiError, UpdateCardInfoByTokenPayload> => {
    const { mutate } = useMutationToastDecorator()
    const showErrorAddCard = useShowErrorAddCard()

    const query = useInvalidateMutation<null, UpdateCardInfoByTokenPayload>(
        CREDIT_CARD_UPDATE_MUTATION,
        async (payload: UpdateCardInfoByTokenPayload) =>
            mutate(userAPI.updateCardInfoByToken(payload), apiLocale.user.updateCreditCard),
        {
            ...options,
            invalidate: [CUSTOMER_QUERY, CUSTOMER_SUBSCRIPTION_QUERY, PAYMENT_PROFILE_QUERY],
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )

    useEffect(() => {
        const handleOffline = () => {
            if (query.isLoading) {
                showErrorAddCard('Unable to save payment method. Please check your connection and try again.')
            }
        }

        window.addEventListener('offline', handleOffline)

        return () => window.removeEventListener('offline', handleOffline)
    }, [query.isLoading])

    return query
}
