import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import UploadIcon from '@mui/icons-material/Upload'
import { Box, Button, CardMedia, Divider, IconButton, Stack, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { ChangeEventHandler, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded'
import * as Yup from 'yup'

import { useSmartVidQuery } from 'api/queries'
import { Input } from 'design/atoms/Form'
import { HOURS_INPUT_WIDTH, MINUTES_INPUT_MAX_WIDTH, MINUTES_INPUT_MIN_WIDTH } from 'design/pages/VidSettings/constants'
import { Formik } from 'formik'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { ThumbnailSource, ThumbnailTimeValues } from 'types/VidSettings'
import { ThumbnailType } from 'types/Video'
import { HOURS_STRING, MINUTES_STRING, secondsToTime, timeRegex, timeToSeconds } from 'utils'

import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { useVidSettingsContext } from 'design/pages/VidSettings/vidSettingsContext'
import style from './ImageUploaderPause.style'

const ThumbnailTimeSchema = (videoLength: string) =>
    Yup.object().shape({
        from: Yup.string()
            .matches(timeRegex, 'Please enter the time as "00:00" or "00:00:00')
            .test(
                'is-less',
                "'From' value should be less than video duration",
                (value) => timeToSeconds(value || '00:00') <= timeToSeconds(videoLength),
            )
            .required("'From' value is required"),
        to: Yup.string()
            .matches(timeRegex, 'Please enter the time as "00:00" or "00:00:00')
            .test(
                'is-less',
                "'To' value should be less than video duration",
                (value) => timeToSeconds(value || '00:00') <= timeToSeconds(videoLength),
            )
            .test('is-greater-than-from', "'To' value should be greater than 'From' value", function (value) {
                const fromValue = this.parent.from
                if (!fromValue || !value) return true
                return timeToSeconds(value) > timeToSeconds(fromValue)
            })
            .required("'To' value is required"),
    })
interface ImageUploadProps {
    id: ThumbnailType
    title?: string
    guid?: string
    image?: string
    tooltip?: string
    imageValidateSize: {
        min: number
        max: number
    }
    disabled?: boolean
    onUpload: (payload: { data: File; id: string; guid?: string; from?: number; to?: number }) => void
    onUploadExitTime: (payload: { timeFrom: number; timeTo: number; guid?: string }) => void
    onDelete: (id: string, sourceType: ThumbnailSource, guid?: string) => void
    setThumbnailSource?: ({
        type,
        value,
        preventDbUpdate,
    }: {
        type: ThumbnailType
        value?: ThumbnailSource
        preventDbUpdate?: boolean
    }) => void
    setThumbnailFromVideoType?: (type: ThumbnailType | undefined) => void
    isDeletable?: boolean
    disableSource?: boolean
    thumbnailSourceType?: ThumbnailSource
    setInputTime?: (timeValues: ThumbnailTimeValues) => void
    handleSwitchThumbnailType?: (thumbnailType: ThumbnailType, value: ThumbnailSource) => void
    isSapEnabled?: boolean
    time?: {
        from?: number
        to?: number
    }
    videoLength?: string
    setThumbnailPauseGuid: (guid: string) => void
    thumbnailIndex: number
}

export const ImageUploadPause = ({
    id,
    guid,
    title,
    image,
    imageValidateSize = { min: 1000, max: 5e6 },
    disabled,
    onUpload,
    onUploadExitTime,
    onDelete,
    setThumbnailSource,
    isDeletable,
    setThumbnailFromVideoType,
    thumbnailSourceType,
    time,
    videoLength,
    setThumbnailPauseGuid,
    thumbnailIndex,
}: ImageUploadProps) => {
    const { smartVidId, stepVidId } = useParams()
    const { data: smartVid } = useSmartVidQuery(smartVidId)
    const [error, setError] = useState('')
    const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>()
    const hiddenFileInput = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const { videoThumbnails } = useFeatureFlags()
    const { pauseThumbnails, setPauseThumbnails } = useVidSettingsContext()

    const handleUploadClick = () => {
        hiddenFileInput.current?.click()
    }
    const getErrorMessage = (fileType: string, fileSize: number) => {
        const isValidType = /image*/.test(fileType)
        let data = !isValidType ? 'Please select valid image file. ' : ''
        if (fileSize <= imageValidateSize.min) {
            data += `Your file is too small. Min size is ${imageValidateSize.min / 1000} MB`
        } else if (fileSize >= imageValidateSize.max) {
            data += `Your file is too big. Limit is ${imageValidateSize.max / 1000000} MB`
        }
        return data ? data : null
    }

    const handleSelectFromVideo = (id: ThumbnailType) => {
        setThumbnailSource?.({
            type: id,
            value: id === ThumbnailType.customPauseScreen ? ThumbnailSource.image : thumbnailSourceType,
            preventDbUpdate: true,
        })
        setThumbnailFromVideoType?.(id as ThumbnailType)
        if (guid) {
            setThumbnailPauseGuid(guid)
        }
    }
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const file = event.target.files?.[0]

        if (!file) {
            return
        }

        const errorMessage = getErrorMessage(file.type, file.size)

        if (errorMessage) {
            setError(errorMessage)
            return
        }

        const reader = new FileReader()
        reader.onload = () => {
            setImagePreview(reader.result)
            if (guid) {
                onUpload({
                    data: file,
                    id,
                    guid,
                    from: time?.from,
                    to: time?.to,
                })
            } else {
                onUpload({
                    data: file,
                    id,
                    guid: undefined,
                    from: time?.from,
                    to: time?.to,
                })
            }

            if (error) {
                setError('')
            }
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const imageCanBeDisplayed = Boolean(imagePreview || image)
    const defaultTimeString = useMemo(
        () => (timeToSeconds(videoLength || MINUTES_STRING) > 3600 ? HOURS_STRING : MINUTES_STRING),
        [videoLength],
    )
    const isUploadDisabled = useMemo(() => thumbnailSourceType === ThumbnailSource.video, [id, thumbnailSourceType])

    const isVideoSource = useMemo(() => {
        return image?.split('.').pop() === 'mp4'
    }, [image])

    const isVidThumbnailsStepLocked = stepVidId ? smartVid?.videoGuid === stepVidId : false

    return (
        <Box>
            <Stack gap={3} sx={style.thumbnail.uploader}>
                <Box sx={[style.thumbnail.container, { display: imageCanBeDisplayed ? 'block' : 'none' }]}>
                    {!disabled && imageCanBeDisplayed && !isVideoSource && (
                        <Box
                            component="img"
                            sx={style.thumbnail.image}
                            src={String(image || imagePreview)}
                            alt={title}
                        />
                    )}
                    {!disabled && imageCanBeDisplayed && isVideoSource && (
                        <CardMedia component="video" sx={style.thumbnail.image} image={`${image}?autoplay=1`} />
                    )}

                    {disabled && (
                        <Box>
                            <Typography sx={style.thumbnail.skeletonText}>Processing</Typography>
                            <Skeleton sx={style.thumbnail.skeleton} variant="rectangular"></Skeleton>
                        </Box>
                    )}
                    {(isDeletable === undefined || isDeletable) && (
                        <IconButton
                            color="error"
                            sx={style.thumbnail.delete}
                            disabled={disabled}
                            onClick={() => {
                                if (guid) {
                                    onDelete(id, thumbnailSourceType as ThumbnailSource, guid)
                                } else {
                                    onDelete(id, thumbnailSourceType as ThumbnailSource)
                                }

                                setImagePreview(null)
                                setError('')
                            }}
                        >
                            {!disabled && <DeleteRoundedIcon />}
                        </IconButton>
                    )}
                </Box>

                <Stack gap={3} direction="row" sx={{ mt: 4 }}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleUploadClick}
                        startIcon={<UploadIcon />}
                        disabled={(videoThumbnails && isUploadDisabled) || disabled}
                    >
                        Upload
                    </Button>
                    <input type="file" onChange={handleChange} ref={hiddenFileInput} style={{ display: 'none' }} />
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={
                            disabled || (thumbnailSourceType === ThumbnailSource.video && isVidThumbnailsStepLocked)
                        }
                        onClick={() => handleSelectFromVideo(id)}
                        startIcon={<SelectAllIcon />}
                    >
                        Select from video
                    </Button>
                </Stack>
                <>
                    <Divider sx={{ mt: 4, mb: 4 }} />
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            from: secondsToTime(time?.from || 0, defaultTimeString === HOURS_STRING),
                            to: secondsToTime(time?.to || 0, defaultTimeString === HOURS_STRING),
                        }}
                        validationSchema={ThumbnailTimeSchema(videoLength || '')}
                        onSubmit={(values) => {
                            const timeFrom = secondsToTime(time?.from || 0)
                            const timeTo = secondsToTime(time?.to || 0)
                            if (timeFrom !== values.from || timeTo !== values.to) {
                                if (!guid) {
                                    const customElement = {
                                        guid: '',
                                        source: '',
                                        from: timeToSeconds(values.from),
                                        to: timeToSeconds(values.to),
                                    }

                                    setPauseThumbnails(
                                        pauseThumbnails.map((item, index) => {
                                            if (index === thumbnailIndex) {
                                                return customElement
                                            }
                                            return item
                                        }),
                                    )
                                }
                                onUploadExitTime({
                                    timeFrom: timeToSeconds(values.from),
                                    timeTo: timeToSeconds(values.to),
                                    guid,
                                })
                            }
                        }}
                        validateOnBlur={true}
                        validateOnChange={true}
                    >
                        {({ errors, handleChange, submitForm, initialValues, values }) => (
                            <form ref={formRef}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={2}
                                    sx={style.thumbnail.videoTimeWrapper}
                                >
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <Typography variant="body2" fontWeight="700">
                                            Exit Time
                                        </Typography>
                                        <HelpTooltip
                                            arrow
                                            id={guid}
                                            placement="top"
                                            title="This sets the time range when the exit thumbnail appears"
                                        />
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        alignSelf="flex-end"
                                        alignItems="center"
                                        gap={2}
                                        sx={style.thumbnail.inputsWrapper}
                                    >
                                        <Input
                                            hideError
                                            fullWidth={false}
                                            sx={{
                                                minWidth:
                                                    defaultTimeString === HOURS_STRING
                                                        ? HOURS_INPUT_WIDTH
                                                        : MINUTES_INPUT_MIN_WIDTH,
                                                maxWidth:
                                                    defaultTimeString === HOURS_STRING
                                                        ? HOURS_INPUT_WIDTH
                                                        : MINUTES_INPUT_MAX_WIDTH,
                                            }}
                                            onBlur={() => {
                                                if (values.from !== initialValues.from) {
                                                    submitForm()
                                                }
                                            }}
                                            name="from"
                                            variant="outlined"
                                            onChange={handleChange}
                                        />
                                        <ArrowForwardRounded fontSize="small" sx={{ color: '#2E3B548F' }} />
                                        <Input
                                            hideError
                                            fullWidth={false}
                                            sx={{
                                                minWidth:
                                                    defaultTimeString === HOURS_STRING
                                                        ? HOURS_INPUT_WIDTH
                                                        : MINUTES_INPUT_MIN_WIDTH,
                                                maxWidth:
                                                    defaultTimeString === HOURS_STRING
                                                        ? HOURS_INPUT_WIDTH
                                                        : MINUTES_INPUT_MAX_WIDTH,
                                            }}
                                            onBlur={() => {
                                                if (values.to !== initialValues.to) {
                                                    submitForm()
                                                }
                                            }}
                                            name="to"
                                            variant="outlined"
                                            onChange={handleChange}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack alignItems="flex-end" sx={{ mt: 2 }}>
                                    {errors.from && (
                                        <Typography variant="caption1" color={'#D32F2F'} pl={'15px'}>
                                            {errors.from}
                                        </Typography>
                                    )}
                                    {errors.to && (
                                        <Typography variant="caption1" color={'#D32F2F'} pl={'15px'}>
                                            {errors.to}
                                        </Typography>
                                    )}
                                </Stack>
                            </form>
                        )}
                    </Formik>
                </>
                {error}
            </Stack>
        </Box>
    )
}
