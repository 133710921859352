import { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

import VideoSelectControlItem from 'design/molecules/VideoSelect/VideoSelectControlItem'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { Folder, Video, VideoGuid } from 'types/Video'

import style from './VideoSelectControl.style'

type VideoSelectControlProps = {
    onChange: (value: Video) => void
    onBlur?: () => void
    value: VideoGuid | null
    options: Video[]
    folders: Folder[]
    disabled?: boolean
    error?: boolean
}

const VideoSelectControl = ({
    onChange,
    onBlur,
    value,
    options,
    disabled,
    error,
    folders,
}: VideoSelectControlProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const option = useMemo(() => {
        return options?.find((option) => option.guid === value)
    }, [options, value])

    const filterOptions = createFilterOptions({
        stringify: (option: Video) => {
            const folder = folders.find((folder) => folder.guid === option.folderGuid)
            return `${option.title} ${folder?.title || ''}`
        },
    })

    const handleClick = (option: Video) => {
        onChange(options.find((o) => o.guid === option.guid) as Video)
        setIsOpen(false)
    }

    return (
        <FormControl sx={{ minWidth: 120, width: 1 }} size="small">
            {!isOpen && (
                <Box
                    onClick={() => !disabled && setIsOpen(true)}
                    sx={[style.fallback, !!disabled && style.disabled, !!error && style.error]}
                >
                    <VideoSelectControlItem item={option} error={error} />
                </Box>
            )}
            {isOpen && (
                <Autocomplete
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    onBlur={() => onBlur?.()}
                    getOptionLabel={(option) => option.title}
                    filterOptions={filterOptions}
                    options={options}
                    PaperComponent={({ children, ...other }) => (
                        <Paper {...other} sx={{ borderRadius: 3, my: 1 }}>
                            {children}
                        </Paper>
                    )}
                    renderOption={(_, option) => {
                        const folder = folders.find((folder) => folder.guid === option.folderGuid)
                        return (
                            <MenuItem
                                onClick={() => handleClick(option)}
                                key={option.guid}
                                value={option.guid}
                                sx={{ maxWidth: 1, position: 'relative', my: '3px', minWidth: 0 }}
                            >
                                <VideoSelectControlItem item={option} folder={folder} showSubtitle />
                                <Divider key={`${option.guid}-d`} sx={style.divider} />
                            </MenuItem>
                        )
                    }}
                    renderInput={(params) => <TextField {...params} sx={style.input} variant="outlined" autoFocus />}
                />
            )}
        </FormControl>
    )
}

export default withErrorBoundary(VideoSelectControl)
