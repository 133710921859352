import { ANALYTIC_INITIAL_VALUES } from 'design/pages/Integrations/constants'
import { CSSProperties } from 'react'
import { IntegrationKey } from 'constants/keys/featuresAccess.constants'

export enum IntegrationName {
    Google = 'google',
    Facebook = 'facebookPixel',
    HubSpot = 'hubspot',
    Zapier = 'zapier',
    HighLevel = 'highlevel',
    ActiveCampaign = 'activeCampaign',
    Segment = 'segment',
}

export interface AllIntegrationStatuses {
    hubSpot: boolean
    highLevel: boolean
    segment: boolean
    facebook: boolean
    gaGtm: boolean
    activeCampaign: boolean
    zapier: boolean
}

export enum WatchedPercentType {
    NONE = '',
    EVERY_PERCENT = 'everyPercent',
    BASIC_PERCENTS = 'basicPercents',
    EVERY_FIVE_PERCENTS = 'everyFivePercents',
}

export interface IntegrationToRender {
    key?: IntegrationKey
    title: string
    description: string
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    logo: any[]
    url?: string
    isActive: boolean
    pathname?: string
    logoStyle?: CSSProperties
    testId?: string
}

export interface AnalyticsSetting {
    integration: IntegrationName.Google | IntegrationName.Facebook | IntegrationName.ActiveCampaign
    status: boolean
    sendWatchedPercent: WatchedPercentType
    sendPlay: boolean
    sendPause: boolean
    sendPausePercentage: boolean
    sendPlayGateSubmit: boolean
    sendResumePlay: boolean
    token?: string
    tokenGeneratedDate?: string
}

export type AnalyticsSettings = AnalyticsSetting[]

export interface AnalyticsSettingPayload {
    sendPlay: boolean
    sendPause: boolean
    sendPlayGateSubmit: boolean
    sendPausePercentage: boolean
    sendResumePlay: boolean
    sendWatchedPercent?: WatchedPercentType
}

export interface ChangeAnalyticsIntegrationStatusPayload {
    integration: IntegrationName.Google | IntegrationName.Facebook | IntegrationName.ActiveCampaign
    status: boolean
}

export type AnalyticFormValues = typeof ANALYTIC_INITIAL_VALUES

export interface ZapierStatus {
    isAvailable: boolean
    isEnabled: boolean
}

export interface HubspotStatus {
    status: string
    message: string
}

export type ZapierFields = string[]

export interface ZapierStatusUpdatePayload {
    zapier: ZapierStatus
}

export interface HubspotLink {
    link: string
}

export interface HighLevelLink {
    url: string
}

export interface HighLevelStatus {
    integrated: boolean
}

export interface HighLevelValidation {
    code: string
}

export interface SegmentKeyChangePayload {
    writeKey: string
}

export interface SegmentKeyStatus {
    createdAt: string
    value: string
}
