import { ReactNode, SyntheticEvent } from 'react'
import { Box, Tab, Tabs as MuiTabs } from '@mui/material'

import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'

import { useVidSettingsContext } from 'design/pages/VidSettings/vidSettingsContext'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import StyleSettings from 'design/organisms/VidSettingsSidebar/StyleSettings'
import PlaybackSettings from 'design/organisms/VidSettingsSidebar/PlaybackSettings'
import MarketingSettings from 'design/organisms/VidSettingsSidebar/MarketingSettings'
import ManagementSettings from 'design/organisms/VidSettingsSidebar/ManagementSettings'

import style from './VIdSettingsSidebarTabs.style'
import './index.scss'

export enum SettingsTab {
    style = 0,
    playback = 1,
    marketing = 2,
    management = 3,
}

function CustomTabPanel(props: { value: SettingsTab; index: SettingsTab; children: ReactNode }) {
    const { children, value, index } = props

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`settings-tab-panel-${index}`}
            aria-labelledby={`settings-tab-${index}`}
            style={{ display: value === index ? 'block' : 'none' }}
        >
            {children}
        </Box>
    )
}

const getTabProps = (index: number) => ({
    id: `settings-tab-${index}`,
    'aria-controls': `settings-tab-panel-${index}`,
    sx: { flexGrow: 1, maxWidth: 'none' },
})

const VidSettingsSidebarTabs = () => {
    const { activeTab, setActiveTab } = useVidSettingsContext()

    const handleChange = (_: SyntheticEvent, newValue: SettingsTab) => {
        setActiveTab(newValue)
    }

    return (
        <Box className="Tabs">
            <MuiTabs sx={style.divider} value={activeTab} onChange={handleChange} aria-label="settingsTabs">
                <Tab label="Style" {...getTabProps(0)} />
                <Tab label="Playback" {...getTabProps(1)} />
                <Tab label="Marketing" {...getTabProps(2)} />
                <Tab
                    label={<SettingsRoundedIcon sx={{ width: 24, height: 24 }} />}
                    {...getTabProps(3)}
                    sx={{ px: 3, width: 44 }}
                />
            </MuiTabs>
            <CustomTabPanel value={activeTab} index={SettingsTab.style}>
                <StyleSettings />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={SettingsTab.playback}>
                <PlaybackSettings />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={SettingsTab.marketing}>
                <MarketingSettings />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={SettingsTab.management}>
                <ManagementSettings />
            </CustomTabPanel>
        </Box>
    )
}

export default withErrorBoundary(VidSettingsSidebarTabs)
