import { createSvgIcon } from '@mui/material'

const BookRounded = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M21.4286 4.63636H20.5714C20.5714 4.20237 20.3908 3.78616 20.0693 3.47928C19.7478 3.1724 19.3118 3 18.8571 3C17.5734 3.00237 16.3036 3.25382 15.1248 3.73906C13.9461 4.2243 12.8829 4.93321 12 5.82273C11.1171 4.93321 10.0539 4.2243 8.87517 3.73906C7.69639 3.25382 6.42657 3.00237 5.14286 3C4.6882 3 4.25216 3.1724 3.93067 3.47928C3.60918 3.78616 3.42857 4.20237 3.42857 4.63636H2.57143C1.88944 4.63636 1.23539 4.89497 0.753154 5.35528C0.270918 5.8156 0 6.43992 0 7.09091V18.5455C0 19.1964 0.270918 19.8208 0.753154 20.2811C1.23539 20.7414 1.88944 21 2.57143 21H21.4286C22.1106 21 22.7646 20.7414 23.2468 20.2811C23.7291 19.8208 24 19.1964 24 18.5455V7.09091C24 6.43992 23.7291 5.8156 23.2468 5.35528C22.7646 4.89497 22.1106 4.63636 21.4286 4.63636ZM18.8571 4.63636V16.0909C16.6659 16.0944 14.5444 16.8264 12.8571 18.1609V7.38545C13.5813 6.53141 14.4953 5.84172 15.533 5.36627C16.5707 4.89081 17.7061 4.6415 18.8571 4.63636ZM5.14286 4.63636C6.29389 4.6415 7.42933 4.89081 8.46702 5.36627C9.50471 5.84172 10.4187 6.53141 11.1429 7.38545V18.1609C9.45561 16.8264 7.33409 16.0944 5.14286 16.0909V4.63636ZM1.71429 18.5455V7.09091C1.71429 6.87391 1.80459 6.66581 1.96534 6.51237C2.12608 6.35893 2.3441 6.27273 2.57143 6.27273H3.42857V16.0909C3.42857 16.5249 3.60918 16.9411 3.93067 17.248C4.25216 17.5549 4.6882 17.7273 5.14286 17.7273C6.90265 17.7304 8.60842 18.3078 9.97714 19.3636H2.57143C2.3441 19.3636 2.12608 19.2774 1.96534 19.124C1.80459 18.9706 1.71429 18.7625 1.71429 18.5455ZM22.2857 18.5455C22.2857 18.7625 22.1954 18.9706 22.0347 19.124C21.8739 19.2774 21.6559 19.3636 21.4286 19.3636H14.0229C15.3916 18.3078 17.0974 17.7304 18.8571 17.7273C19.3118 17.7273 19.7478 17.5549 20.0693 17.248C20.3908 16.9411 20.5714 16.5249 20.5714 16.0909V6.27273H21.4286C21.6559 6.27273 21.8739 6.35893 22.0347 6.51237C22.1954 6.66581 22.2857 6.87391 22.2857 7.09091V18.5455Z"
            fill="currentColor"
        />
    </svg>,
    'BookRounded',
)

export default BookRounded
