import { Autocomplete, Box, MenuItem, SelectChangeEvent, Stack, TextField, Typography, Select } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useCustomerSubscriptionQuery } from 'api/queries'
import { useCustomerQuery } from 'api/queries/user/useCustomerQuery'
import { useGetAgencyClientsQuery } from 'api/queries/agency/useGetAgencyClientsQuery'
import { AgencyClient } from 'api/contracts/agency/entities/agencyClient'
import { useAppDispatch } from 'App'
import { openModal } from 'design/templates/Modal'
import PageOld from 'design/templates/PageOld'
import { CustomerSubscriptionPlanTier } from 'types/Customer'
import { route } from 'constants/routes'

import style from './AgencyAccount.styles'
import locale from './AgencyAccount.locale'
import { ClientCard } from './ClientCard/ClientCard'
import { Header } from './Header/Header'
import { AddNewClientCard } from './ClientCard/AddNewClientCard/AddNewClientCard'
import { AgencyDashboardCard } from './ClientCard/AgencyDashboardCard/AgencyDashboardCard'
import { useClientAccount } from './AgencyAccount.hooks'

export const OPEN_CREATE_CLIENT_MODAL = 'openCreateClientModal'

type SortingOptions = keyof Pick<AgencyClient, 'name' | 'createdAt'>

const sortingOptions: { [key in SortingOptions]: string } = {
    name: locale.sorting.name,
    createdAt: locale.sorting.createdAt,
}

export const AgencyAccount = () => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { isClientAccountEnabled, jumpOut } = useClientAccount()
    const { data: customer } = useCustomerQuery()
    const { data: subscription } = useCustomerSubscriptionQuery()
    const { agencyClients, getAgencyClientsQuery } = useGetAgencyClientsQuery()

    const [sortBy, setSortBy] = useState<SortingOptions>('name')
    const [filterClients, setFilterClients] = useState('')

    useEffect(() => {
        if (isClientAccountEnabled) {
            jumpOut()
        }
    }, [isClientAccountEnabled])

    useEffect(() => {
        // if is not agency
        if (
            subscription &&
            customer &&
            !customer.businessName &&
            subscription.plan.tier === CustomerSubscriptionPlanTier.agency
        ) {
            navigate(route.dashboard)
        }
    }, [customer, subscription])

    useEffect(() => {
        if (location.state) {
            location.state[OPEN_CREATE_CLIENT_MODAL] && dispatch(openModal({ type: 'Create agency client modal' }))
        }
    }, [])

    const clientCards = useMemo(() => {
        if (getAgencyClientsQuery.isLoading) return <AgencyDashboardCard isLoading={true} />

        if (!agencyClients) return null

        const sortByName = (a: AgencyClient, b: AgencyClient) => a.name.localeCompare(b.name)
        const sortByDate = (a: AgencyClient, b: AgencyClient) => Date.parse(a.createdAt) - Date.parse(b.createdAt)

        return agencyClients
            .filter(filterClients.length ? ({ name }) => name === filterClients : () => true)
            .sort(sortBy === 'name' ? sortByName : sortByDate)
            .map((client) => <ClientCard {...client} key={client.id} />)
    }, [agencyClients, getAgencyClientsQuery, filterClients, sortBy])

    return (
        <PageOld header={<Header />} sx={style.container}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={5}>
                <Typography variant="h4">{locale.title}</Typography>
                <Stack gap={2} direction="row" alignItems="end">
                    <Autocomplete
                        disablePortal
                        options={agencyClients?.map((client) => ({ label: client.name })) || []}
                        slotProps={{}}
                        onChange={(_, value) => setFilterClients(value?.label || '')}
                        renderInput={(params) => (
                            <TextField {...params} sx={{ width: 200, input: { mr: 15 } }} placeholder="Search client" />
                        )}
                    />
                    <Select
                        onChange={(e: SelectChangeEvent) => setSortBy(e.target.value as SortingOptions)}
                        value={sortBy}
                        renderValue={(v) => `Sort by: ${sortingOptions[v as SortingOptions]}`}
                        sx={style.sortBy}
                    >
                        <MenuItem key={'name'} value={'name'} disabled={false}>
                            <Typography noWrap>{locale.sorting.name}</Typography>
                        </MenuItem>
                        <MenuItem key={'createdAt'} value={'createdAt'} disabled={false}>
                            <Typography noWrap>{locale.sorting.createdAt}</Typography>
                        </MenuItem>
                    </Select>
                </Stack>
            </Stack>

            <Box sx={style.clientCard.container}>
                {clientCards}
                {!getAgencyClientsQuery.isLoading && <AddNewClientCard />}
            </Box>
        </PageOld>
    )
}

export default AgencyAccount
