import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Formik, FormikProps } from 'formik'
import { useSmartVidQuery, useVideoQuery } from 'api/queries'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { GlobalSettingsFormProvider } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import { FOCUSED_FULLSCREEN_SECTION_INITIAL_VALUES } from 'design/pages/VidSettings/constants'
import { FocusedFullscreenSectionFormData } from 'types/VidSettings'
import { VideoDetails } from 'types/Video'
import FocusedFullscreenContent from './FocusedFullscreenContent'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const FocusedFullscreenSection = ({
    resetForm,
    isSmartVidGlobalSettings,
}: FormikProps<FocusedFullscreenSectionFormData> & Props) => {
    const { videoGuid, smartVidId } = useParams()

    const { data: smartVid } = useSmartVidQuery(String(smartVidId), {
        enabled: Boolean(smartVidId),
    })

    const initForm = (details: VideoDetails) => {
        if (isSmartVidGlobalSettings) return
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoGuid || smartVid?.video.guid, {
        onSuccess: initForm,
    })

    const Provider = useMemo(
        () => (isSmartVidGlobalSettings ? GlobalSettingsFormProvider : SettingsSectionProvider),
        [isSmartVidGlobalSettings],
    )

    return (
        <Provider>
            <Form>
                <FocusedFullscreenContent isSmartVidGlobalSettings={isSmartVidGlobalSettings} />
            </Form>
        </Provider>
    )
}

const FormikWrapper = ({ isSmartVidGlobalSettings }: Props) => {
    return (
        <Formik<FocusedFullscreenSectionFormData>
            initialValues={FOCUSED_FULLSCREEN_SECTION_INITIAL_VALUES}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
        >
            {(props) => <FocusedFullscreenSection {...props} isSmartVidGlobalSettings={isSmartVidGlobalSettings} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
