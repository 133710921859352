import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import './index.scss'

type CloseButtonProps = {
    onClose: () => void
    disabled?: boolean
}

const CloseButton = ({ onClose, disabled }: CloseButtonProps) => {
    return (
        <IconButton disabled={disabled} onClick={onClose} className="CloseButton">
            <CloseIcon />
        </IconButton>
    )
}

export default CloseButton
