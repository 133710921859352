import { set as _set } from 'lodash'

export const valueFormatter =
    <T>(formatter: (value: T) => string | number) =>
    (value: T): string | number =>
        formatter(value)

export const generateMockTableData = (cols: Array<string>, length = 5) => {
    return Array.from({ length }, (_, index) =>
        cols.reduce((obj, key) => (key === 'id' ? _set(obj, key, index + 1) : _set(obj, key, Math.random())), {}),
    )
}
