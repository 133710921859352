import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import mixpanel from 'mixpanel-browser'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import _isBoolean from 'lodash/isBoolean'
import _debounce from 'lodash/debounce'
import _keys from 'lodash/keys'

import useVidConditionsStateMutation from 'api/mutations/video/useVidConditionsStateMutation'
import { useSetVideoEmbedPublicShareMutation } from 'api/mutations'
import {
    useCustomerQuery,
    useCustomerSubscriptionQuery,
    useSmartVidEmbedQuery,
    useVideoEmbedQuery,
    useVideoQuery,
} from 'api/queries'
import { useAppDispatch } from 'App'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { Link } from 'design/atoms/Link'
import { EmbedCode } from 'design/molecules/EmbedCode'
import PublishButton from 'design/molecules/PublishButton'
import VidConditions from 'design/organisms/VidConditions'
import VidConditionsOld from 'design/organisms/VidConditionsOld'
import { useAgencyAccess } from 'design/pages/AgencyAccount/AgencyAccount.hooks'
import { ConversionsLockedContent } from 'design/pages/Conversions'
import {
    Modal,
    openAgencyLockedFeaturesModal,
    openSubscriptionModal,
    setPlan,
    SubscriptionCtaSource,
} from 'design/templates/Modal'
import EmbedCodeShareLink from 'design/templates/Modal/modalTemplates/EmbedCodeModalVidConditions/EmbedCodeShareLink'
import EmbedCodeTabPanel from 'design/templates/Modal/modalTemplates/EmbedCodeModalVidConditions/EmbedCodeTabPanel'
import { closeModal, ModalType, openModal } from 'design/templates/Modal/ModalTypes/modal.slice'
import { useCopyToClipboard } from 'hooks/system/useCopyToClipboard'
import useFeatureFlags, { VID_4622 } from 'hooks/system/useFeatureFlags'
import useTariffPlans from 'hooks/user/useTariffPlans'
import { useLayout } from 'hooks/utilities/useLayout'
import { APPCUES_EVENTS, trackAppCuesEvent } from 'thirdPartyServices/appCues'
import { CustomerStatus } from 'types/Customer'
import { VideoGuid } from 'types/Video'
import UpgradeBadge from 'design/molecules/UpgradeBadge'
import { useCheckAccess } from 'utils'
import { fontSx } from 'styles/theme/typography'
import { FeatureGroup, VidSettingsKey } from 'constants/keys/featuresAccess.constants'
import { vidConditionsLockedModalConfig } from 'constants/subscriptions'
import { route } from 'constants/routes'
import helpArticles from 'constants/help.constants'
import { SAVE_TIMEOUT } from 'constants/api.constants'

import locale from './EmbedCodeModal.locale'
import style from './EmbedCodeModal.style'

type EmbedCodeModalProps = {
    videoId?: string
    funnelId?: string
    smartVid?: boolean
    onClose: () => void
}

enum Embeds {
    video = 'video',
    smartVid = 'smartVid',
}

export type EmbedVideoType = 'async' | 'multiple'

export type QuickShareLinkType = 'preview' | 'oEmbed'

const tabProps = (index: number) => ({
    id: `embed-code-${index}`,
    'aria-controls': `embed-code-panel-${index}`,
    sx: style.tab,
})

export const EmbedCodeModalVidConditions = ({ videoId, funnelId, smartVid, onClose }: EmbedCodeModalProps) => {
    const dispatch = useAppDispatch()
    const { agencyAccessLocked } = useAgencyAccess()
    const isVidConditionsLocked = useCheckAccess(FeatureGroup.VidSettings)(VidSettingsKey.VidConditions)
    const [segmentsCount, setSegmentsCount] = useState(0)
    const [isValid, setIsValid] = useState(true)
    const { vidConditions2, funnelEmbedCodeApiChange, freshUpgradeFlow, featureFlags } = useFeatureFlags()
    const { isTablet } = useLayout()
    const { data, isLoading: isLoadingVideo } = useVideoQuery(videoId as VideoGuid)
    const { copy } = useCopyToClipboard()
    const { pro: proPlan } = useTariffPlans()

    const embedCanBeLoaded = _isBoolean(featureFlags[VID_4622])
    const embedTypeToUse = embedCanBeLoaded
        ? smartVid && funnelEmbedCodeApiChange && Boolean(funnelId)
            ? Embeds.smartVid
            : Embeds.video
        : null
    const useSmartVidEmbed = embedTypeToUse === Embeds.smartVid
    const eventPrefix = useSmartVidEmbed ? 'smartvid_embed' : 'embed'

    const [vidConditionsEnabled, setVidConditionsEnabled] = useState(false)

    const {
        data: videoEmbed,
        isLoading: isVideoEmbedQueryLoading,
        isFetching: isVideoEmbedQueryFetching,
    } = useVideoEmbedQuery(videoId, {
        enabled: embedTypeToUse === Embeds.video && Boolean(videoId),
    })
    const {
        data: smartVidEmbed,
        isLoading: isSmartVidEmbedQueryLoading,
        isFetching: isSmartVidEmbedQueryFetching,
    } = useSmartVidEmbedQuery(funnelId, {
        enabled: useSmartVidEmbed,
    })

    const { data: customer } = useCustomerQuery()
    const { data: subscription } = useCustomerSubscriptionQuery()

    const isTrial = subscription?.status === CustomerStatus.trial
    const embed = useSmartVidEmbed ? smartVidEmbed : videoEmbed
    const isLoading = useSmartVidEmbed ? isSmartVidEmbedQueryLoading : isVideoEmbedQueryLoading
    const isFetching = useSmartVidEmbed ? isSmartVidEmbedQueryFetching : isVideoEmbedQueryFetching

    const setVideoEmbedPublicShareMutation = useSetVideoEmbedPublicShareMutation(videoId, funnelId, useSmartVidEmbed)
    const { mutate: vidConditionsStateMutate } = useVidConditionsStateMutation(videoId as VideoGuid)
    const [embedCodeType, setEmbedCodeType] = useState<EmbedVideoType>('async')
    const [tab, setTab] = useState(0)
    const embedCode = embed ? String(embed[embedCodeType]) : ''

    const handleTabChange = (_: SyntheticEvent, newValue: number) => {
        setTab(newValue)
    }

    const handleTypeChange = (_: SyntheticEvent, value: boolean) => {
        setEmbedCodeType(value ? 'multiple' : 'async')
    }

    const trackingEvent = {
        ...(eventPrefix === 'smartvid_embed' && { smartvid_id: funnelId || null }),
        video_id: videoId || null,
        pathname: smartVid ? route.smartVideo.catalog : route.video.catalog,
        created_at: customer?.dateCreated,
        user_id: customer?.guid,
        is_trial: `${isTrial}`,
        subscription_tier: subscription?.plan.tier,
        subscription_id: subscription?.plan.apiHandle,
        subscription_status: subscription?.status,
        subscription_istrial: subscription?.status === CustomerStatus.trial,
        url: window.location.href,
    }

    useEffect(() => {
        mixpanel.track(`${eventPrefix}_open`, {
            ...(eventPrefix === 'smartvid_embed' && { smartvid_id: funnelId || null }),
            ...trackingEvent,
        })
    }, [])

    const setPublicPreviewPermission = (event: ChangeEvent<HTMLInputElement>) => {
        setVideoEmbedPublicShareMutation.mutate({ isPublicPreviewAllowed: !event.target.checked })
    }

    const handleCopyEmbedCode = () => {
        copy(embedCode)
        trackAppCuesEvent(APPCUES_EVENTS.EMBED_CODE_COPIED)
        mixpanel.track(`${eventPrefix}_code_copied`, {
            ...trackingEvent,
            type: embedCodeType === 'async' ? 'standard' : 'multiple videos',
            ...(eventPrefix === 'embed' && {
                segmentation: segmentsCount,
            }),
        })
    }

    const handleCopyLink = (type: QuickShareLinkType) => {
        mixpanel.track(`${eventPrefix}_link_copied`, {
            ...trackingEvent,
            type: type === 'oEmbed' ? 'oembed' : 'quickshare',
        })
    }

    const onUpgradeClick = () => {
        dispatch(closeModal())
        dispatch(setPlan({ plan: proPlan }))
        dispatch(
            openSubscriptionModal({
                ctaSource: SubscriptionCtaSource.VID_CONDITIONS,
            }),
        )
    }

    const handleUpgradeClick = () => {
        dispatch(
            openModal(
                freshUpgradeFlow
                    ? vidConditionsLockedModalConfig
                    : {
                          type: ModalType.OLD_FEATURE_LOCKED,
                          title: locale.upgradeToUnlock,
                          description: locale.boostEngagement,
                          proPlan,
                          onUpgradeClick,
                          children: <ConversionsLockedContent />,
                      },
            ),
        )
        return
    }

    useEffect(() => {
        const hasSegments = _keys(data?.settings?.targeted?.segments).length > 0

        if (
            data?.settings?.targeted?.enabled === true ||
            (!_isBoolean(data?.settings?.targeted?.enabled) && hasSegments)
        ) {
            setVidConditionsEnabled(true)
        }
    }, [isLoadingVideo, videoId, funnelId])

    const vidConditionsEnabledChangeDebounced = useCallback(_debounce(vidConditionsStateMutate, SAVE_TIMEOUT), [
        vidConditionsStateMutate,
    ])

    return (
        <Modal onClose={onClose} width="lg" stackProps={{ sx: style.container }} open>
            <Modal.Header onClose={onClose} nodeTitle>
                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label={locale.tab.inlineEmbed} {...tabProps(0)} />
                    <Tab label={locale.tab.quickShare} data-testid="publicPreviewPanel" {...tabProps(1)} />
                </Tabs>
            </Modal.Header>
            <Modal.Body>
                <EmbedCodeTabPanel value={tab} index={0}>
                    <Stack>
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                {locale.title}
                            </Typography>
                            <Typography variant="body2">
                                {locale.description}{' '}
                                <Link to={helpArticles.multipleVideos} target="_blank" rel="noreferrer" external>
                                    {locale.learnMore}
                                </Link>
                            </Typography>
                        </Box>
                        <Stack mt={4} alignItems="flex-end">
                            <EmbedCode isLoading={isLoading || isFetching} embedCode={embedCode} />
                        </Stack>
                        <Stack mt={6}>
                            <FormControlLabel
                                onChange={handleTypeChange}
                                control={<Checkbox />}
                                label={
                                    <Stack mt="-3px" gap={1}>
                                        <Typography fontWeight={600}>{locale.multipleVideos}</Typography>
                                        <Typography variant="caption2">{locale.enableMultipleVideos}</Typography>
                                    </Stack>
                                }
                                sx={style.label}
                            />
                        </Stack>
                        <Stack my={6}>
                            <FormControlLabel
                                onClick={() => {
                                    if (agencyAccessLocked) {
                                        return dispatch(openAgencyLockedFeaturesModal({ fallback: false }))
                                    }
                                    if (isVidConditionsLocked) handleUpgradeClick()
                                }}
                                onChange={() => {
                                    if (isVidConditionsLocked) return

                                    setVidConditionsEnabled(!vidConditionsEnabled)
                                    if (vidConditions2) {
                                        vidConditionsEnabledChangeDebounced(!vidConditionsEnabled)
                                    }
                                }}
                                control={<Checkbox checked={vidConditionsEnabled} />}
                                label={
                                    <Stack mt="-3px" gap={1} direction="row" alignItems="center">
                                        <Typography fontWeight={600}>{locale.vidConditions}</Typography>
                                        <HelpTooltip title={locale.vidConditionsTip} sx={{ mt: '1px' }} />
                                        {isVidConditionsLocked && (
                                            <Box sx={{ ml: 3 }}>
                                                <UpgradeBadge size="small" label={locale.unlock} preventDefault />
                                            </Box>
                                        )}
                                    </Stack>
                                }
                            />
                            <Box mt={4} pl={8}>
                                {vidConditions2 ? (
                                    <VidConditions
                                        onChange={({ valid, segmentsCount }) => {
                                            setIsValid(valid)
                                            setSegmentsCount(segmentsCount)
                                        }}
                                        fallback={videoId as VideoGuid}
                                        disabled={!vidConditionsEnabled || isVidConditionsLocked}
                                    />
                                ) : (
                                    <VidConditionsOld
                                        onChange={({ valid, segmentsCount }) => {
                                            setIsValid(valid)
                                            setSegmentsCount(segmentsCount)
                                        }}
                                        fallback={videoId as VideoGuid}
                                        disabled={!vidConditionsEnabled || isVidConditionsLocked}
                                    />
                                )}
                            </Box>
                        </Stack>
                        <Divider sx={{ width: 'calc(100% + 48px)', mx: -6 }} />
                        <Stack direction={isTablet ? 'column' : 'row'} justifyContent="space-between" gap={2} pt={5}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <InfoOutlinedIcon color="info" />
                                <Typography variant="body2">{locale.processingTime}</Typography>
                            </Stack>
                            <Stack gap={2} direction="row" justifyContent="flex-end">
                                <PublishButton
                                    onClick={() => setIsValid(true)}
                                    videoGuid={videoId as VideoGuid}
                                    title={locale.republish}
                                    propsButton={{
                                        color: 'secondary',
                                        disabled: !data?.video?.isUnpublished || !isValid,
                                    }}
                                />
                                <Button
                                    onClick={handleCopyEmbedCode}
                                    variant="contained"
                                    startIcon={<CodeRoundedIcon />}
                                >
                                    {locale.copyCode}
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </EmbedCodeTabPanel>
                <EmbedCodeTabPanel value={tab} index={1}>
                    <Stack gap={6}>
                        <Stack gap={4}>
                            <Typography sx={fontSx.inter.regular} variant="h6" fontWeight="400">
                                {locale.quickShareLabel}
                            </Typography>
                            <FormControlLabel
                                disabled={setVideoEmbedPublicShareMutation.isLoading}
                                label={locale.quickShareDescription}
                                checked={!embed?.isPublicPreviewAllowed}
                                control={<Checkbox onChange={setPublicPreviewPermission} />}
                            />
                        </Stack>
                        <Stack gap={4}>
                            <EmbedCodeShareLink
                                label={locale.linkQuickShare}
                                embed={embed}
                                linkType="preview"
                                embedType={embedCodeType}
                                handleCopy={() => handleCopyLink('preview')}
                            />
                            <EmbedCodeShareLink
                                label={locale.linkOEmbed}
                                embed={embed}
                                linkType="oEmbed"
                                embedType={embedCodeType}
                                handleCopy={() => handleCopyLink('oEmbed')}
                            />
                        </Stack>
                    </Stack>
                </EmbedCodeTabPanel>
            </Modal.Body>
        </Modal>
    )
}

export default EmbedCodeModalVidConditions
