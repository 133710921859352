import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import React, { Dispatch, FocusEvent, SetStateAction, useRef } from 'react'

import { Input, Radio } from 'design/atoms/Form'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { Link } from 'design/atoms/Link'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    CUSTOM_VARIABLES,
    FIELDS,
    NAME,
    TAGS,
    TAGS_SECTION_INITIAL_VALUES,
    TIME,
    TYPE,
} from 'design/pages/VidSettings/constants'
import { TagsSectionFormData } from 'types/VidSettings'
import { TagConfig, TagType } from 'types/VideoTag'
import { route } from 'constants/routes'

import TagsCard from './TagsCard'
import styles from './TagsContent.style'
import './index.scss'

interface TagsContentProps {
    formExpanded: boolean
    setFormExpanded: Dispatch<SetStateAction<boolean>>
}

export const TagsContent = ({ formExpanded, setFormExpanded }: TagsContentProps) => {
    const {
        video,
        formik: { setValues, resetForm, setFieldValue, values },
    } = useSettingsSectionContext<TagsSectionFormData>()

    const hasTags = Boolean(video?.[TAGS].length)

    const scrollRef = useRef<HTMLInputElement>(null)

    const scrollAction = () => {
        if (scrollRef.current) {
            setTimeout(() =>
                scrollRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'nearest',
                }),
            )
        }
    }

    const handleEditClick = (tag: TagConfig) => {
        setValues({ ...TAGS_SECTION_INITIAL_VALUES, ...tag })
        setFieldValue(CUSTOM_VARIABLES, tag.customVariables)
        setFormExpanded(true)
        scrollAction()
    }

    const handleCancel = () => {
        setFormExpanded(false)
        resetForm()
    }

    const handleRemoveTag = (tagName: string) => {
        const newTags = values.customVariables.filter((tag) => tag !== tagName)
        setFieldValue(CUSTOM_VARIABLES, newTags)
    }

    const handleBloorInput = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const newValue = event?.target?.value
        // @ts-ignore
        if (!values.customVariables.includes(newValue) && newValue) {
            setFieldValue(CUSTOM_VARIABLES, [...values.customVariables, event?.target?.value])
        }
        setFieldValue(FIELDS, '')
    }

    const handleKeyDownInput = (
        event: React.KeyboardEvent<HTMLDivElement> | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault()
            // @ts-ignore
            handleBloorInput(event)
        }
    }

    return (
        <Box className="TagsContent">
            <Box className="tagsHeaderText">
                <Typography variant="body2">
                    In order to use tags, you should set up one of our CMS or email software integrations.&nbsp;
                    <Link rel="noreferrer" to={route.integrations.index}>
                        Check them out there
                    </Link>
                </Typography>
            </Box>
            {hasTags && (
                <Box
                    sx={{
                        color: 'text.primary',
                    }}
                >
                    <Typography className="createNewTag" variant="body2">
                        Create new Tag
                        <HelpTooltip title="Create a new tag to use in your video. Tags can be used to trigger automations in your email marketing software." />
                    </Typography>
                    <Box className="tagsCards">
                        {video?.[TAGS].map((tag) => (
                            <TagsCard
                                key={tag.guid}
                                tag={tag}
                                onEditClick={handleEditClick}
                                onDeleteClick={handleCancel}
                            />
                        ))}
                    </Box>
                </Box>
            )}
            {!formExpanded && (
                <Box>
                    <Button
                        className="redesign ico addBtn"
                        onClick={() => {
                            setFormExpanded(true)
                            scrollAction()
                        }}
                        variant="outlined"
                        fullWidth={!hasTags}
                    >
                        <AddRoundedIcon />
                        {hasTags ? 'Add Tag' : 'Create new Tag'}
                    </Button>
                </Box>
            )}

            {formExpanded && (
                <Box sx={styles.formSection} className="formSection">
                    <Box className="formSectionAccordion">
                        <Box className="subSection">
                            <Typography className="subSectionTitle">{values[NAME] ? 'Edit Tag' : 'New Tag'}</Typography>

                            <Input
                                name={NAME}
                                label="Tag name"
                                placeholder="Type here..."
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                displayMaxLength
                                multiline
                            />
                        </Box>
                        <Divider />
                    </Box>
                    <Box className="subSection">
                        <Box className="gap25 tagsRadio">
                            <Radio
                                className="TagType"
                                labelVariant="body2"
                                options={[
                                    {
                                        label: (
                                            <Box className="container">
                                                <Box className="label">
                                                    Time
                                                    <HelpTooltip
                                                        arrow
                                                        id="TagTypeInfo"
                                                        placement="top"
                                                        title=" Select this option to send information on video watch time to
                                                        your CRM."
                                                    />
                                                </Box>
                                            </Box>
                                        ),
                                        value: TagType.time,
                                    },
                                ]}
                                name={TYPE}
                            />
                        </Box>
                        <Box className="inputs" py="6px" sx={{ pl: 7 }}>
                            <Input name={TIME} variant="outlined" placeholder="00:00" />
                        </Box>
                        <Box className="gap25 tagsRadio">
                            <Radio
                                labelVariant="body2"
                                onClick={() => {
                                    setFieldValue(TIME, '00:00')
                                    setFieldValue(TYPE, TagType.callToAction)
                                }}
                                className="TagType"
                                options={[
                                    {
                                        label: (
                                            <Box className="container">
                                                <Box className="label">
                                                    Call to Action
                                                    <HelpTooltip
                                                        id="TagTypeInfo"
                                                        arrow
                                                        placement="top"
                                                        title="Use this option to tag viewers that click on your CTA button(s)."
                                                    />
                                                </Box>
                                            </Box>
                                        ),
                                        value: TagType.callToAction,
                                    },
                                ]}
                                name={TYPE}
                            />
                        </Box>
                        <Box className="gap25 tagsRadio">
                            <Radio
                                labelVariant="body2"
                                onClick={() => {
                                    setFieldValue(TIME, '00:00')
                                    setFieldValue(TYPE, TagType.interactive)
                                }}
                                className="TagType"
                                options={[
                                    {
                                        label: (
                                            <Box className="container">
                                                <Box className="label">
                                                    Interactive
                                                    <HelpTooltip
                                                        id="TagTypeInfo"
                                                        arrow
                                                        placement="top"
                                                        title="Enable this option to send choice(s) selection on your
                                                        interactive smart vids to your CRM."
                                                    />
                                                </Box>
                                            </Box>
                                        ),
                                        value: TagType.interactive,
                                    },
                                ]}
                                name={TYPE}
                            />
                        </Box>
                    </Box>
                    <Box className="formSectionAccordion tagsInputWrapper">
                        <Box className="tagsInput">
                            {values?.customVariables?.map((field) => {
                                return (
                                    <Box className="tagValue" key={field}>
                                        {field}
                                        <IconButton
                                            className="close"
                                            onClick={() => {
                                                handleRemoveTag(field)
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Box className="subSection">
                            <Input
                                name={FIELDS}
                                label="Fields"
                                className="tagFields"
                                onBlur={(event) => handleBloorInput(event)}
                                onKeyDown={(event) => handleKeyDownInput(event)}
                                placeholder=""
                                variant="outlined"
                                multiline
                                rows={4}
                                displayMaxLength
                            />
                        </Box>
                        <Divider />
                    </Box>
                    <Box className="ctas">
                        <Button variant="outlined" className="redesign" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" className="redesign" type="submit">
                            Save
                        </Button>
                    </Box>
                    <Divider />
                </Box>
            )}
            <Box ref={scrollRef} />
        </Box>
    )
}
