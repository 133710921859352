import { http } from 'api/utils/http.utils'
import { AnalyticsData, AnalyticsFiltersData, AnalyticsMetricsData, URLParamsData } from 'design/pages/Analytics/types'
import { AxiosRequestConfig } from 'axios'

const getStatsUrl = (path: string) => `${process.env.REACT_APP_STATS_API_URL}/${path}`

export const analyticsAPI = {
    getGroupStats: (params: string) => http.get<AnalyticsData>(getStatsUrl(`statistic/group-stats/videos?${params}`)),
    getMetricsStats: (params: string) =>
        http.get<AnalyticsMetricsData>(getStatsUrl(`statistic/group-stats/metrics?${params}`)),
    getFiltersStats: () => http.get<AnalyticsFiltersData>(getStatsUrl('statistic/group-stats/filters')),
    getURLParams: () => http.get<URLParamsData[]>(getStatsUrl('statistic/group-stats/filters/url-params')),
    getValuesByURLParam: (params: string) =>
        http.get<URLParamsData[]>(getStatsUrl(`statistic/group-stats/filters/url-params/values?${params}`)),
    downloadVideoAnalytics: (format: string, params: string, config?: AxiosRequestConfig) =>
        http.get<URLParamsData[]>(getStatsUrl(`statistic/group-stats/export/${format}?${params}`), config),
}
