import { ReactNode } from 'react'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material'

import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { transformBytes } from 'utils'
import { useVideoQuery } from 'api/queries'

import './index.scss'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { IMPORTED_FROM } from './constants'

const ManagementSection = () => {
    const { videoGuid, stepVidId } = useParams()
    const { data: video, isLoading: isVideoQueryLoading } = useVideoQuery(videoGuid || stepVidId)
    const { isImportedFrom } = useFeatureFlags()

    const formatImportedFrom = (value: string | undefined) => {
        switch (value) {
            case 'vimeo':
                return IMPORTED_FROM['vimeo']
            case 'wistia':
                return IMPORTED_FROM['wistia']
            case 'youtube':
                return IMPORTED_FROM['youtube']
            default:
                return 'n/a'
        }
    }
    const renderTextOrSkeleton = (text?: string, skeletonWidth = 120) =>
        isVideoQueryLoading ? <Skeleton width={skeletonWidth} /> : <Typography>{text}</Typography>

    const renderRow = ({
        leftNode,
        rightNode,
        leftText,
        rightText,
    }: {
        leftNode?: ReactNode
        rightNode?: ReactNode
        leftText?: string
        rightText?: string
    }) => (
        <Grid container>
            <Grid item mobile={6}>
                {leftNode || <Typography className="titleLabel">{leftText}</Typography>}
            </Grid>
            <Grid item mobile={6}>
                {rightNode || renderTextOrSkeleton(rightText)}
            </Grid>
        </Grid>
    )

    return (
        <Box className="ManagementSection">
            {renderRow({
                leftNode: (
                    <>
                        <Typography className="titleLabel">Video Size</Typography>
                        {renderTextOrSkeleton(
                            video?.video.size ? transformBytes(video.video.size) : 'Processing...',
                            30,
                        )}
                    </>
                ),
                rightNode: (
                    <>
                        <Typography className="titleLabel">Video Length</Typography>
                        {renderTextOrSkeleton(video?.video.length || 'Processing...', 60)}
                    </>
                ),
            })}
            <Divider />
            {renderRow({
                leftText: 'Uploaded by',
                rightText: video?.uploadedBy || 'n/a',
            })}
            <Divider />
            {renderRow({
                leftText: 'Last Updated',
                rightText: video?.video.dateUpdated
                    ? format(new Date(video?.video.dateUpdated), 'MM / dd / yyyy HH:mm')
                    : '',
            })}
            <Divider />
            {renderRow({
                leftText: 'Updated by',
                rightText: video?.updatedBy || 'n/a',
            })}
            {isImportedFrom && (
                <>
                    <Divider />
                    {renderRow({
                        leftText: 'Imported from',
                        rightText: formatImportedFrom(video?.settings.external_platform),
                    })}
                </>
            )}
        </Box>
    )
}

export default withErrorBoundary(ManagementSection)
