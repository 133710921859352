import { Button, ModalProps, Stack, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from 'design/templates/Modal'
import Features from 'design/templates/Modal/modalTemplates/TrialOver/Features'
import PromoMedia from 'design/templates/Modal/modalTemplates/TrialOver/PromoMedia'
import { useHandleOpenSubscriptionModal } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SelectPlanStep/hooks'
import { ModalType } from 'design/templates/Modal/ModalTypes/types'

import useMixpanel, { MIXPANEL_EVENTS } from 'hooks/analytics/useMixpanel'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { useEffectTrackEvent } from 'hooks/analytics/useEffectTrackEvent'
import style from './TrialOver.style'

export interface TrialOverProps {
    modalProps?: Pick<ModalProps, 'sx'>
}

export interface TrialOverOpenModalPayload extends TrialOverProps {
    type: ModalType.TRIAL_OVER
}

type TrialOverModalProps = TrialOverProps & {
    onClose: () => void
}

export const TrialOver = ({ modalProps, onClose }: TrialOverModalProps) => {
    const { t } = useTranslation()
    const [isClosing, setIsClosing] = useState(false)

    const { tier: currentTier, data: subscription } = useAccountSubscription()
    const planToUpgrade = subscription?.plan
    const planName = planToUpgrade?.name
    const { track } = useMixpanel()

    const { handleOpenSubscriptionModal } = useHandleOpenSubscriptionModal()

    const handleClose = useCallback(() => {
        setIsClosing(true)
        setTimeout(() => {
            setIsClosing(false)
        }, 250)
    }, [onClose])

    useEffectTrackEvent({
        eventName: MIXPANEL_EVENTS.UPGRADE_TRIAL_OVER_OPENED,
        eventData: {
            oldtier: currentTier,
            newtier: planToUpgrade?.tier,
        },
        condition: Boolean(planToUpgrade?.tier),
    })

    const handleUpgradeClick = useCallback(() => {
        if (planToUpgrade) {
            track(MIXPANEL_EVENTS.UPGRADE_TRIAL_OVER_KEEPPLAN, {
                oldtier: currentTier,
                newtier: planToUpgrade.tier,
            })
            handleOpenSubscriptionModal(planToUpgrade)
        }
        onClose()
    }, [planToUpgrade, handleOpenSubscriptionModal, onClose, track, currentTier])

    return (
        <Modal
            onClose={handleClose}
            sx={modalProps?.sx}
            stackProps={{ sx: [style.modalStack, isClosing && style.closingModalStack] }}
            width="lg"
            open
        >
            <Modal.Header>
                <Stack width={1} sx={style.headerContainer}>
                    <Typography variant="h4" fontWeight={600}>
                        {t('Your {{planName}} Plan Trial Has Ended', { planName })}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {t('Don’t worry, we’ve saved everything you’ve done on your trial')}
                    </Typography>
                </Stack>
            </Modal.Header>
            <Modal.Body sx={style.body}>
                <Stack direction="row" gap={6} alignItems="center">
                    <PromoMedia />
                    <Features planName={planName} features={planToUpgrade?.features} />
                </Stack>
                <Stack direction="row" gap={2} alignItems="center" justifyContent="flex-end">
                    <Button variant="outlined" color="tertiary" onClick={onClose}>
                        {t('Continue with Free Plan')}
                    </Button>
                    <Button variant="contained" color="accent" onClick={handleUpgradeClick}>
                        {t('Keep My {{planName}} Plan', { planName })}
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    )
}
