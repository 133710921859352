import { SxProps } from '@mui/system'
import { Theme, alpha } from '@mui/material/styles'

const root: SxProps<Theme> = (theme: Theme) => ({
    position: 'fixed',
    left: 32,
    bottom: 24,
    width: 280,
    height: 310,
    zIndex: 1200,
    padding: '20px',
    overflow: 'visible',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: alpha(theme.palette.primary.main, 0.3),
})

const body: SxProps<Theme> = () => ({
    position: 'relative',
    width: 1,
    height: 1,
})

const cross: SxProps<Theme> = (theme: Theme) => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    right: -32,
    top: -32,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.divider,
})

const thumbnail: SxProps<Theme> = {
    borderRadius: 4,
    cursor: 'pointer',
    maxHeight: 134,
    objectFit: 'contain',
}

const play: SxProps<Theme> = {
    position: 'absolute',
    left: '50%',
    top: 48,
    transform: 'translateX(-50%)',
    width: 44,
    height: 44,
    borderRadius: '50%',
    border: 'none',
}

const playBtn: SxProps<Theme> = {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
}

export default {
    root,
    body,
    cross,
    thumbnail,
    play,
    playBtn,
}
