import { isEqual as _isEqual } from 'lodash'
import { useEffect, useRef } from 'react'

import useMixpanel, { MIXPANEL_EVENTS } from 'hooks/analytics/useMixpanel'

interface TrackEventConfig {
    eventName: string
    eventData: Record<string, unknown>
    condition?: boolean
}

export const useEffectTrackEvent = (config: TrackEventConfig) => {
    const lastTrackedEvent = useRef<Record<string, unknown> | null>(null)
    const { eventName, eventData, condition = true } = config
    const { track } = useMixpanel()

    useEffect(() => {
        if (!condition) return

        if (!_isEqual(lastTrackedEvent.current, eventData)) {
            lastTrackedEvent.current = eventData
            track(eventName, eventData)
        }
    }, [eventName, eventData, condition])
}

export { MIXPANEL_EVENTS }
