import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
    set as _set,
    map as _map,
    get as _get,
    pick as _pick,
    filter as _filter,
    find as _find,
    reduce as _reduce,
    values as _values,
    range as _range,
    isString as _isString,
} from 'lodash'

import { HelpTooltip } from 'design/atoms/HelpTooltip'
import VideoSelect from 'design/molecules/VideoSelect'
import VideoSelectControlItem from 'design/molecules/VideoSelect/VideoSelectControlItem'
import { useLayout } from 'hooks/utilities/useLayout'
import { VidConditionSegments, VidConditionTargets, Video, VideoGuid, WidthValues } from 'types/Video'
import { useVideoQuery, VideoConditionsOptions } from 'api/queries'
import locale from './VidConditionsForm.locale'
import style from './VidConditionsForm.style'

const MAX_SEGMENTS = 2

enum ConditionVariants {
    width = 'width',
    isReturning = 'isReturning',
    os = 'os',
    language = 'language',
    videoTag = 'videoTag',
    urlParams = 'urlParams',
}

type IsReturningValues = 'true' | 'false'

type VidConditionStateRow = {
    video: VideoGuid | null
    condition: ConditionVariants | null
    target: number | string | null
}

export type VidConditionState = VidConditionStateRow[]

type VidConditionsFormProps = {
    onChange: (value: VidConditionTargets, valid: boolean) => void
    segments?: VidConditionSegments
    fallback: VideoGuid
    disabled?: boolean
    videoConditionOptions: VideoConditionsOptions
}

type Touched = {
    [key: string]: boolean
}

type Condition = {
    label: string
    value: ConditionVariants
    children: {
        label: string
        value: number | string
        conditionId: number | string
        videoTitle?: string
    }[]
}

type KeyByConditionRow = {
    conditionKey: ConditionVariants
    id: number
    label: string
    selectLabel: string
    value: string | number | boolean | null
}
type KeyByConditions = Record<number, KeyByConditionRow>

enum COLS {
    VIDEO,
    CONDITION,
    TARGET,
}

const OPTION_KEYS = [
    ConditionVariants.width,
    ConditionVariants.isReturning,
    ConditionVariants.os,
    ConditionVariants.language,
    ConditionVariants.urlParams,
]

export const URL_PARAM_CONDITION_IDS = _range(64, 69)
export const VIDEO_TAG_CONDITION_IDS = _range(69, 74)

const emptyRow = { video: null, condition: null, target: null }

function keyByConditionOptionList(options: VideoConditionsOptions): KeyByConditions {
    return _reduce(
        _get(options, 'option-list'),
        (acc, value, key) => {
            _map(value?.options, (option) => _set(acc, _get(option, 'id'), { ...option, conditionKey: key }))

            return acc
        },
        {},
    )
}

const segmentsToState = (segments: VidConditionSegments | undefined, keyBy: KeyByConditions): VidConditionState => {
    if (!segments) return [{ ...emptyRow }]

    return _reduce(
        segments,
        (acc: VidConditionState, rules) => {
            _map(rules.rules, (item) => {
                const { conditionId } = item
                let target: number | string = conditionId
                if (VIDEO_TAG_CONDITION_IDS.includes(conditionId)) {
                    target = _get(item, 'videoTag') as string
                } else if (URL_PARAM_CONDITION_IDS.includes(conditionId)) {
                    target = _get(item, 'urlParams') as string
                }

                acc.push({
                    condition: _get(keyBy, [conditionId, 'conditionKey']),
                    video: _get(item, 'videoGuid') as string,
                    target,
                })
            })

            return acc
        },
        [],
    )
}

const stateToVidConditions = (state: VidConditionState, vidConditions: Condition[]) => {
    return _reduce(
        state,
        (acc: VidConditionTargets, { video, condition, target }, index) => {
            const children = vidConditions.find(({ value }) => value === condition)?.children
            const found = _find(children, ({ value }) => value === target)

            if (condition === ConditionVariants.videoTag && found && video) {
                acc.push({
                    target_video_id: video,
                    condition_id: VIDEO_TAG_CONDITION_IDS[index],
                    value: found.value,
                })

                return acc
            }
            if (condition === ConditionVariants.urlParams && target && video) {
                acc.push({
                    target_video_id: video,
                    condition_id: URL_PARAM_CONDITION_IDS[index],
                    value: target,
                })

                return acc
            }

            const conditionId = _get(found, 'conditionId')

            if (found && video && !_isString(conditionId)) {
                acc.push({
                    target_video_id: video,
                    condition_id: conditionId || 0,
                })
            }

            return acc
        },
        [],
    )
}

function transformConditions(options: VideoConditionsOptions): Condition[] {
    return [
        ...Object.entries(_pick(options['option-list'], OPTION_KEYS)).map(([key, value]) => ({
            value: key as ConditionVariants,
            label: value.label,
            children: value.options.map((option) => ({
                label: option.label,
                value: option.id,
                conditionId: option.id,
            })),
        })),
        {
            value: ConditionVariants.videoTag,
            label: options['option-list'].videoTag?.label ?? '',
            children: options['dictionaries']['video-tag'].map((option) => ({
                label: option.title,
                videoTitle: option.videoTitle,
                value: option.id,
                conditionId: `tag_${option.id}`,
            })),
        },
    ]
}

const VidConditionsForm = ({
    onChange,
    fallback,
    segments,
    disabled,
    videoConditionOptions,
}: VidConditionsFormProps) => {
    const vidConditions = useMemo(() => transformConditions(videoConditionOptions), [videoConditionOptions])
    const keyByConditions = useMemo(() => keyByConditionOptionList(videoConditionOptions), [videoConditionOptions])
    const deleteRef = useRef<(HTMLDivElement | null)[]>([])
    const [state, setState] = useState<VidConditionState>([...segmentsToState(segments, keyByConditions)])
    const [touched, setTouched] = useState<Touched>({})
    const [isTypingMap, setIsTypingMap] = useState<Record<number, boolean>>({})
    const { isTablet } = useLayout()
    const { data, isLoading } = useVideoQuery(fallback)

    const duplicates = useMemo(() => {
        const targets = state.map(({ target }) => target)
        return state?.reduce((acc: number[], { target }, index) => {
            const found = _filter(targets, (el) => el && el === target)
            if (found && found?.length > 1) {
                acc.push(index)
            }

            return acc
        }, [])
    }, [state])

    const isStateValid = useMemo(() => {
        // check some attribute in state null
        const allFilled = state?.map((row) => _values(row).some((v) => v === null)).filter((v) => !!v).length === 0

        return allFilled && duplicates?.length === 0
    }, [state, duplicates])

    useEffect(() => {
        onChange(stateToVidConditions(state, vidConditions), isStateValid)
    }, [state, vidConditions])

    useEffect(() => {
        deleteRef.current = deleteRef.current.slice(0, state.length)
        state?.forEach((_, index) => {
            if (!deleteRef.current[index]) {
                deleteRef.current[index] = null
            }
        })
    }, [state])

    const handleChange = useCallback(
        (value: Video, index: number) => {
            _set(state, [index, 'video'], value.guid)
            if (index > 0 && baseCondition) {
                _set(state, [index, 'condition'], baseCondition)
            }
            setState([...state])
        },
        [state],
    )

    const handleChangeCondition = (value: ConditionVariants, index: number) => {
        _set(state, [index, 'condition'], value)
        _set(state, [index, 'target'], null)
        setState([...state])
    }

    const handleChangeTarget = (value: WidthValues | IsReturningValues, index: number) => {
        setState([..._set(state, [index, 'target'], value)])
    }

    const setAllTouched = (max = state?.length, initial = touched) => {
        let current = { ...initial }
        state?.forEach((_, index) => {
            if (index < max) {
                current = {
                    ...current,
                    [`${index}${COLS.VIDEO}`]: true,
                    [`${index}${COLS.CONDITION}`]: true,
                    [`${index}${COLS.TARGET}`]: true,
                }
            }
        })

        setTouched(current)
    }

    const handleAddRow = () => {
        setAllTouched(state.length, {})
        setState([...state, { ...emptyRow }])
    }

    const handleDeleteRow = (index: number) => {
        setAllTouched(state.length, {})
        setState(state.filter((_, i) => index !== i))
    }

    const baseCondition = state.find((item) => item.condition)?.condition || null

    return (
        <Stack spacing={3}>
            <Stack
                direction={isTablet ? 'column' : 'row'}
                alignItems="center"
                gap={2}
                sx={{ opacity: disabled ? 0.6 : 1 }}
            >
                <Stack direction="row" alignItems="center" width={isTablet ? 1 : 'auto'} gap={2}>
                    <Typography variant="caption2" fontWeight="bold">
                        {locale.show}
                    </Typography>
                    {isLoading && <Skeleton variant="rectangular" sx={{ width: 1, height: 42, borderRadius: 3 }} />}
                    {!isLoading && (
                        <Box sx={[style.fallback, ...(disabled ? [style.disabled] : [])]}>
                            <VideoSelectControlItem item={data?.video} />
                        </Box>
                    )}
                </Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography variant="caption2" fontWeight="bold">
                        {locale.byDefault}
                    </Typography>
                    <HelpTooltip title={locale.defaultVideo} />
                </Stack>
            </Stack>

            {state.map(({ video, condition, target }, index: number) => {
                const isVideoError = !disabled && touched?.[`${index}${COLS.VIDEO}`] && !video
                const isConditionError = !disabled && touched?.[`${index}${COLS.CONDITION}`] && !condition
                const isTargetError =
                    !disabled && ((touched?.[`${index}${COLS.TARGET}`] && !target) || duplicates?.includes(index))
                const restricted = state.length > 1 && state?.map(({ condition }) => condition).some((v) => v)
                const conditionsByBase = _filter(vidConditions, ({ value }) => value === baseCondition) || []

                const conditions = restricted ? conditionsByBase : vidConditions

                return (
                    <Stack
                        onMouseEnter={() =>
                            !disabled && deleteRef.current[index]?.style?.setProperty('display', 'block')
                        }
                        onMouseLeave={() =>
                            !isTablet && deleteRef.current[index]?.style?.setProperty('display', 'none')
                        }
                        key={index}
                        direction={isTablet ? 'column' : 'row'}
                        gap={2}
                        sx={{ position: 'relative' }}
                    >
                        {isTablet && <Divider />}
                        <Stack direction="row" alignItems="center">
                            <Typography variant="caption2" sx={{ fontWeight: 'bold', opacity: disabled ? 0.6 : 1 }}>
                                {locale.show}
                            </Typography>
                            <Box sx={style.rowVideo}>
                                <VideoSelect
                                    onChange={(value: Video) => handleChange(value, index)}
                                    onBlur={() => setAllTouched(index, { [`${index}${COLS.VIDEO}`]: true })}
                                    value={video}
                                    disabled={disabled}
                                    error={isVideoError}
                                />
                            </Box>
                        </Stack>
                        <Stack direction={isTablet ? 'column' : 'row'} gap={2}>
                            <Stack direction="row" alignItems="center" sx={style.rowCondition}>
                                <Typography
                                    variant="caption2"
                                    sx={{ fontWeight: 'bold', mr: 2, opacity: disabled ? 0.6 : 1 }}
                                >
                                    {locale.if}
                                </Typography>
                                <FormControl sx={{ width: 1 }} size="small" error={isConditionError}>
                                    {!condition && (
                                        <InputLabel sx={style.label} error={isConditionError}>
                                            {locale.selectAttribute}
                                        </InputLabel>
                                    )}
                                    <Select
                                        onChange={(e) =>
                                            handleChangeCondition(e?.target?.value as ConditionVariants, index)
                                        }
                                        onBlur={() => setAllTouched(index, { [`${index}${COLS.CONDITION}`]: true })}
                                        value={condition || ''}
                                        disabled={disabled || !video}
                                        sx={style.select}
                                        error={isConditionError}
                                    >
                                        {conditions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack direction="row" alignItems="center" sx={style.rowCondition}>
                                <Typography
                                    variant="caption2"
                                    sx={{ fontWeight: 'bold', mr: 2, opacity: disabled ? 0.6 : 1 }}
                                >
                                    =
                                </Typography>
                                <FormControl sx={{ width: 1, position: 'relative' }} size="small">
                                    {!target && !isTypingMap[index] && (
                                        <InputLabel sx={style.label} error={isTargetError}>
                                            {condition === ConditionVariants.urlParams
                                                ? locale.urlParamsValue
                                                : locale.selectValue}
                                        </InputLabel>
                                    )}

                                    {condition === ConditionVariants.urlParams ? (
                                        <TextField
                                            onChange={(e) => handleChangeTarget(e?.target?.value as WidthValues, index)}
                                            onBlur={() => setAllTouched(index, { [`${index}${COLS.TARGET}`]: true })}
                                            value={target || ''}
                                            disabled={disabled || !video || !condition}
                                            sx={style.textField}
                                            error={isTargetError}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        sx={{
                                                            mr: '12px',
                                                            ml: '2px',
                                                        }}
                                                        position={'start'}
                                                    >
                                                        <HelpTooltip
                                                            placement={'bottom'}
                                                            title={locale.urlParamsHelpTooltip}
                                                            sx={{ mt: '1px', mr: '12px' }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    ) : (
                                        <Autocomplete
                                            disableClearable
                                            options={
                                                vidConditions.find(({ value }) => value === condition)?.children ?? []
                                            }
                                            getOptionLabel={(option) => option.label}
                                            // @ts-expect-error
                                            value={
                                                vidConditions
                                                    .find(({ value }) => value === condition)
                                                    ?.children?.find((opt) => opt.value === target) || null
                                            }
                                            onBlur={() => {
                                                setAllTouched(index, { [`${index}${COLS.TARGET}`]: true })
                                                setIsTypingMap((prev) => ({
                                                    ...prev,
                                                    [index]: false,
                                                }))
                                            }}
                                            onChange={(_, newValue) =>
                                                handleChangeTarget(
                                                    newValue?.value as unknown as WidthValues | IsReturningValues,
                                                    index,
                                                )
                                            }
                                            onInputChange={(_event, newInputValue) => {
                                                setIsTypingMap((prev) => ({
                                                    ...prev,
                                                    [index]: !!newInputValue,
                                                }))
                                            }}
                                            renderInput={(params) => (
                                                <TextField sx={style.textField} {...params} error={isTargetError} />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.value}>
                                                    <Box sx={{ maxWidth: 1 }}>
                                                        <Tooltip title={option.label} enterDelay={1000} arrow>
                                                            <Typography noWrap>{option.label}</Typography>
                                                        </Tooltip>
                                                        {condition === ConditionVariants.videoTag &&
                                                            option.videoTitle && (
                                                                <Tooltip
                                                                    title={option.videoTitle}
                                                                    enterDelay={1000}
                                                                    arrow
                                                                >
                                                                    <Typography sx={style.subTitle} noWrap>
                                                                        {option.videoTitle}
                                                                    </Typography>
                                                                </Tooltip>
                                                            )}
                                                    </Box>
                                                </li>
                                            )}
                                            disabled={disabled || !condition}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: 200,
                                                    overflowY: 'auto',
                                                },
                                            }}
                                        />
                                    )}
                                </FormControl>
                            </Stack>
                            <Box
                                ref={(el: HTMLDivElement) => (deleteRef.current[index] = el)}
                                sx={{ display: !isTablet || disabled ? 'none' : 'block' }}
                            >
                                <IconButton
                                    onClick={() => {
                                        handleDeleteRow(index)
                                        setIsTypingMap((prev) => ({
                                            ...prev,
                                            [index]: false,
                                        }))
                                    }}
                                    disabled={disabled}
                                    sx={style.trash}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Stack>
                )
            })}
            <Stack direction="row" justifyContent={isTablet ? 'flex-end' : 'flex-start'}>
                <Button
                    onClick={handleAddRow}
                    disabled={
                        disabled ||
                        state.length >=
                            (vidConditions.find(({ value }) => value === baseCondition)?.children?.length ||
                                MAX_SEGMENTS) ||
                        ([ConditionVariants.videoTag, 'urlParams'].includes(
                            vidConditions.find(({ value }) => value === baseCondition)?.value || '',
                        ) &&
                            state.length >= 5)
                    }
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="secondary"
                    size="xSmall"
                >
                    {locale.addCondition}
                </Button>
            </Stack>
        </Stack>
    )
}

export default VidConditionsForm
