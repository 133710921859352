import { useState, useRef, useEffect, useId, FocusEvent, MouseEvent, useLayoutEffect, CSSProperties } from 'react'
import { Box, SxProps, Theme, Typography, TextField } from '@mui/material'
import classNames from 'classnames'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

import useResizeObserver from 'hooks/utilities/useResizeObserver'
import { InputChangeHandler } from 'types'

import './index.scss'

export type EditableNameProps = {
    initialName?: string
    autoFocusEnabled?: boolean
    className?: string
    style?: SxProps<Theme>
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void
    onLabelClick?: (event: MouseEvent<HTMLLabelElement>) => void
    inputStyle?: CSSProperties
}

export default function EditableName({
    initialName,
    autoFocusEnabled,
    className,
    style,
    onBlur,
    onLabelClick,
    inputStyle = {},
}: EditableNameProps) {
    const id = useId()
    const [name, setName] = useState(initialName)
    const [initedFocus, setInitedFocus] = useState(false)
    const [width, setWidth] = useState(0)
    const inputRef = useRef<HTMLInputElement>(null)

    const resizeHelper = useResizeObserver((div: HTMLPreElement) => setTimeout(() => setWidth(div.offsetWidth)))

    useEffect(() => {
        if (autoFocusEnabled && !initedFocus) {
            inputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
            inputRef?.current?.focus({ preventScroll: true })
            setInitedFocus(true)
        }
    }, [initedFocus, autoFocusEnabled])

    useEffect(() => {
        setName(initialName)
    }, [initialName])

    const handleFolderNameChange: InputChangeHandler = (event) => {
        setName(event.target.value)
    }

    useLayoutEffect(() => {
        if (resizeHelper.current) {
            setWidth(resizeHelper.current.offsetWidth)
        }
    }, [name])

    return (
        <Box className={classNames('EditableName', className)} sx={style}>
            <Typography className="resizeHelper" component="pre" ref={resizeHelper}>
                {name}
            </Typography>

            <TextField
                InputProps={{
                    inputRef,
                }}
                inputProps={{ style: { width, ...inputStyle } }}
                value={name}
                id={id}
                label=""
                onClick={(event) => {
                    if (document.activeElement === inputRef.current) {
                        event.stopPropagation()
                        event.preventDefault()
                    }
                }}
                onChange={handleFolderNameChange}
                onBlur={onBlur}
                variant="standard"
                data-testId="folderName"
            />

            <label
                className="name"
                htmlFor={id}
                onClick={(event) => {
                    inputRef?.current?.focus()
                    onLabelClick?.(event)
                }}
            >
                <EditOutlinedIcon fontSize="small" />
            </label>
        </Box>
    )
}
