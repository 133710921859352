import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Button, Stack } from '@mui/material'

import { route } from 'constants/routes'

import style from './VideosWarning.style'

type VideosWarningProps = {
    onClose: () => void
}

const VideosWarning = ({ onClose }: VideosWarningProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <Stack gap={6}>
            <Stack gap={3}>
                <Typography variant="h7" fontWeight={500}>
                    {t('You must delete some videos before downgrading')}
                </Typography>
                <Box>
                    <Typography color="text.secondary">
                        {t('You currently have more than 3 videos in your account. ')}
                    </Typography>
                    <Typography color="text.secondary">
                        {t("Delete any you don't want to store anymore before you proceed to downgrade. ")}
                    </Typography>
                </Box>
            </Stack>
            <Stack sx={style.actions}>
                <Button variant="outlined" color="tertiary" onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate(route.video.catalog)
                        onClose()
                    }}
                >
                    {t('Go to My Vids')}
                </Button>
            </Stack>
        </Stack>
    )
}

export default VideosWarning
