import { useQuery } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_SWITCHABLE_METRICS_GRAPHS } from 'api/constants'
import {
    GetSwitchableMetricsQuery,
    GetSwitchableMetricsResponse,
} from 'api/contracts/dashboard/endpoints/getSwitchableMetrics.contract'

export const useGetSwitchableMetricsQuery = (query: GetSwitchableMetricsQuery) =>
    useQuery<GetSwitchableMetricsResponse>([DASHBOARD_GET_SWITCHABLE_METRICS_GRAPHS, query], () =>
        dashboardController.getSwitchableMetricsGraphs(query),
    )
