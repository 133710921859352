import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SupportRoundedIcon from '@mui/icons-material/SupportRounded'
import { route } from 'constants/routes'

import locale from './Sidebar.locale'
import menuLocale from './components/UserActions/Menu/Menu.locale'

export const ADDITIONAL_MOBILE_SIDEBAR_ITEMS = [
    {
        label: menuLocale.accountSettings,
        route: route.account.index,
        Icon: SettingsOutlinedIcon,
        isInternal: true,
    },
    { label: locale.help, route: route.static.help.index, Icon: SupportRoundedIcon, isInternal: false },
]

export const IS_GLOBAL_SIDEBAR_OPENED = 'IS_GLOBAL_SIDEBAR_OPENED'
