import { useMemo } from 'react'
import { Box, LinearProgress, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { range as _range } from 'lodash'

import { Link } from 'design/atoms/Link'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import usePlanUsageInfo from 'design/organisms/Account/usePlanUsageInfo'
import { SubscriptionFeatureKey } from 'design/templates/Modal'
import helpArticles from 'constants/help.constants'

import locale from './UsageInfoGrid.locale'
import style from './UsageInfoGrid.style'
import PromoBadge from './PromoBadge'

type Item = {
    title: string | JSX.Element
    tip: JSX.Element | string | null
    content: JSX.Element
    bottom: string | JSX.Element | null
    badge?: {
        display: boolean
        property: string
        highlightFeature?: SubscriptionFeatureKey
    }
}

export const DISPLAY_PROMO_BADGE_PERCENTAGE = 75

const UsageInfoGrid = () => {
    const {
        isLoading,
        isPlayMode,
        isFree,
        isUsageUnlim,
        isSpaceUnlim,
        limit,
        spaceLimit,
        dimension,
        costDimension,
        limitDimension,
        billingPeriod,
        usage,
        usageProgress,
        extra,
        extraDimension,
        extraPrice,
        space,
        spaceProgress,
        extraSpace,
        spaceExtraPrice,
    } = usePlanUsageInfo()

    const getProgressColor = (percent: number) => {
        if (percent < 75) return 'primary'
        if (percent <= 95) return 'warning'
        return 'error'
    }

    const notAvailable = {
        content: (
            <Typography variant="subtitle2" fontWeight="700">
                {locale.notAvailable}
            </Typography>
        ),
        bottom: '-',
    }

    const all = useMemo(
        () => ({
            usage: {
                title: isPlayMode ? locale.playsUsage : locale.bandwidthUsage,
                tip: isPlayMode ? (
                    locale.amountOfPlays(limit, billingPeriod)
                ) : (
                    <Box>
                        {locale.amountOfBandwidth(limit, limitDimension, billingPeriod)}{' '}
                        <Link to={helpArticles.whatIsBandwidth} sx={style.link} target="_blank">
                            {locale.learnMore}
                        </Link>
                    </Box>
                ),
                content: (
                    <Typography variant="h5" fontWeight="600">
                        {usage}
                    </Typography>
                ),
                bottom: isUsageUnlim ? null : (
                    <LinearProgress
                        variant="determinate"
                        color={getProgressColor(usageProgress)}
                        value={usageProgress > 100 ? 100 : usageProgress}
                        sx={style.progress}
                    />
                ),
                badge: {
                    display: isUsageUnlim ? false : usageProgress >= DISPLAY_PROMO_BADGE_PERCENTAGE,
                    property: 'bandwidth',
                    highlightFeature: SubscriptionFeatureKey.BANDWIDTH,
                },
            },
            extra: {
                title: isPlayMode ? locale.extraPlays : locale.extraBandwidth,
                tip: isPlayMode ? null : locale.additionalDataUsage(`$${extraPrice}/${dimension}`),
                content: (
                    <Typography variant="h5" fontWeight="600">
                        {`${extra} ${extraDimension}`}
                    </Typography>
                ),
                bottom: (
                    <Typography color={(theme) => theme.palette.text.secondary}>
                        {`$${extraPrice} / ${costDimension}`}
                    </Typography>
                ),
            },
            storage: {
                title: locale.videoStorage,
                tip: locale.numberOfVideosStored(spaceLimit),
                content: (
                    <Typography variant="h5" fontWeight="600">
                        {space}
                    </Typography>
                ),
                bottom: isSpaceUnlim ? null : (
                    <LinearProgress
                        variant="determinate"
                        color={getProgressColor(spaceProgress)}
                        value={spaceProgress > 100 ? 100 : spaceProgress}
                        sx={style.progress}
                    />
                ),
                badge: {
                    display: isSpaceUnlim ? false : spaceProgress >= DISPLAY_PROMO_BADGE_PERCENTAGE,
                    property: 'storage',
                },
            },
            extraVideos: {
                title: locale.extraVideos,
                tip: locale.additionalVideosStored(spaceExtraPrice),
                content: (
                    <Typography variant="h5" fontWeight="600">
                        {extraSpace}
                    </Typography>
                ),
                bottom: (
                    <Typography color={(theme) => theme.palette.text.secondary}>{`x $${spaceExtraPrice}`}</Typography>
                ),
            },
            skeleton: {
                title: <Skeleton sx={{ width: 1, minWidth: 150, height: 24 }} />,
                tip: null,
                content: <Skeleton width="100%" height={40} />,
                bottom: <Skeleton width="100%" height={24} />,
            },
        }),
        [isPlayMode, usage, usageProgress, space, spaceProgress],
    )

    const dataItems = isFree
        ? [all.usage, { ...all.extra, ...notAvailable }, all.storage, { ...all.extraVideos, ...notAvailable }]
        : [all.usage, all.extra, all.storage, all.extraVideos]

    const loadingItems = _range(0, 4).map(() => all.skeleton)
    const items = isLoading ? loadingItems : dataItems

    return (
        <Box sx={style.root}>
            {items.map((item: Item, index: number) => (
                <Paper key={index} sx={style.paper}>
                    <Stack>
                        <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                            <Typography fontWeight="600">{item.title}</Typography>
                            {item.tip && <HelpTooltip title={item.tip} />}
                        </Stack>
                        <Box sx={{ width: 1, textAlign: 'center', py: 6 }}>{item.content}</Box>
                        <Stack direction="row" alignItems="center" justifyContent="center">
                            {item.bottom}
                        </Stack>
                        {item.badge?.display && (
                            <PromoBadge property={item.badge.property} highlightFeature={item.badge.highlightFeature} />
                        )}
                    </Stack>
                </Paper>
            ))}
        </Box>
    )
}

export default UsageInfoGrid
