import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DriveFileRenameOutlineRounded from '@mui/icons-material/DriveFileRenameOutlineRounded'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import { Box, type ButtonProps } from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import ClosedCaptionsDeleteModal from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsDeleteModal'
import ClosedCaptionsRenameModal from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsRenameModal'
import useClosedCaptionsContent from 'design/organisms/ClosedCaptionsEditor/useClosedCaptionsContent'
import useSvIds from 'design/pages/SmartVid/hooks/useSvIds'

import { useSmartVidPageContext } from 'design/pages/SmartVid/contexts/smartVidPageContext'
import { OverlayContent } from 'design/pages/SmartVid/StepSettings/Sidebar/SidebarContext'
import { CC_FILE_EXT, DEFAULT_FILE_NAME } from 'design/organisms/ClosedCaptionsEditor/constants'
import route, { VideoNavigatеParams } from 'constants/routes'
import locale from './ClosedCaptionsMenu.locale'

type ClosedCaptionsMenuProps = {
    propsButton?: ButtonProps
    noRename?: boolean
    hasHandleBack?: boolean
    onMenuOpenChange?: (isOpen: boolean) => void
}

export const ClosedCaptionsMenu = ({
    propsButton,
    noRename,
    hasHandleBack,
    onMenuOpenChange,
}: ClosedCaptionsMenuProps) => {
    const { smartVidId, videoId } = useSvIds()
    const { isLoading: isContentLoading, name, content } = useClosedCaptionsContent(String(videoId))
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false)
    const { editingDisabled } = smartVidId ? useSmartVidPageContext() : { editingDisabled: false }
    const navigate = useNavigate()

    const open = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget)
        onMenuOpenChange?.(true)
    }, [])

    const close = useCallback(() => {
        setMenuAnchorEl(null)
        onMenuOpenChange?.(false)
    }, [])

    const handleRename = useCallback(() => {
        close()
        setIsRenameModalOpen(true)
    }, [])

    const handleEdit = useCallback(() => {
        close()

        const navigateParams: VideoNavigatеParams = smartVidId
            ? {
                  smartVidId,
                  stepVidId: String(videoId),
                  ...(hasHandleBack ? {} : { overlay: OverlayContent.closedCaptions }),
              }
            : {
                  videoGuid: String(videoId),
                  ...(hasHandleBack ? {} : { overlay: OverlayContent.closedCaptions }),
              }

        const navigateRoute = smartVidId ? route.smartVideo.step : route.video.settings.byId

        navigate(navigateRoute(navigateParams))
    }, [close, hasHandleBack, navigate, route, smartVidId, videoId])

    const handleDownload = useCallback(() => {
        close()

        const fileName = name || `${DEFAULT_FILE_NAME}.${CC_FILE_EXT}`

        if (content) {
            const blob = new Blob([content], { type: 'text/plain' })
            const a = document.createElement('a')
            a.href = window.URL.createObjectURL(blob)
            a.download = fileName
            a.target = '_blank'
            a.click()
            window.URL.revokeObjectURL(a.href)
        }
    }, [isContentLoading, name])

    const handleOpenModal = useCallback(() => {
        close()
        setIsDeleteModalOpen(true)
    }, [])

    return (
        <Box onClick={(e) => e.preventDefault()}>
            <Button
                variant="text"
                color="tertiary"
                size="iconSmall"
                endIcon={<MoreVertRoundedIcon fontSize="medium" color="action" />}
                disabled={!content}
                onClick={open}
                {...propsButton}
            />

            <Menu
                anchorEl={menuAnchorEl}
                open={!!menuAnchorEl}
                onClose={close}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleEdit}>
                    <Stack gap={3} direction="row" component="span" alignItems="center">
                        <EditIcon />
                        <Typography variant="body1">{hasHandleBack ? locale.editClose : locale.editOpen}</Typography>
                    </Stack>
                </MenuItem>

                <MenuItem onClick={handleDownload}>
                    <Stack gap={3} direction="row" component="span" alignItems="center">
                        <DownloadRoundedIcon />
                        <Typography variant="body1">{locale.download}</Typography>
                    </Stack>
                </MenuItem>

                {!noRename && (
                    <MenuItem onClick={handleRename}>
                        <Stack gap={3} direction="row" component="span" alignItems="center">
                            <DriveFileRenameOutlineRounded />
                            <Typography variant="body1">{locale.rename}</Typography>
                        </Stack>
                    </MenuItem>
                )}

                <Divider />

                <MenuItem onClick={handleOpenModal} disabled={editingDisabled}>
                    <Stack gap={3} direction="row" component="span" alignItems="center">
                        <DeleteOutlineRoundedIcon color="error" />
                        <Typography color="error" variant="body1">
                            {locale.delete}
                        </Typography>
                    </Stack>
                </MenuItem>
            </Menu>

            <ClosedCaptionsDeleteModal isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteModalOpen} />
            <ClosedCaptionsRenameModal isOpen={isRenameModalOpen} setIsOpen={setIsRenameModalOpen} name={name || ''} />
        </Box>
    )
}

export default ClosedCaptionsMenu
