import { SubscriptionCtaSource } from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import { SubscriptionCategory } from 'types/Customer'
import { COMMON_CONFIG } from 'constants/subscriptions/lockedModalConfigs/common'

export const vidalyticsAPILockedModalConfig = {
    ...COMMON_CONFIG,
    source: SubscriptionCtaSource.ACCOUNT_SETTINGS,
    planCategory: SubscriptionCategory.premium,
    title: 'Upgrade to Unlock Vidalytics API!',
    description:
        'Connect your favorite third-party tools with Vidalytics to analyze individual video data, automate events to your CRM, manage your videos, update folders, grab embed codes, and more!',
}

export const domainWhitelistingLockedModalConfig = {
    ...COMMON_CONFIG,
    source: SubscriptionCtaSource.ACCOUNT_SETTINGS,
    planCategory: SubscriptionCategory.premium,
    title: 'Upgrade to Unlock Domain Whitelisting!',
    description: 'Keep your video content safe by deciding in which domains it can be played.',
}

export const globalPlayGatesLockedModalConfig = {
    ...COMMON_CONFIG,
    source: SubscriptionCtaSource.ACCOUNT_SETTINGS,
    title: 'Upgrade to Unlock Global Play Gates!',
    description:
        'Simplify viewer experience by smartly eliminating redundancy on your play gates, so your viewers are only requested to opt-in once across all the videos in your account.',
}
