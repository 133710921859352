import { alpha, SxProps, Theme } from '@mui/material'

export default {
    card: (theme) => ({
        height: 108,
        p: 4,
        borderRadius: 4,
        flexShrink: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.divider}`,
        transition: theme.transitions.create(['border-color'], {
            duration: 200,
        }),

        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    }),
    activeCard: (theme) => ({
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    }),
    addCardButton: {
        justifyContent: 'flex-start',
    },
    editButton: {
        mt: 2.5,
        p: 0,
        width: 'fit-content',
    },
    radio: {
        mr: -3,
        mt: -3,
    },
} satisfies Record<string, SxProps<Theme>>
