import { SxProps, Theme } from '@mui/material'

const container: SxProps<Theme> = {
    backgroundColor: (theme) => theme.palette.action.hover,
    border: (theme) => `1px solid ${theme.palette.action.selected}`,
    borderRadius: 4,
    transition: (theme) => theme.transitions.create(['background', 'box-shadow'], { duration: '.2s' }),
    overflow: 'hidden',
    cursor: 'pointer',

    '&:hover': {
        background: (theme) => theme.palette.background.cardsHover,
        boxShadow: (theme) => theme.shadows[4],
    },
}

const isMenuOpen: SxProps<Theme> = {
    background: (theme) => theme.palette.background.cardsHover,
    boxShadow: (theme) => theme.shadows[4],
}

const cardInfo: SxProps<Theme> = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
    py: 3,
    pl: 4,
    pr: 2,
}

const name: SxProps<Theme> = {
    fontWeight: '600',
    maxWidth: 270,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}

const switchForm: SxProps<Theme> = {
    width: '100%',
    justifyContent: 'space-between',
    ml: 0,
}

const info: SxProps<Theme> = {
    p: 4,
    mt: 2,
    mb: 5,
    borderRadius: 4,
    borderColor: 'info.main',
    borderWidth: '1px',
    borderStyle: 'solid',
}

export default {
    container,
    isMenuOpen,
    cardInfo,
    name,
    switchForm,
    info,
}
