import { Form, Formik, FormikProps } from 'formik'
import { useParams } from 'react-router-dom'
import { noop as _noop } from 'lodash'
import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { ChaptersSectionFormData } from 'types/VidSettings'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { VideoDetails } from 'types/Video'
import { useVideoQuery } from 'api/queries'
import { ChaptersContent } from './ChaptersContent'

const ChaptersSection = ({ resetForm }: FormikProps<ChaptersSectionFormData>) => {
    const { videoGuid } = useParams()

    const initForm = (details: VideoDetails) => {
        resetForm({
            values: details.chapters,
        })
    }

    useVideoQuery(String(videoGuid), {
        onSuccess: initForm,
    })

    return (
        <SettingsSectionProvider>
            <Form>
                <ChaptersContent />
            </Form>
        </SettingsSectionProvider>
    )
}

const FormikWrapper = () => {
    return (
        <Formik<ChaptersSectionFormData>
            initialValues={{ time: 0, title: '' }}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={_noop}
        >
            {(props) => <ChaptersSection {...props} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
