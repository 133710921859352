import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { Alert, AlertTitle, Button, IconButton, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { setDisplayBanner } from 'design/organisms/BannerController/banner.slice'
import { useCustomerSubscriptionQuery } from 'api/queries'
import { Link } from 'design/atoms/Link'
import { useAppDispatch } from 'App'
import routes from 'constants/routes'

const DowngradeSuccessMessage = () => {
    const dispatch = useAppDispatch()
    const { data: subscription } = useCustomerSubscriptionQuery()
    const { t } = useTranslation()

    const planNext = subscription?.planNext
    const dateNextBilling = subscription?.dateNextBilling

    return (
        <Alert
            severity="success"
            variant="standard"
            isBanner
            action={
                <Stack direction="row" gap={2} alignItems="center" pl={2}>
                    <Link to={routes.account.index}>
                        <Button variant="outlined" color="tertiary">
                            {t('Open Settings')}
                        </Button>
                    </Link>
                    <IconButton onClick={() => dispatch(setDisplayBanner({ displayDowngradeSuccessMessage: false }))}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Stack>
            }
            sx={{
                justifyContent: 'center',
                gridTemplateColumns: 'unset',
            }}
        >
            <AlertTitle>{t('Your downgrade is confirmed! ')}</AlertTitle>
            {t("You'll be on the {{planName}} plan starting {{date}}. See details in Account Settings.", {
                planName: planNext?.name,
                date: dateNextBilling ? format(new Date(dateNextBilling), 'MMM dd yyyy') : '',
            })}
        </Alert>
    )
}

export default DowngradeSuccessMessage
