// Customer
export const CUSTOMER_QUERY = 'CUSTOMER'
export const PERMISSIONS_QUERY = 'PERMISSIONS'
export const UNATHORIZED_FEATURE_FLAGS_QUERY = 'UNATHORIZED_FEATURE_FLAGS'
export const CUSTOMER_SUBSCRIPTION_QUERY = 'CUSTOMER_SUBSCRIPTION'
export const PLANS_QUERY = 'PLANS'
export const PLAN_PRORATE_QUERY = 'PLAN_PRORATE'
export const INVOICES_QUERY = 'INVOICES'
export const DOWNLOAD_INVOICE_MUTATION = 'DOWNLOAD_INVOICE'
export const CREDIT_CARD_UPDATE_MUTATION = 'CREDIT_CARD_UPDATE'
export const CHARGIFY_CREDIT_CARD_MUTATION = 'CHARGIFY_CREDIT_CARD'
export const SIGN_UP_CARD_UPDATE_MUTATION = 'SIGN_UP_CARD_UPDATE'
export const PAYMENT_PROFILE_QUERY = 'PAYMENT_PROFILE'
export const PAYMENT_PROFILE_DELETE_MUTATION = 'PAYMENT_PROFILE_DELETE'
export const PAYMENT_PROFILE_ACTIVATE_MUTATION = 'PAYMENT_PROFILE_ACTIVATE'
export const PASSWORD_UPDATE_MUTATION = 'PASSWORD_UPDATE'
export const CREATE_PAYMENT_FALLBACK_MUTATION = 'CREATE_PAYMENT_FALLBACK_MUTATION'
export const DELETE_PAYMENT_FALLBACK_MUTATION = 'DELETE_PAYMENT_FALLBACK_MUTATION'
export const WHITELISTED_DOMAINS_QUERY = 'WHITELISTED_DOMAINS'
export const IP_FILTERS_QUERY = 'IP_FILTERS'
export const SET_UPSELL_SHOWN_QUERY = 'SET_UPSELL_SHOWN'
export const USER_API_TOKEN_QUERY = 'USER_API_TOKEN'
export const GENERATE_USER_API_TOKEN_QUERY = 'GENERATE_USER_API_TOKEN'
export const GLOBAL_EMBED_CODE_QUERY = 'GLOBAL_EMBED_CODE'
export const GLOBAL_SETTINGS_QUERY = 'GLOBAL_SETTINGS'
export const GLOBAL_SETTINGS_MUTATION = 'GLOBAL_SETTINGS_UPDATE'
export const SIGN_UP_PLAN_QUERY = 'SIGN_UP_PLAN'

// Auth
export const PLAN_QUERY = 'PLAN'
export const GOOGLE_ACCOUNT = 'GOOGLE_ACCOUNT'
export const EMAIL_VERIFICATION_QUERY = 'EMAIL_VERIFICATION'
export const FORGOT_PASSWORD_QUERY = 'FORGOT_PASSWORD'
export const RESET_PASSWORD_EMAIL_QUERY = 'RESET_PASSWORD_EMAIL'
export const RESET_PASSWORD_QUERY = 'RESET_PASSWORD'
export const PROCESS_PAYMENT_MUTATION = 'PROCESS_PAYMENT'
export const PROCESS_UPSELL_MUTATION = 'PROCESS_UPSELL'
export const REFRESH_TOKEN_QUERY = 'REFRESH_TOKEN_QUERY'
export const CREATE_ACTIVE_CAMPAIGN_CONTACT_MUTATION = 'CREATE_ACTIVE_CAMPAIGN_CONTACT'

// Users Settings
export const SUB_USERS_QUERY = 'SUB_USERS'

// Funnels
export const SMART_VIDS_STEPS_QUERY = 'FUNNEL_BRANCHES'
export const CHOICE_QUERY = 'CHOICE'
export const BRANCH_QUERY = 'BRANCH'
export const VIDEO_BRANCH_QUERY = 'VIDEO_BRANCH'
export const FUNNEL_PUBLISH_QUERY = 'FUNNEL_PUBLISH'
export const UPDATE_FUNNEL_HEADLINE_MUTATION = 'UPDATE_FUNNEL_HEADLINE'
// - Branches
export const STEP_SETTINGS_QUERY = 'STEP_SETTINGS'
export const UPDATE_BRANCH_SETTINGS_QUERY = 'UPDATE_BRANCH_SETTINGS'
export const UPDATE_SMART_VID_GLOBAL_SETTINGS_QUERY = 'UPDATE_SMART_VID_GLOBAL_SETTINGS'

// Smart Vids
export const SMART_VIDS_QUERY = 'SMART_VIDS'
export const UPDATE_SMART_VIDS_MUTATION = 'UPDATE_SMART_VIDS'
export const SMART_VID_DETAILS_QUERY = 'SMART_VID_DETAILS'
export const SMART_VID_EMBED_QUERY = 'SMART_VID_EMBED'
export const SMART_VID_STEP_QUERY = 'SMART_VID_STEP'
export const CREATE_SMART_VID_MUTATION = 'CREATE_SMART_VID'
export const DELETE_SMART_VID_STEP_MUTATION = 'DELETE_SMART_VID_STEP'
export const UPDATE_SMART_VID_STEP_MUTATION = 'UPDATE_SMART_VID_STEP'
export const CREATE_SMART_VID_STEP_MUTATION = 'CREATE_SMART_VID_STEP'
export const DISCARD_SMART_VID_MUTATION = 'DISCARD_SMART_VID_STEP'

// Videos
export const VIDEOS_QUERY = 'VIDEOS'
export const VIDEO_OPTIONS_QUERY = 'VIDEO_OPTIONS'
export const VIDEO_UPLOAD_MUTATION = 'VIDEO_UPLOAD'
export const VIDEO_UPLOAD_BY_LINK_MUTATION = 'VIDEO_UPLOAD_BY_LINK'
export const VIDEO_FOLDERS_QUERY = 'FOLDERS'
export const VIDEO_PUBLISH_QUERY = 'VIDEO_PUBLISH'
export const VIDEO_DUPLICATE_QUERY = 'VIDEO_DUPLICATE'
export const VIDEO_MOVEMENT_QUERY = 'VIDEO_MOVEMENT'
export const VIDEO_EMBED_PREVIEW_QUERY = 'VIDEO_EMBED_PREVIEW'
export const VIDEO_EMBED_QUERY = 'VIDEO_EMBED'
export const CLOSED_CAPTIONS_TEXT = 'CLOSED_CAPTIONS_TEXT'
export const CLOSED_CAPTIONS_DRAFT = 'CLOSED_CAPTIONS_DRAFT'
export const FOLDER_MOVEMENT_QUERY = 'FOLDER_MOVEMENT'
export const DELETE_ENCODING_THUMBNAIL_MUTATION = 'DELETE_ENCODING_THUMBNAIL_MUTATION'
export const VIDEO_SEO_QUERY = 'VIDEOS_SEO_QUERY'
export const VIDEO_CONDITION_OPTIONS_QUERY = 'VIDEO_CONDITION_OPTIONS'
export const VIDEO_SEO_MUTATION = 'VIDEO_SEO_MUTATION'

// Conversions
export const CONVERSIONS_QUERY = 'CONVERSIONS'
export const CONVERSION_EMBED_QUERY = 'CONVERSION_EMBED'

// Stats
export const MOST_RECENT_PLAYED_QUERY = 'MOST_RECENT_PLAYED'
export const TOTAL_PLAYS_QUERY = 'TOTAL_PLAYS'
export const ALL_CONVERSIONS_QUERY = 'ALL_CONVERSIONS'
export const TOP_CONVERTED_VIDEOS_QUERY = 'TOP_CONVERTED_VIDEOS'
export const VIDEO_STATS_QUERY = 'VIDEO_STATS'
export const SMART_VID_STATS_QUERY = 'SMART_VID_STATS'
export const STATS_URL_PARAMS_QUERY = 'STATS_URL_PARAMS'
export const DOWNLOAD_STATS_MUTATION = 'DOWNLOAD_STATS'
export const RESET_VID_STATS_MUTATION = 'RESET_VID_STATS'
export const RESET_SV_VID_STATS_MUTATION = 'RESET_SV_VID_STATS'

// Settings
export const LOAD_SETTINGS_MUTATION = 'LOAD_SETTINGS'
export const DISCARD_SETTINGS_MUTATION = 'DISCARD_SETTINGS'
export const PUBLISH_SETTINGS_MUTATION = 'PUBLISH_SETTINGS'
export const CREATE_CTA_MUTATION = 'CREATE_CTA'
export const UPDATE_CTA_MUTATION = 'UPDATE_CTA'
export const DELETE_CTA_MUTATION = 'DELETE_CTA'
export const UPDATE_THUMBNAIL_MUTATION = 'UPDATE_THUMBNAIL'
export const UPDATE_THUMBNAIL_EXIT_TIME_MUTATION = 'UPDATE_THUMBNAIL_EXIT_TIME'
export const UPDATE_VIDEO_THUMBNAIL = 'UPDATE_VIDEO_THUMBNAIL'
export const DELETE_THUMBNAIL_MUTATION = 'DELETE_THUMBNAIL'
export const UPDATE_CLOSED_CAPTIONS_MUTATION = 'UPDATE_CLOSED_CAPTIONS'
export const POST_CLOSED_CAPTIONS_MUTATION = 'POST_CLOSED_CAPTIONS'
export const EDIT_CLOSED_CAPTIONS_MUTATION = 'EDIT_CLOSED_CAPTIONS'
export const DELETE_CLOSED_CAPTIONS_MUTATION = 'DELETE_CLOSED_CAPTIONS'
export const GENERATE_CLOSED_CAPTIONS_MUTATION = 'GENERATE_CLOSED_CAPTIONS'
export const GENERATE_CLOSED_CAPTIONS_CANCEL_MUTATION = 'GENERATE_CLOSED_CAPTIONS_CANCEL'
export const SMART_VID_TAGS_QUERY = 'SMART_VID_TAGS'
export const UPDATE_SMART_VID_TAGS_MUTATION = 'UPDATE_SMART_VID_TAGS'
export const CREATE_TAG_MUTATION = 'CREATE_TAG_MUTATION'
export const UPDATE_TAG_MUTATION = 'UPDATE_TAG_MUTATION'
export const DELETE_TAG_MUTATION = 'DELETE_TAG_MUTATION'
export const CREATE_PLAY_GATE_MUTATION = 'CREATE_PLAY_GATE_MUTATION'
export const UPDATE_PLAY_GATE_MUTATION = 'UPDATE_PLAY_GATE_MUTATION'
export const DELETE_PLAY_GATE_MUTATION = 'DELETE_PLAY_GATE_MUTATION'
export const REPLACE_VIDEO_MUTATION = 'REPLACE_VIDEO_MUTATION'
export const VID_CONDITIONS_MUTATION = 'VID_CONDITIONS_MUTATION'

// States
export const IS_REDIRECTING = 'IS_REDIRECTING'

// Integrations
export const ANALYTICS_SETTINGS_QUERY = 'ANALYTICS_SETTINGS'
export const ALL_INTEGRATIONS_STATUS_QUERY = 'ALL_INTEGRATIONS_STATUS_QUERY'
export const ZAPPIER_STATUS_QUERY = 'ZAPPIER_STATUS'
export const ZAPPIER_FIELDS_QUERY = 'ZAPPIER_FIELDS'
export const HUBSPOT_STATUS_QUERY = 'HUBSPOT_STATUS'
export const HUBSPOT_VALIDATION_CODE_QUERY = 'HUBSPOT_VALIDATION_CODE'
export const HUBSPOT_LINK_QUERY = 'HUBSPOT_LINK'
export const SEGMENT_STATUS_QUERY = 'SEGMENT_KEY_STATUS'
export const SEGMENT_KEY_QUERY = 'SEGMENT_KEY'
export const HIGH_LEVEL_LINK_QUERY = 'HIGH_LEVEL_LINK_QUERY'
export const HIGH_LEVEL_STATUS_QUERY = 'HIGH_LEVEL_STATUS_QUERY'
export const HIGH_LEVEL_VALIDATION_CODE_QUERY = 'HIGH_LEVEL_VALIDATION_CODE_QUERY'
export const ACTIVE_CAMPAIGN_STATUS_QUERY = 'ACTIVE_CAMPAIGN_STATUS_QUERY'

// Agency
export const AGENCY_CREATE_CLIENT = 'AGENCY_CREATE_CLIENT'
export const AGENCY_UPDATE_CLIENT = 'AGENCY_UPDATE_CLIENT'
export const AGENCY_REMOVE_CLIENT = 'AGENCY_REMOVE_CLIENT'
export const AGENCY_CANCEL_ACCOUNT = 'AGENCY_DELETE_CLIENT'
export const AGENCY_ACCEPT_INVITATION = 'AGENCY_ACCEPT_INVITATION'
export const AGENCY_RESEND_CANCELLATION_EMAIL = 'AGENCY_RESEND_CANCELLATION_EMAIL'
export const AGENCY_REVOKE_CANCEL_ACCOUNT = 'AGENCY_REVOKE_CANCEL_ACCOUNT'
export const AGENCY_REVOKE_ACCESS = 'AGENCY_REVOKE_ACCESS'
export const AGENCY_INVITATION_RESEND = 'AGENCY_INVITATION_RESEND'
export const AGENCY_INVITATION_CANCEL = 'AGENCY_INVITATION_CANCEL'
export const AGENCY_GET_INFO = 'AGENCY_GET_INFO'
export const AGENCY_IS_CLIENT = 'AGENCY_IS_CLIENT'
export const AGENCY_GET_INVITE_INFO = 'AGENCY_GET_INVITE_INFO'
export const AGENCY_GET_CLIENTS = 'AGENCY_GET_CLIENTS'
export const AGENCY_GET_TARIFF_INFO = 'AGENCY_GET_TARIFF_INFO'
export const AGENCY_CLIENT_VALIDATE = 'AGENCY_CLIENT_VALIDATE'

// Dashboard
export const DASHBOARD_GET_SWITCHABLE_METRICS_GRAPHS = 'DASHBOARD_GET_SWITCHABLE_METRICS_GRAPHS'
export const DASHBOARD_GET_PRIMARY_METRICS_CARD = 'DASHBOARD_GET_PRIMARY_METRICS_CARD'
export const DASHBOARD_GET_CTA_ROW_DATA = 'DASHBOARD_GET_CTA_ROW_DATA'
export const DASHBOARD_GET_CONVERSIONS_ROW_DATA = 'DASHBOARD_GET_CONVERSIONS_ROW_DATA'
export const DASHBOARD_GET_TAGS_ROW_DATA = 'DASHBOARD_GET_TAGS_ROW_DATA'
export const DASHBOARD_GET_DISTRIBUTION_METRICS = 'DASHBOARD_GET_DISTRIBUTION_METRICS'
export const DASHBOARD_GET_DISTRIBUTION_BY_DATE = 'DASHBOARD_GET_DISTRIBUTION_BY_DATE'
export const DASHBOARD_GET_TOP_VIDEOS_BY_DATE = 'DASHBOARD_GET_TOP_VIDEOS_BY_DATE'

//Transfer Videos
export const GET_TRANSFER_VIDEOS = 'GET_TRANSFER_VIDEOS'
export const LOGOUT_QUERY = 'LOGOUT_QUERY'
export const GET_TRANSFER_STATUS = 'GET_TRANSFER_STATUS'
export const GET_AUTH_LINK = 'GET_AUTH_LINK'
export const VALIDATE_AUTH = 'VALIDATE_AUTH'
export const START_MIGRATION = 'START_MIGRATION'
export const SAVE_TOKENS = 'SAVE_TOKENS'

//Analytics
export const GROUP_STATS_QUERY = 'GROUP_STATS'
export const METRICS_STATS_QUERY = 'METRICS_STATS'
export const FILTERS_STATS_QUERY = 'FILTERS_STATS'
export const URL_PARAMS_STATS_QUERY = 'URL_PARAMS_STATS'
export const VALUES_URL_PARAM_STATS_QUERY = 'VALUES_URL_PARAM_STATS'
export const DOWNLOAD_ANALYTICS_MUTATION = 'DOWNLOAD_ANALYTICS'
