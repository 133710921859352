import { Button, ButtonProps, Stack, Typography } from '@mui/material'
import { ImgWithErrorHandler } from 'design/atoms/ImgWithErrorHandler'
import { Link, LinkProps } from 'design/atoms/Link'
import { ReactNode } from 'react'
import { ConditionalWrapper } from 'design/molecules/ConditionalWrapper'
import styles from './InfoCard.style'

interface InfoCardProps {
    title: string
    description: ReactNode
    imageSrc: string
    buttonText: string
    linkProps?: LinkProps
    buttonProps?: ButtonProps
}

const InfoCard = ({ title, description, buttonText, imageSrc, linkProps, buttonProps }: InfoCardProps) => {
    return (
        <Stack sx={styles.root}>
            <ImgWithErrorHandler lazy={false} src={imageSrc} sx={{ height: 180 }} />
            <Stack gap={2}>
                <Stack gap={1}>
                    <Typography variant="subtitle2" fontWeight={600}>
                        {title}
                    </Typography>
                    <Typography variant="body2">{description}</Typography>
                </Stack>
                <ConditionalWrapper
                    condition={Boolean(linkProps)}
                    wrapper={(children) => (
                        <Link {...linkProps!} sx={{ alignSelf: 'flex-end' }}>
                            {children}
                        </Link>
                    )}
                >
                    <Button sx={styles.cta} variant="text" color="primary" {...buttonProps}>
                        {buttonText}
                    </Button>
                </ConditionalWrapper>
            </Stack>
        </Stack>
    )
}

export default InfoCard
