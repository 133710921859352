import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useIntercom } from 'react-use-intercom'
import { matchPath, useLocation } from 'react-router-dom'
import { get as _get } from 'lodash'

import useFeatureFlags from 'hooks/system/useFeatureFlags'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { useCustomerQuery } from 'api/queries'
import { useCheckAccess, parseJwt } from 'utils'
import { useLayout } from 'hooks/utilities/useLayout'
import { FeatureGroup } from 'constants/keys/featuresAccess.constants'
import { ACCESS_TOKEN } from 'constants/cookies.constants'
import { route } from 'constants/routes'

export default function useManageIntercom() {
    const [cookies] = useCookies()
    const { intercomUser } = useFeatureFlags()
    const location = useLocation()
    const { data: customer, isLoading: isLoadingCustomer } = useCustomerQuery()
    const { data: subscription, isLoading: isLoadingSubscription } = useAccountSubscription()
    const { update } = useIntercom()
    const isLocked = useCheckAccess(FeatureGroup.Intercom)()
    const isAdmin = _get(parseJwt(cookies[ACCESS_TOKEN]), 'payload.isAdmin') === true
    const { isMobile } = useLayout()
    const pathesToHide = [route.stats.root(), route.stats.detailed(), route.analytics.root()]
    const isPathMatchHidden = pathesToHide.some((route) => matchPath(route, location.pathname))

    const isLoading = isLoadingCustomer || isLoadingSubscription

    useEffect(() => {
        if (customer && intercomUser) {
            update({
                email: customer.email,
                name: customer.firstname + ' ' + customer.lastname,
                userHash: customer.userHashIntercom,
            })
        }
    }, [customer])

    useEffect(() => {
        if (isLoading) {
            return
        }

        if (isAdmin || isLocked || !subscription || !customer) {
            update({ hideDefaultLauncher: true })
        } else {
            if (isMobile && isPathMatchHidden) {
                update({ hideDefaultLauncher: true })
            } else {
                update({ hideDefaultLauncher: false })
            }
        }
    }, [isLoading, isMobile, isPathMatchHidden, isAdmin, isLocked, subscription, customer])

    return null
}
