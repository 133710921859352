import { objectToQueryString } from 'utils/url'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'
import { Id } from 'types'

export const endpoints = {
    auth: {
        signin: '/auth/signin',
    },
    agency: {
        client: {
            getById: (clientId: AgencyClientId) => `/agency-clients/${clientId}`,
            cancelAccount: (clientId: AgencyClientId) => `/agency-clients/${clientId}/account-cancellation`,
            revokeAccountCancellation: (clientId: AgencyClientId) =>
                `/agency-clients/${clientId}/revoke-account-cancellation`,
            tariffInfo: (clientId: AgencyClientId) => `/agency-clients/${clientId}/tariff-info`,
            update: (clientId: AgencyClientId) => `/agency-clients/${clientId}/update`,
            validate: '/agency-clients/validate',
            list: (page?: string, perPage?: string) => `/agency-clients${objectToQueryString({ page, perPage })}`,
        },
        info: '/agencies/my',
        revokeAccess: '/customers/revoke-agency-access',
        invitation: {
            accept: '/agency-clients-invitation-accept',
            cancel: (clientId: AgencyClientId) => `/agency-clients/${clientId}/invitation-cancel`,
            resend: (clientId: AgencyClientId) => `/agency-clients/${clientId}/invitation-resend`,
            getInfo: (token: string) => `/agency-clients/invitation-info?invitationToken=${token}`,
        },
    },
    dashboard: {
        distributionByDate: 'statistic/dashboard/distribution-by-date',
        distributionMetrics: 'statistic/dashboard/distribution-metrics',
        rowData: {
            videos: 'statistic/dashboard/row-data/videos',
            cta: 'statistic/dashboard/row-data/cta',
            conversions: 'statistic/dashboard/row-data/conversions',
            tags: 'statistic/dashboard/row-data/tags',
        },
        primaryMetricsCard: 'statistic/dashboard/primary-metrics-card',
        switchableMetricGraphs: 'statistic/dashboard/switchable-metrics-graphs',
    },
    integrations: {
        customer: {
            google: 'customer/integration/setting/google',
            facebook: 'customer/integration/setting/facebookPixel',
            status: 'customer/integration/setting/change-status',
            analyticsSettings: 'customer/integration/setting',
        },
        hubspot: {
            status: 'hubspot/status',
            link: (redirectUri: string) => `/hubspot/auth/link?redirect_uri=${redirectUri}`,
            delete: 'hubspot/delete',
            verifyValidationCode: (validationCode: string) => `hubspot/auth/validate-code?code=${validationCode}`,
        },
        zapier: {
            status: 'integration/zapier',
            fields: 'integration/zapier/fields',
            field: (fieldName: string) => `integration/zapier/field/${fieldName}`,
        },
        highLevel: {
            link: (redirectUri: string) => `/high-level/auth/link?redirect_uri=${redirectUri}`,
            status: '/high-level/integration/status',
            delete: '/high-level/integration/cancel',
            sendValidationCode: '/high-level/integration',
        },
        segment: {
            key: 'twilio-segment/write-key/show',
            delete: 'twilio-segment/write-key/delete',
            change: 'twilio-segment/write-key/upsert',
        },
        activeCampaign: {
            status: 'customer/integration/active-campaign/status',
            delete: 'customer/integration/active-campaign/deactivate',
        },
    },
    smartVids: {
        list: '/video/smart-vids',
        details: (svId: Id) => `/video/smart-vids/${svId}/all`,
        discard: (svId: Id) => `/video/smart-vids/${svId}/discard`,
        step: (svId: Id, vidId: Id) => `/video/smart-vids/${svId}/step/${vidId}`,
        byId: (svId: Id) => `/video/smart-vids/${svId}`,
        embed: (svId: Id) => `/video/smart-vids/${svId}/embed`,
    },
}

export default endpoints
