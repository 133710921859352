import { CustomerStatus } from 'types/Customer'
import { useCustomerQuery, useCustomerSubscriptionQuery } from 'api/queries'

const useUserStatuses = () => {
    const { data: customer } = useCustomerQuery()
    const { data: subscription } = useCustomerSubscriptionQuery()

    return {
        isUserInactive: [customer?.status, subscription?.status].includes(CustomerStatus.inactive),
        isUserActive: [customer?.status, subscription?.status].includes(CustomerStatus.active),
        isUserOnTrial: [customer?.status, subscription?.status].includes(CustomerStatus.trial),
    }
}

export default useUserStatuses
