import { Box, Stack } from '@mui/material'

import CheckboxForm from 'design/atoms/Checkbox/CheckboxForm/CheckboxForm'
import { Input, Radio, Select } from 'design/atoms/Form'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { DISPLAY_MODE, HTML_SELECTOR, HTML_SELECTOR_TYPE, AUTO_SCROLL } from 'design/pages/VidSettings/constants'
import { SubscriptionCtaSource } from 'design/templates/Modal'
import { VideoCallToActionCustomHTMLSelectorType, VideoCallToActionDisplayMode } from 'types/VideoCallToAction'
import { CallsToActionsSectionFormData } from 'types/VidSettings'
import { useCheckAccess } from 'utils'
import { FeatureGroup, VidSettingsKey } from 'constants/keys/featuresAccess.constants'

import locale from './Placement.locale'

const Placement = () => {
    const {
        isLoading,
        formik: { values, setFieldValue },
    } = useSettingsSectionContext<CallsToActionsSectionFormData>()
    const checkAccess = useCheckAccess(FeatureGroup.VidSettings)

    const placementOptions = [
        {
            ...locale.belowVideo,
            // this value is used for the select, but the checkbox is used to toggle between reserveSpace and expandContainer
            value: VideoCallToActionDisplayMode.reserveSpace,
        },
        {
            ...locale.onTop,
            value: VideoCallToActionDisplayMode.onTop,
        },
        {
            ...locale.customHTML,
            value: VideoCallToActionDisplayMode.customHTML,
            locked: checkAccess(VidSettingsKey.CustomHtml),
        },
    ]

    const SubSection = () => {
        switch (values[DISPLAY_MODE]) {
            case VideoCallToActionDisplayMode.reserveSpace:
            case VideoCallToActionDisplayMode.expandContainer: {
                const checked = values[DISPLAY_MODE] === VideoCallToActionDisplayMode.reserveSpace

                return (
                    <CheckboxForm
                        formControlProps={{
                            sx: { mt: 2 },
                            checked: checked,
                            onChange: (_, value) =>
                                setFieldValue(
                                    DISPLAY_MODE,
                                    value
                                        ? VideoCallToActionDisplayMode.reserveSpace
                                        : VideoCallToActionDisplayMode.expandContainer,
                                ),
                        }}
                        name={DISPLAY_MODE}
                        label={
                            <Stack direction="row" alignItems="center" gap={1}>
                                {locale.reserveSpace}
                                <HelpTooltip
                                    title={checked ? locale.reserveSpaceTooltip : locale.expandContainerTooltip}
                                />
                            </Stack>
                        }
                    />
                )
            }
            case VideoCallToActionDisplayMode.customHTML:
                return (
                    <Box sx={{ ml: 2.5, mt: 3 }}>
                        <Radio
                            name={HTML_SELECTOR_TYPE}
                            className="htmlSelectorType"
                            labelVariant="body2"
                            disabled={isLoading}
                            options={[
                                {
                                    label: (
                                        <Box className="htmlSelectorTypeContainer">
                                            {locale.class}
                                            {values[HTML_SELECTOR_TYPE] ===
                                                VideoCallToActionCustomHTMLSelectorType.class && (
                                                <Input
                                                    name={HTML_SELECTOR}
                                                    variant="outlined"
                                                    placeholder="Type here"
                                                    fullWidth
                                                />
                                            )}
                                        </Box>
                                    ),
                                    value: VideoCallToActionCustomHTMLSelectorType.class,
                                },
                                {
                                    label: (
                                        <Box className="htmlSelectorTypeContainer">
                                            {locale.id}
                                            {values[HTML_SELECTOR_TYPE] ===
                                                VideoCallToActionCustomHTMLSelectorType.id && (
                                                <Input
                                                    name={HTML_SELECTOR}
                                                    variant="outlined"
                                                    placeholder="Type here"
                                                    fullWidth
                                                />
                                            )}
                                        </Box>
                                    ),
                                    value: VideoCallToActionCustomHTMLSelectorType.id,
                                },
                            ]}
                        />
                    </Box>
                )

            default:
                return null
        }
    }

    return (
        <Box className="displayMode">
            <Select
                options={placementOptions}
                name={DISPLAY_MODE}
                selectProps={{
                    value:
                        values[DISPLAY_MODE] === VideoCallToActionDisplayMode.expandContainer
                            ? VideoCallToActionDisplayMode.reserveSpace
                            : values[DISPLAY_MODE],
                    disabled: isLoading,
                }}
                afterChange={(_name, value) => {
                    if (value === VideoCallToActionDisplayMode.customHTML) {
                        setFieldValue(HTML_SELECTOR_TYPE, VideoCallToActionCustomHTMLSelectorType.class)
                    } else {
                        setFieldValue(AUTO_SCROLL, false)
                    }
                }}
                upgradeSource={SubscriptionCtaSource.VID_SETTINGS}
            />

            <Box sx={{ ml: 2.5 }}>{SubSection()}</Box>
        </Box>
    )
}

export default Placement
