import { ReactNode } from 'react'
import { To } from 'react-router-dom'

import { ConditionalWrapper } from 'design/molecules/ConditionalWrapper'
import { Link, LinkProps } from 'design/atoms/Link'

type ConditionalLinkWrapperProps = {
    link?: To
    children: ReactNode
    linkProps?: Omit<LinkProps, 'to' | 'className'>
}

export const ConditionalLinkWrapper = ({ link, linkProps, children }: ConditionalLinkWrapperProps) => (
    <ConditionalWrapper
        condition={Boolean(link)}
        wrapper={(children) => (
            <Link to={link!} {...linkProps}>
                {children}
            </Link>
        )}
    >
        {children}
    </ConditionalWrapper>
)
