import { VideoDetails } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useUpdateVideoNameMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<VideoDetails, { id: string; title: string }>(VIDEOS_QUERY, async ({ id, title }) => {
        return mutate(
            videoAPI.updateVideo(id, {
                video: {
                    title,
                },
                settings: {},
            } as VideoDetails),
            apiLocale.video.renameVideo,
        )
    })
}
