import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { Feature } from 'design/templates/Modal/modalTemplates/SubscriptionModal/Feature'

interface FeaturesProps {
    planName?: string
    features?: string[]
}

const HIDEN_FEATURES = ['Bandwidth', 'Videos', 'Plays']

const Features = ({ planName, features }: FeaturesProps) => {
    const { t } = useTranslation()

    return (
        <Stack gap={4} flex={3}>
            <Typography variant="subtitle1" fontWeight={600}>
                {t('You will no longer have access to the advanced {{planName}} features:', { planName })}
            </Typography>

            <Stack gap={2}>
                {features
                    ?.filter((feature) => !HIDEN_FEATURES.some((hiddenFeature) => feature.includes(hiddenFeature)))
                    .map((feature) => (
                        <Feature
                            key={feature}
                            icon={<CloseRoundedIcon color="error" />}
                            featureTitle={feature}
                            typographyVariant="subtitle2"
                        />
                    ))}
            </Stack>
        </Stack>
    )
}

export default Features
