import { Box, Stack, StackProps, SvgIconProps } from '@mui/material'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'

import amexImg from 'assets/img/card-logo/amex.svg'
import discoverImg from 'assets/img/card-logo/discover.svg'
import maestroImg from 'assets/img/card-logo/maestro.svg'
import mastercardImg from 'assets/img/card-logo/mastercard.svg'
import visaImg from 'assets/img/card-logo/visa.svg'
import { CreditCardType } from 'types/Auth'

const logos = [
    { reg: /^4/, type: CreditCardType.visa, img: visaImg },
    { reg: /^5[1-5]/, type: CreditCardType.mastercard, img: mastercardImg },
    { reg: /^3[47]/, type: CreditCardType.amex, img: amexImg },
    { reg: /^6(?:011|5)/, type: CreditCardType.discover, img: discoverImg },
    { reg: /^(5018|5020|5038|5893|6304|6759|676[1-3])/, type: CreditCardType.maestro, img: maestroImg },
]

type CreditCardLogoProps = {
    number?: string
    type?: CreditCardType
    propsStack?: StackProps
    fallbackProps?: SvgIconProps
}

export const CreditCardLogo = ({ number, type, propsStack, fallbackProps }: CreditCardLogoProps) => {
    const logo = logos.find((log) => (number ? log.reg?.test(number) : log.type === type))?.img

    return (
        <Stack {...propsStack} sx={propsStack?.sx}>
            {logo ? <Box component="img" src={logo} /> : <CreditCardRoundedIcon {...fallbackProps} />}
        </Stack>
    )
}

export default CreditCardLogo
