import { ChangeEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Stack, Slider } from '@mui/material'
import { debounce as _debounce } from 'lodash'

import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import { UI_THEME_STYLE_BORDER_RADIUS } from 'design/pages/VidSettings/constants'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { ThemeSectionFormData } from 'types/VidSettings'
import { Input } from 'design/atoms/Form'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'

export const PLAYER_THEME_STYLE_BORDERRADIUS = {
    MIN: 0,
    MAX: 10,
}

const DEFAULT_BORDER_RADIUS = 0

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const PlayerThemeStyle = ({ isSmartVidGlobalSettings }: Props) => {
    const { t } = useTranslation()

    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )

    const {
        formik: { values, setValues },
        video,
        isLoading,
        onChange,
    } = useSectionContext<ThemeSectionFormData>()

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value.replace(/[^0-9]/g, '')
            let value = ''

            if (newValue !== '') {
                value = Math.min(
                    Math.max(Number(newValue), PLAYER_THEME_STYLE_BORDERRADIUS.MIN),
                    PLAYER_THEME_STYLE_BORDERRADIUS.MAX,
                ).toString()
            }

            setValues({
                ...values,
                [UI_THEME_STYLE_BORDER_RADIUS]: value,
            })
        },
        [values],
    )

    const onChangeDebounced = _debounce(onChange, 1000)

    const handleSlide = useCallback(
        (_: Event, value: number | number[]) => {
            setValues({
                ...values,
                [UI_THEME_STYLE_BORDER_RADIUS]: value,
            })
            onChangeDebounced()
        },
        [values],
    )

    const handleSave = useCallback(() => {
        const value =
            values?.[UI_THEME_STYLE_BORDER_RADIUS] !== ''
                ? values?.[UI_THEME_STYLE_BORDER_RADIUS]
                : video?.settings[UI_THEME_STYLE_BORDER_RADIUS]
        setValues({
            ...values,
            [UI_THEME_STYLE_BORDER_RADIUS]: parseInt(value) || 0,
        })
        onChange()
    }, [values, video])

    return (
        <Section title={t('Style')} indent>
            <Stack direction="row" alignItems="center" gap={5}>
                <Typography variant="body2">{t('Corner Radius')}</Typography>
                <Slider
                    onChange={handleSlide}
                    value={values?.[UI_THEME_STYLE_BORDER_RADIUS] || DEFAULT_BORDER_RADIUS}
                    min={PLAYER_THEME_STYLE_BORDERRADIUS.MIN}
                    max={PLAYER_THEME_STYLE_BORDERRADIUS.MAX}
                    disabled={isLoading}
                />
                <Input
                    fullWidth={false}
                    disabled={isLoading}
                    onBlur={handleSave}
                    onChange={handleChange}
                    name={UI_THEME_STYLE_BORDER_RADIUS}
                    variant="outlined"
                    sx={{ width: 72 }}
                    hideError
                />
            </Stack>
        </Section>
    )
}

export default withErrorBoundary(PlayerThemeStyle)
