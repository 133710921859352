export const ID = 'id'
export const GUID = 'guid'
export const DATA = 'data'

// VIDEO folders
export const ROOT = 'root'
export const MAIN = 'My Vids'

export const CREATE = 'create'

export const SAVE_TIMEOUT = 500

export const RESPONSE_STATUS = {
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    SESSION_EXPIRED: 401,
    EXCESSIVE_DURATION: 412,
    BIG_FILE: 413,
    SERVER_ERROR: 500,
}
