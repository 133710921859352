import { SxProps } from '@mui/material'
import { fontSx } from 'styles/theme/typography'

const CONTENT_HEIGHT = 446

const list: SxProps = {
    p: 0,
    height: CONTENT_HEIGHT,
    overflow: 'auto',
}

const listItem: SxProps = {
    p: 0,
}

const divider: SxProps = {
    ml: 3,
}

const emptyContainer: SxProps = {
    height: CONTENT_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
}

const emptyTitle: SxProps = fontSx.inter.semibold

export default {
    emptyContainer,
    emptyTitle,
    list: {
        root: list,
        item: listItem,
    },
    divider,
}
