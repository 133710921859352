import { Typography } from '@mui/material'
import { get } from 'lodash'
import { useMemo } from 'react'

import { Radio } from 'design/atoms/Form'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import {
    NONE,
    UI_CONTROL_BAR_ALWAYS_SHOW,
    UI_CONTROL_BAR_SEEK_BAR_SHOW,
    UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS,
    UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW,
} from 'design/pages/VidSettings/constants'
import { SubscriptionCtaSource } from 'design/templates/Modal'
import { ControlsSectionFormData } from 'types/VidSettings'
import { FeatureGroup, VidSettingsKey } from 'constants/keys/featuresAccess.constants'
import locale from './SeparatedSeekingBar.locale'

export const seekingBarStyleKeys = [
    UI_CONTROL_BAR_SEEK_BAR_SHOW,
    UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS,
    UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW,
] as const

interface Props {
    isSmartVidGlobalSettings?: boolean
}

export const SeparatedSeekingBar = ({ isSmartVidGlobalSettings }: Props) => {
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )
    const {
        formik: { values, setValues },
        isLoading,
        onChange,
    } = useSectionContext<ControlsSectionFormData>()
    const commonProps = {
        afterChange: onChange,
        disabled: isLoading,
        redesign: true,
        switchProps: { size: 'small' },
    } as const

    const endVideoActions = useMemo(
        () => [
            {
                value: UI_CONTROL_BAR_SEEK_BAR_SHOW,
                ...locale.controls.endVideo.standard,
            },
            {
                value: UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS,
                ...locale.controls.endVideo.revisit,
                featureKey: VidSettingsKey.ReVisitSeekingBar,
            },
            {
                value: UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW,
                ...locale.controls.endVideo.engageBar,
                featureKey: VidSettingsKey.RapidEngageBar,
            },
            {
                value: NONE,
                ...locale.controls.endVideo.none,
            },
        ],
        [],
    )

    const getRadioValue = () =>
        seekingBarStyleKeys.reduce((acc, l) => {
            if (values[l]) {
                return l
            }
            return acc
        }, '') || NONE

    const handleRadioChange = (key?: keyof ControlsSectionFormData) => {
        if (!key || values[key]) {
            return
        }

        setValues({
            ...values,
            ...seekingBarStyleKeys.reduce(
                (acc, k) => ({
                    ...acc,
                    [k]: false,
                }),
                {},
            ),
            ...(key ? { [key]: true } : {}),
        })

        onChange()
    }

    return (
        <Section title={locale.title} indent stackProps={{ gap: 2 }}>
            <SwitchForm
                sx={style.container}
                name={UI_CONTROL_BAR_ALWAYS_SHOW}
                label={<Typography variant="body2">{locale.controls.alwaysShow.label}</Typography>}
                labelPlacement="start"
                tooltipPlacement={SwitchTooltipPlacement.inside}
                helpInfo={locale.controls.alwaysShow.hint}
                {...commonProps}
            />

            <Radio
                labelVariant="body2"
                name="seekingRadioGroup"
                options={endVideoActions}
                value={getRadioValue()}
                controlled
                featureGroup={FeatureGroup.VidSettings}
                {...commonProps}
                onClick={(event) => {
                    if (get(event, 'target.checked')) {
                        handleRadioChange()
                    }
                }}
                onChange={(_, value) => {
                    const key = value as keyof ControlsSectionFormData

                    handleRadioChange(key)
                }}
                upgradeSource={SubscriptionCtaSource.VID_SETTINGS}
            />
        </Section>
    )
}
