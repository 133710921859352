import { SxProps, Theme } from '@mui/material'

const container: SxProps = {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
}

const button: SxProps = { borderRadius: 3, py: 1.25, px: 2, pointerEvents: 'auto' }

const divider: SxProps<Theme> = (theme) => ({
    color: theme.palette.divider,
})

const deleteButton: SxProps<Theme> = (theme) => ({
    color: theme.palette.error.main,
})

export default { container, button, divider, deleteButton }
