export const getRandomArbitrary = (min: number, max: number) => Math.random() * (max - min) + min

export const numberWithCommas = (x: number) => x.toLocaleString('en-US')

export const convertCentsToDollars = (n?: number | string) =>
    Number(n) > 0 || n === 0
        ? (Number(n) / 100).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
          })
        : '$0.00'

export const convertValueToDollars = (n?: number | string) =>
    Number(n) > 0 || n === 0
        ? Number(n).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
          })
        : '$0.00'

export const formatAsPercentage = (number = 0, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
    return new Intl.NumberFormat('default', {
        style: 'percent',
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(number)
}

export const formatAsPercentageOrDefault = (
    value: string | number = 0,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
) => {
    if (typeof value === 'string') {
        return value
    }

    return formatAsPercentage(value, minimumFractionDigits, maximumFractionDigits)
}

/**
 * Output:
 * 1000 => 1,000
 * 987654321 => 987,654,321
 * 1234567890 => 1,234,567,890
 */
export function prettifyNumber(value: number | string, round = 0, delim = ','): string {
    let num: number
    if (typeof value === 'string') {
        num = parseFloat(value?.replace(/,/g, '') || '0')
    } else {
        num = value
    }

    return num.toFixed(round).replace(/\B(?=(\d{3})+(?!\d))/g, delim)
}

/**
 * Output:
 * 950 => 950
 * 1234 => 1.2K
 * 15000 => 15K
 * 1500000 => 1.5M
 * 1500000000 => 1.5B
 */
export function simplifyNumber(value: number): string {
    if (value < 1000) {
        return value.toString()
    } else if (value < 1_000_000) {
        return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    } else if (value < 1_000_000_000) {
        return (value / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'
    } else {
        return (value / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'
    }
}

export const format = (value: string): string => {
    const number = parseFloat(value?.replace(/,/g, ''))

    if (isNaN(number)) {
        return '0'
    }

    const formattedNumber = number.toFixed(number > 1000 ? 0 : 1)

    return formattedNumber.endsWith('.0') ? `${parseFloat(formattedNumber)}` : formattedNumber
}
