import { SxProps, Theme } from '@mui/material'

const title: SxProps<Theme> = {
    gap: 2,
}

const content = (theme: Theme) => ({
    [theme.breakpoints.up('tablet')]: {
        minWidth: 530,
    },
})

export default {
    title,
    content,
}
