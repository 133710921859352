import { ReactNode } from 'react'
import { Box } from '@mui/system'

import useUploadingWarning from 'hooks/system/useUploadingWarning'
import { SubscriptionModal, UpsellBannerModal } from 'design/templates/Modal'
import { useAutoSignOut } from 'hooks/navigation/useAutoSignOut'
import TemporaryMobileBanner from 'design/templates/Layout/TemporaryMobileBanner'
import WelcomeFirstVideoUploaded from 'design/organisms/Welcome/WelcomeFirstVideoUploaded'

import style from './Layout.style'

import './index.scss'

const Layout = ({ children }: { children: ReactNode }) => {
    useUploadingWarning()
    useAutoSignOut()

    return (
        <Box sx={style.app}>
            <TemporaryMobileBanner />
            {children}
            <SubscriptionModal />
            <UpsellBannerModal />
            <WelcomeFirstVideoUploaded />
        </Box>
    )
}
export default Layout
