import { useQuery, UseQueryOptions } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_CTA_ROW_DATA } from 'api/constants'
import { GetCtaRowDataQuery, GetCtaRowDataResponse } from 'api/contracts/dashboard/endpoints/getCtaRowData.contract'

export const useGetCtaRowDataQuery = (query: GetCtaRowDataQuery, options?: UseQueryOptions<GetCtaRowDataResponse>) =>
    useQuery<GetCtaRowDataResponse>(
        [DASHBOARD_GET_CTA_ROW_DATA, query],
        () => dashboardController.getCtaRowData(query),
        options,
    )

export default useGetCtaRowDataQuery
