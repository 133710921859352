import { Suspense } from 'react'
import { Routes as ReactRoutes, Route, Navigate, useLocation } from 'react-router-dom'

import lazyImport from 'utils/renderers/lazyImport'
import { ScreenLoader } from 'design/atoms/ScreenLoader'

import { route } from 'constants/routes'

const SignIn = lazyImport(() => import('design/pages/SignIn/SignIn'))
const MicrosoftAuth = lazyImport(() => import('design/molecules/MicrosoftAuth'))
const SignUp = lazyImport(() => import('design/pages/SignUp/SignUp'))
const ForgotPassword = lazyImport(() => import('design/pages/ForgotPassword/ForgotPassword'))
const ResetPassword = lazyImport(() => import('design/pages/ResetPassword/ResetPassword'))

function RoutesAuth() {
    const location = useLocation()

    return (
        <Suspense fallback={<ScreenLoader />}>
            <ReactRoutes>
                <Route
                    path={route.all}
                    element={<Navigate to={route.auth.signIn} state={{ from: location }} replace />}
                />
                <Route path={route.auth.signIn} element={<SignIn />} />
                <Route path={route.auth.microsoftAuth} element={<MicrosoftAuth />} />
                <Route path={route.auth.signUp.index} element={<SignUp />} />
                <Route path={route.auth.signUp.withPlan()} element={<SignUp />} />
                <Route path={route.auth.forgotPassword} element={<ForgotPassword />} />
                <Route path={route.auth.resetPassword.index} element={<ResetPassword />} />
                <Route path={route.auth.resetPassword.withToken()} element={<ResetPassword />} />
                <Route path={route.plan.upgrade.index} element={<SignUp upsellUpgrade />} />
                <Route path={route.plan.upgrade.byToken()} element={<SignUp upsellUpgrade />} />
            </ReactRoutes>
        </Suspense>
    )
}

export default RoutesAuth
