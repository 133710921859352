import { subDays } from 'date-fns'
import { createSlice, type PayloadAction, createSelector } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { StatsDateRangeState, SetDateRangePayload, StatsDateRange } from './types'

const initialState: StatsDateRangeState = {
    dates: [subDays(new Date(), 29), new Date()],
    secondaryDateRange: null,
}

export const statsDateRangeSlice = createSlice({
    name: 'statsDateRange',
    initialState,
    reducers: {
        setDateRange: (state, action: PayloadAction<SetDateRangePayload>) => {
            const dateOrAction = action.payload

            if (typeof dateOrAction === 'function') {
                state.dates = dateOrAction(state.dates)
            } else {
                state.dates = dateOrAction
            }
        },
        setSecondaryDateRange: (state, action: PayloadAction<SetDateRangePayload>) => {
            const dateOrAction = action.payload

            if (typeof dateOrAction === 'function') {
                state.secondaryDateRange = dateOrAction(state.secondaryDateRange)
            } else {
                state.secondaryDateRange = dateOrAction
            }
        },
        clearSecondaryDateRange: (state) => {
            state.secondaryDateRange = null
        },
    },
})

export const { setDateRange, setSecondaryDateRange, clearSecondaryDateRange } = statsDateRangeSlice.actions

export const selectStatsDateRangeRaw = (state: RootState) => {
    const dates = state.statsDateRange.dates

    return [new Date(dates[0]), new Date(dates[1])] as StatsDateRange
}
export const selectStatsDateRange = createSelector([selectStatsDateRangeRaw], (dateRange) => dateRange)

export const selectStatsSecondaryDateRange = (state: RootState) => {
    const dates = state.statsDateRange.secondaryDateRange

    return dates ? ([new Date(dates[0]), new Date(dates[1])] as StatsDateRange) : null
}

export * from './types'

export const statsDateRange = statsDateRangeSlice.reducer
