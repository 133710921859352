import { useCallback, useMemo } from 'react'

import { useCustomerQuery } from 'api/queries'

export const VID_4566 = 'VID-4566-plan-reactivation'
export const VID_4622 = 'VID-4622-funnel-embed-code-api-change'
export const VID_4846 = 'VID-4846-hide-inherit-styles-and-jump-back'
export const VID_4917 = 'VID-4917-agency-accounts'
export const VID_4797 = 'VID-4797-video-length-limit'
export const VID_6050 = 'VID-6050-high-level-integration'
export const VID_6290 = 'VID-6290-segment-integration'
export const VID_4927 = 'VID-4927-timed-exit-cta'
export const VID_5597 = 'VID-5597-Captions-Improvements'
export const VID_7329 = 'VID-7329-Caption-Search'
export const VID_7670 = 'VID-7670-disable-new-closed-caption-settings'
export const VID_7425 = 'VID-7425-add-button-to-change-the-light-dark-mode'
export const VID_5175 = 'upsell_banner'
export const VID_7609 = 'VID-7609-lower-bandwidth-usage-setting'
export const VID_8547 = 'VID-8547-add-playgate-checkbox'
export const VID_8519 = 'vid-8519-support-multiple-payment-methods'
export const VID_9463 = 'VID-9463-allow-combining-seeking-bar-settings'
export const VID_8440 = 'vid-8440-close-account-flow-doesnt-work-in-application-when-audience-is-set-as-users'
export const VID_5988 = 'VID-5988-SV-Create-New-SV'
export const VID_7046 = 'VID-7046-autoplay-on-return-vid-settings'
export const VID_5550 = 'VID-5550-add-global-script-to-account-settings'
export const VID_6410 = 'VID-6410-add-sv-analytics-mode'
export const VID_5416 = 'VID-5416-fe-add-ability-to-customize-columns-on-stats-page'
export const VID_8102 = 'VID-8102-discard-sv-changes'
export const VID_7344 = 'VID-7344-hide-resume-play'
export const VID_8474 = 'VID-8474-sv-analytics'
export const VID_8779 = 'VID-8779-open-link'
export const VID_8780 = 'VID-8780-go-back-start-over'
export const VID_9235 = 'VID-9235-clickable-area'
export const VID_10089 = 'vid-10089-app-implement-play-gate-email-validation-section-on-account-settings'
export const VID_6665 = 'VID-6665-coupons'
// Video thumbnails
export const VID_7449 = 'VID-7449-video-thumbnails'
export const VID_9099 = 'VID-9099-Thumbnail-another-video'
// Under construction Smart Vids 2
export const VID_8106 = 'VID-8106-under-construction'
export const VID_7932 = 'VID-7932-Analytics'
export const VID_7613 = 'VID-7613-imported-from'
export const VID_8874 = 'VID-8874-new-upload'
export const VID_9402 = 'VID-940-vid-conditions-2'
export const VID_9321 = 'VID-9321-fix-selection-ui-for-premium-tier'
export const VID_9412 = 'VID-9412-dashboard-top-videos'
export const VID_5669 = 'VID-5669-chapters'
export const VID_9535 = 'VID-9535-show-google-drive-import-btn'
export const VID_8840 = 'VID-8840-global-settings-starter-plan'
export const VID_8844 = 'VID-8844-sidebar-upgrade-ico-starter-plan'
export const VID_8509 = 'VID-8509-users-limit-on-premium'
export const VID_9034 = 'VID-9034-vid-analytics-starter'
export const VID_8608 = 'VID-8608-video-info'
export const VID_8879 = 'VID-8879-focused-fullscreen'
export const VID_6423 = 'VID-6423-stats-timeline'
export const VID_9362 = 'VID-9362-auto-scroll-cta'
export const VID_9621 = 'VID-9621-accessibility-tooltips'
export const VID_7621 = 'VID-7621-sv-time-tag'
export const VID_6512 = 'vid-6512-redesign-vids-list'
export const VID_9991 = 'vid-9991-add-settings-for-themes'
export const VID_8903 = 'VID-8903-stats-analytics-home'

// Vid Stats: Compare Period
export const VID_8806 = 'VID-8806-vid-stats-compare-period-baseline'

export const VID_8409 = 'VID-8409-fresh-upgrade-flow'
export const VID_9728 = 'VID-9728-new-plan-select-modal'
export const VID_9839 = 'VID-9839-develop-change-ui-for-locked-pages'
export const VID_9729 = 'vid-9729-new-welcome-popup-for-upgrades'
export const VID_10181 = 'VID-10181-sv-replace-video-ui'
export const VID_8444 = 'VID-8444-trial-auto-downgrade-after-trial-when-user-has-no-card'
export const VID_8413 = 'VID-8413-update-change-plan-ui-flow'

// Multi Pause Thumbnails
export const VID_6178 = 'VID-6178-multiple-exit-thumbnails'

export const VID_8745 = 'vid-8745-add-new-segment-by-affiliate-to-stats-page'

const useFeatureFlags = () => {
    const { data: customerFlags } = useCustomerQuery()

    const featureFlags = useMemo(() => customerFlags?.featureFlags || {}, [customerFlags])

    const isAllEnabled = Boolean(process.env.REACT_APP_FF_ENABLE_ALL)

    // flag is enabled if it is received from the backend in customerFlags
    // or if all are enabled via environment var REACT_APP_FF_ENABLE_ALL
    // or if it is enabled via specific environment var REACT_APP_FF_ENABLE_XXX_FLAG_NAME_XXX
    const isFlagEnabled = useCallback(
        (flag: string) =>
            Boolean(featureFlags[flag]) || isAllEnabled || Boolean(process.env?.[`REACT_APP_FF_ENABLE_${flag}`]),
        [featureFlags],
    )

    return {
        featureFlags,
        isFlagEnabled,
        planReactivation: isFlagEnabled(VID_4566),
        funnelEmbedCodeApiChange: isFlagEnabled(VID_4622),
        hideISandJB: isFlagEnabled(VID_4846),
        highLevelCardIntegration: isFlagEnabled(VID_6050),
        agencyAccounts: isFlagEnabled(VID_4917),
        videoLengthLimit: isFlagEnabled(VID_4797),
        segmentCardIntegration: isFlagEnabled(VID_6290),
        timedExitCTA: isFlagEnabled(VID_4927),
        createNewSV: isFlagEnabled(VID_5988),
        vidConditions2: isFlagEnabled(VID_9402),
        captionSearch: isFlagEnabled(VID_7329),
        disableNewCaptionSettings: isFlagEnabled(VID_7670),
        showAutoplayOnReturn: isFlagEnabled(VID_7046),
        showDarkLightModeToggle: isFlagEnabled(VID_7425),
        showGlobalScript: isFlagEnabled(VID_5550),
        showSVAnalyticsMode: isFlagEnabled(VID_6410),
        upsellBanner: isFlagEnabled(VID_5175),
        lowerBandwidthUsage: isFlagEnabled(VID_7609),
        hideResumePlay: isFlagEnabled(VID_7344),
        columnsOnStats: isFlagEnabled(VID_5416),
        couponFlag: isFlagEnabled(VID_6665),
        multiplePaymentMethods: isFlagEnabled(VID_8519),
        combineSeekBarSettings: isFlagEnabled(VID_9463),
        addPlayGateCheckbox: isFlagEnabled(VID_8547),
        videoThumbnails: isFlagEnabled(VID_7449),
        svUnderConstruction: isFlagEnabled(VID_8106),
        svDiscardChanges: isFlagEnabled(VID_8102),
        analyticsPage: isFlagEnabled(VID_7932),
        svAnalytics: isFlagEnabled(VID_8474),
        isImportedFrom: isFlagEnabled(VID_7613),
        newUpload: isFlagEnabled(VID_8874),
        chaptersEnabled: isFlagEnabled(VID_5669),
        svOpenlink: isFlagEnabled(VID_8779),
        goBackStartOver: isFlagEnabled(VID_8780),
        hasClickableArea: isFlagEnabled(VID_9235),
        focusedFullscreenSection: isFlagEnabled(VID_8879),
        statsTimeline: isFlagEnabled(VID_6423),
        autoScrollCTA: isFlagEnabled(VID_9362),
        accessibilityTooltips: isFlagEnabled(VID_9621),
        selectionUIFixForPremiumTier: isFlagEnabled(VID_9321),
        showGoogleDriveImportBtn: isFlagEnabled(VID_9535),
        groupStatistic: isFlagEnabled(VID_8903),
        globalSettingsStarterPlan: isFlagEnabled(VID_8840),
        sidebarUpgradeIconStarterPlan: isFlagEnabled(VID_8844),
        usersLimitOnPremium: isFlagEnabled(VID_8509),
        vidAnalyticsStarter: isFlagEnabled(VID_9034),
        videoInfo: isFlagEnabled(VID_8608),
        dashboardTopVideos: isFlagEnabled(VID_9412),
        svTimeTags: isFlagEnabled(VID_7621),
        showComparePeriod: isFlagEnabled(VID_8806),
        freshUpgradeFlow: isFlagEnabled(VID_8409),
        newPlanSelectModal: isFlagEnabled(VID_9728),
        vidsListNew: isFlagEnabled(VID_6512),
        lockedPagesFF: isFlagEnabled(VID_9839),
        intercomUser: isFlagEnabled(VID_8440),
        otherVideoThumbnail: isFlagEnabled(VID_9099),
        newWelcomePopupForUpgrades: isFlagEnabled(VID_9729),
        playerThemesSettings: isFlagEnabled(VID_9991),
        multipleExitThumbnails: isFlagEnabled(VID_6178),
        newSegmentAffiliate: isFlagEnabled(VID_8745),
        svReplaceVideoUI: isFlagEnabled(VID_10181),
        playGateEmailValidation: isFlagEnabled(VID_10089),
        trialAutoDowngradeAfterTrialWhenUserHasNoCard: isFlagEnabled(VID_8444),
        updateChangePlanUIFlow: isFlagEnabled(VID_8413),
    }
}

export default useFeatureFlags
