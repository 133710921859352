import { SxProps, Theme } from '@mui/material'

const root: SxProps<Theme> = {
    display: 'flex',
    minHeight: 205,
    flexDirection: 'column',
    justifyContent: 'space-around',
    position: 'relative',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 4,
    py: 6,
}

const cancelBtn: SxProps<Theme> = {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'text.disabled',
    mt: 4,
}

const tooltip: SxProps = {
    position: 'absolute',
    top: 16,
    right: 16,
}

export default {
    root,
    cancelBtn,
    tooltip,
}
