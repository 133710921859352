import { Box } from '@mui/material'

import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import AccordionMenu from 'design/organisms/VidSettingsSidebar/AccordionMenu/AccordionMenu'

import usePlaybackSettingsMenu from './usePlaybackSettingsMenu'

const PlaybackSettings = () => {
    const menu = usePlaybackSettingsMenu()

    return (
        <Box className="PlaybackSettings">
            <AccordionMenu menu={menu} />
        </Box>
    )
}

export default withErrorBoundary(PlaybackSettings)
