export default function getCookiesAsArray() {
    const res = []
    const cookies = document.cookie.split('; ')

    for (const cookie of cookies) {
        const [key, value] = cookie.split('=')
        res.push({ key, value: decodeURIComponent(value) })
    }

    return res
}
