import { useEffect, useMemo } from 'react'

import { useAppSelector } from 'App'
import { selectAccount } from 'design/organisms/Account/account.slice'
import { useCustomerQuery, useCustomerSubscriptionQuery } from 'api/queries'
import { BillingMode, CustomerStatus, CustomerSubscriptionPlanTier } from 'types/Customer'
import { format, getBillingPeriod, numberWithCommas, transformMB } from 'utils'
import { AGENCY_CLIENT_ID } from 'constants/local-storage.constants'

type PlanUsageInfoProps = {
    refetch?: (cb: () => void) => void
}

function usePlanUsageInfo(options?: PlanUsageInfoProps) {
    const { isAgency } = useAppSelector(selectAccount)
    const {
        data: subscription,
        isLoading: isSubscriptionLoading,
        refetch: refetchSubscription,
    } = useCustomerSubscriptionQuery()
    const { data: customer, isLoading: isCustomerLoading, refetch: refetchCustomer } = useCustomerQuery()
    const { plan, planNext } = subscription || {}

    useEffect(() => {
        options?.refetch?.(() => {
            refetchSubscription()
            refetchCustomer()
        })
    }, [options, refetchSubscription, refetchCustomer])

    const bandwidthUsed = useMemo(() => {
        return transformMB(subscription?.bandwidthUsedInMB)
    }, [subscription])

    const bandwidthOverlay = useMemo(() => {
        return transformMB(subscription?.bandwidthOverageInMB, false)
    }, [subscription])

    const bandwidthLimit = useMemo(() => {
        return transformMB(subscription?.bandwidthLimitInMB)
    }, [subscription])

    const isBandWidthMode = subscription?.plan.billingMode === BillingMode.bandWidth
    const isPlayMode = subscription?.plan?.billingMode === BillingMode.play
    const isFree = Boolean(subscription?.plan?.isFree)
    const isFreeTier = subscription?.plan.tier === CustomerSubscriptionPlanTier.free
    const isTrial = customer?.status === CustomerStatus.trial

    const used = format(`${(isPlayMode ? subscription?.playUsed : bandwidthUsed.value) || 0}`)
    const usedOrigin = format(`${(isPlayMode ? subscription?.playUsed : bandwidthUsed.origin) || 0}`)
    const limit = format(`${(isPlayMode ? subscription?.playLimit : bandwidthLimit.value) || 0}`)
    const limitOrigin = format(`${(isPlayMode ? subscription?.playLimit : bandwidthLimit.origin) || 0}`)
    const spaceUsed = format(`${subscription?.videoSpaceUsed || 0}`)
    const spaceLimit = format(`${subscription?.plan?.videoSpace || 0}`)
    const coupons = subscription?.plan?.coupons
    const isDiscountApplied = parseFloat(coupons?.totalDiscount || '0') > 0

    const price = parseFloat(format(planNext?.price || plan?.price || '0'))
    const totalDiscount = coupons?.nextDiscount || '0'
    const extraBandwidthCost =
        (plan?.extraBandwidthPrice || 0) * parseFloat(subscription?.bandwidthOverage.replaceAll(',', '') || '0')
    const extraPlaysCost = (plan?.extraPlaysPrice || 0) * (subscription?.playOverage || 0)
    const videoSpaceCost = (plan?.extraVideoSpacePrice || 0) * (subscription?.videoSpaceOverage || 0)
    const costWithoutDiscount = price + extraBandwidthCost + extraPlaysCost + videoSpaceCost
    const costTotal = parseFloat(`${costWithoutDiscount - parseFloat(totalDiscount) || 0}`)
    const isUsageUnlim = !(+limit >= 0)
    const isSpaceUnlim = !(+spaceLimit >= 0)

    return {
        isLoading: isSubscriptionLoading || isCustomerLoading,
        subscription,
        plan,
        customer,
        coupons,
        totalDiscount,

        isBandWidthMode,
        isPlayMode,
        isFree,
        isFreeTier,
        isTrial,
        isAgency: isAgency && !localStorage.getItem(AGENCY_CLIENT_ID),
        isDiscountApplied,
        isUsageUnlim,
        isSpaceUnlim,

        billingPeriod: getBillingPeriod(plan?.billingPeriod),
        trialDaysLeft: subscription?.daysTillPeriodEnds,
        used,
        limit: isUsageUnlim ? 'Unlim' : limit,
        spaceUsed,
        spaceLimit: isSpaceUnlim ? 'Unlim' : spaceLimit,
        dimension: isPlayMode ? '' : 'GB',
        costDimension: isPlayMode ? 'Play' : ' GB',
        usage: isUsageUnlim
            ? `${used} ${isPlayMode ? '' : bandwidthUsed.dimension} / Unlim`
            : `${used} ${isPlayMode ? '' : bandwidthUsed.dimension} / ${limit} ${
                  isPlayMode ? '' : bandwidthLimit.dimension
              }`,
        limitDimension: isPlayMode ? '' : bandwidthLimit.dimension,
        usageProgress: limit !== '0' ? (parseFloat(usedOrigin) / parseFloat(limitOrigin)) * 100 : 0,
        extra: numberWithCommas(
            parseFloat(format(`${(isPlayMode ? subscription?.playOverage : bandwidthOverlay.value) || 0}`)),
        ),
        extraDimension: `${isPlayMode ? '' : bandwidthOverlay.dimension}`,
        extraPrice: `${
            (isPlayMode ? subscription?.plan?.extraPlaysPrice : subscription?.plan?.extraBandwidthPrice) || 0
        }`,
        space: isSpaceUnlim ? `${spaceUsed} / Unlim` : `${spaceUsed} / ${spaceLimit}`,
        spaceProgress: spaceLimit !== '0' ? (parseFloat(spaceUsed) / parseFloat(spaceLimit)) * 100 : 0,
        extraSpace: format(`${subscription?.videoSpaceOverage || 0}`),
        spaceExtraPrice: `${subscription?.plan?.extraVideoSpacePrice || 0}`,

        price,
        extraBandwidthCost,
        extraPlaysCost,
        videoSpaceCost,
        costWithoutDiscount,
        costTotal,
    }
}

export default usePlanUsageInfo
