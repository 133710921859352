import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type RootState } from 'App'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'
import { IS_GLOBAL_SIDEBAR_OPENED } from 'design/templates/Sidebar'

type RefreshingTokenPayload = {
    isTokenRefreshing: boolean
}

type AccountState = {
    isTokenRefreshing: boolean
    isAgency: boolean
    isSidebarOpened: boolean
    managedClientId?: AgencyClientId
}

const initialState: AccountState = {
    isTokenRefreshing: false,
    isAgency: false,
    isSidebarOpened: Boolean(localStorage.getItem(IS_GLOBAL_SIDEBAR_OPENED)),
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setTokenRefreshing: (state, action: PayloadAction<RefreshingTokenPayload | undefined>) => {
            const newState = state as AccountState
            newState.isTokenRefreshing = action.payload?.isTokenRefreshing || false
        },
        assignAgencyAccountStatus: (state) => ({ ...state, isAgency: true }),
        clearAgencyAccountStatus: (state) => ({ ...state, isAgency: false }),
        actAsClient: (state, { payload }: PayloadAction<{ clientId: AgencyClientId }>) => ({
            ...state,
            managedClientId: payload.clientId,
        }),
        stopActingAsClient: (state) => ({ ...state, managedClientId: undefined }),
        setIsSidebarOpened: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            isSidebarOpened: payload,
        }),
    },
})

export const {
    setTokenRefreshing,
    assignAgencyAccountStatus,
    clearAgencyAccountStatus,
    actAsClient,
    stopActingAsClient,
    setIsSidebarOpened,
} = accountSlice.actions

export const selectAccount = (state: RootState) => ({
    ...state.account,
    isAgencyAcc: state.account.isAgency && !state.account.managedClientId,
})

export const account = accountSlice.reducer
