import { SxProps, Theme } from '@mui/material'

const title: SxProps = {
    pt: 5,
}

const container: SxProps<Theme> = {
    minHeight: '100vh',
}

const offset: SxProps<Theme> = {
    px: 6,
}

const main: SxProps = {
    flexGrow: 1,
}

export default {
    title,
    container,
    offset,
    main,
}
