import { useQuery, UseQueryOptions } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_TAGS_ROW_DATA } from 'api/constants'
import { GetTagsRowDataQuery, GetTagsRowDataResponse } from 'api/contracts/dashboard/endpoints/getTagsRowData.contract'

export const useGetTagsRowDataQuery = (query: GetTagsRowDataQuery, options?: UseQueryOptions<GetTagsRowDataResponse>) =>
    useQuery<GetTagsRowDataResponse>(
        [DASHBOARD_GET_TAGS_ROW_DATA, query],
        () => dashboardController.getTagsRowData(query),
        options,
    )

export default useGetTagsRowDataQuery
