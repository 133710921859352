import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material'
import { ReactNode } from 'react'

import { UpgradeBadgeTooltipProps } from 'design/molecules/UpgradeBadge/UpgradeBadgeTooltip/UpgradeBadgeTooltip'
import UpgradeBadgeTooltip from 'design/molecules/UpgradeBadge/UpgradeBadgeTooltip'

export type SupportedBtnSize = Extract<MuiButtonProps['size'], 'medium' | 'small' | 'xSmall'>

export type ButtonProps = {
    label: ReactNode
    size?: SupportedBtnSize
    tooltipProps?: Omit<UpgradeBadgeTooltipProps, 'children'>
}

export interface Props extends ButtonProps {
    buttonProps: Omit<MuiButtonProps, 'size'>
}

const Button = ({ size = 'small', label, tooltipProps, buttonProps }: Props) => {
    return (
        <UpgradeBadgeTooltip placement="top" {...tooltipProps}>
            <MuiButton {...buttonProps} size={size}>
                {label}
            </MuiButton>
        </UpgradeBadgeTooltip>
    )
}

export default Button
