import { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { Box, Divider, Grid, Typography, Skeleton, Button, TypographyProps } from '@mui/material'

import CurrentPlanArrow from 'assets/img/freemium/arrows/current-plan-arrow.svg'
import useUserStatuses from 'hooks/user/useUserStatuses'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { trackAppCuesEvent, APPCUES_EVENTS } from 'thirdPartyServices/appCues'
import { Plan as PlanInterface, CustomerSubscriptionPlanTier } from 'types/Customer'
import { getBillingPeriod } from 'utils/dates'
import { PlanStatus } from 'types/Auth'
import { useOpenSupport } from 'hooks/system/useOpenSupport'
import { useCustomerSubscriptionQuery } from 'api/queries'
import { usePlans } from 'design/templates/Modal/modalTemplates/SubscriptionModal/OldSelectPlanStep/usePlans'
import { PlanIndexByTier } from 'constants/plan.constants'

import { SetPlan, SubscriptionSteps } from '..'
import { Plan } from './Plan'
import { PlanActions } from './Plan/Action'
import { Feature } from './Feature'

import './index.scss'

type SelectPlanStepProps = {
    hideCurrentPlan?: boolean
    setPlan: SetPlan
    onClose: () => void
}

export const SelectPlanStep = ({ setPlan, hideCurrentPlan }: SelectPlanStepProps) => {
    const { data: subscription, isLoading: subLoading } = useCustomerSubscriptionQuery()
    const { planReactivation } = useFeatureFlags()
    const { plans, plansLoading } = usePlans()
    const { isUserInactive } = useUserStatuses()
    const openSupport = useOpenSupport()
    const pageLoading = subLoading || plansLoading
    const currentPlan = subscription?.plan || ({} as Partial<PlanInterface>)
    const titleProps: TypographyProps = hideCurrentPlan
        ? { textAlign: 'center', variant: 'h4', mb: 6 }
        : { variant: 'h2' }
    const handleContactUsClick = useCallback(() => {
        openSupport()
    }, [])

    const getPlanIndexByTier = useCallback((tier: CustomerSubscriptionPlanTier | undefined) => {
        return PlanIndexByTier[tier || CustomerSubscriptionPlanTier.unreachable]
    }, [])

    const currentPlanIndex = useMemo(
        () => getPlanIndexByTier(currentPlan.tier),
        [currentPlan, plans, getPlanIndexByTier],
    )

    const handleReactivateClick = (plan: PlanInterface) => setPlan(plan, { reactivate: true })
    const handleDowngradeClick = (plan: PlanInterface) =>
        setPlan(plan, {
            downgrade: true,
            ...(plan?.isFree ? { step: SubscriptionSteps.CONFIRM_DOWNGRADE } : {}),
        })

    const renderReactivateBtn = (breakpoint: string) => {
        const userPlan = subscription?.plan

        if (!isUserInactive || !userPlan || !planReactivation || userPlan.status === PlanStatus.archive) {
            return
        }

        return (
            <Button
                className={classNames('reactivate', breakpoint)}
                variant="contained"
                onClick={() => {
                    trackAppCuesEvent(
                        `${APPCUES_EVENTS.PLANS_POPUP_PLAN_BTN_CLK} action - ${PlanActions.REACTIVATE}, plan.apiHandle - ${userPlan.apiHandle}`,
                    )
                    handleReactivateClick(userPlan)
                }}
            >
                Reactivate My Plan
            </Button>
        )
    }

    const renderHeader = () => {
        if (hideCurrentPlan) {
            return null
        }

        return (
            <>
                <Box className="head">
                    <Box className="header">
                        <Typography component="h4">Your Current Plan</Typography>
                        <img className="currentPlanArrow" width={24} height={22} src={CurrentPlanArrow} alt="Arrow" />
                        {renderReactivateBtn('md')}
                    </Box>

                    <Box className="currentPlanDetails">
                        <Box className="badge">
                            <Typography component="span">{subLoading ? 'Loading...' : currentPlan.name}</Typography>
                        </Box>
                        <Box className="price">
                            <Typography component="span">
                                {subLoading ? (
                                    <Skeleton width={50} />
                                ) : (
                                    <>
                                        <strong>${currentPlan.price}</strong> /{' '}
                                        {getBillingPeriod(currentPlan.billingPeriod)}
                                    </>
                                )}
                            </Typography>
                        </Box>

                        <Box
                            className={classNames('features', {
                                stretched: currentPlan.tier !== CustomerSubscriptionPlanTier.free,
                            })}
                        >
                            <Feature isLoading={subLoading}>
                                {currentPlan.bandwidth === 0 || currentPlan.bandwidth === -1 ? (
                                    'Unlimited GB'
                                ) : (
                                    <>
                                        {currentPlan.bandwidth}GB / {getBillingPeriod(currentPlan.billingPeriod)}
                                    </>
                                )}
                            </Feature>
                            <Feature isLoading={subLoading}>
                                {currentPlan.videoSpace === 0 || currentPlan.videoSpace === -1 ? (
                                    'Unlimited Storage'
                                ) : (
                                    <>{currentPlan.videoSpace} videos</>
                                )}
                            </Feature>
                            {currentPlan.tier !== CustomerSubscriptionPlanTier.free && (
                                <Feature isLoading={subLoading}>
                                    {currentPlan.extraBandwidthPrice} / Extra {currentPlan.extraBandwidthUnit}
                                </Feature>
                            )}
                        </Box>
                    </Box>
                </Box>

                {renderReactivateBtn('sm')}
            </>
        )
    }

    return (
        <Box className="SelectPlanStep">
            {renderHeader()}

            <Typography className="plansTitle" {...titleProps}>
                Plans to suit your needs!
            </Typography>

            <Grid container spacing={2} className="plans">
                {plans?.map((plan) => (
                    <Grid key={plan.id} item desktop={plans.length === 3 ? 4 : 3} mobile={12} className="plan">
                        <Plan
                            plan={plan}
                            currentPlan={{ ...currentPlan, index: currentPlanIndex }}
                            isLoading={pageLoading}
                            setUpgradePlan={setPlan}
                            onDowngrade={handleDowngradeClick}
                            onReactivate={handleReactivateClick}
                        />
                    </Grid>
                ))}
            </Grid>

            {currentPlan.tier !== CustomerSubscriptionPlanTier.enterprise && (
                <>
                    <Divider />
                    <Grid container spacing={2} className="enterprise">
                        <Grid item desktop={2} mobile={12}>
                            <Typography component="h3" className="enterpriseHeader">
                                Need even more?
                            </Typography>
                        </Grid>
                        <Grid item desktop={8} mobile={12}>
                            <Typography className="enterpriseText">
                                If you want even more bandwidth and video storage, you be in one of our Enterprise
                                plans. They include a full account audit and concierge service (we&apos;ll move your
                                videos for you for free!). Get in touch with us to learn more.
                            </Typography>
                        </Grid>
                        <Grid item desktop={2} mobile={12} className="enterpriseCtaContainer">
                            <Button onClick={handleContactUsClick} variant="contained">
                                Contact Us
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    )
}
