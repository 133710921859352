import { SxProps, Theme } from '@mui/material'

const thumbnailContainer: SxProps<Theme> = { position: 'relative' }

const image: SxProps<Theme> = {
    width: '100%',
    borderRadius: 3,
    minHeight: 201,
    maxHeight: 240,
    objectFit: 'contain',
}

const thumbnailDelete: SxProps<Theme> = {
    position: 'absolute',
    top: 0,
    right: 0,
    transition: '250ms ease-in-out',
}

const skeleton: SxProps<Theme> = {
    borderRadius: 3,
    height: 240,
    width: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const skeletonText: SxProps<Theme> = {
    position: 'absolute',
    top: '50%',
    left: '45%',
}

const uploader: SxProps<Theme> = {
    p: 4,
    my: 5,
    gap: 0,
    border: (theme) => `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: (theme) => theme.palette.background.cardsEnabled,
    borderRadius: 4,
}

const videoTimeWrapper: SxProps<Theme> = {
    '@media (max-width: 1475px)': {
        flexDirection: 'column',
        alignItems: 'center',
    },
}

const inputsWrapper: SxProps<Theme> = {
    '@media (max-width: 1475px)': {
        alignSelf: 'center',
    },
}

export default {
    thumbnail: {
        uploader,
        image,
        skeleton,
        skeletonText,
        container: thumbnailContainer,
        delete: thumbnailDelete,
        videoTimeWrapper,
        inputsWrapper,
    },
}
