import { once } from 'lodash'
import {
    Dispatch,
    ElementType,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import { useUpdateSmartVidStepMutation } from 'api/mutations'
import { locale } from 'locales'
import { useSmartVidDetailsQuery, useVideoQuery } from 'api/queries'
import { useParams } from 'react-router-dom'
import { SmartVidVersion } from 'types/SmartVids'
import { useAppDispatch, useAppSelector } from 'App'
import {
    selectAnalyticsSwitchState,
    setIsAnalyticMode as sliceSetIsAnalyticMode,
} from 'design/pages/SmartVid/Header/Actions/AnalyticsSwitch/AnalyticsSwitch.slice'
import { StatsEntity } from 'constants/routes'

interface ContextState {
    showLegacyModal: boolean
    isGlobalSettingsOpen: boolean
    isAnalyticMode: boolean
    notificationMessage: string
    editingDisabled: boolean
    isUnpublished?: boolean
    updateSmartVidStepMutation: ReturnType<typeof useUpdateSmartVidStepMutation>
    setShowLegacyModal: Dispatch<SetStateAction<boolean>>
    setIsAnalyticMode: Dispatch<SetStateAction<boolean>>
    handleOpenGlobalSettings: () => void
    handleCloseGlobalSettings: () => void
}

const createSmartVidPageContext = once(() => createContext({} as ContextState))
const useSmartVidPageContext = () => useContext(createSmartVidPageContext())

const HIDE_NOTIFICATION_TM = 3000

type SmartVidPageProviderProps = {
    children?: ReactNode
}

function SmartVidPageProvider({ children }: SmartVidPageProviderProps) {
    const { smartVidId, stepVidId, entity } = useParams()
    const { data: svDetails } = useSmartVidDetailsQuery(smartVidId)
    const { data: video } = useVideoQuery(stepVidId)
    const dispatch = useAppDispatch()

    const { isAnalyticMode } = useAppSelector(selectAnalyticsSwitchState)
    const [isGlobalSettingsOpen, setIsGlobalSettingsOpen] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')

    const isOldVersion = svDetails?.smartVid.svVersion === SmartVidVersion.v1
    const legacyModalDisplayedRef = useRef(false)
    const [showLegacyModal, setShowLegacyModal] = useState<boolean>(isOldVersion)

    const isVideoUnpublished = video?.video.isUnpublished
    const isUnpublished = svDetails?.smartVid.isUnpublished || isVideoUnpublished

    const notificationTimerRef = useRef<ReturnType<typeof setTimeout>>()
    const updateSmartVidStepMutation = useUpdateSmartVidStepMutation({
        onSuccess: () => {
            setNotificationMessage(locale.messages.info.saved)
            notificationTimerRef.current = setTimeout(() => setNotificationMessage(''), HIDE_NOTIFICATION_TM)
        },
    })

    const handleOpenGlobalSettings = () => setIsGlobalSettingsOpen(true)
    const handleCloseGlobalSettings = () => setIsGlobalSettingsOpen(false)

    const setIsAnalyticMode: Dispatch<SetStateAction<boolean>> = (payload) => {
        dispatch(sliceSetIsAnalyticMode(payload))
    }

    useEffect(() => {
        if (stepVidId && isAnalyticMode) {
            setIsAnalyticMode(false)
        }
        if (entity === StatsEntity.smartVid && !isAnalyticMode) {
            setIsAnalyticMode(true)
        }
    }, [isAnalyticMode, entity, stepVidId])

    useEffect(() => {
        if (!legacyModalDisplayedRef.current && isOldVersion) {
            legacyModalDisplayedRef.current = true
            setShowLegacyModal(true)
        }
    }, [isOldVersion])

    useEffect(() => {
        return () => {
            if (notificationTimerRef.current) {
                clearTimeout(notificationTimerRef.current)
            }
        }
    }, [])

    const smartVidPageProps = useMemo(
        () => ({
            showLegacyModal,
            isGlobalSettingsOpen,
            isAnalyticMode,
            updateSmartVidStepMutation,
            notificationMessage,
            editingDisabled: isOldVersion || !svDetails,
            setShowLegacyModal,
            setIsAnalyticMode,
            isUnpublished,
            handleOpenGlobalSettings,
            handleCloseGlobalSettings,
        }),
        [
            updateSmartVidStepMutation,
            notificationMessage,
            isGlobalSettingsOpen,
            isAnalyticMode,
            showLegacyModal,
            isOldVersion,
            svDetails,
            isUnpublished,
        ],
    )

    const SmartVidPageContext = createSmartVidPageContext()

    return <SmartVidPageContext.Provider value={smartVidPageProps}>{children}</SmartVidPageContext.Provider>
}

const withSmartVidPageProvider = (Content: ElementType) => () => {
    return (
        <SmartVidPageProvider>
            <Content />
        </SmartVidPageProvider>
    )
}

export { SmartVidPageProvider, useSmartVidPageContext, withSmartVidPageProvider }
