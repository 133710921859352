import { Box, Grid, Skeleton, Typography } from '@mui/material'

import LightningStrike from 'assets/img/freemium/lightning-strike.svg'

import Action, { ActionProps } from './Action'
import { Features } from './Features'

type PlanProps = ActionProps & {
    isLoading: boolean
}

export const Plan = ({ plan, isLoading, currentPlan, setUpgradePlan, onDowngrade, onReactivate }: PlanProps) => {
    if (isLoading) {
        return (
            <Grid item desktop={4} mobile={12} className="plan">
                <Box className="planCard">
                    <Typography className="planName">
                        <Skeleton width={50} />
                    </Typography>
                    <Box>
                        <Skeleton width={100} height={50} />
                    </Box>
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={40}
                        className="cta"
                        data-testid="ctaSkeleton"
                    />

                    <Features plan={plan} />
                </Box>
            </Grid>
        )
    }

    return (
        <Box className="planCard">
            <Typography className="planName">
                {!plan.isFree && <img width={16} src={LightningStrike} />} {plan.name}
            </Typography>
            <Box>
                <Typography className="planPrice" component="span">
                    ${Number(plan.price)}
                </Typography>
                <Typography className="planPeriod" component="span">
                    / month
                </Typography>
            </Box>

            <Action
                plan={plan}
                currentPlan={currentPlan}
                setUpgradePlan={setUpgradePlan}
                onDowngrade={onDowngrade}
                onReactivate={onReactivate}
            />

            <Features plan={plan} />
        </Box>
    )
}
