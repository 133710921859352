import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

import { SMART_VID_DETAILS_QUERY, UPDATE_THUMBNAIL_EXIT_TIME_MUTATION, VIDEOS_QUERY } from 'api/constants'

import { useInvalidateMutation, Options } from 'api/hooks'
import { Video } from 'types/Video'
import { useActualVideoGuid } from 'hooks/video/useActualVideoGuid'

type Payload = {
    timeFrom: number
    timeTo: number
    guid?: string
}
export const useUpdateThumbnailExitTimeMutation = (options?: Options<Video, Payload, string>) => {
    const vidId = useActualVideoGuid()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Video, Payload, string>(
        UPDATE_THUMBNAIL_EXIT_TIME_MUTATION,
        async ({ timeFrom, timeTo, guid }: Payload) => {
            if (!vidId) {
                return
            }
            if (guid) {
                return mutate(
                    settingsAPI.updateThumbnailExitTime(vidId, timeFrom, timeTo, guid),
                    apiLocale.settings.thumbnail.update,
                )
            }
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY, SMART_VID_DETAILS_QUERY],
        },
    )
}
