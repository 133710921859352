import { createSvgIcon } from '@mui/material'

const CheckmarkContained = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M15.142 9.98299L10.875 14.25L9.42049 12.7955M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>,
    'CheckmarkContained',
)

export default CheckmarkContained
