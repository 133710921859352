import { METRICS } from 'constants/metrics.constants'

const metrics: { [key in METRICS]: string } = {
    [METRICS.AVG_ORDER]: 'Average order',
    [METRICS.AVERAGE_PERCENT_WATCHED]: 'Average % Watched',
    [METRICS.AVG_REVENUE_PER_PLAY]: 'avg. Revenue Per Play',
    [METRICS.AVG_WATCH_TIME]: 'Avg % Watched',
    [METRICS.CTA_CTR]: 'CTA-CTR',
    [METRICS.CONVERSION_RATE]: 'Conversion Rate',
    [METRICS.CONVERSIONS]: 'Conversions',
    [METRICS.IMPRESSIONS]: 'Impressions',
    [METRICS.PLAY_RATE]: 'Play rate',
    [METRICS.PLAYS]: 'Plays',
    [METRICS.REVENUE]: 'Revenue',
    [METRICS.OPT_IN_RATE]: 'Opt-in Rate',
    [METRICS.TAGS_TRIGGERED_RATE]: 'Tags Triggered Rate',
    [METRICS.TOTAL_PLAYS]: 'Total Plays',
    [METRICS.UNIQUE_VIEWERS]: 'Unique Viewers',
    [METRICS.UNMUTE_RATE]: 'Unmute Rate',
} as const

export default metrics
