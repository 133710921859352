import { SxProps, Theme } from '@mui/material'
import { MOBILE_BURGER_HEADER_HEIGHT } from 'styles/constants'

const container: SxProps<Theme> = (theme) => ({
    p: 3,
    width: 1,

    [theme.breakpoints.up('laptop')]: {
        pt: 3,
        px: 8,
        pb: 3,
    },

    [theme.breakpoints.down('tablet')]: {
        height: `calc(100vh - ${MOBILE_BURGER_HEADER_HEIGHT}px)`,
        minHeight: 'unset',
    },
})

const content: SxProps<Theme> = {
    maxWidth: 1792,
    width: 1,
    mx: 'auto',
}

const main: SxProps<Theme> = (theme) => ({
    minHeight: 'calc(100vh - 76px)', // 44px pt + 32px pb
    width: 1,

    [theme.breakpoints.down('tablet')]: {
        height: `calc(100vh - ${MOBILE_BURGER_HEADER_HEIGHT}px)`,
        minHeight: 'unset',
    },
})

const title: SxProps = {
    mb: 7,
}

export default {
    container,
    content,
    main,
    title,
}
