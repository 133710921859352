import { CSSObject, SxProps, Theme } from '@mui/material'
import { MOBILE_BURGER_HEADER_HEIGHT, OPEN_DRAWER_WIDTH } from 'styles/constants'
import { pxToRem } from 'styles/theme/typography'
import { alpha } from '@mui/material/styles'

const openedMixin: SxProps = {
    width: OPEN_DRAWER_WIDTH,

    '& ~ .MuiBox-root': {
        position: 'relative',
        width: `calc(100% - ${OPEN_DRAWER_WIDTH}px - 1px)`,
    },
}

const closedMixin: SxProps<Theme> = (theme: Theme) => ({
    width: `calc(${theme.spacing(16)} + 1px)`,

    '& .MuiListItemIcon-root': {
        marginRight: 0,
    },
    '& ~ .MuiBox-root': {
        position: 'relative',
        width: `calc(100% - ${theme.spacing(16)} - 1px)`,
    },
})

const hiddenMixin: SxProps = {
    width: 0,
    opacity: 0,
    pointerEvents: 'none',

    '& ~ .MuiBox-root': {
        width: 1,
    },
}

const drawerIdle: SxProps<Theme> = (theme) => ({
    width: OPEN_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    display: 'flex',
    zIndex: (theme) => theme.zIndex.drawer,

    transition: theme.transitions.create(['width', 'opacity'], {
        easing: theme.transitions.easing.easeOut,
        duration: '.5s',
    }),

    '& ~ .MuiBox-root': {
        transition: theme.transitions.create(['width', 'opacity'], {
            easing: theme.transitions.easing.easeOut,
            duration: '.5s',
        }),
    },

    '.MuiPaper-root': {
        overflow: 'visible',
    },

    ':hover': {
        '.MuiList-root .MuiSvgIcon-root': {
            opacity: 1,
        },
    },
})

const drawerOpen: SxProps = {
    ...openedMixin,
    '& .MuiDrawer-paper': openedMixin,
}

const drawerClose: SxProps<Theme> = (theme) => ({
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
})

const drawerHidden: SxProps = {
    ...hiddenMixin,
    '& .MuiDrawer-paper': hiddenMixin,
}

const outletContainer: SxProps = {
    width: '100%',
}

const logoLink: SxProps = {
    p: '20px 18px',
    display: 'flex',
    gap: '5.2px',
}

const logomark: SxProps = {
    width: 'auto',
    height: 'auto',
}

const logotypeIdle: SxProps<Theme> = {
    width: 'auto',
    height: 'auto',
    display: 'none',
}

const logotypeOpen: SxProps = {
    display: 'block',
}

const list = (theme: Theme) => ({
    [theme.breakpoints.up('mobile')]: {
        '.MuiDivider-root': {
            display: 'none',
        },
    },
})

const listItemButton: SxProps = {
    display: 'flex',
    gap: 4,
    p: '14px 12px 14px 20px',
}

const itemLink: SxProps = {
    position: 'relative',
    display: 'block',
}

const listItemIconSelected: SxProps = {
    color: 'primary.main',
}

const listItemIcon: SxProps = {
    minWidth: 0,
    my: 1,
    position: 'relative',
}

const drawerHandlerIconIdle: SxProps<Theme> = {
    position: 'absolute',
    p: 0.5,
    right: -14,
    zIndex: 1,
    top: '50%',
    borderRadius: '50%',
    bgcolor: 'background.default',
    opacity: 0,
    boxShadow: (theme) => theme.shadows[1],
    transition: (theme) => `opacity 150ms ${theme.transitions.easing.easeOut}`,
    transform: 'translate(0, -50%)',
}

const drawerHandlerIconOpen: SxProps<Theme> = {
    transform: 'translate(0, -50%) rotate(180deg)',
}

const listItemTextIdle: SxProps = {
    typography: {
        display: 'none',
    },
}

const listItemTextOpen: SxProps = {
    typography: {
        display: 'block',
    },
}

const uploadCtaIdle: SxProps = {
    transition: '300ms ease-out',

    '.MuiButton-startIcon .MuiSvgIcon-root': {
        fontSize: '1rem',
    },
}

const uploadCtaClosed: SxProps = {
    p: 3,
    minWidth: 0,
    width: 'auto',

    '.MuiButton-startIcon': {
        m: 0,
    },
    'span:not(.MuiTouchRipple-root):not(.MuiButton-startIcon)': {
        display: 'none',
    },
}

const mobileContainer: SxProps<Theme> = {
    height: MOBILE_BURGER_HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pr: 3,
    backgroundColor: 'background.default',
}

const mobileDrawerIdle: SxProps<Theme> = {
    zIndex: (theme) => theme.zIndex.drawer,
    width: '100%',
}

const mobileClosedMixin = (): CSSObject => ({
    height: 0,

    '& ~ .MuiBox-root': {
        height: 0,
    },
})

const mobileDrawerOpen: SxProps<Theme> = () => ({
    position: 'relative',
    height: `calc(100vh - ${MOBILE_BURGER_HEADER_HEIGHT}px)`,
    right: 0,
    width: '100%',
    '& ~ .MuiBox-root': {
        height: `calc(100% - ${MOBILE_BURGER_HEADER_HEIGHT}px - 1px)`,
    },
    '& .MuiDrawer-paper': {
        height: `calc(100vh - ${MOBILE_BURGER_HEADER_HEIGHT}px)`,
        position: 'relative',
        right: 0,
        pb: 16,
    },
})

const mobileDrawerClose: SxProps<Theme> = () => ({
    height: 0,
    ...mobileClosedMixin(),
    '& .MuiDrawer-paper': mobileClosedMixin(),
})

const mobileDrawerHidden: SxProps<Theme> = {
    height: 0,
    ...hiddenMixin,
    '& .MuiDrawer-paper': hiddenMixin,
}

const mobileAdditionalList: SxProps<Theme> = {
    pb: 16,
}

const firstUploadSidebar: SxProps<Theme> = (theme: Theme) => ({
    py: 5,
    px: '18px',

    [theme.breakpoints.up('tablet')]: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
})

const upgradeIcon: SxProps = {
    position: 'absolute',
    top: 14,
    left: 17,
    fontSize: pxToRem(16),
    color: 'accent.main',
}

const newBtn: SxProps<Theme> = {
    color: 'success.main',
    pointerEvents: 'none',
    background: '#2e7d32',

    '&, &:hover': {
        background: (theme) => alpha(theme.palette.success.light, theme.palette.action.selectedOpacity),
        border: 'none',
    },
}

const newBtnClosed: SxProps<Theme> = {
    color: 'success.dark',
    pointerEvents: 'none',
    background: '#2e7d32',
    width: '36px',
    height: '18px',
    fontSize: '10px',
    position: 'absolute',
    right: '3px',
    top: '2px',
    padding: '2px',

    '&, &:hover': {
        background: (theme) => alpha(theme.palette.success.light, theme.palette.action.selectedOpacity),
        border: 'none',
    },
}

const newBtnClose: SxProps<Theme> = {
    position: 'absolute',
    top: 3,
    right: 3,
    '&, &:hover': {
        background: (theme) => alpha(theme.palette.success.light, theme.palette.action.selectedOpacity),
        border: 'none',
    },
}

export const style = {
    drawer: {
        idle: drawerIdle,
        open: drawerOpen,
        close: drawerClose,
        hidden: drawerHidden,
    },
    outletContainer,
    logoLink,
    list,
    itemLink,
    listItem: {
        button: listItemButton,
        icon: listItemIcon,
        iconSelected: listItemIconSelected,
        text: {
            idle: listItemTextIdle,
            open: listItemTextOpen,
        },
    },
    logomark,
    logotype: {
        idle: logotypeIdle,
        open: logotypeOpen,
    },
    drawerHandlerIcon: {
        idle: drawerHandlerIconIdle,
        open: drawerHandlerIconOpen,
    },
    uploadCta: {
        idle: uploadCtaIdle,
        closed: uploadCtaClosed,
    },
    updateCta: {
        idle: uploadCtaIdle,
        closed: uploadCtaClosed,
    },
    mobile: {
        container: mobileContainer,
        drawer: {
            idle: mobileDrawerIdle,
            open: mobileDrawerOpen,
            close: mobileDrawerClose,
            hidden: mobileDrawerHidden,
        },
        additionalList: mobileAdditionalList,
    },
    firstUploadSidebar,
    upgradeIcon,
    newBtn,
    newBtnClosed,
    newBtnClose,
}
