import { ImgWithErrorHandler } from 'design/atoms/ImgWithErrorHandler'

import style from './PromoMedia.style'

type PromoMediaProps = {
    src: string
    isInsideContainer?: boolean
}

const PromoMedia = ({ src, isInsideContainer = false }: PromoMediaProps) => {
    return (
        <ImgWithErrorHandler lazy={false} src={src} sx={[style.img, isInsideContainer && style.imgInsideContainer]} />
    )
}

export default PromoMedia
