import { ModalType } from 'design/templates/Modal/ModalTypes/types'
import { SubscriptionCategory } from 'types/Customer'

export const COMMON_CONFIG: {
    type: ModalType.FEATURE_LOCKED
    planCategory: SubscriptionCategory
    description: string
} = {
    type: ModalType.FEATURE_LOCKED,
    // Maybe use needed plan from featureAccess
    planCategory: SubscriptionCategory.pro,
    description: 'Enhance your video marketing with powerful features!',
}
