import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { ImgWithErrorHandler } from 'design/atoms/ImgWithErrorHandler'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { Folder, Video } from 'types/Video'

import { Box, Tooltip } from '@mui/material'
import locale from './VideoSelectControlItem.locale'
import style from './VideoSelectControlItem.style'

type VideoSelectControlItemProps = {
    item?: Video
    folder?: Folder
    error?: boolean
    showSubtitle?: boolean
}

const VideoSelectControlItem = ({ item, error, showSubtitle, folder }: VideoSelectControlItemProps) => {
    const src = item?.thumbnail?.previewSrc

    return (
        <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', maxWidth: 1 }}>
            <ImgWithErrorHandler
                src={src}
                alt={item?.title || locale.chooseVideo}
                sx={{
                    minWidth: 45,
                    width: 45,
                    height: 26,
                    borderRadius: '6px',
                    objectFit: 'cover',
                    ...(!src ? { filter: 'brightness(0.95)' } : {}),
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: '8px', minWidth: 0 }}>
                <Tooltip title={item?.title} enterDelay={1000} arrow>
                    <Typography sx={[style.label, !!error && style.error]}>
                        {item?.title || locale.chooseVideo}
                    </Typography>
                </Tooltip>

                {showSubtitle && <Typography sx={style.sublabel}>{folder?.title}</Typography>}
            </Box>
        </Stack>
    )
}

export default withErrorBoundary(VideoSelectControlItem)
