import {
    Alert,
    type AlertColor,
    Snackbar,
    Slide,
    SnackbarProps,
    AlertProps,
    AlertTitle,
    Stack,
    Button,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { createContext, type ReactNode, SyntheticEvent, useCallback, useMemo, useState } from 'react'

export interface ToastProviderProps {
    children: ReactNode
}

export interface ToastContext {
    showToast: (params: ToastParams) => void
    closeToast: () => void
}

export const Context = createContext({} as ToastContext)

export interface ToastParams {
    message: string
    title?: string
    type?: AlertColor
    duration?: number | null
    SnackbarProps?: Omit<SnackbarProps, 'children'>
    AlertProps?: Omit<AlertProps, 'severity' | 'title' | 'children' | 'sx'>
    action?: () => void
}

const defaults: ToastParams = {
    type: 'info',
    message: '',
    duration: 2000,
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
    const [toast, setToast] = useState<ToastParams>(defaults)
    const [open, setOpen] = useState(false)

    const showToast = useCallback((params: ToastParams) => {
        setToast({ ...defaults, ...params })
        setOpen(true)
    }, [])

    const closeToast = useCallback(() => {
        setOpen(false)
    }, [])

    const contextValue = useMemo<ToastContext>(
        () => ({
            showToast,
            closeToast,
        }),
        [showToast, closeToast],
    )

    const { message, title, duration, type, AlertProps, SnackbarProps, action } = toast

    const alertOnClose = AlertProps?.onClose
        ? (event: SyntheticEvent) => {
              closeToast()
              AlertProps?.onClose?.(event)
          }
        : undefined

    return (
        <Context.Provider value={contextValue}>
            <Snackbar
                open={open}
                onClose={closeToast}
                autoHideDuration={duration}
                TransitionComponent={Slide}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                {...SnackbarProps}
            >
                <Alert severity={type} {...AlertProps} onClose={alertOnClose} sx={{ p: 3 }}>
                    {action && (
                        <Stack direction="row" gap={2}>
                            <Stack>
                                {title && <AlertTitle>{title}</AlertTitle>}
                                {message}
                            </Stack>
                            <Stack direction="row" gap={2} justifyContent="flex-end" alignItems="flex-start">
                                <Button
                                    variant="outlined"
                                    onClick={action}
                                    sx={{
                                        borderColor: 'common.tertiaryOutlinedBorder',
                                        color: 'tertiary.main',
                                        fontSize: '13px',
                                        py: '5px',
                                        px: '14px',
                                        height: '32px',
                                    }}
                                >
                                    {type === 'success' ? 'Customize' : 'Try again'}
                                </Button>
                                <Button
                                    sx={{ px: 0 }}
                                    onClick={closeToast}
                                    startIcon={
                                        <ClearIcon sx={{ color: 'tertiary.main', width: '16px', height: '16px' }} />
                                    }
                                />
                            </Stack>
                        </Stack>
                    )}
                    {!action && (
                        <>
                            {title && <AlertTitle>{title}</AlertTitle>}
                            {message}
                        </>
                    )}
                </Alert>
            </Snackbar>
            {children}
        </Context.Provider>
    )
}

// TODO: [VID-7326] Add action (close button)
// TODO: [VID-7326] Test persistent behavior
