import { useMemo } from 'react'
import { Typography, Box } from '@mui/material'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import {
    UI_CONTROL_BAR_FULLSCREEN_CUSTOM_DEFAULT,
    UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MOBILE,
    UI_CONTROL_BAR_FULLSCREEN_CUSTOM_EXPAND_ON_PLAY,
    UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MINIMIZE_ON_PAUSE,
    PLAYER_DOCS_FOCUSED_FULLSCREEN_LINK,
} from 'design/pages/VidSettings/constants'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import { Link } from 'design/atoms/Link'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import sidebarStyle from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { type ControlsSectionFormData } from 'types/VidSettings'
import locale from './FocusedFullscreenContent.locale'
import style from './FocusedFullscreenContent.style'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const FocusedFullscreenContent = ({ isSmartVidGlobalSettings }: Props) => {
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )

    const {
        formik: { values },
        isLoading,
        onChange,
    } = useSectionContext<ControlsSectionFormData>()

    const commonProps = {
        sx: sidebarStyle.container,
        afterChange: onChange,
        disabled: isLoading,
        switchProps: { size: 'small' },
        labelPlacement: 'start',
        tooltipPlacement: SwitchTooltipPlacement.inside,
    } as const

    const hasAnyFocusedFullscreen = !!(
        values[UI_CONTROL_BAR_FULLSCREEN_CUSTOM_DEFAULT] || values[UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MOBILE]
    )

    return (
        <Section mb={4}>
            <Box mb={4}>
                <Typography variant="body2" sx={style.info}>
                    {locale.heading.title}{' '}
                    <HelpTooltip
                        title={
                            <Box sx={style.tooltipText}>
                                {locale.heading.tooltip} {!!isSmartVidGlobalSettings && locale.heading.tooltipSmartVid}{' '}
                                <Link
                                    sx={style.tooltipLink}
                                    to={PLAYER_DOCS_FOCUSED_FULLSCREEN_LINK}
                                    target="_blank"
                                    external
                                >
                                    Learn More
                                </Link>
                            </Box>
                        }
                    />
                </Typography>
            </Box>
            <SwitchForm
                {...commonProps}
                name={UI_CONTROL_BAR_FULLSCREEN_CUSTOM_DEFAULT}
                label={<Typography variant="body2">{locale.controls.enableForDesktop}</Typography>}
            />
            <SwitchForm
                {...commonProps}
                name={UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MOBILE}
                label={<Typography variant="body2">{locale.controls.enableForMobile}</Typography>}
            />
            <SwitchForm
                {...commonProps}
                name={UI_CONTROL_BAR_FULLSCREEN_CUSTOM_EXPAND_ON_PLAY}
                label={<Typography variant="body2">{locale.controls.expandOnPlay}</Typography>}
                helpInfo={<Box sx={style.tooltipText}>{locale.controls.tooltips.expandOnPlay}</Box>}
                disabled={commonProps.disabled || !hasAnyFocusedFullscreen}
                switchProps={{
                    ...commonProps.switchProps,
                    checked: hasAnyFocusedFullscreen ? values[UI_CONTROL_BAR_FULLSCREEN_CUSTOM_EXPAND_ON_PLAY] : false,
                }}
            />
            <SwitchForm
                {...commonProps}
                name={UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MINIMIZE_ON_PAUSE}
                label={<Typography variant="body2">{locale.controls.minimizeOnPause}</Typography>}
                helpInfo={<Box sx={style.tooltipText}>{locale.controls.tooltips.minimizeOnPause}</Box>}
                disabled={commonProps.disabled || !hasAnyFocusedFullscreen}
                switchProps={{
                    ...commonProps.switchProps,
                    checked: hasAnyFocusedFullscreen
                        ? values[UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MINIMIZE_ON_PAUSE]
                        : false,
                }}
            />
        </Section>
    )
}

export default withErrorBoundary(FocusedFullscreenContent)
