const freeEmailDomains = [
    'gmail.com',
    'yahoo.com',
    'outlook.com',
    'aol.com',
    'icloud.com',
    'protonmail.com',
    'tutanota.com',
    'zoho.com',
    'mailfence.com',
    'gmx.com',
    'yandex.com',
    'mail.com',
    'fastmail.com',
    'hushmail.com',
    'inbox.com',
    'lycos.com',
    'rediffmail.com',
    't-online.de',
    'seznam.cz',
    'web.de',
    'laposte.net',
    'orange.fr',
    'free.fr',
    'bol.com.br',
    'terra.com.br',
    'uol.com.br',
    'rambler.ru',
    'qq.com',
    'naver.com',
    'daum.net',
    'hanmail.net',
    'libero.it',
    'virgilio.it',
    'mail.ru',
    'bk.ru',
    'list.ru',
    'inbox.ru',
    'hotmail.com',
    'live.com',
    'msn.com',
    'yandex.ru',
    'gawab.com',
    'o2.pl',
    'wp.pl',
    'interia.pl',
    'onet.pl',
    'abv.bg',
    'tiscali.co.uk',
    'bluewin.ch',
    'rocketmail.com',
    'bigpond.com',
    'btinternet.com',
    'talktalk.net',
    'ntlworld.com',
    'virginmedia.com',
    'yopmail.com',
    'mailinator.com',
    'guerrillamail.com',
    '10minutemail.com',
    'tempmail.com',
    'trashmail.com',
    'fakeinbox.com',
    'getnada.com',
    'mytemp.email',
    'emailondeck.com',
    'dispostable.com',
    'sharklasers.com',
    'airmail.cc',
    'eclipso.eu',
    'runbox.com',
    'zoho.eu',
    'posteo.de',
    'tuta.io',
    'proton.me',
    'vfemail.net',
    'shortmail.com',
    'netcourrier.com',
    'mailbox.org',
    'hinet.net',
    '21cn.com',
    'sina.com',
    'sogou.com',
    'tom.com',
    '126.com',
    '163.com',
    'yeah.net',
    'googlemail.com',
    'live.co.uk',
    'hotmail.co.uk',
    'hotmail.fr',
    'live.fr',
    'wanadoo.fr',
    'telstra.com',
    'mymail.com',
    'outlook.co.uk',
    'rogers.com',
    'videotron.ca',
    'sympatico.ca',
    'shaw.ca',
    'mail.ee',
    'zohomail.com',
    '123mail.org',
    'gmx.us',
]

export default freeEmailDomains
