import { SxProps } from '@mui/material'

const folderIcon: SxProps = {
    fontSize: 48,
    color: 'action.active',
}

const arrowIcon: SxProps = {
    color: 'action.active',
}

const root: SxProps = {
    p: 0,
}

const divider: SxProps = {
    ml: 3,
}

const accordionRoot: SxProps = {
    p: 0,
    boxShadow: 'none',
    width: '100%',
}

const accordionSummary: SxProps = {
    p: 0,
    m: 0,
    position: 'relative',

    '.MuiAccordionSummary-content': {
        m: 0,
    },

    '.MuiAccordionSummary-expandIconWrapper': {
        position: 'absolute',
        right: 16,
        pointerEvents: 'none',

        '&.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
    },
}

const accordionSummaryContent: SxProps = {
    width: '100%',
}

const accordionDetails: SxProps = {
    p: 0,
}

const entity = (level: number): SxProps => ({
    width: '100%',
    pl: `calc(12px + ${level * 36}px)`,
    pr: 9,
})

export default {
    root,
    divider,
    icon: {
        folder: folderIcon,
        arrow: arrowIcon,
    },
    accordion: {
        root: accordionRoot,
        summary: {
            root: accordionSummary,
            content: accordionSummaryContent,
        },
        details: accordionDetails,
    },
    entity,
}
