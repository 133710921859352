import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { closeModal, Modal, ModalControllerState, selectModalControllerState } from 'design/templates/Modal'
import { useDeleteFoldersMutation, useDeleteVideosMutation } from 'api/mutations'
import { useAppDispatch, useAppSelector } from 'App'
import { locale } from 'locales'
import modalLocale from './MyVidsConfirmationDeleteModal.locale'

export type MyVidsConfirmDeleteProps = {
    selectedMyVidsRows: string[]
    rowType?: string
}

export const MyVidsConfirmDeleteModal = () => {
    const deleteVideosMutation = useDeleteVideosMutation()
    const deleteFoldersMutation = useDeleteFoldersMutation()
    const dispatch = useAppDispatch()

    const {
        type,
        props: { selectedMyVidsRows, rowType },
    } = useAppSelector(selectModalControllerState) as ModalControllerState<MyVidsConfirmDeleteProps>

    const onClose = () => {
        dispatch(closeModal())
    }

    const isFolder = rowType === 'folder'

    const handleDelete = () => {
        if (isFolder) {
            deleteFoldersMutation.mutate(selectedMyVidsRows)
        } else deleteVideosMutation.mutateAsync(selectedMyVidsRows)

        onClose()
    }

    return (
        <Modal open={type === 'Confirm delete myVids'} onClose={onClose}>
            <Modal.Header title={modalLocale.title(isFolder)} onClose={onClose} />
            <Modal.Body>
                <Typography>{modalLocale.message(isFolder)}</Typography>
            </Modal.Body>
            <Modal.Actions>
                <Button variant="outlined" color="tertiary" onClick={onClose}>
                    {locale.operations.generic.cancel}
                </Button>
                <Button variant="contained" color="error" onClick={handleDelete}>
                    {locale.operations.entity.delete}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
