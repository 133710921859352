import { useEffect, useState, useRef, useMemo } from 'react'
import { Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import classNames from 'classnames'
import mixpanel from 'mixpanel-browser'

import { Modal } from 'design/templates/Modal'
import { ConfirmDowngrade as OldConfirmDowngrade } from 'design/templates/Modal/modalTemplates/SubscriptionModal/OldConfirmDowngrade'
import useUserStatuses from 'hooks/user/useUserStatuses'
import { useLayout } from 'hooks/utilities/useLayout'
import { CustomerSubscriptionPlanTier, Plan } from 'types/Customer'
import { useAppSelector, useAppDispatch } from 'App'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import { usePlans } from 'design/templates/Modal/modalTemplates/SubscriptionModal/OldSelectPlanStep/usePlans'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { ConfirmDowngrade } from 'design/templates/Modal/modalTemplates/SubscriptionModal/ConfirmDowngrade'
import ActivationWelcome from 'design/templates/Modal/modalTemplates/SubscriptionModal/OldActivationStep/ActivationWelcome'
import { useCustomerSubscriptionQuery } from 'api/queries'

import {
    selectSubscription,
    closeSubscriptionModal,
    setPlan,
    SubscriptionSteps,
    PlanOptions,
} from './subscription.slice'
import { SelectPlanStep as OldSelectPlanStep } from './OldSelectPlanStep'
import { ActivationStep as OldActivationStep } from './OldActivationStep'
import ActivationStep from './ActivationStep'
import { SelectPlanStep } from './SelectPlanStep'
import style from './SubscriptionModal.style'
import './index.scss'

export type SetPlan = (plan?: Plan, options?: PlanOptions) => void

export const SubscriptionModal = () => {
    const { isMobile } = useLayout()
    const { data: customerSubscription } = useCustomerSubscriptionQuery()
    const [oldTier] = useState<CustomerSubscriptionPlanTier | undefined>(customerSubscription?.plan.tier)
    const [disabledExitAnimation, setDisabledExitAnimation] = useState(false)
    const [displayVidsWarning, setDisplayVidsWarning] = useState(false)
    const { newPlanSelectModal, trialAutoDowngradeAfterTrialWhenUserHasNoCard } = useFeatureFlags()
    const [savedPlan, setSavedPlan] = useState<Plan | undefined>(undefined)
    const subscription = useAppSelector(selectSubscription)
    const { isUserInactive } = useUserStatuses()
    const dispatch = useAppDispatch()
    const isSubscriptionOpenTriggeredRef = useRef(false)
    const { plans } = usePlans()

    const handleClose = () => {
        if (
            subscription.planOptions?.isTrialStartedMode &&
            currentStep !== SubscriptionSteps.WELCOME_STEP &&
            trialAutoDowngradeAfterTrialWhenUserHasNoCard
        ) {
            setSelectedPlan(undefined, { step: SubscriptionSteps.WELCOME_STEP })
        } else {
            isSubscriptionOpenTriggeredRef.current = false
            dispatch(closeSubscriptionModal())
            subscription.onClose?.()
        }
    }

    useEffect(() => {
        if (subscription.plan) {
            setSavedPlan(subscription.plan)
        }
    }, [subscription.plan])

    useEffect(() => {
        if (!subscription.open) return
        if (isSubscriptionOpenTriggeredRef.current) return
        mixpanel.track(MIXPANEL_EVENTS.SUBSCRIPTIONS_OPENED, { cta_source: subscription.ctaSource || null })
        isSubscriptionOpenTriggeredRef.current = true
    }, [subscription])

    const setSelectedPlan: SetPlan = (plan, options) => dispatch(setPlan({ plan, options }))

    const currentStep = useMemo(() => {
        if (subscription.step === SubscriptionSteps.WELCOME_STEP) {
            return SubscriptionSteps.WELCOME_STEP
        }

        if (subscription.step === SubscriptionSteps.CONFIRM_DOWNGRADE && subscription.plan) {
            return SubscriptionSteps.CONFIRM_DOWNGRADE
        }

        if (subscription.step === SubscriptionSteps.ACTIVATION_STEP || subscription.plan) {
            return SubscriptionSteps.ACTIVATION_STEP
        }

        return SubscriptionSteps.SELECT_PLAN_STEP
    }, [subscription.step, subscription.plan]) as SubscriptionSteps

    const updatedSelectPlanStep = newPlanSelectModal && currentStep === SubscriptionSteps.SELECT_PLAN_STEP
    const updatedActivationStep =
        trialAutoDowngradeAfterTrialWhenUserHasNoCard && currentStep === SubscriptionSteps.ACTIVATION_STEP

    const confirmDowngradeStep =
        trialAutoDowngradeAfterTrialWhenUserHasNoCard && currentStep === SubscriptionSteps.CONFIRM_DOWNGRADE

    const getModalWidth = () => {
        if (trialAutoDowngradeAfterTrialWhenUserHasNoCard) {
            if (displayVidsWarning) return 'md'
            return 'lg'
        }
        if (plans.length === 3 || currentStep === SubscriptionSteps.ACTIVATION_STEP) return 'lg'
        return 'xl'
    }

    return (
        <Modal
            open={subscription.open}
            onClose={handleClose}
            className={classNames('Subscription', {
                isUserInactive,
                disabledExitAnimation,
                hasParent: Boolean(subscription.containerRef),
            })}
            container={isMobile ? undefined : subscription.containerRef}
            stackProps={{ sx: [updatedSelectPlanStep ? style.modalStack : style.oldModalStack] }}
            width={getModalWidth()}
        >
            <Modal.Header sx={style.header}>
                {!subscription.planOptions?.isTrialStartedMode && (
                    <Box display="flex" justifyContent="end" flexGrow="1">
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}
            </Modal.Header>
            {updatedSelectPlanStep ||
            updatedActivationStep ||
            confirmDowngradeStep ||
            currentStep === SubscriptionSteps.WELCOME_STEP ? (
                <Modal.Body>
                    {currentStep === SubscriptionSteps.SELECT_PLAN_STEP && (
                        <SelectPlanStep highlightFeature={subscription.highlightFeature} setPlan={setSelectedPlan} />
                    )}
                    {currentStep === SubscriptionSteps.ACTIVATION_STEP && (
                        <ActivationStep
                            selectedPlan={subscription.plan}
                            lookForApiHandle={subscription.lookForApiHandle}
                            lookForTier={subscription.lookForTier}
                            hideBreadcrumb={subscription.hideBreadcrumb}
                            planOptions={subscription.planOptions}
                            setPlan={setSelectedPlan}
                            onClose={handleClose}
                        />
                    )}
                    {currentStep === SubscriptionSteps.CONFIRM_DOWNGRADE && (
                        <ConfirmDowngrade
                            displayVidsWarning={displayVidsWarning}
                            setDisplayVidsWarning={setDisplayVidsWarning}
                            selectedPlan={subscription.plan}
                            planOptions={subscription.planOptions}
                            onClose={handleClose}
                        />
                    )}
                    {currentStep === SubscriptionSteps.WELCOME_STEP && (
                        <ActivationWelcome oldTier={oldTier} plan={savedPlan} onClose={handleClose} />
                    )}
                </Modal.Body>
            ) : (
                <Modal.Body>
                    <Box className="container" onAnimationEnd={() => setDisabledExitAnimation(false)}>
                        <Box className="body">
                            {currentStep === SubscriptionSteps.SELECT_PLAN_STEP && (
                                <OldSelectPlanStep
                                    setPlan={setSelectedPlan}
                                    onClose={handleClose}
                                    hideCurrentPlan={subscription.hideCurrentPlan}
                                />
                            )}

                            {currentStep === SubscriptionSteps.ACTIVATION_STEP && (
                                <OldActivationStep
                                    selectedPlan={subscription.plan}
                                    lookForApiHandle={subscription.lookForApiHandle}
                                    lookForTier={subscription.lookForTier}
                                    hideBreadcrumb={subscription.hideBreadcrumb}
                                    planOptions={subscription.planOptions}
                                    setPlan={setSelectedPlan}
                                    onClose={handleClose}
                                />
                            )}

                            {currentStep === SubscriptionSteps.CONFIRM_DOWNGRADE && (
                                <OldConfirmDowngrade
                                    selectedPlan={subscription.plan}
                                    planOptions={subscription.planOptions}
                                    onCancel={handleClose}
                                    onClose={handleClose}
                                />
                            )}
                        </Box>
                    </Box>
                </Modal.Body>
            )}
        </Modal>
    )
}
