import { UseMutationOptions, useQueryClient } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { IP_FILTERS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { Id } from 'types'
import { IpFilter } from 'types/Customer'
import { GUID } from 'constants/keys'

export const useDeleteIpFilterMutation = (options?: UseMutationOptions<null, Error, Id>) => {
    const queryClient = useQueryClient()
    const { mutate } = useMutationToastDecorator()

    const key = IP_FILTERS_QUERY

    return useInvalidateMutation<null, Id>(
        key,
        async (id: Id) => mutate(userAPI.deleteIpFilter(id), apiLocale.user.ipFilter.delete),
        {
            ...options,
            onMutate: async (guid) => {
                await queryClient.cancelQueries(key)

                queryClient.setQueryData<IpFilter[]>(
                    key,
                    (previousIpFilter) =>
                        previousIpFilter?.filter((whitelistedDomain) => whitelistedDomain[GUID] !== guid) || [],
                )

                options?.onMutate?.(guid)
            },
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
