import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ExpandedVideoOrFolder, ExpandedVideosWithFolders } from './useVideosWithFolders'

type CustomParams = {
    lookForId?: string
}

type FolderChildrenWithPath = {
    path: Partial<ExpandedVideoOrFolder>[]
    children: ExpandedVideosWithFolders
}

export const useFolderChildrenWithPath = (
    videosWithFolders: ExpandedVideosWithFolders,
    { lookForId }: CustomParams = {},
): FolderChildrenWithPath => {
    const { guid } = useParams()

    const searchInTree = (
        element: Partial<ExpandedVideoOrFolder>,
        match: string,
        path: Partial<ExpandedVideoOrFolder>[] = [],
    ): FolderChildrenWithPath | null => {
        const newPath = element.title ? [...path, { title: element.title, guid: element.guid }] : path

        if (element.guid == match) {
            return { path: newPath, children: element.children as ExpandedVideosWithFolders }
        } else if (element.children != null) {
            let result = null

            for (let i = 0; result == null && i < element.children.length; i++) {
                result = searchInTree(element.children[i], match, newPath)
            }

            return result
        }

        return null
    }

    const folderChildrenWithPath = useMemo(() => {
        const path: Partial<ExpandedVideoOrFolder>[] = []
        const children = videosWithFolders

        if ((!guid && !lookForId) || !videosWithFolders.length) {
            return { path, children }
        }

        const result = searchInTree({ children: videosWithFolders }, String(lookForId || guid))

        return result || { path, children }
    }, [videosWithFolders, guid, lookForId])

    return folderChildrenWithPath
}
