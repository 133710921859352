import { Box, Button, TextField, Typography } from '@mui/material'

import { Modal } from 'design/templates/Modal'
import { Folder } from 'types/Video'

import { useLayout } from 'hooks/utilities/useLayout'
import { useAddFolderMutation } from 'api/mutations'
import { ExpandedVideosWithFolders } from 'design/pages/MyVids'
import { useState } from 'react'
import { getFolderTitle } from 'design/organisms/MyVids/MyVids.utils'
import { ROOT } from 'constants/api.constants'
import style from './CreateNewFolderModal.style'

interface MoveFolderModalProps {
    open: boolean
    onClose: () => void
    guid?: string
    data: ExpandedVideosWithFolders
}

type Extension = {
    children: ExpandedFolder[]
}

type ExpandedFolder = Folder & Extension

export const CreateNewFolderModal = ({ open, onClose, guid, data }: MoveFolderModalProps) => {
    const [newFolderTitle, setNewFolderTitle] = useState<string>('')
    const { isMobile } = useLayout()
    const addFolderMutation = useAddFolderMutation()

    const onSubmit = () => {
        addFolderMutation.mutate({
            folder: { parentFolderGuid: guid || ROOT, title: newFolderTitle || getFolderTitle(data) },
        })
        setNewFolderTitle('')
        onClose()
    }

    const handleClose = () => {
        setNewFolderTitle('')
        onClose()
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="new-folder" aria-describedby="new-folder">
            <Modal.Header onClose={handleClose} nodeTitle>
                <Typography color="text.primary" variant="h7" display="flex" alignItems="center" sx={style.title}>
                    New Folder
                </Typography>
            </Modal.Header>
            <Modal.Body>
                <Box sx={style.content}>
                    <TextField
                        value={newFolderTitle}
                        placeholder="Untitled"
                        fullWidth
                        type="text"
                        onChange={(e) => setNewFolderTitle(e.target.value)}
                    />
                </Box>
            </Modal.Body>
            <Modal.Actions>
                <Button variant="outlined" onClick={handleClose} size={isMobile ? 'small' : 'medium'}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={onSubmit} size={isMobile ? 'small' : 'medium'}>
                    Create
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
