import { Components, Theme } from '@mui/material'

export const MuiTab: Components<Omit<Theme, 'components'>>['MuiTab'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            textTransform: 'capitalize',
            fontWeight: 600,
            fontSize: 16,
            minHeight: 47,
            padding: theme.spacing(3, 4, 2.75),
            transition: `background-color ${theme.transitions.duration.standard}ms`,

            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        }),
    },
}
