import { UseMutationOptions } from 'react-query'

import { authAPI } from 'api/controllers'
import { ProcessPaymentPayload, ProcessPaymentResponse } from 'types/Auth'
import {
    CUSTOMER_QUERY,
    CUSTOMER_SUBSCRIPTION_QUERY,
    PROCESS_PAYMENT_MUTATION,
    SIGN_UP_PLAN_QUERY,
} from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { queryClient, useCustomerQuery, useCustomerSubscriptionQuery } from 'api/queries'

export const useProcessPaymentMutation = (
    options?: UseMutationOptions<ProcessPaymentResponse, unknown, ProcessPaymentPayload>,
) => {
    const { mutate } = useMutationToastDecorator({ showSuccessToast: false, showPendingToast: false })
    const { data: subscriptionQuery } = useCustomerSubscriptionQuery()
    const { data: customerQuery } = useCustomerQuery()

    return useInvalidateMutation<ProcessPaymentResponse, ProcessPaymentPayload>(
        PROCESS_PAYMENT_MUTATION,
        async (payload: ProcessPaymentPayload) => mutate(authAPI.processPayment(payload), {}),
        {
            invalidate: [CUSTOMER_QUERY, CUSTOMER_SUBSCRIPTION_QUERY, SIGN_UP_PLAN_QUERY],
            ...options,
            onSuccess: (data, ...rest) => {
                const subscription = {
                    ...(data.subscription || subscriptionQuery),
                    isFake: false,
                }

                queryClient.setQueryData(CUSTOMER_SUBSCRIPTION_QUERY, subscription)

                const customer = {
                    ...customerQuery,
                    status: subscription.plan.status,
                }
                queryClient.setQueryData(CUSTOMER_QUERY, customer)
                options?.onSuccess?.({ subscription }, ...rest)
            },
        },
    )
}
