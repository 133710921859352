import { add } from 'date-fns'
import getCookieDomain from 'utils/cookie/getCookieDomain'
import { VIDALYTICS_DOMAIN } from 'constants/environment.constants'

const maxAge = 60 * 60 * 24 * 30 // month in seconds

export const COOKIES_OPTIONS = {
    domain: VIDALYTICS_DOMAIN,
    path: '/',
    expires: add(new Date(), { months: 1 }),
    maxAge,
} as const

export const COOKIES_OPTIONS_AUTH = {
    ...COOKIES_OPTIONS,
    domain: getCookieDomain(),
} as const

export const SS_WATTS_KEY = 'SSWATTS'
export const GOOGLE_DRIVE_AUTH_TOKEN = 'GOOGLE_DRIVE_AUTH_TOKEN'
export const REFERRER = 'vidalytics_referrer'
export const ACCESS_TOKEN = 'auth_token'
export const REFRESH_TOKEN = 'refresh_token'
