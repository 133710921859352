import { CustomerSubscriptionPlanTier } from 'types/Customer'
import { OneOf } from 'types/utility'
import { AnalyticsMetricKeys, MetricKeys } from 'design/organisms/StatsTable/types'
import { MetricKeys as AnalyticsKeys } from 'design/pages/Analytics/types'
import { MetricKey, MetricKeysTimeline } from 'design/pages/VidStats/components/PlayerBlock/MetricsMenu/types'
import {
    IntegrationKey,
    FeatureGroup,
    VidSettingsKey,
    GlobalSettingsKey,
    VidStatsKey,
    SidebarRouteKey,
    SidebarRoute,
    AnalyticsHomeKeys,
} from 'constants/keys/featuresAccess.constants'

export type FeatureKey =
    | IntegrationKey
    | VidSettingsKey
    | GlobalSettingsKey
    | VidStatsKey
    | MetricKeys
    | MetricKeysTimeline
    | MetricKey
    | SidebarRouteKey
    | AnalyticsHomeKeys
    | AnalyticsKeys
    | AnalyticsMetricKeys

type TierAccess = OneOf<{
    lockedByDefault: boolean
    unlocked: FeatureKey[]
    locked: FeatureKey[]
}>

type GroupFeatures = {
    [key in CustomerSubscriptionPlanTier]?: TierAccess
}

type FeaturesAccess = {
    [key in FeatureGroup]: GroupFeatures
}

const featuresAccess: FeaturesAccess = {
    [FeatureGroup.Integrations]: {
        [CustomerSubscriptionPlanTier.free]: {
            lockedByDefault: true,
        },
        [CustomerSubscriptionPlanTier.starter]: {
            unlocked: [IntegrationKey.Facebook, IntegrationKey.GoogleAndGtm],
        },
    },
    [FeatureGroup.VidSettings]: {
        [CustomerSubscriptionPlanTier.free]: {
            locked: [
                VidSettingsKey.GenerateAICaptions,
                VidSettingsKey.VidConditions,
                VidSettingsKey.VideoThumbnails,
                VidSettingsKey.ReVisitSeekingBar,
                VidSettingsKey.RapidEngageBar,
                VidSettingsKey.ExitThumbnail,
                VidSettingsKey.ResumePlay,
                VidSettingsKey.SmartPause,
                VidSettingsKey.ExpireVideo,
                VidSettingsKey.PlayGates,
                VidSettingsKey.Tags,
                VidSettingsKey.CountdownTimerRedirection,
                VidSettingsKey.CustomHtml,
                VidSettingsKey.Chapters,
            ],
        },
        [CustomerSubscriptionPlanTier.starter]: {
            locked: [
                VidSettingsKey.GenerateAICaptions,
                VidSettingsKey.VidConditions,
                VidSettingsKey.ReVisitSeekingBar,
                VidSettingsKey.ExitThumbnail,
                VidSettingsKey.ExpireVideo,
                VidSettingsKey.Tags,
                VidSettingsKey.CountdownTimerRedirection,
                VidSettingsKey.CustomHtml,
            ],
        },
        [CustomerSubscriptionPlanTier.pro]: {
            locked: [VidSettingsKey.GenerateAICaptions],
        },
        [CustomerSubscriptionPlanTier.proIv]: {
            locked: [VidSettingsKey.GenerateAICaptions],
        },
    },
    [FeatureGroup.Conversions]: {
        [CustomerSubscriptionPlanTier.free]: {
            lockedByDefault: true,
        },
        [CustomerSubscriptionPlanTier.starter]: {
            lockedByDefault: true,
        },
    },
    [FeatureGroup.SmartVids]: {
        [CustomerSubscriptionPlanTier.free]: {
            lockedByDefault: true,
        },
        [CustomerSubscriptionPlanTier.starter]: {
            lockedByDefault: true,
        },
        [CustomerSubscriptionPlanTier.pro]: {
            lockedByDefault: true,
        },
    },
    [FeatureGroup.GlobalSettings]: {
        [CustomerSubscriptionPlanTier.free]: {
            locked: [
                GlobalSettingsKey.PlayGate,
                GlobalSettingsKey.PublicAPI,
                GlobalSettingsKey.DomainWhitelisting,
                GlobalSettingsKey.PlayGateEmailValidation,
            ],
        },
        [CustomerSubscriptionPlanTier.starter]: {
            locked: [
                GlobalSettingsKey.PlayGate,
                GlobalSettingsKey.PublicAPI,
                GlobalSettingsKey.DomainWhitelisting,
                GlobalSettingsKey.PlayGateEmailValidation,
            ],
        },
        [CustomerSubscriptionPlanTier.pro]: {
            locked: [
                GlobalSettingsKey.PublicAPI,
                GlobalSettingsKey.DomainWhitelisting,
                GlobalSettingsKey.PlayGateEmailValidation,
            ],
        },
        [CustomerSubscriptionPlanTier.proIv]: {
            locked: [
                GlobalSettingsKey.PublicAPI,
                GlobalSettingsKey.DomainWhitelisting,
                GlobalSettingsKey.PlayGateEmailValidation,
            ],
        },
        [CustomerSubscriptionPlanTier.premium]: {
            locked: [GlobalSettingsKey.PlayGateEmailValidation],
        },
        [CustomerSubscriptionPlanTier.premiumNc]: {
            locked: [GlobalSettingsKey.PlayGateEmailValidation],
        },
    },
    [FeatureGroup.Intercom]: {
        [CustomerSubscriptionPlanTier.free]: {
            lockedByDefault: true,
        },
        [CustomerSubscriptionPlanTier.starter]: {
            lockedByDefault: true,
        },
    },
    [FeatureGroup.VidSummary]: {
        [CustomerSubscriptionPlanTier.free]: {
            locked: [
                MetricKeys.conversionCount,
                MetricKeys.conversionRate,
                MetricKeys.revenueAverage,
                MetricKeys.pgOptInCount,
                MetricKeys.pgOptInRate,
                MetricKeys.revenue,
                MetricKeys.revenuePerViewer,
                MetricKeys.bounceRate,
            ],
        },
        [CustomerSubscriptionPlanTier.starter]: {
            locked: [
                MetricKeys.conversionCount,
                MetricKeys.conversionRate,
                MetricKeys.revenueAverage,
                MetricKeys.pgOptInCount,
                MetricKeys.pgOptInRate,
                MetricKeys.revenue,
                MetricKeys.revenuePerViewer,
                MetricKeys.bounceRate,
            ],
        },
    },
    [FeatureGroup.VidStatsTimeline]: {
        [CustomerSubscriptionPlanTier.free]: {
            locked: [
                MetricKeysTimeline.conversionCount,
                MetricKeysTimeline.conversionRate,
                MetricKeysTimeline.revenueAverage,
                MetricKeysTimeline.pgOptInCount,
                MetricKeysTimeline.pgOptInRate,
                MetricKeysTimeline.revenue,
                MetricKeysTimeline.revenuePerViewer,
                MetricKeysTimeline.bounceRate,
            ],
        },
        [CustomerSubscriptionPlanTier.starter]: {
            locked: [
                MetricKeysTimeline.conversionCount,
                MetricKeysTimeline.conversionRate,
                MetricKeysTimeline.revenueAverage,
                MetricKeysTimeline.pgOptInCount,
                MetricKeysTimeline.pgOptInRate,
                MetricKeysTimeline.revenue,
                MetricKeysTimeline.revenuePerViewer,
                MetricKeysTimeline.bounceRate,
            ],
        },
    },
    [FeatureGroup.VidStatsGraph]: {
        [CustomerSubscriptionPlanTier.free]: {
            unlocked: [MetricKey.audience],
        },
        [CustomerSubscriptionPlanTier.starter]: {
            unlocked: [MetricKey.audience],
        },
    },
    [FeatureGroup.VidStats]: {
        [CustomerSubscriptionPlanTier.free]: {
            locked: [VidStatsKey.StatsSegments, VidStatsKey.CompareVideos, VidStatsKey.DownloadStats],
        },
        [CustomerSubscriptionPlanTier.starter]: {
            locked: [VidStatsKey.StatsSegments, VidStatsKey.CompareVideos, VidStatsKey.DownloadStats],
        },
    },
    [FeatureGroup.Sidebar]: {
        [CustomerSubscriptionPlanTier.free]: {
            locked: [SidebarRoute.SmartVids, SidebarRoute.Conversions, SidebarRoute.Integrations],
        },
        [CustomerSubscriptionPlanTier.starter]: {
            locked: [SidebarRoute.SmartVids, SidebarRoute.Conversions],
        },
        [CustomerSubscriptionPlanTier.pro]: {
            locked: [SidebarRoute.SmartVids],
        },
    },
    [FeatureGroup.AccountUsersLimit]: {
        [CustomerSubscriptionPlanTier.premium]: {
            lockedByDefault: true,
        },
    },
    [FeatureGroup.UserSettings]: {
        [CustomerSubscriptionPlanTier.free]: {
            lockedByDefault: true,
        },
        [CustomerSubscriptionPlanTier.starter]: {
            lockedByDefault: true,
        },
        [CustomerSubscriptionPlanTier.pro]: {
            lockedByDefault: true,
        },
        [CustomerSubscriptionPlanTier.proIv]: {
            lockedByDefault: true,
        },
    },
    [FeatureGroup.AnalyticsHome]: {
        [CustomerSubscriptionPlanTier.free]: {
            locked: [
                AnalyticsHomeKeys.filtering,
                AnalyticsKeys.conversionRate,
                AnalyticsKeys.revenueAverage,
                AnalyticsKeys.revenue,
                AnalyticsKeys.revenuePerViewer,
                AnalyticsKeys.bounceRate,
                AnalyticsKeys.conversionCount,
                AnalyticsKeys.pgOptInRate,
                AnalyticsMetricKeys.conversionCount,
                AnalyticsMetricKeys.conversionRate,
                AnalyticsMetricKeys.revenueAverage,
                AnalyticsMetricKeys.pgOptInRate,
                AnalyticsMetricKeys.revenue,
                AnalyticsMetricKeys.revenuePerViewer,
                AnalyticsMetricKeys.bounceRate,
            ],
        },
        [CustomerSubscriptionPlanTier.starter]: {
            locked: [
                AnalyticsHomeKeys.filtering,
                AnalyticsKeys.conversionRate,
                AnalyticsKeys.revenueAverage,
                AnalyticsKeys.revenue,
                AnalyticsKeys.revenuePerViewer,
                AnalyticsKeys.bounceRate,
                AnalyticsKeys.conversionCount,
                AnalyticsKeys.pgOptInRate,
                AnalyticsMetricKeys.conversionCount,
                AnalyticsMetricKeys.conversionRate,
                AnalyticsMetricKeys.revenueAverage,
                AnalyticsMetricKeys.pgOptInRate,
                AnalyticsMetricKeys.revenue,
                AnalyticsMetricKeys.revenuePerViewer,
                AnalyticsMetricKeys.bounceRate,
            ],
        },
    },
}

export default featuresAccess
