export default {
    show: 'Show',
    byDefault: 'by default',
    defaultVideo: 'Viewers will default to this video in case they do not match any of the configured conditions.',
    addCondition: 'Add Condition',
    if: 'if',
    selectAttribute: 'Select attribute',
    selectValue: 'Select value',
    urlParamsValue: 'e.g. source=email',
    urlParamsHelpTooltip: `Enter the URL parameter name and value in the format: [name]=[value] e.g., utm_source=facebook`,
}
