import { useEffect } from 'react'
import { Stack } from '@mui/material'

import mixpanel from 'mixpanel-browser'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import { HelpMenu } from 'design/templates/Sidebar/components/UserActions/HelpMenu/HelpMenu'
import { Menu } from './Menu/Menu'
import style from './UserActions.style'

interface UserActionsProps {
    open: boolean
}

export const UserActions = ({ open }: UserActionsProps) => {
    useEffect(() => {
        mixpanel.track_links('#sidebarHelpLink', MIXPANEL_EVENTS.SIDEBAR_HELP_CLICKED)
    }, [])

    return (
        <Stack sx={style.stack} data-testid="UserActionsBar">
            <Menu openSidebar={open} />
            <HelpMenu openSidebar={open} />
        </Stack>
    )
}
