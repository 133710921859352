import { FeatureLockedOpenModalPayload } from 'design/templates/Modal'
import { SubscriptionCtaSource } from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import { SubscriptionCategory } from 'types/Customer'
import { VidSettingsKey } from 'constants/keys/featuresAccess.constants'
import { COMMON_CONFIG } from './common'

const COMMON_VID_SETTINGS_CONFIG = {
    ...COMMON_CONFIG,
    source: SubscriptionCtaSource.VID_SETTINGS,
}

export const AICaptionsLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    planCategory: SubscriptionCategory.premium,
    title: 'Upgrade to Unlock AI Captions!',
    promoMediaSrc: '/img/subscription/ai-captions.png',
    description:
        'Supercharge your videos with the power of AI to increase viewer engagement. Generate closed captions with the click of a button and let Vidalytics do the rest!',
}

export const chaptersLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Chapters!',
    promoMediaSrc: '/img/subscription/chapters.png',
    description:
        'Section your video with chapters to show your viewers what they can expect next, allowing them to jump to the specific parts that they know will interest them more.',
}

export const videoThumbnailsLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Video Thumbnails!',
    promoMediaSrc: '/img/subscription/vid-thumbnails.png',
    description:
        'Video Thumbnails are not only more engaging than static thumbnails, but they allow you to highlight key moments from your video, directly in the thumbnail.',
}

export const exitThumbnailsLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Exit Thumbnails!',
    description:
        'Re-engage your viewers when they’re about to bounce with Use Exit Thumbnails. When they pause your video, display a custom, attention-grabbing thumbnail and copy to draw them back in.',
    promoMediaSrc: '/img/subscription/vid-thumbnails.png',
}

export const playGatesLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Play Gates!',
    promoMediaSrc: '/img/subscription/play-gates.png',
    description:
        'You could have an opt-in right on top of your video and be collecting your viewers’ names, emails and phone numbers. Yes, right now, with a simple click... Heck, you can even collect those if they try to leave your video... What do you say? 😏',
}

export const tagsLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Tagging on Your Videos!',
    description:
        'Tag your viewers in your CRM, email or SMS platform when they watch to a specific point in one of your videos... So your messaging is personalized specifically for what they have already seen. 🎯',
}

export const vidConditionsLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Vid Conditions!',
    promoMediaSrc: '/img/subscription/vid-conditions.png',
    description:
        'Drive conversions by directing viewers to tailored videos based on parameters you set—vertical for mobile, horizontal for desktop, or clincher-copy for returning viewers.',
}

export const resumePlayLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Resume Play!',
    description:
        'With Resume Play, viewers can pick up exactly where they left off when they return to your page (or choose to start over). This reduces boredom, boosts engagement, and drives conversions.',
}

export const rapidEngageBarLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Rapid Engage Bar!',
    description:
        'Hook your viewers attention instantly with a simulated seeking bar which starts off really fast, to make your video seem shorter in the beginning. Then, the bar gradually slows down closer to the actual speed of your video as it progresses.',
}

export const reVisitSeekingBarLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock ReVisit Seeking Bar!',
    description:
        'Only show a seeking bar to your RETURNING viewers, so they can jump back to where they left off, which is great for retargeting traffic returning to your page.',
}

export const smartPauseLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Smart Pause!',
    description:
        'Automatically pause video playback when viewers switch tabs to ensure you have their full attention when they return.',
}

export const expireVideoLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Expire Video!',
    description: 'Create a sense of urgency by setting your videos to expire at a specific date or time.',
}

export const countdownTimerLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Countdown Timer!',
    description:
        'Did you know 25% of conversions happen after the video ends? Nudge viewers to take the next step in your funnel automatically. After the video ends, display a customizable message with a countdown timer that redirects to any URL you choose when it hits zero.',
}

export const customHTMLLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Custom HTML!',
    description:
        'Place ANY content you want below your video at a specific time, with Custom HTML. Reveal order boxes, opt-in forms, copy, or anything else.',
}

export const passwordProtectionLockedModalConfig = {
    ...COMMON_VID_SETTINGS_CONFIG,
    title: 'Upgrade to Unlock Password Protection!',
    description:
        'Boost security and privacy by password-protecting you video content so that only authorized viewers with the correct password can access it.',
}

export const VidSettingsLockedModalConfigs: Record<VidSettingsKey, FeatureLockedOpenModalPayload> = {
    [VidSettingsKey.GenerateAICaptions]: AICaptionsLockedModalConfig,
    [VidSettingsKey.Chapters]: chaptersLockedModalConfig,
    [VidSettingsKey.VideoThumbnails]: videoThumbnailsLockedModalConfig,
    [VidSettingsKey.ExitThumbnail]: exitThumbnailsLockedModalConfig,
    [VidSettingsKey.PlayGates]: playGatesLockedModalConfig,
    [VidSettingsKey.Tags]: tagsLockedModalConfig,
    [VidSettingsKey.VidConditions]: vidConditionsLockedModalConfig,
    [VidSettingsKey.ResumePlay]: resumePlayLockedModalConfig,
    [VidSettingsKey.RapidEngageBar]: rapidEngageBarLockedModalConfig,
    [VidSettingsKey.ReVisitSeekingBar]: reVisitSeekingBarLockedModalConfig,
    [VidSettingsKey.SmartPause]: smartPauseLockedModalConfig,
    [VidSettingsKey.ExpireVideo]: expireVideoLockedModalConfig,
    [VidSettingsKey.CountdownTimerRedirection]: countdownTimerLockedModalConfig,
    [VidSettingsKey.CustomHtml]: customHTMLLockedModalConfig,
}
