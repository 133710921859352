import { useAppDispatch } from 'App'
import { useAgencyAccess } from 'design/pages/AgencyAccount/AgencyAccount.hooks'
import { useUpgradeToUnlockStatsModal } from 'design/pages/VidStats/hooks/useUpgradeToUnlockStatsModal'
import { FeatureLockedOpenModalPayload, openAgencyLockedFeaturesModal } from 'design/templates/Modal'
import { openModal } from 'design/templates/Modal/ModalTypes/modal.slice'

import {
    openSubscriptionModal,
    SubscriptionCtaSource,
    SubscriptionFeatureKey,
} from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { getLockedModalConfig } from 'constants/subscriptions/lockedModalConfigs'
import { FeatureGroup } from 'constants/keys/featuresAccess.constants'
import { FeatureKey } from 'constants/featuresAccess.constants'

export interface SubscriptionConfig {
    highlightFeature?: SubscriptionFeatureKey
}

export interface UpgradeCallbackProps {
    featureGroup?: FeatureGroup
    featureKey?: FeatureKey
    config?: FeatureLockedOpenModalPayload
}

const useUpgradeCallback = (source = SubscriptionCtaSource.NOT_DEFINED, subscriptionConfig?: SubscriptionConfig) => {
    const dispatch = useAppDispatch()
    const { agencyAccessLocked } = useAgencyAccess()
    const { show: showUpgradeToUnlockModal } = useUpgradeToUnlockStatsModal()
    const { freshUpgradeFlow } = useFeatureFlags()

    const handleClick = (upgradeProps?: UpgradeCallbackProps) => {
        const { featureGroup, featureKey, config } = upgradeProps || {}

        if (agencyAccessLocked) {
            return dispatch(openAgencyLockedFeaturesModal({ fallback: false }))
        }

        if (source === SubscriptionCtaSource.VID_STATS) {
            return showUpgradeToUnlockModal()
        }
        const lockedModalConfig = getLockedModalConfig(featureGroup, featureKey) || config

        dispatch(
            lockedModalConfig && freshUpgradeFlow
                ? openModal(lockedModalConfig)
                : openSubscriptionModal({ ctaSource: source, ...subscriptionConfig }),
        )
    }

    return handleClick
}

export default useUpgradeCallback
