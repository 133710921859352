import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { ActionNotifications } from 'design/pages/VidSettings/constants'

export type VideoSettingsSliceProps = {
    isPlayerRendered: boolean
    actionNotification: ActionNotifications
    selectedVideoGuid?: string
}

const initialState: VideoSettingsSliceProps = {
    isPlayerRendered: false,
    actionNotification: ActionNotifications.empty,
}

export const vidSettingsSlice = createSlice({
    name: 'vidSettings',
    initialState,
    reducers: {
        setIsPlayerRendered: (state, action: PayloadAction<boolean>) => {
            state.isPlayerRendered = action.payload
        },
        setActionNotification: (state, action: PayloadAction<ActionNotifications>) => {
            state.actionNotification = action.payload
        },
        setSelectedVidGuid: (state, action: PayloadAction<string | undefined>) => {
            state.selectedVideoGuid = action.payload
        },
        resetState: () => initialState,
    },
})

export const { setIsPlayerRendered, setActionNotification, resetState, setSelectedVidGuid } = vidSettingsSlice.actions

export const selectVideoSettingsState = (state: RootState) => state.videoSettings

export const videoSettings = vidSettingsSlice.reducer
