import { SxProps, Theme } from '@mui/material'

export const switchFormStyles: SxProps<Theme> = {
    display: 'flex',
    width: '100%',
    gap: 8,
    alignItems: 'center',

    '.MuiFormControlLabel': {
        '&-root': {
            gap: 8,
            margin: 0,
        },

        '&-label': {
            color: (theme) => theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
        },
    },
}

export default {
    switchFormStyles,
}
