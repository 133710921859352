import { once } from 'lodash'
import { useParams } from 'react-router-dom'
import { useState, createContext, ReactNode, Dispatch, SetStateAction, useContext, useRef, useEffect } from 'react'

import { ThumbnailSource } from 'types/VidSettings'
import { useUpdateVideoMutation } from 'api/mutations'
import { ThumbnailType, VideoGuid } from 'types/Video'
import { locale } from 'locales'
import { SettingsTab } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarTabs'

interface ContextState {
    videoGuid?: VideoGuid
    thumbnailFromVideoType?: ThumbnailType
    activeTab: SettingsTab
    integrationsExist: boolean
    notificationMessage: string
    updateVideoMutation: ReturnType<typeof useUpdateVideoMutation>
    setNotificationMessage: Dispatch<SetStateAction<string>>
    setThumbnailFromVideoType: Dispatch<SetStateAction<ThumbnailType | undefined>>
    setActiveTab: Dispatch<SetStateAction<SettingsTab>>
    setIntegrationsExist: Dispatch<SetStateAction<boolean>>
    thumbnailSource: ThumbnailSource
    setThumbnailSource: Dispatch<SetStateAction<ThumbnailSource>>
    thumbnailPauseGuid: string
    setThumbnailPauseGuid: Dispatch<SetStateAction<string>>
    pauseThumbnails: {
        guid?: string
        source?: string
        from?: number
        to?: number
    }[]
    setPauseThumbnails: Dispatch<
        SetStateAction<
            {
                guid?: string
                source?: string
                from?: number
                to?: number
            }[]
        >
    >
}

const createVidSettingsContext = once(() => createContext({} as ContextState))
const useVidSettingsContext = () => useContext(createVidSettingsContext())

const HIDE_NOTIFICATION_TM = 3000

type VidSettingsProviderProps = {
    children?: ReactNode
}

function VidSettingsProvider({ children }: VidSettingsProviderProps) {
    const { videoGuid, stepVidId } = useParams()
    const [thumbnailFromVideoType, setThumbnailFromVideoType] = useState<ThumbnailType | undefined>(undefined)
    const [thumbnailSource, setThumbnailSource] = useState<ThumbnailSource>(ThumbnailSource.image)
    const [thumbnailPauseGuid, setThumbnailPauseGuid] = useState<string>('')
    const [integrationsExist, setIntegrationsExist] = useState(true)
    const [pauseThumbnails, setPauseThumbnails] = useState<
        {
            guid?: string
            source?: string
            from?: number
            to?: number
        }[]
    >([])
    const [activeTab, setActiveTab] = useState<SettingsTab>(SettingsTab.style)

    const [notificationMessage, setNotificationMessage] = useState('')
    const notificationTimerRef = useRef<ReturnType<typeof setTimeout>>()
    const updateVideoMutation = useUpdateVideoMutation(String(videoGuid || stepVidId), {
        onSuccess: () => {
            setNotificationMessage(locale.messages.info.saved)
            notificationTimerRef.current = setTimeout(() => setNotificationMessage(''), HIDE_NOTIFICATION_TM)
        },
    })

    useEffect(() => {
        return () => {
            if (notificationTimerRef.current) {
                clearTimeout(notificationTimerRef.current)
            }
        }
    }, [])

    const vidSettingsProps = {
        videoGuid,
        thumbnailFromVideoType,
        activeTab,
        integrationsExist,
        notificationMessage,
        updateVideoMutation,
        setNotificationMessage,
        setThumbnailFromVideoType,
        setActiveTab,
        setIntegrationsExist,
        thumbnailSource,
        setThumbnailSource,
        thumbnailPauseGuid,
        setThumbnailPauseGuid,
        pauseThumbnails,
        setPauseThumbnails,
    }

    const VidSettingsContext = createVidSettingsContext()

    return <VidSettingsContext.Provider value={vidSettingsProps}>{children}</VidSettingsContext.Provider>
}

export { VidSettingsProvider, useVidSettingsContext }
