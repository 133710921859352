import { RefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _noop from 'lodash/noop'
import _get from 'lodash/get'

import { useToast } from 'design/organisms/ToastProvider'
import { t } from 'i18next'

export type ChargifyToken = string | null
export interface ChargifyError {
    message: string
    invalidFields: string[]
}

export const useShowErrorAddCard = () => {
    const { showToast } = useToast()
    return (message: string) =>
        showToast({
            title: t("We couldn't process your card."),
            message,
            type: 'error',
            SnackbarProps: {
                autoHideDuration: null,
                onClose: _noop,
            },
            AlertProps: {
                onClose: _noop,
            },
        })
}

export const useOnPaymentError = () => {
    const { showToast } = useToast()
    const { t } = useTranslation()

    const showErrorAddCard = useShowErrorAddCard()

    return (chargifyError?: ChargifyError) => {
        const isInvalid = Boolean(_get(chargifyError, 'invalidFields.length'))

        if (isInvalid) {
            return showToast({
                message: t('Some fields are not valid.'),
                type: 'error',
            })
        }

        showErrorAddCard(t('Please check your details or try a different card'))
    }
}

export const useChagifyTokenStatus = () => {
    const { showToast } = useToast()
    const onPaymentError = useOnPaymentError()

    const chargifyTokenSuccess = (onSucess: (token: string) => void) => (token: ChargifyToken) => {
        if (!token) {
            showToast({
                message: 'Chargify token is not available',
                type: 'error',
            })
            return
        }

        onSucess(token)
    }

    return { chargifyTokenError: onPaymentError, chargifyTokenSuccess }
}

const INTERVAL_TM = 50 // up to 100 ticks until resolving
const TIMEOUT_TM = 5000

export const useChagifyFormLoadingState = (ref: RefObject<HTMLFormElement>) => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const interval = setInterval(() => {
            if (ref.current?.querySelector('iframe') !== null) {
                setIsLoading(false)
                if (interval) {
                    clearInterval(interval)
                }
            }
        }, INTERVAL_TM)

        setTimeout(() => {
            if (interval) {
                clearInterval(interval)
            }

            if (isLoading) {
                setIsLoading(false)
            }
        }, TIMEOUT_TM)
    }, [])

    return { isLoading }
}
