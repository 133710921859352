import { UseMutationOptions } from 'react-query'

import { authAPI } from 'api/controllers'
import { CREATE_ACTIVE_CAMPAIGN_CONTACT_MUTATION } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { CreateActiveCampaignContactPayload } from 'types/Auth'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useCreateActiveCampaignContactMutation = (
    options?: UseMutationOptions<unknown, any, CreateActiveCampaignContactPayload>,
) => {
    const { mutate } = useMutationToastDecorator({
        showPendingToast: false,
        showSuccessToast: false,
        showErrorToast: false,
    })

    return useInvalidateMutation<unknown, CreateActiveCampaignContactPayload>(
        CREATE_ACTIVE_CAMPAIGN_CONTACT_MUTATION,
        async (payload: CreateActiveCampaignContactPayload) => mutate(authAPI.createActiveCampaignContact(payload), {}),
        options,
    )
}
