import { SxProps } from '@mui/material'

const img: SxProps = {
    borderRadius: 4,
    backgroundColor: 'background.paper',
    flex: 5,
}

export default {
    img,
}
