import { useEffect, useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import _toNumber from 'lodash/toNumber'

import { useVideosQuery, useCustomerSubscriptionQuery } from 'api/queries'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { route } from 'constants/routes'
import { isEnterprisePlan } from 'constants/plan.constants'
import useUserStatuses from './useUserStatuses'

export const STATIC_TRIAL_UPLOAD_LIMIT = 3

export const useUploadLimit = (considerQueriesStatus = true) => {
    const location = useLocation()
    const [isFirstUpload, setIsFirstUpload] = useState<boolean | null>(location.pathname === route.welcome.index)
    const [isNoUploads, setIsNoUploads] = useState<boolean>(false)
    const { isLoading: isLoadingSubscription, data: subscription } = useCustomerSubscriptionQuery()
    const { isLoading: isLoadingVideos, data: videos } = useVideosQuery()
    const { isUserOnTrial } = useUserStatuses()
    const { trialAutoDowngradeAfterTrialWhenUserHasNoCard } = useFeatureFlags()

    const isLoading = considerQueriesStatus ? isLoadingSubscription || isLoadingVideos : true

    const uploadLimitReached = useMemo(() => {
        if (isLoading) return considerQueriesStatus

        if (!subscription || !videos) return true

        if (
            isUserOnTrial && trialAutoDowngradeAfterTrialWhenUserHasNoCard
                ? isEnterprisePlan(subscription.plan)
                : subscription.videoSpaceLimit < 0 || !subscription.plan.isFree
        )
            return false

        const uploadLimit =
            isUserOnTrial && trialAutoDowngradeAfterTrialWhenUserHasNoCard
                ? STATIC_TRIAL_UPLOAD_LIMIT
                : subscription.videoSpaceLimit

        return videos.filter((v) => !v.isDemoVideo).length >= uploadLimit
    }, [
        subscription,
        videos,
        isLoading,
        isUserOnTrial,
        considerQueriesStatus,
        trialAutoDowngradeAfterTrialWhenUserHasNoCard,
    ])

    useEffect(() => {
        if (isLoading) {
            return
        }

        if (location.pathname !== route.welcome.index || _toNumber(subscription?.videoSpaceUsed) > 0) {
            setIsFirstUpload(false)
        }
    }, [location, subscription, isLoading])

    useEffect(() => {
        if (!isLoading && subscription?.videoSpaceUsed && subscription?.videoSpaceUsed === 0) {
            setIsNoUploads(true)
            return
        }

        setIsNoUploads(false)
    }, [location, subscription, isLoading])

    return { isLoading, isFirstUpload, isNoUploads, uploadLimitReached }
}

export default useUploadLimit
