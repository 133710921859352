import { useMemo } from 'react'
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined'
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined'
import MultiDirectionalOutline from 'assets/icons/MultiDirectionalOutline'
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import ShuffleOutlinedIcon from '@mui/icons-material/ShuffleOutlined'
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined'

import { useCheckAccess } from 'utils'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import AnalyticsSidebarIcon from 'assets/icons/AnalyticsSidebarIcon'
import { FeatureGroup, SidebarRouteKey } from 'constants/keys/featuresAccess.constants'
import { route, StatsEntity } from 'constants/routes'
import {
    conversionLockedModalConfig,
    integrationsLockedModalConfig,
    smartVidsLockedModalConfig,
} from 'constants/subscriptions'

export const useSidebarItems = (excludeItems?: string[]) => {
    const { groupStatistic } = useFeatureFlags()

    const SIDEBAR_ITEMS = [
        {
            label: 'Dashboard',
            Icon: SpeedOutlinedIcon,
            route: route.dashboard,
            isInternal: true,
        },
        {
            label: 'My Vids',
            Icon: VideoLibraryOutlinedIcon,
            route: route.video.catalog,
            isInternal: true,
        },
        {
            label: 'Analytics',
            isNew: true,
            Icon: AnalyticsSidebarIcon,
            route: groupStatistic ? route.statistic.groupStats : route.analytics.root(StatsEntity.analytics),
            isInternal: true,
        },
        {
            label: 'Smart Vids',
            Icon: MultiDirectionalOutline,
            isNew: false,
            route: route.smartVideo.catalog,
            isInternal: true,
            subscriptionCtaConfig: smartVidsLockedModalConfig,
        },
        {
            label: 'Conversions',
            Icon: AttachMoneyRoundedIcon,
            route: route.conversion,
            isInternal: true,
            subscriptionCtaConfig: conversionLockedModalConfig,
        },
        {
            label: 'Integrations',
            Icon: ShuffleOutlinedIcon,
            route: route.integrations.index,
            isInternal: true,
            subscriptionCtaConfig: integrationsLockedModalConfig,
        },

        {
            label: 'Training Center',
            Icon: SupportAgentOutlinedIcon,
            route: route.trainingCenter.index,
            isInternal: true,
        },
    ]

    const checkAccess = useCheckAccess(FeatureGroup.Sidebar)
    const sidebarItems = useMemo(
        () => SIDEBAR_ITEMS.map((item) => ({ ...item, isLocked: checkAccess(item.route as SidebarRouteKey) })),
        [checkAccess],
    )

    return {
        items: sidebarItems?.filter((el) => !(excludeItems || []).includes(el.label)) || [],
    }
}
