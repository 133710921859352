import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'

type ActivePlayer = 'main' | 'compared' | null

export type SelectedVideoGuid = string | null | undefined

export type VideoCompareState = {
    selectorOpen: boolean
    selectedVidGuid: SelectedVideoGuid
    isPlaying: boolean
    activePlayer: ActivePlayer
}

const initialState: VideoCompareState = {
    selectorOpen: false,
    selectedVidGuid: null,
    isPlaying: false,
    activePlayer: null,
}

export const videoCompareSliceNew = createSlice({
    name: 'videoCompareNew',
    initialState,
    reducers: {
        setSelectorState: (state, action: PayloadAction<boolean>) => {
            state.selectorOpen = action.payload

            if (action.payload) {
                state.isPlaying = false
            }
        },
        setSelectedVidGuid: (state, action: PayloadAction<SelectedVideoGuid>) => {
            state.selectedVidGuid = action.payload
        },
        setIsPlaying: (state, action: PayloadAction<boolean>) => {
            state.isPlaying = action.payload
        },
        setActivePlayer: (state, action: PayloadAction<ActivePlayer>) => {
            if (state.activePlayer === action.payload) {
                state.activePlayer = null
                state.isPlaying = false
            } else {
                state.activePlayer = action.payload
                state.isPlaying = true
            }
        },
        resetState: () => initialState,
    },
})

export const { setSelectorState, setSelectedVidGuid, setIsPlaying, setActivePlayer, resetState } =
    videoCompareSliceNew.actions

export const selectVideoCompareState = (state: RootState) => state.videoCompareNew

export const videoCompareNew = videoCompareSliceNew.reducer
