import { Components, Theme } from '@mui/material'

export const MuiTabs: Components<Omit<Theme, 'components'>>['MuiTabs'] = {
    styleOverrides: {
        scrollButtons: {
            '&.Mui-disabled': {
                opacity: 0.3,
            },
        },
    },
    defaultProps: {
        variant: 'scrollable',
        scrollButtons: 'auto',
        allowScrollButtonsMobile: true,
    },
}
