import { useCookies } from 'react-cookie'
import { useSearchParams } from 'react-router-dom'

import { VDLY } from 'design/organisms/SignUp/constants'

export const useVdly = () => {
    const [searchParams] = useSearchParams()
    const [cookies] = useCookies()

    return searchParams.get(VDLY) || localStorage.getItem(VDLY) || cookies[VDLY] || null
}
