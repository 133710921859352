import { SxProps, Theme } from '@mui/material'

export default {
    header: {
        position: 'absolute',
        zIndex: 1,
        right: 16,
        top: 16,
    },
    oldModalStack: {
        position: 'relative',
        width: 1,
    },
    modalStack: {
        backgroundColor: 'secondary.main',
        borderRadius: 4,
        p: 10,
        position: 'relative',
        width: 1,
        maxWidth: 1240,
        boxShadow: (theme) => theme.shadows[24],
    },
} satisfies Record<string, SxProps<Theme>>
