import { Stack, Typography } from '@mui/material'
import { ReactNode, useMemo } from 'react'

import CheckmarkContained from 'assets/icons/CheckmarkContained'
import { SubscriptionFeatureKey } from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import { CustomTypographyVariant } from 'styles/theme/typography'
import style from './Feature.style'

interface FeatureProps {
    highlightFeature?: SubscriptionFeatureKey
    featureTitle: string
    icon?: ReactNode
    typographyVariant?: CustomTypographyVariant
}

export const Feature = ({ featureTitle, highlightFeature, icon, typographyVariant = 'body1' }: FeatureProps) => {
    const shouldBeHighlighted = useMemo(() => {
        if (!highlightFeature) return false

        const includesBandwidthOrPlaysAndAtLeastOneNumber = /(?=.*\d)(.*bandwidth.*|.*plays.*|.*bandwith.*)/i

        return includesBandwidthOrPlaysAndAtLeastOneNumber.test(featureTitle)
    }, [featureTitle, highlightFeature])

    const Icon = icon || <CheckmarkContained color={shouldBeHighlighted ? 'accent' : 'primary'} />

    return (
        <Stack sx={[style.root]}>
            {Icon}
            <Typography variant={typographyVariant} sx={[shouldBeHighlighted && style.highlightedText]}>
                {featureTitle}
            </Typography>
        </Stack>
    )
}
