import { SxProps, Theme } from '@mui/material'

export default {
    errorLink: {
        color: 'inherit',
        textDecoration: 'underline',

        '&:hover': {
            color: 'inherit',
            textDecoration: 'none',
        },
    },
    haveAccountLogin: (theme: Theme) => ({
        mt: 3,
        mb: -4,
        display: 'flex',
        justifyContent: 'center',

        [theme.breakpoints.up('tablet')]: {
            display: 'none',
        },
    }),
    close: {
        position: 'absolute',
        right: 12,
        top: 8,
        cursor: 'pointer',
        fontSize: 20,
    },
} satisfies Record<string, SxProps<Theme>>
