import { useState } from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material'

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

import { useDeletePlayGateMutation } from 'api/mutations'
import { Modal } from 'design/templates/Modal'
import { VideoPlayGateConfig } from 'types/VideoPlayGate'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { capitalize } from 'lodash'

import styles from './styles'
import './index.scss'

interface PlayGatesCardProps {
    playGate: VideoPlayGateConfig
    disabled?: boolean
    onEditClick: (playGate: VideoPlayGateConfig) => void
    onDeleteClick: () => void
}

const PlayGatesCard = ({ playGate, disabled, onEditClick, onDeleteClick }: PlayGatesCardProps) => {
    const deletePlayGateMutation = useDeletePlayGateMutation()
    const [deleteGateModal, setDeleteGateModal] = useState(false)

    return (
        <Box sx={styles.card} className="PlayGateCard Card">
            <Box className="header">
                <Typography className="title">{playGate?.textTop?.replace(/<br>/g, '\n')}</Typography>
                <Box className="btns">
                    <IconButton className="edit" onClick={() => onEditClick(playGate)}>
                        <EditRoundedIcon />
                    </IconButton>
                    {!disabled && (
                        <IconButton
                            className="delete"
                            onClick={() => {
                                setDeleteGateModal(true)
                            }}
                        >
                            <DeleteOutlineRoundedIcon />
                        </IconButton>
                    )}
                </Box>
            </Box>

            {[{ label: capitalize(playGate.type), value: playGate.type === 'time' ? playGate.time : '' }].map((row) => (
                <Box className="row" key={row.label}>
                    <Typography>{row.label}</Typography>
                    <Typography>{row.value}</Typography>
                </Box>
            ))}
            <Modal open={deleteGateModal} onClose={() => setDeleteGateModal(false)}>
                <Modal.Header
                    title="Are you sure you want to delete this Play Gate?"
                    onClose={() => setDeleteGateModal(false)}
                />
                <Modal.Body>
                    <Typography>This action cannot be undone.</Typography>
                </Modal.Body>
                <Modal.Actions>
                    <Button
                        variant="text"
                        data-testid="cancelDeleteGateHandler"
                        onClick={() => setDeleteGateModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        data-testid="confirmDeleteGateHandler"
                        onClick={() => {
                            setDeleteGateModal(false)
                            onDeleteClick()
                            deletePlayGateMutation.mutate(playGate)
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Actions>
            </Modal>
        </Box>
    )
}

export default withErrorBoundary(PlayGatesCard)
