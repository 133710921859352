import { SxProps, Theme } from '@mui/material'

const label: SxProps<Theme> = {
    maxWidth: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
}

const sublabel: SxProps<Theme> = (theme: Theme) => ({
    maxWidth: 200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',

    fontSize: theme.typography.caption2,
    color: theme.palette.text.disabled,

    [theme.breakpoints.up('laptop')]: {
        maxWidth: 240,
    },
})

const error: SxProps<Theme> = {
    color: (theme) => theme.palette.error.main,
}

export default {
    label,
    sublabel,
    error,
}
