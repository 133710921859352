const SECONDS_IN_MINUTE = 60
const SECONDS_IN_HOUR = 3600

export const transformSecondsToString = (seconds?: number | null, initial = false) => {
    if (!seconds) {
        return initial ? '00:00' : ''
    }
    if (seconds > SECONDS_IN_HOUR) {
        const hours = Math.floor(seconds / SECONDS_IN_HOUR)
        const minutes = Math.floor((seconds - hours * SECONDS_IN_HOUR) / SECONDS_IN_MINUTE)
        const remainingSeconds = seconds % SECONDS_IN_MINUTE
        const formattedHours = hours.toString().padStart(2, '0')
        const formattedMinutes = minutes.toString().padStart(2, '0')
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0')
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    }
    const minutes = Math.floor(seconds / SECONDS_IN_MINUTE)
    const remainingSeconds = seconds % SECONDS_IN_MINUTE

    const formattedMinutes = minutes.toString().padStart(2, '0')
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

    return `${formattedMinutes}:${formattedSeconds}`
}

export const transformStringToSeconds = (time: string) => {
    if (!time) {
        return 0
    }
    const timeArr = time.split(':')
    if (timeArr.length === 3) {
        const [hours, minutes, seconds] = timeArr
        return Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds)
    } else {
        const [minutes, seconds] = timeArr
        return Number(minutes) * 60 + Number(seconds)
    }
}

export const HOURS_STRING = '00:00:00'
export const MINUTES_STRING = '00:00'
export const timeRegex = /^(?:\d{2}:\d{2}|\d{2}:\d{2}:\d{2})$/
