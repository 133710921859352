import { useQueryClient, useMutation, MutationFunction, UseMutationOptions, MutationKey } from 'react-query'
import { ApiError } from 'api/contracts/utilitary/error'

export enum InvalidateOn {
    SUCCESS = 'success',
    AFTER_SUCCESS = 'afterSuccess',
    SETTLED = 'settled',
    NEVER = 'never',
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export type Options<ResponsePayload, Variables, Error> = UseMutationOptions<ResponsePayload, Error, Variables> & {
    invalidate?: Array<string | Array<any>>
    invalidateOn?: InvalidateOn
}

export const useInvalidateMutation = <ResponsePayload, Variables, Error = ApiError>(
    key: MutationKey,
    fn: MutationFunction<ResponsePayload, Variables>,
    options: Options<ResponsePayload, Variables, Error> = {},
) => {
    const queryClient = useQueryClient()
    const { onSettled, onSuccess, invalidate, invalidateOn } = options

    const invalidateFunc = async () => {
        const keys = Array.isArray(invalidate) ? [key, ...invalidate] : [key, invalidate].filter(Boolean)

        await Promise.all(keys.map((k) => queryClient.invalidateQueries(k)))
    }

    return useMutation(key, fn, {
        ...options,
        onSuccess: async (...args) => {
            if (invalidateOn === InvalidateOn.SUCCESS) {
                await invalidateFunc()
            }

            let res = null
            if (typeof onSuccess === 'function') {
                res = onSuccess(...args)
            }

            if (invalidateOn === InvalidateOn.AFTER_SUCCESS) {
                await invalidateFunc()
            }

            return res
        },
        onSettled: async (...args) => {
            if (!invalidateOn || invalidateOn === InvalidateOn.SETTLED) {
                await invalidateFunc()
            }

            if (typeof onSettled === 'function') {
                return onSettled(...args)
            }
        },
    })
}
