import { UseMutationOptions, useQueryClient } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { WHITELISTED_DOMAINS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { Id } from 'types'
import { WhitelistedDomain } from 'types/Customer'
import { GUID } from 'constants/api.constants'

export const useDeleteWhitelistedDomainMutation = (options?: UseMutationOptions<null, Error, Id>) => {
    const queryClient = useQueryClient()
    const { mutate } = useMutationToastDecorator()

    const key = WHITELISTED_DOMAINS_QUERY

    return useInvalidateMutation<null, Id>(
        key,
        async (id: Id) => mutate(userAPI.deleteWhitelistedDomain(id), apiLocale.user.whitelistDomain.delete),
        {
            ...options,
            onMutate: async (guid) => {
                await queryClient.cancelQueries(key)

                queryClient.setQueryData<WhitelistedDomain[]>(
                    key,
                    (previousWhitelistedDomain) =>
                        previousWhitelistedDomain?.filter((whitelistedDomain) => whitelistedDomain[GUID] !== guid) ||
                        [],
                )

                options?.onMutate?.(guid)
            },
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
