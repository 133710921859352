import { WatchedPercentType } from 'types/Integrations'

export const ID = 'id'
export const NUMBER = 'number'
export const DATE_CREATED = 'dateCreatedTS'
export const STATUS = 'status'
export const AMOUNT_IN_CENTS = 'amountInCents'

export const SEND_PAUSE = 'sendPause'
export const SEND_PAUSE_PERCENTAGE = 'sendPausePercentage'
export const SEND_PLAY = 'sendPlay'
export const SEND_PLAY_GATE_SUBMIT = 'sendPlayGateSubmit'
export const SEND_RESUME_PLAY = 'sendResumePlay'
export const SEND_WATCHED_PERCENT = 'sendWatchedPercent'
export const WATCHED_PERCENT_ENABLED = 'watchedPercentEnabled'

export const ANALYTIC_INITIAL_VALUES = {
    [SEND_PAUSE]: false,
    [SEND_PAUSE_PERCENTAGE]: false,
    [SEND_PLAY]: false,
    [SEND_PLAY_GATE_SUBMIT]: false,
    [SEND_RESUME_PLAY]: false,
    [SEND_WATCHED_PERCENT]: WatchedPercentType.NONE,
    [WATCHED_PERCENT_ENABLED]: false,
}
