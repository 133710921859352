import { ReactNode, Ref } from 'react'
import { Helmet } from 'react-helmet'
import { ErrorBoundary } from 'react-error-boundary'
import { GlobalStyles } from '@mui/system'
import { Stack, Typography } from '@mui/material'

import { Fallback } from 'design/atoms/Fallback'
import { ApiError } from 'design/atoms/ApiError'
import { BannerController } from 'design/organisms/BannerController'
import { ModalController } from 'design/templates/Modal'

import style from './Page.style'

interface PageProps {
    stackRef?: Ref<HTMLDivElement>
    title?: string
    children: ReactNode
    isError?: boolean
    showTitle?: boolean
}

export const Page = ({ stackRef, title, children, isError, showTitle }: PageProps) => {
    return (
        <ErrorBoundary fallbackRender={() => <Fallback />}>
            <Helmet>{<title>{title ? `${title} | Vidalytics` : `Vidalytics`}</title>}</Helmet>
            <GlobalStyles
                styles={(theme) => ({
                    body: {
                        backgroundColor: theme.palette.background.surface,
                    },
                })}
            />

            <ModalController />
            <BannerController />

            <Stack ref={stackRef} sx={style.container}>
                <Stack sx={style.content}>
                    {showTitle === true && (
                        <Typography variant="h6" component="h1" sx={style.title}>
                            {title}
                        </Typography>
                    )}

                    {isError ? (
                        <ApiError redesigned={true} />
                    ) : (
                        <Stack component="main" sx={style.main}>
                            <ErrorBoundary fallbackRender={() => <Fallback />}>{children}</ErrorBoundary>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </ErrorBoundary>
    )
}

export default Page
