import { Stack, Typography } from '@mui/material'
import { get, isNumber } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePlanUsageInfo from 'design/organisms/Account/usePlanUsageInfo'
import { Feature } from 'design/templates/Modal/modalTemplates/SubscriptionModal/Feature'
import { OnDowngrade } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SelectPlanStep/hooks'
import { ExtendedPlan } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SelectPlanStep/usePlans'
import { SubscriptionFeatureKey } from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import { pxToRem } from 'styles/theme/typography'
import { getBillingPeriod } from 'utils/dates'
import { useIsTrialAvailableForPlan } from 'hooks/user/useIsTrialAvailableForPlan'
import { MIXPANEL_EVENTS } from 'hooks/analytics/useMixpanel'
import { useEffectTrackEvent } from 'hooks/analytics/useEffectTrackEvent'
import { isFreePlan, PlanIndexByTier, RECOMMENDED_TIER_BY_TIER_INDEX } from 'constants/plan.constants'

import { Action } from './Action'
import style from './PlanCard.style'

interface PlanCardProps {
    plan: ExtendedPlan
    previousPlan?: ExtendedPlan
    highlightFeature?: SubscriptionFeatureKey
    onDowngrade: OnDowngrade
}

const planKeys = ['id', 'apiHandle', 'tier'] as const

export const PlanCard = ({ plan, previousPlan, highlightFeature, onDowngrade }: PlanCardProps) => {
    const { isTrial, trialDaysLeft, subscription } = usePlanUsageInfo()
    const { price, billingPeriod, name, subtitle, features } = plan
    const { t } = useTranslation()

    const isHighlighted = useMemo(() => {
        const currentPlanTier = subscription?.plan.tier

        if (!currentPlanTier) return false
        if (isTrial) return plan.tier === currentPlanTier

        const tierIndex = get(PlanIndexByTier, currentPlanTier)

        if (!isNumber(tierIndex)) return false

        const recommendedTier = Object.entries(RECOMMENDED_TIER_BY_TIER_INDEX).find(([, tier]) =>
            tier.includes(tierIndex),
        )?.[0]

        if (!recommendedTier) return false

        return plan.tier.includes(recommendedTier)
    }, [isTrial, plan, subscription])

    const isCurrentPlan = planKeys.some((key) => plan[key] === subscription?.plan[key])
    const displayOutlinedBorder = isCurrentPlan && !isHighlighted

    const isTrialAvailable = useIsTrialAvailableForPlan(plan)

    const promo = isFreePlan(plan) ? (
        t('Unbranded player, plus...')
    ) : (
        <>
            {t('All')}{' '}
            {
                <Typography fontWeight={700} color="inherit" component="span">
                    {previousPlan?.name}
                </Typography>
            }{' '}
            {t('Features plus...')}
        </>
    )

    useEffectTrackEvent({
        eventName: MIXPANEL_EVENTS.ALLPLANS_POPUP_OPENED,
        eventData: {
            tier_recommended: plan.tier,
            trial_available: isTrialAvailable,
        },
        condition: isHighlighted,
    })

    return (
        <Stack sx={[style.root, isHighlighted && style.recommendedRoot]}>
            {isHighlighted && (
                <Typography variant="caption1" sx={[style.highlightedText, isTrial && style.currentTrial]}>
                    {isTrial ? t('currentTrial', { days: trialDaysLeft }) : t('RECOMMENDED')}
                </Typography>
            )}
            <Stack
                sx={[style.container, isHighlighted && style.recommended, displayOutlinedBorder && style.currentPlan]}
            >
                <Stack>
                    <Typography variant="subtitle1" fontWeight={400}>
                        {name}
                    </Typography>
                    <Typography my={-2}>
                        {Number(price) ? (
                            <>
                                <Typography component="span" fontWeight={600} fontSize={pxToRem(40)}>
                                    $
                                </Typography>
                                <Typography component="span" fontWeight={500} fontSize={pxToRem(64)}>
                                    {Number(price)}
                                </Typography>
                                <Typography sx={{ opacity: 0.6 }} component="span">
                                    / {getBillingPeriod(billingPeriod, true)}
                                </Typography>
                            </>
                        ) : (
                            <Typography component="span" fontWeight={500} fontSize={pxToRem(64)}>
                                {t('Custom')}
                            </Typography>
                        )}
                    </Typography>
                    <Typography
                        variant="caption1"
                        color="text.secondary"
                        minHeight={(theme) =>
                            `calc(${theme.typography.caption1.fontSize} * 2 * ${
                                parseFloat(String(theme.typography.caption1.lineHeight)) / 100
                            })`
                        }
                    >
                        {subtitle}
                    </Typography>
                </Stack>

                <Action plan={plan} activateNow={isHighlighted && isTrial} onDowngrade={onDowngrade} />

                <Stack gap={4}>
                    <Typography variant="body1">{promo}</Typography>
                    {features.map((feature) => (
                        <Feature key={feature} featureTitle={feature} highlightFeature={highlightFeature} />
                    ))}
                </Stack>
            </Stack>
        </Stack>
    )
}
