import {
    KeyboardEvent,
    MouseEvent,
    MouseEventHandler,
    SyntheticEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'

import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Skeleton from '@mui/material/Skeleton'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'

import { ConditionalLinkWrapper } from 'design/molecules/ConditionalLinkWrapper'
import useLogout from 'hooks/user/useLogout'
import { useCustomerQuery } from 'api/queries'
import { Context as ThemeContext } from 'App/utility-components/ThemeProvider/ThemeProvider'
import { route } from 'constants/routes'
import style from './Menu.style'
import locale from './Menu.locale'

interface MenuProps {
    openSidebar: boolean
}

export const Menu = ({ openSidebar }: MenuProps) => {
    const { theme, toggleThemeMode } = useContext(ThemeContext)
    const logout = useLogout()
    const customer = useCustomerQuery()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLDivElement>(null)

    const menuItems = [
        { label: locale.accountSettings, pathname: route.account.index },
        {
            label: locale.logout,
            onClick: () => {
                if (theme === 'dark') {
                    toggleThemeMode()
                }
                logout()
            },
        },
    ]

    const handleClick =
        (callback?: (event: MouseEvent<HTMLElement>) => void): MouseEventHandler<HTMLElement> =>
        (event) => {
            setOpen(false)
            callback?.(event)
        }

    const handleToggle = () => {
        setOpen((open) => !open)
    }

    const handleClose = (event: Event | SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)

    useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current?.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <>
            <Stack direction="row" alignItems="center" ref={anchorRef} sx={style.opener} onClick={handleToggle}>
                <AccountCircleRoundedIcon sx={[style.accountIcon.idle, openSidebar && style.accountIcon.open]} />
                <Typography
                    component="span"
                    variant="body2"
                    color="text.primary"
                    sx={[style.customerName, style.opacity.idle, openSidebar && style.opacity.open]}
                >
                    {customer.data ? (
                        `${customer.data.firstname} ${customer.data.lastname}`
                    ) : (
                        <Skeleton width={120} height={24} animation="wave" />
                    )}
                </Typography>
                <IconButton
                    color="default"
                    size="small"
                    sx={[style.arrowDropdown, style.opacity.idle, openSidebar && style.opacity.open]}
                >
                    <ArrowDropDownRoundedIcon />
                </IconButton>
            </Stack>
            <Popper
                open={open}
                sx={style.popper}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="auto"
                transition
                disablePortal
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={style.grow}>
                        <Paper sx={style.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    onKeyDown={handleListKeyDown}
                                    sx={style.menuList}
                                >
                                    {menuItems.map((item) => (
                                        <ConditionalLinkWrapper link={item.pathname} key={item.label}>
                                            <MenuItem onClick={handleClick(item.onClick)}>
                                                <Typography component="span" variant="body2" color="text.primary">
                                                    {item.label}
                                                </Typography>
                                            </MenuItem>
                                        </ConditionalLinkWrapper>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
