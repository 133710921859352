import { useState, useEffect } from 'react'
import { useIsMutating } from 'react-query'
import _isUndefined from 'lodash/isUndefined'

import { isVideoGuid } from 'utils/embed'
import { useVideoQuery, useClosedCaptionsTextQuery, useClosedCaptionsDraftQuery } from 'api/queries'
import { DISCARD_SETTINGS_MUTATION, PUBLISH_SETTINGS_MUTATION } from 'api/constants'
import { CC_FILE_EXT, DEFAULT, GUID_LENGTH } from 'design/organisms/ClosedCaptionsEditor/constants'

const useClosedCaptionsContent = (videoId: string) => {
    const { data, isLoading: isLoadingVideo } = useVideoQuery(videoId)
    const {
        data: draft,
        isError: isDraftError,
        isLoading: isLoadingDraft,
    } = useClosedCaptionsDraftQuery(String(videoId), { enabled: isVideoGuid(videoId) })

    const [content, setContent] = useState<string | null>(draft?.data || null)
    const [name, setName] = useState<string | null>(draft?.name || null)
    const [isLoading, setIsLoading] = useState(true)

    const isMutatingPublish = useIsMutating({ mutationKey: PUBLISH_SETTINGS_MUTATION })
    const isMutatingDiscard = useIsMutating({ mutationKey: DISCARD_SETTINGS_MUTATION })
    const isMutating = isMutatingPublish || isMutatingDiscard

    const source = data?.settings?.ui_closedCaptions_source || ''
    const { data: text, isLoading: isLoadingText } = useClosedCaptionsTextQuery(source, {
        enabled: !!source,
    })

    const sourceName = decodeURIComponent(source?.split('/').pop() || `${DEFAULT}.${CC_FILE_EXT}`)
    const isLoadingQueries = isLoadingVideo || isLoadingDraft || isLoadingText || (isMutating && content)

    useEffect(() => {
        if (isLoadingQueries) {
            return setIsLoading(true)
        }
    }, [isLoadingQueries])

    useEffect(() => {
        if (!isLoadingQueries && (!source || (!isDraftError && draft?.isDelete))) {
            setContent(null)
            setName(null)
        }
    }, [isLoadingQueries, source, isDraftError, draft])

    useEffect(() => {
        if (isLoadingQueries) {
            return
        }

        // not uploaded
        if (!source && isDraftError) {
            setContent(null)
            setName(null)
            return setIsLoading(false)
        }

        // deleted
        if (!isDraftError && draft?.isDelete) {
            setContent(null)
            setName(null)
            return setIsLoading(false)
        }

        // no draft - get from source
        if (isDraftError && source && !_isUndefined(text)) {
            setContent(text)
            setName(sourceName?.startsWith(`${CC_FILE_EXT}-`) ? sourceName.slice(GUID_LENGTH) : sourceName)
            return setIsLoading(false)
        }

        // get from draft
        if (!isDraftError && !draft?.isDelete && !_isUndefined(draft?.data)) {
            setContent(draft?.data || null)
            setName(draft?.name || null)
            return setIsLoading(false)
        }

        setContent(null)
        setName(null)
        setIsLoading(false)
    }, [isLoadingQueries, source, draft, isDraftError, text])

    return { isLoading, name, content }
}

export default useClosedCaptionsContent
