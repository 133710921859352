import { createSvgIcon } from '@mui/material'

const AutoGraphRounded = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M8.06667 3.5332C8.46954 3.5332 8.8 3.86366 8.8 4.26654V11.7332C8.8 12.1361 8.46954 12.4665 8.06667 12.4665C7.66379 12.4665 7.33333 12.1361 7.33333 11.7332V4.26654C7.33333 3.86366 7.66379 3.5332 8.06667 3.5332Z"
            fill="currentColor"
        />
        <path
            d="M4.39987 6.33333H4.26654C3.86366 6.33333 3.5332 6.66379 3.5332 7.06667V11.7333C3.5332 12.1362 3.86366 12.4667 4.26654 12.4667H4.39987C4.80275 12.4667 5.1332 12.1362 5.1332 11.7333V7.06667C5.1332 6.66379 4.80275 6.33333 4.39987 6.33333Z"
            fill="currentColor"
        />
        <path
            d="M12.4667 9.59987C12.4667 9.19699 12.1362 8.86654 11.7333 8.86654C11.3305 8.86654 11 9.19699 11 9.59987V11.7332C11 12.1361 11.3305 12.4665 11.7333 12.4665C12.1362 12.4665 12.4667 12.1361 12.4667 11.7332V9.59987Z"
            fill="currentColor"
        />
    </svg>,
    'AutoGraphRounded',
)

export default AutoGraphRounded
