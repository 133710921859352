import { UseMutationOptions } from 'react-query'

import { CUSTOMER_QUERY } from 'api/constants'
import { authAPI } from 'api/controllers'
import { useInvalidateMutation } from 'api/hooks'
import useUnathorizedFeatureFlags, { VID_9265 } from 'hooks/system/useUnathorizedFeatureFlags'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { SignUpPayload, SignUpResponse } from 'types/Auth'
import { useOnPaymentError } from 'hooks/chargify.hooks'
import { StatusCode } from 'api/utils/http.utils'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useSignUpMutation = (options?: UseMutationOptions<SignUpResponse, any, SignUpPayload>) => {
    const onPaymentError = useOnPaymentError()

    const { mutate } = useMutationToastDecorator({
        showPendingToast: false,
        showSuccessToast: false,
        showErrorToast: false,
        showForbiddenToast: true,
    })
    const { featureFlags } = useUnathorizedFeatureFlags()
    const newSignUp = featureFlags[VID_9265]

    return useInvalidateMutation<SignUpResponse, SignUpPayload>(
        CUSTOMER_QUERY,
        async (payload: SignUpPayload) => mutate(newSignUp ? authAPI.signUp(payload) : authAPI.oldSignUp(payload), {}),
        {
            ...options,
            onError: (...args) => {
                options?.onError?.(...args)

                if (args[0]?.status === StatusCode.Forbidden) {
                    return
                }

                onPaymentError()
            },
        },
    )
}
