import { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Stack, Typography } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { useParams } from 'react-router-dom'

import { SwitchForm } from 'design/atoms/Switch/SwitchForm/SwitchForm'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { ChaptersDetails } from 'types/VidSettings'
import { useUpdateVideoMutation } from 'api/mutations'
import { useVideoQuery } from 'api/queries'
import { UI_CONTROL_BAR_SEEK_BAR_SHOW, UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW } from 'design/pages/VidSettings/constants'

import mixpanel from 'mixpanel-browser'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import locale from '../Chapters.locale'
import style from './ChaptersContent.style'
import { ChapterItem } from './ChapterItem/ChapterItem'

export const ChaptersContent = () => {
    const { videoGuid } = useParams()
    const { data, isLoading: isVideoLoading } = useVideoQuery(videoGuid)
    const [chapters, setChapters] = useState<ChaptersDetails[]>([])
    useEffect(() => {
        const chaptersData = data?.settings.ui_chapters_data
        if (chaptersData && chaptersData.length > 0) {
            const sortedChapters = [...chaptersData].sort((a, b) => a.time - b.time)
            setChapters(sortedChapters)
        }
    }, [data?.settings.ui_chapters_data, isVideoLoading])
    const isChaptersEnabled = useMemo(() => data?.settings.ui_chapters_enabled, [data?.settings.ui_chapters_enabled])
    const { mutateAsync, isLoading } = useUpdateVideoMutation(String(videoGuid))

    const handleMutateSettings = async (settingsKey: string, settingsValue: unknown) => {
        if (!data) return
        try {
            await mutateAsync({
                ...data,
                settings: {
                    ...data.settings,
                    [settingsKey]: settingsValue,
                },
            })
            if (settingsKey === 'ui_chapters_enabled') {
                mixpanel.track(MIXPANEL_EVENTS.CHAPTERS_ENABLED, {
                    chapters_enabled: settingsValue,
                })
            }
        } catch (e) {
            console.error(e)
        }
    }
    const handleChangeChaptersEnabled = async () => {
        chapters.length === 0 && setChapters([{ title: '', time: 0 }])

        await handleMutateSettings('ui_chapters_enabled', !isChaptersEnabled)
    }
    const handleAddClick = async () => {
        const sortedChapters = [...chapters].sort((a, b) => b.time - a.time)
        const lastChapter = sortedChapters[0]
        const newChapters = [
            ...chapters,
            {
                time: lastChapter ? lastChapter.time + 5 : 0,
                title: '',
            },
        ]
        setChapters(newChapters)
    }

    const handleChange = async (guid: number, title: string, time: number) => {
        const newChapters = [...chapters]
        newChapters[guid] = {
            title,
            time,
        }
        setChapters(newChapters)
        newChapters.every((el) => el.title !== '') && (await handleMutateSettings('ui_chapters_data', newChapters))
    }

    const handleRemove = async (key: number) => {
        const newChapters = chapters.filter((_, index) => index !== key)
        setChapters(newChapters)
        await handleMutateSettings('ui_chapters_data', newChapters)
    }

    const isAddChapterEnabled = useMemo(() => chapters.length >= 20, [chapters])
    const existingTimes = useCallback(
        (itemIndex: number) => chapters?.filter((_el, index) => index !== itemIndex).map((el) => el?.time),
        [chapters],
    )

    return (
        <Section mb={4}>
            {(!data?.settings[UI_CONTROL_BAR_SEEK_BAR_SHOW] || data?.settings[UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW]) && (
                <Alert severity="warning" sx={style.alert}>
                    {locale.featureDisabled}
                </Alert>
            )}
            <SwitchForm
                sx={style.switcher}
                name="chaptersSwitch"
                label={
                    <>
                        <Typography variant="body1" fontWeight={600}>
                            Chapters
                        </Typography>
                        <HelpTooltip title={locale.createChapter} />
                    </>
                }
                tooltipPlacement={undefined}
                onChange={() => handleChangeChaptersEnabled()}
                labelPlacement="start"
                switchProps={{ size: 'medium' }}
                value={isChaptersEnabled}
            />

            {!isVideoLoading && (
                <Stack gap={5}>
                    {!chapters.length && (
                        <ChapterItem
                            guid={0}
                            index={0}
                            time={0}
                            title=""
                            videoLength={data?.video.length}
                            disabled={!isChaptersEnabled}
                            existingTimes={[]}
                        />
                    )}
                    {chapters.length > 0 &&
                        chapters.map((item, index) => (
                            <ChapterItem
                                guid={index}
                                videoLength={data?.video.length}
                                index={index}
                                key={item.time}
                                time={item?.time}
                                title={item?.title}
                                handleRemove={handleRemove}
                                handleChangeChapter={handleChange}
                                existingTimes={existingTimes(index)}
                                disabled={!isChaptersEnabled || isLoading}
                            />
                        ))}
                    <Button
                        sx={{ alignSelf: 'flex-start', mx: 5 }}
                        className="addBtn"
                        onClick={handleAddClick}
                        variant="contained"
                        color={'secondary'}
                        disabled={isAddChapterEnabled || isLoading || !isChaptersEnabled}
                    >
                        <AddRoundedIcon />
                        Add Chapter
                    </Button>
                    {isAddChapterEnabled && (
                        <Typography variant="caption1" color={'#D32F2F'} pl={5}>
                            {'You have reached the maximum limit of 20 chapters per video.'}
                        </Typography>
                    )}
                </Stack>
            )}
        </Section>
    )
}
