import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Stack } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'

import { useSmartVidQuery, useVideoQuery } from 'api/queries'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { GlobalSettingsFormProvider } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import { CONTROLS_SECTION_INITIAL_VALUES, GO_BACK, START_OVER, SHOW, TEXT } from 'design/pages/VidSettings/constants'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { ControlsSectionFormData } from 'types/VidSettings'
import { VideoDetails } from 'types/Video'
import { PlayerColors } from './PlayerColors'
import { FlowNavigation } from './FlowNavigation'
import { SeekingBar } from './SeekingBar'
import { SeparatedSeekingBar } from './SeparatedSeekingBar'
import Controls from './Controls'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const ControlsSection = ({ resetForm, isSmartVidGlobalSettings }: FormikProps<ControlsSectionFormData> & Props) => {
    const { videoGuid, smartVidId } = useParams()
    const { goBackStartOver, combineSeekBarSettings, playerThemesSettings } = useFeatureFlags()

    const { data: smartVid } = useSmartVidQuery(String(smartVidId), {
        enabled: Boolean(smartVidId),
    })

    const initForm = (details: VideoDetails) => {
        if (isSmartVidGlobalSettings) return
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoGuid || smartVid?.video.guid, {
        onSuccess: initForm,
    })

    const Provider = useMemo(
        () => (isSmartVidGlobalSettings ? GlobalSettingsFormProvider : SettingsSectionProvider),
        [isSmartVidGlobalSettings],
    )

    return (
        <Provider>
            <Form>
                <Stack mb={4} gap={4} divider={<Divider />}>
                    {!playerThemesSettings && <PlayerColors isSmartVidGlobalSettings={isSmartVidGlobalSettings} />}
                    {goBackStartOver && isSmartVidGlobalSettings && <FlowNavigation />}
                    {combineSeekBarSettings ? (
                        <SeekingBar isSmartVidGlobalSettings={isSmartVidGlobalSettings} />
                    ) : (
                        <SeparatedSeekingBar isSmartVidGlobalSettings={isSmartVidGlobalSettings} />
                    )}
                    <Controls isSmartVidGlobalSettings={isSmartVidGlobalSettings} />
                </Stack>
            </Form>
        </Provider>
    )
}

const FormikWrapper = ({ isSmartVidGlobalSettings }: Props) => {
    const TEXT_VALIDATION = yup
        .string()
        .required('Required. Permitted max length is 50.')
        .max(50, 'Permitted max length is 50.')

    const ReplayButtonSchema = yup.object().shape({
        [SHOW]: yup.boolean(),
        [TEXT]: yup.string().when(SHOW, {
            is: true,
            then: TEXT_VALIDATION,
        }),
    })

    const ControlsValidationSchema = yup.object().shape({
        [GO_BACK]: ReplayButtonSchema,
        [START_OVER]: ReplayButtonSchema,
    })

    return (
        <Formik<ControlsSectionFormData>
            initialValues={CONTROLS_SECTION_INITIAL_VALUES}
            validationSchema={ControlsValidationSchema}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={() => {}}
        >
            {(props) => <ControlsSection {...props} isSmartVidGlobalSettings={isSmartVidGlobalSettings} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
