import { Box } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import _noop from 'lodash/noop'
import { useParams } from 'react-router-dom'

import { useVideoQuery } from 'api/queries'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { VIDEO_STARTUP_QUALITY_INITIAL_VALUES } from 'design/pages/VidSettings/constants'
import { VideoDetails } from 'types/Video'
import { VideoStartupQualityFormData } from 'types/VidSettings'

import { VideoStartupQualityContent } from './VideoStartupQualityContent'

const VideoStartupQualitySection = ({ resetForm }: FormikProps<VideoStartupQualityFormData>) => {
    const { videoGuid } = useParams()

    const initForm = (details: VideoDetails) => {
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoGuid, {
        onSuccess: initForm,
    })

    return (
        <SettingsSectionProvider>
            <Form>
                <Box className="PlayOptionsSection">
                    <VideoStartupQualityContent />
                </Box>
            </Form>
        </SettingsSectionProvider>
    )
}

const FormikWrapper = () => {
    return (
        <Formik<VideoStartupQualityFormData>
            initialValues={VIDEO_STARTUP_QUALITY_INITIAL_VALUES}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={_noop}
        >
            {(props) => <VideoStartupQualitySection {...props} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
