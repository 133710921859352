import { useEffect } from 'react'
import { useCookies } from 'react-cookie'

import { VDLY } from 'design/organisms/SignUp/constants'
import { useVdly } from 'thirdPartyServices/vdly/useVdly'
import { COOKIES_OPTIONS } from 'constants/cookies.constants'

export const useVdlyInit = () => {
    const vdly = useVdly()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_cookies, setCookie] = useCookies()

    useEffect(() => {
        if (vdly) {
            localStorage.setItem(VDLY, vdly)
            setCookie(VDLY, vdly, COOKIES_OPTIONS)
        }
    }, [vdly, setCookie])
}
