import { SxProps, Theme } from '@mui/material'

export default {
    root: {
        padding: 6,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 6,
        borderRadius: 4,
        backgroundColor: 'background.paperElevation1',
    },
    currentPlan: {
        border: (theme) => `4px solid ${theme.palette.tertiary.main}`,
    },
} satisfies Record<string, SxProps<Theme>>
