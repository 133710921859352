import { useQuery, UseQueryOptions } from 'react-query'

import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_GET_INFO } from 'api/constants'
import { GetAgencyInfoResponse } from 'api/contracts/agency/endpoints/getAgencyInfo.contract'
import { useApiErrorToast } from 'api/hooks'
import { ApiError } from 'api/contracts/utilitary/error'

export const useGetAgencyInfoQuery = (
    options?: Omit<
        UseQueryOptions<GetAgencyInfoResponse, ApiError, GetAgencyInfoResponse>,
        'queryKey' | 'queryFn' | 'onError'
    >,
) => {
    const { showApiErrorToast } = useApiErrorToast()
    const getAgencyInfoQuery = useQuery<GetAgencyInfoResponse, ApiError>(
        AGENCY_GET_INFO,
        () => agencyController.getAgencyInfo(),
        { onError: (e) => showApiErrorToast(e), ...options },
    )

    return { agency: getAgencyInfoQuery.data?.data, getAgencyInfoQuery }
}
