import { createSvgIcon } from '@mui/material'

const MultiDirectionalOutline = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <path d="M14.1391 8.30759C14.3585 8.1355 14.5709 7.95279 14.7753 7.759C15.372 7.19315 15.8021 6.61665 16.0944 6.1566L16.6008 5.35957L15.9666 5.24082C15.5485 5.16255 15.4624 4.60156 15.8377 4.40143L18.3663 3.05307C18.6002 2.92834 18.8907 3.0318 18.9931 3.2763L20.0086 5.70093C20.1643 6.07263 19.7904 6.44585 19.419 6.28954L18.7983 6.02832L18.5065 6.60585C18.1301 7.35091 17.5066 8.33207 16.5093 9.31331C16.2325 9.58616 15.9407 9.84437 15.6364 10.0872C15.5451 10.16 15.4125 10.1339 15.3536 10.0362C15.069 9.56413 14.6656 9.17491 14.1837 8.90742C13.9562 8.78111 13.9385 8.46496 14.1391 8.30759Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.45 11.0374C15.1833 11.4904 15.1833 12.5096 14.45 12.9512L4.82159 18.8172C4.02908 19.3042 3 18.7606 3 17.866V6.13402C3 5.2394 4.02908 4.69583 4.82159 5.18278L14.45 11.0374ZM5 16.3666L12.1754 11.9951L5 7.63198V16.3666Z"
        />
        <path d="M16.534 11.159H17.0074L20.7464 11.1118L20.7304 10.462C20.7208 10.0753 21.1715 9.85754 21.4686 10.1055L23.6794 11.9506C23.895 12.1306 23.8949 12.4619 23.6791 12.6418L21.4659 14.4861C21.1683 14.7341 20.7172 14.5152 20.728 14.1279L20.746 13.4807L16.6687 13.4773C16.4462 13.4771 16.2903 13.3271 16.2346 13.1543C16.1961 13.0351 16.211 12.8684 16.2238 12.7261C16.2294 12.6634 16.2346 12.6053 16.2346 12.5582V11.5738C16.2346 11.2949 16.3963 11.159 16.534 11.159Z" />
        <path d="M17.7183 17.3163L18.0176 17.9111L18.6601 17.7311C19.0377 17.6253 19.3605 18.0194 19.1825 18.3687L17.9931 20.7026C17.8727 20.939 17.575 21.0204 17.3511 20.8783L15.2767 19.5621C14.9593 19.3606 15.0141 18.8819 15.3689 18.7575L15.8362 18.5936L15.316 17.771C15.0245 17.3102 14.5955 16.732 13.9999 16.1646C13.8632 16.0343 13.9553 15.8031 14.1441 15.8033L15.9998 15C16.142 15.0001 16.2775 15.0676 16.3617 15.185C16.7085 15.6688 17.5226 16.9273 17.7183 17.3163Z" />
    </svg>,
    'MultiDirectionalOutline',
)

export default MultiDirectionalOutline
