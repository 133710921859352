import { TransferPlatform, VideoDetails } from 'types/Video'

import { http } from 'api/utils/http.utils'
import { TransferVideo, StartMigration } from 'api/contracts/transfer/entities/videos'

export const transferAPI = {
    getVideos: (platform: string) => http.get<TransferVideo>(`${platform}/get-videos-list`),
    getPlantformAuthLink: (platform: TransferPlatform) => http.get<VideoDetails>(`${platform}/auth/get-auth-link`),
    getImportStatus: () => http.get('/video-import/get-import-status'),
    validatePlatformLink: (platform: TransferPlatform, code?: string, state?: string) =>
        http.get<VideoDetails>(`${platform}/auth/validate-code?code=${code}&state=${state}`),
    platformLogout: (platform: TransferPlatform) => http.get(`/video-import/clear-auth-token?platform=${platform}`),
    saveTokens: ({ secret, siteID }: { secret: string; siteID: string }) =>
        http.post(`jw-player/save-tokens`, { secret, siteID }),
    startMigration: ({
        video_id,
        platform,
        migration_type,
        folderGuid,
    }: Omit<StartMigration, 'overwrite' | 'use_vidalytics_features'>) =>
        http.post<StartMigration>(`${platform}/start-migration`, {
            video_id,
            overwrite: true,
            platform,
            use_vidalytics_features: true,
            migration_type,
            folderGuid,
        }),
}
