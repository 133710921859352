import { default as dates } from './dates'
import { default as inputs } from './inputs'
import { default as messages } from './messages'
import { default as metrics } from './metrics'
import { default as operations } from './operations'
import { default as labels } from './labels'
import { default as entities } from './entities'
import { default as validations } from './validations'
import { default as subscription } from './subscription'

export const locale = {
    dates,
    entities,
    inputs,
    labels,
    messages,
    metrics,
    operations,
    validations,
    subscription,
}
