import { useEffect } from 'react'
import { ENVIRONMENT, PRODUCTION } from 'constants/environment.constants'

// <script>
//     if (typeof window.addEventListener === "function") {
//         window.addEventListener("load", function() {
//             if (window.cedexis === undefined) {
//                 var radar = document.createElement("script");
//                 radar.src = "//radar.cedexis.com/1/59463/radar.js";
//                 document.body.appendChild(radar);
//             }
//         });
//     }
// </script>

const useCedexisInit = () => {
    useEffect(() => {
        if (typeof window.addEventListener === 'function' && ENVIRONMENT === PRODUCTION) {
            window.addEventListener('load', () => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((window as any).cedexis === undefined) {
                    const radar = document.createElement('script')
                    radar.src = '//radar.cedexis.com/1/59463/radar.js'
                    document.body.appendChild(radar)
                }
            })
        }
    }, [])

    return null
}

export default useCedexisInit
