import { ElementType, ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { once } from 'lodash'

export enum OverlayContent {
    interaction = 'interaction',
    closedCaptions = 'closed-captions',
}

type Overlay = OverlayContent | null

interface ContextState {
    overlaySidebar: Overlay
    displayOverlay: Overlay
    openOverlaySidebar: (overlay: Overlay) => void
    closeOverlaySidebar: () => void
}

const createSidebarContext = once(() => createContext({} as ContextState))
const useSidebarContext = () => useContext(createSidebarContext())

interface SidebarProviderProps {
    children?: ReactNode
}

function SidebarProvider({ children }: SidebarProviderProps) {
    const { overlay, interactionId } = useParams()

    const initialOverlaySidebar = useMemo(
        () =>
            overlay === OverlayContent.closedCaptions
                ? OverlayContent.closedCaptions
                : interactionId
                ? OverlayContent.interaction
                : null,
        [overlay, interactionId],
    )

    const [overlaySidebar, setOverlaySidebar] = useState<Overlay>(initialOverlaySidebar)
    // for animation purposes
    const [displayOverlay, setDisplayOverlay] = useState<Overlay>(overlaySidebar)

    const timer = useRef<ReturnType<typeof setTimeout>>()

    const openOverlaySidebar = (overlay: Overlay) => {
        clearTimeout(timer.current)

        setOverlaySidebar(overlay)
        setDisplayOverlay(overlay)
    }

    const closeOverlaySidebar = () => {
        setOverlaySidebar(null)
        timer.current = setTimeout(() => setDisplayOverlay(null), 500)
    }

    const sidebarProps = {
        overlaySidebar,
        displayOverlay,
        openOverlaySidebar,
        closeOverlaySidebar,
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer.current)
        }
    }, [])

    const SidebarContext = createSidebarContext()

    return <SidebarContext.Provider value={sidebarProps}>{children}</SidebarContext.Provider>
}

const withSidebarProvider = (Component: ElementType) => () =>
    (
        <SidebarProvider>
            <Component />
        </SidebarProvider>
    )

export { SidebarProvider, useSidebarContext, withSidebarProvider }
