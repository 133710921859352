import { Folder, FolderAPI, Video } from 'types/Video'
import { NEW_FOLDER_LABEL } from 'design/organisms/MyVids/MyVids.constants'
import { ExpandedVideosWithFolders } from 'design/organisms/MyVids/hooks/useVideosWithFolders'
import { MAIN, ROOT } from 'constants/api.constants'

export function folderPath(folders: Folder[], folder: FolderAPI, path: string[] = []): string[] {
    if (folder.parentFolderGuid === ROOT) {
        path.push(MAIN)

        return path
    }
    const folderParent = folders.find((f) => f.guid === folder.parentFolderGuid)

    if (!folderParent) {
        return path
    }

    path.push(folderParent.title)

    return folderPath(folders, folderParent, path)
}

export function getFolderPath(folders: Folder[], folder: FolderAPI): string[] {
    return folderPath(folders, folder).reverse()
}

export function getVideoBreadcrumbs(video: Video, folders: Folder[], currentFolderGuid?: string): string {
    if (video.folderGuid === (currentFolderGuid || ROOT)) {
        return ''
    }

    const folder = folders.find((f) => f.guid === video.folderGuid)

    if (folder) {
        return folder.folderPath?.join(' / ') + ` / ${folder.title}`
    }

    return ''
}

export function getFolderBreadcrumbs(folder: Folder, currentFolderGuid?: string): string {
    if (folder.parentFolderGuid === (currentFolderGuid || ROOT)) {
        return ''
    }

    return folder.folderPath?.join(' / ') || ''
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const flatListToTree = <T>(
    flatList: T[],
    idPath: keyof T,
    parentIdPath: keyof T,
    childListPath: keyof T,
    isParent: (t: T) => boolean,
) => {
    const rootParents: T[] = []
    const map: any = {}

    for (const item of flatList) {
        if (!(item as any)[childListPath]) (item as any)[childListPath] = []

        map[item[idPath]] = item
    }
    for (const item of flatList) {
        const parentId = item[parentIdPath]
        const parentItem = map[parentId]

        if (isParent(item) || !parentItem) {
            rootParents.push(item)
        } else {
            parentItem[childListPath].push(item)
        }
    }

    return rootParents
}

export const getFolderTitle = (data?: { title: string }[]) => {
    let title = ''
    let count = 0

    const foldersWithCommonNaming = data?.filter((folder) => folder.title.startsWith(NEW_FOLDER_LABEL))

    while (!title) {
        const ending = count > 0 ? ` ${count}` : ''
        const titleCandidate = `${NEW_FOLDER_LABEL}${ending}`
        const doesTitleExist = foldersWithCommonNaming?.find((folder) => folder.title === titleCandidate)

        if (doesTitleExist) {
            count++
        } else {
            title = titleCandidate
        }
    }

    return title
}

export const filterByTitle = (
    items: ExpandedVideosWithFolders | undefined,
    search: string,
): ExpandedVideosWithFolders => {
    if (!items || !search.trim()) return items || []

    const lowerSearch = search.toLowerCase()

    return items.reduce<ExpandedVideosWithFolders>((acc, item) => {
        if ('children' in item && Array.isArray(item.children)) {
            const filteredChildren = filterByTitle(item.children, search)
            if (item.title.toLowerCase().includes(lowerSearch) || filteredChildren?.length > 0) {
                acc.push({ ...item, children: filteredChildren })
            }
        } else {
            if (item.title.toLowerCase().includes(lowerSearch)) {
                acc.push(item)
            }
        }
        return acc
    }, [])
}
