import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Replay } from '@sentry/replay'

import { App, Root } from 'App'
import { Flow, ENVIRONMENT, PRODUCTION } from 'constants/environment.constants'

if (!process.env.REACT_APP_IS_LOCAL) {
    Sentry.init({
        environment: ENVIRONMENT,
        dsn: 'https://92f1411b037b49dd9dd96f89d89f7c26@o68218.ingest.sentry.io/4505511206322176',
        integrations: [Sentry.browserTracingIntegration(), new Replay()],
        // Performance Monitoring
        tracesSampleRate: ENVIRONMENT === PRODUCTION ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
    <Root flow={Flow.system}>
        <App />
    </Root>,
)
