import { SxProps, Theme, alpha } from '@mui/material'
import { keyframes } from '@mui/system'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const root: SxProps<Theme> = (theme) => ({
    pt: 2,

    '& .MuiGrid-item iframe': {
        width: '100% !important',
        height: '92px !important',
        minHeight: '92px !important',
    },

    [theme.breakpoints.up('laptop')]: {
        overflow: 'hidden',
    },
})

const item: SxProps = {
    position: 'relative',
    minHeight: 98,
    marginTop: -3,
}

const itemFloat: SxProps<Theme> = (theme) => ({
    ...item,

    [theme.breakpoints.down('laptop')]: {
        marginTop: -6,
    },
})

const cardIco: SxProps<Theme> = {
    position: 'absolute',
    top: 47,
    left: 25,
    color: (theme) => alpha(theme.palette.text.primary, 0.55),
    opacity: 0,
    animation: `${fadeIn} 0.25s ease-in forwards`,
}

const cvv: SxProps = {
    position: 'absolute',
    top: 48,
    right: 12,
    opacity: 0,
    animation: `${fadeIn} 0.25s ease-in forwards`,
}

export default {
    root,
    item,
    itemFloat,
    cardIco,
    cvv,
}
