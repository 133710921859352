import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { Button, Stack, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ENTERPRISE_PLAN, PLAYS_BASED_ENTERPRISE_PLAN } from '__mocks'
import { Link } from 'design/atoms/Link'
import usePlanUsageInfo from 'design/organisms/Account/usePlanUsageInfo'
import { CustomerSubscriptionPlanTier } from 'types/Customer'
import { SetPlan } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SubscriptionModal'
import { useHandleDowngradeClick } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SelectPlanStep/hooks'
import { SubscriptionFeatureKey } from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import route from 'constants/routes'
import { arePlansInSameLevel, PlanIndexByTier } from 'constants/plan.constants'
import { FreePlan } from './FreePlan'
import { PlanCard } from './PlanCard'
import { styles } from './SelectPlanStep.style'
import { usePlans } from './usePlans'

interface SelectPlanStepProps {
    highlightFeature?: SubscriptionFeatureKey
    setPlan: SetPlan
}

export const SelectPlanStep = ({ highlightFeature, setPlan }: SelectPlanStepProps) => {
    const { t } = useTranslation()
    const { plans } = usePlans()
    const { subscription } = usePlanUsageInfo()
    const { handleDowngradeClick } = useHandleDowngradeClick(setPlan)

    const billingMode = subscription?.plan?.billingMode

    const plansToRender = useMemo(() => {
        const nonFreePlans = plans?.filter((plan) => !plan.isFree)
        if (!nonFreePlans) return []

        const hasEnterprisePlan = nonFreePlans.some((plan) => plan.tier === CustomerSubscriptionPlanTier.enterprise)
        const isCurrentPlanEnterprise = subscription?.plan?.tier === CustomerSubscriptionPlanTier.enterprise

        // Handle enterprise plans
        const getEnterprisePlans = () => {
            if (isCurrentPlanEnterprise) {
                return [
                    {
                        ...subscription?.plan,
                        subtitle: subscription?.plan.subtitle || ENTERPRISE_PLAN.subtitle,
                        index: PlanIndexByTier[subscription.plan.tier] || ENTERPRISE_PLAN.index,
                    },
                ]
            }

            if (!hasEnterprisePlan) {
                return [ENTERPRISE_PLAN, PLAYS_BASED_ENTERPRISE_PLAN].filter((plan) => plan.billingMode === billingMode)
            }

            return []
        }

        const plansWithEnterprise = [...nonFreePlans, ...getEnterprisePlans()]

        const includesCurrentPlan = plansWithEnterprise.some((plan) => plan.apiHandle === subscription?.plan?.apiHandle)

        if (includesCurrentPlan) {
            return plansWithEnterprise
        }

        return plansWithEnterprise.map((plan) => {
            if (!subscription?.plan?.tier) return plan

            if (
                arePlansInSameLevel(plan.tier, subscription.plan.tier) &&
                !subscription.plan.name.toLocaleLowerCase().includes('legacy')
            ) {
                return {
                    ...subscription.plan,
                    index: PlanIndexByTier[subscription.plan.tier],
                    subtitle: subscription.plan.subtitle || plan.subtitle,
                }
            }

            return plan
        })
    }, [plans, subscription, billingMode])

    return (
        <Stack sx={styles.root}>
            <Typography variant="h4" fontWeight={600}>
                {t('So Effective & Affordable, It Pays for Itself')}
            </Typography>
            <Stack direction="row" gap={4}>
                {plansToRender?.map((plan, index) => (
                    <PlanCard
                        key={plan.id}
                        plan={plan}
                        previousPlan={plansToRender[index - 1]}
                        highlightFeature={highlightFeature}
                        onDowngrade={handleDowngradeClick}
                    />
                ))}
            </Stack>
            <Link to={route.static.pricing} target="_blank" rel="noreferrer" external>
                <Button
                    variant="text"
                    color="tertiary"
                    size="large"
                    endIcon={<ChevronRightRoundedIcon />}
                    sx={{ color: 'text.primary' }}
                >
                    {t('See All Features')}
                </Button>
            </Link>
            <FreePlan onDowngrade={handleDowngradeClick} />
        </Stack>
    )
}
