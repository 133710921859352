import { Box } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import _noop from 'lodash/noop'
import { useParams } from 'react-router-dom'

import { useVideoQuery } from 'api/queries'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { SMART_PAUSE_INITIAL_VALUES } from 'design/pages/VidSettings/constants'
import { VideoDetails } from 'types/Video'
import { SmartPauseFormData } from 'types/VidSettings'

import { SmartPauseContent } from './SmartPauseContent'

const SmartPauseSection = ({ resetForm }: FormikProps<SmartPauseFormData>) => {
    const { videoGuid } = useParams()

    const initForm = (details: VideoDetails) => {
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoGuid, {
        onSuccess: initForm,
    })

    return (
        <SettingsSectionProvider>
            <Form>
                <Box className="PlayOptionsSection">
                    <SmartPauseContent />
                </Box>
            </Form>
        </SettingsSectionProvider>
    )
}

const FormikWrapper = () => {
    return (
        <Formik<SmartPauseFormData> initialValues={SMART_PAUSE_INITIAL_VALUES} onSubmit={_noop}>
            {(props) => <SmartPauseSection {...props} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
