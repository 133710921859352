import { Dispatch, SetStateAction, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from 'formik'
import { Box } from '@mui/material'
import { cloneDeep } from 'lodash'
import * as yup from 'yup'
import { locale } from 'locales'

import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    AUTO_SCROLL,
    CALL_TO_ACTIONS,
    CALLS_TO_ACTION_SECTION_INITIAL_VALUES,
    DISPLAY_MODE,
    HTML_SELECTOR,
    HTML_SELECTOR_TYPE,
    LINK,
    SCROLL_OFFSET,
    SHOW_ONLY_WHEN_TRIGGERED_BEFORE,
    SHOW_TO_RETURNING_VIEWERS,
    TIME_FROM,
    TIME_TO,
    timePatternValidator,
    TITLE,
    TYPE,
} from 'design/pages/VidSettings/constants'
import {
    CallToActionType,
    VideoCallToActionCustomHTMLSelectorType,
    VideoCallToActionDisplayMode,
} from 'types/VideoCallToAction'
import { CallsToActionsSectionFormData } from 'types/VidSettings'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { useVideoQuery } from 'api/queries'
import { CallToActionConfig } from 'types/Video'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { useCreateCTAMutation, useUpdateCTAMutation } from 'api/mutations'
import { getTotalSeconds } from 'design/organisms/VidSettingsSidebar/MarketingSettings/helpers/getTotalSeconds'
import { GUID } from 'constants/api.constants'
import { URL_OPTIONAL_PROTOCOL_REGEX } from 'constants/validations/common.constants'
import { CallsToActionsContent } from './CallsToActionsContent'

interface CallsToActionsSectionProps {
    formExpanded: boolean
    setFormExpanded: Dispatch<SetStateAction<boolean>>
}

const CallsToActionsSection = (props: CallsToActionsSectionProps) => {
    return (
        <SettingsSectionProvider>
            <Form className="CallsToActionsForm">
                <Box className="CallsToActionsSection">
                    <CallsToActionsContent {...props} />
                </Box>
            </Form>
        </SettingsSectionProvider>
    )
}

export const displayModes = ['expandContainer', 'reserveSpace', 'onTop']

const FormikWrapper = () => {
    const [formExpanded, setFormExpanded] = useState(false)
    const { videoGuid, stepVidId } = useParams()
    const vidId = videoGuid || stepVidId
    const { timedExitCTA } = useFeatureFlags()
    const { data: video } = useVideoQuery(vidId)
    const callToActions = video?.[CALL_TO_ACTIONS] || []

    /**
     * Class and IDs can have different names.
     * We will just just check if the first character is a letter to avoid cases like: 11111, #selector, $variable, .class etc.
     *
     * @param selector
     * @private
     */
    const isCustomHTMLSelectorValid = (selector?: string): boolean => {
        if (!selector) {
            return false
        }
        return /[a-zA-Z]/.test(selector[0])
    }

    const isHTMLSelectorAlreadyInUse = (selector?: string, guid?: string): boolean => {
        if (!selector) {
            return false
        }

        const htmlSelector = callToActions.find((cta) => cta.htmlSelector === selector)

        if (!htmlSelector || htmlSelector.guid === guid) {
            return false
        }

        return callToActions.includes(htmlSelector)
    }

    /**
     * Return total number of seconds from XX:XX or XX:XX:XX string value
     * @param length - in format XX:XX or XX:XX:XX
     * @private
     */

    const validateTimeLength = (time?: string) => {
        if (!time) {
            return true
        }

        if (getTotalSeconds(time) > getTotalSeconds(video?.video.length || '0')) {
            return false
        }

        return true
    }

    const callsToActionsValidationSchema = yup.object({
        [TITLE]: yup.string().when(DISPLAY_MODE, {
            is: (displayMode: VideoCallToActionDisplayMode) => displayMode === VideoCallToActionDisplayMode.customHTML,
            then: yup.string(),
            otherwise: yup
                .string()
                .max(62, locale.validations.length.max(62))
                .required(locale.validations.common.required),
        }),
        [LINK]: yup.string().when(DISPLAY_MODE, {
            is: (displayMode: VideoCallToActionDisplayMode) => displayMode === VideoCallToActionDisplayMode.customHTML,
            then: yup.string(),
            otherwise: yup
                .string()
                .max(2048, locale.validations.length.max(2048))
                .required(locale.validations.common.required)
                .matches(URL_OPTIONAL_PROTOCOL_REGEX, locale.validations.url.invalid),
        }),
        [TIME_FROM]: yup.string().when(TYPE, {
            is: (type: CallToActionType) => !timedExitCTA && type === CallToActionType.exit,
            then: yup.string(),
            otherwise: yup
                .string()
                .matches(timePatternValidator, locale.validations.time.invalidFormat)
                .test('time-from-before-video-length', 'CTA cannot be shown after the video', validateTimeLength)
                .test('from smaller than to', "Time 'from' must be smaller than 'to'", (timeFrom, context) => {
                    if (!context.parent[TIME_TO]) {
                        return true
                    }

                    if (timeFrom) {
                        return getTotalSeconds(timeFrom) < getTotalSeconds(context.parent[TIME_TO])
                    }

                    return false
                }),
        }),
        [TIME_TO]: yup.string().when(TYPE, {
            is: (type: CallToActionType) => !timedExitCTA && type === CallToActionType.exit,
            then: yup.string(),
            otherwise: yup
                .string()
                .matches(timePatternValidator, locale.validations.time.invalidFormat)
                .test('time-from-before-video-length', 'CTA cannot be hidden after the video', validateTimeLength),
        }),
        [HTML_SELECTOR]: yup.string().when(DISPLAY_MODE, {
            is: (displayMode: VideoCallToActionDisplayMode) => displayMode === VideoCallToActionDisplayMode.customHTML,
            then: yup
                .string()
                .nullable()
                .required(
                    'Your hidden content must use at least a class or unique id in order for the system to be able to unhide it at the correct time.',
                )
                .test('Valid selector', 'Error', (selector, { createError, parent }) =>
                    isCustomHTMLSelectorValid(selector || '')
                        ? true
                        : createError({
                              message: `Please enter a valid ${
                                  parent[HTML_SELECTOR_TYPE] === VideoCallToActionCustomHTMLSelectorType.class
                                      ? 'class name.'
                                      : 'ID.'
                              }`,
                          }),
                )
                .test(
                    'Existing element',
                    'Element class or ID already in use. Please use a different class / ID name.',
                    (selector, context) => !isHTMLSelectorAlreadyInUse(selector || '', context.parent[GUID]),
                ),
            otherwise: yup.string().nullable(),
        }),
        [SCROLL_OFFSET]: yup.number().when(AUTO_SCROLL, {
            is: true,
            then: yup
                .number()
                .required('Scroll Offset is required.')
                .max(10000, 'Scroll Offset cannot be more than 10000px'),
            otherwise: yup.number(),
        }),
    })

    const createCTAMutation = useCreateCTAMutation()
    const updateCTAMutation = useUpdateCTAMutation()

    const onSubmit = async (
        values: CallsToActionsSectionFormData,
        formikHelpers: FormikHelpers<CallsToActionsSectionFormData>,
    ) => {
        if (createCTAMutation.isLoading || updateCTAMutation.isLoading) return

        const copy = cloneDeep(values) as CallToActionConfig
        // Send null value instead of empty string
        if (!copy[TIME_TO]) {
            copy[TIME_TO] = null
        }
        if (copy[GUID]) {
            const editingCta = callToActions.find((cta) => cta[GUID] === copy[GUID])

            if (
                editingCta?.[DISPLAY_MODE] === VideoCallToActionDisplayMode.customHTML &&
                copy[DISPLAY_MODE] !== VideoCallToActionDisplayMode.customHTML
            ) {
                copy[SHOW_ONLY_WHEN_TRIGGERED_BEFORE] = false
                copy[SHOW_TO_RETURNING_VIEWERS] = false
            }
        }

        if (values[DISPLAY_MODE] === VideoCallToActionDisplayMode.customHTML) {
            copy[TITLE] = `Custom HTML (${copy[HTML_SELECTOR]})`
        }

        if (copy[DISPLAY_MODE] !== VideoCallToActionDisplayMode.customHTML) {
            delete copy.htmlSelector
            delete copy.htmlSelectorType
        } else {
            delete copy.link
            delete copy.shadow
            delete copy.linkInNewWindow
            delete copy.color_background
            delete copy.color_foreground
            delete copy.colorHover_background
            delete copy.colorHover_foreground
        }

        if (copy[GUID]) {
            await updateCTAMutation.mutateAsync(copy)
        } else {
            delete copy[GUID]
            await createCTAMutation.mutateAsync({ videoGuid: String(vidId), callToAction: copy })
        }

        setFormExpanded(false)
        formikHelpers.resetForm()
    }

    return (
        <Formik<CallsToActionsSectionFormData>
            initialValues={CALLS_TO_ACTION_SECTION_INITIAL_VALUES}
            validationSchema={callsToActionsValidationSchema}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={onSubmit}
        >
            {() => <CallsToActionsSection formExpanded={formExpanded} setFormExpanded={setFormExpanded} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
