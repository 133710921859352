import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import mixpanel from 'mixpanel-browser'
import {
    WarningAmber as WarningAmberIcon,
    InfoOutlined as InfoOutlinedIcon,
    CloseOutlined as CloseOutlinedIcon,
} from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Grid, FormHelperText, Divider, Typography, InputAdornment, Alert, AlertTitle } from '@mui/material'

import { Input } from 'design/atoms/Form'
import { Link } from 'design/atoms/Link'
import { HaveAccountLogin } from 'design/organisms/SignUp/HaveAccountLogin'
import { PasswordVisibility } from 'design/molecules/PasswordVisibility'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { BUSINESSNAME, CARD_STEP, FIRSTNAME, LASTNAME, PARTIAL, SOURCE } from 'design/organisms/SignUp/constants'
import { ALLOW_MICROSOFT_ACCOUNT_USAGE } from 'design/molecules/MicrosoftAuth/constants'
import { PlanPayload, SignUpFormData, SignUpSource } from 'types/Auth'
import { useCreateActiveCampaignContactMutation, useVerifyEmailMutation } from 'api/mutations'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import { CustomerSubscriptionPlanTier } from 'types/Customer'
import { SignUpSubmitProps } from 'design/organisms/SignUp/Forms'
import { useVerifyEmailPayload } from 'design/organisms/SignUp/Forms/GeneralForm/hooks/useVerifyEmailPayload'
import { route } from 'constants/routes'
import freeEmailDomains from 'constants/validations/freeEmailDomains.constants'
import { EMAIL, PASSWORD } from 'constants/validations/user.constants'
import { HELP_MAIL } from 'constants/brand.constants'

import MicrosoftButton from './MicrosoftButton'
import GoogleButton from './GoogleButton'
import Terms from './Terms'
import style from './GeneralForm.style'
import locale from './GeneralForm.locale'
import './index.scss'

type GeneralFormProps = {
    renderOnlyGeneralForm: boolean
    changeStep: (step: number) => void
    handleSubmit: (props: SignUpSubmitProps) => () => void
    isLoading: boolean
    isPlanError: boolean
    plan: PlanPayload
}

const EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE'
const BUSINESS_EMAIL_CHECKED = 'BUSINESS_EMAIL_CHECKED'

const GeneralForm = ({
    renderOnlyGeneralForm,
    changeStep,
    handleSubmit,
    isLoading: signUpLoading,
    isPlanError,
    plan,
}: GeneralFormProps) => {
    const [isLoading, setIsLoading] = useState(localStorage.getItem(ALLOW_MICROSOFT_ACCOUNT_USAGE) === 'true')
    const [showPassword, setShowPassword] = useState(false)
    const [prevEmail, setPrevEmail] = useState('')
    const [isFreeEmail, setIsFreeEmail] = useState(false)
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const formik = useFormikContext<SignUpFormData>()
    const verifyEmailMutation = useVerifyEmailMutation()
    const createActiveCampaignContactMutation = useCreateActiveCampaignContactMutation()
    const { touched, values, errors, handleChange, setFieldTouched } = formik

    const verifyEmailPayload = useVerifyEmailPayload(values[EMAIL])

    const handleBlur = async () => {
        const errs = await setFieldTouched(EMAIL)

        if (!errs?.[EMAIL] && values[EMAIL] !== prevEmail) {
            mixpanel.track(MIXPANEL_EVENTS.SIGN_UP_EMAIL_ENTERED, {
                subscription_tier: plan.tier,
                subscription_id: plan.apiHandle,
                subscription_istrial: plan.hasTrial,
                subscription_plan: plan.name,
                url: window.location.href,
            })
        }

        setPrevEmail(values[EMAIL])
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value

        if (email) {
            const domain = email?.split('@')?.[1]?.toLowerCase()
            setIsFreeEmail(freeEmailDomains.includes(domain) && !localStorage.getItem(BUSINESS_EMAIL_CHECKED))
        }

        handleChange(e)
    }

    const handleForm = (source: SignUpSource | null) => async () => {
        formik.values[SOURCE] = source

        const errors = await formik.validateForm()
        await formik.submitForm()

        if (!Object.keys(errors).length) {
            try {
                setIsLoading(true)

                const email = get(formik.values, EMAIL)

                if (email) {
                    await verifyEmailMutation.mutateAsync(verifyEmailPayload)

                    createActiveCampaignContactMutation.mutate({
                        email,
                        firstName: formik.values.firstname,
                        lastName: formik.values.lastname,
                        apiHandle: plan.apiHandle,
                    })

                    if (renderOnlyGeneralForm) {
                        handleSubmit({ type: PARTIAL, formik, isSkipButton: false })()
                    } else {
                        changeStep(CARD_STEP)
                    }
                }
            } catch (e) {
                console.warn(e)
                formik.setFieldError(EMAIL, EMAIL_ALREADY_IN_USE)
                setIsLoading(false)
            }
        }
    }

    const getLoadingState = () => {
        if (renderOnlyGeneralForm) {
            return !formik.isValidating && (verifyEmailMutation.isLoading || signUpLoading)
        }

        return !formik.isValidating && verifyEmailMutation.isLoading
    }

    const handleAlertClose = () => {
        setIsFreeEmail(false)
        localStorage.setItem(BUSINESS_EMAIL_CHECKED, 'true')
    }

    return (
        <Box>
            <Grid className="signUpFormGrid" container spacing={4}>
                <Grid item laptop={6} mobile={12}>
                    <GoogleButton
                        onSuccess={handleForm(SignUpSource.google)}
                        disabled={getLoadingState() || isLoading || isPlanError}
                        setIsLoading={setIsLoading}
                        plan={plan}
                    />
                </Grid>
                <Grid item laptop={6} mobile={12}>
                    <MicrosoftButton
                        onSuccess={handleForm(SignUpSource.microsoft)}
                        disabled={getLoadingState() || isLoading || isPlanError}
                        setIsLoading={setIsLoading}
                        plan={plan}
                    />
                </Grid>

                <Grid item mobile={12}>
                    <Divider sx={{ py: 2 }}>
                        <Typography variant="body2" fontWeight="500">
                            {locale.form.signUpManually}
                        </Typography>
                    </Divider>
                </Grid>
                {plan.tier === CustomerSubscriptionPlanTier.agency && (
                    <Grid item mobile={12} textAlign="start">
                        <Input
                            name={BUSINESSNAME}
                            label={locale.form.businessName}
                            placeholder={locale.form.typeAgencyName}
                        />
                    </Grid>
                )}

                <Grid item laptop={6} mobile={12} textAlign="start" data-testid="firstNameInputContainer">
                    <Input name={FIRSTNAME} label={locale.firstName.label} placeholder={locale.firstName.placeholder} />
                </Grid>
                <Grid item laptop={6} mobile={12} textAlign="start" data-testid="lastNameInputContainer">
                    <Input name={LASTNAME} label={locale.lastName.label} placeholder={locale.lastName.placeholder} />
                </Grid>
                <Grid item laptop={6} mobile={12} textAlign="start">
                    <Box className="formControl" data-testid="emailInputContainer">
                        <Input
                            onChange={handleEmailChange}
                            onBlur={handleBlur}
                            name={EMAIL}
                            label={locale.email.label}
                            type="email"
                            variant="outlined"
                            placeholder={locale.email.placeholder}
                            helperText=""
                            disabled={!!searchParams.get('email') && !!searchParams.get('agencyInvitationToken')}
                        />
                        {errors[EMAIL] && touched[EMAIL] ? (
                            <FormHelperText error>
                                {errors[EMAIL] === EMAIL_ALREADY_IN_USE ? (
                                    <>
                                        {locale.inUse.looksLikeYouAlreadyHaveAnAccount}. {locale.inUse.please}{' '}
                                        <Link to={route.auth.signIn}>{locale.inUse.logIn}</Link> {locale.inUse.hereOr}{' '}
                                        <Link
                                            to={route.static.contacts}
                                            external
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {locale.inUse.contactOurTeamForHelp}
                                        </Link>
                                        .
                                    </>
                                ) : (
                                    errors[EMAIL]
                                )}
                            </FormHelperText>
                        ) : null}
                    </Box>
                </Grid>
                <Grid item laptop={6} mobile={12} textAlign="start" data-testid="passInputContainer">
                    <Input
                        name={PASSWORD}
                        label={locale.password.label}
                        type={showPassword ? 'text' : 'password'}
                        placeholder={locale.password.placeholder}
                        data-testid="password"
                        variant="outlined"
                        endAdornment={
                            <InputAdornment position="end">
                                <PasswordVisibility onChange={setShowPassword} isVisible={showPassword} />
                            </InputAdornment>
                        }
                    />
                </Grid>
                {isPlanError && (
                    <Grid item mobile={12}>
                        <Box className="error">
                            <Box className="errorIcon">
                                <WarningAmberIcon fontSize="inherit" />
                            </Box>
                            <Box>
                                <b>{locale.planError.planDoesNotExist}</b>{' '}
                                <Link sx={style.errorLink} rel="noopener noreferrer" to={route.static.pricing} external>
                                    {locale.planError.tryDifferentOne}
                                </Link>{' '}
                                {locale.planError.reachOutTo}{' '}
                                <Link sx={style.errorLink} to={route.mail.hi} external>
                                    {HELP_MAIL}
                                </Link>{' '}
                                {locale.planError.teamCanAssistYou}
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid>
            {isFreeEmail && (
                <Box sx={{ position: 'relative' }} mt={4}>
                    <Alert
                        severity="info"
                        iconMapping={{
                            info: <InfoOutlinedIcon sx={{ pt: 0 }} />,
                        }}
                        sx={{
                            textAlign: 'left',
                            alignItems: 'flex-start',
                            backgroundColor: 'transparent',
                        }}
                    >
                        <AlertTitle>{t('Is this your best business email?')}</AlertTitle>
                        <Box component="span">{t('A business email ensures better account security and support')}</Box>
                        <CloseOutlinedIcon onClick={handleAlertClose} sx={style.close} />
                    </Alert>
                </Box>
            )}
            <LoadingButton
                variant="contained"
                color="primary"
                sx={{ my: 4 }}
                onClick={handleForm(null)}
                data-testid={renderOnlyGeneralForm ? 'submitBtn' : 'nextStep'}
                disabled={isPlanError || getLoadingState()}
                loading={getLoadingState()}
                fullWidth
            >
                {renderOnlyGeneralForm ? locale.buttons.letMeIn : locale.buttons.nextStep}
            </LoadingButton>

            <Terms />

            <Box sx={style.haveAccountLogin}>
                <HaveAccountLogin />
            </Box>
        </Box>
    )
}

export default withErrorBoundary(GeneralForm)
