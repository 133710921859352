import { Box, Divider, Typography } from '@mui/material'

import { Radio } from 'design/atoms/Form'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { PLAYER_LOWER_BANDWIDTH_USAGE, PLAYER_STARTUP_QUALITY } from 'design/pages/VidSettings/constants'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { VideoStartupQualityFormData } from 'types/VidSettings'

import locale from './VideoStartupQualityContent.locale'
import { useVideoStartupQualityOptions } from './useVideoStartupQualityOptions'

export const VideoStartupQualityContent = () => {
    const { lowerBandwidthUsage } = useFeatureFlags()
    const videoStartupQualityOptions = useVideoStartupQualityOptions()

    const { isLoading, onChange } = useSettingsSectionContext<VideoStartupQualityFormData>()

    const commonProps = {
        disabled: isLoading,
        switchProps: { size: 'small' },
        afterChange: onChange,
    } as const

    return (
        <Box className="PlayOptionsContent">
            <Typography variant="body2" color="text.secondary" sx={{ textWrap: 'wrap', mb: 4 }}>
                {locale.header}
            </Typography>
            <Radio
                name={PLAYER_STARTUP_QUALITY}
                options={videoStartupQualityOptions}
                {...commonProps}
                labelVariant="body2"
            />
            {lowerBandwidthUsage && (
                <>
                    <Divider className="dividerSection" />
                    <Box pr={5}>
                        <SwitchForm
                            sx={style.container}
                            name={PLAYER_LOWER_BANDWIDTH_USAGE}
                            label={<Typography variant="body2">{locale.lowerBandwidthUsage.label}</Typography>}
                            labelPlacement="start"
                            tooltipPlacement={SwitchTooltipPlacement.inside}
                            helpInfo={locale.lowerBandwidthUsage.tooltip}
                            {...commonProps}
                        />
                    </Box>
                </>
            )}
        </Box>
    )
}
