import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { isNumber } from 'lodash'

import { ColorPicker, Input, Select } from 'design/atoms/Form'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    PLAYBACK_ON_END,
    UI_OVERLAY_REDIRECT_COLOR_BACKGROUND,
    UI_OVERLAY_REDIRECT_COLOR_FOREGROUND,
    UI_OVERLAY_REDIRECT_SHOW,
    UI_OVERLAY_REDIRECT_TEXT,
    UI_OVERLAY_REDIRECT_TIMEOUT,
    UI_OVERLAY_REDIRECT_URL,
} from 'design/pages/VidSettings/constants'
import { PlayerOnPlaybackEndType } from 'types/Player'
import { EndOfVideoActionFormData } from 'types/VidSettings'
import { SubscriptionCtaSource } from 'design/templates/Modal'
import { FeatureGroup, VidSettingsKey } from 'constants/keys/featuresAccess.constants'
import { URL_PLACEHOLDER } from 'constants/validations/common.constants'

export const EndOfVideoActionContent = () => {
    const { t } = useTranslation()
    const { playerThemesSettings } = useFeatureFlags()
    const {
        isLoading,
        formik: { values, setFieldValue, setFieldTouched },
        formik,
        video,
        onChange,
    } = useSettingsSectionContext<EndOfVideoActionFormData>()

    const commonProps = {
        disabled: isLoading,
        switchProps: { size: 'small' },
    } as const

    const endVideoActions = [
        {
            label: t('Pause'),
            value: PlayerOnPlaybackEndType.PAUSE,
        },
        {
            label: t('Display Thumbnail'),
            tooltip: t(
                'If you have only one thumbnail set up, we will show it on all devices. If you have a mobile thumbnail set up, we will show that one on mobile devices',
            ),
            value: PlayerOnPlaybackEndType.DISPLAY_THUMBNAIL,
        },
        {
            value: PlayerOnPlaybackEndType.REPLAY,
            label: t('Loop'),
            disabled: Boolean(isLoading || video?.funnel),
            tooltip: video?.funnel
                ? t(
                      'This option cannot be enabled on this video because it is being used in a Smart Vid. If you want to enable them, please delete the video from the funnel first.',
                  )
                : null,
        },
        {
            value: PlayerOnPlaybackEndType.REDIRECT,
            label: t('Countdown Timer / Redirect'),
            disabled: Boolean(isLoading || video?.funnel),
            tooltip: video?.funnel
                ? t(
                      'This option cannot be enabled on this video because it is being used in a Smart Vid. If you want to enable them, please delete the video from the funnel first.',
                  )
                : null,
            featureKey: VidSettingsKey.CountdownTimerRedirection,
        },
    ]

    const timeInputRef = useRef<HTMLInputElement>()

    const afterChangeWithLinkValidation = async () => {
        await setFieldTouched(UI_OVERLAY_REDIRECT_URL, true)

        if (Number(values[UI_OVERLAY_REDIRECT_TIMEOUT]) === 0) {
            setFieldValue(UI_OVERLAY_REDIRECT_SHOW, false)
        }

        const errors = await formik.validateForm()

        if (!Object.values(errors).length) {
            onChange()
        }
    }

    const changeValidation = async () => {
        const errors = await formik.validateForm()
        if (!Object.values(errors).length) {
            onChange()
        }
        await afterChangeWithLinkValidation()
    }

    return (
        <Box className="PlayOptionsContent">
            <Box className="wrapper">
                <Select
                    name={PLAYBACK_ON_END}
                    options={endVideoActions}
                    featureGroup={FeatureGroup.VidSettings}
                    selectProps={{
                        disabled: isLoading,
                    }}
                    afterChange={(_, value) =>
                        value === PlayerOnPlaybackEndType.REDIRECT ? afterChangeWithLinkValidation() : onChange()
                    }
                    upgradeSource={SubscriptionCtaSource.VID_SETTINGS}
                />

                <Accordion
                    expanded={values[PLAYBACK_ON_END] === PlayerOnPlaybackEndType.REDIRECT}
                    className="formSectionAccordion"
                >
                    <AccordionSummary></AccordionSummary>
                    <AccordionDetails>
                        <Input
                            name={UI_OVERLAY_REDIRECT_URL}
                            label={t('Link')}
                            placeholder={URL_PLACEHOLDER}
                            variant="outlined"
                            formControlClassName="uiOverlayRedirecUrl"
                            multiline
                            {...commonProps}
                            onBlur={afterChangeWithLinkValidation}
                        />

                        <Input
                            InputProps={{
                                inputRef: timeInputRef,
                                value: `${
                                    isNumber(Number(values[UI_OVERLAY_REDIRECT_TIMEOUT]))
                                        ? values[UI_OVERLAY_REDIRECT_TIMEOUT]
                                        : '0'
                                }s`,
                            }}
                            name={UI_OVERLAY_REDIRECT_TIMEOUT}
                            label={t('Delay')}
                            placeholder="10"
                            variant="outlined"
                            formControlClassName="uiOverlayRedirecUrl"
                            onChange={(ev) => {
                                const nextValue = String(Number(ev.target.value.replace(/[^0-9.]/g, ''))) || '0'
                                const pos = nextValue.length || 0

                                setFieldValue(UI_OVERLAY_REDIRECT_TIMEOUT, nextValue)

                                setTimeout(() => {
                                    timeInputRef.current?.setSelectionRange?.(pos, pos)
                                }, 0)

                                return nextValue
                            }}
                            onBlur={changeValidation}
                            {...commonProps}
                        />

                        <SwitchForm
                            sx={{ width: '100%', justifyContent: 'space-between' }}
                            name={UI_OVERLAY_REDIRECT_SHOW}
                            label={<Typography variant="body2">{t('Redirect Message')}</Typography>}
                            tooltipPlacement={SwitchTooltipPlacement.inside}
                            helpInfo={t(
                                'This message is shown to your viewers before the page is redirected. You may customize it below. Use %d to denote the seconds left before the page redirects, which will count down in real time. It will use the amount of time from the delay above. For example: “You Will Be Redirected in %d Seconds”',
                            )}
                            afterChange={changeValidation}
                            switchProps={{ size: 'small' }}
                            disabled={isLoading || values[UI_OVERLAY_REDIRECT_TIMEOUT] === 0}
                        />

                        <Accordion expanded={values[UI_OVERLAY_REDIRECT_SHOW]} className="formSectionAccordion">
                            <AccordionSummary></AccordionSummary>
                            <AccordionDetails>
                                <Input
                                    name={UI_OVERLAY_REDIRECT_TEXT}
                                    label={t('Headline')}
                                    inputProps={{ maxLength: 100 }}
                                    variant="outlined"
                                    multiline
                                    displayMaxLength
                                    onBlur={changeValidation}
                                    {...commonProps}
                                />

                                <ColorPicker
                                    name={UI_OVERLAY_REDIRECT_COLOR_FOREGROUND}
                                    label={t('Text Color')}
                                    prefixed
                                    afterChange={changeValidation}
                                    {...commonProps}
                                />
                                <ColorPicker
                                    name={UI_OVERLAY_REDIRECT_COLOR_BACKGROUND}
                                    label={playerThemesSettings ? t('Background Color') : t('Shadow Color')}
                                    prefixed
                                    afterChange={changeValidation}
                                    {...commonProps}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    )
}
