import { MouseEvent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu, MenuItem, Stack, Button, Divider, Typography, useTheme } from '@mui/material'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded'

import { useAppDispatch } from 'App'
import { Logo } from 'design/atoms/Logo'
import { openModal } from 'design/templates/Modal'
import useLogout from 'hooks/user/useLogout'
import { Link } from 'design/atoms/Link'
import { route } from 'constants/routes'

import locale from './Header.locale'
import style from './Header.styles'

export const Header = () => {
    const logout = useLogout()
    const { palette } = useTheme()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [agencyMenuAnchor, setAgencyMenuAnchor] = useState<null | HTMLElement>(null)

    const openAgencyMenu = useCallback((event: MouseEvent<HTMLElement>) => {
        setAgencyMenuAnchor(event.currentTarget)
    }, [])

    const closeAgencyMenu = useCallback(() => {
        setAgencyMenuAnchor(null)
    }, [])

    return (
        <>
            <Stack gap={5} direction="row" justifyContent="space-between" alignItems="center" sx={style.toolbar}>
                <Button>
                    <Logo sx={style.logo} />
                </Button>
                <Stack gap={2} direction="row" alignItems="center">
                    <Button
                        variant="outlined"
                        color="tertiary"
                        startIcon={<ExitToAppIcon />}
                        onClick={() => navigate(route.dashboard)}
                    >
                        {locale.action.jumpInMyAccount}
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleRoundedIcon />}
                        onClick={() => dispatch(openModal({ type: 'Create agency client modal' }))}
                    >
                        {locale.action.addClient}
                    </Button>
                    <Link to={route.trainingCenter.index}>
                        <Button color="tertiary">{locale.help}</Button>
                    </Link>
                    <Button color="tertiary" onClick={openAgencyMenu} endIcon={<ArrowDropDownRoundedIcon />}>
                        {locale.menu.label}
                    </Button>
                </Stack>
            </Stack>

            <Menu
                anchorEl={agencyMenuAnchor}
                open={!!agencyMenuAnchor}
                onClose={closeAgencyMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem>
                    <Link to={route.account.index} sx={style.menu.item}>
                        <SettingsRoundedIcon style={{ fill: palette.action.active }} />
                        <Typography>{locale.menu.option.accountSettings}</Typography>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to={route.account.users} sx={style.menu.item}>
                        <SupervisorAccountRoundedIcon style={{ fill: palette.action.active }} />
                        <Typography>{locale.menu.option.userSettings}</Typography>
                    </Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={logout} sx={style.menu.item}>
                    <ExitToAppRoundedIcon style={{ fill: palette.error.main }} />
                    <Typography color="error.main">{locale.menu.option.logout}</Typography>
                </MenuItem>
            </Menu>
        </>
    )
}
