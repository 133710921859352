import { UseQueryOptions, useQuery } from 'react-query'

import { VideoDetails } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEOS_QUERY } from 'api/constants'
import { isVideoGuid } from 'utils'

export const useVideoQuery = (id?: string, options?: UseQueryOptions<VideoDetails, unknown>) =>
    useQuery<VideoDetails>([VIDEOS_QUERY, id], () => videoAPI.getVideo(String(id)), {
        enabled: isVideoGuid(id),
        ...options,
    })
