import { Button, Chip, Skeleton, Stack, Typography } from '@mui/material'
import BoltRoundedIcon from '@mui/icons-material/BoltRounded'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomerSubscriptionPlanTier, Plan } from 'types/Customer'
import useUserStatuses from 'hooks/user/useUserStatuses'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { getBillingPeriod } from 'utils/dates'
import { usePlanProrateQuery } from 'api/queries'
import { convertCentsToDollars } from 'utils/numbers'

import style from './Banner.style'

interface BannerProps {
    planToUpgrade?: Plan
    onSubmit: () => void
}

const Banner = ({ planToUpgrade, onSubmit }: BannerProps) => {
    const { isUserOnTrial } = useUserStatuses()
    const { data: subscription } = useAccountSubscription()
    const { data: planProrate, isLoading: isPlanProrateLoading } = usePlanProrateQuery(planToUpgrade?.apiHandle)
    const { t } = useTranslation()

    const isUserOnCurrentPlan = subscription?.plan.id === planToUpgrade?.id && isUserOnTrial

    const extraInfo = useMemo(() => {
        const billingPeriod = getBillingPeriod(planToUpgrade?.billingPeriod || '1 month', true)
        const price = parseFloat(planToUpgrade?.price || '0')
        const priceWithPeriod = `$${price}/${billingPeriod}` // $149/mo

        if (planToUpgrade?.tier === CustomerSubscriptionPlanTier.enterprise)
            return t('Contact us to get your exclusive deal.')

        if (planToUpgrade?.hasTrial)
            // Try free for 14 days, then $149/mo
            return t(`Try free for ${planToUpgrade.trialPeriod}s, then ${priceWithPeriod}`)

        if (isPlanProrateLoading) return <Skeleton variant="text" width={240} />
        const proratedPrice = planProrate?.migration.payment_due_in_cents
            ? convertCentsToDollars(planProrate?.migration.payment_due_in_cents)
            : `$${price}`

        // Due today: $149, then $149/mo
        return t(`Due today: ${proratedPrice}, then, ${priceWithPeriod}`)
    }, [planToUpgrade, isUserOnTrial, planProrate, isPlanProrateLoading])

    const ctaText = useMemo(() => {
        if (!planToUpgrade) return t('Upgrade Now')

        if (isUserOnCurrentPlan) return t('Activate Now')

        if (planToUpgrade.tier === CustomerSubscriptionPlanTier.enterprise && !Number(planToUpgrade.price))
            return t('Let’s Talk')

        if (isUserOnTrial) return t('Upgrade Now')

        if (!planToUpgrade.trialPeriod) return t(`Start Your Free Trial`)

        return t(`Start Your ${planToUpgrade.trialPeriod}s Trial`)
    }, [isUserOnCurrentPlan, planToUpgrade, isUserOnTrial])

    return (
        <Stack sx={style.container}>
            {!isUserOnCurrentPlan && (
                <Chip
                    sx={style.chip}
                    label={
                        <Typography variant="caption1" fontWeight={600} color="accent.contrastText">
                            {t('RECOMMENDED')}
                        </Typography>
                    }
                    color="accent"
                    size="small"
                />
            )}

            <Stack gap={1}>
                <Stack direction="row" gap={2} alignItems="center">
                    <BoltRoundedIcon color="accent" />
                    <Typography variant="h6" fontWeight={600}>
                        {planToUpgrade?.name || <Skeleton variant="text" width={100} />}
                    </Typography>
                </Stack>
                <Typography variant="body1" color="text.secondary">
                    {t('Advanced conversion driving features for experienced video marketers')}
                </Typography>
                <Typography variant="body1" fontWeight={700}>
                    {extraInfo}
                </Typography>
            </Stack>

            <Button variant="contained" color="accent" size="large" onClick={onSubmit}>
                {ctaText}
            </Button>
        </Stack>
    )
}

export default Banner
