import { useEffect } from 'react'
import { Button, Stack, Typography } from '@mui/material'

import { CustomerSubscriptionPlanTier, Plan } from 'types/Customer'
import { useCustomerSubscriptionQuery, useVideosQuery } from 'api/queries'
import { useActivatePlanMutation } from 'api/mutations'
import { PlanOptions } from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import VideosWarning from 'design/templates/Modal/modalTemplates/SubscriptionModal/VideosWarning'

import PromoMedia from 'design/templates/Modal/modalTemplates/TrialOver/PromoMedia'
import Features from 'design/templates/Modal/modalTemplates/TrialOver/Features'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'App'
import { setDisplayBanner } from 'design/organisms/BannerController/banner.slice'
import { useEffectTrackEvent, MIXPANEL_EVENTS } from 'hooks/analytics/useEffectTrackEvent'
import useMixpanel from 'hooks/analytics/useMixpanel'
import { LoadingButton } from '@mui/lab'
import style from './ConfirmDowngrade.style'

type ConfirmDowngradeProps = {
    selectedPlan?: Plan
    planOptions?: PlanOptions
    displayVidsWarning: boolean
    setDisplayVidsWarning: (displayVidsWarning: boolean) => void
    onClose: () => void
}

export const ConfirmDowngrade = ({
    selectedPlan,
    planOptions,
    displayVidsWarning,
    setDisplayVidsWarning,
    onClose,
}: ConfirmDowngradeProps) => {
    const dispatch = useAppDispatch()
    const { data: videos } = useVideosQuery()
    const { track } = useMixpanel()
    const { data: subscription } = useCustomerSubscriptionQuery()
    const activatePlanMutation = useActivatePlanMutation({
        onSuccess: () => {
            dispatch(setDisplayBanner({ displayDowngradeSuccessMessage: true }))
            onClose()
        },
    })
    const { t } = useTranslation()

    const currentPlan = subscription?.plan
    const planName = currentPlan?.name

    const handleDowngrade = () => {
        if (!selectedPlan) return

        track(MIXPANEL_EVENTS.UPGRADE_DOWNGRADE_CONFIRMED, {
            oldtier: currentPlan?.tier,
            newtier: selectedPlan?.tier,
        })

        if (
            selectedPlan.tier === CustomerSubscriptionPlanTier.free &&
            (videos?.filter((v) => !v.isDemoVideo).length || 0) > 3
        ) {
            return setDisplayVidsWarning(true)
        }

        activatePlanMutation.mutate({
            apiHandle: selectedPlan.apiHandle,
            ...(planOptions?.creditCardToken && { creditCardToken: planOptions.creditCardToken }),
            ...(planOptions?.addons && { addons: planOptions.addons }),
            ...(planOptions?.pricePoint && { pricePoint: planOptions.pricePoint }),
        })
    }

    useEffect(() => {
        return () => {
            setDisplayVidsWarning(false)
        }
    }, [])

    useEffectTrackEvent({
        eventName: MIXPANEL_EVENTS.UPGRADE_DOWNGRADE_OPENED,
        eventData: {
            oldtier: currentPlan?.tier,
            newtier: selectedPlan?.tier,
        },
        condition: Boolean(currentPlan && selectedPlan),
    })

    if (displayVidsWarning) {
        return <VideosWarning onClose={onClose} />
    }

    return (
        <Stack p={2} gap={8}>
            <Stack width={1} sx={style.headerContainer}>
                <Typography variant="h4" fontWeight={600}>
                    {t('Are You Sure You Want to Downgrade?')}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {t('You’ll be missing all these features:')}
                </Typography>
            </Stack>

            <Stack direction="row" gap={6} alignItems="center">
                <PromoMedia />
                <Features planName={planName} features={currentPlan?.features} />
            </Stack>

            <Stack direction="row" gap={2} alignItems="center" justifyContent="flex-end">
                <Button variant="contained" color="primary" onClick={onClose} disabled={activatePlanMutation.isLoading}>
                    {t('Cancel')}
                </Button>
                <LoadingButton
                    variant="outlined"
                    color="tertiary"
                    onClick={handleDowngrade}
                    loading={activatePlanMutation.isLoading}
                >
                    {t('Yes, Downgrade')}
                </LoadingButton>
            </Stack>
        </Stack>
    )
}
