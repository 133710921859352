import { useQuery, UseQueryOptions } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_TOP_VIDEOS_BY_DATE } from 'api/constants'
import {
    GetTopVideosRowDataQuery,
    GetTopVideosRowDataResponse,
} from 'api/contracts/dashboard/endpoints/getTopVideosRowData.contract'

export const useGetTopVideosQuery = (
    query: GetTopVideosRowDataQuery,
    options?: UseQueryOptions<GetTopVideosRowDataResponse>,
) =>
    useQuery<GetTopVideosRowDataResponse>(
        [DASHBOARD_GET_TOP_VIDEOS_BY_DATE, query],
        () => dashboardController.getTopVideos(query),
        options,
    )

export default useGetTopVideosQuery
