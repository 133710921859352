import { useToast } from 'design/organisms/ToastProvider'
import { ApiError } from 'api/contracts/utilitary/error'
import { locale } from 'locales'

export const useApiErrorToast = () => {
    const { showToast } = useToast()

    return {
        showApiErrorToast: ({ response }: ApiError) =>
            showToast({
                type: 'error',
                message: response?.data?.message ? response.data.message : locale.messages.errors.generic,
            }),
    }
}
