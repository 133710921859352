import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import UploadIcon from '@mui/icons-material/Upload'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import {
    Box,
    Button,
    CardMedia,
    Divider,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { ChangeEventHandler, useMemo, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { useAppDispatch } from 'App'
import { useSmartVidQuery } from 'api/queries'
import { Input } from 'design/atoms/Form'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import UpgradeBadge from 'design/molecules/UpgradeBadge'
import { ConversionsLockedContent } from 'design/pages/Conversions/'
import { HOURS_INPUT_WIDTH, MINUTES_INPUT_MAX_WIDTH, MINUTES_INPUT_MIN_WIDTH } from 'design/pages/VidSettings/constants'
import { openSubscriptionModal, setPlan, SubscriptionCtaSource } from 'design/templates/Modal'
import { closeModal, ModalType, openModal } from 'design/templates/Modal/ModalTypes/modal.slice'
import { Formik } from 'formik'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import useTariffPlans from 'hooks/user/useTariffPlans'
import { setSelectedVidGuid } from 'design/pages/VidSettings/VidSettings.slice'
import {
    DefaultThumbnailInputType,
    MobileThumbnailInputType,
    ThumbnailSource,
    ThumbnailTimeValues,
} from 'types/VidSettings'
import { MainThumbnailType, ThumbnailType } from 'types/Video'
import {
    HOURS_STRING,
    MINUTES_STRING,
    secondsToTime,
    timeRegex,
    timeToSeconds,
    truncateText,
    useCheckAccess,
} from 'utils'
import { videoThumbnailsLockedModalConfig } from 'constants/subscriptions'
import route from 'constants/routes'
import { FeatureGroup, VidSettingsKey } from 'constants/keys/featuresAccess.constants'

import style from './ImageUploader.style'
import { SelectVideoModal } from './SelectVideoModal'

const ThumbnailTimeSchema = (videoLength: string) =>
    Yup.object().shape({
        from: Yup.string()
            .matches(timeRegex, 'Please enter the time as "00:00" or "00:00:00')
            .test(
                'is-less',
                "'From' value should be less than video duration",
                (value) => timeToSeconds(value || '00:00') <= timeToSeconds(videoLength),
            )
            .required("'From' value is required"),
        to: Yup.string()
            .matches(timeRegex, 'Please enter the time as "00:00" or "00:00:00')
            .test(
                'is-less',
                "'To' value should be less than video duration",
                (value) => timeToSeconds(value || '00:00') <= timeToSeconds(videoLength),
            )
            .test('is-greater-than-from', "'To' value should be greater than 'From' value", function (value) {
                const fromValue = this.parent.from
                if (!fromValue || !value) return true
                return timeToSeconds(value) > timeToSeconds(fromValue)
            })
            .required("'To' value is required"),
    })
interface ImageUploadProps {
    id: ThumbnailType
    title?: string
    image?: string
    tooltip?: string
    imageValidateSize: {
        min: number
        max: number
    }
    disabled?: boolean
    onUpload: (payload: { data: File; id: string }) => void
    onDelete: (id: string, sourceType: ThumbnailSource) => void
    setThumbnailSource?: ({
        type,
        value,
        preventDbUpdate,
    }: {
        type: ThumbnailType
        value?: ThumbnailSource
        preventDbUpdate?: boolean
    }) => void
    setThumbnailFromVideoType?: (type: ThumbnailType | undefined) => void
    isDeletable?: boolean
    disableSource?: boolean
    thumbnailSourceType?: ThumbnailSource
    setInputTime?: (timeValues: ThumbnailTimeValues) => void
    handleSwitchThumbnailType?: (thumbnailType: ThumbnailType, value: ThumbnailSource) => void
    isSapEnabled?: boolean
    time?: {
        from?: number
        to?: number
    }
    videoLength?: string
    otherVideoName?: string
    otherVideoGuid?: string | null
}

export const THUMBNAIL_INPUT_TYPE = {
    to: {
        [MainThumbnailType.thumbnail]: DefaultThumbnailInputType.ui_thumbnail_default_to,
        [MainThumbnailType.mobileThumbnail]: MobileThumbnailInputType.ui_thumbnail_mobile_to,
    },
    from: {
        [MainThumbnailType.thumbnail]: DefaultThumbnailInputType.ui_thumbnail_default_from,
        [MainThumbnailType.mobileThumbnail]: MobileThumbnailInputType.ui_thumbnail_mobile_from,
    },
}

export const ImageUpload = ({
    id,
    title,
    image,
    tooltip,
    imageValidateSize = { min: 1000, max: 5e6 },
    disabled,
    onUpload,
    onDelete,
    setThumbnailSource,
    isDeletable,
    disableSource,
    setThumbnailFromVideoType,
    setInputTime,
    thumbnailSourceType,
    isSapEnabled,
    time,
    videoLength,
    otherVideoName,
    otherVideoGuid,
}: ImageUploadProps) => {
    const { smartVidId, stepVidId } = useParams()
    const { data: smartVid } = useSmartVidQuery(smartVidId)
    const [error, setError] = useState('')
    const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>()
    const isVideoThumbnailsLocked = useCheckAccess(FeatureGroup.VidSettings)(VidSettingsKey.VideoThumbnails)
    const hiddenFileInput = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const { videoThumbnails, freshUpgradeFlow, otherVideoThumbnail } = useFeatureFlags()
    const { pro: proPlan } = useTariffPlans()
    const dispatch = useAppDispatch()

    const handleUploadClick = () => {
        hiddenFileInput.current?.click()
    }
    const getErrorMessage = (fileType: string, fileSize: number) => {
        const isValidType = /image*/.test(fileType)
        let data = !isValidType ? 'Please select valid image file. ' : ''
        if (fileSize <= imageValidateSize.min) {
            data += `Your file is too small. Min size is ${imageValidateSize.min / 1000} MB`
        } else if (fileSize >= imageValidateSize.max) {
            data += `Your file is too big. Limit is ${imageValidateSize.max / 1000000} MB`
        }
        return data ? data : null
    }
    const handleThumbnailSource = (source: ThumbnailSource) => {
        setThumbnailFromVideoType?.(undefined)
        setThumbnailSource?.({ type: id, value: source })
    }
    const handleSetSelectedVideoGuid = (guid: string) => {
        dispatch(setSelectedVidGuid(undefined))
        setThumbnailFromVideoType?.(id as ThumbnailType)
        setTimeout(() => {
            dispatch(setSelectedVidGuid(guid))
            handleSelectFromVideo(id)
        }, 1000)
    }
    const handleSelectFromVideo = (id: ThumbnailType) => {
        setThumbnailSource?.({
            type: id,
            value: id === ThumbnailType.customPauseScreen ? ThumbnailSource.image : thumbnailSourceType,
            preventDbUpdate: true,
        })
        setThumbnailFromVideoType?.(id as ThumbnailType)
    }
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const file = event.target.files?.[0]

        if (!file) {
            return
        }

        const errorMessage = getErrorMessage(file.type, file.size)

        if (errorMessage) {
            setError(errorMessage)
            return
        }

        const reader = new FileReader()
        reader.onload = () => {
            setImagePreview(reader.result)

            onUpload({
                data: file,
                id,
            })

            if (error) {
                setError('')
            }
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const imageCanBeDisplayed = Boolean(imagePreview || image)
    const defaultTimeString = useMemo(
        () => (timeToSeconds(videoLength || MINUTES_STRING) > 3600 ? HOURS_STRING : MINUTES_STRING),
        [videoLength],
    )
    const isUploadDisabled = useMemo(() => thumbnailSourceType === ThumbnailSource.video, [id, thumbnailSourceType])
    const isThumbnailSwitchAvailable = useMemo(
        () => videoThumbnails && !disableSource && id !== ThumbnailType.customPauseScreen,
        [videoThumbnails, disableSource, id],
    )
    const isVideoSource = useMemo(() => {
        return image?.split('.').pop() === 'mp4'
    }, [image])

    const onUpgradeClick = () => {
        dispatch(closeModal())
        dispatch(setPlan({ plan: proPlan }))
        dispatch(
            openSubscriptionModal({
                ctaSource: SubscriptionCtaSource.VID_SETTINGS,
            }),
        )
    }
    const handleOpenProModal = () => {
        dispatch(
            openModal(
                freshUpgradeFlow
                    ? videoThumbnailsLockedModalConfig
                    : {
                          type: ModalType.OLD_FEATURE_LOCKED,
                          title: 'Upgrade to Unlock Video Thumbnails!',
                          description: 'Boost engagement and conversion with Vidalytics` most advanced features.',
                          proPlan,
                          onUpgradeClick,
                          children: <ConversionsLockedContent />,
                      },
            ),
        )
    }

    const isVidThumbnailsStepLocked = stepVidId ? smartVid?.videoGuid === stepVidId : true
    return (
        <Box>
            {title && (
                <Stack direction="row" gap={1} alignItems="center" mb={4}>
                    <Typography variant="body2" fontWeight="600">
                        {title}
                    </Typography>

                    <HelpTooltip arrow id={`${title}Help`} placement="top" title={tooltip} />
                </Stack>
            )}

            <Stack gap={3} sx={style.thumbnail.uploader}>
                <Box
                    sx={[
                        style.thumbnail.container,
                        { display: imageCanBeDisplayed ? 'block' : 'none', position: 'relative' },
                    ]}
                >
                    {otherVideoName && thumbnailSourceType === ThumbnailSource.video && otherVideoThumbnail && (
                        <Box
                            display="flex"
                            gap={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ position: 'absolute', bottom: '14px', left: 3 }}
                        >
                            <img src="/img/icons/playIcon.svg" />
                            <Typography
                                sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)' }}
                                fontWeight="bold"
                                color="#fff"
                                variant="caption2"
                            >
                                {truncateText(otherVideoName, 28)}
                            </Typography>
                        </Box>
                    )}
                    {!disabled && imageCanBeDisplayed && !isVideoSource && (
                        <Box
                            component="img"
                            sx={style.thumbnail.image}
                            src={String(image || imagePreview)}
                            alt={title}
                        />
                    )}
                    {!disabled && imageCanBeDisplayed && isVideoSource && (
                        <CardMedia component="video" sx={style.thumbnail.image} image={`${image}?autoplay=1`} />
                    )}

                    {disabled && (
                        <Box>
                            <Typography sx={style.thumbnail.skeletonText}>Processing</Typography>
                            <Skeleton sx={style.thumbnail.skeleton} variant="rectangular"></Skeleton>
                        </Box>
                    )}
                    {(isDeletable === undefined || isDeletable) && (
                        <IconButton
                            color="error"
                            sx={style.thumbnail.delete}
                            disabled={disabled}
                            onClick={() => {
                                onDelete(id, thumbnailSourceType as ThumbnailSource)
                                setImagePreview(null)
                                setError('')
                            }}
                        >
                            {!disabled && <DeleteRoundedIcon />}
                        </IconButton>
                    )}
                </Box>
                {isThumbnailSwitchAvailable && (
                    <Stack gap={3} direction="row" justifyContent="center" alignItems="center">
                        <RadioGroup
                            row
                            value={thumbnailSourceType}
                            onChange={(event) => handleThumbnailSource(event.target.value as ThumbnailSource)}
                        >
                            <FormControlLabel
                                value={ThumbnailSource.image}
                                control={<Radio />}
                                disabled={disabled}
                                label="Image"
                            />
                            <FormControlLabel
                                disabled={isVideoThumbnailsLocked || !isVidThumbnailsStepLocked || disabled}
                                value={ThumbnailSource.video}
                                control={<Radio />}
                                onClick={() => {
                                    if (isVideoThumbnailsLocked) {
                                        handleOpenProModal()
                                    }
                                }}
                                label={
                                    <Stack direction="row" alignItems="center" gap={2} component="span">
                                        Video {isVideoThumbnailsLocked && <UpgradeBadge preventDefault />}
                                    </Stack>
                                }
                            />
                        </RadioGroup>
                    </Stack>
                )}
                {thumbnailSourceType === ThumbnailSource.video && !otherVideoThumbnail && (
                    <Stack gap={3} direction="row">
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={true}
                            startIcon={<UploadIcon />}
                        >
                            Upload
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={disabled || !isVidThumbnailsStepLocked}
                            onClick={() => handleSelectFromVideo(id)}
                            startIcon={<SelectAllIcon />}
                        >
                            Select From Video
                        </Button>
                    </Stack>
                )}
                {thumbnailSourceType === ThumbnailSource.video && otherVideoThumbnail && (
                    <Stack gap={3} direction="row">
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={
                                disabled ||
                                (thumbnailSourceType === ThumbnailSource.video && !isVidThumbnailsStepLocked)
                            }
                            onClick={() => handleSelectFromVideo(id)}
                            startIcon={<SelectAllIcon />}
                        >
                            Current Vid
                        </Button>
                        <SelectVideoModal
                            disabled={!otherVideoThumbnail || !isVidThumbnailsStepLocked}
                            handleSelectVideo={(videoGuid) => handleSetSelectedVideoGuid(videoGuid)}
                            excludeVideo={otherVideoGuid}
                        />
                    </Stack>
                )}
                {thumbnailSourceType === ThumbnailSource.image && (
                    <Stack gap={3} direction="row">
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={handleUploadClick}
                            startIcon={<UploadIcon />}
                            disabled={(videoThumbnails && isUploadDisabled) || disabled}
                        >
                            Upload
                        </Button>
                        <input type="file" onChange={handleChange} ref={hiddenFileInput} style={{ display: 'none' }} />
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={disabled}
                            onClick={() => handleSelectFromVideo(id)}
                            startIcon={<SelectAllIcon />}
                        >
                            Select from video
                        </Button>
                    </Stack>
                )}
                {isSapEnabled && thumbnailSourceType === ThumbnailSource.video && (
                    <Stack direction="row" gap={2} mt={4} className="ErrorBlock">
                        <WarningAmberOutlinedIcon color="warning" />

                        <Typography
                            className="description"
                            sx={{
                                color: '#663C00',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal',
                            }}
                        >
                            This video plays automatically. To display the video thumbnail, disable{' '}
                            <Link
                                rel="noreferrer"
                                target="_blank"
                                to={route.static.help.article({ slug: '1968219-how-smart-autoplay-works' })}
                            >
                                Smart Autoplay.
                            </Link>
                        </Typography>
                    </Stack>
                )}
                {thumbnailSourceType === ThumbnailSource.video && videoThumbnails && (
                    <>
                        <Divider sx={{ mt: 4, mb: 4 }} />
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                from: secondsToTime(time?.from || 0, defaultTimeString === HOURS_STRING),
                                to: secondsToTime(time?.to || 0, defaultTimeString === HOURS_STRING),
                            }}
                            validationSchema={ThumbnailTimeSchema(videoLength || '')}
                            onSubmit={(values) => {
                                if (id !== ThumbnailType.thumbnail && id !== ThumbnailType.mobileThumbnail) {
                                    return
                                }

                                // typescript throws an error here, so we need to cast the id to MainThumbnailType
                                const idAsMainThumbnailType = id as unknown as MainThumbnailType

                                const timeFrom = secondsToTime(time?.from || 0)
                                const timeTo = secondsToTime(time?.to || 0)

                                if (timeFrom !== values.from || timeTo !== values.to) {
                                    setInputTime?.({
                                        [THUMBNAIL_INPUT_TYPE.from[idAsMainThumbnailType]]: timeToSeconds(values.from),
                                        [THUMBNAIL_INPUT_TYPE.to[idAsMainThumbnailType]]: timeToSeconds(values.to),
                                    } as ThumbnailTimeValues)
                                }
                            }}
                            validateOnBlur={true}
                            validateOnChange={true}
                        >
                            {({ errors, handleChange, submitForm, initialValues, values }) => (
                                <form ref={formRef}>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        gap={2}
                                        sx={style.thumbnail.videoTimeWrapper}
                                    >
                                        <Typography variant="body2" fontWeight="700">
                                            Video Time
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            alignSelf="flex-end"
                                            alignItems="center"
                                            gap={2}
                                            sx={style.thumbnail.inputsWrapper}
                                        >
                                            <Typography variant="body2">From</Typography>
                                            <Input
                                                disabled={!isVidThumbnailsStepLocked}
                                                hideError
                                                fullWidth={false}
                                                sx={{
                                                    minWidth:
                                                        defaultTimeString === HOURS_STRING
                                                            ? HOURS_INPUT_WIDTH
                                                            : MINUTES_INPUT_MIN_WIDTH,
                                                    maxWidth:
                                                        defaultTimeString === HOURS_STRING
                                                            ? HOURS_INPUT_WIDTH
                                                            : MINUTES_INPUT_MAX_WIDTH,
                                                }}
                                                onBlur={() => {
                                                    if (values.from !== initialValues.from) {
                                                        submitForm()
                                                    }
                                                }}
                                                name="from"
                                                variant="outlined"
                                                onChange={handleChange}
                                            />
                                            <Typography variant="body2">To</Typography>
                                            <Input
                                                disabled={!isVidThumbnailsStepLocked}
                                                hideError
                                                fullWidth={false}
                                                sx={{
                                                    minWidth:
                                                        defaultTimeString === HOURS_STRING
                                                            ? HOURS_INPUT_WIDTH
                                                            : MINUTES_INPUT_MIN_WIDTH,
                                                    maxWidth:
                                                        defaultTimeString === HOURS_STRING
                                                            ? HOURS_INPUT_WIDTH
                                                            : MINUTES_INPUT_MAX_WIDTH,
                                                }}
                                                onBlur={() => {
                                                    if (values.to !== initialValues.to) {
                                                        submitForm()
                                                    }
                                                }}
                                                name="to"
                                                variant="outlined"
                                                onChange={handleChange}
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack alignItems="flex-end" sx={{ mt: 2 }}>
                                        {errors.from && (
                                            <Typography variant="caption1" color={'#D32F2F'} pl={'15px'}>
                                                {errors.from}
                                            </Typography>
                                        )}
                                        {errors.to && (
                                            <Typography variant="caption1" color={'#D32F2F'} pl={'15px'}>
                                                {errors.to}
                                            </Typography>
                                        )}
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </>
                )}
                {error}
            </Stack>
        </Box>
    )
}
