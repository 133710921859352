import { SxProps, Theme } from '@mui/material'

const formSection: SxProps<Theme> = (theme) => ({
    backgroundColor: theme.palette.background.cardsEnabled,
    border: `1px solid ${theme.palette.background.cardsHover}`,
})

export default {
    formSection,
}
