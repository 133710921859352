import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type RootState } from 'App'

interface Banner {
    displayDowngradeSuccessMessage?: boolean
}

interface BannerState {
    banner: Banner
}

const initialState: BannerState = {
    banner: {
        displayDowngradeSuccessMessage: false,
    },
}

export const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {
        setDisplayBanner: (state, action: PayloadAction<Banner>) => {
            state.banner = { ...state.banner, ...action.payload }
        },
        resetBanner: (state) => {
            state.banner = initialState.banner
        },
    },
})

export const { setDisplayBanner, resetBanner } = bannerSlice.actions

export const selectBanner = (state: RootState) => state.banner

export const banner = bannerSlice.reducer
