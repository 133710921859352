import AddRoundedIcon from '@mui/icons-material/AddRounded'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    Stack,
    Typography,
    useTheme,
} from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useRef } from 'react'

import { ColorPicker, Input, Radio } from 'design/atoms/Form'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    BUTTON_TEXT,
    PLAY_GATE_BUTTON_COLOR_BACKGROUND,
    PLAY_GATE_BUTTON_COLOR_FOREGROUND,
    PLAY_GATE_CHECKBOX,
    PLAY_GATE_CHECKBOX_REQUIRED,
    PLAY_GATE_EMAIL_SHOW,
    PLAY_GATE_NAME_SHOW,
    PLAY_GATE_ONLY_ONE,
    PLAY_GATE_PHONE_SHOW,
    PLAY_GATE_SKIPPABLE,
    PLAY_GATE_SWITCH_VALIDATE,
    PLAY_GATE_TIME_UNIQ,
    PLAY_GATES,
    PLAY_GATES_SECTION_INITIAL_VALUES,
    TEXT_BOTTOM,
    TEXT_SKIP,
    TEXT_TOP,
    TIME,
    TYPE,
} from 'design/pages/VidSettings/constants'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { PlayGatesSectionFormData } from 'types/VidSettings'
import { VideoPlayGateConfig, VideoPlayGateType } from 'types/VideoPlayGate'
import { replaceBrToLineBreak } from 'utils'

import styles from 'design/organisms/VidSettingsSidebar/MarketingSettings/TagsSection/TagsContent/TagsContent.style'
import PlayGatesCard from './PlayGatesCard'
import './index.scss'

interface PlayGatesContentProps {
    formExpanded: boolean
    setFormExpanded: Dispatch<SetStateAction<boolean>>
}

export const PlayGatesContent = ({ formExpanded, setFormExpanded }: PlayGatesContentProps) => {
    const { palette } = useTheme()
    const { addPlayGateCheckbox } = useFeatureFlags()
    const {
        video,
        isLoading,
        formik: { values, setValues, resetForm, errors, setFieldValue },
    } = useSettingsSectionContext<PlayGatesSectionFormData>()

    const hasPlayGates = Boolean(video?.[PLAY_GATES].length)
    const scrollRef = useRef<HTMLInputElement>(null)

    const scrollAction = () => {
        if (scrollRef.current) {
            // 500ms transition before opening completely
            setTimeout(() => scrollRef.current?.scrollIntoView({ behavior: 'smooth' }), 500)
        }
    }

    const handleCancel = () => {
        setFormExpanded(false)
        resetForm()
    }

    const handleEditClick = (playGate: VideoPlayGateConfig) => {
        setValues({ ...PLAY_GATES_SECTION_INITIAL_VALUES, ...playGate, [PLAY_GATE_SWITCH_VALIDATE]: true })
        setFormExpanded(true)
        scrollAction()
    }

    const checkSubst = (timeString: string) => {
        if (timeString.substring(0, 2) === '00') {
            return timeString.substring(1)
        }
        return timeString
    }

    useEffect(() => {
        const videoExitTypeExist = video?.[PLAY_GATES].find((playGate) => playGate.type === 'exit')
        if (videoExitTypeExist && values[TYPE] === 'exit' && videoExitTypeExist?.guid !== values.guid) {
            setFieldValue(PLAY_GATE_ONLY_ONE, false)
        } else {
            setFieldValue(PLAY_GATE_ONLY_ONE, true)
        }
    }, [values[TYPE], formExpanded])

    useEffect(() => {
        const videoTimeIsExist = video?.[PLAY_GATES].find(
            (playGate) => playGate.time === checkSubst(values[TIME]) && playGate.type === 'time',
        )
        if (videoTimeIsExist && values[TYPE] === 'time' && videoTimeIsExist?.guid !== values.guid) {
            setFieldValue(PLAY_GATE_TIME_UNIQ, false)
        } else {
            setFieldValue(PLAY_GATE_TIME_UNIQ, true)
        }
    }, [values[TIME], values[TYPE], formExpanded])

    const tooltipMarkStyle = { color: palette.accent.light }

    return (
        <Box className="PlayGateContent">
            {hasPlayGates && (
                <Box className="PlayGateCards">
                    {video?.[PLAY_GATES].map((playGate) => (
                        <PlayGatesCard
                            key={playGate?.guid}
                            playGate={playGate}
                            disabled={isLoading}
                            onEditClick={handleEditClick}
                            onDeleteClick={handleCancel}
                        />
                    ))}
                </Box>
            )}

            <Accordion expanded={formExpanded} className="sectionSubAccordion">
                <AccordionSummary>
                    {!formExpanded && (
                        <Button
                            className="redesign ico addBtn"
                            onClick={() => setFormExpanded(true)}
                            variant="outlined"
                            fullWidth={!hasPlayGates}
                            disabled={isLoading}
                        >
                            <AddRoundedIcon />
                            {hasPlayGates ? 'Add Play Gate' : 'Create new Play Gate'}
                        </Button>
                    )}
                </AccordionSummary>

                <AccordionDetails>
                    <Box sx={styles.formSection} className="formSection" ref={scrollRef}>
                        <Accordion className="formSectionAccordion" expanded={formExpanded}>
                            <AccordionSummary></AccordionSummary>
                            <AccordionDetails>
                                <Box className="subSection">
                                    <Typography className="subSectionTitle">Fields</Typography>
                                    <Box className="fieldWrapper">
                                        <Input
                                            name={TEXT_TOP}
                                            label="Headline"
                                            placeholder="Type here ..."
                                            inputProps={{
                                                maxLength: 255,
                                                value: replaceBrToLineBreak(values[TEXT_TOP]),
                                            }}
                                            multiline
                                            rows={3}
                                            variant="outlined"
                                            displayMaxLength
                                            disabled={isLoading}
                                        />
                                    </Box>
                                    {addPlayGateCheckbox && (
                                        <Box className="fieldWrapper">
                                            <Input
                                                name={BUTTON_TEXT}
                                                label="Button Text"
                                                inputProps={{
                                                    maxLength: 24,
                                                    value: replaceBrToLineBreak(values[BUTTON_TEXT]),
                                                }}
                                                displayMaxLength
                                                placeholder="Type here ..."
                                                variant="outlined"
                                                multiline
                                                disabled={isLoading}
                                            />
                                        </Box>
                                    )}
                                    <Box className="fieldWrapper">
                                        <Input
                                            className="textArea"
                                            name={TEXT_BOTTOM}
                                            label="Lower Message"
                                            {...(addPlayGateCheckbox && {
                                                tooltip: (
                                                    <>
                                                        Use markdown format to add links:{' '}
                                                        <span style={tooltipMarkStyle}>[text](URL)</span>. The URL must
                                                        start with <span style={tooltipMarkStyle}>http://</span> or{' '}
                                                        <span style={tooltipMarkStyle}>https://</span> to ensure the
                                                        link functions correctly. Example: We hate{' '}
                                                        <span style={tooltipMarkStyle}>
                                                            [spam](https://www.example.com)
                                                        </span>{' '}
                                                        and will never spam you.
                                                    </>
                                                ),
                                            })}
                                            placeholder="Type here ..."
                                            inputProps={{
                                                maxLength: 255,
                                                value: replaceBrToLineBreak(values[TEXT_BOTTOM]),
                                            }}
                                            variant="outlined"
                                            displayMaxLength
                                            rows={3}
                                            multiline
                                            disabled={isLoading}
                                        />
                                    </Box>
                                    {addPlayGateCheckbox && (
                                        <>
                                            <Box className="fieldWrapper">
                                                <SwitchForm
                                                    name={PLAY_GATE_CHECKBOX}
                                                    sx={{ width: '100%', justifyContent: 'space-between' }}
                                                    label={
                                                        <Typography variant="body2">
                                                            Show Checkbox with Lower Message
                                                        </Typography>
                                                    }
                                                    labelPlacement="start"
                                                    switchProps={{ size: 'small' }}
                                                    disabled={isLoading}
                                                />
                                            </Box>
                                            <Box className="fieldWrapper">
                                                <SwitchForm
                                                    name={PLAY_GATE_CHECKBOX_REQUIRED}
                                                    sx={{ width: '100%', justifyContent: 'space-between' }}
                                                    label={
                                                        <Typography variant="body2">Make Checkbox Mandatory</Typography>
                                                    }
                                                    labelPlacement="start"
                                                    switchProps={{
                                                        size: 'small',
                                                        checked: values[PLAY_GATE_CHECKBOX]
                                                            ? values[PLAY_GATE_CHECKBOX_REQUIRED]
                                                            : false,
                                                    }}
                                                    disabled={isLoading || !values[PLAY_GATE_CHECKBOX]}
                                                />
                                            </Box>
                                        </>
                                    )}
                                    {!addPlayGateCheckbox && (
                                        <Box className="fieldWrapper">
                                            <Input
                                                name={BUTTON_TEXT}
                                                label="Button Text"
                                                inputProps={{
                                                    maxLength: 24,
                                                    value: replaceBrToLineBreak(values[BUTTON_TEXT]),
                                                }}
                                                displayMaxLength
                                                placeholder="Type here ..."
                                                variant="outlined"
                                                multiline
                                                disabled={isLoading}
                                            />
                                        </Box>
                                    )}
                                </Box>
                                <Divider />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="formSectionAccordion" expanded={formExpanded}>
                            <AccordionSummary></AccordionSummary>
                            <AccordionDetails>
                                <Box className="subSection">
                                    <Typography className="subSectionTitle">Options</Typography>
                                    <Box className="fieldWrapper">
                                        <SwitchForm
                                            sx={{ width: '100%', justifyContent: 'space-between' }}
                                            name={PLAY_GATE_NAME_SHOW}
                                            label={<Typography variant="body2">Name</Typography>}
                                            labelPlacement="start"
                                            switchProps={{ size: 'small' }}
                                            tooltipPlacement={SwitchTooltipPlacement.inside}
                                            disabled={isLoading}
                                        />
                                    </Box>
                                    <Box className="fieldWrapper">
                                        <SwitchForm
                                            name={PLAY_GATE_PHONE_SHOW}
                                            sx={{ width: '100%', justifyContent: 'space-between' }}
                                            label={<Typography variant="body2">Phone</Typography>}
                                            labelPlacement="start"
                                            switchProps={{ size: 'small' }}
                                            tooltipPlacement={SwitchTooltipPlacement.inside}
                                            disabled={isLoading}
                                        />
                                    </Box>
                                    <Box className="fieldWrapper">
                                        <SwitchForm
                                            name={PLAY_GATE_EMAIL_SHOW}
                                            sx={{ width: '100%', justifyContent: 'space-between' }}
                                            label={<Typography variant="body2">Email</Typography>}
                                            labelPlacement="start"
                                            switchProps={{ size: 'small' }}
                                            tooltipPlacement={SwitchTooltipPlacement.inside}
                                            disabled={isLoading}
                                        />
                                    </Box>
                                    <Box className="fieldWrapper">
                                        <SwitchForm
                                            name={PLAY_GATE_SKIPPABLE}
                                            sx={{ width: '100%', justifyContent: 'space-between' }}
                                            label={<Typography variant="body2">Skippable</Typography>}
                                            labelPlacement="start"
                                            switchProps={{ size: 'small' }}
                                            tooltipPlacement={SwitchTooltipPlacement.inside}
                                            helpInfo="Let your viewers keep watching without opting in. If you don’t select this, then someone must opt in to keep watching past this Play Gate"
                                            disabled={isLoading}
                                        />
                                    </Box>
                                    {values[PLAY_GATE_SKIPPABLE] && (
                                        <Input
                                            name={TEXT_SKIP}
                                            label="Text"
                                            placeholder="Type here ..."
                                            variant="outlined"
                                            inputProps={{ maxLength: 20 }}
                                            multiline
                                            displayMaxLength
                                            disabled={isLoading}
                                        />
                                    )}
                                </Box>

                                <Box className="error">{errors.switchValidate}</Box>
                                <Divider />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="formSectionAccordion" expanded={formExpanded}>
                            <AccordionSummary></AccordionSummary>
                            <AccordionDetails>
                                <Box className="subSection">
                                    <Typography className="subSectionTitle">Display time</Typography>
                                    <Box className="gap25">
                                        <Radio
                                            labelVariant="body2"
                                            onClick={() => {
                                                setFieldValue(BUTTON_TEXT, 'Keep Watching')
                                                setFieldValue(TEXT_TOP, PLAY_GATES_SECTION_INITIAL_VALUES[TEXT_TOP])
                                            }}
                                            disabled={isLoading}
                                            className="callToActionType"
                                            options={[
                                                {
                                                    label: (
                                                        <Box className="container">
                                                            <Box className="label">
                                                                Time
                                                                <HelpTooltip
                                                                    arrow
                                                                    id="TimePlayGate"
                                                                    placement="top"
                                                                    title="Time Play Gates will show an opt-in on top of your video at
                                                                a specific time that you choose."
                                                                />
                                                            </Box>
                                                        </Box>
                                                    ),
                                                    value: VideoPlayGateType.time,
                                                },
                                            ]}
                                            name={TYPE}
                                        />
                                    </Box>
                                    <Stack className="inputs" py="6px" sx={{ pl: 7 }}>
                                        <Input
                                            name={TIME}
                                            variant="outlined"
                                            placeholder="00:00"
                                            disabled={isLoading}
                                        />
                                    </Stack>
                                    <Box className="marginLeft gap25">
                                        <Radio
                                            labelVariant="body2"
                                            onClick={() => {
                                                setFieldValue(BUTTON_TEXT, 'Click Here')
                                                setFieldValue(
                                                    TEXT_TOP,
                                                    `Don't have time to watch now?<br/>`.concat(
                                                        PLAY_GATES_SECTION_INITIAL_VALUES[TEXT_TOP],
                                                    ),
                                                )
                                            }}
                                            disabled={isLoading}
                                            className="callToActionType"
                                            options={[
                                                {
                                                    label: (
                                                        <Box className="container">
                                                            <Box className="label">
                                                                Exit
                                                                <HelpTooltip
                                                                    id="ExitPlayGate"
                                                                    arrow
                                                                    placement="top"
                                                                    title=" Exit Play Gates will show an opt-in on top of your video
                                                                whenever someone pauses your video."
                                                                />
                                                            </Box>
                                                        </Box>
                                                    ),
                                                    value: VideoPlayGateType.exit,
                                                },
                                            ]}
                                            name={TYPE}
                                        />
                                    </Box>
                                </Box>
                                <Divider />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            sx={{
                                margin: 0,
                            }}
                            expanded={formExpanded}
                            className="formSectionAccordion"
                        >
                            <AccordionSummary></AccordionSummary>
                            <AccordionDetails>
                                <Box className="subSection">
                                    <Typography className="subSectionTitle">Main Buttons Colors</Typography>

                                    <Box className="gap">
                                        <ColorPicker
                                            name={PLAY_GATE_BUTTON_COLOR_BACKGROUND}
                                            label="Button"
                                            disabled={isLoading}
                                        />
                                        <ColorPicker
                                            name={PLAY_GATE_BUTTON_COLOR_FOREGROUND}
                                            label="Text"
                                            disabled={isLoading}
                                        />
                                    </Box>
                                </Box>
                                <Divider />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="formSectionAccordion" expanded={formExpanded}>
                            <AccordionSummary></AccordionSummary>
                            <AccordionDetails>
                                <Box className="ctas">
                                    <Button variant="outlined" className="redesign" onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" className="redesign" type="submit" disabled={isLoading}>
                                        Save
                                    </Button>
                                </Box>
                                <Box className="error">{errors[PLAY_GATE_ONLY_ONE] || errors[PLAY_GATE_TIME_UNIQ]}</Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
