import { Skeleton, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'

import { Feature } from 'design/templates/Modal/modalTemplates/SubscriptionModal/Feature'

import { useTranslation } from 'react-i18next'
import { useCustomerSubscriptionQuery } from 'api/queries'
import style from './PlanDetails.style'

type PlanDetailsProps = {
    isTrialStartedMode?: boolean
    dueToday?: string
    price?: string
    pricePeriod?: string
    features?: string[]
    isPriceLoading?: boolean
    planName?: string
}

const formatPrice = (price?: string) => {
    if (!price) return ''
    const priceNumber = Number(price)

    if (Number(priceNumber.toFixed(0)) === priceNumber) {
        return priceNumber.toFixed(0)
    }
    return priceNumber.toFixed(2)
}

const PlanDetails = ({
    isTrialStartedMode,
    dueToday,
    price,
    pricePeriod,
    features,
    isPriceLoading,
    planName,
}: PlanDetailsProps) => {
    const { t } = useTranslation()
    const { data: subscription } = useCustomerSubscriptionQuery()

    return (
        <Stack sx={style.root}>
            {isTrialStartedMode && subscription?.currentPeriodEndsAt ? (
                <Typography sx={style.dueToday} variant="caption1" fontWeight={600}>
                    {t('Trial active until')} {format(new Date(subscription?.currentPeriodEndsAt), 'MMMM d')}
                </Typography>
            ) : (
                <Typography sx={style.dueToday} variant="caption1" fontWeight={600}>
                    {t('Due today')}: ${isPriceLoading ? <Skeleton width={16} /> : formatPrice(dueToday)}. {t('Then')},
                    ${isPriceLoading ? <Skeleton width={16} /> : formatPrice(price)}/{pricePeriod}
                </Typography>
            )}

            <Stack gap={2}>
                <Typography variant="subtitle1" fontWeight={700}>
                    {planName}
                </Typography>
                <Typography sx={style.price} variant="highlight">
                    <Stack direction="row" component="span" alignItems="flex-end">
                        <Typography sx={style.priceValue} variant="body1">
                            $
                        </Typography>
                        {price && formatPrice(price)}
                    </Stack>
                    <Typography sx={style.pricePeriod} variant="body1">
                        /{pricePeriod}
                    </Typography>
                </Typography>
            </Stack>

            <Stack gap={4}>
                {features?.map((feature) => (
                    <Feature key={feature} featureTitle={feature} typographyVariant="subtitle2" />
                ))}
            </Stack>
        </Stack>
    )
}

export default PlanDetails
