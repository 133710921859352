import { lazy, ComponentType } from 'react'
import { Cookies } from 'react-cookie'

import FallbackReloadPage from 'design/atoms/Fallback/FallbackReloadPage'
import { COOKIES_OPTIONS } from 'constants/cookies.constants'

const RETRY_IMPORT_RELOAD_COOKIE_KEY = 'RETRY_IMPORT_RELOAD'

const retryImport = <T extends { default: ComponentType }>(
    fn: () => Promise<T>,
    retries = 1,
    delay = 1000,
): Promise<T> =>
    new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch(() => {
                if (retries <= 0) {
                    const value = new Cookies().get(RETRY_IMPORT_RELOAD_COOKIE_KEY)

                    if (!value) {
                        new Cookies().set(RETRY_IMPORT_RELOAD_COOKIE_KEY, '1', { ...COOKIES_OPTIONS, maxAge: 60 })
                        setTimeout(() => window.location.reload(), 10)
                        return
                    }

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
                    return resolve({ default: FallbackReloadPage } as any)
                }

                setTimeout(
                    () =>
                        retryImport(fn, retries - 1, delay)
                            .then(resolve)
                            .catch(reject),
                    delay,
                )
            })
    })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lazyImport = <T extends { default: ComponentType<any> }>(fn: () => Promise<T>) => lazy(() => retryImport(fn))

export default lazyImport
