export default {
    header: "Changing this sets the video quality at startup. It might be adjusted depending on the viewer's connection.",
    qualityOptions: {
        auto: {
            label: 'Auto',
            tooltip:
                'The player will find the best startup quality automatically, to match your viewer’s internet speed.',
            extraInfo: '',
        },
        low: {
            label: 'Low Quality',
            extraInfo: ' - Fastest 360p',
            tooltip: '360p (low-res). Player will be able to play at a better quality after 40 seconds, if available.',
        },
        medium: {
            label: 'Medium Quality',
            extraInfo: ' - 720p',
            tooltip:
                '720p in most cases, but for some videos, it could be 360p (high-res) or 1080p (because of adaptive bitrate streaming).',
        },
        high: {
            label: 'High Quality',
            extraInfo: ' - 1080p',
            tooltip: '1080p.',
        },
    },
    lowerBandwidthUsage: {
        label: 'Lower Bandwidth Usage',
        tooltip:
            'Enabling this option limits video resolution to enhance bandwidth efficiency, potentially affecting video quality.',
    },
}
