import { Stack, SxProps, Theme, Typography } from '@mui/material'

import { ImgWithErrorHandler } from 'design/atoms/ImgWithErrorHandler'
import { getSx } from 'styles/theme/utils'
import { linesLimit } from 'styles/common'

import style from './EntityRow.style'

interface EntityRowProps {
    videoImg?: string
    title?: React.ReactNode
    selected?: boolean
    rootSx?: SxProps<Theme>
    leftSlot?: React.ReactNode
    onClick?: () => void
}

export const EntityRow = ({ videoImg, title, selected = false, rootSx, leftSlot, onClick }: EntityRowProps) => {
    return (
        <Stack
            sx={getSx([style.root.idle, selected && style.root.selected], rootSx)}
            direction="row"
            alignItems="center"
            gap={3}
            onClick={onClick}
        >
            {leftSlot}
            {videoImg && <ImgWithErrorHandler sx={style.videoImage} src={videoImg} />}
            <Typography sx={linesLimit(1)} variant="body1">
                {title}
            </Typography>
        </Stack>
    )
}
