import { Dispatch, ReactNode, RefObject, SetStateAction, useEffect, useRef } from 'react'
import { alpha, Box, Divider, Grid, Stack, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import CreditCardIcon from '@mui/icons-material/CreditCard'

import cvvIcon from 'assets/img/cvv.svg'
import { CHARGIFY_PUBLIC_KEY, CHARGIFY_SERVER_HOST } from 'App'
import { CreditCard } from 'types/Auth'
import CreditCardFormItemSkeleton from 'design/molecules/CreditCardForm/CreditCardFormItemSkeleton'
import {
    ChargifyError,
    useChagifyFormLoadingState,
    useChagifyTokenStatus,
    useShowErrorAddCard,
} from 'hooks/chargify.hooks'
import { useLayout } from 'hooks/utilities/useLayout'
import locale from './CreditCardForm.locale'
import style from './CreditCardForm.style'

type CreditCardFormProps = {
    isSubmitting?: boolean
    isAddingCreditCard?: boolean
    setSubmitting?: Dispatch<SetStateAction<boolean>>
    onApply?: (token: string) => void
    actions?: ReactNode
    displayActions?: boolean
    creditCard?: CreditCard | null
    formRef?: RefObject<HTMLFormElement>
    chargifyRef?: RefObject<typeof window.Chargify>
}

const CreditCardForm = ({
    onApply,
    creditCard,
    actions,
    isAddingCreditCard,
    isSubmitting,
    setSubmitting,
    displayActions = true,
    formRef,
    chargifyRef,
}: CreditCardFormProps) => {
    const showErrorAddCard = useShowErrorAddCard()
    const { isTablet } = useLayout()
    const ref = formRef || useRef(null)
    const theme = useTheme()
    const chargify = chargifyRef || useRef(window.Chargify ? new window.Chargify() : null)
    const { chargifyTokenSuccess, chargifyTokenError } = useChagifyTokenStatus()
    const { isLoading } = useChagifyFormLoadingState(ref)

    const handleSubmit = () => {
        setSubmitting?.(true)
        chargify.current?.token(
            ref.current,

            chargifyTokenSuccess(async (token) => {
                setSubmitting?.(false)
                onApply?.(token)
            }),
            (error: ChargifyError) => {
                chargifyTokenError(error)
                setSubmitting?.(false)
            },
        )

        if (!navigator.onLine) {
            setSubmitting?.(false)
            showErrorAddCard('Unable to save payment method. Please check your connection and try again.')
        }
    }

    useEffect(() => {
        const handleOffline = () => {
            if (isSubmitting) {
                setSubmitting?.(false)
                showErrorAddCard('Unable to save payment method. Please check your connection and try again.')
            }
        }

        window.addEventListener('offline', handleOffline)

        return () => {
            window.removeEventListener('offline', handleOffline)
        }
    }, [isSubmitting])

    useEffect(() => {
        chargify.current?.load({
            selector: '#credit-card-chargify-form',
            publicKey: CHARGIFY_PUBLIC_KEY,
            type: 'card',
            serverHost: CHARGIFY_SERVER_HOST,
            hideCardImage: true,
            optionalLabel: '',
            requiredLabel: '',
            addressDropdowns: true,
            style: {
                field: {
                    overflow: 'hidden',
                    margin: 0,
                },
                input: {
                    ...theme.typography.body1,
                    color: theme.palette.text.primary,
                    borderRadius: '12px',
                    padding: '9.5px 14px',
                    height: '42px',
                    fontFamily: 'Inter, Arial, sans-serif',
                    background: theme.palette.background.default,
                    placeholder: { color: alpha(theme.palette.text.secondary, 0.6) },
                    boxShadow: theme.shadows[2],
                },
                label: {
                    ...theme.typography.body2,
                    color: theme.palette.text.secondary,
                    margin: '0 0 6px 0',
                },
                message: {
                    ...theme.typography.caption,
                    color: theme.palette.error.main,
                    margin: '0',
                },
            },
            fields: {
                firstName: {
                    selector: '#chargify-firstname',
                    required: true,
                    message: 'Please enter a valid first name',
                    maxlength: '30',
                    value: creditCard?.firstname || '',
                    ...locale.firstName,
                },
                lastName: {
                    selector: '#chargify-lastname',
                    required: true,
                    message: 'Please enter a valid last name',
                    maxlength: '30',
                    value: creditCard?.lastname || '',
                    ...locale.lastName,
                },
                number: {
                    selector: '#chargify-card',
                    message: 'Please enter a valid card number',
                    style: {
                        input: {
                            padding: '10px 10px 8px 46px',
                        },
                    },
                    placeholder: creditCard?.cardNumber?.replaceAll('-', ' ') || locale.number.placeholder,
                    label: locale.number.label,
                },
                month: {
                    selector: '#chargify-month',
                    message: ' ',
                    required: true,
                    ...locale.month,
                },
                year: {
                    selector: '#chargify-year',
                    message: ' ',
                    required: true,
                    ...locale.year,
                },
                cvv: {
                    selector: '#chargify-cvv',
                    required: true,
                    message: ' ',
                    ...locale.cvv,
                },
                zip: {
                    selector: '#chargify-zip',
                    required: true,
                    message: 'Please enter a valid zip code',
                    maxlength: '10',
                    ...locale.zip,
                },
            },
        })

        return () => {
            chargify.current?.unload()
        }
    }, [theme])

    return (
        <Box component="form" sx={style.root} ref={ref} id="credit-card-chargify-form">
            <Box>
                <Grid spacing={3} container>
                    <Grid sx={style.item} tablet={6} mobile={12} id="chargify-firstname" item>
                        <CreditCardFormItemSkeleton isLoading={isLoading} />
                    </Grid>
                    <Grid sx={style.itemFloat} tablet={6} mobile={12} id="chargify-lastname" item>
                        <CreditCardFormItemSkeleton isLoading={isLoading} />
                    </Grid>
                </Grid>

                <Grid spacing={3} container>
                    <Grid sx={style.item} mobile={12} id="chargify-card" item>
                        <CreditCardFormItemSkeleton isLoading={isLoading}>
                            <CreditCardIcon sx={style.cardIco} />
                        </CreditCardFormItemSkeleton>
                    </Grid>
                </Grid>

                <Grid spacing={3} container>
                    <Grid sx={style.item} tablet={2.5} mobile={3} id="chargify-month" item>
                        <CreditCardFormItemSkeleton isLoading={isLoading} />
                    </Grid>
                    <Grid sx={style.item} tablet={2.5} mobile={3} id="chargify-year" item>
                        <CreditCardFormItemSkeleton isLoading={isLoading} />
                    </Grid>
                    {!isTablet && <Grid sx={style.item} tablet={4.5} mobile={0} item />}
                    <Grid sx={style.item} position="relative" tablet={2.5} mobile={6} id="chargify-cvv" item>
                        <CreditCardFormItemSkeleton isLoading={isLoading}>
                            <Box sx={style.cvv} component="img" src={cvvIcon} alt="cvv" />
                        </CreditCardFormItemSkeleton>
                    </Grid>
                </Grid>

                <Grid spacing={3} container>
                    <Grid sx={style.item} mobile={12} id="chargify-zip" item>
                        <CreditCardFormItemSkeleton isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Box>
            {displayActions && (
                <>
                    <Divider />
                    <Stack direction="row" justifyContent="end" gap={3} py={3}>
                        {actions}
                        <LoadingButton
                            onClick={handleSubmit}
                            variant="contained"
                            loading={isSubmitting || isAddingCreditCard}
                        >
                            {locale.save}
                        </LoadingButton>
                    </Stack>
                </>
            )}
        </Box>
    )
}

export default CreditCardForm
