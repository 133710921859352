import { Box, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from 'App'
import { useConversionsQuery, useCustomerQuery } from 'api/queries'
import HelperButton from 'design/atoms/Table/Toolbar/HelperButton'
import SearchField from 'design/atoms/Table/Toolbar/SearchField'
import { HEADLINE } from 'design/atoms/Table/constants'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { useAgencyAccess } from 'design/pages/AgencyAccount/AgencyAccount.hooks'
import { ConversionsLockedContent } from 'design/pages/Conversions/ConversionsLockedContent/ConversionsLockedContent'
import { EditConversionModal, openSubscriptionModal, setPlan, SubscriptionCtaSource } from 'design/templates/Modal'
import { closeModal, ModalType, openModal } from 'design/templates/Modal/ModalTypes/modal.slice'
import Page from 'design/templates/Page'
import usePageTitle from 'hooks/navigation/usePageTitle'
import { useLockPage } from 'hooks/user/useLockPage'
import useTariffPlans from 'hooks/user/useTariffPlans'
import { Conversion } from 'types/Conversion'
import { CustomerStatus } from 'types/Customer'
import { useCheckAccess } from 'utils'
import { FeatureGroup } from 'constants/keys/featuresAccess.constants'

import { conversionLockedModalConfig } from 'constants/subscriptions'
import style from './Conversions.style'
import { ConversionsTable } from './ConversionsTable/ConversionsTable'

export const Conversions = withErrorBoundary(() => {
    usePageTitle('Conversions | Vidalytics')
    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const lockModalContainer = useRef<HTMLDivElement>(null)
    const { data: conversions, isLoading, isError } = useConversionsQuery()
    const isConversionsLocked = useCheckAccess(FeatureGroup.Conversions)()
    const { pro: proPlan } = useTariffPlans()
    const [conversion, setConversion] = useState<Conversion>()
    const [editConversionModalOpen, setEditConversionModalOpen] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const { agencyAccessLocked } = useAgencyAccess()
    const { data: customer } = useCustomerQuery()
    const isUserOnTrial = customer?.status === CustomerStatus.trial

    const onUpgradeClick = () => {
        dispatch(closeModal())
        dispatch(setPlan({ plan: proPlan }))
        dispatch(
            openSubscriptionModal({
                containerRef: lockModalContainer.current,
                ctaSource: SubscriptionCtaSource.CONVERSIONS,
            }),
        )
    }

    const openOldLockedModal = () => {
        dispatch(
            openModal({
                type: ModalType.OLD_FEATURE_LOCKED,
                title: 'Upgrade to Unlock Conversion Tracking!',
                description:
                    'See the exact spot in your videos that makes your viewers convert. It is as easy as dropping a snippet of code on your thank you page, and, oh so powerful... 😎',
                proPlan,
                isUserOnTrial,
                containerRef: lockModalContainer.current,
                onUpgradeClick,
                children: <ConversionsLockedContent />,
            }),
        )
    }

    const { containerRef, isLocked, lockedPagesFF } = useLockPage({
        featureGroup: FeatureGroup.Conversions,
        openOldLockedModal,
        config: conversionLockedModalConfig,
    })

    const onEditConversionModalClose = () => {
        setEditConversionModalOpen(false)
        setConversion(undefined)
    }

    const buttonConfiguration = {
        label: '+ New Conversion',
        onClick: () => setEditConversionModalOpen(true),
        disabled: isConversionsLocked || agencyAccessLocked,
        testId: 'newConversionButton',
    }

    const handleActionEdit = (conversion?: Conversion) => {
        if (conversion) {
            setConversion(conversion)
        }
        setEditConversionModalOpen(true)
    }

    const title = t('Conversions')

    if (isLocked && lockedPagesFF) {
        return (
            <Page title={title}>
                <Box ref={containerRef} />
            </Page>
        )
    }

    return (
        <Page title={title} stackRef={lockModalContainer} isError={isError}>
            <Stack flexDirection="row" sx={style.header} gap={4} flexWrap="wrap" data-testId="conversionsPageHeader">
                <Typography color="text.primary" variant="h6" component="h1" noWrap>
                    {t('Conversions')}
                </Typography>

                {!isError && (
                    <>
                        <SearchField
                            sx={style.headerSearch}
                            searchByIds={[HEADLINE]}
                            searchByLabel={t('Search by Name')}
                            onChange={(search) => setSearchValue(search)}
                            value={searchValue}
                        />

                        <Box sx={style.helperButton}>
                            <HelperButton fullWidth button={buttonConfiguration} />
                        </Box>
                    </>
                )}
            </Stack>
            <ConversionsTable
                conversions={conversions}
                isLoading={isLoading}
                searchValue={searchValue}
                locked={isConversionsLocked}
                handleActionEdit={handleActionEdit}
            />

            <EditConversionModal
                open={editConversionModalOpen}
                conversion={conversion}
                onClose={onEditConversionModalClose}
            />
        </Page>
    )
})

export default Conversions
