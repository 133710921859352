import { Form, Formik, FormikContextType } from 'formik'
import { useLayout } from 'hooks/utilities/useLayout'
import mixpanel from 'mixpanel-browser'
import { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate, useSearchParams } from 'react-router-dom'
import isCreditCard from 'validator/lib/isCreditCard'
import * as yup from 'yup'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { IS_REDIRECTING } from 'api/constants'
import { useActivatePlanMutation, useSignUpMutation, useUpdateSignUpCardByTokenMutation } from 'api/mutations'
import { queryClient, useCustomerQuery, useUnathorizedFeatureFlagsQuery } from 'api/queries'
import { useAppDispatch } from 'App'
import Awards from 'design/molecules/Awards'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { assignAgencyAccountStatus } from 'design/organisms/Account/account.slice'
import usePlan from 'design/organisms/SignUp/hooks/usePlan'
import { SignUpSteps } from 'design/organisms/SignUp/SignUpSteps'
import useExternalProjectNavigate from 'hooks/navigation/useExternalProjectNavigate'
import useEventTrack from 'hooks/analytics/useEventTrack'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import { useVdly } from 'thirdPartyServices/vdly'
import { SignUpFormData, SignUpResponse } from 'types/Auth'
import { ApiHandle, CustomerSubscriptionPlanTier } from 'types/Customer'
import { getMarketingParamsAll } from 'utils'
import useMixpanel from 'hooks/analytics/useMixpanel'

import { useFingerprint } from 'hooks/user/useFingerprint'
import {
    AGENCY_INVITATION_TOKEN,
    API_HANDLE,
    BUSINESSNAME,
    CARD_NUMBER,
    CARD_STEP,
    CREDIT_CARD_TOKEN,
    CREDIT_FIRSTNAME,
    CREDIT_LASTNAME,
    CUSTOMER,
    CVV,
    EXPIRATION_DATE,
    FIRSTNAME,
    FULL,
    GENERAL_STEP,
    INITIAL_VALUES,
    LASTNAME,
    REDIRECT_URL,
    SOURCE,
    TOKENS,
    UPSELL_TOKEN,
    UPSELL_TOKEN_COOKIE,
    ZIPCODE,
} from 'design/organisms/SignUp/constants'
import { generatePayload, getCreditCardInfo } from 'design/organisms/SignUp/utils'
import {
    ACCESS,
    BUSINESSNAME_VALIDATION,
    EMAIL,
    EMAIL_VALIDATION,
    FIRSTNAME_VALIDATION,
    LASTNAME_VALIDATION,
    PASSWORD,
    PASSWORD_VALIDATION,
    REFRESH,
} from 'constants/validations/user.constants'
import { route } from 'constants/routes'
import {
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    COOKIES_OPTIONS,
    COOKIES_OPTIONS_AUTH,
    REFERRER,
} from 'constants/cookies.constants'
import ChargifyForm from './ChargifyForm'
import GeneralForm from './GeneralForm'
import style from './style'

const generalFormValidationSchema = yup.object({
    [FIRSTNAME]: FIRSTNAME_VALIDATION,
    [LASTNAME]: LASTNAME_VALIDATION,
    [EMAIL]: EMAIL_VALIDATION,
    [PASSWORD]: PASSWORD_VALIDATION,
})
const agencyFormValidationSchema = yup.object({
    [BUSINESSNAME]: BUSINESSNAME_VALIDATION,
    [FIRSTNAME]: FIRSTNAME_VALIDATION,
    [LASTNAME]: LASTNAME_VALIDATION,
    [EMAIL]: EMAIL_VALIDATION,
    [PASSWORD]: PASSWORD_VALIDATION,
})

export const cardFormValidationSchema = yup.object({
    [CREDIT_FIRSTNAME]: FIRSTNAME_VALIDATION,
    [CREDIT_LASTNAME]: LASTNAME_VALIDATION,
    [CARD_NUMBER]: yup
        .string()
        .test('test-card-number', 'Please enter a valid card number', (value) => (value ? isCreditCard(value) : true))
        .required('Card number is required'),
    [EXPIRATION_DATE]: yup
        .string()
        .test('test-card-date', 'Wrong date format', (value) =>
            value ? /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/.test(value) : true,
        )
        .required('Expiration date is required'),
    [CVV]: yup
        .string()
        .test('test-card-cvv', 'Please enter a valid CVV', (value) => /^[0-9]{3,4}$/.test(value as string))
        .required('CVV is required'),
    [ZIPCODE]: yup
        .string()
        .min(2, 'Please enter a valid zip code')
        .max(10, 'Please enter a valid zip code')
        .required('Zip code is required'),
})

type FormsProps = {
    upsellUpgrade?: boolean
    updateCard?: boolean
    isPlanError: boolean
}

export type SignUpSubmitProps = {
    type: string
    formik: FormikContextType<SignUpFormData>
    token?: string
    isSkipButton?: boolean
}

const Forms = ({ upsellUpgrade, updateCard, isPlanError }: FormsProps) => {
    // 1 - general info, 2 - card info
    useUnathorizedFeatureFlagsQuery()
    const [step, setStep] = useState(upsellUpgrade ? CARD_STEP : GENERAL_STEP)
    const [type, setType] = useState<string | null>(null)
    const [cookies, setCookie, removeCookie] = useCookies()
    const [searchParams] = useSearchParams()
    const pricepoint = searchParams.get('pricepoint')
    const addons = searchParams.get('addons')
    const productPricePoint = searchParams.get('productPricePoint')
    const couponCodes = searchParams.getAll('coupon')
    const email = searchParams.get('email')
    const vdly = useVdly()
    const fingerprint = useFingerprint()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { isTablet } = useLayout()
    const externalNavigate = useExternalProjectNavigate()
    const { track } = useMixpanel()
    const { data: customer } = useCustomerQuery({ enabled: upsellUpgrade })
    const { planId, plan, isLoading } = usePlan()
    const skipBillingPage = plan?.skipBillingPageAtSignup

    const navigateToDashboard = () => {
        if (plan.tier === CustomerSubscriptionPlanTier.agency) {
            dispatch(assignAgencyAccountStatus())
            navigate(route.agency.dashboard)
        } else {
            navigate(`${route.welcome.index}#success-${planId}`)
        }
    }

    const activatePlanMutation = useActivatePlanMutation({ onSuccess: navigateToDashboard })
    const updateCreditCardByTokenMutation = useUpdateSignUpCardByTokenMutation({
        onSuccess: () => navigate(route.account.paymentProcessing),
    })

    const onSuccess = async (response: SignUpResponse) => {
        queryClient.setQueryData(IS_REDIRECTING, true)

        removeCookie(REFRESH_TOKEN, COOKIES_OPTIONS_AUTH)
        setCookie(ACCESS_TOKEN, response[TOKENS][ACCESS], COOKIES_OPTIONS_AUTH)

        if (response[TOKENS][REFRESH]) {
            setCookie(REFRESH_TOKEN, response[TOKENS][REFRESH], COOKIES_OPTIONS_AUTH)
        }
        if (response[UPSELL_TOKEN]) {
            setCookie(UPSELL_TOKEN_COOKIE, response[UPSELL_TOKEN], COOKIES_OPTIONS)
        }

        const customer = response[CUSTOMER]
        const signUpMethod = response[SOURCE]
        useEventTrack(`signup-${planId}`, customer.guid)

        mixpanel.identify(customer.guid)

        mixpanel.people.set({
            $name: `${customer.firstname} ${customer.lastname}`,
            $email: customer.email,
            guid: customer.guid,
        })

        track(MIXPANEL_EVENTS.SIGN_UP_ACCOUNT_CREATED, {
            method: signUpMethod ? signUpMethod : 'email',
        })

        if (response[REDIRECT_URL]) {
            externalNavigate(response[REDIRECT_URL], '_self', '')()
        } else {
            queryClient.setQueryData(IS_REDIRECTING, false)
            navigateToDashboard()
        }
    }

    const signUpMutation = useSignUpMutation({ onSuccess, onError: () => setType(null) })

    useEffect(() => {
        if (planId) {
            setCookie(API_HANDLE, planId, COOKIES_OPTIONS)
        }
    }, [planId])

    const validateForm = async (formik: FormikContextType<SignUpFormData>) => {
        const errors = await formik.validateForm()
        await formik.submitForm()

        if (Object.keys(errors).length) {
            return false
        }

        return true
    }

    const signUpSubmit =
        ({ type, formik, token, isSkipButton }: SignUpSubmitProps) =>
        async () => {
            setType(type)
            if (type === FULL) {
                const valid = await validateForm(formik)

                if (!valid) {
                    return
                }
            }

            const marketingParams = getMarketingParamsAll(cookies)
            const referrer = cookies[REFERRER]

            const payload = generatePayload({
                values: formik.values,
                agencyInvitationToken: searchParams.get(AGENCY_INVITATION_TOKEN),
                cookies,
                vdly,
                fingerprint,
                type,
                pricepoint,
                utm: marketingParams,
                referrer,
                couponCodes,
                token,
            })

            signUpMutation.mutate(payload)

            const event = isSkipButton ? MIXPANEL_EVENTS.SIGN_UP_CARD_SKIP : MIXPANEL_EVENTS.SIGN_UP_CONFIRM
            mixpanel.track(event, {
                subscription_tier: plan.tier,
                subscription_id: plan.apiHandle,
                subscription_istrial: plan.hasTrial,
                subscription_plan: plan.name,
                url: window.location.href,
            })
        }

    const upsellSubmit =
        ({ formik, token }: SignUpSubmitProps) =>
        async () => {
            let valid = true

            if (!token) {
                valid = await validateForm(formik)
            }

            if (!valid || !planId) {
                return
            }

            activatePlanMutation.mutate({
                apiHandle: planId as ApiHandle,
                ...(token ? { [CREDIT_CARD_TOKEN]: token } : { creditCard: getCreditCardInfo(formik.values) }),
                pricePoint: productPricePoint,
                addons,
            })
        }

    const updateCardSubmit =
        ({ formik, token }: SignUpSubmitProps) =>
        async () => {
            let valid = true

            if (!token) {
                valid = await validateForm(formik)
            }

            if (!valid) {
                return
            }

            updateCreditCardByTokenMutation.mutate({ token })
        }

    const getSubmitFn = () => {
        if (updateCard) return updateCardSubmit
        if (upsellUpgrade) return upsellSubmit

        return signUpSubmit
    }

    const handleSubmit = getSubmitFn()

    const canSkip = useMemo(() => {
        if (upsellUpgrade || plan.creditCardRequired) {
            return false
        }

        return plan.hasTrial || !(parseFloat(plan.price) > 0)
    }, [plan])

    const changeStep = (nextStep: number) => {
        navigate(`${route.auth.signUp.withPlan({ planId: planId! })}${location.search}#payment`)
        setStep(nextStep)
        mixpanel.track(MIXPANEL_EVENTS.SIGN_UP_PAYMENT_OPEN, {
            subscription_tier: plan.tier,
            subscription_id: plan.apiHandle,
            subscription_istrial: plan.hasTrial,
            subscription_plan: plan.name,
            url: window.location.href,
        })
    }

    const validationSchema = useMemo(() => {
        if (step === GENERAL_STEP && planId === 'agency') return agencyFormValidationSchema
        else if (step === GENERAL_STEP) return generalFormValidationSchema
        else return cardFormValidationSchema
    }, [step, planId])

    const renderSteps = (!upsellUpgrade && !skipBillingPage) || planId === 'agency'

    const renderForm = () => {
        const renderOnlyGeneralForm = skipBillingPage && !upsellUpgrade

        if (step === GENERAL_STEP || renderOnlyGeneralForm) {
            return (
                <Form>
                    <GeneralForm
                        changeStep={changeStep}
                        handleSubmit={handleSubmit}
                        isLoading={signUpMutation.isLoading}
                        isPlanError={isPlanError}
                        renderOnlyGeneralForm={renderOnlyGeneralForm}
                        plan={plan}
                    />
                </Form>
            )
        } else {
            return (
                <ChargifyForm
                    handleSubmit={handleSubmit}
                    isLoading={signUpMutation.isLoading || activatePlanMutation.isLoading}
                    type={type}
                    canSkip={canSkip}
                    updateCard={updateCard}
                />
            )
        }
    }

    return (
        <Box data-testid="signUpForm">
            {renderSteps && (
                <Box mb={8} width={1} data-testid="signUpSteps">
                    <SignUpSteps onChange={setStep} step={step} isLoading={isLoading} />
                </Box>
            )}
            <Typography variant={isTablet ? 'h6' : 'h5'} component="div" sx={style.title} data-testid="formTitle">
                You&apos;re About to Secure a 25% Conversion Bump In Your Video Marketing
            </Typography>
            <Formik
                initialValues={{
                    ...INITIAL_VALUES,
                    ...(email ? { [EMAIL]: email } : {}),
                    ...(upsellUpgrade && customer
                        ? { [FIRSTNAME]: customer.firstname, [LASTNAME]: customer.lastname }
                        : {}),
                }}
                validationSchema={validationSchema}
                /* eslint-disable  @typescript-eslint/no-empty-function */
                onSubmit={() => {}}
            >
                {() => renderForm()}
            </Formik>

            <Box textAlign="center" mt={6}>
                <Awards size="md" />
            </Box>
        </Box>
    )
}

export default withErrorBoundary(Forms)
