import { useMemo } from 'react'
import { useLocation, useMatch, useParams } from 'react-router-dom'

import { PlanPayload } from 'types/Auth'
import { usePlanQuery, useGetSignUpPlanQuery } from 'api/queries'
import { PlanId } from 'types/Plan'
import route from 'constants/routes'

const usePlan = () => {
    const location = useLocation()
    const { planId: planIdParam } = useParams<{ planId: PlanId }>()
    const planIdHash = location.hash?.match(/#success-(.+)/)?.[1]
    const planId = (planIdParam || planIdHash) as PlanId
    const isUpdatePayment = Boolean(useMatch(route.account.updatePayment))

    const {
        data: planData,
        isLoading: isLoadingPlan,
        isError: isErrorPlan,
    } = usePlanQuery(planId!, {
        enabled: Boolean(planId),
    })
    const {
        data: signUpPlan,
        isLoading: isLoadingSignUpPlan,
        isError: isErrorSignUpPlan,
    } = useGetSignUpPlanQuery({ enabled: !planId && isUpdatePayment })

    const isLoading = isLoadingPlan || isLoadingSignUpPlan
    const isError = isErrorPlan || isErrorSignUpPlan

    const plan = useMemo(() => planData || signUpPlan?.data || ({} as PlanPayload), [planData, signUpPlan])
    const idToUse = planId || plan.apiHandle

    return { planId: idToUse, plan, isLoading, isError }
}

export default usePlan
