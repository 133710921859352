import { Button, Menu, Stack, InputAdornment, TextField, Box } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { useState } from 'react'

import { ContentList } from 'design/molecules/ContentList'
import style from './SelectVideo.style'

export const SelectVideoModal = ({
    handleSelectVideo,
    disabled,
    excludeVideo,
}: {
    handleSelectVideo?: (guid: string) => void
    disabled?: boolean
    excludeVideo?: string | null
}) => {
    const [selectedFolderGuid, setSelectedFolderGuid] = useState<string>('')
    const [searchText, setSearchText] = useState<string>('')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleModalOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleModalClose = () => {
        setAnchorEl(null)
        setSearchText('')
        setSelectedFolderGuid('')
    }
    return (
        <Box width={1}>
            <Button fullWidth disabled={disabled} variant="contained" color="secondary" onClick={handleModalOpen}>
                Another Vid
            </Button>

            <Menu sx={style.menu} anchorEl={anchorEl} open={!!anchorEl} onClose={handleModalClose} id="test">
                <Stack height={1} tabIndex={null as unknown as undefined}>
                    <TextField
                        sx={style.search}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Type here..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon />
                                </InputAdornment>
                            ),
                            notched: false,
                        }}
                    />

                    <ContentList
                        selectedEnitityGuid={selectedFolderGuid}
                        excludeVideo={excludeVideo}
                        handleFolderClick={() => false}
                        handleVideoClick={(_e, video) => {
                            handleSelectVideo?.(video.guid ?? '')
                            handleModalClose()
                        }}
                        searchText={searchText}
                        disableLink
                    />
                </Stack>
            </Menu>
        </Box>
    )
}
