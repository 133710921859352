import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import { Button, Stack, useMediaQuery, useTheme } from '@mui/material'
import { Row, UseTableCellProps } from 'react-table'

import { useAppDispatch } from 'App'
import { Link } from 'design/atoms/Link'
import { ExpandedVideo } from 'design/pages/MyVids'
import useSettingsLinkProps from 'design/pages/VidSettings/hooks/useSettingsLinkProps'
import { useGetDetailedStatsUrl } from 'design/pages/VidStats/hooks/useGetDetailedStatsUrl'
import { ModalType, openModal } from 'design/templates/Modal/ModalTypes/modal.slice'
import useUpgradeCallback from 'hooks/user/useUpgradeCallback'

import './index.scss'

type RowActionsProps = {
    cell: UseTableCellProps<object>
    row: Row<ExpandedVideo>
    head?: boolean
    folder?: boolean
}

export function RowActions({
    row: {
        original: { guid, isReady },
    },
    folder,
}: RowActionsProps) {
    const dispatch = useAppDispatch()
    const { getDetailedStatsUrl } = useGetDetailedStatsUrl()
    const settingsLinkProps = useSettingsLinkProps()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))
    const upgradeCallback = useUpgradeCallback()

    const openVideoEmbedCodeModal = upgradeCallback(() => {
        dispatch(openModal({ type: ModalType.VIDEO_EMBED_CODE, videoId: guid }))
    })

    if (folder) {
        return null
    }

    return (
        <div className="RowActions">
            <Stack justifyContent="space-between" gap={3} direction="row">
                <Link to={getDetailedStatsUrl({ videoGuid: guid })} disabled={!isReady}>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={!isReady}
                        className="button"
                        data-testid="StatsButton"
                    >
                        Stats
                    </Button>
                </Link>
                <Link to={settingsLinkProps.getLink(guid)} external={settingsLinkProps.external}>
                    <Button variant="outlined" color="primary" className="button" data-testid="EditButton">
                        Edit
                    </Button>
                </Link>
                <Button
                    onClick={openVideoEmbedCodeModal}
                    sx={{ display: isMobile ? 'none' : 'inline-flex' }}
                    variant="outlined"
                    color="primary"
                    className="button"
                >
                    <ShareOutlinedIcon />
                </Button>
            </Stack>
        </div>
    )
}
