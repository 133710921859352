import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { HelpTooltip } from 'design/atoms/HelpTooltip'
import locale from './ClosedCaptionsZoneLoader.locale'
import style from './ClosedCaptionsZoneLoader.style'

type ClosedCaptionsZoneLoaderPropsType = {
    isGenerating?: boolean
    text?: string
    handleCancel?: () => void
}

const ClosedCaptionsZoneLoader = ({ handleCancel, text, isGenerating = false }: ClosedCaptionsZoneLoaderPropsType) => {
    return (
        <Box sx={style.root}>
            <HelpTooltip title={locale.tooltip} iconProps={{ sx: style.tooltip }} />
            <Box textAlign="center">
                <CircularProgress />
                {!text && <Typography mt={6}>{locale.loading}</Typography>}
                {text && <Typography mt={6}>{text}</Typography>}
                <>
                    <Typography color="text.secondary">{locale.takeSomeTime}</Typography>
                    <Button
                        onClick={() => handleCancel?.()}
                        sx={style.cancelBtn}
                        variant="contained"
                        color="action"
                        size="small"
                        disabled={!isGenerating}
                    >
                        {locale.cancel}
                    </Button>
                </>
            </Box>
        </Box>
    )
}

export default ClosedCaptionsZoneLoader
