import PlayerOptions from 'types/PlayerConfig'
import { Orientation, Video } from 'types/Video'

export const getPlayerOrientation = (options: PlayerOptions) => {
    const { width, height, aspectRatio } = options.player
    let orientation = ''

    if (aspectRatio === '1:1') {
        orientation = Orientation.square
    } else if (width > height) {
        orientation = Orientation.landscape
    } else {
        orientation = `${Orientation.portrait} ${height / width > 1.5 ? 'standard' : ''}`
    }

    return orientation
}

export function getAspectRatioAsNumber(width: number, height: number) {
    return width > height ? width / height : height / width
}

export const sortByDateCreated = (vids?: Video[]) =>
    vids?.sort((a, b) => {
        const dateA = new Date(a.dateCreated).getTime()
        const dateB = new Date(b.dateCreated).getTime()

        return dateB - dateA
    })
