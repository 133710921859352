import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, ListItem } from '@mui/material'

import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'

import { EntityRow } from 'design/molecules/EntityRow'
import { FOLDER } from 'design/atoms/Table/constants'
import { ExpandedFolder, ExpandedVideo } from 'design/pages/MyVids'
import { VideoItem, VideoItemProps } from 'design/molecules/ContentList/VideoItem'

import style from './FolderItem.style'

export interface FolderItemProps {
    folder: ExpandedFolder
    selectedEnitityGuid?: string
    level: number
    defaultExpanded?: boolean
    handleFolderClick?: (folder: ExpandedFolder) => void
    handleVideoClick?: VideoItemProps['onClick']
    disableLink?: boolean
    excludeVideo?: string | null
}

export const FolderItem = ({
    folder,
    selectedEnitityGuid,
    level,
    defaultExpanded,
    handleFolderClick,
    handleVideoClick,
    disableLink,
    excludeVideo,
}: FolderItemProps) => {
    const filteredFolderItems = folder.children.filter((el) => excludeVideo !== el.guid)
    return (
        <>
            <ListItem sx={style.root}>
                <Accordion sx={style.accordion.root} disableGutters defaultExpanded={defaultExpanded}>
                    <AccordionSummary
                        sx={style.accordion.summary.root}
                        expandIcon={<KeyboardArrowRightRoundedIcon sx={style.icon.arrow} />}
                    >
                        <Box sx={style.accordion.summary.content}>
                            <EntityRow
                                rootSx={style.entity(level)}
                                selected={selectedEnitityGuid === folder.guid}
                                leftSlot={<FolderRoundedIcon sx={style.icon.folder} />}
                                title={folder.title}
                                onClick={() => handleFolderClick?.(folder)}
                            />
                            <Divider sx={style.divider} />
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={style.accordion.details}>
                        {filteredFolderItems.map((record) =>
                            record.type === FOLDER ? (
                                <FolderItem
                                    key={record.guid}
                                    folder={record as ExpandedFolder}
                                    level={level + 1}
                                    selectedEnitityGuid={selectedEnitityGuid}
                                    handleFolderClick={handleFolderClick}
                                    handleVideoClick={handleVideoClick}
                                    disableLink={disableLink}
                                />
                            ) : (
                                <VideoItem
                                    key={record.guid}
                                    video={record as ExpandedVideo}
                                    selectedEnitityGuid={selectedEnitityGuid}
                                    level={level + 1}
                                    onClick={handleVideoClick}
                                    disableLink={disableLink}
                                />
                            ),
                        )}
                    </AccordionDetails>
                </Accordion>
            </ListItem>
        </>
    )
}
