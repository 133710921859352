import { Box, capitalize, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useCustomerQuery } from 'api/queries'
import { useAppSelector } from 'App'
import { UpgradeCTA } from 'design/atoms/UpgradeCTA'
import { ConditionalWrapper } from 'design/molecules/ConditionalWrapper'
import { selectAccount } from 'design/organisms/Account/account.slice'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { useLayout } from 'hooks/utilities/useLayout'
import { CustomerStatus } from 'types/Customer'
import UpgradeBadge from 'design/molecules/UpgradeBadge'
import { SubscriptionCtaSource } from 'design/templates/Modal'
import useUserStatuses from 'hooks/user/useUserStatuses'
import useUpgradeCallback from 'design/molecules/UpgradeBadge/useUpgradeCallback'
import usePlanUsageInfo from 'design/organisms/Account/usePlanUsageInfo'
import { useSubscriptionPlanCategory } from 'hooks/user/useSubscriptionPlanCategory'
import { getCurrentTrialPromoConfig, proPromoConfig } from 'constants/subscriptions'

import { style } from '../Sidebar.style'

interface UpgradeCtaButtonProps {
    open: boolean
}

export const UpgradeCtaButton = ({ open }: UpgradeCtaButtonProps) => {
    const { t } = useTranslation()
    const { isMobile } = useLayout()
    const upgradeCallback = useUpgradeCallback()
    const { data: customer } = useCustomerQuery()
    const { isAgency } = useAppSelector(selectAccount)
    const { isUserInactive } = useUserStatuses()
    const {
        isLoading: isAccountSubscriptionLoading,
        isFree,
        planTransitionRequired,
        data: subscription,
    } = useAccountSubscription()
    const { subscriptionPlanCategory } = useSubscriptionPlanCategory()
    const { trialDaysLeft } = usePlanUsageInfo()
    const isFreeAndNotTrial = Boolean(isFree && customer && customer.status !== CustomerStatus.trial)

    if (isAgency) return null

    if (customer?.status === CustomerStatus.trial) {
        if (!trialDaysLeft) return null

        const daysLeftText = t('days', { count: trialDaysLeft })

        return (
            <UpgradeBadge
                source={SubscriptionCtaSource.SIDEBAR}
                label={open ? t(`Free Trial: ${capitalize(daysLeftText)} Left`) : trialDaysLeft}
                buttonProps={{ sx: { borderRadius: 25 }, startIcon: null, fullWidth: true }}
                tooltipProps={{
                    slotProps: {
                        tooltip: {
                            sx: { maxWidth: 'unset', textAlign: 'center' },
                        },
                    },
                    title: (
                        <>
                            <Box fontWeight={700}>
                                {t(`You are on ${subscription?.plan.name} Free Trial with ${daysLeftText} left.`)}
                            </Box>
                            <Box>{t('Upgrade now to start scaling your growth today!')}</Box>
                        </>
                    ),
                    placement: 'right',
                }}
                config={{
                    ...getCurrentTrialPromoConfig(subscription?.plan.name ?? ''),
                    planCategory: subscriptionPlanCategory,
                }}
            />
        )
    }

    if (!isAccountSubscriptionLoading && (isFree || isUserInactive) && !planTransitionRequired) {
        const onClick = () => {
            upgradeCallback({ config: proPromoConfig })
        }

        return (
            <ConditionalWrapper
                condition={isFreeAndNotTrial}
                wrapper={(children) => (
                    <Tooltip
                        arrow
                        title={
                            <Box textAlign="center">
                                <Box fontWeight={700}>{t('Ready to scale your video strategy?')}</Box>
                                <Box>
                                    {t(
                                        'Upgrade to get more bandwidth and boost your conversions with powerful features for growth.',
                                    )}
                                </Box>
                            </Box>
                        }
                        placement="right"
                    >
                        <Box>{children}</Box>
                    </Tooltip>
                )}
            >
                <UpgradeCTA
                    onClick={onClick}
                    renderIcon
                    fullWidth
                    sx={[style.updateCta.idle, !(open || isMobile) && style.updateCta.closed]}
                />
            </ConditionalWrapper>
        )
    }

    return null
}
