import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { MetricKeys, SetMetricVisibilityPayload, MetricsLineVisibilityState } from 'design/organisms/StatsTable/types'

const initialState: MetricsLineVisibilityState = {
    [MetricKeys.plays]: true,
    [MetricKeys.playRate]: true,
    [MetricKeys.impressions]: true,
    [MetricKeys.playsUnique]: true,
    [MetricKeys.unmuteRate]: true,
    [MetricKeys.engagement]: true,
    [MetricKeys.conversionCount]: true,
    [MetricKeys.conversionRate]: true,
    [MetricKeys.revenueAverage]: true,
    [MetricKeys.pgOptInRate]: true,
    [MetricKeys.pgOptInCount]: true,
    [MetricKeys.revenue]: true,
    [MetricKeys.revenuePerViewer]: false,
    [MetricKeys.bounceRate]: true,
    [MetricKeys.unmutePlays]: false,
}

export const metricsLineSelectionSlice = createSlice({
    name: 'metricsLineSelection',
    initialState,
    reducers: {
        setMetricVisibility: (state, action: PayloadAction<SetMetricVisibilityPayload>) => {
            const { key, value } = action.payload

            state[key] = value
        },
    },
})

export const { setMetricVisibility } = metricsLineSelectionSlice.actions

export const selectMetricsLineState = (state: RootState) => state.metricsLineSelection

export const metricsLineSelection = metricsLineSelectionSlice.reducer
