import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import { CustomerStatus, CustomerSubscriptionPlanTier } from 'types/Customer'
import { useEffectTrackEvent } from 'hooks/analytics/useEffectTrackEvent'

export const useTrackEvent = (oldTier?: CustomerSubscriptionPlanTier) => {
    const { data: subscription } = useAccountSubscription()

    const newTier = oldTier === subscription?.plan.tier ? undefined : subscription?.plan.tier

    useEffectTrackEvent({
        eventName: MIXPANEL_EVENTS.TRIAL_STARTED_OPENED,
        eventData: {
            oldtier: oldTier,
            newtier: newTier,
        },
        condition: Boolean(oldTier && newTier && subscription?.status === CustomerStatus.trial),
    })
}
