import { useQuery } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_PRIMARY_METRICS_CARD } from 'api/constants'
import {
    GetPrimaryMetricsCardQuery,
    GetPrimaryMetricsCardResponse,
} from 'api/contracts/dashboard/endpoints/getPrimaryMetricsCard.contract'

export const useGetPrimaryMetricsCardQuery = (query: GetPrimaryMetricsCardQuery) =>
    useQuery<GetPrimaryMetricsCardResponse>([DASHBOARD_GET_PRIMARY_METRICS_CARD, query], () =>
        dashboardController.getPrimaryMetricsCard(query),
    )
