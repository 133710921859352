import { SxProps, Theme } from '@mui/material'

export default {
    container: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 1,
        height: 1,
    },
    backdrop: {
        background: 'rgba(0, 0, 0, 0.50)',
        zIndex: 1300,
        position: 'absolute',
        left: 0,
        top: 0,
        width: 1,
        height: 1,
    },
    wrapper: {
        position: 'fixed',
        textAlign: 'center',
        background: 'white',
        zIndex: 1301,
        left: 0,
        bottom: 0,
        right: 0,
        pt: 12,
        px: 8,
        pb: 8,
        borderTopLeftRadius: (theme) => theme.shape.borderRadius * 4,
        borderTopRightRadius: (theme) => theme.shape.borderRadius * 4,
    },
    title: {
        pb: 4,
        fontWeight: 700,
    },
    subtitle: {
        pb: 8,
    },
    button: {
        mb: 3,
    },
    close: {
        position: 'absolute',
        top: 4,
        right: 4,
    },
} satisfies Record<string, SxProps<Theme>>
