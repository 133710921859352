import BoltRoundedIcon from '@mui/icons-material/BoltRounded'
import { ButtonProps as MuiButtonProps } from '@mui/material'
import { useMemo } from 'react'

import { SubscriptionCtaSource } from 'design/templates/Modal'
import Button, { ButtonProps, SupportedBtnSize } from './Button'
import IconButton, { IconButtonProps } from './IconButton'
import { SupportedIconBtnSize } from './IconButton/IconButton.style'
import useUpgradeCallback, { SubscriptionConfig, UpgradeCallbackProps } from './useUpgradeCallback'

export type UpgradeBadgeProps = (ButtonProps | IconButtonProps) &
    UpgradeCallbackProps & {
        preventDefault?: boolean
        subscriptionConfig?: SubscriptionConfig
        source?: SubscriptionCtaSource
        buttonProps?: Omit<MuiButtonProps, 'size'>
        onClick?: (source: SubscriptionCtaSource) => void
    }

const UpgradeBadge = (props: UpgradeBadgeProps) => {
    const {
        size,
        source = SubscriptionCtaSource.LOCKED_ACCOUNT,
        preventDefault,
        onClick,
        buttonProps,
        tooltipProps,
        featureGroup,
        featureKey,
        subscriptionConfig,
        config,
    } = props

    const upgradeCallback = useUpgradeCallback(source, subscriptionConfig)

    const handleClick = useMemo(() => {
        if (preventDefault) return undefined

        return onClick ? () => onClick(source) : () => upgradeCallback({ featureGroup, featureKey, config })
    }, [source, preventDefault, onClick, upgradeCallback, featureGroup, featureKey, config])

    // Type guard to check if the label property exists
    const isLabelPresent = (props: UpgradeBadgeProps): props is ButtonProps =>
        (props as ButtonProps['label']) !== undefined

    const label = useMemo(() => (isLabelPresent(props) ? props.label : null), [props])

    const commonProps = useMemo(
        (): Omit<MuiButtonProps, 'size'> => ({
            startIcon: (
                <BoltRoundedIcon
                    sx={{ color: buttonProps?.variant === 'contained' ? 'accent.contrastText' : 'accent.main' }}
                />
            ),
            variant: 'badge',
            color: 'accent',
            onClick: (event) => {
                event.preventDefault()
                handleClick?.()
            },
            ...buttonProps,
        }),
        [size, buttonProps, handleClick],
    )

    return label ? (
        <Button buttonProps={commonProps} tooltipProps={tooltipProps} size={size as SupportedBtnSize} label={label} />
    ) : (
        <IconButton buttonProps={commonProps} tooltipProps={tooltipProps} size={size as SupportedIconBtnSize} />
    )
}

export default UpgradeBadge
