import { useMemo } from 'react'
import { useAppSelector } from 'App'
import { selectAnalyticsDateRange } from 'design/pages/Analytics/AnalyticsDatePicker/analyticsDateRange.slice'
import {
    selectIntervalMenuSwitchesState,
    selectMetricsMenuSwitchesState,
} from 'design/pages/Analytics/analyticsActions.slice'
import { analyticsIntervalMenu, analyticsMetricsMenu } from 'design/pages/Analytics/types'

const useAnalyticsData = () => {
    const dates = useAppSelector(selectAnalyticsDateRange)
    const metricsMenuSwitches = useAppSelector(selectMetricsMenuSwitchesState)
    const intervalMenuSwitches = useAppSelector(selectIntervalMenuSwitchesState)

    const selectedMetric = useMemo(
        () => analyticsMetricsMenu.find((element) => metricsMenuSwitches[element.key]),
        [metricsMenuSwitches],
    )

    const selectedInterval = useMemo(
        () => analyticsIntervalMenu.find((element) => intervalMenuSwitches[element.key]),
        [intervalMenuSwitches],
    )

    return { dates, selectedMetric, selectedInterval, metricsMenuSwitches, intervalMenuSwitches }
}

export default useAnalyticsData
