import { useMemo } from 'react'

import { SubscriptionCategory } from 'types/Customer'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import useTariffPlans from 'hooks/user/useTariffPlans'

export const useSubscriptionPlanCategory = () => {
    const { data: subscription } = useAccountSubscription()
    const tariffPlans = useTariffPlans()

    const currentPlanCategory = useMemo(() => {
        if (!subscription?.plan) return SubscriptionCategory.free
        return (
            (Object.entries(tariffPlans).find(
                ([, plan]) => plan?.id === subscription.plan.id,
            )?.[0] as SubscriptionCategory) || SubscriptionCategory.free
        )
    }, [subscription?.plan, tariffPlans])

    return {
        subscriptionPlanCategory: currentPlanCategory,
    }
}
