export default {
    title: 'Smart Pause',
    tooltip:
        'Pause your video when your viewer switches to a different tab, to make sure you always have their full attention.',
    autoplayOnReturn: {
        title: 'Autoplay when Tab Re-Engaged',
        tooltip:
            'Smart Pause will stop your video when someone goes to a different tab. This option will have it immediately play again when they return. No un-muting necessary.',
    },
}
