import { useQueryClient } from 'react-query'

import { Funnel } from 'types/Funnel'
import { smartVidConroller } from 'api/controllers'
import { SMART_VIDS_QUERY, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { SmartVideo } from 'api/contracts/smartVids/entities/smartVid'
import useMixpanel from 'hooks/analytics/useMixpanel'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'

const dataKey = 'guid'

const getUpdatedData = (indexes: string[], previousData: SmartVideo[], newData: SmartVideo[]) => {
    if (!indexes.length) {
        return [...newData, ...previousData]
    }

    return previousData.reduce((acc: SmartVideo[], funnel: SmartVideo) => {
        if (!indexes.includes(funnel[dataKey])) {
            acc.push(funnel)
        }

        return acc
    }, [])
}

type Payload = {
    funnels: SmartVideo[]
}

export const useDeleteFunnelsMutation = () => {
    const queryClient = useQueryClient()
    const { track } = useMixpanel()
    const key = SMART_VIDS_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<SmartVideo[], Payload>(
        key,
        async ({ funnels }: Payload) => {
            const funnelsText = `Smart Vid${funnels.length > 1 ? 's' : ''}`

            return mutate(Promise.all(funnels.map((funnel) => smartVidConroller.deleteSmartVid(funnel.guid))), {
                pending: `Deleting ${funnelsText}`,
                error: `Failed to delete ${funnelsText}`,
                success: `${funnelsText} deleted`,
            })
        },
        {
            onSuccess: async (...args) => {
                await queryClient.cancelQueries(key)
                const [, data] = args
                const keyIds = data.funnels.map((row) => row[dataKey])
                const previousData = queryClient.getQueryData(key) as Funnel[]
                if (previousData) {
                    const indexes = previousData.reduce((acc: string[], funnel: Funnel) => {
                        if (keyIds.includes(funnel[dataKey])) {
                            acc.push(funnel[dataKey])
                        }
                        return acc
                    }, [])
                    const updated = getUpdatedData(indexes, previousData, data.funnels)
                    queryClient.setQueryData(key, updated)
                }
                track(MIXPANEL_EVENTS.SMART_VID_DELETED)
            },
            invalidate: [VIDEOS_QUERY],
        },
    )
}
