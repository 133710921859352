import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

import { SMART_VID_DETAILS_QUERY, UPDATE_THUMBNAIL_MUTATION, VIDEOS_QUERY } from 'api/constants'

import { useInvalidateMutation, Options } from 'api/hooks'
import { Video, ThumbnailType } from 'types/Video'
import { useActualVideoGuid } from 'hooks/video/useActualVideoGuid'

type Payload = {
    video: Video
    file: File | Blob
    thumbnailType: ThumbnailType
    guid?: string
    from?: number
    to?: number
}
export const useUpdateThumbnailMutation = (options?: Options<Video, Payload, string>) => {
    const vidId = useActualVideoGuid()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Video, Payload, string>(
        UPDATE_THUMBNAIL_MUTATION,
        async ({ file, thumbnailType, guid, from, to }: Payload) => {
            if (!vidId) {
                return
            }
            if (guid) {
                return mutate(
                    settingsAPI.updateThumbnail(vidId, file, thumbnailType, guid, from, to),
                    apiLocale.settings.thumbnail.update,
                )
            } else {
                return mutate(
                    settingsAPI.updateThumbnail(vidId, file, thumbnailType, guid, from, to),
                    apiLocale.settings.thumbnail.update,
                )
            }
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY, SMART_VID_DETAILS_QUERY],
        },
    )
}
