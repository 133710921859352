import { useQuery, UseQueryOptions } from 'react-query'

import { PermissionsObject, PermissionMap } from 'types/Customer'
import { userAPI } from 'api/controllers'
import { PERMISSIONS_QUERY } from 'api/constants'
import { useCustomerQuery } from './useCustomerQuery'

export const usePermissionsQuery = (options?: UseQueryOptions<PermissionMap | null, unknown, PermissionMap | null>) => {
    const { data: customer } = useCustomerQuery()

    return useQuery<PermissionMap | null>(
        PERMISSIONS_QUERY,
        async () => {
            const permissions = (await userAPI.getPermissions()) as PermissionsObject

            if (!Object.keys(permissions).length) {
                return customer?.isSubUser ? new Map() : null
            }

            return new Map(
                Object.values(permissions).map((p) => {
                    return [p.name, p.title]
                }),
            )
        },
        options,
    )
}
