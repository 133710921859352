export default {
    creditCard: {
        number: {
            label: 'Card number',
            placeholder: '1234 5678 9012 3456',
        },
        cvv: {
            label: 'CVV',
            placeholder: '•••',
        },
        expiry: {
            month: {
                label: 'Month',
                placeholder: 'MM',
            },
            year: {
                label: 'Year',
                placeholder: 'YYYY',
            },
        },
    },
    user: {
        email: {
            label: 'Email',
            placeholder: 'Type your email',
        },
        name: {
            label: 'Name',
            placeholder: 'Type your name',
        },
        firstName: {
            label: 'First name',
            placeholder: 'Type your name',
        },
        lastName: {
            label: 'Last name',
            placeholder: 'Type your last name',
        },
        password: {
            label: 'Password',
            placeholder: 'Type your password',
        },
        newPassword: {
            label: 'New password',
            placeholder: 'Type your new password',
        },
        confirmPassword: {
            label: 'Confirm password',
            placeholder: 'Type your password confirmation',
        },
    },
    address: {
        zip: {
            label: 'ZIP Code',
            placeholder: '12345',
        },
    },
    common: {
        placeholder: 'Type here...',
    },
}
