import { Stack, Typography, StackProps, SxProps, Theme } from '@mui/material'
import { ReactNode, Ref, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { ErrorBoundary } from 'react-error-boundary'
import { Fallback } from 'design/atoms/Fallback'
import { ApiError } from 'design/atoms/ApiError'
import { getSx } from 'styles/theme/utils'
import { BannerController } from 'design/organisms/BannerController'
import { ModalController } from 'design/templates/Modal'

import style from './PageOld.style'

interface PageOldProps extends StackProps {
    containerSx?: SxProps<Theme>
    title?: string
    children: ReactNode
    header?: ReactNode
    head?: ReactNode
    stackRef?: Ref<HTMLDivElement>
    isError?: boolean
}

export const PageOld = ({
    title,
    header,
    sx,
    children,
    head,
    stackRef,
    containerSx,
    isError,
    ...stackProps
}: PageOldProps) => {
    const renderHeader = useCallback(() => {
        if (header) {
            return header
        }

        if (title) {
            return (
                <Typography variant="h4" component="h1" sx={getSx(style.title, style.offset)}>
                    {title}
                </Typography>
            )
        }

        return null
    }, [title, header])

    return (
        <ErrorBoundary fallbackRender={() => <Fallback />}>
            <Helmet>{head ? head : <title>{title ? `${title} | Vidalytics` : `Vidalytics`}</title>}</Helmet>

            <ModalController />

            <Stack
                ref={stackRef}
                gap={5}
                justifyContent="space-between"
                sx={getSx(style.container, containerSx)}
                {...stackProps}
            >
                <BannerController />

                <ErrorBoundary fallbackRender={() => <Fallback />}>{renderHeader()}</ErrorBoundary>

                {isError ? (
                    <ApiError />
                ) : (
                    <Stack component="main" sx={sx ? sx : getSx(style.offset, style.main)} flexDirection="column">
                        <ErrorBoundary fallbackRender={() => <Fallback />}>{children}</ErrorBoundary>
                    </Stack>
                )}
            </Stack>
        </ErrorBoundary>
    )
}

export default PageOld
