import { SxProps, Theme } from '@mui/material'
import { pxToRem } from 'styles/theme/typography'
import { getPropByTheme } from 'styles/theme/utils'

const imgPlaceholder: SxProps<Theme> = {
    position: 'absolute',
    top: 32,
    left: 12,
    zIndex: 1,
    width: 45,
    height: 26,
    borderRadius: pxToRem(6),
    cursor: 'pointer',
    backgroundColor: (theme) => getPropByTheme('#1B24321F', '#FFF', theme.palette.mode),
}

const imgPlaceholderDisabled: SxProps = {
    cursor: 'initial',
}

const imgMain: SxProps = {
    width: 45,
    height: 26,
    minWidth: 45,
    objectFit: 'cover',
    borderRadius: pxToRem(6),
}

const textLabel: SxProps = {
    display: 'flex',
    gap: 1.5,
    alignItems: 'center',
}

const textLabelLocked: SxProps = {
    color: 'text.disabled',
}

export default {
    img: {
        main: imgMain,
        placeholder: imgPlaceholder,
        placeholderDisabled: imgPlaceholderDisabled,
    },
    textLabel,
    textLabelLocked,
}
