import { Typography } from '@mui/material'
import React from 'react'

// A broad (but not all-encompassing) regex for matching emoji.
// For robust coverage, consider libraries like "emoji-regex".
const EMOJI_REGEX = /[\u{1F300}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\uD83D[\uDC00-\uDFFF}]+/u

interface FixedEmojisInTextProps {
    text: string
}

const FixedEmojisInText = ({ text }: FixedEmojisInTextProps) => {
    // Split the string on emoji, *capturing* the split tokens
    // This gives us an array with text and emoji interleaved.
    // Example: "Hello 🌎" => ["Hello ", "🌎"]
    // We use 'g' with capturing group => split retains the emoji in the array.
    const parts = text.split(new RegExp(`(${EMOJI_REGEX.source})`, 'gu'))

    if (!parts.length) return <>{text}</>

    return (
        <>
            {parts.map((part, i) => {
                // If 'part' matches the EMOJI_REGEX, it's an emoji
                if (EMOJI_REGEX.test(part)) {
                    return (
                        <Typography key={i} variant="inherit" component="span" color="black">
                            {part}
                        </Typography>
                    )
                }
                // Otherwise, it's normal text
                return <React.Fragment key={i}>{part}</React.Fragment>
            })}
        </>
    )
}

export default FixedEmojisInText
