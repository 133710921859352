import { DomainWhitelistForm } from './types'

export const DOMAIN = 'domain'
export const DATE_CREATED = 'dateCreated'
export const GUID = 'guid'

export const INITIAL_VALUES: DomainWhitelistForm = {
    [DOMAIN]: '',
    [DATE_CREATED]: null,
    [GUID]: null,
}
