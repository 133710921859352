import { SxProps } from '@mui/material'

const root: SxProps = {
    p: 0,
}

const entity = (level: number): SxProps => ({
    width: '100%',
    pl: `calc(12px + ${level * 36}px)`,
})

const link: SxProps = {
    width: '100%',
}

const divider: SxProps = {
    ml: 3,
}

export default {
    root,
    link,
    divider,
    entity,
}
