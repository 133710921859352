import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import UpgradeBadge from 'design/molecules/UpgradeBadge'
import { SubscriptionFeatureKey } from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'

import style from './PromoBadge.style'

type PromoBadgeProps = {
    property: string
    highlightFeature?: SubscriptionFeatureKey
}

const PromoBadge = ({ property, highlightFeature }: PromoBadgeProps) => {
    const { t } = useTranslation()

    return (
        <UpgradeBadge
            subscriptionConfig={{ highlightFeature }}
            buttonProps={{ sx: style.badge }}
            tooltipProps={{
                slotProps: {
                    tooltip: {
                        sx: { maxWidth: 300, textAlign: 'center' },
                    },
                },
                title: (
                    <>
                        <Box fontWeight={700}>{t('Ready to scale your video strategy?')}</Box>
                        <Box>
                            {t(
                                `Upgrade to get more ${property} and boost your conversions with powerful features for growth.`,
                            )}
                        </Box>
                    </>
                ),
            }}
            size="xSmall"
        />
    )
}

export default PromoBadge
