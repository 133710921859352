import { List, ListItem, Skeleton, Stack, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Link } from 'design/atoms/Link'
import { FOLDER } from 'design/atoms/Table/constants'
import { ExpandedFolder, ExpandedVideo, ExpandedVideoOrFolder, useVideosWithFolders } from 'design/pages/MyVids'
import { sortByDateCreated } from 'utils/video'
import route from 'constants/routes'
import { MAIN, ROOT } from 'constants/api.constants'

import style from './ContentList.style'
import { FolderItem, FolderItemProps } from './FolderItem'
import { VideoItem } from './VideoItem'
import locale from './ContentList.locale'

interface ContentListProps
    extends Pick<FolderItemProps, 'handleFolderClick' | 'selectedEnitityGuid' | 'handleVideoClick'> {
    searchText: string
    compareMode?: boolean
    disableLink?: boolean
    excludeVideo?: string | null
}

const emptyData = Array.from({ length: 7 }, (_, i) => i)

export const ContentList = ({
    searchText,
    selectedEnitityGuid,
    handleFolderClick,
    handleVideoClick,
    disableLink,
    excludeVideo,
}: ContentListProps) => {
    const { videoGuid } = useParams()
    const {
        videos: { data: videos },
        videosWithFolders,
        isLoading,
    } = useVideosWithFolders(
        { filter: searchText, sort: sortByDateCreated },
        { filteredByFunnels: true, processedVideosOnly: true },
    )

    const filteredRootVideosWithFolders = useMemo(
        () =>
            videosWithFolders.filter((r) =>
                r.type === FOLDER
                    ? true
                    : r.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) && excludeVideo !== r.guid,
            ),
        [videosWithFolders, searchText, videoGuid],
    )
    const recordsUnderRoot = useMemo(
        () =>
            ('main'.includes(searchText.toLowerCase())
                ? [
                      {
                          guid: ROOT,
                          title: MAIN,
                          type: FOLDER,
                          parentFolderGuid: null,
                          children: filteredRootVideosWithFolders,
                      },
                  ]
                : filteredRootVideosWithFolders) as ExpandedVideoOrFolder[],
        [filteredRootVideosWithFolders],
    )

    if (!isLoading && !videos?.length) {
        return (
            <Stack sx={style.emptyContainer}>
                <Typography sx={style.emptyTitle}>{locale.noVids}</Typography>
                <Link to={route.upload}>{locale.uploadFirst}</Link>
            </Stack>
        )
    }

    if (!isLoading && searchText && !recordsUnderRoot.length) {
        return (
            <Stack sx={style.emptyContainer}>
                <Typography sx={style.emptyTitle}>{locale.noVids}</Typography>
                <Typography>{locale.notFoundNotification}</Typography>
            </Stack>
        )
    }

    return (
        <List sx={style.list.root}>
            {isLoading
                ? emptyData.map((i) => (
                      <ListItem key={`content-list-${i}`} sx={{ p: 0, mb: 3, mt: i ? 0 : 3 }}>
                          <Skeleton
                              sx={{ borderRadius: 2, mx: 4 }}
                              width="100%"
                              height={40}
                              animation="wave"
                              variant="rectangular"
                          />
                      </ListItem>
                  ))
                : recordsUnderRoot.map((record) =>
                      record.type === FOLDER ? (
                          <FolderItem
                              key={record.guid}
                              folder={record as ExpandedFolder}
                              level={0}
                              defaultExpanded={record.guid === ROOT}
                              selectedEnitityGuid={selectedEnitityGuid}
                              handleFolderClick={handleFolderClick}
                              handleVideoClick={handleVideoClick}
                              disableLink={disableLink}
                              excludeVideo={excludeVideo}
                          />
                      ) : (
                          <VideoItem
                              key={record.guid}
                              video={record as ExpandedVideo}
                              level={0}
                              selectedEnitityGuid={selectedEnitityGuid}
                              onClick={handleVideoClick}
                              disableLink={disableLink}
                          />
                      ),
                  )}
        </List>
    )
}
