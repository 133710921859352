export default {
    common: {
        required: 'Required.',
    },
    time: {
        invalidFormat: "Please enter the time as '00:00' or '00:00:00'",
        smallerThanVideoLength: (target: string, duration = '00:00') =>
            `Time ${target} must be smaller than total video duration. The total duration is ${duration}`,
        from: {
            smallerThanTo: "Time 'from' must be smaller than 'to'",
        },
    },
    length: {
        max: (length: number) => `Permitted max length is ${length}`,
    },
    url: {
        invalid: 'Please, enter correct url.',
    },
}
