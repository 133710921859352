import { useEffect, useRef } from 'react'

import { useAppDispatch, useAppSelector } from 'App'
import useUpgradeCallback from 'design/molecules/UpgradeBadge/useUpgradeCallback'
import { useAgencyAccess } from 'design/pages/AgencyAccount/AgencyAccount.hooks'
import { FeatureLockedOpenModalPayload, openAgencyLockedFeaturesModal } from 'design/templates/Modal'
import { closeModal, selectModalState } from 'design/templates/Modal/ModalTypes/modal.slice'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { useCheckAccess } from 'utils'
import { FeatureGroup } from 'constants/keys/featuresAccess.constants'

type UseLockedPageProps = {
    featureGroup: FeatureGroup
    config: FeatureLockedOpenModalPayload
    openOldLockedModal: () => void
}

export const useLockPage = ({ featureGroup, openOldLockedModal, config }: UseLockedPageProps) => {
    const { lockedPagesFF } = useFeatureFlags()
    const lockedModalRef = useRef<HTMLDivElement>(null)
    const subscriptionModalState = useAppSelector(selectModalState)

    const isLocked = useCheckAccess(featureGroup)()
    const { isLoading: isAccountSubscriptionLoading, planTransitionRequired } = useAccountSubscription()
    const { agencyAccessLocked } = useAgencyAccess()

    const dispatch = useAppDispatch()
    const upgradeCallback = useUpgradeCallback()

    // DEPRECATED, to be removed after migration to new modal
    useEffect(() => {
        if (lockedPagesFF) return
        if (isAccountSubscriptionLoading) return

        if (agencyAccessLocked) {
            dispatch(openAgencyLockedFeaturesModal({ containerRef: lockedModalRef.current }))
            return
        }

        if (isLocked && !planTransitionRequired) {
            openOldLockedModal()
            return
        }

        // if user updated subscription
        if (!planTransitionRequired) {
            dispatch(closeModal())
        }
    }, [isAccountSubscriptionLoading, agencyAccessLocked, isLocked, planTransitionRequired, config, lockedPagesFF])

    useEffect(() => {
        if (!lockedPagesFF) return
        if (isAccountSubscriptionLoading || subscriptionModalState.type) return

        if (agencyAccessLocked) {
            dispatch(openAgencyLockedFeaturesModal({ containerRef: lockedModalRef.current }))
            return
        }

        if (isLocked && !planTransitionRequired) {
            upgradeCallback({ config: { ...config, containerRef: lockedModalRef.current } })
            return
        }

        // if user updated subscription
        if (!planTransitionRequired) {
            dispatch(closeModal())
        }
    }, [
        isAccountSubscriptionLoading,
        agencyAccessLocked,
        isLocked,
        planTransitionRequired,
        lockedPagesFF,
        config,
        subscriptionModalState,
    ])

    useEffect(() => {
        return () => {
            dispatch(closeModal())
        }
    }, [])

    return {
        containerRef: lockedModalRef,
        isLocked,
        lockedPagesFF,
    }
}
