import { http } from 'api/utils/http.utils'
import get from 'lodash/get'
import { SmartVidTagsConfig, TagConfig } from 'types/VideoTag'
import { CallToActionConfig, ThumbnailType, ThumbnailVideoType, Video, VideoDetails, VideoGuid } from 'types/Video'
import { VideoPlayGateConfig } from 'types/VideoPlayGate'

export const settingsAPI = {
    loadSettings: (guid: string, fromGuid: string) =>
        http.post<VideoDetails>(`video/${guid}/load-settings/${fromGuid}`),
    discardSettings: (guid: string) => http.post<VideoDetails>(`/video/${guid}/discard-settings`),
    publishSettings: (guid: string) => http.post<VideoDetails>(`/video/${guid}/publish`),

    createCTA: (videoGuid: string, callToAction: CallToActionConfig) =>
        http.post<{ callToAction: CallToActionConfig }>(`/video/${videoGuid}/call-to-action`, { callToAction }),
    deleteCTA: (callToAction: CallToActionConfig) => http.delete<boolean>(`/video/call-to-action/${callToAction.guid}`),
    updateCTA: (callToAction: CallToActionConfig) =>
        http.post<{ callToAction: CallToActionConfig }>(`/video/call-to-action/${callToAction.guid}`, { callToAction }),
    getSmartVidTags: (videoGuid: string) => http.get(`/video/${videoGuid}/tags`),
    updateSmartVidTags: (videoGuid: string, config: SmartVidTagsConfig) =>
        http.put<SmartVidTagsConfig>(`/video/${videoGuid}/tags`, config),
    createTag: (videoGuid: string, tag: TagConfig) => http.post<{ tag: TagConfig }>(`/video/${videoGuid}/tag`, { tag }),
    deleteTag: (tag: TagConfig) => http.delete<boolean>(`/video/tag/${tag.guid}`),
    updateTag: (tag: TagConfig) => http.post<{ tag: TagConfig }>(`/video/tag/${tag.guid}`, { tag }),
    createPlayGate: (videoGuid: string, playGate: VideoPlayGateConfig) =>
        http.post<{ playGate: VideoPlayGateConfig }>(`/video/${videoGuid}/play-gate`, { playGate }),
    deletePlayGate: (videoPlayGate: VideoPlayGateConfig) =>
        http.delete<boolean>(`/video/play-gate/${videoPlayGate.guid}`),
    updatePlayGate: (playGate: VideoPlayGateConfig) =>
        http.post<{ playGate: VideoPlayGateConfig }>(`/video/play-gate/${playGate.guid}`, { playGate }),
    updateVideoThumbnail: (videoGuid: VideoGuid, from: number, to: number, type?: ThumbnailVideoType) => {
        return http.post('/video/preview', {
            guid: videoGuid,
            from,
            to,
            type,
        })
    },
    updateThumbnail: (
        videoGuid: VideoGuid,
        file: Blob | File,
        thumbnailType: ThumbnailType,
        guid?: string,
        from?: number,
        to?: number,
    ) => {
        const input = new FormData()
        const name = get(file, 'name') as string

        if (name) {
            //eslint-disable-next-line
            const fileName = name.replace(/[&\/\\#,+()$~%'":*?<>{} ]/g, '')
            input.append('fileName', file, fileName)
        } else {
            input.append('fileName', file)
        }

        input.append('timeFrom', String(from || 0))
        input.append('timeTo', String(to || 0))

        const config = { headers: { 'Content-Type': 'multipart/form-data' } }

        let url: string
        switch (thumbnailType) {
            case ThumbnailType.mobileThumbnail:
                url = `/video/${videoGuid}/thumbnail/mobile`
                break
            case ThumbnailType.customPauseScreen:
                url = guid
                    ? `/video/${videoGuid}/thumbnail/custom-pause-screen/${guid}`
                    : `/video/${videoGuid}/thumbnail/custom-pause-screen`
                break
            default:
                url = `/video/${videoGuid}/thumbnail`
        }

        return http.post<FormData>(url, input, config)
    },
    updateThumbnailExitTime: (videoGuid: VideoGuid, timeFrom: number, timeTo: number, guid?: string) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }

        const url: string = guid
            ? `/video/${videoGuid}/thumbnail/custom-pause-screen/${guid}`
            : `/video/${videoGuid}/thumbnail/custom-pause-screen`

        return http.post(
            url,
            {
                timeFrom: timeFrom,
                timeTo: timeTo,
            },
            config,
        )
    },
    deleteThumbnail: (videoGuid: VideoGuid, thumbnailType: ThumbnailType, guid?: string) => {
        let url: string

        switch (thumbnailType) {
            case ThumbnailType.mobileThumbnail:
                url = `/video/${videoGuid}/thumbnail/mobile`
                break
            case ThumbnailType.customPauseScreen:
                url = guid
                    ? `/video/${videoGuid}/thumbnail/custom-pause-screen/${guid}`
                    : `/video/${videoGuid}/thumbnail/custom-pause-screen`
                break
            default:
                url = `/video/${videoGuid}/thumbnail`
        }

        return http.delete(url)
    },
    updateClosedCaptions: (video: Video, file: string | Blob) => {
        const input = new FormData()
        input.append('fileName', file)
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }

        return http.post<FormData>(`/video/${video.guid}/closed-captions`, input, config)
    },
    postClosedCaptions: (video: Video, file: string | Blob) => {
        const input = new FormData()
        input.append('fileName', file)
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }

        return http.post<FormData>(`/video/${video.guid}/closed-captions/edit`, input, config)
    },
    editClosedCaptions: (video: Video, file: string | Blob) => {
        const input = new FormData()
        input.append('fileName', file)
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }

        return http.put<FormData>(`/video/${video.guid}/closed-captions/edit`, input, config)
    },
    generateClosedCaptions: (guid: string) => {
        return http.post(`/video/generate-closed-captions${guid ? `/${guid}` : ''}`)
    },
    generateClosedCaptionsCancel: (guid: string) => {
        return http.delete(`/video/generate-closed-captions${guid ? `/${guid}` : ''}`)
    },
    readClosedCaptionsText: (url: string) => {
        return http.get(url, { headers: { noHeaders: true }, withCredentials: false })
    },
    readClosedCaptionsDraft: (guid: string) => {
        return http.get(`/video/${guid}/closed-captions/draft`)
    },
    deleteClosedCaptions: (video: Video) => {
        return http.delete(`/video/${video.guid}/closed-captions`)
    },
    removeClosedCaptions: (video: Video) => {
        return http.delete(`/video/${video.guid}/closed-captions/edit`)
    },
}
