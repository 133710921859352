import _get from 'lodash/get'

import { FeatureKey } from 'constants/featuresAccess.constants'
import { FeatureGroup } from 'constants/keys/featuresAccess.constants'
import { VidSettingsLockedModalConfigs } from './vidSettingsLockedModalConfigs'

export * from './accountSettingsLockedModalConfigs'
export * from './globalPageLockedModalConfigs'
export * from './promo'
export * from './vidSettingsLockedModalConfigs'

export const getLockedModalConfig = (featureGroup?: FeatureGroup, featureKey?: FeatureKey) =>
    _get(LockedModalConfigs, `${featureGroup}.${featureKey}`)

export const LockedModalConfigs = {
    [FeatureGroup.VidSettings]: VidSettingsLockedModalConfigs,
}
