import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Alert, AlertTitle, Button, IconButton, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import BoltRoundedIcon from '@mui/icons-material/BoltRounded'
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded'

import { CustomerStatus } from 'types/Customer'
import { useCustomerQuery } from 'api/queries'
import usePlanUsageInfo from 'design/organisms/Account/usePlanUsageInfo'
import useUpgradeCallback from 'design/molecules/UpgradeBadge/useUpgradeCallback'
import { proPromoConfig } from 'constants/subscriptions'

const ProgressSuccessBanner = () => {
    const [isOpen, setIsOpen] = useState(true)
    const { t } = useTranslation()
    const { isFree, usage } = usePlanUsageInfo()
    const { data: customer } = useCustomerQuery()

    const isTrialAvailable = isFree && customer && customer.status !== CustomerStatus.trial

    const upgradeCallback = useUpgradeCallback()

    if (!isOpen) return null

    const spaceUsed = usage?.split(' / ')?.[0] || ''

    if (!spaceUsed) return null

    return (
        <Alert
            severity="success"
            variant="standard"
            icon={<SpeedRoundedIcon />}
            isBanner
            action={
                <Stack direction="row" gap={2} alignItems="center" pl={2}>
                    <Button
                        variant="contained"
                        color="accent"
                        startIcon={<BoltRoundedIcon />}
                        size="small"
                        onClick={() =>
                            isTrialAvailable ? upgradeCallback({ config: proPromoConfig }) : upgradeCallback()
                        }
                    >
                        {isTrialAvailable ? t('Start Your Free Trial') : t('Upgrade Now')}
                    </Button>
                    <IconButton sx={{ color: 'inherit' }} onClick={() => setIsOpen(false)}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Stack>
            }
            sx={{
                justifyContent: 'center',
                gridTemplateColumns: 'unset',
                py: 3,
            }}
        >
            <AlertTitle>
                {t('Congrats! You’ve already used {{spaceUsed}} this month!', {
                    spaceUsed,
                })}
            </AlertTitle>
            {t(
                'Great job — your videos are hitting the mark with viewers! Just a heads-up: exceeding your bandwidth allowance incurs extra charges per GB. To continue your success without worrying about overage fees, consider upgrading your plan now.',
            )}
        </Alert>
    )
}

export default ProgressSuccessBanner
