import { useQuery } from 'react-query'

import { ApiHandle, PlanProrate } from 'types/Customer'
import { userAPI } from 'api/controllers'
import { PLAN_PRORATE_QUERY } from 'api/constants'
import { useCustomerSubscriptionQuery } from './useCustomerSubscriptionQuery'

export const usePlanProrateQuery = (apiHandle?: ApiHandle, pricePoint?: string) => {
    const { data: subsription } = useCustomerSubscriptionQuery()

    return useQuery<PlanProrate>(
        [PLAN_PRORATE_QUERY, apiHandle],
        () => userAPI.getPlanProrate({ planHandle: apiHandle, pricePoint }),
        {
            enabled: subsription ? apiHandle && subsription.plan.apiHandle !== apiHandle : false,
        },
    )
}
