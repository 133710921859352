import { Box } from '@mui/material'

import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import AccordionMenu from 'design/organisms/VidSettingsSidebar/AccordionMenu/AccordionMenu'

import useMarketingSettingsMenu from './useMarketingSettingsMenu'

const MarketingSettings = () => {
    const menu = useMarketingSettingsMenu()

    return (
        <Box className="MarketingSettings">
            <AccordionMenu menu={menu} />
        </Box>
    )
}

export default withErrorBoundary(MarketingSettings)
