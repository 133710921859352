import {
    Box,
    FormControlLabel,
    FormControlLabelProps,
    Switch as MuiSwitch,
    SwitchProps as MuiSwitchProps,
} from '@mui/material'
import { ReactNode } from 'react'

import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { getSx } from 'styles/theme/utils'
import UpgradeBadge from 'design/molecules/UpgradeBadge'
import useUpgradeCallback from 'design/molecules/UpgradeBadge/useUpgradeCallback'
import { SubscriptionCtaSource } from 'design/templates/Modal'
import { useCheckAccess } from 'utils'
import { FeatureKey } from 'constants/featuresAccess.constants'
import { FeatureGroup } from 'constants/keys/featuresAccess.constants'
import style from './Switch.styles'

export enum SwitchTooltipPlacement {
    after = 'after',
    inside = 'inside',
}

export interface SwitchProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
    /** Switch value, on or off */
    label?: FormControlLabelProps['label']
    /** Switch value, on or off */
    value?: boolean
    /** Switch label position controls */
    labelPlacement?: FormControlLabelProps['labelPlacement']
    /** Switch help info text */
    helpInfo?: ReactNode
    betaLabel?: ReactNode
    /** Switch disable toggle */
    disabled?: boolean
    /** Switch feature group to check is locked */
    featureGroup?: FeatureGroup
    /** Switch feature key to check is locked */
    featureKey?: FeatureKey
    /** Switch tooltip position controls */
    tooltipPlacement?: SwitchTooltipPlacement
    /** Switch additional className */
    labelClassName?: string
    /** Switch additional props */
    switchProps?: MuiSwitchProps
    upgradeSource?: SubscriptionCtaSource
}
/**
 * Switch component
 */
export const Switch = ({
    value,
    label,
    betaLabel,
    labelPlacement = 'start',
    helpInfo,
    disabled,
    featureKey,
    featureGroup,
    tooltipPlacement = SwitchTooltipPlacement.after,
    labelClassName,
    switchProps,
    upgradeSource,
    sx,
    onChange,
    ...props
}: SwitchProps) => {
    const upgradeCallback = useUpgradeCallback(upgradeSource)
    const isLocked = Boolean(featureGroup && featureKey && useCheckAccess(featureGroup)(featureKey))

    const renderLabel = () => (
        <Box sx={style.wrapper}>
            {tooltipPlacement === SwitchTooltipPlacement.inside ? (
                <Box sx={style.wrapper}>
                    <Box sx={[style.wrapper, disabled ? style.disabled : {}]}> {label}</Box>
                    {helpInfo && <HelpTooltip title={helpInfo} />}
                    {betaLabel}
                </Box>
            ) : (
                label
            )}
        </Box>
    )

    const renderHelpTooltip = () =>
        helpInfo &&
        tooltipPlacement === SwitchTooltipPlacement.after &&
        (disabled ? (
            <Box sx={style.disabled}>{<HelpTooltip title={helpInfo} />}</Box>
        ) : (
            <HelpTooltip title={helpInfo} />
        ))

    return (
        <>
            <FormControlLabel
                className={labelClassName}
                checked={value}
                sx={getSx(style.formControlLabelRoot, sx)}
                control={
                    isLocked ? (
                        <UpgradeBadge preventDefault tooltipProps={{ placement: 'left' }} />
                    ) : (
                        <MuiSwitch
                            sx={style.switchRoot(switchProps?.color, switchProps?.size)}
                            {...switchProps}
                            disableRipple
                        />
                    )
                }
                label={renderLabel()}
                disabled={disabled || isLocked}
                labelPlacement={labelPlacement}
                onChange={onChange}
                {...props}
                onClick={(event) => {
                    if (isLocked) {
                        return upgradeCallback({ featureGroup, featureKey })
                    }

                    props.onClick?.(event)
                }}
            />
            {renderHelpTooltip()}
        </>
    )
}

// Workaround for Storybook bug. Do not delete.
Switch.displayName = 'Switch'
