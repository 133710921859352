import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { noop as _noop } from 'lodash'

import { HlsPlayer } from 'types/Player'
import { useLayout } from 'hooks/utilities/useLayout'
import { Modal } from 'design/templates/Modal'
import useEmbedCode from 'hooks/video/useEmbedCode'
import useMixpanel, { MIXPANEL_EVENTS } from 'hooks/analytics/useMixpanel'
import { useCustomerQuery } from 'api/queries'
import { vidalyticsPlayerInitialized } from 'utils/embed'
import { ModalDefaultProps } from 'design/templates/Modal/ModalTypes/types'
import { EMBED_ID, EMBED_CONDITION_ID, CONVERSION_ID } from 'design/organisms/Welcome/Welcome.constants'
import { CUSTOMER_ID } from 'constants/video.constants'

import styles from './WelcomeModal.style'

export const WelcomeModal = ({ onClose }: ModalDefaultProps) => {
    const { t } = useTranslation()
    const { isTablet } = useLayout()
    const ref = useRef<HTMLDivElement>(null)
    const refWrap = useRef<HTMLDivElement>(null)
    useEmbedCode(CUSTOMER_ID, EMBED_ID)
    const [player, setPlayer] = useState<HlsPlayer>()
    const { data: customer } = useCustomerQuery()
    const { track } = useMixpanel()

    const fullEmbedId = `vidalytics_embed_${EMBED_ID}`
    const conversionStorageKey = `vidalytics_conversion_${customer?.guid}`

    const initConversionScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = `https://fast.vidalytics.com/js/conversion.min.js?cid=${CONVERSION_ID}`
        const scriptLoadCallback = () => {
            // @ts-ignore
            const VidalyticsC = window['VidalyticsC']
            const l = new VidalyticsC['ConversionTracking']({ url: 'https://stats.vidalytics.com' })
            l.track(CUSTOMER_ID, { conversionId: CONVERSION_ID })
            localStorage.setItem(conversionStorageKey, 'true')
        }

        script.onload = scriptLoadCallback
        document.head.appendChild(script)
    }

    useEffect(() => {
        if (!localStorage.getItem(conversionStorageKey) && !customer?.loginCount) {
            setTimeout(() => {
                initConversionScript()
            }, 1000)
        }
    }, [])

    useEffect(() => {
        const getPlayer = async (id: string) => {
            const res = (await vidalyticsPlayerInitialized(`vidalytics_embed_${id}`)) as HlsPlayer
            if (res) setPlayer(res)
        }

        getPlayer(EMBED_ID)
        getPlayer(EMBED_CONDITION_ID)
    }, [])

    useEffect(() => {
        if (!player) return

        player.on('play', () => {
            track(MIXPANEL_EVENTS.WELCOME_VIDEO_STARTED)
        })

        player.on('played', () => {
            track(MIXPANEL_EVENTS.WELCOME_VIDEO_WATCHED)
        })
    }, [player])

    const updateHeight = () => {
        if (ref.current && refWrap.current) {
            const heightWrap = refWrap.current.offsetHeight
            const height = ref.current.offsetHeight
            const width = ref.current.offsetWidth

            if (height > heightWrap) {
                ref.current.style.width = `${width * (heightWrap / height)}px`
            }
        }
    }

    useEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight)
        return () => window.removeEventListener('resize', updateHeight)
    }, [player])

    return (
        <Modal onClose={_noop} stackProps={{ sx: styles.root }} width="lg" open>
            <Modal.Body>
                <Button onClick={onClose} sx={styles.close} variant="contained" color="tertiary" size="iconMedium">
                    <CloseIcon fontSize="small" />
                </Button>
                <Box ref={refWrap} sx={styles.videoWrap}>
                    <Box ref={ref} sx={styles.video}>
                        <Box id={fullEmbedId} />
                    </Box>
                </Box>
                <Stack data-testid="welcomeCta">
                    <Stack sx={styles.text} direction="column" textAlign="center">
                        <Typography
                            variant={isTablet ? 'h7' : 'h6'}
                            fontWeight={700}
                            lineHeight="24px"
                            data-testid="welcomeCtaText"
                        >
                            {t(
                                "It's time to boost your leads, conversions and sales with your new Vidalytics account.",
                            )}
                        </Typography>
                    </Stack>
                    <Stack sx={styles.controls} direction="row" justifyContent="center">
                        <Button
                            onClick={onClose}
                            sx={styles.btn}
                            variant="contained"
                            size={isTablet ? 'xLarge' : 'x2Large'}
                        >
                            {t('Upload Your Video')}
                        </Button>
                    </Stack>
                </Stack>
            </Modal.Body>
        </Modal>
    )
}

export default WelcomeModal
