import { SxProps, Theme } from '@mui/material'

export default {
    root: (theme) => ({
        justifyContent: 'flex-start',
        flex: 1,
        alignSelf: 'stretch',
        position: 'relative',
        borderRadius: 4,
        mt: 7,
        transition: theme.transitions.create(['box-shadow']),

        '&:hover': {
            boxShadow: theme.shadows[5],
        },
    }),

    recommendedRoot: {
        pt: 7,
        mt: 0,
    },

    container: {
        py: 6,
        px: 5,
        justifyContent: 'flex-start',
        gap: 6,
        flex: 1,
        alignSelf: 'stretch',
        borderRadius: 4,
        backgroundColor: 'background.paperElevation1',
        position: 'relative',
        zIndex: 1,
    },

    currentPlan: {
        border: (theme) => `4px solid ${theme.palette.tertiary.main}`,
        py: 5,
        px: 4,
    },

    recommended: {
        py: 5,
        px: 4,
        border: (theme) => `4px solid ${theme.palette.accent.main}`,
    },

    highlightedText: (theme) => ({
        position: 'absolute',
        zIndex: 0,
        textAlign: 'center',
        color: 'accent.contrastText',
        fontWeight: 500,
        backgroundColor: 'accent.main',
        textTransform: 'uppercase',
        width: 1,
        top: 0,
        borderRadius: `${theme.spacing(4)} ${theme.spacing(4)} 0px 0px`,
        padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(7)} ${theme.spacing(3)}`,
    }),

    currentTrial: { textTransform: 'capitalize' },
} satisfies Record<string, SxProps<Theme>>
