import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { SvgIconProps } from '@mui/material'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import { FC } from 'react'

import { getSx } from 'styles/theme/utils'

import style from './HelpTooltip.style'

type HelpTooltipProps = {
    iconColor?: SvgIconProps['color']
    iconProps?: SvgIconProps
}

export const HelpTooltip: FC<HelpTooltipProps & Omit<TooltipProps, 'children'>> = ({
    iconColor,
    iconProps,
    ...props
}) => {
    return (
        <Tooltip placement="top" arrow {...props}>
            <HelpOutlineIcon
                onClick={(event) => event.stopPropagation()}
                color={iconColor || 'primary'}
                {...iconProps}
                sx={getSx(style.icon, iconProps?.sx)}
            />
        </Tooltip>
    )
}
