import { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from 'App'
import { ModalType, closeModal, openModal } from 'design/templates/Modal/ModalTypes/modal.slice'
import useTariffPlans from 'hooks/user/useTariffPlans'
import {
    openSubscriptionModal,
    setPlan,
    SubscriptionCtaSource,
} from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import StatsLockedContent from 'design/pages/VidStats/components/StatsLockedContent'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { route } from 'constants/routes'
import { analyticsLockedModalConfig } from 'constants/subscriptions/lockedModalConfigs/globalPageLockedModalConfigs'

export const useUpgradeToUnlockStatsModal = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const lockModalContainer = useRef()
    const { pro: proPlan } = useTariffPlans()
    const { freshUpgradeFlow } = useFeatureFlags()

    const onUpgradeClick = useCallback(
        (options = { toCatalogOnClose: false }) => {
            dispatch(closeModal())
            dispatch(setPlan({ plan: proPlan }))
            dispatch(
                openSubscriptionModal({
                    containerRef: lockModalContainer.current,
                    ctaSource: SubscriptionCtaSource.VID_STATS,
                    onClose: () => options.toCatalogOnClose && navigate(route.video.catalog),
                }),
            )
        },
        [proPlan],
    )

    const show = useCallback(
        (options = { toCatalogOnClose: false }) => {
            dispatch(
                openModal(
                    freshUpgradeFlow
                        ? analyticsLockedModalConfig
                        : {
                              type: ModalType.OLD_FEATURE_LOCKED,
                              title: t('Upgrade to Unlock Stats for Your Videos!'),
                              description: t(
                                  'You have stats for your video plays… Upgrade to Pro Now To See how your videos are converting. Unlock these conversion-getting features today for free. 📈',
                              ),
                              proPlan,
                              containerRef: lockModalContainer.current,
                              onUpgradeClick: () => onUpgradeClick(options),
                              onCloseClick: () => options.toCatalogOnClose && navigate(route.video.catalog),
                              children: <StatsLockedContent />,
                              modalProps: {
                                  sx: {
                                      zIndex: 1300,
                                  },
                              },
                          },
                ),
            )
        },
        [proPlan, onUpgradeClick, freshUpgradeFlow, analyticsLockedModalConfig],
    )

    const close = useCallback(() => {
        dispatch(closeModal())
    }, [closeModal])

    return { show, close }
}
