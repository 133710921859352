import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack, Typography } from '@mui/material'

import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import { UI_THEME_NAME } from 'design/pages/VidSettings/constants'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { ControlsSectionFormData } from 'types/VidSettings'

import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import { PlayerThemeName } from 'types/Video'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const PlayerThemeSwitch = ({ isSmartVidGlobalSettings }: Props) => {
    const { t } = useTranslation()
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )

    const {
        formik: { values, setValues },
        isLoading,
        onChange,
    } = useSectionContext<ControlsSectionFormData>()

    const handleClick = useCallback(
        (variant: PlayerThemeName) => {
            setValues({
                ...values,
                [UI_THEME_NAME]: variant,
            })

            onChange()
        },
        [setValues, onChange],
    )

    const isClassic = values[UI_THEME_NAME] === PlayerThemeName.classic

    return (
        <Section>
            <Typography variant="body2" mb={6}>
                {t('Tailor your viewing experience to match your brand.')}
            </Typography>
            <Stack direction="row" gap={2}>
                <Button
                    onClick={() => handleClick(PlayerThemeName.classic)}
                    variant={isClassic ? 'contained' : 'outlined'}
                    color={isClassic ? 'secondary' : 'tertiary'}
                    disabled={isLoading}
                    fullWidth
                >
                    {t('Classic')}
                </Button>
                <Button
                    onClick={() => handleClick(PlayerThemeName.sleek)}
                    variant={!isClassic ? 'contained' : 'outlined'}
                    color={!isClassic ? 'secondary' : 'tertiary'}
                    disabled={isLoading}
                    fullWidth
                >
                    {t('Sleek')}
                </Button>
            </Stack>
        </Section>
    )
}

export default withErrorBoundary(PlayerThemeSwitch)
