import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs'

export const useFingerprint = () => {
    const [fingerprint, setFingerprint] = useState('')

    const fetchFingerprint = async () => {
        try {
            const result = await getFingerprint()
            setFingerprint(result)
        } catch (error) {
            console.error('Error getting fingerprint:', error)
            Sentry.captureException(error)
        }
    }

    useEffect(() => {
        fetchFingerprint()
    }, [])

    return fingerprint
}
