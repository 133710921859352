import { useCallback } from 'react'

import useSubscriptionTier from 'hooks/user/useSubscriptionTier'
import { FeatureGroup } from 'constants/keys/featuresAccess.constants'
import { getIsLockedByTierAndGroup } from './getIsLockedByTierAndGroup'

/**
 * A custom hook that checks if a feature is accessible based on the user's subscription tier.
 *
 * @param group - The feature group to check access for.
 * @returns A memoized function that returns true if the feature is locked, false otherwise.
 */
export const useCheckAccess = (group?: FeatureGroup) => {
    const tier = useSubscriptionTier()
    if (!group) return () => false

    return useCallback(getIsLockedByTierAndGroup(tier)(group), [tier, group])
}
