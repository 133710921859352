import { alpha, SxProps, Theme } from '@mui/material'

export default {
    root: (theme) => ({
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        border: `1px solid ${alpha(theme.palette.primary.main, theme.palette.action.outlinedBorderOpacity)}`,
        py: 8,
        px: 6,
        gap: 6,
        borderRadius: 4,
        flex: 1,
    }),
    priceValue: {
        fontSize: 32,
        fontWeight: 600,
        letterSpacing: '-1.28px',
        position: 'relative',
        bottom: -4,
    },
    price: {
        display: 'inline-flex',
        alignItems: 'flex-end',
        gap: 2,
        fontWeight: 500,
        letterSpacing: '-1.28px',
    },
    pricePeriod: {
        lineHeight: '137.5%',
        opacity: 0.6,
    },
    dueToday: {
        py: 0.75,
        px: 2,
        borderRadius: 2,
        bgcolor: 'action.selected',
        width: 'fit-content',
        display: 'inline-flex',
    },
} satisfies Record<string, SxProps<Theme>>
