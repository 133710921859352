import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

const container: SxProps<Theme> = {
    width: '100%',
    justifyContent: 'space-between',
    ml: 0,
}

const stack: SxProps<Theme> = {
    ml: 5,
    gap: 1,
}

const labelWrapper: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
}

const label: SxProps<Theme> = {
    p: 1,
    backgroundColor: (theme) => theme.palette.secondary.dark,
    color: (theme) => theme.palette.primary.dark,
    borderRadius: 1,
}

const labelIdle: SxProps<Theme> = {
    color: (theme) => theme.palette.text.primary,
}
const labelIcon: SxProps<Theme> = {
    color: (theme) => theme.palette.text.secondary,
}
const labelHighlighted: SxProps<Theme> = {
    color: (theme) => theme.palette.primary.main,
}
const switchContainer: SxProps<Theme> = {
    width: 1,
    justifyContent: 'space-between',
    ml: 0,
    py: 4,
}

const sliderContainer: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}

const slider: SxProps<Theme> = {
    flex: '0 0 60%',
    maxWidth: '60%',
}

export default {
    container,
    labelWrapper,
    label,
    stack,
    tabHeader: {
        idle: labelIdle,
        idleIcon: labelIcon,
        highlighted: labelHighlighted,
    },
    switchContainer,
    sliderContainer,
    slider,
}
