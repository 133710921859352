import { UseMutationOptions } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { CUSTOMER_QUERY, CUSTOMER_SUBSCRIPTION_QUERY } from 'api/constants'
import { queryClient, useRefreshTokenQuery } from 'api/queries'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { ActivateCustomerPlanPayload, Customer, CustomerStatus, Plan } from 'types/Customer'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useActivatePlanMutation = (
    options?: UseMutationOptions<Plan, any, ActivateCustomerPlanPayload>,
    showNotifications = true,
) => {
    const { mutate } = useMutationToastDecorator({
        showSuccessToast: showNotifications,
        showPendingToast: showNotifications,
        showErrorToast: showNotifications,
    })
    const { refetch } = useRefreshTokenQuery()

    return useInvalidateMutation<Plan, ActivateCustomerPlanPayload>(
        CUSTOMER_SUBSCRIPTION_QUERY,
        async (payload: ActivateCustomerPlanPayload) => mutate(userAPI.activatePlan(payload), apiLocale.user.plan),
        {
            ...options,
            onSuccess: async (data, variables, context) => {
                await refetch()

                options?.onSuccess?.(data, variables, context)

                const customer = queryClient.getQueryData(CUSTOMER_QUERY) as Customer

                if (customer) {
                    queryClient.setQueryData(CUSTOMER_QUERY, {
                        ...customer,
                        status: customer.status === CustomerStatus.inactive ? CustomerStatus.active : customer.status,
                    })
                }

                queryClient.invalidateQueries()
            },
            invalidateOn: InvalidateOn.AFTER_SUCCESS,
        },
    )
}
