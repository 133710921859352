import { SxProps, Theme } from '@mui/material'

const info: SxProps<Theme> = {
    whiteSpace: 'normal',
    color: 'text.secondary',
    '& > svg': {
        verticalAlign: 'text-top',
    },
}

const tooltipText: SxProps<Theme> = {
    whiteSpace: 'pre-line',
}

const tooltipLink: SxProps<Theme> = {
    color: 'accent.light',
    '&:hover': {
        color: 'accent.main',
    },
}

export default {
    info,
    tooltipText,
    tooltipLink,
}
