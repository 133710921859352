import { Theme, alpha } from '@mui/material'
import { grey } from '@mui/material/colors'

import { getPropByTheme } from 'styles/theme/utils'

const switchRoot =
    (color = 'primary', size = 'medium') =>
    (theme: Theme) => ({
        padding: 0,
        margin: 1,
        borderRadius: '100px',

        '.MuiSwitch-track': {
            opacity: '1 !important',
            background: getPropByTheme('#808DA3', alpha('#fff', 0.38), theme.palette.mode),
        },

        '.MuiButtonBase-root': {
            padding: 0.5,
        },

        '.MuiSwitch-thumb': {
            color: getPropByTheme(grey[50], grey[300], theme.palette.mode),
            boxShadow: theme.shadows[1],
        },

        '&:has(.Mui-checked):not(:has(.Mui-disabled))': {
            '.MuiSwitch-thumb': {
                color: theme.palette.common.white,
            },

            ...(color === 'primary' && {
                '&:hover .MuiSwitch-track': {
                    backgroundColor: theme.palette.primary.dark,
                },
                '&:active': {
                    outline: `4px solid ${alpha(theme.palette.primary.dark, 0.3)}`,
                },
            }),

            ...(color === 'default' && {
                '&:hover .MuiSwitch-track': {
                    backgroundColor: '#808DA3',
                },
            }),

            ...(color === 'secondary' && {
                '&:hover .MuiSwitch-track': {
                    backgroundColor: theme.palette.secondary.main,
                },
                '&:active': {
                    outline: `4px solid ${alpha(theme.palette.secondary.main, 0.3)}`,
                },
            }),

            ...(color === 'error' && {
                '&:hover .MuiSwitch-track': {
                    backgroundColor: theme.palette.error.main,
                },
                '&:active': {
                    outline: `4px solid ${alpha(theme.palette.error.main, 0.3)}`,
                },
            }),

            ...(color === 'warning' && {
                '&:hover .MuiSwitch-track': {
                    backgroundColor: theme.palette.warning.dark,
                },
                '&:active': {
                    outline: `4px solid ${alpha(theme.palette.warning.main, 0.3)}`,
                },
            }),

            ...(color === 'info' && {
                '&:hover .MuiSwitch-track': {
                    backgroundColor: theme.palette.info.dark,
                },
                '&:active': {
                    outline: `4px solid ${alpha(theme.palette.info.main, 0.3)}`,
                },
            }),

            ...(color === 'success' && {
                '&:hover .MuiSwitch-track': {
                    backgroundColor: theme.palette.success.dark,
                },
                '&:active': {
                    outline: `4px solid ${alpha(theme.palette.success.main, 0.3)}`,
                },
            }),
        },

        '.Mui-disabled': {
            '~ .MuiSwitch-track': {
                background: `${theme.palette.action.disabledBackground} !important`,
            },

            '.MuiSwitch-thumb': {
                color: getPropByTheme(grey[100], grey[600], theme.palette.mode),
                boxShadow: theme.shadows[1],
            },
        },

        ...(size === 'small' && {
            width: 36,
            height: 20,

            '.MuiSwitch-thumb': {
                width: 16,
                height: 16,
            },
        }),
        ...(size === 'medium' && {
            width: 44,
            height: 24,

            '.MuiSwitch-thumb': {
                width: 20,
                height: 20,
            },
        }),
    })

const formControlLabelRoot = (theme: Theme) => ({
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    fontFeatureSettings: "'clig' off, 'liga' off",
    gap: 1,
    m: 0,
})

const wrapper = () => ({
    display: 'flex',
    gap: 1,
    alignItems: 'center',
})

const disabled = () => ({
    opacity: 0.5,
})

export default {
    switchRoot,
    wrapper,
    formControlLabelRoot,
    disabled,
}
