import {
    CALL_TO_ACTIONS,
    PLAYBACK_AUTOPLAY_ENABLED,
    PLAYBACK_NO_PAUSE,
    PLAYBACK_RESUME,
    PLAYBACK_SKIP_AND_REWIND_WITH_KEYBOARD,
    UI_CONTROL_BAR_ACCESSIBILITY_TOOLTIPS,
    PLAYBACK_SMART_PAUSE,
    PLAY_GATES,
    TAGS,
    UI_CLOSED_CAPTIONS_DEFAULT_ENABLED,
    UI_CONTROL_BAR_FULLSCREEN_SHOW,
    UI_CONTROL_BAR_PLAY_SHOW,
    UI_CONTROL_BAR_QUALITY_CONTROL_SHOW,
    UI_CONTROL_BAR_REWIND_SHOW,
    UI_CONTROL_BAR_SEEK_BAR_SHOW,
    UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS,
    UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW,
    UI_CONTROL_BAR_SPEED_CONTROL_SHOW,
    UI_CONTROL_BAR_VOLUME_SHOW,
    UI_OVERLAY_EXPIRE_ENABLED,
    UI_OVERLAY_PAUSE_SOURCE,
    UI_OVERLAY_PLAY_BUTTOW_SHOW,
    UI_OVERLAY_PLAY_SEEK_BUTTONS_SHOW,
} from 'design/pages/VidSettings/constants'
import { PlayerOnPlaybackEndType } from 'types/Player'
import { VideoSettings } from 'types/Video'
import { route } from 'constants/routes'

export const MIXPANEL_EVENTS = {
    ANALYTICS_FILTERS_APPLY_CLICK: 'analytics_filters_applied',
    PAGE_OPEN: 'page_open',
    SIGN_UP_OPEN: 'sign_up_open',

    SIGN_IN: 'sign_in',
    SIGN_UP_ACCOUNT_CREATED: 'sign_up_account_created',
    SIGN_UP_EMAIL_ENTERED: 'sign_up_email_entered',
    SIGN_UP_PAYMENT_OPEN: 'sign_up_payment_open',
    SIGN_UP_CARD_SKIP: 'sign_up_card_skip',
    SIGN_UP_CONFIRM: 'sign_up_confirm',
    SIGN_UP_WITH_GOOGLE_CLICKED: 'sign_up_with_google_clicked',
    SIGN_UP_WITH_MICROSOFT_CLICKED: 'sign_up_with_microsoft_clicked',
    SIGN_OUT: 'sign_out',

    WELCOME_OPEN: 'welcome_open',
    WELCOME_VIDEO_STARTED: 'welcome_video_started',
    WELCOME_VIDEO_WATCHED: 'welcome_video_watched',
    WELCOME_TRY_DEMO_CLICK: 'upload_try_demo_clicked',

    SIDEBAR_UPLOAD_CLICK: 'sibebar_upload_click',
    SIDEBAR_HELP_CLICKED: 'help_clicked',
    SUBSCRIPTIONS_OPENED: 'subscriptions_opened',
    SUBSCRIPTIONS_PLAN_SELECTED: 'subscriptions_plan_selected',

    INTEGRATION_ENABLED: 'integration_enabled',
    GLOBAL_SCRIPT_COPIED: 'global_script_copied',

    UPLOAD_INITIATED: 'upload_initiated',
    UPLOAD_FAILED: 'upload_failed',
    UPLOAD_CANCELLED: 'upload_cancelled',

    VID_SETTINGS_LOADED: 'vidsettings_loaded',
    VID_SETTINGS_PUBLISHED: 'vidsettings_published',
    VID_SETTINGS_DISCARDED: 'vidsettings_discarded',
    VID_SETTINGS_EMBED_CLICKED: 'vidsettings_embed_clicked',
    VID_SETTINGS_CHANGED: 'vidsettings_changed',
    VID_SETTINGS_AUTOSCROLL_CTA: 'vidsettings_autoscroll_cta',

    VID_STATS_VIEWSEGMENT: 'vidstats_viewsegment',
    VID_STATS_COMPARE_CLICKED: 'vidstats_vids_compare_clicked',
    VID_STATS_COMPARED: 'vidstats_vids_compared',
    VID_STATS_PERIOD_COMPARED: 'vidstats_periods_compared',
    VID_STATS_RESET: 'vidstats_stats_reset',
    VID_STATS_DOWNLOADED: 'vidstats_downloaded',
    VID_STATS_TIMELINE_CLICKED: 'vidstats_timeline_clicked',

    CONVERSION_CREATED: 'conversion_created',
    CONVERSION_CODE_COPIED: 'conversion_code_copied',

    SMART_VID_NEW_CLICKED: 'smartvid_new_clicked',
    SMART_VID_NEW_CREATED: 'smartvid_new_created',
    SMART_VID_DELETED: 'smartvid_deleted',
    SMART_VID_PUBLISHED: 'smartvid_published',
    SMART_VID_ANALYTICS_OPEN: 'smartvid_flow_analytics_open',
    SMART_VID_STEP_ADDED: 'smartvid_step_added',
    SMART_VID_STEP_DELETED: 'smartvid_step_deleted',
    SMART_VID_STEP_STATS_OPEN: 'smartvid_step_stats_open',
    SMART_VID_STEP_SETTINGS_OPEN: 'smartvid_step_settings_open',
    SMART_VID_GENERAL_SETTINGS_OPEN: 'smartvid_general_settings_open',
    SMART_VID_PREVIEW_OPEN: 'smartvid_preview_open',
    SMART_VID_INTERACTION_ADDED: 'smartvid_interaction_added',

    UPGRADE_PROMO_POPUP_OPENED: 'upgrade_promo_popup_opened',
    ALLPLANS_POPUP_OPENED: 'upgrade_allplans_popup_opened',
    TRIAL_STARTED_OPENED: 'trial_started_opened',
    UPGRADE_DOWNGRADE_CONFIRMED: 'upgrade_downgrade_confirmed',
    UPGRADE_DOWNGRADE_OPENED: 'upgrade_downgrade_opened',
    UPGRADE_TRIAL_OVER_KEEPPLAN: 'upgrade_trial_over_keepplan',
    UPGRADE_TRIAL_OVER_OPENED: 'upgrade_trial_over_opened',
    UPGRADE_PAYMENT_SKIPPED: 'upgrade_payment_skipped',
    UPGRADE_PAYMENT_CONFIRMED: 'upgrade_payment_confirmed',

    CHAPTERS_ENABLED: 'vidsettings_chapters',
}

export const ROUTES_TO_SKIP: string[] = [
    // 'page_open' event on Vids Settings page is sent separately
    // as it has some additional info
    route.video.settings.byId(),
    route.stats.detailed(),
]

export const videoSettingsBoolProperties: {
    [key: string]: keyof VideoSettings | (keyof VideoSettings)[]
} = {
    vidsettings_style_bigplaybtn: UI_OVERLAY_PLAY_BUTTOW_SHOW,
    vidsettings_style_smallplaybtn: UI_CONTROL_BAR_PLAY_SHOW,
    vidsettings_style_settings: [UI_CONTROL_BAR_SPEED_CONTROL_SHOW, UI_CONTROL_BAR_QUALITY_CONTROL_SHOW],
    vidsettings_style_volume: UI_CONTROL_BAR_VOLUME_SHOW,
    vidsettings_style_fullscreen: UI_CONTROL_BAR_FULLSCREEN_SHOW,
    vidsettings_style_rewind: UI_CONTROL_BAR_REWIND_SHOW,
    vidsettings_style_fastforward: UI_OVERLAY_PLAY_SEEK_BUTTONS_SHOW,
    vidsettings_style_rewind_kb: PLAYBACK_SKIP_AND_REWIND_WITH_KEYBOARD,
    vidsettings_style_accessibility: UI_CONTROL_BAR_ACCESSIBILITY_TOOLTIPS,
    vidsettings_thumbnails_exit: UI_OVERLAY_PAUSE_SOURCE,
    vidsettings_smartautoplay: PLAYBACK_AUTOPLAY_ENABLED,
    vidsettings_resumeplay: PLAYBACK_RESUME,
    vidsettings_smartpause: PLAYBACK_SMART_PAUSE,
    vidsettings_disablepausing: PLAYBACK_NO_PAUSE,
    vidsettings_closedcaptions: UI_CLOSED_CAPTIONS_DEFAULT_ENABLED,
    vidsettings_expirevid: UI_OVERLAY_EXPIRE_ENABLED,
}

export const videoSettingsArrayBoolProperties = {
    vidsettings_ctas: CALL_TO_ACTIONS,
    vidsettings_playgates: PLAY_GATES,
    vidsettings_tags: TAGS,
} as const

export const SEEKING_BAR_STYLE_LABELS = {
    [UI_CONTROL_BAR_SEEK_BAR_SHOW]: 'standard',
    [UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS]: 'revisit',
    [UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW]: 'rapid_engage',
}

export const END_VIDEO_ACTIONS_LABELS = {
    [PlayerOnPlaybackEndType.PAUSE]: 'pause',
    [PlayerOnPlaybackEndType.DISPLAY_THUMBNAIL]: 'thumbnail',
    [PlayerOnPlaybackEndType.REPLAY]: 'loop',
    [PlayerOnPlaybackEndType.REDIRECT]: 'redirect',
}
