import {
    ACCESS_TOKEN,
    API_HANDLE,
    BUSINESSNAME,
    CARD_NUMBER,
    COUPON_CODES,
    CREDENTIAL,
    CREDIT_CARD,
    CREDIT_CARD_TOKEN,
    CREDIT_FIRSTNAME,
    CREDIT_LASTNAME,
    CUSTOMER,
    CVV,
    DATE_CREATED,
    EXPIRATION_DATE,
    EXPIRATION_MONTH,
    EXPIRATION_YEAR,
    FEATURES,
    FIRSTNAME,
    GUID,
    LASTNAME,
    NAME,
    PLAN,
    PRICEPOINT,
    REDIRECT_URL,
    REMEMBER_ME,
    SOURCE,
    TOKEN,
    TOKENS,
    UPSELL_TOKEN,
    UTM,
    VDLY,
    ZIPCODE,
} from 'design/organisms/SignUp/constants'
import {
    ApiHandle,
    BillingMode,
    CustomerSubscription,
    CustomerSubscriptionPlanTier,
    ExtraPlaysUnit,
    PlanName,
} from 'types/Customer'
import { MktParams } from 'utils'
import { ACCESS, CONFIRM_PASSWORD, EMAIL, PASSWORD, REDIRECT, REFRESH } from 'constants/validations/user.constants'
import { TYPE } from 'constants/keys'

export type SignInPayload = {
    [EMAIL]: string
    [PASSWORD]: string
    [REMEMBER_ME]?: boolean
}

export type SignInResponse = {
    [ACCESS]: string
    [REFRESH]?: string
    [REDIRECT]?: string
}

type SignInWithGooglePayloadAccessTokenRequired = {
    [ACCESS_TOKEN]: string
    [REMEMBER_ME]?: boolean
}

type SignInWithGooglePayloadCredentialRequired = {
    [CREDENTIAL]: string
    [REMEMBER_ME]?: boolean
}

export type SignInWithGooglePayload =
    | SignInWithGooglePayloadAccessTokenRequired
    | SignInWithGooglePayloadCredentialRequired

export type SignInWithMicrosoftPayload = {
    [TOKEN]: string
    [REMEMBER_ME]?: boolean
}

export type RefreshTokenPayload = {
    access: string
    refresh: string
}

export type Plan = {
    [API_HANDLE]: string
    [PRICEPOINT]?: string | null
}

export type Customer = {
    [FIRSTNAME]: string
    [LASTNAME]: string
    [EMAIL]: string
    [PASSWORD]: string
}

export type CustomerResponse = Customer & {
    [GUID]: string
    [DATE_CREATED]: string
}

export enum CreditCardType {
    visa = 'visa',
    mastercard = 'master',
    amex = 'american_express',
    discover = 'discover',
    maestro = 'maestro',
}

export type CreditCard = {
    id?: number
    [FIRSTNAME]: string
    [LASTNAME]: string
    [CARD_NUMBER]: string
    [CVV]: string
    [EXPIRATION_YEAR]: number
    [EXPIRATION_MONTH]: number
    [ZIPCODE]: string
    [TYPE]?: CreditCardType
}

export type SignUpFormData = {
    [FIRSTNAME]: string
    [LASTNAME]: string
    [BUSINESSNAME]: string
    [CREDIT_FIRSTNAME]: string
    [CREDIT_LASTNAME]: string
    [EMAIL]: string
    [PASSWORD]: string
    [CARD_NUMBER]: string
    [EXPIRATION_DATE]: string
    [CVV]: string
    [ZIPCODE]: string
    [SOURCE]: SignUpSource | null
}

export type SignUpPayload = {
    [PLAN]: Plan
    [CUSTOMER]: Customer
    [CREDIT_CARD]?: CreditCard | null
    [CREDIT_CARD_TOKEN]?: string
    [UTM]?: MktParams
    [VDLY]?: string | null
    [COUPON_CODES]?: string[]
}

export type SignUpResponse = {
    [CUSTOMER]: CustomerResponse
    [TOKENS]: SignInResponse
    [SOURCE]: SignUpSource | null
    [REDIRECT_URL]?: string
    [UPSELL_TOKEN]?: string
}

export type AuthForm = {
    [FIRSTNAME]: string
    [LASTNAME]: string
    [EMAIL]: string
    [PASSWORD]: string
    [CARD_NUMBER]: string
    [EXPIRATION_DATE]: string
    [CVV]: string
    [ZIPCODE]: string

    [FIRSTNAME]: string
    [LASTNAME]: string
    [CARD_NUMBER]: string
    [EXPIRATION_DATE]: string
    [CVV]: string
    [ZIPCODE]: string
    [EMAIL]: string
    [PASSWORD]: string
}

export enum PlanStatus {
    active = 'active',
    trial = 'trial',
    archive = 'archive',
}

export enum DiscountType {
    percentage = 'percentage',
    amount = 'amount',
}

export type CouponsDetails = {
    amount: number | null
    code: string
    discounted_price: string
    duration_period: number | null
    percentage: number | null
    period: string
    type: DiscountType
}

export type PlanPayload = {
    id: number
    chargifyId: number
    status?: PlanStatus
    [API_HANDLE]: ApiHandle
    [NAME]: PlanName
    subtitle?: string | null
    [FEATURES]: string[]
    price: string
    trialPrice: string
    creditCardRequired: boolean
    setupFeeInCents: number | null
    skipBillingPageAtSignup: boolean
    hasTrial: boolean
    isFree: boolean
    isPublished: boolean
    billingPeriod: string
    trialPeriod: string | null
    bandwidth: number
    videoSpace: number
    plays?: number | null
    minutes?: number | null
    encoding: string
    extraBandwidthPrice: number
    extraPlaysPrice: number | null
    extraPlaysUnit?: ExtraPlaysUnit
    extraMinutesPrice?: number | null
    extraMinutesUnit?: number | null
    extraBandwidthUnit: string
    extraVideoSpacePrice: number | null
    tier: CustomerSubscriptionPlanTier
    upsellPage: null
    upsellMainProducts: null
    billingMode?: BillingMode
    coupons?: {
        totalDiscount: string
        nextDiscount: string
        couponsDetails: Array<CouponsDetails>
    }
}

export interface GoogleAccount {
    email: string
    family_name: string
    given_name: string
    hd: string
    id: string
    locale: string
    name: string
    picture: string
    verified_email: boolean
}

export type ForgotPasswordPayload = {
    [EMAIL]: string
}

export type ForgotPasswordResponse = {
    description: string
}

export type ResetPasswordEmailResponse = {
    email: string
}

export type ResetPasswordForm = {
    [PASSWORD]: string
    [CONFIRM_PASSWORD]: string
}

export type ResetPasswordPayload = ResetPasswordForm & {
    resetPasswordToken: string
}

export enum SignUpSource {
    google = 'Google',
    microsoft = 'Microsoft',
}

export type ProcessPaymentPayload = {}
export type ProcessPaymentResponse = {
    subscription: CustomerSubscription
}

export type ProcessUpsellPayload = {
    apiHandle: ApiHandle
}
export type ProcessUpsellResponse = {
    requestCard: boolean
}

export type VerifyEmailPayload = {
    email: string
    refererUrl?: string
    vdly?: string
    us?: string | null
    um?: string | null
    uca?: string | null
    uco?: string | null
    ut?: string | null
    fc?: string | null
    fp?: string | null
    fid?: string | null
    f: string
}

export type CreateActiveCampaignContactPayload = {
    email: string
    firstName?: string
    lastName?: string
    apiHandle?: string
}

export interface SignUpPlanResponse {
    data: PlanPayload
}
