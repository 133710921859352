import { useEffect, useMemo, useRef, useState } from 'react'
import { Formik } from 'formik'
import * as Sentry from '@sentry/react'
import { Box, Button, Divider, Grid, IconButton, Skeleton, Tooltip, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useSearchParams } from 'react-router-dom'

import { SuccessMessage } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SuccessMessage'
import { useToast } from 'design/organisms/ToastProvider'
import { useAppDispatch } from 'App'
import { Features } from 'design/templates/Modal/modalTemplates/SubscriptionModal/OldSelectPlanStep/Plan/Features'
import SuccessCheckmarkGreen from 'assets/img/freemium/checkmarks/success-checkmark-green.svg'
import LightningStrike from 'assets/img/freemium/lightning-strike.svg'
import { cardFormValidationSchema } from 'design/organisms/SignUp/Forms'
import { INITIAL_VALUES } from 'design/organisms/SignUp/constants'
import { ApiHandle, CustomerSubscriptionPlanTier, Plan } from 'types/Customer'
import { convertCentsToDollars, getBillingPeriod, stringPriceToNumber } from 'utils'
import { useCustomerSubscriptionQuery, usePlanProrateQuery, usePlanQuery, usePlansQuery } from 'api/queries'
import { useActivatePlanMutation } from 'api/mutations'
import { setTokenRefreshing } from 'design/organisms/Account/account.slice'
import { ChargifyError, useChagifyTokenStatus, useShowErrorAddCard } from 'hooks/chargify.hooks'
import {
    PlanOptions,
    SubscriptionSteps,
} from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import useTariffPlans from 'hooks/user/useTariffPlans'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { SetPlan } from '..'
import ActivationWelcome from './ActivationWelcome'

import locale from './ActivationStep.locale'
import { ChargifyForm } from './ChargifyForm'
import './index.scss'

type ActivationStepProps = {
    selectedPlan?: Plan
    lookForTier?: CustomerSubscriptionPlanTier
    lookForApiHandle?: ApiHandle
    planOptions?: PlanOptions
    hideBreadcrumb?: boolean
    setPlan: SetPlan
    onClose: () => void
}

export const ActivationStep = ({
    selectedPlan,
    lookForTier,
    lookForApiHandle,
    planOptions,
    hideBreadcrumb,
    setPlan,
    onClose,
}: ActivationStepProps) => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const { newWelcomePopupForUpgrades } = useFeatureFlags()
    const { data: subscription, isLoading } = useCustomerSubscriptionQuery()
    const { data: plans } = usePlansQuery()
    const { showToast } = useToast()
    const { data: planByApiHandle } = usePlanQuery(lookForApiHandle as string, {
        enabled: Boolean(lookForApiHandle),
        onError: () => {
            showToast({
                message: locale.error.failedLoadPlan,
                type: 'error',
            })
            onClose()
        },
    })

    const { chargifyTokenError, chargifyTokenSuccess } = useChagifyTokenStatus()

    const chargify = useRef(window.Chargify ? new window.Chargify() : null)
    const formRef = useRef(null)
    const [openForm, setOpenForm] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [reactivationSuccess, setReactivationSuccess] = useState(false)
    const [isLoadingActivation, setIsLoadingActivation] = useState(false)
    const [oldTier] = useState<CustomerSubscriptionPlanTier | undefined>(subscription?.plan.tier)

    const activatePlanMutation = useActivatePlanMutation({
        onSuccess: async () => {
            return planOptions?.reactivate ? setReactivationSuccess(true) : setShowSuccessMessage(true)
        },
    })
    const { pro, premium } = useTariffPlans()

    useEffect(() => {
        return () => {
            dispatch(setTokenRefreshing({ isTokenRefreshing: false }))
        }
    }, [])

    const plan = useMemo(() => {
        if (lookForApiHandle) {
            return planByApiHandle
        }

        if (selectedPlan || !plans?.length) {
            return selectedPlan
        }

        return (
            plans.find((p) =>
                lookForTier
                    ? p.tier === lookForTier
                    : p.tier === CustomerSubscriptionPlanTier.proIv || p.tier === CustomerSubscriptionPlanTier.pro,
            ) ||
            premium ||
            pro
        )
    }, [selectedPlan, lookForApiHandle, planByApiHandle, plans, pro, premium])
    const urlPricePoint = searchParams.get('pricepoint') ?? undefined
    const { data: planProrate, isLoading: isPlanProrateLoading } = usePlanProrateQuery(plan?.apiHandle, urlPricePoint)

    const action = useMemo(() => {
        if (planOptions?.reactivate) {
            return 'Reactivate'
        }

        if (planOptions?.downgrade) {
            return 'Downgrade'
        }

        return 'Upgrade'
    }, [planOptions])

    const openCardForm = isLoading ? false : Boolean(openForm || !subscription?.creditCard?.cardNumber)

    const renderAvailableCard = () => {
        if (openCardForm || !subscription) {
            return null
        }

        const expYear = subscription.creditCard?.expirationYear.toString()

        return (
            <Box className="customerCreditCard" data-testid="customerCreditCard">
                <Box className="detailsContainer">
                    <img src={SuccessCheckmarkGreen} alt="success checkmark icon" />

                    <Box className="details">
                        <Typography className="bullets" component="span">
                            &#8226; &#8226; &#8226; &#8226;
                        </Typography>
                        <Typography className="cardNumbers" component="span">
                            {subscription.creditCard?.cardNumber.substring(15)}
                        </Typography>
                        <Typography className="expiration">
                            Exp.{subscription.creditCard?.expirationMonth}/
                            {expYear && expYear.length > 2 ? expYear.substring(2) : expYear}
                        </Typography>

                        <Button variant="outlined" onClick={() => setOpenForm(true)}>
                            Change Payment Method
                        </Button>
                    </Box>
                </Box>

                <Tooltip title={locale.removeTooltip} placement="right" arrow>
                    <IconButton
                        disabled
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="remove card"
                        className="trash"
                        style={{ pointerEvents: 'visible' }}
                        onClick={() => setOpenForm(true)}
                    >
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }

    const renderCost = () => {
        if (isPlanProrateLoading || !plan) {
            return (
                <Box className="planPriceBlock">
                    <Skeleton width={60} />
                    <Skeleton width={80} />
                </Box>
            )
        }

        const paymentDueInCents = planProrate?.migration.payment_due_in_cents || 0
        const fullPrice = stringPriceToNumber(plan.price)

        if (
            planProrate &&
            (paymentDueInCents > 0 ? paymentDueInCents / 100 !== fullPrice : paymentDueInCents !== fullPrice)
        ) {
            return (
                <Box>
                    <Box className="planPriceBlock upgradePrice">
                        <Typography component="h3" variant="h3" className="total">
                            Today You Pay
                        </Typography>
                        <Typography component="h3" variant="h3" className="planPrice">
                            {convertCentsToDollars(paymentDueInCents)}
                        </Typography>
                    </Box>
                    <Box className="planPriceBlock fullPrice">
                        <Typography component="h3" variant="h3" className="total">
                            Plan Total:
                        </Typography>
                        <Typography component="h3" variant="h3" className="planPrice">
                            <span className="crossed">
                                ${plan ? fullPrice.toFixed(2) : <Skeleton width={24} variant="text" />}
                            </span>
                            <Typography component="span" className="period">
                                / {getBillingPeriod(plan.billingPeriod)}
                            </Typography>
                        </Typography>
                    </Box>
                </Box>
            )
        }

        return (
            <Box className="planPriceBlock">
                <Typography component="h3" variant="h3" className="total">
                    Total:
                </Typography>
                <Typography component="h3" variant="h3" className="planPrice">
                    ${plan ? fullPrice.toFixed(2) : <Skeleton width={24} variant="text" />}
                    <Typography component="span" className="period">
                        / {getBillingPeriod(plan.billingPeriod)}
                    </Typography>
                </Typography>
            </Box>
        )
    }
    const handleActivate = async () => {
        setIsLoadingActivation(true)

        if (!plan) {
            return
        }

        const apiHandle = planOptions?.reactivate ? subscription?.plan.apiHandle : plan.apiHandle
        const pricePoint = urlPricePoint ?? subscription?.pricePoint
        const addons = planOptions?.addons

        if (!apiHandle) {
            return
        }

        if (openCardForm) {
            if (!navigator.onLine) {
                setIsLoadingActivation(false)
                showErrorAddCard('Unable to save payment method. Please check your connection and try again.')
            }

            return chargify.current?.token(
                formRef.current,

                chargifyTokenSuccess(async (token) => {
                    dispatch(setTokenRefreshing({ isTokenRefreshing: true }))

                    if (planOptions?.downgrade) {
                        setIsLoadingActivation(false)
                        return setPlan(plan, {
                            step: SubscriptionSteps.CONFIRM_DOWNGRADE,
                            creditCardToken: token,
                            pricePoint,
                            addons,
                        })
                    }

                    try {
                        await activatePlanMutation.mutateAsync({
                            apiHandle,
                            creditCardToken: token,
                            pricePoint,
                            addons,
                        })
                    } catch (error) {
                        Sentry.captureException(error)
                    } finally {
                        setIsLoadingActivation(false)
                    }
                }),
                (error: ChargifyError) => {
                    setIsLoadingActivation(false)
                    chargifyTokenError(error)
                },
            )
        }

        if (planOptions?.downgrade) {
            setIsLoadingActivation(false)
            return setPlan(plan, {
                step: SubscriptionSteps.CONFIRM_DOWNGRADE,
                pricePoint,
                addons,
            })
        }

        try {
            await activatePlanMutation.mutateAsync({ apiHandle, pricePoint, addons })
        } catch (error) {
            Sentry.captureException(error)
        } finally {
            setIsLoadingActivation(false)
        }
    }

    const showErrorAddCard = useShowErrorAddCard()

    useEffect(() => {
        const handleOffline = () => {
            if (isLoadingActivation) {
                setIsLoadingActivation(false)
                showErrorAddCard('Unable to save payment method. Please check your connection and try again.')
            }
        }

        window.addEventListener('offline', handleOffline)

        return () => {
            window.removeEventListener('offline', handleOffline)
        }
    }, [isLoadingActivation])

    if (reactivationSuccess) {
        return (
            <SuccessMessage
                mainTitle="Reactivation Success!"
                title="Reactivation"
                description="You have successfully reactivated your account!"
                onClose={onClose}
            />
        )
    }

    if (showSuccessMessage) {
        return newWelcomePopupForUpgrades ? (
            <ActivationWelcome oldTier={oldTier} onClose={onClose} />
        ) : (
            <SuccessMessage
                mainTitle="You have been successfully upgraded!"
                title="Check Out Your New Perks"
                description="Now that you have decided to up your video marketing game, make sure to check out the best features to
        enable to make sure your videos perform their best."
                oldTier={oldTier}
                onClose={onClose}
            />
        )
    }

    return (
        <Box className="ActivationStep">
            <Box className="header">
                {!hideBreadcrumb && (
                    <Typography className="actionBack" onClick={() => setPlan(undefined)}>
                        <ArrowBackIosNewOutlinedIcon />
                        Choose a different plan
                    </Typography>
                )}
            </Box>

            <Grid container spacing={2}>
                <Grid item laptop={5} mobile={12} className="plan">
                    <Typography className="text">{action}</Typography>
                    <Typography className="planName">
                        {!plan?.isFree && <img width={26} src={LightningStrike} alt="lightning icon" />}{' '}
                        {plan?.name || <Skeleton width={100} height={36} />}
                    </Typography>
                    <Features plan={plan} />
                </Grid>

                <Grid item laptop={7} mobile={12}>
                    <Formik
                        initialValues={INITIAL_VALUES}
                        validationSchema={cardFormValidationSchema}
                        /* eslint-disable  @typescript-eslint/no-empty-function */
                        onSubmit={() => {}}
                    >
                        {() => (
                            <Box className="paymentDetails">
                                <Typography component="h2">Payment Details</Typography>

                                {renderAvailableCard()}

                                {openCardForm && <ChargifyForm chargify={chargify} formRef={formRef} />}

                                <Divider />

                                {renderCost()}

                                {(planProrate?.migration.hasTrial || subscription?.plan.isFree) && (
                                    <Typography className="trialNotice">
                                        *Your trial will be effective today, and billed in 14 days, and every month
                                        after that, until you cancel your subscription.
                                    </Typography>
                                )}

                                <LoadingButton
                                    variant="contained"
                                    className="activateCta"
                                    onClick={handleActivate}
                                    disabled={!plan}
                                    data-testid="activatePlanCta"
                                    loading={isLoadingActivation}
                                >
                                    {planProrate?.migration.hasTrial ? 'Activate your trial' : 'Activate Your Plan'}
                                </LoadingButton>
                            </Box>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Box>
    )
}
