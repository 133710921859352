import { useMemo, useCallback } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import {
    UI_CONTROL_BAR_ALWAYS_SHOW,
    UI_OVERLAY_PLAY_BUTTOW_SHOW,
    UI_CONTROL_BAR_PLAY_SHOW,
    UI_CONTROL_BAR_QUALITY_CONTROL_SHOW,
    UI_CONTROL_BAR_SPEED_CONTROL_SHOW,
    UI_CONTROL_BAR_VOLUME_SHOW,
    UI_CONTROL_BAR_FULLSCREEN_SHOW,
    UI_CONTROL_BAR_REWIND_SHOW,
    UI_OVERLAY_PLAY_SEEK_BUTTONS_SHOW,
    PLAYBACK_SKIP_AND_REWIND_WITH_KEYBOARD,
    UI_CONTROL_BAR_ACCESSIBILITY_TOOLTIPS,
    UI_CONTROL_BAR_FULLSCREEN_CUSTOM_DEFAULT,
    UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MOBILE,
    PLAYER_DOCS_FOCUSED_FULLSCREEN_LINK,
    UI_CONTROL_BAR_FULLSCREEN_CUSTOM_EXPAND_ON_PLAY,
    UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MINIMIZE_ON_PAUSE,
} from 'design/pages/VidSettings/constants'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { ControlsSectionFormData } from 'types/VidSettings'

import { Switch, SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import { Link } from 'design/atoms/Link'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import locale from '../ControlsSection.locale'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const Controls = ({ isSmartVidGlobalSettings }: Props) => {
    const { focusedFullscreenSection, accessibilityTooltips, combineSeekBarSettings } = useFeatureFlags()
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )

    const {
        formik: { values, setValues },
        video,
        isLoading,
        onChange,
    } = useSectionContext<ControlsSectionFormData>()

    const commonProps = {
        afterChange: onChange,
        disabled: isLoading,
        labelTypography: { variant: 'body2' as Variant },
        switchProps: { size: 'small' },
    } as const

    const handleFocusedFullscreenChange = useCallback(
        (name: string, checked: boolean) => {
            setValues((prevValues) => {
                return {
                    ...prevValues,
                    [name]: checked,
                    // Setting these values to `true` because they were separated
                    // from the Focused Fullscreen property and have no effect
                    // on their own, so it's safe to assume they should be enabled
                    [UI_CONTROL_BAR_FULLSCREEN_CUSTOM_EXPAND_ON_PLAY]: true,
                    [UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MINIMIZE_ON_PAUSE]: true,
                }
            })
            onChange()
        },
        [onChange, setValues, values],
    )

    const isDisablePausingEnabledWithAutoplay = useMemo(
        () => Boolean(video?.settings?.playback_noPause && video?.settings?.playback_autoplay_enabled),
        [video],
    )

    return (
        <Section title="Controls" indent>
            {combineSeekBarSettings && (
                <SwitchForm
                    sx={style.container}
                    name={UI_CONTROL_BAR_ALWAYS_SHOW}
                    label={<Typography variant="body2">{locale.controls.alwaysShow}</Typography>}
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    helpInfo={locale.tooltips.alwaysShow}
                    labelPlacement="start"
                    {...commonProps}
                />
            )}
            <SwitchForm
                sx={style.container}
                name={UI_OVERLAY_PLAY_BUTTOW_SHOW}
                label={<Typography variant="body2">{locale.controls.bigPlayButton}</Typography>}
                {...commonProps}
                tooltipPlacement={SwitchTooltipPlacement.inside}
                helpInfo={isDisablePausingEnabledWithAutoplay && locale.tooltips.disabledPlayButtons}
                disabled={Boolean(isLoading || isDisablePausingEnabledWithAutoplay)}
            />
            <SwitchForm
                sx={style.container}
                name={UI_CONTROL_BAR_PLAY_SHOW}
                label={<Typography variant="body2">{locale.controls.smallPlayButton}</Typography>}
                {...commonProps}
                tooltipPlacement={SwitchTooltipPlacement.inside}
                helpInfo={isDisablePausingEnabledWithAutoplay && locale.tooltips.disabledPlayButtons}
                disabled={Boolean(isLoading || isDisablePausingEnabledWithAutoplay)}
            />
            <Switch
                sx={style.container}
                checked={values[UI_CONTROL_BAR_SPEED_CONTROL_SHOW] || values[UI_CONTROL_BAR_QUALITY_CONTROL_SHOW]}
                onChange={(_, checked) => {
                    setValues({
                        ...values,
                        [UI_CONTROL_BAR_SPEED_CONTROL_SHOW]: checked,
                        [UI_CONTROL_BAR_QUALITY_CONTROL_SHOW]: checked,
                    })
                    onChange()
                }}
                label={<Typography variant="body2">{locale.controls.settings}</Typography>}
                disabled={isLoading}
                switchProps={{ size: 'small' }}
            />
            <Stack sx={style.stack}>
                <SwitchForm
                    sx={style.container}
                    name={UI_CONTROL_BAR_QUALITY_CONTROL_SHOW}
                    label={<Typography variant="body2">{locale.controls.quality}</Typography>}
                    {...commonProps}
                />
                <SwitchForm
                    sx={style.container}
                    name={UI_CONTROL_BAR_SPEED_CONTROL_SHOW}
                    label={<Typography variant="body2">{locale.controls.speed}</Typography>}
                    {...commonProps}
                />
            </Stack>
            <SwitchForm
                sx={style.container}
                name={UI_CONTROL_BAR_VOLUME_SHOW}
                label={<Typography variant="body2">{locale.controls.volume}</Typography>}
                {...commonProps}
            />
            {!isSmartVidGlobalSettings && (
                <SwitchForm
                    sx={style.container}
                    name={UI_CONTROL_BAR_FULLSCREEN_SHOW}
                    label={
                        <Typography variant="body2" {...(isLoading ? { color: 'text.disabled' } : {})}>
                            {locale.controls.fullscreen}
                        </Typography>
                    }
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    helpInfo={video?.funnel && locale.tooltips.fullscreen}
                    {...commonProps}
                    onChange={(name, checked) => {
                        setValues((prevValues) => {
                            return {
                                ...prevValues,
                                [name]: checked,
                                ...(!focusedFullscreenSection
                                    ? {
                                          [UI_CONTROL_BAR_FULLSCREEN_CUSTOM_DEFAULT]: false,
                                          [UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MOBILE]: false,
                                      }
                                    : {}),
                            }
                        })

                        onChange()
                    }}
                    disabled={Boolean(isLoading)}
                />
            )}
            {!focusedFullscreenSection && (isSmartVidGlobalSettings || values[UI_CONTROL_BAR_FULLSCREEN_SHOW]) && (
                <Stack sx={[isSmartVidGlobalSettings ? { gap: 1 } : style.stack]}>
                    <SwitchForm
                        sx={style.container}
                        tooltipPlacement={SwitchTooltipPlacement.inside}
                        name={UI_CONTROL_BAR_FULLSCREEN_CUSTOM_DEFAULT}
                        label={
                            <Typography
                                variant="body2"
                                {...(isLoading || (isSmartVidGlobalSettings ? false : video?.funnel)
                                    ? { color: 'text.disabled' }
                                    : {})}
                            >
                                {locale.controls.focusedFullscreenDesktop}
                            </Typography>
                        }
                        {...commonProps}
                        helpInfo={
                            <>
                                {locale.tooltips.focusedFullscreenDesktop}{' '}
                                <Link to={PLAYER_DOCS_FOCUSED_FULLSCREEN_LINK} target="_blank" external>
                                    Learn More
                                </Link>
                            </>
                        }
                        onChange={handleFocusedFullscreenChange}
                        disabled={Boolean(isLoading || (isSmartVidGlobalSettings ? false : video?.funnel))}
                    />

                    <SwitchForm
                        name={UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MOBILE}
                        tooltipPlacement={SwitchTooltipPlacement.inside}
                        sx={style.container}
                        label={
                            <Box sx={style.labelWrapper}>
                                <Typography variant="body2" {...(isLoading ? { color: 'text.disabled' } : {})}>
                                    {locale.controls.focusedFullscreenMobile}
                                </Typography>
                            </Box>
                        }
                        helpInfo={
                            <>
                                {isSmartVidGlobalSettings
                                    ? locale.tooltips.svFocusedFullscreenMobile
                                    : locale.tooltips.focusedFullscreenMobile}{' '}
                                <br />
                                <Link to={PLAYER_DOCS_FOCUSED_FULLSCREEN_LINK} target="_blank" external>
                                    Learn More
                                </Link>
                            </>
                        }
                        {...commonProps}
                        onChange={handleFocusedFullscreenChange}
                        disabled={Boolean(isLoading)}
                    />
                </Stack>
            )}
            <SwitchForm
                sx={style.container}
                name={UI_CONTROL_BAR_REWIND_SHOW}
                label={<Typography variant="body2">{locale.controls.rewinds}</Typography>}
                tooltipPlacement={SwitchTooltipPlacement.inside}
                helpInfo={locale.tooltips.rewinds}
                labelPlacement="start"
                {...commonProps}
            />
            <SwitchForm
                sx={style.container}
                name={UI_OVERLAY_PLAY_SEEK_BUTTONS_SHOW}
                label={<Typography variant="body2">{locale.controls.rewindsFast}</Typography>}
                labelPlacement="start"
                tooltipPlacement={SwitchTooltipPlacement.inside}
                helpInfo={locale.tooltips.rewindsFast}
                {...commonProps}
            />
            <SwitchForm
                sx={style.container}
                name={PLAYBACK_SKIP_AND_REWIND_WITH_KEYBOARD}
                label={<Typography variant="body2">{locale.controls.rewindsSkip}</Typography>}
                labelPlacement="start"
                tooltipPlacement={SwitchTooltipPlacement.inside}
                helpInfo={locale.tooltips.rewindsSkip}
                {...commonProps}
            />
            {accessibilityTooltips && (
                <SwitchForm
                    sx={style.container}
                    name={UI_CONTROL_BAR_ACCESSIBILITY_TOOLTIPS}
                    label={<Typography variant="body2">{locale.controls.accessibilityTooltips}</Typography>}
                    labelPlacement="start"
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    helpInfo={locale.tooltips.accessibilityTooltips}
                    {...commonProps}
                />
            )}
        </Section>
    )
}

export default withErrorBoundary(Controls)
