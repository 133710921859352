import { SxProps, Theme } from '@mui/material'

const card: SxProps<Theme> = {
    backgroundColor: '#f3f7ff',
    border: `1px solid #c6d7fe`,
    '& > div:nth-child(2n)': {
        backgroundColor: '#e7eeff',
    },
}

const title: SxProps<Theme> = (theme) => ({
    color: theme.palette.text.primary,
})

export default {
    card,
    title,
}
