import { useQuery } from 'react-query'
import { Cookies } from 'react-cookie'
import { AxiosError } from 'axios'
import { set as _set } from 'lodash'

import { RefreshTokenPayload } from 'types/Auth'
import { authAPI } from 'api/controllers'
import chooseAuthCookie from 'utils/cookie/chooseAuthCookie'
import { REFRESH_TOKEN_QUERY } from 'api/constants'
import { ACCESS_TOKEN, REFRESH_TOKEN, COOKIES_OPTIONS_AUTH } from 'constants/cookies.constants'

export const useRefreshTokenQuery = () => {
    const cookies = new Cookies()
    const token = chooseAuthCookie(REFRESH_TOKEN)

    const config = {}
    _set(config, 'headers.AuthorizationOverride', `Bearer ${token}`)

    const response = useQuery<RefreshTokenPayload, AxiosError>(
        REFRESH_TOKEN_QUERY,
        () => authAPI.refreshToken(config),
        { enabled: false },
    )

    if (response.data?.access && response.data?.refresh) {
        cookies.set(ACCESS_TOKEN, response.data?.access, COOKIES_OPTIONS_AUTH)
        cookies.set(REFRESH_TOKEN, response.data?.refresh, COOKIES_OPTIONS_AUTH)
    }

    return response
}
