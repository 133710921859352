import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { DELETE_THUMBNAIL_MUTATION, SMART_VID_DETAILS_QUERY, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation, Options } from 'api/hooks'
import { Video, ThumbnailType } from 'types/Video'
import { useActualVideoGuid } from 'hooks/video/useActualVideoGuid'

type Payload = {
    video: Video
    thumbnailType: ThumbnailType
    guid?: string
}

export const useDeleteThumbnailMutation = (options?: Options<null, Payload, string>) => {
    const vidId = useActualVideoGuid()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, Payload, string>(
        DELETE_THUMBNAIL_MUTATION,
        async ({ thumbnailType, guid }: Payload) => {
            if (!vidId) {
                return
            }

            if (guid) {
                return mutate(
                    settingsAPI.deleteThumbnail(vidId, thumbnailType, guid),
                    apiLocale.settings.thumbnail.delete,
                )
            } else {
                return mutate(settingsAPI.deleteThumbnail(vidId, thumbnailType), apiLocale.settings.thumbnail.delete)
            }
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY, SMART_VID_DETAILS_QUERY],
        },
    )
}
