import HiveRoundedIcon from '@mui/icons-material/HiveRounded'
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded'
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded'
import { KeyValuePair } from 'types'
import { PlayerBlockView } from 'design/pages/VidStats/types'

export enum MetricKey {
    heatmap = 'heatmap',
    heatmapSkips = 'heatmapSkips',
    heatmapRewinds = 'heatmapRewinds',
    audience = 'audience',
    conversions = 'conversions',
    engagement = 'engagement',
    fullScreen = 'fullScreen',
    volume = 'volume',
    closedCaptions = 'closedCaptions',
    speedUps = 'speedUps',
    pauses = 'pauses',
}

export enum MetricKeysTimeline {
    plays = 'plays',
    playRate = 'playRate',
    impressions = 'impressions',
    playsUnique = 'playsUnique',
    unmuteRate = 'unmuteRate',
    engagement = 'engagement',
    conversionRate = 'conversionRate',
    revenueAverage = 'revenueAverage',
    revenue = 'revenue',
    revenuePerViewer = 'revenuePerViewer',
    bounceRate = 'bounceRate',
    conversionCount = 'conversionCount',
    pgOptInRate = 'pgOptInRate',
    pgOptInCount = 'pgOptInCount',
    unmutePlays = 'unmutePlays',
}

export interface MetricsTimeline {
    [MetricKeysTimeline.plays]?: KeyValuePair<number>
    [MetricKeysTimeline.playRate]?: KeyValuePair<number>
    [MetricKeysTimeline.impressions]?: KeyValuePair<number>
    [MetricKeysTimeline.playsUnique]?: KeyValuePair<number>
    [MetricKeysTimeline.unmuteRate]?: KeyValuePair<number>
    [MetricKeysTimeline.engagement]?: KeyValuePair<number>
    [MetricKeysTimeline.conversionRate]?: KeyValuePair<number>
    [MetricKeysTimeline.revenueAverage]?: KeyValuePair<number>
    [MetricKeysTimeline.revenue]?: KeyValuePair<number>
    [MetricKeysTimeline.revenuePerViewer]?: KeyValuePair<number>
    [MetricKeysTimeline.bounceRate]?: KeyValuePair<number>
    [MetricKeysTimeline.conversionCount]?: KeyValuePair<number>
    [MetricKeysTimeline.pgOptInRate]?: KeyValuePair<number>
    [MetricKeysTimeline.pgOptInCount]?: KeyValuePair<number>
    [MetricKeysTimeline.unmutePlays]?: KeyValuePair<number>
}

export interface SetMetricPayload {
    key: MetricKey
    value: boolean
}

export interface SetMetricTimelinePayload {
    key: MetricKeysTimeline
    value: boolean
}

export interface InitialStateType {
    videoMetrics: {
        [key in MetricKey]: boolean
    }
    timelineMetrics: {
        [key in MetricKeysTimeline]: boolean
    }
    playerBlockView: PlayerBlockView
}

export const metricsVideo = [
    {
        label: 'Skips Heatmap',
        key: MetricKey.heatmapSkips,
        icon: HiveRoundedIcon,
        disabled: false,
    },
    {
        label: 'Rewinds Heatmap',
        key: MetricKey.heatmapRewinds,
        icon: HiveRoundedIcon,
        disabled: false,
    },
    {
        label: 'Audience',
        key: MetricKey.audience,
        icon: SupervisorAccountRoundedIcon,
        disabled: false,
    },
    {
        label: 'Conversions',
        key: MetricKey.conversions,
        icon: MonetizationOnRoundedIcon,
        disabled: false,
    },
]

export const metricsTimeline = [
    {
        label: 'Plays',
        key: MetricKeysTimeline.plays,
        disabled: false,
        type: 'number',
    },
    {
        label: 'Play Rate',
        key: MetricKeysTimeline.playRate,
        disabled: false,
        type: 'percentage',
    },
    {
        label: 'Unique Viewers',
        key: MetricKeysTimeline.playsUnique,
        disabled: false,
        type: 'number',
    },
    {
        label: 'Avg % Watched',
        key: MetricKeysTimeline.engagement,
        disabled: false,
        type: 'percentage',
    },
    {
        label: 'Impressions',
        key: MetricKeysTimeline.impressions,
        disabled: false,
        type: 'number',
    },
    {
        label: 'Unmute Rate',
        key: MetricKeysTimeline.unmuteRate,
        disabled: false,
        type: 'percentage',
    },
    {
        label: 'Average Order Value',
        key: MetricKeysTimeline.revenueAverage,
        disabled: false,
        type: 'currency',
    },
    {
        label: 'Revenue',
        key: MetricKeysTimeline.revenue,
        disabled: false,
        type: 'currency',
    },
    {
        label: 'Conversions',
        key: MetricKeysTimeline.conversionCount,
        disabled: false,
        type: 'number',
    },
    {
        label: 'Opt-Ins',
        key: MetricKeysTimeline.pgOptInCount,
        disabled: false,
        type: 'number',
    },
    {
        label: 'Opt-In Rate',
        key: MetricKeysTimeline.pgOptInRate,
        disabled: false,
        type: 'percentage',
    },
    {
        label: 'Conversions Rate',
        key: MetricKeysTimeline.conversionRate,
        disabled: false,
        type: 'percentage',
    },
    {
        label: 'Revenue per Viewer',
        key: MetricKeysTimeline.revenuePerViewer,
        disabled: false,
        type: 'currency',
    },
    {
        label: 'Bounce Rate',
        key: MetricKeysTimeline.bounceRate,
        disabled: false,
        type: 'percentage',
    },
    {
        label: 'Unmute Plays',
        key: MetricKeysTimeline.unmutePlays,
        disabled: false,
        type: 'number',
    },
]

export type MetricsTimelineType = {
    disabled: boolean
    key: MetricKeysTimeline
    label: string
    type: string
}
