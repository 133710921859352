export default {
    bandwidthUsage: 'Bandwidth',
    playsUsage: 'Plays',
    extraBandwidth: 'Extra Bandwidth',
    extraPlays: 'Extra Plays',
    videoStorage: 'Video Storage',
    extraVideos: 'Extra Videos',
    notAvailable: 'Not Available',
    learnMore: 'Learn More',
    amountOfPlays: (amount: string, period = 'month') =>
        `The number of times your videos have been played. Your plan includes ${amount} plays per ${period}.`,
    amountOfBandwidth: (amount: string, dimension = 'GB', period = 'month') =>
        `The amount of data used when your videos play. Your plan includes ${amount} ${dimension} per ${period}.`,
    additionalDataUsage: (cost: string) =>
        `Additional data usage beyond your plan's limit. Extra bandwidth is charged at ${cost} per month.`,
    numberOfVideosStored: (count: string) =>
        `The total number of videos stored in your account. Your plan includes free storage for up to ${count} videos.`,
    additionalVideosStored: (cost: string) =>
        `Additional videos stored beyond your plan's limit. Extra videos are charged at $${cost}/video per month.`,
}
