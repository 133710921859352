import route from 'constants/routes'

export enum FeatureGroup {
    Integrations = 'integrations',
    VidSettings = 'vidSettings',
    Conversions = 'conversions',
    SmartVids = 'smartVids',
    GlobalSettings = 'globalSettings',
    Intercom = 'intercom',
    VidStats = 'vidStats',
    VidStatsTimeline = 'vidStatsTimeline',
    VidSummary = 'vidSummary',
    VidStatsGraph = 'vidStatsGraph',
    Sidebar = 'sidebar',
    AccountUsersLimit = 'accountUsersLimit',
    UserSettings = 'userSettings',
    AnalyticsHome = 'analyticsHome',
}

// not all keys needed
// only these features are configured for tiers
export enum IntegrationKey {
    GoogleAndGtm = 'googleAndGtm',
    Facebook = 'facebook',
}

export enum VidSettingsKey {
    GenerateAICaptions = 'generatAICaptions',
    VidConditions = 'vidConditions',
    VideoThumbnails = 'videoThumbnails',
    ReVisitSeekingBar = 'reVisitSeekingBar',
    RapidEngageBar = 'rapidEngageBar',
    ExitThumbnail = 'exitThumbnail',
    ResumePlay = 'resumePlay',
    SmartPause = 'smartPause',
    ExpireVideo = 'expireVideo',
    PlayGates = 'playGates',
    Tags = 'tags',
    CountdownTimerRedirection = 'countdownTimerRedirection',
    CustomHtml = 'customHtml',
    Chapters = 'chapters',
}

export enum GlobalSettingsKey {
    PlayGate = 'playGate',
    PlayGateEmailValidation = 'playGateEmailValidation',
    PublicAPI = 'publicAPI',
    DomainWhitelisting = 'domainWhitelisting',
}

export enum VidStatsKey {
    StatsSegments = 'statsSegments',
    CompareVideos = 'compareVideos',
    DownloadStats = 'downloadStats',
}

export const SidebarRoute = {
    SmartVids: route.smartVideo.catalog,
    Conversions: route.conversion,
    Integrations: route.integrations.index,
} as const

export enum AnalyticsHomeKeys {
    filtering = 'filtering',
}

export type SidebarRouteKey = (typeof SidebarRoute)[keyof typeof SidebarRoute]
