import { SxProps } from '@mui/material'

const checkmark: SxProps = {
    width: 24,
    height: 24,
}

const button: SxProps = {
    width: 'fit-content',
}

export default {
    checkmark,
    button,
}
