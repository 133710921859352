import { useQuery, UseQueryOptions } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_CONVERSIONS_ROW_DATA } from 'api/constants'
import {
    GetConversionsRowDataQuery,
    GetConversionsRowDataResponse,
} from 'api/contracts/dashboard/endpoints/getConversionsRowData.contract'

export const useGetConversionsRowDataQuery = (
    query: GetConversionsRowDataQuery,
    options?: UseQueryOptions<GetConversionsRowDataResponse>,
) =>
    useQuery<GetConversionsRowDataResponse>(
        [DASHBOARD_GET_CONVERSIONS_ROW_DATA, query],
        () => dashboardController.getConversionsRowData(query),
        options,
    )

export default useGetConversionsRowDataQuery
