import _get from 'lodash/get'

import { useCustomerSubscriptionQuery } from 'api/queries'
import { isPremiumOrEnterprisePlan } from 'constants/plan.constants'

const useAccountSubscription = () => {
    const { isLoading, isFetching, data, error } = useCustomerSubscriptionQuery()

    const tier = data?.plan.tier
    const isFree = Boolean(data?.plan.isFree)
    const isPremiumOrEnterprise = data ? isPremiumOrEnterprisePlan(data?.plan) : false

    return {
        data,
        isLoading,
        isFetching,
        isFree,
        isPremiumOrEnterprise,
        isFake: _get(error, 'response.status') === 422 || data?.isFake,
        tier,
        lockedForFreePlan: tier ? isFree : false,
        transition: data?.transition,
        planTransitionRequired: data?.transition?.require && data?.transition.apiHandle,
    }
}

export default useAccountSubscription
