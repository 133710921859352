import { useQuery, UseQueryOptions } from 'react-query'

import { analyticsAPI } from 'api/controllers'
import { METRICS_STATS_QUERY } from 'api/constants'
import { format } from 'date-fns'
import { AnalyticsMetricsData } from 'design/pages/Analytics/types'
import useAnalyticsData from 'design/pages/Analytics/hooks/useAnalyticsData'
import { useAnalyticsFilterParams } from 'api/hooks/useAnalyticsFilterParams'

export const useMetricsStatisticsQuery = (
    videoGuids: string,
    options?: UseQueryOptions<AnalyticsMetricsData, unknown, AnalyticsMetricsData>,
) => {
    const { selectedInterval, selectedMetric, dates } = useAnalyticsData()
    const filterStringParams = useAnalyticsFilterParams()

    const dateFrom = format(dates[0], 'yyyy-MM-dd')
    const dateTo = format(dates[1], 'yyyy-MM-dd')

    const params = [
        `dateFrom=${dateFrom}`,
        `dateTo=${dateTo}`,
        `interval=${selectedInterval?.key}`,
        `metric=metric.${selectedMetric?.key}`,
        filterStringParams,
        `videos=${videoGuids}`,
    ]
        .filter(Boolean)
        .join('&')

    return useQuery<AnalyticsMetricsData, unknown, AnalyticsMetricsData>(
        [METRICS_STATS_QUERY, params],
        () => analyticsAPI.getMetricsStats(params),
        {
            enabled: !!videoGuids,
            ...options,
        },
    )
}
