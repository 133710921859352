export enum TagType {
    time = 'time',
    interactive = 'interactive',
    callToAction = 'callToAction',
    percentage = 'percentage',
}

export interface TagConfig {
    customVariables: []
    guid?: string
    hubspotContactListId: null
    name: string
    time: string
    type: TagType
}

export interface SmartVidTag {
    guid?: string
    name: string
    time: string | null
    percentage: number | null
    type: TagType
}

export interface SmartVidTagsConfig {
    tags: SmartVidTag[]
    customVariables: string[]
}
