import {
    buttonBaseClasses,
    checkboxClasses,
    selectClasses,
    SxProps,
    tablePaginationClasses,
    Theme,
} from '@mui/material'
import { gridClasses } from '@mui/x-data-grid-pro'

const container: SxProps<Theme> = {
    position: 'relative',
    flex: 1,
    mb: 4,
    maxHeight: 1,
}

const dataGrid =
    (disableHeader: boolean): SxProps<Theme> =>
    (theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,

        [`&:has(.${gridClasses.overlayWrapper})`]: {
            [`& .${gridClasses.columnHeaderTitleContainer}`]: {
                color: disableHeader ? theme.palette.text.disabled : theme.palette.text.primary,
            },
            [`& .${gridClasses.columnHeader}`]: {
                pointerEvents: disableHeader ? 'none' : 'auto',
            },
        },

        [`& .${buttonBaseClasses.root}.${checkboxClasses.root}`]: {
            m: 0,
        },

        [`& .${gridClasses.actionsCell}`]: {
            alignItems: 'end',
            width: 1,
            [`& .${buttonBaseClasses.root}:is(:not([aria-label="More"]))`]: {
                opacity: 0,
            },
        },

        [`& .${gridClasses.row}`]: {
            '&:hover': {
                [`.${gridClasses.actionsCell}`]: {
                    [`& .${buttonBaseClasses.root}`]: {
                        opacity: 1,
                    },
                },
            },
        },

        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
        },

        [`& .${tablePaginationClasses.root} .${tablePaginationClasses.input}`]: {
            display: 'block',

            [`& .${tablePaginationClasses.selectIcon}`]: {
                right: -12,
            },
        },

        [`& .${tablePaginationClasses.root} .${tablePaginationClasses.select}`]: {
            textAlign: 'left',
            textAlignLast: 'left',
            padding: 2,
            marginRight: 2,
        },

        [`& .${tablePaginationClasses.root} .${tablePaginationClasses.displayedRows}`]: {
            minWidth: 109,
            textAlign: 'center',
            marginRight: 2,
        },

        [`& .${selectClasses.root}`]: {
            minWidth: 72,
            marginLeft: 2,
            marginRight: 2,
        },

        [`& .${tablePaginationClasses.root} .${tablePaginationClasses.actions}`]: {
            marginLeft: 2,
            marginRight: 2,
            minWidth: 72,
            textAlign: 'center',
        },
    })

export default {
    container,
    dataGrid,
}
