import { DATE_CREATED, DOMAIN, GUID } from 'design/organisms/Account/AccountGlobalSettings/DomainWhitelisting/constants'
import { IP_ADDRESS, NAME, IP_FILTER } from 'design/organisms/Account/AccountGlobalSettings/IpFiltering/constants'
import {
    ID,
    NUMBER,
    DATE_CREATED as INVOICE_DATE_CREATED,
    STATUS,
    AMOUNT_IN_CENTS,
} from 'design/pages/Integrations/constants'
import { EXPIRATION_MONTH, EXPIRATION_YEAR } from 'design/organisms/SignUp/constants'
import { CouponsDetails, CreditCard, PlanPayload } from 'types/Auth'
import { PASSWORD, NEW_PASSWORD, EMAIL } from 'constants/validations/user.constants'

export interface UpsellBanner {
    plan: string
    components: number[]
    description: string
    id: string
}

export type FeatureFlags = {
    [key: string]: boolean
} & {
    upsell_banner?: UpsellBanner | []
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Plan extends PlanPayload {}

export type Customer = {
    businessName: string // if businessName !== null - this is agency
    firstname: string
    lastname: string
    email: string
    guid: string
    dateCreated: string
    status: CustomerStatus
    hasUploadedVideo: boolean
    featureFlags: FeatureFlags
    loginCount: number
    isSubUser: boolean
    conversionsCount: number
    isBetaTester: boolean
    integrations: {
        vimeo: boolean
        wistia: boolean
        youtube: boolean
    }
    userHashIntercom?: string
    uiTheme: 'auto' | 'dark' | 'light'
}

export interface CustomerSubscription {
    creditCard: CreditCard | null
    dateNextBilling: string
    daysTillPeriodEnds: number
    bandwidthLimit: string
    bandwidthLimitInMB?: number
    bandwidthOverage: string
    bandwidthOverageInMB?: number
    bandwidthUsed: string
    bandwidthUsedInMB?: number
    plan: Plan
    pricePoint?: string
    planNext: Plan
    playLimit: number | null
    playOverage: number | null
    playUsed: number | null
    referredFriends: number
    totalCost: number
    videoSpaceLimit: number
    videoSpaceOverage: number
    videoSpaceUsed: number
    status?: CustomerStatus
    transition?: PlanTransition
    chargifyId: number
    isFake?: boolean
    currentPeriodEndsAt?: string
    coupons?: {
        totalDiscount: string
        couponsDetails: Array<CouponsDetails>
    }
}

export interface PlanTransition {
    require: boolean
    apiHandle: ApiHandle
    pricePoint: string | null
}

export enum CustomerStatus {
    active = 'active',
    inactive = 'inactive',
    trial = 'trial',
}

export enum CustomerSubscriptionPlanTier {
    agency = 'agency',
    free = 'free',
    pro = 'pro',
    starter = 'starter',
    pro2021 = 'pro-2021',
    proIv = 'pro-iv',
    proNc = 'pro-nc',
    proSvPlays = 'pro-sv-plays',
    premium = 'premium',
    premiumNc = 'premium-nc',
    enterprise = 'enterprise',
    enterprisePlays = 'enterprise-plays',
    unreachable = 'unreachable',
}

export enum SubscriptionCategory {
    free = 'free',
    starter = 'starter',
    pro = 'pro',
    premium = 'premium',
    enterprise = 'enterprise',
}

export enum BillingMode {
    bandWidth = 'bandwidth',
    play = 'play',
}

export enum PlanCollapse {
    plans = 'plans',
    creditCard = 'creditCard',
}

export enum TierMode {
    free = 'free',
    paid = 'paid',
}

export enum PlanName {
    free = 'Free',
    pro = 'Pro',
    premium = 'Premium',
    proIv = 'Pro Smart Vids',
    vip = 'Vip',
    agency = 'Agency',
    enterprise = 'Enterprise',
}

export enum ApiHandle {
    Free = 'free-2021',
    Pro = 'pro-2021',
    Premium = 'premium',
    SmartVsl = 'smart-vsl',
    Ent1Tb = 'ent-1tb',
    Ent2Tb = 'ent-2tb',
    Pro3Tb = 'pro-3tb',
    ProNc = 'pro-nc',
    PremiumNc = 'premium-nc',
    ProIv = 'pro-ivj23',

    Enterprise = 'enterprise', // for hardcoded plan

    // plays based
    FreePlays = 'free-plays',
    StarterPlays = 'starter-plays',
    ProPlays = 'pro-plays',
    PremiumPlays = 'premium-plays',
    PremiumPlaysTierUpgrade = 'premium-plays-tier-upgrade',

    // exlusive
    Unlimited = 'unlimited',
}

export enum ExtraPlaysUnit {
    view = 'view',
}

export type ActivateCustomerPlanPayload = {
    apiHandle: ApiHandle
    creditCard?: CreditCard
    pricePoint?: string | null
    addons?: string | Array<number> | null
    creditCardToken?: string
}

export type PlanProratePayload = {
    planHandle?: ApiHandle
    pricePoint?: string
}

export type PlanProrate = {
    migration: {
        prorated_adjustment_in_cents: number
        charge_in_cents: number
        payment_due_in_cents: number
        credit_applied_in_cents: number
        hasTrial: boolean
    }
} | null

export type UserApiToken = {
    value: string
    dateCreate: string
}

export interface Permission {
    name: string
    title: string
}

export interface PermissionsObject {
    [key: number]: Permission
}

export type PermissionMap = Map<Permission['name'], Permission['title']>

export type CreditCardWithStringDates = Omit<CreditCard, 'expirationYear' | 'expirationMonth'> & {
    [EXPIRATION_YEAR]: string
    [EXPIRATION_MONTH]: string
}

export interface UpdateCardInfoByTokenPayload {
    token?: string
}

export interface UpdateCustomerPayload {
    /**
     * @deprecated
     */
    businessName?: string | null
    /**
     * @deprecated
     */
    firstName: string
    /**
     * @deprecated
     */
    lastName: string
    /**
     * @deprecated
     */
    [EMAIL]?: string
    [PASSWORD]?: string
    [NEW_PASSWORD]?: string
}

export interface WhitelistedDomain {
    [GUID]: string
    [DATE_CREATED]: string
    [DOMAIN]: string
}

export interface CreateWhitelistedDomainPayload {
    [DOMAIN]: Pick<WhitelistedDomain, 'domain'>
}

export interface UpdateWhitelistedDomainPayload {
    [DOMAIN]: WhitelistedDomain
}

export interface IpFilter {
    [NAME]: string
    [IP_ADDRESS]: string
    [GUID]: string
    [DATE_CREATED]: string
}

export interface CreateIpFilterPayload {
    [IP_FILTER]: Pick<IpFilter, 'name' | 'ipAddress'>
}

export interface UpdateIpFilterPayload {
    [IP_FILTER]: IpFilter
}

export interface Invoice {
    [ID]: string
    [NUMBER]: string
    [INVOICE_DATE_CREATED]: string
    [STATUS]: string
    [AMOUNT_IN_CENTS]: string
}

export interface GlobalEmbedScript {
    embed: string
}

export interface UpsellShownPayload {
    upsellName: string
}

export interface GlobalSettings {
    playGate?: boolean
    passUrlParamsFromAllPages?: boolean
    playGateValidationEnabled?: boolean
}
