import { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react'
import { matchRoutes, Outlet, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import {
    Box,
    Divider,
    Drawer,
    Fade,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Stack,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import mixpanel from 'mixpanel-browser'

import { useCustomerQuery } from 'api/queries'
import { Link } from 'design/atoms/Link'
import { Logo } from 'design/atoms/Logo'
import { ToggleThemeButton } from 'design/atoms/ToggleThemButton'
import { setIsSidebarOpened } from 'design/organisms/Account/account.slice'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import useLogout from 'hooks/user/useLogout'
import { useLayout } from 'hooks/utilities/useLayout'
import { useSidebarItems } from 'hooks/utilities/useSidebarItems'
import { APPCUES_EVENTS, trackAppCuesEvent } from 'thirdPartyServices/appCues'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import useUploadLimit from 'hooks/user/useUploadLimit'
import { defaultStatsRouteParams, route, SmartVidStepRouteBuilder, StatsEntity } from 'constants/routes'
import { UpgradeCtaButton } from './components/UpgradeCtaButton'
import { UploadButton } from './components/UploadButton'
import { UserActions } from './components/UserActions/UserActions'

import { ADDITIONAL_MOBILE_SIDEBAR_ITEMS, IS_GLOBAL_SIDEBAR_OPENED } from './Sidebar.contstants'
import { style } from './Sidebar.style'
import { SidebarItem } from './SidebarItem'

export function Sidebar() {
    const { isMobile } = useLayout()
    const logout = useLogout()
    const [mobileOpen, setMobileOpen] = useState(false)
    const [open, setOpen] = useState(localStorage.getItem(IS_GLOBAL_SIDEBAR_OPENED) === 'true')
    const dispatch = useDispatch()
    const location = useLocation()
    const { pathname } = location
    const { data: customer } = useCustomerQuery()
    const outletContainerRef = useRef<HTMLDivElement>(null)
    const { showDarkLightModeToggle, createNewSV } = useFeatureFlags()
    const { isFirstUpload } = useUploadLimit()
    const { items: sideBarItems } = useSidebarItems(undefined)

    const ROUTES_TO_HIDE_SIDEBAR = useMemo(
        () =>
            createNewSV
                ? [
                      route.smartVideo.byId(),
                      route.smartVideo.step(),
                      route.smartVideo.step(undefined, SmartVidStepRouteBuilder.overlay),
                      route.stats.detailed({ ...defaultStatsRouteParams, entity: StatsEntity.smartVid }),
                  ]?.map((path) => ({ path }))
                : [],
        [createNewSV],
    )

    const isHidden = useMemo(
        () =>
            matchRoutes([{ path: route.smartVideo.underConstruction }], location)
                ? false
                : matchRoutes(ROUTES_TO_HIDE_SIDEBAR, location),
        [location],
    )

    useEffect(() => {
        outletContainerRef.current?.scrollIntoView?.({ behavior: 'smooth', block: 'start' })
    }, [location])

    const handleDrawerOpen: MouseEventHandler<SVGSVGElement> = (event) => {
        event.preventDefault()
        setOpen(true)
        localStorage.setItem(IS_GLOBAL_SIDEBAR_OPENED, 'true')
        dispatch(setIsSidebarOpened(true))
    }

    const handleDrawerClose: MouseEventHandler<SVGSVGElement> = (event) => {
        event.preventDefault()
        setOpen(false)
        localStorage.setItem(IS_GLOBAL_SIDEBAR_OPENED, 'false')
        dispatch(setIsSidebarOpened(false))
    }

    const handleUploadLinkClick = () => {
        trackAppCuesEvent(APPCUES_EVENTS.MAIN_NAVBAR_UPLOAD_BTN_CLK)
        mixpanel.track(MIXPANEL_EVENTS.SIDEBAR_UPLOAD_CLICK, { user_id: customer?.guid })
    }

    const handleCloseMobile = () => setMobileOpen(false)

    const renderSidebarItems = () =>
        sideBarItems.map((item, index) => (
            <SidebarItem
                {...item}
                key={item.route}
                open={isMobile ? true : open}
                withOpenIcon={index === 0 && !isMobile}
                handleOpenIconClick={open ? handleDrawerClose : handleDrawerOpen}
                pathname={pathname}
                isMobile={isMobile}
                mobileClose={handleCloseMobile}
            />
        ))

    useEffect(() => {
        if (location.pathname.includes('stats')) {
            setOpen(false)
            localStorage.setItem(IS_GLOBAL_SIDEBAR_OPENED, 'false')
            dispatch(setIsSidebarOpened(false))
        }
    }, [])

    if (isFirstUpload) {
        return (
            <Stack direction="column">
                <Box sx={style.firstUploadSidebar}>
                    <Logo sx={{ width: isMobile ? 160 : 200 }} showSign />
                </Box>
                <Box sx={style.outletContainer} ref={outletContainerRef}>
                    <Outlet />
                </Box>
            </Stack>
        )
    }

    if (isMobile) {
        return (
            <Stack direction="column" sx={[mobileOpen && { overflow: 'hidden' }]}>
                <Box sx={[isHidden && style.mobile.drawer.hidden]}>
                    <Box sx={style.mobile.container}>
                        <Link to={route.dashboard} sx={style.logoLink} onClick={handleCloseMobile}>
                            <Logo showSign sx={{ width: 160 }} />
                        </Link>
                        <IconButton onClick={() => setMobileOpen(!mobileOpen)} color="tertiary">
                            {mobileOpen ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                    </Box>

                    <Drawer
                        sx={[
                            style.mobile.drawer.idle,
                            mobileOpen ? style.mobile.drawer.open : style.mobile.drawer.close,
                        ]}
                        variant="permanent"
                        open={mobileOpen}
                    >
                        <List sx={style.list}>{renderSidebarItems()}</List>
                        <Stack gap={3} py={3} px={5}>
                            <UploadButton
                                onClick={() => {
                                    handleUploadLinkClick()
                                    handleCloseMobile()
                                }}
                                open
                            />
                            <Box onClick={handleCloseMobile}>
                                <UpgradeCtaButton open />
                            </Box>
                        </Stack>
                        <List sx={[style.list, style.mobile.additionalList]}>
                            {ADDITIONAL_MOBILE_SIDEBAR_ITEMS.map((item) => (
                                <SidebarItem
                                    {...item}
                                    key={item.route}
                                    open
                                    pathname={pathname}
                                    isMobile
                                    mobileClose={handleCloseMobile}
                                />
                            ))}
                            <Divider />
                            <ListItem disablePadding>
                                <ListItemButton sx={style.listItem.button}>
                                    <ListItemIcon sx={style.listItem.icon}>
                                        <AccountCircleRoundedIcon />
                                    </ListItemIcon>
                                    {customer ? (
                                        <ListItemText
                                            primary={`${customer.firstname} ${customer.lastname}`}
                                            primaryTypographyProps={{ color: 'text.primary', variant: 'body1' }}
                                        />
                                    ) : (
                                        <Skeleton width={120} height={24} animation="wave" />
                                    )}
                                    <ListItemIcon sx={[style.listItem.icon, { mr: 2 }]}>
                                        <LogoutIcon onClick={logout} />
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Drawer>
                </Box>
                <Box sx={style.outletContainer} ref={outletContainerRef}>
                    <Outlet />
                </Box>
            </Stack>
        )
    }

    return (
        <Stack direction="row">
            <Fade in={true} easing="ease-in">
                <Drawer
                    sx={[
                        style.drawer.idle,
                        open ? style.drawer.open : style.drawer.close,
                        isHidden && style.drawer.hidden,
                    ]}
                    variant="permanent"
                    open={open}
                >
                    <Link to={route.dashboard} sx={style.logoLink}>
                        <Logo showSign={open} sx={{ width: 200 }} />
                    </Link>
                    <List sx={style.list}>{renderSidebarItems()}</List>
                    <Stack gap={3} py={3} px={open ? 5 : 3}>
                        <UploadButton onClick={handleUploadLinkClick} open={open} />
                        <UpgradeCtaButton open={open} />
                        {open && showDarkLightModeToggle && <ToggleThemeButton />}
                    </Stack>
                    <UserActions open={open} />
                </Drawer>
            </Fade>
            <Box sx={style.outletContainer} ref={outletContainerRef}>
                <Outlet />
            </Box>
        </Stack>
    )
}

export default Sidebar
