import ClosedCaptionRoundedIcon from '@mui/icons-material/ClosedCaptionRounded'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import PaletteRoundedIcon from '@mui/icons-material/PaletteRounded'
import ControlCameraRoundedIcon from '@mui/icons-material/ControlCameraRounded'
import { SvgIcon } from '@mui/material'

import useFeatureFlags from 'hooks/system/useFeatureFlags'
import PlayerThemeSection from 'design/organisms/VidSettingsSidebar/StyleSettings/PlayerThemeSection'
import { VidSettingsKey } from 'constants/keys/featuresAccess.constants'

import ChaptersSection from './ChaptersSection'
import ClosedCaptionsSection from './ClosedCaptionsSection'
import ControlsSection from './ControlsSection'
import FocusedFullscreenSection from './FocusedFullscreenSection'
import ThumbnailsSection from './ThumbnailsSection'

export type VidSettingsMenuItem = {
    label: string
    Icon: typeof SvgIcon
    Children: React.ComponentType
    isLocked?: boolean
    isNew?: boolean
}

const useVidSettingsMenu = () => {
    const { chaptersEnabled, focusedFullscreenSection, playerThemesSettings } = useFeatureFlags()

    return [
        playerThemesSettings && { label: 'Player Theme', Icon: PaletteRoundedIcon, Children: PlayerThemeSection },
        { label: 'Controls', Icon: ControlCameraRoundedIcon, Children: ControlsSection },
        focusedFullscreenSection && {
            label: 'Focused Fullscreen',
            Icon: FullscreenRoundedIcon,
            Children: FocusedFullscreenSection,
        },
        { label: 'Thumbnails', Icon: ImageRoundedIcon, Children: ThumbnailsSection },
        { label: 'Closed Captions', Icon: ClosedCaptionRoundedIcon, Children: ClosedCaptionsSection },
        chaptersEnabled && {
            label: 'Chapters',
            Icon: FormatListBulletedIcon,
            Children: ChaptersSection,
            featureKey: VidSettingsKey.Chapters,
            isNew: true,
        },
    ].filter(Boolean) as VidSettingsMenuItem[]
}

export default useVidSettingsMenu
