import { Box, Button, TextField, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { closeModal, Modal, ModalControllerState, selectModalControllerState } from 'design/templates/Modal'
import { Folder } from 'types/Video'

import { useLayout } from 'hooks/utilities/useLayout'
import { useFolderNameChangeMutation } from 'api/mutations'
import { useAppDispatch, useAppSelector } from 'App'
import { useUpdateVideoNameMutation } from 'api/mutations/video/useVideoNameChangeMutation'
import style from './MyVidsRenameFolderOrVideo.style'

type Extension = {
    children: ExpandedFolder[]
}

type ExpandedFolder = Folder & Extension

export type MyVidsRenameFolderOrVideoProps = {
    rowId: string
    rowType: string
    title: string
}

const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
})

export const MyVidsRenameFolderOrVideo = () => {
    const {
        type,
        props: { rowId, title, rowType },
    } = useAppSelector(selectModalControllerState) as ModalControllerState<MyVidsRenameFolderOrVideoProps>

    const { isMobile } = useLayout()
    const folderNameChangeMutation = useFolderNameChangeMutation()
    const videoNameChangeMutation = useUpdateVideoNameMutation()
    const dispatch = useAppDispatch()
    const onClose = () => {
        dispatch(closeModal())
    }
    const isTypeFolder = rowType === 'folder'

    return (
        <Modal
            open={type === 'RenameFolderOrVideo'}
            onClose={onClose}
            aria-labelledby="new-folder"
            aria-describedby="new-folder"
        >
            <Modal.Header onClose={onClose} nodeTitle>
                <Typography color="text.primary" variant="h7" display="flex" alignItems="center" sx={style.title}>
                    Rename
                </Typography>
            </Modal.Header>
            <Formik
                initialValues={{ title }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    isTypeFolder
                        ? folderNameChangeMutation.mutateAsync({
                              folder: {
                                  guid: rowId,
                                  title: values.title,
                              } as Folder,
                          })
                        : videoNameChangeMutation.mutateAsync({
                              id: rowId,
                              title: values.title,
                          })

                    onClose()
                }}
            >
                {({ errors, touched }) => (
                    <Form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        }}
                    >
                        <Modal.Body>
                            <Box sx={(theme) => style.content(theme)}>
                                <Field
                                    as={TextField}
                                    name="title"
                                    placeholder="Untitled"
                                    fullWidth
                                    type="text"
                                    error={touched.title && Boolean(errors.title)}
                                    helperText={touched.title && errors.title}
                                />
                            </Box>
                        </Modal.Body>
                        <Modal.Actions>
                            <Button variant="outlined" onClick={onClose} size={isMobile ? 'small' : 'medium'}>
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit" size={isMobile ? 'small' : 'medium'}>
                                OK
                            </Button>
                        </Modal.Actions>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}
