import { SxProps } from '@mui/material'

const img: SxProps = {
    borderRadius: 4,
    backgroundColor: 'background.paper',
    flex: 2,
    gridArea: '1 / 1 / 2 / 6',
    width: 1,
}

const imgInsideContainer: SxProps = {
    gridArea: '1 / 1 / 2 / 8',
}

export default {
    img,
    imgInsideContainer,
}
