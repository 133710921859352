import { SxProps, Theme } from '@mui/material'

const switcher: SxProps<Theme> = {
    width: 1,
    justifyContent: 'space-between',
    pb: 5,
}

const alert: SxProps<Theme> = {
    '& .MuiAlert-standard': {
        border: '1px solid #EF6C0080',
        borderRadius: '12px',
    },
    fontSize: 'body1.fontSize',
    textWrap: 'wrap',
}

export default {
    switcher,
    alert,
}
