import { SxProps } from '@mui/material'

const menu: SxProps = {
    mt: 2,

    '& > .MuiPaper-root': {
        display: 'flex',
        width: 500,
        height: 660,
        overflow: 'hidden',
    },

    '.MuiList-root': {
        flexGrow: 1,
    },
}

const search: SxProps = {
    py: 4,
    px: 3,
}

export default {
    menu,
    search,
}
