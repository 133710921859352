import { selectUploaderState, setFiles } from 'design/pages/VideoUpload/uploader.slice'

import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { videoAPI } from 'api/controllers'
import { FolderAPI, MoveToFolderPayload } from 'types/Video'
import { useAppDispatch, useAppSelector } from 'App'
import { VIDEO_FOLDERS_QUERY, VIDEO_MOVEMENT_QUERY, VIDEOS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'

export const useMoveToFolderMutation = () => {
    const { files } = useAppSelector(selectUploaderState)
    const dispatch = useAppDispatch()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<FolderAPI[], MoveToFolderPayload[]>(
        VIDEO_MOVEMENT_QUERY,
        async (payload: MoveToFolderPayload[]) => {
            const itemsText = `ideo${payload.length > 1 ? 's' : ''}`

            return mutate(Promise.all(payload.map((p) => videoAPI.moveToFolder(p))), {
                pending: `Moving v${itemsText}`,
                success: `V${itemsText} moved`,
                error: `V${itemsText} movement failed`,
            })
        },
        {
            onSuccess: (data, payload) => {
                if (!files.length) {
                    return
                }

                const videosIds = payload.map((p) => p.itemId)
                const folderId = data[0]?.guid

                dispatch(
                    setFiles((files) =>
                        files.map((f) =>
                            videosIds.some((id) => id === f.state?.video?.guid) && f.state?.video
                                ? {
                                      ...f,
                                      state: {
                                          ...f.state,
                                          video: {
                                              ...f.state.video,
                                              folderGuid: String(folderId),
                                          },
                                      },
                                  }
                                : f,
                        ),
                    ),
                )
            },
            invalidate: [VIDEOS_QUERY, VIDEO_FOLDERS_QUERY],
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
