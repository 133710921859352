import { useCallback, useMemo } from 'react'

import { usePlansQuery } from 'api/queries'
import { CustomerSubscriptionPlanTier } from 'types/Customer'
import { PlanIndexByTier } from 'constants/plan.constants'

import { MOCKED_PLANS } from './SelectPlanStep.constants'

export const usePlans = () => {
    const { data: plans, isLoading: plansLoading } = usePlansQuery()

    const getPlanIndexByTier = useCallback((tier: CustomerSubscriptionPlanTier | undefined) => {
        return PlanIndexByTier[tier || CustomerSubscriptionPlanTier.unreachable]
    }, [])

    return {
        plans: useMemo(
            () => (plans ?? MOCKED_PLANS)?.map((plan) => ({ ...plan, index: getPlanIndexByTier(plan.tier) })),
            [plans, getPlanIndexByTier],
        ),
        plansLoading,
    }
}
