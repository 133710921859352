import { useState, useCallback, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import Skeleton from '@mui/material/Skeleton'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _keys from 'lodash/keys'
import _omit from 'lodash/omit'
import _reduce from 'lodash/reduce'
import _isEqual from 'lodash/isEqual'

import VidConditionsForm from 'design/organisms/VidConditionsOld/VidConditionsForm'
import { useVidConditionsMutation } from 'api/mutations'
import { useVideoQuery } from 'api/queries'
import { VideoGuid, VidConditionTargets, VidConditionSegments } from 'types/Video'

type VidConditionsChangeEvent = {
    changed: boolean
    enabled: boolean
    valid: boolean
    segmentsCount: number
}

type VidConditionsProps = {
    onChange: (event: VidConditionsChangeEvent) => void
    fallback: VideoGuid
    disabled?: boolean
}

let saved: VidConditionSegments | undefined

const VidConditions = ({ onChange, fallback, disabled }: VidConditionsProps) => {
    const [isInitialLoading, setIsInitialLoading] = useState(true)
    const { data, isFetching: isFetchingVideo, refetch } = useVideoQuery(fallback)
    const { mutateAsync, isLoading: isLoadingVidConditions } = useVidConditionsMutation(fallback)

    useEffect(() => {
        if (isInitialLoading && !isFetchingVideo) {
            setIsInitialLoading(false)
            const segmentsCount = _keys(data?.settings?.targeted?.segments).length
            if (segmentsCount) {
                onChange({ changed: false, enabled: true, valid: false, segmentsCount })
            }
        }
    }, [isFetchingVideo])

    useEffect(() => {
        refetch()
        saved = undefined
    }, [])

    const save = useCallback(async (value: VidConditionTargets) => {
        try {
            await mutateAsync(value)
        } catch (e) {
            Sentry.captureException(e)
        }
    }, [])

    const handleChange = (targets: VidConditionTargets, segments: VidConditionSegments, valid: boolean) => {
        if (!saved) {
            saved = _reduce(
                data?.settings?.targeted?.segments,
                (acc, { rules }) => {
                    if (rules) {
                        _set(
                            acc,
                            [_get(rules, ['0', 'videoGuid']), 'rules'],
                            [_omit(rules[0], ['videoGuid', 'conditionId'])],
                        )
                    }

                    return acc
                },
                {} as VidConditionSegments,
            )
        }

        const changed = !isLoadingVidConditions && !_isEqual(segments, saved)

        if (changed) {
            saved = segments
            save(targets)
        }

        onChange({
            changed,
            enabled: !disabled,
            valid: valid || targets?.length === 0,
            segmentsCount: _keys(segments).length,
        })
    }

    return (
        <>
            {isInitialLoading && <Skeleton variant="rectangular" sx={{ width: 1, height: 42, borderRadius: 3 }} />}

            {!isInitialLoading && (
                <VidConditionsForm
                    onChange={handleChange}
                    fallback={fallback}
                    segments={data?.settings?.targeted?.segments}
                    disabled={disabled}
                />
            )}
        </>
    )
}

export default VidConditions
