import { range as _range } from 'lodash'
import { CustomerSubscriptionPlanTier, Plan as PlanType } from 'types/Customer'

/**
 * FYI: key - plan ID, value - plan name
 */
export const Plan = {
    agency: 'Agency',
    free: 'Free',
    premium: 'Premium',
    pro: 'Pro',
    proIv: 'Pro Smart Vids',
    proSvPlays: 'Pro Smart Vids Plays',
    vip: 'Vip',
} as const

export const PlanLevel = {
    free: _range(0, 4),
    starter: _range(5, 9),
    pro: _range(10, 19),
    premium: _range(20, 29),
    enterprise: _range(50, 59),
    unreachable: _range(1000, 2000), // some unreachable value
}

export const PlanIndexByTier = {
    [CustomerSubscriptionPlanTier.free]: 0,
    [CustomerSubscriptionPlanTier.starter]: 5,
    [CustomerSubscriptionPlanTier.pro]: 10,
    [CustomerSubscriptionPlanTier.proNc]: 10,
    [CustomerSubscriptionPlanTier.pro2021]: 10,
    [CustomerSubscriptionPlanTier.proIv]: 11,
    [CustomerSubscriptionPlanTier.proSvPlays]: 11,
    [CustomerSubscriptionPlanTier.premium]: 20,
    [CustomerSubscriptionPlanTier.premiumNc]: 21,
    [CustomerSubscriptionPlanTier.enterprise]: 50,
    [CustomerSubscriptionPlanTier.enterprisePlays]: 51,
    [CustomerSubscriptionPlanTier.agency]: 1000,
    [CustomerSubscriptionPlanTier.unreachable]: 1000,
} as const

export const isFreePlan = (plan: PlanType) => PlanLevel.free.includes(PlanIndexByTier[plan.tier])
export const isStarterPlan = (plan: PlanType) => PlanLevel.starter.includes(PlanIndexByTier[plan.tier])
export const isProPlan = (plan: PlanType) => PlanLevel.pro.includes(PlanIndexByTier[plan.tier])
export const isPremiumPlan = (plan: PlanType) => PlanLevel.premium.includes(PlanIndexByTier[plan.tier])
export const isEnterprisePlan = (plan: PlanType) => plan.tier?.includes(CustomerSubscriptionPlanTier.enterprise)

export const isPremiumOrEnterprisePlan = (plan: PlanType) => isPremiumPlan(plan) || isEnterprisePlan(plan)

export const getPlanLevelKey = (plan: PlanType): keyof typeof PlanLevel => {
    const tierIndex = PlanIndexByTier[plan.tier]

    return Object.entries(PlanLevel).find(([, range]) => range.includes(tierIndex))?.[0] as keyof typeof PlanLevel
}

export const arePlansInSameLevel = (
    planATier: CustomerSubscriptionPlanTier,
    planBTier: CustomerSubscriptionPlanTier,
): boolean => {
    const planAIndex = PlanIndexByTier[planATier]
    const planBIndex = PlanIndexByTier[planBTier]

    return Object.values(PlanLevel).some((range) => range.includes(planAIndex) && range.includes(planBIndex))
}

export const RECOMMENDED_TIER_BY_TIER_INDEX = {
    [CustomerSubscriptionPlanTier.pro]: PlanLevel.free,
    [CustomerSubscriptionPlanTier.premium]: PlanLevel.pro,
    [CustomerSubscriptionPlanTier.enterprise]: PlanLevel.premium,
} as const
