import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from 'assets/icons/EditIcon'
import AutoGraphRounded from 'assets/icons/AutoGraphRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FolderMoveIcon from '@mui/icons-material/DriveFileMove'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import { MouseEvent, useState } from 'react'
import Divider from '@mui/material/Divider'
import useUpgradeCallback from 'hooks/user/useUpgradeCallback'
import { ModalType, openModal } from 'design/templates/Modal/ModalTypes/modal.slice'
import { useAppDispatch } from 'App'
import { openMyVidsConfirmDeleteModal, openMyVidsRenameFolderOrVideo } from 'design/templates/Modal'
import { Link } from 'design/atoms/Link'
import { useGetDetailedStatsUrl } from 'design/pages/VidStats/hooks/useGetDetailedStatsUrl'
import useSettingsLinkProps from 'design/pages/VidSettings/hooks/useSettingsLinkProps'
import { useAddFolderMutation, useDuplicateVideoMutation, usePublishVideoMutation } from 'api/mutations'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROOT } from 'constants/api.constants'
import styles from './RowActions.style'

interface RowActionsProps {
    type: string
    rowId: string
    title: string
    isReady: boolean
    handleMoveFolderModal: (openMoveFolderModal: boolean, rowId: string) => void
    getAllVideoIdsInFolder: (folderId: string) => string[]
}

const RowActions = ({
    type,
    rowId,
    title,
    handleMoveFolderModal,
    isReady,
    getAllVideoIdsInFolder,
}: RowActionsProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const dispatch = useAppDispatch()
    const { getDetailedStatsUrl } = useGetDetailedStatsUrl()
    const settingsLinkProps = useSettingsLinkProps()
    const addFolderMutation = useAddFolderMutation()
    const publishVideoMutation = usePublishVideoMutation()
    const duplicateVideoMutation = useDuplicateVideoMutation()
    const { guid } = useParams()
    const { t } = useTranslation()

    const upgradeCallback = useUpgradeCallback()

    const openVideoEmbedCodeModal = upgradeCallback(() => {
        dispatch(openModal({ type: ModalType.VIDEO_EMBED_CODE, videoId: rowId }))
    })

    const openConfirmationModal = () => {
        dispatch(
            openMyVidsConfirmDeleteModal({
                rowType: type,
                selectedMyVidsRows: [rowId],
            }),
        )
    }

    const openRenameFolderOrVideo = () => {
        dispatch(
            openMyVidsRenameFolderOrVideo({
                rowType: type,
                rowId,
                title,
            }),
        )
    }

    const handlePublish = () => {
        upgradeCallback(() => publishVideoMutation.mutate([rowId]))({} as MouseEvent)
    }

    const handlePublishFolder = () => {
        upgradeCallback(() => publishVideoMutation.mutate(getAllVideoIdsInFolder(rowId)))({} as MouseEvent)
    }

    const handleDuplicate = () => {
        duplicateVideoMutation.mutate([rowId])
    }

    const handleDuplicateFolder = () => {
        addFolderMutation.mutate({
            folder: { parentFolderGuid: guid || ROOT, title: title + t(' Duplicate') },
        })
    }

    const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        event.preventDefault()
        setAnchorEl(event.currentTarget)
    }
    const handleMenuClose = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(null)
    }

    if (type === 'folder') {
        return (
            <Box sx={styles.container} onClick={(event) => event.stopPropagation()}>
                <Tooltip title={t('Rename')} placement="bottom">
                    <Button
                        sx={styles.button}
                        startIcon={<DriveFileRenameOutlineIcon />}
                        size="small"
                        color="tertiary"
                        variant="outlined"
                        onClick={openRenameFolderOrVideo}
                    />
                </Tooltip>
                <Tooltip title={t('Publish')} placement="bottom">
                    <Button
                        sx={styles.button}
                        startIcon={<CloudUploadIcon />}
                        size="small"
                        color="tertiary"
                        variant="outlined"
                        onClick={handlePublishFolder}
                    />
                </Tooltip>
                <Tooltip title={t('More actions')} placement="bottom">
                    <Button
                        className={anchorEl ? 'menu-open' : ''}
                        onClick={handleMenuOpen}
                        sx={styles.button}
                        startIcon={<MoreVertIcon />}
                        size="small"
                        color="tertiary"
                        variant="outlined"
                    />
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    onClick={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={handleDuplicateFolder}>
                        <ListItemIcon>
                            <ContentCopyIcon />
                        </ListItemIcon>
                        <ListItemText>{t('Duplicate')}</ListItemText>
                    </MenuItem>
                    <Divider sx={styles.divider} />
                    <MenuItem onClick={openConfirmationModal}>
                        <ListItemIcon>
                            <DeleteIcon color="error" />
                        </ListItemIcon>
                        <ListItemText sx={styles.deleteButton}>{t('Delete')}</ListItemText>
                    </MenuItem>
                </Menu>
            </Box>
        )
    }

    return (
        <>
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Link external={settingsLinkProps.external} to={settingsLinkProps.getLink(rowId)}>
                    <Tooltip title={t('Edit')} placement="bottom">
                        <Button
                            sx={styles.button}
                            startIcon={<EditIcon />}
                            size="small"
                            color="tertiary"
                            variant="outlined"
                        />
                    </Tooltip>
                </Link>
                <Link disabled={!isReady} to={getDetailedStatsUrl({ videoGuid: rowId })}>
                    <Tooltip title={t('Open stats')} placement="bottom">
                        <Button
                            sx={styles.button}
                            startIcon={<AutoGraphRounded />}
                            size="small"
                            color="tertiary"
                            variant="outlined"
                        />
                    </Tooltip>
                </Link>

                <Tooltip title={t('Embed/Share')} placement="bottom">
                    <Button
                        sx={styles.button}
                        startIcon={<ShareRoundedIcon />}
                        size="small"
                        color="tertiary"
                        variant="outlined"
                        onClick={openVideoEmbedCodeModal}
                    />
                </Tooltip>

                <Tooltip title={t('More actions')} placement="bottom">
                    <Button
                        className={anchorEl ? 'menu-open' : ''}
                        onClick={handleMenuOpen}
                        sx={styles.button}
                        startIcon={<MoreVertIcon />}
                        size="small"
                        color="tertiary"
                        variant="outlined"
                    />
                </Tooltip>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    onClick={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Link external={settingsLinkProps.external} to={settingsLinkProps.getLink(rowId)}>
                        <MenuItem onClick={handleMenuClose}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText>{t('Edit')}</ListItemText>
                        </MenuItem>
                    </Link>
                    <MenuItem disabled={!isReady} onClick={handlePublish}>
                        <ListItemIcon>
                            <CloudUploadIcon />
                        </ListItemIcon>
                        <ListItemText>{t('Publish')}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={openVideoEmbedCodeModal}>
                        <ListItemIcon>
                            <ShareRoundedIcon />
                        </ListItemIcon>
                        <ListItemText>{t('Embed/Share')}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={openRenameFolderOrVideo}>
                        <ListItemIcon>
                            <DriveFileRenameOutlineIcon />
                        </ListItemIcon>
                        <ListItemText>{t('Rename')}</ListItemText>
                    </MenuItem>
                    <MenuItem disabled={!isReady} onClick={handleDuplicate}>
                        <ListItemIcon>
                            <ContentCopyIcon />
                        </ListItemIcon>
                        <ListItemText>{t('Duplicate')}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleMoveFolderModal(true, rowId)}>
                        <ListItemIcon>
                            <FolderMoveIcon />
                        </ListItemIcon>
                        <ListItemText>{t('Move to Folder')}</ListItemText>
                    </MenuItem>
                    <Divider sx={styles.divider} />
                    <MenuItem onClick={openConfirmationModal}>
                        <ListItemIcon>
                            <DeleteIcon color="error" />
                        </ListItemIcon>
                        <ListItemText color={'error.main'} sx={styles.deleteButton}>
                            {t('Delete')}
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Box>
        </>
    )
}

export default RowActions
