import { ButtonProps, SxProps } from '@mui/material'
import { pxToRem } from 'styles/theme/typography'

export type SupportedIconBtnSize = Extract<ButtonProps['size'], 'medium' | 'small' | 'xSmall'>

const buttonStyleByIconBtnSizes: Record<SupportedIconBtnSize, SxProps> = {
    medium: {
        p: 1.25,

        '.MuiButton-startIcon .MuiSvgIcon-root': {
            fontSize: pxToRem(24),
        },
    },
    small: {
        p: 2,
        borderRadius: 3,
    },
    xSmall: {
        p: 1,
        borderRadius: 2,
    },
}

const iconButton = (iconBtnSize: SupportedIconBtnSize): SxProps => buttonStyleByIconBtnSizes[iconBtnSize]

export default {
    iconButton,
}
