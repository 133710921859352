import { locale } from 'locales'

export default {
    title: 'Embed Code',
    description:
        'Embed your Vid anywhere in your website. Copy the code and paste it into a custom code widget or HTML editor on your website builder.',
    learnMore: 'Learn more',
    multipleVideos: 'Multiple Videos on One Page',
    enableMultipleVideos: "Enable this if you have multiple videos on your page. Don't use this for the first video.",
    copyToClipboard: locale.operations.generic.copyToClipboard,
    tab: {
        inlineEmbed: 'Inline Embed',
        quickShare: 'Quick Share / oEmbed',
    },
    notification: {
        sharingDisabled: 'Public sharing is disabled',
        loading: locale.messages.info.loading,
    },
    linkQuickShare: 'Quickshare link',
    linkOEmbed: 'oEmbed link',
    quickShareLabel: 'Public Preview',
    quickShareDescription: 'Disable Public Preview and oEmbed',
    vidConditions: 'Vid Conditions',
    vidConditionsTip: 'Boost engagement and conversion by serving videos tailored to different audience segments.',
    processingTime: 'Processing your vid can take up to 60 minutes before it is ready to play.',
    copyCode: 'Copy code',
    republish: 'Republish',
    proLabel: 'Pro feature. Upgrade and start using now',
    upgradeToUnlock: 'Upgrade to Unlock Vid Conditions!',
    unlock: 'Upgrade',
    boostEngagement: "Boost engagement and conversion with Vidalytics' most advanced features.",
}
