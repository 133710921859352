export default {
    title: (isFolder: boolean) =>
        `Are you sure you want to delete ${isFolder ? 'folder and all its videos?' : 'video'} `,
    message: (isFolder: boolean) =>
        `${
            isFolder
                ? 'All vids inside of it will be permanently deleted.'
                : 'All data will be lost. This action is permanent and cannot be undone.'
        }`,
}
