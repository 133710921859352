import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { UPDATE_SMART_VID_TAGS_MUTATION, VIDEOS_QUERY, SMART_VID_TAGS_QUERY } from 'api/constants'
import { Options, useInvalidateMutation } from 'api/hooks'
import { SmartVidTagsConfig, SmartVidTag } from 'types/VideoTag'

type Payload = {
    videoGuid: string
    config: SmartVidTagsConfig
}

export const useUpdateSmartVidTagsMutation = (options?: Options<SmartVidTag, Payload, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<SmartVidTag, Payload, string>(
        UPDATE_SMART_VID_TAGS_MUTATION,
        async (payload: Payload) =>
            mutate(settingsAPI.updateSmartVidTags(payload.videoGuid, payload.config), apiLocale.settings.tag.save),
        {
            ...options,
            invalidate: [SMART_VID_TAGS_QUERY, VIDEOS_QUERY],
        },
    )
}
