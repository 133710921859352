import usePageTitle from 'hooks/navigation/usePageTitle'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import MyVids from 'design/organisms/MyVids/MyVids'

export const MyVidsPage = withErrorBoundary(() => {
    usePageTitle('My Vids | Vidalytics')

    return <MyVids />
})

export default MyVidsPage
