import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box, Button, Tooltip } from '@mui/material'

import { ConditionalWrapper } from 'design/molecules/ConditionalWrapper'
import { Link } from 'design/atoms/Link'
import { useLayout } from 'hooks/utilities/useLayout'
import { route } from 'constants/routes'
import { style } from '../Sidebar.style'
import locale from '../Sidebar.locale'

interface UploadButtonProps {
    onClick(): void
    open: boolean
}

export const UploadButton = ({ onClick, open }: UploadButtonProps) => {
    const { isMobile } = useLayout()

    return (
        <ConditionalWrapper
            condition={!isMobile && !open}
            wrapper={(children) => (
                <Tooltip arrow title={locale.upload} placement="right">
                    <Box>{children}</Box>
                </Tooltip>
            )}
        >
            <Link to={route.upload} onClick={onClick}>
                <Button
                    startIcon={<CloudUploadIcon fontSize="small" />}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    sx={[style.uploadCta.idle, !(open || isMobile) && style.uploadCta.closed]}
                >
                    <span>{locale.upload}</span>
                </Button>
            </Link>
        </ConditionalWrapper>
    )
}
