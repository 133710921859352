export default {
    reserveSpace: 'Reserve Space',
    reserveSpaceTooltip:
        'Reserve the space on your page for the button, so your page elements below the video do not move when it appears.',
    expandContainerTooltip:
        'This will NOT reserve the space below your video for the button. Thus, when your CTA button appears, it will move your page elements below the video down.',
    id: 'Id',
    class: 'Class',
    display: 'Display',
    belowVideo: {
        label: 'Below Video (Recommended)',
        tooltip:
            'This will show your CTA button below your video as an HTML button. This is the most reliable way to have a CTA button, and therefore is recommended.',
    },
    onTop: {
        label: 'On Top',
        tooltip:
            'Show your button on top of your video. Important note: When your video goes full screen on iOS devices, this option will not appear. To avoid this, use the Below Video CTA option.',
    },
    customHTML: {
        label: 'Custom HTML',
        tooltip:
            "Add any kind of content you'd like to display below your video at a specific in-video time – such as order boxes. Make sure your hidden container uses the attribute ‘display: ”none”’. Vidalytics will overwrite this and unhide the content block at the specified time.",
    },
}
