import { Button } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import _get from 'lodash/get'

import useUserStatuses from 'hooks/user/useUserStatuses'
import { trackAppCuesEvent, APPCUES_EVENTS } from 'thirdPartyServices/appCues'
import { Plan as PlanInterface } from 'types/Customer'
import { UpgradeCTA } from 'design/atoms/UpgradeCTA/UpgradeCTA'
import { SetPlan } from 'design/templates/Modal'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { isEnterprisePlan } from 'constants/plan.constants'

interface PlanIndexedInterface extends PlanInterface {
    index: number
}

interface CurrentPlanIndexedInterface extends Partial<PlanInterface> {
    index: number
}

export type ActionProps = {
    plan: PlanIndexedInterface
    currentPlan: CurrentPlanIndexedInterface
    setUpgradePlan: SetPlan
    onDowngrade: (plan: PlanInterface) => void
    onReactivate: (plan: PlanInterface) => void
}

export enum PlanActions {
    UPGRADE = 'UPGRADE',
    REACTIVATE = 'REACTIVATE',
    DOWNGRADE = 'DOWNGRADE',
}

const Action = ({ plan, currentPlan, setUpgradePlan, onDowngrade, onReactivate }: ActionProps) => {
    const { isUserInactive } = useUserStatuses()
    const { selectionUIFixForPremiumTier } = useFeatureFlags()

    const keyCheck = selectionUIFixForPremiumTier ? 'tier' : 'id'

    const trackPlanBtnClick = (action: PlanActions) => {
        trackAppCuesEvent(
            `${APPCUES_EVENTS.PLANS_POPUP_PLAN_BTN_CLK} action - ${action}, plan.apiHandle - ${plan.apiHandle}`,
        )
        mixpanel.track(MIXPANEL_EVENTS.SUBSCRIPTIONS_PLAN_SELECTED, {
            old_plan: currentPlan.apiHandle || null,
            new_plan: plan.apiHandle,
        })
    }

    const handleUpgradeBtnClick = () => {
        setUpgradePlan(plan)
        trackPlanBtnClick(PlanActions.UPGRADE)
    }

    const handleReactivation = () => {
        onReactivate(plan)
        trackPlanBtnClick(PlanActions.REACTIVATE)
    }

    const handleDowngrade = () => {
        onDowngrade(plan)
        trackPlanBtnClick(PlanActions.DOWNGRADE)
    }

    if (
        _get(plan, keyCheck) === _get(currentPlan, keyCheck) ||
        (plan.index === currentPlan.index && !isEnterprisePlan(plan))
    ) {
        return (
            <Button
                variant="contained"
                className="planCurrent cta"
                disabled={!isUserInactive}
                onClick={handleReactivation}
                fullWidth
            >
                {isUserInactive ? 'Reactivate' : 'Your current plan'}
            </Button>
        )
    }

    if (
        plan.index < currentPlan.index ||
        (_get(plan, keyCheck) !== _get(currentPlan, keyCheck) &&
            plan.index === currentPlan.index &&
            isEnterprisePlan(plan))
    ) {
        return (
            <Button onClick={handleDowngrade} variant="outlined" className="planDowngrade cta" fullWidth>
                Downgrade to {plan.name}
            </Button>
        )
    }

    return <UpgradeCTA onClick={handleUpgradeBtnClick} dataTestid={plan.name} renderIcon fullWidth />
}

export default Action
