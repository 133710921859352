import { Switch } from 'design/atoms/Switch'
import { Stack, Typography } from '@mui/material'
import { useThemeProvider } from 'App'

export const ToggleThemeButton = () => {
    const { theme, toggleThemeMode } = useThemeProvider()

    return (
        <Stack my={5} px={4} flexDirection="row" justifyContent="space-between" alignContent="center">
            <Switch
                onChange={toggleThemeMode}
                label={<Typography variant="body2">{theme === 'light' ? 'Dark Mode' : 'Light Mode'}</Typography>}
            />
        </Stack>
    )
}
