import { useMemo } from 'react'
import { FilterKeys } from 'design/pages/Analytics/types'
import { useAppSelector } from 'App'
import { selectFiltersState } from 'design/pages/Analytics/analyticsActions.slice'
import { getFiltersFromURL, getParameterFromURL } from 'design/pages/Analytics/SidebarBlock/utils'

export const useAnalyticsFilterParams = (): string => {
    const storeFilters = useAppSelector(selectFiltersState)
    const urlTypeFromURL = getParameterFromURL('url_type')[0]
    const filtersFromURL = getFiltersFromURL(storeFilters)

    const filterPrefix = 'filter'

    return useMemo(
        () =>
            Object.entries(filtersFromURL)
                .filter((entry) => entry[1].length > 0)
                .map(([key, values]) => {
                    const valuesString = values.join(',')
                    if (key === FilterKeys.urlTracking && urlTypeFromURL) {
                        return `${filterPrefix}.${key}.${urlTypeFromURL}=${valuesString}`
                    }
                    return `${filterPrefix}.${key}=${valuesString}`
                })
                .join('&'),
        [storeFilters, urlTypeFromURL],
    )
}
