export const SCOPE = 'https://www.googleapis.com/auth/drive.readonly'
export const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string
export const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY as string
export const APP_ID = process.env.REACT_APP_DROPBOX_APP_ID as string
export const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_APP_ID as string

export const GOOGLE_DRIVE_PICKER_URL = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
export const GOOGLE_DRIVE_SCOPES = 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive'
export const GOOGLE_DRIVE_MIME_TYPES = 'video/mp4,video/webm,video/ogg'
export const GIS_SCRIPT_URL = 'https://accounts.google.com/gsi/client'
export const GAPI_SCRIPT_URL = 'https://apis.google.com/js/api.js'

// missing mime types in file selector
// https://github.com/react-dropzone/file-selector/pull/56
export const MISSING_MIME_TYPES: { [key: string]: string } = {
    wmv: 'video/x-ms-wmv',
    flv: 'video/x-flv',
    '3gp': 'video/3gp',
}

export const UPLOAD_TO_FOLDER_KEY = 'uploadToFolder'
