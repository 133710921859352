import { SxProps, Theme } from '@mui/material'

export default {
    root: {
        padding: 4,
        gap: 4,
        borderRadius: 4,
        border: '1px solid',
        borderColor: 'divider',
        textAlign: 'left',
    },
    cta: {
        width: 'fit-content',
        alignSelf: 'flex-end',
        fontWeight: 600,
    },
} satisfies Record<string, SxProps<Theme>>
