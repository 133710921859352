import _get from 'lodash/get'
import { useQuery, UseQueryOptions } from 'react-query'
import { enableQueryHelper } from 'utils'

import { CUSTOMER_SUBSCRIPTION_QUERY } from 'api/constants'
import { userAPI } from 'api/controllers'
import { CustomerSubscription } from 'types/Customer'
import { queryClient } from 'api/queries/init'

export const useCustomerSubscriptionQuery = (options?: UseQueryOptions<CustomerSubscription, unknown>) => {
    return useQuery<CustomerSubscription>(CUSTOMER_SUBSCRIPTION_QUERY, () => userAPI.getCustomerSubscription(), {
        ...options,
        enabled: enableQueryHelper(),
        onError: (error) => {
            if (_get(error, 'response.status') === 422) {
                queryClient.setQueryData(CUSTOMER_SUBSCRIPTION_QUERY, { isFake: true, plan: {} })
            }
        },
    })
}
