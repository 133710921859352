import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import {
    ClickAwayListener,
    Divider,
    Grow,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack,
    Typography,
} from '@mui/material'
import {
    HelpOutlineRounded as HelpOutlineRoundedIcon,
    MailOutlineRounded as MailOutlineRoundedIcon,
    StarOutlineRounded as StarOutlineRoundedIcon,
} from '@mui/icons-material'

import { ConditionalLinkWrapper } from 'design/molecules/ConditionalLinkWrapper'
import BookRounded from 'assets/icons/BookRounded'
import LayersIcon from 'assets/icons/LayersIcon'
import { route } from 'constants/routes'

import style from './HelpMenu.style'
import locale from './HelpMenu.locale'

interface MenuProps {
    openSidebar: boolean
}

const menuItems = [
    { label: locale.menuItems.helpCenter, pathname: route.static.help.index, icon: <LayersIcon /> },
    { label: locale.menuItems.knowledgeBase, pathname: route.trainingCenter.index, icon: <BookRounded /> },
    { label: locale.menuItems.contactUs, pathname: route.static.help.contactUs, icon: <MailOutlineRoundedIcon /> },
    { label: locale.menuItems.feedback, pathname: route.static.help.feedback, icon: <StarOutlineRoundedIcon /> },
]

export const HelpMenu = ({ openSidebar }: MenuProps) => {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLDivElement>(null)

    const handleToggle = () => setOpen((prev) => !prev)

    const handleClose = () => setOpen(false)

    const handleListKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
            case 'Tab':
                event.preventDefault()
                setOpen(false)
                break
            case 'Escape':
                setOpen(false)
                break
        }
    }

    useEffect(() => {
        if (!open) anchorRef.current?.focus()
    }, [open])

    return (
        <>
            <Stack direction="row" alignItems="center" ref={anchorRef} sx={style.opener} onClick={handleToggle}>
                <HelpOutlineRoundedIcon
                    sx={[style.helpIcon.idle, openSidebar && style.helpIcon.open, open && style.helpIcon.active]}
                />
                <Typography
                    component="span"
                    variant="body2"
                    color="text.primary"
                    sx={[style.help, style.opacity.idle, openSidebar && style.opacity.open]}
                >
                    {locale.help}
                </Typography>
            </Stack>

            <Popper
                open={open}
                sx={style.popper}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="right-start"
                transition
                disablePortal
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={style.grow}>
                        <Paper sx={style.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="test"
                                    onKeyDown={handleListKeyDown}
                                    sx={style.menuList}
                                >
                                    {menuItems.map((item, index) => (
                                        <ConditionalLinkWrapper
                                            linkProps={{ target: '_blank' }}
                                            link={item.pathname}
                                            key={item.label}
                                        >
                                            {index === menuItems.length - 1 && <Divider sx={{ py: 2 }} />}
                                            <MenuItem onClick={handleClose}>
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <Typography component="span" variant="body2" color="text.primary">
                                                    {item.label}
                                                </Typography>
                                            </MenuItem>
                                        </ConditionalLinkWrapper>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
