import { SxProps, Theme } from '@mui/material'

export default {
    modalStack: {
        p: 8,
        gap: 8,
        zIndex: (theme) => theme.zIndex.modal,
        transition: (theme) =>
            theme.transitions.create('transform', {
                duration: 250,
                easing: 'ease-in-out',
            }),
    },
    headerContainer: {
        px: 8,
        textAlign: 'center',
        gap: 4,

        '& ~ .MuiIconButton-root': {
            position: 'absolute',
            top: 0,
            right: 0,
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    closingModalStack: {
        transform: 'scale(1.02)',
    },
} satisfies Record<string, SxProps<Theme>>
