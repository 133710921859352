import { CallbackDoc } from '@fyelci/react-google-drive-picker/dist/typeDefs'

import { CancelTokenSource } from 'axios'
import { Id } from 'types'
import { Funnel } from 'types/Funnel'
import { PlayerOnPlaybackEndType, VideoStartupQuality } from 'types/Player'
import {
    CallToActionType,
    VideoCallToActionCustomHTMLSelectorType,
    VideoCallToActionDisplayMode,
} from 'types/VideoCallToAction'
import { ChaptersDetails, ThumbnailSource } from 'types/VidSettings'
import { VideoPlayGateConfig, VideoPlayGateType } from 'types/VideoPlayGate'
import { TimezoneDate } from 'api/contracts/utilitary/entities/timezoneDate'
import { TagConfig } from 'types/VideoTag'

export type VideoGuid = string

/* GET /videos array item */
export type Video = {
    guid: VideoGuid
    title: string
    folderGuid: string
    dateCreated: string
    dateUpdated: string
    datePublished: string
    isDemo: boolean
    isUnpublished: boolean
    length: string
    steps: number
    plays: number
    size: number
    embedCodeGuid: string
    src: string
    funnel: {
        id: number
        headline: string
    } | null
    thumbnail: {
        src: string
        previewSrc: string
        dashboardSrc?: string
    }
    thumbnailType: ThumbnailSource
    videoThumbnailGeneration?: VideoThumbGeneration
    thumbnailEncodingStarted?: TimezoneDate
    isReady: boolean
    breadcrumb?: string
    isDemoVideo: boolean
    originalFileName: string
}

export enum VideoThumbGeneration {
    pending = 'pending',
    ready = 'ready',
}

export enum UnmuteType {
    box = 'box', // Front & Center
    button = 'button', // Classy in the Corner
    overlay = 'overlay', // Loud & in Your Face
    none = 'none',
}

export enum CUDEventType {
    update,
    create,
    delete,
}

export interface ExpireVideo {
    ui_overlay_expire_enabled?: boolean
    ui_overlay_expire_dateExpire?: string
    ui_overlay_expire_textTop_text?: string
    ui_overlay_expire_textBottom_text?: string
    ui_overlay_expire_link_href?: string
    ui_overlay_expire_link_blank?: boolean
    ui_overlay_expire_color_foreground?: string
    ui_overlay_expire_color_background?: string
}

export interface PlayOptions {
    playback_resume: boolean
    ui_overlay_resume_show: boolean
    ui_overlay_resume_text: string
    ui_overlay_resume_button_resume_text: string
    ui_overlay_resume_button_replay_text: string
    ui_overlay_resume_color_foreground: string
    ui_overlay_resume_color_background: string
    playback_onEnd: PlayerOnPlaybackEndType
    playback_skipAndRewindWithKeyboard: boolean
    ui_overlay_redirect_color_foreground: string
    ui_overlay_redirect_color_background: string
    ui_overlay_redirect_show: boolean
    ui_overlay_redirect_text: string
    ui_overlay_redirect_url: string
    ui_overlay_redirect_timeout: number
    ui_thumbnail_fullSize: boolean
    playback_noPause: boolean
    playback_smartPause: boolean
    playback_smartPauseResume: boolean
    player_startupQuality: VideoStartupQuality
    player_lowerBandwidthUsage: boolean
}

export interface FocusedFullscreen {
    ui_controlBar_fullscreen_customFullscreen_default_enabled: boolean
    ui_controlBar_fullscreen_customFullscreen_mobile_enabled: boolean
    ui_controlBar_fullscreen_customFullscreen_expandOnPlay: boolean
    ui_controlBar_fullscreen_customFullscreen_minimizeOnPause: boolean
}

export interface SmartAutoplay {
    player_width: number
    player_height: number
    playback_autoplay_enabled: boolean
    ui_overlay_unmute_type: UnmuteType
    ui_overlay_unmute_textTop_text: string
    ui_overlay_unmute_textBottom_text: string
    ui_overlay_unmute_color_foreground: string
    ui_overlay_unmute_color_background: string
    ui_overlay_unmute_doReplay: boolean
    playback_autoplay_onlyMuted: boolean
    playback_autoplay_mobile: boolean
    ui_overlay_unmute_goFullscreen: boolean
    ui_overlay_unmute_mobile_enabled: boolean
    ui_overlay_unmute_goFullscreen_default_enabled: boolean
    ui_overlay_unmute_goFullscreen_mobile_enabled: boolean
    ui_overlay_unmute_mobile_textTop_text: string
    ui_overlay_unmute_mobile_textBottom_text: string
}

export interface Thumbnail {
    ui_thumbnail_default_source: string
    ui_thumbnail_mobile_source: string
    ui_thumbnail_default_mp4?: string
    ui_thumbnail_default_webm?: string
    ui_thumbnail_mobile_mp4?: string
    ui_thumbnail_mobile_webm?: string
}

export interface ExitThumbnail {
    ui_overlay_pause_enabled: boolean
    ui_overlay_pause_source: string
    ui_overlay_multiPause?: {
        [guid: string]: {
            guid: string
            source: string
            From: number
            To: number
        }
    }
}

export enum ThumbnailType {
    thumbnail = 'thumbnail',
    mobileThumbnail = 'mobileThumbnail',
    customPauseScreen = 'customPauseScreen',
}

export enum MainThumbnailType {
    thumbnail = ThumbnailType.thumbnail,
    mobileThumbnail = ThumbnailType.mobileThumbnail,
}

export enum ThumbnailVideoType {
    default = 'default',
    mobile = 'mobile',
    both = 'both',
}

export enum PlayerThemeName {
    classic = 'classic',
    sleek = 'sleek',
}

export interface PlayerTheme {
    ui_theme_name: PlayerThemeName
    ui_color_foreground: string
    ui_color_background: string
    ui_color_force: boolean
    ui_theme_style_borderRadius: number
}

export interface VideoPlayerFlowNavigation {
    goBack: ReplayButton
    startOver: ReplayButton
}

export interface ReplayButton {
    show: boolean
    text: string
}

export interface VideoPlayerStyle extends PlayerTheme, VideoPlayerFlowNavigation {
    ui_overlay_play_button_show: boolean
    ui_controlBar_play_show: boolean
    ui_controlBar_seekBar_show: boolean
    ui_controlBar_seekBar_showToReturningViewers: boolean
    ui_controlBar_qualityControl_show: boolean
    ui_controlBar_speedControl_show: boolean
    ui_controlBar_volume_show: boolean
    ui_controlBar_fullscreen_show: boolean
    ui_controlBar_fullscreen_customFullscreen_default_enabled: boolean
    ui_controlBar_fullscreen_customFullscreen_mobile_enabled: boolean
    ui_shadow: boolean
    ui_controlBar_rewind_show: boolean
    ui_controlBar_smartSeekBar_show: boolean
    ui_controlBar_smartSeekBar_desktopSpeed: number
    ui_controlBar_smartSeekBar_mobileSpeed: number
    ui_controlBar_alwaysShow: boolean
    ui_controlBar_accessibilityTooltips: boolean
    ui_overlay_play_seekButtons_show: boolean
}

export interface VideoClosedCaptions {
    ui_closedCaptions_source: string
    ui_closedCaptions_name: string // deprecated
    ui_closedCaptions_default_displayWhenMuted: boolean
    ui_closedCaptions_default_enabled: boolean
    ui_closedCaptions_mobile_displayWhenMuted: boolean
    ui_closedCaptions_mobile_enabled: boolean
}

type VidConditionSegmentRuleVariantKeys = 'minWidth' | 'maxWidth' | 'isReturning' | VideoGuid
export type WidthValues = 'Mobile (<768px)' | 'Tablet (768-1024px)' | 'Desktop (>1024px)'

export type VidConditionSegmentRuleVariant = {
    [key in VidConditionSegmentRuleVariantKeys]: WidthValues | boolean | string
} & {
    conditionId: number
}

type VidConditionSegmentRule = {
    rules: VidConditionSegmentRuleVariant[]
}

export type VidConditionSegments = {
    [key: string]: VidConditionSegmentRule
}

export type VidConditionTargetRow = {
    condition_id: number
    target_video_id: VideoGuid
    value?: number | string
}

export type VidConditionTargets = VidConditionTargetRow[]

export type VideoDescription = {
    video_title: string
    video_publicTitle: string
    video_description?: string
    video_internalNotes?: string
}

export interface VideoSettings
    extends VideoPlayerStyle,
        Thumbnail,
        ExitThumbnail,
        SmartAutoplay,
        PlayOptions,
        ExpireVideo,
        VideoClosedCaptions,
        VideoDescription,
        FocusedFullscreen {
    bitmovin_key: string
    bitmovin_subkey: string
    player_branding: boolean
    player_media_dash_source: string
    player_media_hls_source: string
    player_media_mp4_source: string
    player_aspectRatio: string
    player_height: number
    player_width: number
    targeted?: {
        enabled?: boolean
        segments: VidConditionSegments
    }
    triggers_items: {
        [guid: string]: {
            time: number
            name: string
            params: {
                [name: string]: string
            }
        }
    }
    triggers_params: {
        [name: string]: string
    }
    tracking_enabled: boolean
    tracking_customerId: string
    tracking_url: string
    tracking_videoId: string
    external_platform?: string
    ui_callToActions: {
        [guid: string]: {
            displayMode: VideoCallToActionDisplayMode
            htmlSelectorType: VideoCallToActionCustomHTMLSelectorType
            htmlSelector: string
            title: string
            link: string
            linkInNewWindow: boolean
            timeFrom: string
            timeTo: string
            type: CallToActionType
            color_background: string
            color_foreground: string
            colorHover_background: string
            colorHover_foreground: string
            shadow: boolean
            showToReturningViewers: boolean
            autoScroll: boolean
            scrollOffset: number
        }
    }
    ui_chapters_data: ChaptersDetails[]
    ui_thumbnail_default_from: number
    ui_thumbnail_default_to: number
    ui_thumbnail_default_type: 'image' | 'video'
    ui_thumbnail_mobile_from: number
    ui_thumbnail_mobile_to: number
    ui_thumbnail_mobile_type: 'image' | 'video'
    ui_thumbnail_forceDefault: boolean
    ui_thumbnail_videoGuid: string | null
    ui_thumbnail_mobile_videoGuid: string | null
    ui_chapters_enabled: boolean
    ui_overlay_playGate: {
        [guid: string]: {
            button: {
                color: {
                    background: string
                }
                text: {
                    text: string
                }
            }
            color: {
                foreground: string
            }
            email: { show: boolean }
            name: { show: boolean }
            phone: { show: boolean }
            skippable: boolean
            textBottom: { text: string }
            textSkip: { text: string }
            textTop: { text: string }
            time?: number
            type: VideoPlayGateType
            forceAlwaysDisplay?: boolean
        }
    }
    ui_thumbnail_default_mp4?: string
    ui_thumbnail_default_webm?: string
    ui_thumbnail_mobile_mp4?: string
    ui_thumbnail_mobile_webm: string
}

export interface ButtonInterface {
    text: string
    color: string
}

export interface ColorConfig {
    foreground?: string
    background?: string
    force?: boolean
}

interface ShowToReturningViewersInterface extends ShowInterface {
    showToReturningViewers?: boolean
}

interface OptionEnabledConfig {
    enabled: boolean
}

interface EnableInterface {
    enabled?: boolean
}

interface TextInterface {
    text?: string
}

interface LinkInterface {
    href: string
    blank: boolean
}

interface ExternalFileConfig {
    source: string
}

export interface PlaybackAutoplayConfig extends EnableInterface {
    onlyMuted?: boolean
    mobile?: boolean
    keepMutedAfterDomainInteraction?: boolean
}

interface UIOverlayResumeButtonConfig {
    replay: TextInterface
    resume: TextInterface
}

export interface CallToActionConfig {
    guid?: string
    displayMode: VideoCallToActionDisplayMode
    title: string
    link?: string
    type: CallToActionType
    timeFrom?: string
    timeTo?: string | null
    color?: ColorConfig
    colorHover?: ColorConfig
    shadow?: boolean
    showToReturningViewers?: boolean
    linkInNewWindow?: boolean
    showOnlyWhenTriggeredBefore?: boolean
    htmlSelectorType?: VideoCallToActionCustomHTMLSelectorType
    htmlSelector?: string
    color_background?: string
    color_foreground?: string
    colorHover_background?: string
    colorHover_foreground?: string
    autoScroll: boolean
    scrollOffset: number
}

export interface CallToActionsConfig {
    [guid: string]: CallToActionConfig
}

export interface ClosedCaptionsConfig {
    source?: string
    default?: OptionEnabledConfig
    mobile?: OptionEnabledConfig
}

export interface UIControlBarConfig {
    fullscreen: ShowFullscreenInterface
    play: ShowInterface
    seekBar: ShowToReturningViewersInterface
    smartSeekBar: ShowInterface
    qualityControl: ShowInterface
    speedControl: ShowInterface
    volume: ShowInterface
    rewind: ShowInterface
    alwaysShow?: boolean
}

interface ShowInterface {
    show?: boolean
}

interface ShowFullscreenInterface extends ShowInterface {
    customFullscreen?: DeviceTypeConfig
}

export interface DeviceTypeConfig {
    default?: OptionEnabledConfig
    mobile?: OptionEnabledConfig
}

interface UIOverlayUnmuteMobileConfig extends EnableInterface {
    textBottom: TextInterface
    textTop: TextInterface
}

export type UIOverlayPauseConfig = ExternalFileConfig

export interface UIOverlayPlayConfig extends ShowInterface {
    button?: ShowInterface
    seekButtons?: ShowInterface
}

export interface UIOverlayRedirectConfig extends ShowInterface, TextInterface {
    color?: ColorConfig
    timeout?: number
    url?: string
}

export interface UIOverlayResumeConfig extends ShowInterface, TextInterface {
    button?: UIOverlayResumeButtonConfig
    color?: ColorConfig
    shadow?: boolean
}

export interface UIOverlayUnmuteConfig extends ShowInterface {
    color?: ColorConfig
    doReplay?: boolean
    goFullscreen?: DeviceTypeConfig
    mobile?: UIOverlayUnmuteMobileConfig
    shadow?: boolean
    textBottom?: TextInterface
    textTop?: TextInterface
    type?: UnmuteType
}

export interface PlayGateButtonConfig {
    text?: TextInterface
    color?: ColorConfig
}

export enum PlayGateType {
    TIME = 'time',
    EXIT = 'exit',
}

export interface PlayGateConfig {
    guid?: string
    type: PlayGateType
    time?: number
    color: ColorConfig
    textTop?: TextInterface
    textBottom?: TextInterface
    button?: PlayGateButtonConfig
    name: ShowInterface
    email: ShowInterface
    phone: ShowInterface
    skippable: boolean
    textSkip?: TextInterface
    forceAlwaysDisplay?: boolean
}

export interface UIOverlayPlayGatesConfig {
    [guid: string]: PlayGateConfig
}

export interface UIOverlayExpireConfig extends EnableInterface {
    dateExpire: string
    textTop?: TextInterface
    textBottom?: TextInterface
    link?: LinkInterface
}

export interface UIOverlayConfig {
    pause?: UIOverlayPauseConfig
    play?: UIOverlayPlayConfig
    redirect?: UIOverlayRedirectConfig
    resume?: UIOverlayResumeConfig
    unmute?: UIOverlayUnmuteConfig
    playGate?: UIOverlayPlayGatesConfig
    expire?: UIOverlayExpireConfig
    interactiveVideos?: UIOverlayInteractiveVideosConfig
    smartVids?: UIOverlayInteractiveVideosConfig
}

export interface UIOverlayThumbnailConfig {
    default?: ExternalFileConfig
    mobile?: ExternalFileConfig
    placeholder?: ExternalFileConfig
    fullSize?: boolean
}

export enum AssetsPositionConfig {
    TOP_LEFT = 'topLeft',
    TOP_RIGHT = 'topRight',
    BOTTOM_LEFT = 'bottomLeft',
    BOTTOM_RIGHT = 'bottomRight',
}

export interface UICustomLogoConfig {
    source: string
    position: AssetsPositionConfig
    link?: LinkInterface
    opacity: number
    borderRadius: number
}

interface UIConfig extends EnableInterface {
    callToActions?: CallToActionsConfig
    closedCaptions?: ClosedCaptionsConfig
    color: ColorConfig
    controlBar: UIControlBarConfig
    overlay: UIOverlayConfig
    shadow?: boolean
    thumbnail?: UIOverlayThumbnailConfig
    customLogo?: UICustomLogoConfig
}

export type StylesToInheritConfig = Pick<UIConfig, 'color' | 'controlBar' | 'shadow'>

export interface InteractionConfig {
    displayTime: {
        displayMode: InteractionDisplayMode
        timeFrom: number
    }
    options: InteractionOptionsConfig
}

interface InteractionOptionsConfig {
    [guid: string]: InteractionOptionConfig
}

interface InteractionOptionConfig {
    embedId: string
    actionType: InteractionOptionActionType
    buttonText: string
    cssEmbed: string
    url?: string
}

export type InteractionDisplayMode = 'time' | 'exit' | 'end'

export type InteractionOptionActionType = 'openVideo' | 'openLink' | 'continue'

export interface UIOverlayInteractiveVideosConfig {
    funnelId: string
    parent: string
    goBack: FunnelReplayButtonConfig
    startOver: FunnelReplayButtonConfig
    enableBackground: boolean
    disableUserInteraction?: boolean
    branch: FunnelBranchConfig
    defaultAction?: FunnelDefaultAction
    interactions: {
        [guid: string]: InteractionConfig
    }
}

export interface FunnelDefaultAction {
    actionType: FunnelDefaultActionType
    timeout: number
    option?: string
    redirectMessageShow?: boolean
    url?: string | null
    text?: string
    color?: string
}

export type FunnelDefaultActionType = 'openVideo' | 'openLink' | 'replay' | 'displayThumbnail'

export interface FunnelReplayButtonConfig {
    show: boolean
    button: ButtonInterface
}

export interface FunnelBranchConfig {
    type: FunnelOptionType
    headline?: string
    displayMode: FunnelDisplayMode
    inheritStyles?: boolean
    timeFrom?: number
    timeTo?: number
    autoplayOption?: string
    autoplayAfter?: number
    timerType?: TimerType
    options: FunnelOptionsConfig
}

export enum FunnelOptionType {
    BUTTONS = 'buttons',
    CLICKABLE_AREAS = 'clickableAreas',
}

export enum FunnelDisplayMode {
    END = 'end',
    TIME = 'time',
    BEFORE_END = 'beforeEnd',
}

export interface FunnelOptionsConfig {
    [guid: string]: FunnelOptionConfig
}

export interface FunnelOptionConfig {
    cssEmbed: string
    buttonText?: string
    buttonColor?: string
    buttonHover?: string
    textColor?: string
    textHover?: string
    positionX?: number
    positionY?: number
    width?: number
    height?: number
    borderRadius?: number
}

export interface FunnelSettings {
    goBackEmbedId?: string
    startOverEmbedId?: string
    stylesToInherit?: StylesToInheritConfig
}

export enum TimerType {
    PROGRESS_BAR = 'progressBar',
    SECONDS = 'seconds',
    CLOCK = 'clock',
    NONE = 'none',
}

export interface LicenseStatusData {
    code: LicenseStatusCode
    allowedDomains: string[] | null
    message: string
}

type LicenseStatusCode =
    | 'OK'
    | 'ERR_TRIAL_EXPIRED'
    | 'ERR_FREE_PLAYS_REACHED'
    | 'ERR_CUSTOMER_NOT_FOUND'
    | 'ERR_SUBSCRIPTION_INVALID'

export interface VideoInFunnel extends Funnel {
    embedCode: UIOverlayInteractiveVideosConfig
}

export interface VideoInDetails extends Video {
    closedCaptionsGeneration: string
}

/* GET /video/GUID */
export interface VideoDetails {
    video: VideoInDetails
    settings: VideoSettings
    funnel: VideoInFunnel | null
    updatedBy: string
    uploadedBy: string
    callToActions: CallToActionConfig[]
    tags: TagConfig[]
    playGates: VideoPlayGateConfig[]
    chapters: ChaptersDetails
    replaceHistory: string[]
}

export interface VideoEmbedPreview {
    cssEmbed: string
    key: string
    urlPlayer: string
}

export type FolderAPI = {
    dateCreated: string
    dateUpdated: string | null
    guid: string
    parentFolderGuid: string
    title: string
    videoCount: number
}

export type Folder = FolderAPI & {
    type: string
    autoFocus?: boolean
    folderPath?: string[]
    breadcrumb?: string
}

export type CreateFolderPayload = {
    folder: {
        parentFolderGuid: string
        title: string
    }
}

export type MoveToFolderPayload = {
    targetId: Id
    itemId: Id
}

export type FolderNameChangePayload = {
    folder: Folder
}

export type PublicPreviewPermission = {
    isPublicPreviewAllowed: boolean
}

export type PublicPreviewCode = {
    async: string
    multiple: string
    beta: string
    oEmbedAsync: string
    oEmbedSync: string
    previewAsync: string
    previewSync: string
}

export type VideoEmbed = PublicPreviewPermission & PublicPreviewCode

export enum ErrorType {
    notImage = 'notImage',
    notVideo = 'notVideo',
    bigFile = 'bigFile',
    invalidName = 'invalidName',
    network = 'networkError',
    other = 'other',
    forbidden = 'forbidden',
    sessionExpired = 'sessionExpired',
    excessiveDuration = 'excessiveDuration',
    transferFailed = 'failed_video',
    limitError = 'limitError',
    internalServerError = 'internalServerError',
}

export interface DropboxFile {
    bytes: number
    icon: string
    id: string
    isDir: boolean
    link: string
    linkType: string
    name: string
    thumbnailLink: string
}

export enum TransferStatus {
    inProgress = 'in_progress',
    done = 'done',
    failedVideo = 'failed_video',
    failedSettings = 'failed_settings',
}

export enum TransferPlatform {
    vimeo = 'vimeo',
    youtube = 'youtube',
    wistia = 'wistia',
    jwplayer = 'jw-player',
}
export interface TransferFile {
    guid?: string
    video_id: number | string
    status?: TransferStatus
    name: string
    size: string
    source: TransferPlatform
    migrationType?: TransferSettingsType
    error?: string
}

export interface VideoListItem {
    name: string
    size: string
    id: number
}

export interface UploadingMutationError {
    type?: ErrorType
    message?: string
}

export interface ExtendedFileState {
    startedProcessing?: boolean
    uploaded?: boolean
    errorType?: ErrorType | null
    progress?: number
    video?: Video
    isSettled?: boolean
    canceled?: boolean
    selected?: boolean
    mutationError?: UploadingMutationError
    cancelTokenSource?: CancelTokenSource
}

export interface ExtendedFile {
    id: string
    file?: File
    googleDriveFile?: CallbackDoc
    dropboxFile?: DropboxFile
    state?: ExtendedFileState
    transferFile?: TransferFile
}

export interface GoogleDriveFileUploadPayload {
    googleFileId: string
    authToken: string
    fileName: string
    folderGuid?: string
}

export interface DropboxFileUploadPayload {
    link: string
    folderGuid?: string
}

export type PlaybackOnEndType = 'pause' | 'redirect' | 'replay' | 'display_thumbnail'

export enum Orientation {
    landscape = 'landscape',
    square = 'square',
    portrait = 'portrait',
}

export enum PublishStatus {
    default = 'publish',
    loading = 'publishing',
    done = 'published',
}

export enum TransferSettingsType {
    only_videos = 'only_videos',
    import_settings = 'import_settings',
}

export type VidConditionOption = {
    id: number
    title: string
    value: string | null
}

export type VidConditionOptions = {
    maxWidth: VidConditionOption[]
    'minWidth-maxWidth': VidConditionOption[]
    minWidth: VidConditionOption[]
    isReturning: VidConditionOption[]
    os: VidConditionOption[]
    urlParams: VidConditionOption[]
    videoTag: VidConditionOption[]
    language: VidConditionOption[]
}
