import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { CustomerSubscriptionPlanTier, ApiHandle, Plan, ActivateCustomerPlanPayload } from 'types/Customer'
import { type RootState } from 'App'

export enum SubscriptionSteps {
    ACTIVATION_STEP = 'ACTIVATION_STEP',
    SELECT_PLAN_STEP = 'SELECT_PLAN_STEP',
    CONFIRM_DOWNGRADE = 'CONFIRM_DOWNGRADE',
    WELCOME_STEP = 'WELCOME_STEP',
}

export enum SubscriptionCtaSource {
    HEADER = 'header',
    SIDEBAR = 'sidebar_promo',
    DASHBOARD = 'dashboard',
    USER_SETTINGS = 'user_settings',
    ACCOUNT_SETTINGS = 'account_settings',
    LOCKED_ACCOUNT = 'locked_account',
    UPSELL_BANNER = 'upsell_banner',

    UPLOAD = 'feature_upload',
    UPLOAD_LIMIT = 'upload_limit',
    USAGE_BANNER = 'usage_banner',
    STATS = 'feature_stats',
    CONVERSIONS = 'feature_conversions',
    INTEGRATIONS = 'feature_integrations',
    SMART_VIDS = 'feature_smart_vids',
    VID_SETTINGS = 'feature_vid_settings',
    VID_STATS = 'feature_vid_stats',
    VID_CONDITIONS = 'feature_vid_conditions',

    NOT_DEFINED = 'not_defined',
}

export enum SubscriptionFeatureKey {
    BANDWIDTH = 'bandwidth',
    PLAYS = 'plays',
}

export interface PlanOptions {
    reactivate?: boolean
    downgrade?: boolean
    isTrialStartedMode?: boolean
    step?: SubscriptionSteps
    addons?: ActivateCustomerPlanPayload['addons']
    pricePoint?: ActivateCustomerPlanPayload['pricePoint']
    creditCardToken?: ActivateCustomerPlanPayload['creditCardToken']
}

interface SubscriptionState {
    open: boolean
    step: SubscriptionSteps
    ctaSource?: SubscriptionCtaSource
    plan?: Plan
    lookForTier?: CustomerSubscriptionPlanTier
    lookForApiHandle?: ApiHandle
    planOptions?: PlanOptions
    lockClose?: boolean
    hideBreadcrumb?: boolean
    hideCurrentPlan?: boolean
    highlightFeature?: SubscriptionFeatureKey
    containerRef?: HTMLElement | null
    onClose?(): void
}

interface SubscriptionPayload
    extends Pick<
        SubscriptionState,
        'hideBreadcrumb' | 'hideCurrentPlan' | 'lookForTier' | 'lookForApiHandle' | 'containerRef' | 'highlightFeature'
    > {
    step?: SubscriptionState['step']
    ctaSource?: SubscriptionState['ctaSource']
    onClose?(): void
}

const initialState: SubscriptionState = {
    open: false,
    step: SubscriptionSteps.SELECT_PLAN_STEP,
}

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        openSubscriptionModal: (state, action: PayloadAction<SubscriptionPayload | undefined>) => {
            const newState = state as SubscriptionState

            newState.open = true
            newState.lookForTier = action.payload?.lookForTier
            newState.lookForApiHandle = action.payload?.lookForApiHandle
            newState.containerRef = action.payload?.containerRef
            newState.hideBreadcrumb = action.payload?.hideBreadcrumb
            newState.hideCurrentPlan = action.payload?.hideCurrentPlan
            newState.highlightFeature = action.payload?.highlightFeature
            newState.ctaSource = action.payload?.ctaSource
            newState.onClose = action.payload?.onClose

            if (action.payload?.step) {
                newState.step = action.payload.step
            }
        },
        closeSubscriptionModal: () => initialState,
        setPlan: (state, action: PayloadAction<{ plan?: Plan; options?: PlanOptions }>) => {
            state.plan = action.payload.plan
            state.planOptions = action.payload.options

            if (state.planOptions?.step) {
                state.step = state.planOptions.step
            } else {
                state.step = action.payload.plan
                    ? SubscriptionSteps.ACTIVATION_STEP
                    : SubscriptionSteps.SELECT_PLAN_STEP
            }
        },
    },
})

export const { openSubscriptionModal, closeSubscriptionModal, setPlan } = subscriptionSlice.actions

export const selectSubscription = (state: RootState) => state.subscription

export const subscription = subscriptionSlice.reducer
