/* eslint-disable  @typescript-eslint/no-explicit-any */
import isEmpty from 'lodash/isEmpty'

export type MktParams = {
    [key in MktParamKey]?: string | null
}

export enum MktParamKey {
    utm_source = 'utm_source',
    utm_medium = 'utm_medium',
    utm_campaign = 'utm_campaign',
    utm_content = 'utm_content',
    utm_term = 'utm_term',
    gclid = 'gclid',
    fbclid = 'fbclid',
    _fbp = '_fbp',
    _fbc = '_fbc',
}

export const VIDALYTICS_UTM = 'vidalytics_utm'
export const DEFAULT_PREDEFINED_PARAMS = Object.values(MktParamKey) as MktParamKey[]

export const getMarketingParamsFromURL = (
    predefinedParams: MktParamKey[] = DEFAULT_PREDEFINED_PARAMS,
): MktParams | null => {
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)

    // make URLSearchParams() case insensitive
    const searchParams = new URLSearchParams()
    for (const [name, value] of params) {
        searchParams.append(name.toLowerCase(), value)
    }

    const result: MktParams = {}

    for (const urlElement of predefinedParams) {
        if (searchParams.has(urlElement)) {
            const value = searchParams.get(urlElement)
            result[urlElement as MktParamKey] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : null
        }
    }

    return isEmpty(result) ? null : result
}

export const getMarketingParamsFromCookies = (
    cookies: { [x: string]: any },
    predefinedParams: MktParamKey[] = DEFAULT_PREDEFINED_PARAMS,
): MktParams | null => {
    const paramsFromCookies = {
        ...cookies,
        ...cookies[VIDALYTICS_UTM],
    } as MktParams

    const result: MktParams = {}

    for (const key of predefinedParams) {
        result[key as MktParamKey] = paramsFromCookies[key]
    }

    return result
}

export const getMarketingParamsAll = (
    cookies: {
        [x: string]: any
    },
    predefinedParams?: MktParamKey[],
): MktParams | null => {
    const paramsFromCookies = getMarketingParamsFromCookies(cookies, predefinedParams)
    const paramsFromURL = getMarketingParamsFromURL(predefinedParams)

    const mergedParams = {
        ...(paramsFromCookies ?? {}),
        ...(paramsFromURL ?? {}),
    }

    return Object.keys(mergedParams).length === 0 ? null : mergedParams
}

export const objectToQueryString = (obj: { [key: string]: any }): string => {
    let queryString = ''
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (queryString.length > 0) {
                queryString += '&'
            }
            queryString += `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        }
    }

    return queryString
}
