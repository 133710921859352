export const getDomainEnvPrefix = (url = window.location.hostname) => {
    const match = url.match(/(?:\b|\.)([a-zA-Z0-9-]+)\.[a-zA-Z0-9-]+\.[a-zA-Z]+$/)
    const res = match ? match[1] : null

    if (res === 'apps') {
        return ''
    }

    return res
}
