import { TypographyOptions } from '@mui/material/styles/createTypography'
import { converter } from 'utils/pxToRem.utils'

export const font = {
    inter: {
        light: 'Inter, sans-serif', // 300
        regular: 'Inter, sans-serif', // 400
        medium: 'Inter, sans-serif', // 500
        semibold: 'Inter, sans-serif', // 600
        bold: 'Inter, sans-serif', // 700
        extraBold: 'Inter, sans-serif', // 800
    },
    openSans: {
        light: 'open-sans-light, sans-serif;', // 300
    },
}

export const fontSx = {
    inter: {
        light: {
            fontFamily: font.inter.light,
        },
        regular: {
            fontFamily: font.inter.regular,
        },
        medium: {
            fontFamily: font.inter.medium,
        },
        semibold: {
            fontFamily: font.inter.semibold,
        },
        bold: {
            fontFamily: font.inter.bold,
        },
        extraBold: {
            fontFamily: font.inter.extraBold,
        },
    },
}

const fontSize = 16

const fontFeatureSettings = "'clig' off, 'liga' off"

export const { pxToRem } = converter(fontSize)

const variants = {
    body1: {
        fontFeatureSettings,
        fontSize: pxToRem(16),
        lineHeight: '150%',
        letterSpacing: '0.15px',
    },
    body2: {
        fontFeatureSettings,
        fontSize: pxToRem(14),
        lineHeight: '143%',
        letterSpacing: 'unset',
    },
    caption1: {
        fontFeatureSettings,
        fontSize: pxToRem(13),
        lineHeight: '142%',
        letterSpacing: '0',
    },
    caption2: {
        fontFeatureSettings,
        fontSize: pxToRem(12),
        lineHeight: '166%',
        letterSpacing: '0.4px',
    },
    caption3: {
        fontFeatureSettings,
        fontSize: pxToRem(10),
        lineHeight: '140%',
        letterSpacing: 0,
    },
    h1: {
        fontFeatureSettings,
        fontSize: pxToRem(96),
        lineHeight: '116.7%',
        letterSpacing: '-1.5px',
    },
    h2: {
        fontFeatureSettings,
        fontSize: pxToRem(60),
        lineHeight: '120%',
        letterSpacing: '-0.5px',
    },
    h3: {
        fontFeatureSettings,
        fontSize: pxToRem(48),
        lineHeight: '116.7%',
    },
    h4: {
        fontFeatureSettings,
        fontSize: pxToRem(34),
        lineHeight: '123.5%',
    },
    h5: {
        fontFeatureSettings,
        fontSize: pxToRem(30),
        lineHeight: '126.5%',
    },
    h6: {
        fontFeatureSettings,
        fontSize: pxToRem(24),
        lineHeight: '133.5%',
    },
    h7: {
        fontFeatureSettings,
        fontSize: pxToRem(20),
        lineHeight: '160%',
        letterSpacing: '0.15px',
    },
    label: {
        fontFeatureSettings,
        fontSize: pxToRem(14),
        lineHeight: '141%',
    },
    subtitle1: {
        fontFeatureSettings,
        fontSize: pxToRem(18),
        lineHeight: '152%',
    },
    subtitle2: {
        fontFeatureSettings,
        fontSize: pxToRem(16),
        lineHeight: '175%',
        letterSpacing: '0.15px',
    },
    subtitle3: {
        fontFeatureSettings,
        fontSize: pxToRem(14),
        lineHeight: '157%',
        letterSpacing: '0.1px',
    },
    highlight: {
        fontFeatureSettings,
        fontSize: pxToRem(64),
        lineHeight: '100%',
        letterSpacing: '-1.28px',
    },
}

/**
 * Workaround for TypeScript to sync the custom typography variants usage.
 * Needed because, for some reason, removing variant 'caption' doesn't work in 'TypographyPropsVariantOverrides'.
 */
export type CustomTypographyVariant = keyof typeof variants

export const typography: TypographyOptions = {
    ...variants,
    fontFamily: font.inter.regular,
    htmlFontSize: fontSize,
    overline: {
        fontFeatureSettings,
        fontSize: pxToRem(12),
        lineHeight: '266%',
        letterSpacing: '1px',
        textTransform: 'uppercase',
    },
}
