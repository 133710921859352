import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useQuery, UseQueryOptions } from 'react-query'
import { format } from 'date-fns'

import {
    selectStatsDateRange,
    selectStatsSecondaryDateRange,
} from 'design/pages/VidStats/components/DatePicker/statsDateRange.slice'
import { SegmentRequest } from 'design/pages/VidStats/constants'
import { StatisticsData, SegmentType, PlayerBlockView } from 'design/pages/VidStats/types'
import { statsAPI } from 'api/controllers'
import { VIDEO_STATS_QUERY } from 'api/constants'
import { useAppSelector } from 'App'
import { selectPlayerBlockView } from 'design/pages/VidStats/components/PlayerBlock/MetricsMenu/MetricsSwitches.slice'
import useSvIds from 'design/pages/SmartVid/hooks/useSvIds'

const VIDEO_PARAMS = ['watches', 'conversions', 'rewinds', 'skips']
const TIMELINE_PARAMS = [
    'plays',
    'play_rate',
    'impressions',
    'unique_viewers',
    'unmute_rate',
    'avg_watched',
    'conversion_rate',
    'conversions',
    'average_order',
    'opt_in',
    'opt_in_rate',
    'revenue',
    'bounce_rate',
    'revenue_per_viewer',
    'unmute_plays',
]

interface VideoStatsOptions extends UseQueryOptions<StatisticsData, unknown> {
    dateRange?: { from: Date; to: Date }
}

export const useVideoStatsQuery = (selectedGuid?: string, options?: VideoStatsOptions) => {
    const { segmentType, segmentParamName } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const { videoId, video } = useSvIds(true)
    const dates = useAppSelector(selectStatsDateRange)
    const playerBlockView = useAppSelector(selectPlayerBlockView)
    const secondaryDates = useAppSelector(selectStatsSecondaryDateRange)

    const isDemo = searchParams?.get('isDemo')
    const [firstSegmentType, secondSegmentType] = segmentType?.split('&') || []
    const firstSegment = SegmentRequest[(firstSegmentType as SegmentType) || SegmentType.item]
    const secondSegment = secondSegmentType ? SegmentRequest[secondSegmentType as SegmentType] : ''
    const segment = !secondaryDates
        ? secondSegment
            ? `${firstSegment},${secondSegment}`
            : firstSegment
        : SegmentRequest[SegmentType.item]

    const encodedSegmentParamName = segmentParamName?.split('&').map(encodeURIComponent).join(',')
    const segmentParam = segmentParamName ? `&paramName=${encodedSegmentParamName}` : ''

    const dateFrom = format(options?.dateRange?.from || dates[0], 'yyyy-MM-dd')
    const dateTo = format(options?.dateRange?.to || dates[1], 'yyyy-MM-dd')
    const videoParams =
        `segment=${segment}&dateFrom=${dateFrom}&dateTo=${dateTo}${segmentParam}&` +
        VIDEO_PARAMS.map((param: string) => `metrics[]=metric.${param}`).join('&')
    const timelineParams =
        `segment=${segment}&dateFrom=${dateFrom}&dateTo=${dateTo}${segmentParam}&presentationType=timeline&` +
        TIMELINE_PARAMS.map((param: string) => `metrics[]=metric.${param}`).join('&')
    let initialParams = playerBlockView === PlayerBlockView.timeline ? timelineParams : videoParams
    if (isDemo || video?.video?.isDemo) {
        initialParams += '&demoData=true'
    }

    const [params, setParams] = useState(initialParams)

    const id = selectedGuid || videoId

    useEffect(() => {
        if (video?.video?.isDemo && !params?.includes('demoData')) {
            const newParams = new URLSearchParams(searchParams)
            newParams.set('isDemo', 'true')
            setSearchParams(newParams)
            setParams(`${initialParams}&demoData=true`)
        } else {
            setParams(initialParams)
        }
    }, [video, initialParams])

    return useQuery<StatisticsData>(
        [VIDEO_STATS_QUERY, id, segment, dateFrom, dateTo, segmentParam, params],
        () => statsAPI.getVideoStats(String(id), params),
        {
            enabled: Boolean(id),
            select: (data) => ({
                ...data,
                response: {
                    ...data.response,
                    data: {
                        ...data.response.data,
                        segments: data.response.data.segments.sort(
                            (a, b) => Number(b.metrics.plays) - Number(a.metrics.plays),
                        ),
                    },
                },
            }),
            ...options,
        },
    )
}
