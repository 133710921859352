import { Stack, Typography } from '@mui/material'
import AstronautWithFlag from 'assets/icons/astronaut/AstronautWithFlag'
import { FC } from 'react'
import styles from './ApiError.styles'
import locale from './ApiError.locale'

type ApiErrorProps = {
    redesigned?: boolean
}

export const ApiError: FC<ApiErrorProps> = ({ redesigned }) => {
    return (
        <Stack sx={styles.wrapper(redesigned)}>
            <AstronautWithFlag color="primary" sx={styles.icon} />

            <Stack sx={styles.textWrapper}>
                <Typography variant="subtitle2" sx={styles.textTypography}>
                    {locale.error}
                </Typography>
                <Typography variant="subtitle2" sx={styles.textTypography}>
                    {locale.message}
                </Typography>
            </Stack>
        </Stack>
    )
}
