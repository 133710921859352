import { Box, Tooltip } from '@mui/material'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import Typography from '@mui/material/Typography'

import DataGridTable from 'design/atoms/DataGridTable'
import { getDateAgoWithRightTimezone } from 'utils/dates'
import useMyVidsEmptyState from 'design/organisms/MyVids/hooks/useMyVidsEmptyState'
import { ImgWithErrorHandler } from 'design/atoms/ImgWithErrorHandler'
import { ExpandedVideosWithFolders } from 'design/organisms/MyVids'
import { Link } from 'design/atoms/Link'
import RowActions from 'design/organisms/MyVids/VidsTable/RowActions/RowActions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { MouseEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { GridSortModel } from '@mui/x-data-grid'
import { SelectedAction } from 'design/atoms/DataGridTable/components'
import { GridActionsCellItem, GridActionsCellItemProps, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { MoveFolderModal, openMyVidsConfirmDeleteModal, openMyVidsRenameFolderOrVideo } from 'design/templates/Modal'
import { useAppDispatch } from 'App'
import {
    useDuplicateVideoMutation,
    useMoveFolderToFolderMutation,
    useMoveToFolderMutation,
    usePublishVideoMutation,
} from 'api/mutations'
import useUpgradeCallback from 'hooks/user/useUpgradeCallback'
import { useLayout } from 'hooks/utilities/useLayout'
import { GridActionsColDef } from '@mui/x-data-grid/models/colDef/gridColDef'
import { DeleteOutlineRounded, Edit as EditIcon } from '@mui/icons-material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FolderMoveIcon from '@mui/icons-material/DriveFileMove'
import useSettingsLinkProps from 'design/pages/VidSettings/hooks/useSettingsLinkProps'
import { ModalType, openModal } from 'design/templates/Modal/ModalTypes/modal.slice'
import { FOLDER } from 'design/atoms/Table/constants'
import { useTranslation } from 'react-i18next'
import { VIDEO_COUNT } from 'design/atoms/Table/Row/Folder/constants'
import route from 'constants/routes'
import { COLUMN } from 'constants/table.constants'
import styles from './VidsTable.style'

interface MyVidsTableProps {
    data: ExpandedVideosWithFolders
    isLoading: boolean
}

interface ExpandedVideoOrFolder {
    guid: string
    type: string
    children?: ExpandedVideoOrFolder[]
}

type SelectedIds = {
    folderIds: string[]
    videoIds: string[]
}

const VidsTable = ({ data, isLoading }: MyVidsTableProps) => {
    const [openMoveFolderModal, setOpenMoveFolderModal] = useState({
        open: false,
        rowId: '',
    })
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
    const emptyState = useMyVidsEmptyState()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: COLUMN.DATE_PUBLISHED,
            sort: 'desc',
        },
    ])
    const dispatch = useAppDispatch()
    const duplicateVideoMutation = useDuplicateVideoMutation()
    const publishVideoMutation = usePublishVideoMutation()
    const moveToFolderMutation = useMoveToFolderMutation()
    const moveFolderToFolderMutation = useMoveFolderToFolderMutation()
    const upgradeCallback = useUpgradeCallback()
    const settingsLinkProps = useSettingsLinkProps()
    const { isMobile } = useLayout()
    const parentRef = useRef<HTMLDivElement>(null)

    const pageSizeOptions = [25, 50, 250]
    const defaultPageSize = 25

    const page = Number(searchParams.get('page')) || 1
    const pageSizeParam = Number(searchParams.get('rowsPerPage'))
    const pageSize = pageSizeOptions.includes(pageSizeParam) ? pageSizeParam : defaultPageSize

    useEffect(() => {
        if (!pageSizeOptions.includes(pageSizeParam)) {
            setSearchParams((prev) => ({
                ...Object.fromEntries(prev.entries()),
                page: '1',
                rowsPerPage: String(defaultPageSize),
            }))
        }
    }, [pageSizeParam, setSearchParams])

    const handlePaginationChange = (paginationModel: { page: number; pageSize: number }) => {
        setSearchParams({
            page: paginationModel.pageSize !== pageSize ? '1' : String(paginationModel.page + 1),
            rowsPerPage: String(
                pageSizeOptions.includes(paginationModel.pageSize) ? paginationModel.pageSize : defaultPageSize,
            ),
        })
    }

    const onFolderClick = (folderId: string) => {
        navigate(route.video.byId({ guid: folderId }))
    }

    const handleMoveFolderModal = (isOpen: boolean, rowId: string) => {
        setOpenMoveFolderModal({
            open: isOpen,
            rowId: rowId,
        })
    }

    const getSelectedIds = (data: ExpandedVideoOrFolder[], rowSelectionModel: GridRowSelectionModel): SelectedIds => {
        return data.reduce(
            (acc: SelectedIds, item: ExpandedVideoOrFolder) => {
                if (rowSelectionModel.includes(item.guid)) {
                    if (item.type === FOLDER) {
                        acc.folderIds.push(item.guid)
                    } else {
                        acc.videoIds.push(item.guid)
                    }
                }

                return acc
            },
            { folderIds: [], videoIds: [] },
        )
    }

    const openConfirmationModal = (rowSelection: readonly GridRowId[]) => {
        const selectedIds = getSelectedIds(data, rowSelection)

        if (selectedIds.folderIds.length) {
            dispatch(
                openMyVidsConfirmDeleteModal({
                    rowType: FOLDER,
                    selectedMyVidsRows: selectedIds.folderIds,
                }),
            )
        }
        if (selectedIds.videoIds.length) {
            dispatch(
                openMyVidsConfirmDeleteModal({
                    selectedMyVidsRows: selectedIds.videoIds,
                }),
            )
        }
    }

    const handleDrop = async (rowId: string, targetId: string) => {
        const selectedIds = getSelectedIds(data, rowSelectionModel)

        if (selectedIds.folderIds.length) {
            await moveFolderToFolderMutation.mutateAsync(selectedIds.folderIds.map((id) => ({ itemId: id, targetId })))
        }
        if (selectedIds.videoIds.length) {
            await moveToFolderMutation.mutateAsync(selectedIds.videoIds.map((id) => ({ itemId: id, targetId })))
        }

        if (rowId) {
            await moveToFolderMutation.mutateAsync([{ itemId: rowId, targetId }])
        }
    }

    const onModalDrop = async (target: string) => {
        await handleDrop(openMoveFolderModal.rowId, target)
    }

    const handlePublish = (rowSelection: readonly GridRowId[], rowId?: string) => {
        const selectedIds = getSelectedIds(data, rowSelection)
        if (selectedIds.videoIds.length) {
            upgradeCallback(() => publishVideoMutation.mutate(selectedIds.videoIds))({} as MouseEvent)
        }
        if (rowId) {
            upgradeCallback(() => publishVideoMutation.mutate([rowId]))({} as MouseEvent)
        }
    }

    const handleDuplicate = (rowSelection: readonly GridRowId[], rowId?: string) => {
        const selectedIds = getSelectedIds(data, rowSelection)
        if (selectedIds.videoIds.length) {
            duplicateVideoMutation.mutate(selectedIds.videoIds)
        }
        if (rowId) {
            duplicateVideoMutation.mutate([rowId])
        }
    }

    const isFolderSelection = useMemo(() => {
        return rowSelectionModel.every((id) => {
            const row = data.find((item) => item.guid === id)
            return row?.type === FOLDER
        })
    }, [rowSelectionModel, data])

    const isVideoSelection = useMemo(() => {
        return rowSelectionModel.every((id) => {
            const row = data.find((item) => item.guid === id)
            return row?.type !== FOLDER
        })
    }, [rowSelectionModel, data])

    const folderActions: SelectedAction[] = [
        {
            actionClick: (rowSelection) => handlePublish(rowSelection),
            label: t('Publish'),
            icon: <PublishRoundedIcon />,
            sx: styles.selectedAction,
        },
        {
            actionClick: () => handleMoveFolderModal(true, ''),
            label: t('Move to Folder'),
            icon: <SwapHorizRoundedIcon />,
            sx: styles.selectedAction,
        },
        {
            actionClick: openConfirmationModal,
            label: t('Delete'),
            icon: <DeleteOutlineRoundedIcon />,
            sx: styles.selectedAction,
        },
    ]

    const videoActions: SelectedAction[] = [
        {
            actionClick: (rowSelection) => handlePublish(rowSelection),
            label: t('Publish'),
            icon: <PublishRoundedIcon />,
            sx: styles.selectedAction,
        },
        {
            actionClick: () => handleMoveFolderModal(true, ''),
            label: t('Move to Folder'),
            icon: <SwapHorizRoundedIcon />,
            sx: styles.selectedAction,
        },
        {
            actionClick: (rowSelection) => handleDuplicate(rowSelection),
            label: t('Duplicate'),
            icon: <ContentCopyRoundedIcon />,
            sx: styles.selectedAction,
        },
        {
            actionClick: openConfirmationModal,
            label: t('Delete'),
            icon: <DeleteOutlineRoundedIcon />,
            sx: styles.selectedAction,
        },
    ]

    const mixedActions: SelectedAction[] = [
        {
            actionClick: (rowSelection) => handlePublish(rowSelection),
            label: t('Publish'),
            icon: <PublishRoundedIcon />,
            sx: styles.selectedAction,
        },
        {
            actionClick: () => handleMoveFolderModal(true, ''),
            label: t('Move to Folder'),
            icon: <SwapHorizRoundedIcon />,
            sx: styles.selectedAction,
        },
        {
            actionClick: openConfirmationModal,
            label: t('Delete'),
            icon: <DeleteOutlineRoundedIcon />,
            sx: styles.selectedAction,
        },
    ]

    const getActions: GridActionsColDef['getActions'] = (params) => {
        const actions: ReactElement<GridActionsCellItemProps>[] = []
        const rowId = params.row.guid
        const isFolder = params.row.type === FOLDER

        if (isFolder) {
            actions.push(
                <GridActionsCellItem
                    key={params.id + 'rename'}
                    icon={<DriveFileRenameOutlineIcon />}
                    label={t('Rename')}
                    sx={{ textTransform: 'capitalize' }}
                    aria-label={'More'}
                    onClick={() =>
                        dispatch(
                            openMyVidsRenameFolderOrVideo({
                                rowType: params.row.type,
                                rowId,
                                title: params.row.title,
                            }),
                        )
                    }
                    showInMenu
                />,
                <GridActionsCellItem
                    key={params.id + 'duplicate'}
                    icon={<ContentCopyIcon />}
                    label={t('Duplicate')}
                    sx={{ textTransform: 'capitalize' }}
                    aria-label={'More'}
                    onClick={() => handleDuplicate([], rowId)}
                    showInMenu
                />,
                <GridActionsCellItem
                    key={params.id + 'delete'}
                    icon={<DeleteOutlineRounded color="error" />}
                    aria-label={'More'}
                    sx={styles.actionDelete}
                    label={t('Delete')}
                    onClick={() =>
                        dispatch(
                            openMyVidsConfirmDeleteModal({
                                rowType: params.row.type,
                                selectedMyVidsRows: [rowId],
                            }),
                        )
                    }
                    showInMenu
                />,
            )
        } else {
            actions.push(
                <GridActionsCellItem
                    key={params.id + 'edit'}
                    icon={<EditIcon />}
                    label={t('Edit')}
                    onClick={() => navigate(settingsLinkProps.getLink(rowId))}
                    showInMenu
                />,
                <GridActionsCellItem
                    key={params.id + 'publish'}
                    icon={<CloudUploadIcon />}
                    label={t('Publish')}
                    sx={{ textTransform: 'capitalize' }}
                    aria-label={'More'}
                    onClick={() => handlePublish([], rowId)}
                    showInMenu
                />,
                <GridActionsCellItem
                    key={params.id + 'share'}
                    icon={<ShareRoundedIcon />}
                    label={t('Embed/Share')}
                    sx={{ textTransform: 'capitalize' }}
                    aria-label={'More'}
                    onClick={() => dispatch(openModal({ type: ModalType.VIDEO_EMBED_CODE, videoId: rowId }))}
                    showInMenu
                />,
                <GridActionsCellItem
                    key={params.id + 'rename'}
                    icon={<DriveFileRenameOutlineIcon />}
                    label={t('Rename')}
                    sx={{ textTransform: 'capitalize' }}
                    aria-label={'More'}
                    onClick={() =>
                        dispatch(
                            openMyVidsRenameFolderOrVideo({
                                rowType: params.row.type,
                                rowId,
                                title: params.row.title,
                            }),
                        )
                    }
                    showInMenu
                />,
                <GridActionsCellItem
                    key={params.id + 'duplicate'}
                    icon={<ContentCopyIcon />}
                    label={t('Duplicate')}
                    sx={{ textTransform: 'capitalize' }}
                    aria-label={'More'}
                    onClick={() => handleDuplicate([], rowId)}
                    showInMenu
                />,
                <GridActionsCellItem
                    key={params.id + 'move'}
                    icon={<FolderMoveIcon />}
                    label={t('Move to Folder')}
                    sx={{ textTransform: 'capitalize' }}
                    aria-label={'More'}
                    onClick={() => handleMoveFolderModal(true, params.row.guid)}
                    showInMenu
                    divider
                />,
                <GridActionsCellItem
                    key={params.id + 'delete'}
                    icon={<DeleteOutlineRounded color="error" />}
                    aria-label={'More'}
                    sx={styles.actionDelete}
                    label={t('Delete')}
                    onClick={() =>
                        dispatch(
                            openMyVidsConfirmDeleteModal({
                                rowType: params.row.type,
                                selectedMyVidsRows: [rowId],
                            }),
                        )
                    }
                    showInMenu
                />,
            )
        }

        return actions
    }

    const selectedActions = isFolderSelection ? folderActions : isVideoSelection ? videoActions : mixedActions

    const getAllVideoIdsInFolder = (folderId: string): string[] => {
        const videoIds: string[] = []

        const findFolderAndCollectVideos = (items: ExpandedVideoOrFolder[]): void => {
            for (const item of items) {
                if (item.guid === folderId && item.type === FOLDER) {
                    collectVideoIds(item)
                    return
                }

                if (item.children) {
                    findFolderAndCollectVideos(item.children)
                }
            }
        }

        const collectVideoIds = (folder: ExpandedVideoOrFolder): void => {
            if (!folder.children) return

            for (const item of folder.children) {
                if (item.type === FOLDER) {
                    collectVideoIds(item)
                } else {
                    videoIds.push(item.guid)
                }
            }
        }

        findFolderAndCollectVideos(data)
        return videoIds
    }

    return (
        <>
            <DataGridTable
                rows={data}
                parentRef={parentRef}
                propsContainer={{
                    sx: styles.container,
                }}
                loading={isLoading}
                columns={[
                    {
                        field: 'title',
                        headerName: t('Name'),
                        flex: 1,
                        minWidth: 300,
                        editable: false,
                        renderCell: (params) => (
                            <Box sx={styles.titleRowContainer}>
                                {params.row.type === FOLDER ? (
                                    <FolderRoundedIcon sx={styles.folderIcon} />
                                ) : (
                                    <Box sx={styles.imageContainer}>
                                        <ImgWithErrorHandler
                                            src={params.row.thumbnail?.dashboardSrc}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                borderRadius: '6px',
                                            }}
                                        />
                                    </Box>
                                )}
                                <Link
                                    sx={styles.title}
                                    external={settingsLinkProps.external}
                                    to={settingsLinkProps.getLink(params.row.guid)}
                                >
                                    <Tooltip enterDelay={1500} title={params.value}>
                                        <Typography noWrap>{params.value}</Typography>
                                    </Tooltip>
                                    {params.row.type === FOLDER && (
                                        <Typography variant="caption2" color="text.secondary">
                                            {t('folderVideoCountEnding', { count: params.row[VIDEO_COUNT] })}
                                        </Typography>
                                    )}
                                </Link>

                                {!isMobile && (
                                    <Box sx={styles.rowActionsContainer} className="RowActionsOuter">
                                        <RowActions
                                            type={params.row.type}
                                            rowId={params.row.guid}
                                            title={params.value}
                                            handleMoveFolderModal={handleMoveFolderModal}
                                            isReady={params.row.isReady}
                                            getAllVideoIdsInFolder={getAllVideoIdsInFolder}
                                        />
                                    </Box>
                                )}
                            </Box>
                        ),
                    },

                    {
                        field: COLUMN.DATE_UPDATED,
                        minWidth: 200,
                        headerName: t('Updated'),
                        editable: false,
                        renderCell: (params) =>
                            params.row.type !== FOLDER && (
                                <Box sx={styles.typographyContainer}>
                                    <Typography variant="body2">{getDateAgoWithRightTimezone(params.value)}</Typography>
                                </Box>
                            ),
                    },
                    {
                        field: COLUMN.DATE_PUBLISHED,
                        headerName: t('Published'),
                        minWidth: 200,
                        editable: false,
                        renderCell: (params) =>
                            params.row.type !== FOLDER && (
                                <Box sx={styles.typographyContainer}>
                                    <Typography variant="body2">{getDateAgoWithRightTimezone(params.value)}</Typography>
                                    {params.row.isUnpublished && (
                                        <Typography variant="body2" color="error.light" component="span">
                                            {t('Unpublished Changes')}
                                        </Typography>
                                    )}
                                    {!params.row.isReady && (
                                        <Typography variant="body2" color="primary.main" component="span">
                                            {t('Processing')}
                                        </Typography>
                                    )}
                                </Box>
                            ),
                    },
                    {
                        field: 'plays',
                        headerName: t('Plays'),
                        minWidth: 100,
                        editable: false,
                        renderCell: (params) =>
                            params.row.type !== FOLDER && (
                                <Box sx={styles.typographyContainer}>
                                    <Typography variant="body2">{params.value?.toLocaleString('en-US')}</Typography>
                                </Box>
                            ),
                    },
                    {
                        field: 'actions',
                        type: 'actions',
                        resizable: false,
                        width: 50,
                        getActions,
                    },
                    {
                        field: 'type',
                        minWidth: 65,
                        editable: false,
                    },
                ]}
                selectedActions={selectedActions}
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={setRowSelectionModel}
                propsTable={{
                    initialState: {
                        sorting: {
                            sortModel: [
                                {
                                    field: COLUMN.DATE_PUBLISHED,
                                    sort: 'desc',
                                },
                            ],
                        },
                    },
                    sortModel: [{ field: 'type', sort: 'desc' }, ...sortModel],
                    onSortModelChange: (model) => setSortModel(model),
                    pagination: true,
                    pageSizeOptions,
                    paginationModel: {
                        page: page - 1,
                        pageSize,
                    },
                    checkboxSelection: true,
                    getRowHeight: () => 'auto',
                    onPaginationModelChange: handlePaginationChange,
                    onCellClick: (params) => {
                        if (params.row.type === FOLDER && params.field !== '__check__' && params.field !== 'actions') {
                            onFolderClick(params.row.guid)
                        }
                    },
                    columnVisibilityModel: {
                        type: false,
                        actions: isMobile,
                    },
                }}
                {...(emptyState && { noRowsConfig: emptyState })}
            />

            <MoveFolderModal
                open={openMoveFolderModal.open}
                onClose={() => handleMoveFolderModal(false, '')}
                onDrop={onModalDrop}
            />
        </>
    )
}

export default VidsTable
