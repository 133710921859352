import { UseMutationOptions } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import {
    SIGN_UP_CARD_UPDATE_MUTATION,
    CUSTOMER_QUERY,
    CUSTOMER_SUBSCRIPTION_QUERY,
    PAYMENT_PROFILE_QUERY,
} from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { UpdateCardInfoByTokenPayload } from 'types/Customer'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useUpdateSignUpCardByTokenMutation = (
    options?: UseMutationOptions<null, any, UpdateCardInfoByTokenPayload>,
) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, UpdateCardInfoByTokenPayload>(
        SIGN_UP_CARD_UPDATE_MUTATION,
        async (payload: UpdateCardInfoByTokenPayload) =>
            mutate(userAPI.updateSignUpCardByToken(payload), apiLocale.user.updateCreditCard),
        {
            ...options,
            invalidate: [CUSTOMER_QUERY, CUSTOMER_SUBSCRIPTION_QUERY, PAYMENT_PROFILE_QUERY],
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
