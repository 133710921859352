import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import { Button, Stack, Typography } from '@mui/material'
import { pxToRem } from 'styles/theme/typography'
import { useTranslation } from 'react-i18next'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useSearchParams } from 'react-router-dom'

import { ReactComponent as CongratsIcon } from 'assets/img/agency/checkmark-with-confetti.svg'

import { SuccessMessage } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SuccessMessage'
import { useToast } from 'design/organisms/ToastProvider'
import { useAppDispatch } from 'App'
import { ApiHandle, CustomerSubscriptionPlanTier, Plan } from 'types/Customer'
import { useCustomerSubscriptionQuery, usePlanProrateQuery, usePlanQuery, usePlansQuery } from 'api/queries'
import { useActivatePlanMutation } from 'api/mutations'
import { setTokenRefreshing } from 'design/organisms/Account/account.slice'
import { ChargifyError, useChagifyTokenStatus, useShowErrorAddCard } from 'hooks/chargify.hooks'
import {
    PlanOptions,
    SubscriptionSteps,
} from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import useTariffPlans from 'hooks/user/useTariffPlans'
import { SetPlan } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SubscriptionModal'

import { getBillingPeriod } from 'utils/dates'
import { LoadingButton } from '@mui/lab'
import { useIsMutating } from 'react-query'
import { PAYMENT_PROFILE_ACTIVATE_MUTATION } from 'api/constants'
import useMixpanel, { MIXPANEL_EVENTS } from 'hooks/analytics/useMixpanel'
import PlanDetails from './PlanDetails'
import PaymentInfo from './PaymentInfo'

type ActivationStepProps = {
    selectedPlan?: Plan
    lookForTier?: CustomerSubscriptionPlanTier
    lookForApiHandle?: ApiHandle
    planOptions?: PlanOptions
    hideBreadcrumb?: boolean
    setPlan: SetPlan
    onClose: () => void
}

const ActivationStep = ({
    selectedPlan,
    lookForTier,
    lookForApiHandle,
    planOptions,
    setPlan,
    onClose,
}: ActivationStepProps) => {
    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const { data: subscription, isLoading } = useCustomerSubscriptionQuery()
    const { data: plans } = usePlansQuery()
    const { showToast } = useToast()
    const { data: planByApiHandle } = usePlanQuery(lookForApiHandle as string, {
        enabled: Boolean(lookForApiHandle),
        onError: () => {
            showToast({
                message: t('Failed to load plan'),
                type: 'error',
            })
            onClose()
        },
    })

    const { track } = useMixpanel()
    const { chargifyTokenError, chargifyTokenSuccess } = useChagifyTokenStatus()

    const chargify = useRef(window.Chargify ? new window.Chargify() : null)
    const formRef = useRef(null)
    const [openForm, setOpenForm] = useState(false)
    const [reactivationSuccess, setReactivationSuccess] = useState(false)
    const [isLoadingActivation, setIsLoadingActivation] = useState(false)

    const isCardChanging = useIsMutating({ mutationKey: [PAYMENT_PROFILE_ACTIVATE_MUTATION] })

    const showErrorAddCard = useShowErrorAddCard()

    const showWelcomeMessage = useCallback(() => {
        setPlan(undefined, { step: SubscriptionSteps.WELCOME_STEP })
    }, [setPlan])

    const activatePlanMutation = useActivatePlanMutation({
        onSuccess: async () => {
            return planOptions?.reactivate ? setReactivationSuccess(true) : showWelcomeMessage()
        },
    })
    const { pro, premium } = useTariffPlans()

    useEffect(() => {
        return () => {
            dispatch(setTokenRefreshing({ isTokenRefreshing: false }))
        }
    }, [])

    const plan = useMemo(() => {
        if (lookForApiHandle) {
            return planByApiHandle
        }

        if (selectedPlan || !plans?.length) {
            return selectedPlan
        }

        return (
            plans.find((p) =>
                lookForTier
                    ? p.tier === lookForTier
                    : p.tier === CustomerSubscriptionPlanTier.proIv || p.tier === CustomerSubscriptionPlanTier.pro,
            ) ||
            premium ||
            pro
        )
    }, [selectedPlan, lookForApiHandle, planByApiHandle, plans, pro, premium])
    const urlPricePoint = searchParams.get('pricepoint') ?? undefined
    const { data: planProrate, isLoading: isPlanProrateLoading } = usePlanProrateQuery(plan?.apiHandle, urlPricePoint)

    const openCardForm = isLoading ? false : Boolean(openForm || !subscription?.creditCard?.cardNumber)
    const isTrialStartedMode = planOptions?.isTrialStartedMode

    const dueToday = useMemo(() => {
        const cents = planProrate?.migration.payment_due_in_cents || 0

        if (cents) {
            return String(cents / 100)
        }

        return plan?.price
    }, [planProrate, plan])

    const handleActivate = async () => {
        track(MIXPANEL_EVENTS.UPGRADE_PAYMENT_CONFIRMED, {
            oldtier: subscription?.plan.tier,
            newtier: plan?.tier,
        })

        if (!plan) {
            return
        }

        const apiHandle = planOptions?.reactivate ? subscription?.plan.apiHandle : plan.apiHandle
        const pricePoint = urlPricePoint ?? subscription?.pricePoint
        const addons = planOptions?.addons

        if (!apiHandle) {
            return
        }

        setIsLoadingActivation(true)

        if (openCardForm) {
            if (!navigator.onLine) {
                setIsLoadingActivation(false)
                showErrorAddCard('Unable to save payment method. Please check your connection and try again.')
            }

            return chargify.current?.token(
                formRef.current,

                chargifyTokenSuccess(async (token) => {
                    dispatch(setTokenRefreshing({ isTokenRefreshing: true }))

                    if (planOptions?.downgrade) {
                        setIsLoadingActivation(false)
                        return setPlan(plan, {
                            step: SubscriptionSteps.CONFIRM_DOWNGRADE,
                            creditCardToken: token,
                            pricePoint,
                            addons,
                        })
                    }

                    try {
                        await activatePlanMutation.mutateAsync({
                            apiHandle,
                            creditCardToken: token,
                            pricePoint,
                            addons,
                        })
                    } catch (error) {
                        Sentry.captureException(error)
                    } finally {
                        setIsLoadingActivation(false)
                    }
                }),
                (error: ChargifyError) => {
                    setIsLoadingActivation(false)
                    chargifyTokenError(error)
                },
            )
        }

        if (planOptions?.downgrade) {
            setIsLoadingActivation(false)
            return setPlan(plan, {
                step: SubscriptionSteps.CONFIRM_DOWNGRADE,
                pricePoint,
                addons,
            })
        }

        try {
            await activatePlanMutation.mutateAsync({ apiHandle, pricePoint, addons })
        } catch (error) {
            Sentry.captureException(error)
        } finally {
            setIsLoadingActivation(false)
        }
    }

    useEffect(() => {
        const handleOffline = () => {
            if (isLoadingActivation) {
                setIsLoadingActivation(false)
                showErrorAddCard('Unable to save payment method. Please check your connection and try again.')
            }
        }

        window.addEventListener('offline', handleOffline)

        return () => {
            window.removeEventListener('offline', handleOffline)
        }
    }, [isLoadingActivation])

    const handleCancel = () => {
        track(MIXPANEL_EVENTS.UPGRADE_PAYMENT_SKIPPED, {
            oldtier: subscription?.plan.tier,
            newtier: plan?.tier,
        })
        isTrialStartedMode ? showWelcomeMessage() : onClose()
    }

    if (reactivationSuccess) {
        return (
            <SuccessMessage
                mainTitle={t('Reactivation Success!')}
                title={t('Reactivation')}
                description={t('You have successfully reactivated your account!')}
                onClose={onClose}
            />
        )
    }

    return (
        <Stack gap={6}>
            <Stack gap={2} textAlign="center" alignItems="center">
                {isTrialStartedMode ? (
                    <CongratsIcon style={{ width: 168, height: 120 }} />
                ) : (
                    <CreditCardRoundedIcon
                        sx={{ fontSize: pxToRem(54), color: (theme) => theme.palette.action.active }}
                    />
                )}
                <Stack gap={1}>
                    <Typography variant="h6" fontWeight={600}>
                        {isTrialStartedMode ? t('Free Trial has Started!') : t('Confirm Payment Info')}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                        {isTrialStartedMode
                            ? t(`You have ${plan?.trialPeriod}s to see how well Vidalytics works at full capacity.`)
                            : t('Please provide and confirm your payment information to proceed with the upgrade.')}
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction="row" gap={4}>
                <PlanDetails
                    isTrialStartedMode={isTrialStartedMode}
                    dueToday={dueToday}
                    planName={plan?.name}
                    price={plan?.price}
                    pricePeriod={getBillingPeriod(plan?.billingPeriod, true)}
                    isPriceLoading={isPlanProrateLoading}
                    features={plan?.features}
                />
                <PaymentInfo
                    openCardForm={openCardForm}
                    formRef={formRef}
                    chargifyRef={chargify}
                    onAddCard={() => setOpenForm(true)}
                />
            </Stack>

            <Stack direction="row" gap={3} justifyContent="flex-end">
                <Button variant="outlined" color="tertiary" onClick={handleCancel} disabled={isLoadingActivation}>
                    {isTrialStartedMode ? t('Continue without a Card') : t('Cancel')}
                </Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleActivate}
                    disabled={!!isCardChanging}
                    loading={isLoadingActivation || activatePlanMutation.isLoading}
                >
                    {isTrialStartedMode ? t('Add Payment Details') : t('Confirm Payment')}
                </LoadingButton>
            </Stack>
        </Stack>
    )
}

export default ActivationStep
