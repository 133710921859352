import { UseMutationOptions } from 'react-query'

import { VideoGuid } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEOS_QUERY, VID_CONDITIONS_MUTATION } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useVidConditionsStateMutation = (
    guid: VideoGuid,
    options?: UseMutationOptions<string, unknown, boolean>,
) => {
    const { mutate } = useMutationToastDecorator({ showPendingToast: false, showSuccessToast: false })

    return useInvalidateMutation<string, boolean>(
        [VID_CONDITIONS_MUTATION, guid],
        async (payload: boolean) => mutate(videoAPI.vidConditionsState(guid, payload), apiLocale.vidConditions),
        {
            invalidate: [VIDEOS_QUERY],
            ...options,
        },
    )
}

export default useVidConditionsStateMutation
