import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SubscriptionCtaSource } from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import { COMMON_CONFIG } from './common'

export const useMoreVideoStoragePromoConfig = () => {
    const { t } = useTranslation()

    return {
        ...COMMON_CONFIG,
        title: 'Need More Video Storage?',
        description: (
            <>
                {t('Upgrade to')}{' '}
                <Typography component="span" color="accent.dark">
                    {t('Pro')}
                </Typography>{' '}
                {t('and get')}{' '}
                <Typography component="span" color="accent.dark">
                    {t('20 Videos stored free')}
                </Typography>{' '}
                {t('and even more on-demand! Scale you account as your business grows!')}
            </>
        ),
    }
}

export const getCurrentTrialPromoConfig = (planName: string) => ({
    ...COMMON_CONFIG,
    title: `You are on the ${planName} Plan Free Trial`,
    source: SubscriptionCtaSource.SIDEBAR,
})

export const proPromoConfig = {
    ...COMMON_CONFIG,
    title: 'Get More with Pro',
    source: SubscriptionCtaSource.SIDEBAR,
}

export const uploadLimitPromoConfig = {
    ...proPromoConfig,
    source: SubscriptionCtaSource.UPLOAD_LIMIT,
}

export const usageBannerPromoConfig = {
    ...proPromoConfig,
    source: SubscriptionCtaSource.USAGE_BANNER,
}

export const uploadPromoConfig = {
    ...proPromoConfig,
    source: SubscriptionCtaSource.UPLOAD,
}
