import { Box } from '@mui/material'

import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import AccordionMenu from 'design/organisms/VidSettingsSidebar/AccordionMenu/AccordionMenu'
import useFeatureFlags from 'hooks/system/useFeatureFlags'

import ManagementSection from './ManagementSection'
import useManagementSettingsMenu from './useManagementSettingsMenu'

const ManagementSettings = () => {
    const menu = useManagementSettingsMenu()
    const { videoInfo } = useFeatureFlags()

    return (
        <Box className="ManagementSettings">
            {!videoInfo && <ManagementSection />}
            <AccordionMenu menu={menu} initial />
        </Box>
    )
}

export default withErrorBoundary(ManagementSettings)
