import { format } from 'date-fns'
import { useQuery, UseQueryOptions } from 'react-query'

import { selectStatsDateRange } from 'design/pages/VidStats/components/DatePicker/statsDateRange.slice'
import { useSmartVidPageContext } from 'design/pages/SmartVid/contexts/smartVidPageContext'
import { SmartVidStatisticsData } from 'design/pages/VidStats/types'
import { statsAPI } from 'api/controllers'
import { SMART_VID_STATS_QUERY } from 'api/constants'
import { useAppSelector } from 'App'

export const useSmartVidStatsQuery = (id?: string, options?: UseQueryOptions<SmartVidStatisticsData, unknown>) => {
    const dates = useAppSelector(selectStatsDateRange)
    const dateFrom = format(dates[0], 'yyyy-MM-dd')
    const dateTo = format(dates[1], 'yyyy-MM-dd')
    const params = `date_from=${dateFrom}&date_to=${dateTo}`
    const { isAnalyticMode } = useSmartVidPageContext()

    return useQuery<SmartVidStatisticsData>(
        [SMART_VID_STATS_QUERY, id, dateFrom, dateTo],
        () => statsAPI.getSmartVidStats(String(id), params),
        {
            enabled: Boolean(id) && isAnalyticMode,
            refetchOnMount: 'always',
            ...options,
        },
    )
}
