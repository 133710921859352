import { useEffect, useMemo } from 'react'
import { Form, Formik, FormikProps } from 'formik'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import * as yup from 'yup'
import { locale } from 'locales'

import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    EXPIRE_VIDEO_SECTION_INITIAL_VALUES,
    UI_OVERLAY_EXPIRE_COLOR_BACKGROUND,
    UI_OVERLAY_EXPIRE_COLOR_FOREGROUND,
    UI_OVERLAY_EXPIRE_DATE_EXPIRE,
    UI_OVERLAY_EXPIRE_ENABLED,
    UI_OVERLAY_EXPIRE_LINK_BLANK,
    UI_OVERLAY_EXPIRE_LINK_HREF,
    UI_OVERLAY_EXPIRE_TEXT_BOTTOM_TEXT,
    UI_OVERLAY_EXPIRE_TEXT_TOP_TEXT,
} from 'design/pages/VidSettings/constants'
import { ExpireVideoSectionFormData } from 'types/VidSettings'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { VideoDetails } from 'types/Video'
import { useSmartVidQuery, useVideoQuery } from 'api/queries'
import { GlobalSettingsFormProvider } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import { URL_OPTIONAL_PROTOCOL_REGEX } from 'constants/validations/common.constants'
import ExpireVideoContent from './ExpireVideoContent'

const getFormattedExpireDate = (date?: string): string => {
    if (!date) {
        return ''
    }

    return (
        date.substring(0, 4) +
        '/' +
        date.substring(5, 7) +
        '/' +
        date.substring(8, 10) +
        ' ' +
        date.substring(11, 13) +
        ':' +
        date.substring(14, 16) +
        ':' +
        date.substring(17, 19)
    )
}

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const ExpireVideoSection = ({
    resetForm,
    isSmartVidGlobalSettings,
}: FormikProps<ExpireVideoSectionFormData> & Props) => {
    const { videoGuid, smartVidId } = useParams()

    const initForm = (details: VideoDetails) => {
        resetForm({
            values: {
                [UI_OVERLAY_EXPIRE_ENABLED]: Boolean(details.settings[UI_OVERLAY_EXPIRE_ENABLED]),
                [UI_OVERLAY_EXPIRE_DATE_EXPIRE]: getFormattedExpireDate(
                    details.settings[UI_OVERLAY_EXPIRE_DATE_EXPIRE],
                ),
                [UI_OVERLAY_EXPIRE_TEXT_TOP_TEXT]: details.settings[UI_OVERLAY_EXPIRE_TEXT_TOP_TEXT] || '',
                [UI_OVERLAY_EXPIRE_TEXT_BOTTOM_TEXT]: details.settings[UI_OVERLAY_EXPIRE_TEXT_BOTTOM_TEXT] || '',
                [UI_OVERLAY_EXPIRE_LINK_HREF]: details.settings[UI_OVERLAY_EXPIRE_LINK_HREF] || '',
                [UI_OVERLAY_EXPIRE_LINK_BLANK]: Boolean(details.settings[UI_OVERLAY_EXPIRE_LINK_BLANK]),
                [UI_OVERLAY_EXPIRE_COLOR_FOREGROUND]: details.settings[UI_OVERLAY_EXPIRE_COLOR_FOREGROUND] || '',
                [UI_OVERLAY_EXPIRE_COLOR_BACKGROUND]: details.settings[UI_OVERLAY_EXPIRE_COLOR_BACKGROUND] || '',
            },
        })
    }

    const { data: smartVid } = useSmartVidQuery(String(smartVidId), {
        enabled: Boolean(smartVidId),
    })

    const { data } = useVideoQuery(videoGuid || smartVid?.video.guid, {
        // https://github.com/TanStack/query/discussions/937
        // https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#state-syncing
        // initForm isn't calling
        onSuccess: initForm,
    })

    useEffect(() => {
        if (data) {
            initForm(data)
        }
    }, [data])

    const Provider = useMemo(
        () => (isSmartVidGlobalSettings ? GlobalSettingsFormProvider : SettingsSectionProvider),
        [isSmartVidGlobalSettings],
    )

    return (
        <Provider>
            <Form>
                <Box className="ExpireVideoSection">
                    <ExpireVideoContent isSmartVidGlobalSettings={isSmartVidGlobalSettings} />
                </Box>
            </Form>
        </Provider>
    )
}

const FormikWrapper = ({ isSmartVidGlobalSettings }: Props) => {
    const TagsValidationSchema = yup.object().shape(
        {
            [UI_OVERLAY_EXPIRE_DATE_EXPIRE]: yup
                .string()
                .required(locale.validations.common.required)
                .matches(
                    /^([0-9]{4})\/([0-1][0-9])\/([0-3][0-9])\s([0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])$/,
                    'Please enter a value in YYYY/MM/DD  hh:mm:ss format',
                ),
            [UI_OVERLAY_EXPIRE_TEXT_TOP_TEXT]: yup.string().when(UI_OVERLAY_EXPIRE_TEXT_BOTTOM_TEXT, {
                is: (text?: string) => !text,
                then: yup.string().required('Please enter Top Text or Bottom Text'),
                otherwise: yup.string(),
            }),

            [UI_OVERLAY_EXPIRE_TEXT_BOTTOM_TEXT]: yup.string().when(UI_OVERLAY_EXPIRE_TEXT_TOP_TEXT, {
                is: (text?: string) => !text,
                then: yup.string().required('Please enter Top Text or Bottom Text'),
                otherwise: yup.string(),
            }),
            [UI_OVERLAY_EXPIRE_LINK_HREF]: yup
                .string()
                .max(2048, locale.validations.length.max(2048))
                .matches(URL_OPTIONAL_PROTOCOL_REGEX, locale.validations.url.invalid),
        },
        [[UI_OVERLAY_EXPIRE_TEXT_TOP_TEXT, UI_OVERLAY_EXPIRE_TEXT_BOTTOM_TEXT]],
    )

    return (
        <Formik<ExpireVideoSectionFormData>
            initialValues={EXPIRE_VIDEO_SECTION_INITIAL_VALUES}
            validationSchema={TagsValidationSchema}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={() => {}}
        >
            {(props) => <ExpireVideoSection {...props} isSmartVidGlobalSettings={isSmartVidGlobalSettings} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
