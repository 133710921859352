import { SxProps, Theme } from '@mui/material'

const header: SxProps<Theme> = () => ({
    pb: 5,
    flexWrap: 'wrap',
    '& > *:first-of-type': {
        flexWrap: 'wrap',
        minWidth: 0,
        flex: 1,
    },
    '& [data-testid="FolderBreadcrumb"]': {
        flexWrap: 'nowrap',
        flex: 'none',
    },
})

const headerSearch: SxProps<Theme> = (theme) => ({
    ml: 'auto',
    overflow: 'hidden',

    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },

    '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
    },

    [theme.breakpoints.down('tablet')]: {
        transition: 'width 0s',
        ml: 0,
        [`&.isSearching`]: {
            width: '100%',
            maxWidth: '100%',
            order: 5,
            transition: 'width 0s',
        },
    },
})

const buttonsStack: SxProps<Theme> = {
    '.isSearching ~ &': {
        ml: 'auto',
    },
    flexWrap: 'nowrap',
    minWidth: 'fit-content',
}

const folderName: SxProps<Theme> = (theme) => ({
    'input, .resizeHelper': {
        ...theme.typography.h6,
    },
    input: {
        height: theme.typography.h6.lineHeight,
    },
    '.MuiInput-root': {
        backgroundColor: theme.palette.background.surface,
    },
    '.MuiInput-root.MuiInput-underline': {
        '&::before': {
            border: 'none',
        },
        '&::after': {
            borderColor: theme.palette.primary.main,
        },
        '&:hover::before': {
            border: 'none',
        },
    },
})

const folderNameHeaderLink: SxProps<Theme> = {
    cursor: 'pointer',
    color: (theme) => theme.palette.text.primary,
    m: 0,

    '&:hover': {
        color: (theme) => theme.palette.primary.main,
    },
}

const helperButton: SxProps<Theme> = (theme) => ({
    flexBasis: '100%',
    minWidth: 'fit-content',

    [theme.breakpoints.up('laptop')]: {
        flexBasis: 'unset',
    },
    [theme.breakpoints.up('mobile')]: {
        flexBasis: 'unset',
    },
})

const addNewFolder: SxProps<Theme> = (theme) => ({
    backgroundColor: theme.palette.background.default,
})

export default {
    header,
    folderName,
    headerSearch,
    helperButton,
    addNewFolder,
    buttonsStack,
    folderNameHeader: {
        link: folderNameHeaderLink,
    },
}
