import { SxProps } from '@mui/material'

export default {
    root: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 2,
    },
    highlightedText: {
        color: 'accent.main',
        fontWeight: 700,
    },
} satisfies Record<string, SxProps>
