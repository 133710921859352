import { useMemo } from 'react'

import HdRoundedIcon from '@mui/icons-material/HdRounded'
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import TimelapseRoundedIcon from '@mui/icons-material/TimelapseRounded'
import Resume from 'assets/icons/Resume'
import SmartAutoPlay from 'assets/icons/SmartAutoPlay'

import { VidSettingsKey } from 'constants/keys/featuresAccess.constants'

import EndOfVideoActionSection from './EndOfVideoActionSection'
import ExpireVideoSection from './ExpireVideoSection'
import locale from './PlaybackSettings.locale'
import ResumePlaySection from './ResumePlaySection'
import SmartAutoplaySection from './SmartAutoplaySection'
import SmartPauseSection from './SmartPauseSection'
import VideoStartupQualitySection from './VideoStartupQualitySection'

const usePlaybackSettingsMenu = () => {
    return useMemo(
        () => [
            { label: locale.smartAutoplay, Icon: SmartAutoPlay, Children: SmartAutoplaySection },
            {
                label: locale.resumePlay,
                Icon: Resume,
                Children: ResumePlaySection,
                featureKey: VidSettingsKey.ResumePlay,
            },
            {
                label: locale.smartPause,
                Icon: PauseRoundedIcon,
                Children: SmartPauseSection,
                featureKey: VidSettingsKey.SmartPause,
            },
            { label: locale.endOfVideoAction, Icon: TimelapseRoundedIcon, Children: EndOfVideoActionSection },
            {
                label: locale.expireVideo,
                Icon: HourglassBottomRoundedIcon,
                Children: ExpireVideoSection,
                featureKey: VidSettingsKey.ExpireVideo,
            },
            {
                label: locale.videoStartupQuality,
                Icon: HdRoundedIcon,
                Children: VideoStartupQualitySection,
            },
        ],
        [],
    )
}

export default usePlaybackSettingsMenu
