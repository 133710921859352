import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Card, Button, Stack, Typography } from '@mui/material'
import { Portal } from '@mui/base'
import { Close as CloseIcon, PlayArrowRounded as PlayArrowRoundedIcon } from '@mui/icons-material'

import { useVideosQuery } from 'api/queries'
import { route } from 'constants/routes'

const INTERVAL = 10000
const VIDEO_PATH = '/video/'

import styles from './WelcomeFirstVideoUploaded.style'

export function WelcomeFirstVideoUploaded() {
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [isShown, setIsShown] = useState(false)
    const isFirstUpload = sessionStorage.getItem('firstUpload')
    const { data } = useVideosQuery({}, { enabled: !!isFirstUpload, refetchInterval: INTERVAL })
    const filtered = data?.filter((video) => !video.isDemoVideo && video.isReady)
    const firstFiltered = filtered?.[0]
    const demo = data?.find((video) => video.isDemoVideo)

    const handleClick = useCallback(() => {
        setIsShown(false)
        if (!firstFiltered?.guid) return

        navigate(route.video.settings.byId({ videoGuid: firstFiltered?.guid }))
    }, [firstFiltered])

    useEffect(() => {
        const isOnVideoPage = pathname.includes(VIDEO_PATH)
        const isOnDemo = demo && pathname.includes(demo?.guid)

        if (filtered?.length && isFirstUpload) {
            sessionStorage.removeItem('firstUpload')

            if (!isOnVideoPage || isOnDemo) {
                setIsShown(true)
            }
        }
    }, [filtered, isFirstUpload, demo, pathname])

    if (!isShown) return null

    return (
        <Portal>
            <Card sx={styles.root} elevation={6}>
                <Box sx={styles.body}>
                    <Card sx={styles.cross}>
                        <Button onClick={() => setIsShown(false)} variant="text" color="tertiary">
                            <CloseIcon fontSize="small" />
                        </Button>
                    </Card>
                    <Stack gap={3}>
                        <Card sx={styles.play}>
                            <Button onClick={handleClick} sx={styles.playBtn} variant="text" color="primary">
                                <PlayArrowRoundedIcon fontSize="large" />
                            </Button>
                        </Card>
                        <Box
                            onClick={handleClick}
                            component="img"
                            src={firstFiltered?.thumbnail?.previewSrc || ''}
                            sx={styles.thumbnail}
                        />
                        <Stack gap={2}>
                            <Typography fontWeight={500}>{t('Your video is added')}</Typography>
                            <Typography variant="body2">
                                {t('Now you can customize your video settings and embed it to your site!')}
                            </Typography>
                        </Stack>
                        <Button onClick={handleClick} variant="contained" color="primary">
                            {t('Customize video')}
                        </Button>
                    </Stack>
                </Box>
            </Card>
        </Portal>
    )
}

export default WelcomeFirstVideoUploaded
