import { useEffect, useRef, useState, RefObject } from 'react'
import { useFormikContext } from 'formik'
import { useTheme, Box, Grid, Typography, Stack } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import _noop from 'lodash/noop'

import { ChargifyFormItemSkeleton } from 'design/organisms/SignUp/ChargifyFormItemSkeleton'
import { SignUpFormData } from 'types/Auth'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { TooltipStyled } from 'design/atoms/TooltipStyled/TooltipStyled'
import { FIRSTNAME, LASTNAME, PARTIAL, TOKEN } from 'design/organisms/SignUp/constants'
import { CHARGIFY_PUBLIC_KEY, CHARGIFY_SERVER_HOST } from 'App'
import {
    ChargifyError,
    useChagifyFormLoadingState,
    useChagifyTokenStatus,
    useShowErrorAddCard,
} from 'hooks/chargify.hooks'
import { SignUpSubmitProps } from '..'

import locale from './ChargifyForm.locale'
import style from './ChargifyForm.style'

import './index.scss'

type ChargifyFormProps = {
    handleSubmit: (props: SignUpSubmitProps) => () => void
    isLoading: boolean
    updateCard?: boolean
    type: string | null
    canSkip: boolean
}

const ChargifyForm = ({ handleSubmit, updateCard, isLoading, type, canSkip }: ChargifyFormProps) => {
    const [loading, setLoading] = useState(false)
    const formik = useFormikContext<SignUpFormData>() || {}
    const { chargifyTokenError, chargifyTokenSuccess } = useChagifyTokenStatus()
    const showErrorAddCard = useShowErrorAddCard()

    const formRef: RefObject<HTMLFormElement> = useRef(null)
    const chargify = useRef(window.Chargify ? new window.Chargify() : null)
    const theme = useTheme()

    const { isLoading: isChargifyFormLoading } = useChagifyFormLoadingState(formRef)

    const onSignUp = () => {
        setLoading(true)

        if (!navigator.onLine) {
            setLoading(false)
            showErrorAddCard('Unable to save payment method. Please check your connection and try again.')
        }

        chargify.current?.token(
            formRef.current,

            chargifyTokenSuccess((token) => {
                handleSubmit({ type: TOKEN, formik, token })()
                setLoading(false)
            }),
            (error: ChargifyError) => {
                chargifyTokenError(error)
                setLoading(false)
            },
        )
    }

    useEffect(() => {
        ;(async () => {
            await chargify.current?.load({
                selector: '#chargify-form',
                publicKey: CHARGIFY_PUBLIC_KEY,
                type: 'card',
                serverHost: CHARGIFY_SERVER_HOST,
                hideCardImage: true,
                optionalLabel: '',
                requiredLabel: '',
                addressDropdowns: true,
                style: {
                    field: {
                        overflow: 'hidden',
                        margin: 0,
                    },
                    input: {
                        ...theme.typography.body1,
                        color: theme.palette.text.primary,
                        borderRadius: '12px', // it is impossible to use relative MUI values, as the styles are sent to a third-party library
                        padding: '9.5px 14px',
                        height: '42px',
                        background: theme.palette.background.default,
                        placeholder: { color: theme.palette.text.secondary },
                    },
                    label: {
                        ...theme.typography.body2,
                        color: theme.palette.text.secondary,
                        margin: '0 0 6px 0',
                    },
                    message: {
                        ...theme.typography.caption,
                        color: theme.palette.error.main,
                    },
                },
                fields: {
                    firstName: {
                        selector: '#chargify-firstname',
                        label: locale.user.firstName.label,
                        placeholder: locale.user.firstName.placeholder,
                        required: true,
                        message: 'Please enter a valid first name',
                        maxlength: '30',
                        value: formik.values[FIRSTNAME],
                    },
                    lastName: {
                        selector: '#chargify-lastname',
                        label: locale.user.lastName.label,
                        placeholder: locale.user.lastName.placeholder,
                        required: true,
                        message: 'Please enter a valid last name',
                        maxlength: '30',
                        value: formik.values[LASTNAME],
                    },
                    number: {
                        selector: '#chargify-card',
                        label: locale.creditCard.number.label,
                        placeholder: locale.creditCard.number.placeholder,
                        message: 'Please enter a valid card number',
                        style: {
                            input: {
                                padding: '10px 10px 10px 46px',
                            },
                        },
                    },
                    month: {
                        selector: '#chargify-month',
                        label: locale.creditCard.expiry.month.label,
                        placeholder: locale.creditCard.expiry.month.placeholder,
                        message: ' ',
                        style: {
                            input: {
                                padding: '10px',
                            },
                        },
                    },
                    year: {
                        selector: '#chargify-year',
                        label: locale.creditCard.expiry.year.label,
                        placeholder: locale.creditCard.expiry.year.placeholder,
                        message: ' ',
                        style: {
                            input: {
                                padding: '10px',
                            },
                        },
                    },
                    cvv: {
                        selector: '#chargify-cvv',
                        label: locale.creditCard.cvv.label,
                        placeholder: locale.creditCard.cvv.placeholder,
                        required: true,
                        message: 'Invalid CVV code',
                    },
                    zip: {
                        selector: '#chargify-zip',
                        label: locale.address.zip.label,
                        placeholder: locale.address.zip.placeholder,
                        required: true,
                        message: 'Invalid zip code',
                        maxlength: '10',
                    },
                },
            })

            if (updateCard) {
                chargify.current?.token(
                    formRef.current,

                    chargifyTokenSuccess(_noop),
                    _noop,
                )
            }
        })()

        return () => {
            chargify.current?.unload()
        }
    }, [theme])

    useEffect(() => {
        const handleOffline = () => {
            if (loading) {
                setLoading(false)
                showErrorAddCard('Unable to save payment method. Please check your connection and try again.')
            }
        }

        window.addEventListener('offline', handleOffline)

        return () => {
            window.removeEventListener('offline', handleOffline)
        }
    }, [loading])

    const getLoadingState = (typeToCheck: 'partial' | 'token') => {
        if (typeToCheck === PARTIAL) {
            return (!formik.isValidating && type === PARTIAL) || isLoading || loading
        }

        return (!formik.isValidating && type === TOKEN) || isLoading || loading
    }

    return (
        <Box>
            {canSkip && (
                <Stack direction="row" mt={-5} mb={6}>
                    <Typography lineHeight={1}>{locale.tooltip.whyDoIneedCard}</Typography>
                    <TooltipStyled
                        title={
                            <Box p={2}>
                                <Box pb={2}>{locale.tooltip.youCanSkip}</Box>
                                <Box pb={2}>{locale.tooltip.recommendedThatYouAddCard}</Box>
                                <Box>{locale.tooltip.cancelAnytime}</Box>
                            </Box>
                        }
                        sxTooltip={{ maxWidth: 330 }}
                        arrow
                    >
                        <HelpOutlineOutlinedIcon
                            sx={{ ml: 1, fontSize: 'body1.fontSize', lineHeight: 1 }}
                            color="primary"
                        />
                    </TooltipStyled>
                </Stack>
            )}

            <form ref={formRef} id="chargify-form">
                <Box className="ChargifyCreditChargifyForm">
                    <Grid container spacing={{ tablet: 4 }}>
                        <Grid sx={style.gridItem} tablet={6} mobile={12} id="chargify-firstname" item>
                            <ChargifyFormItemSkeleton isLoading={isChargifyFormLoading} />
                        </Grid>
                        <Grid sx={style.gridItem} tablet={6} mobile={12} id="chargify-lastname" item>
                            <ChargifyFormItemSkeleton isLoading={isChargifyFormLoading} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} className="creditCardContainer">
                        <Grid sx={style.gridItem} mobile={12} id="chargify-card" item>
                            <ChargifyFormItemSkeleton isLoading={isChargifyFormLoading}>
                                <CreditCardIcon sx={style.cardIco} />
                                <Box
                                    sx={style.cardMethods}
                                    component="img"
                                    src="/img/icons/payment-methods.svg"
                                    alt="payment-methods"
                                />
                            </ChargifyFormItemSkeleton>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} className="additionalDetailsContainer">
                        <Grid sx={style.gridItem} tablet={2.5} mobile={3} id="chargify-month" item>
                            <ChargifyFormItemSkeleton isLoading={isChargifyFormLoading} />
                        </Grid>
                        <Grid sx={style.gridItem} tablet={2.5} mobile={3} id="chargify-year" item>
                            <ChargifyFormItemSkeleton isLoading={isChargifyFormLoading} />
                        </Grid>
                        <Grid sx={style.gridItem} position="relative" tablet={2.5} mobile={6} id="chargify-cvv" item>
                            <ChargifyFormItemSkeleton isLoading={isChargifyFormLoading}>
                                <Box sx={style.cvv} component="img" src="/img/icons/cvv.svg" alt="cvv" />
                            </ChargifyFormItemSkeleton>
                        </Grid>
                        <Grid sx={style.gridItem} tablet={4.5} mobile={12} id="chargify-zip" item>
                            <ChargifyFormItemSkeleton isLoading={isChargifyFormLoading} />
                        </Grid>
                    </Grid>
                </Box>
                <Box className="signUpFormBottom">
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        onClick={onSignUp}
                        disabled={getLoadingState(TOKEN)}
                        loading={getLoadingState(TOKEN)}
                        data-testid="submitBtn"
                    >
                        {locale.buttons.letMeIn}
                    </LoadingButton>

                    {canSkip && (
                        <LoadingButton
                            sx={{ mt: 6 }}
                            variant="text"
                            onClick={handleSubmit({ type: PARTIAL, formik, isSkipButton: true })}
                            disabled={getLoadingState(PARTIAL)}
                            loading={getLoadingState(PARTIAL)}
                            fullWidth
                            data-testid="skipBtn"
                        >
                            {locale.buttons.skip}
                        </LoadingButton>
                    )}
                </Box>
            </form>
        </Box>
    )
}

export default withErrorBoundary(ChargifyForm)
