import { analyticsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { DOWNLOAD_ANALYTICS_MUTATION } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { ExportType } from 'types'
import { FilterKeys } from 'design/pages/Analytics/types'

export interface DownloadVideoAnalyticsMutationPayload {
    dateFrom: string
    dateTo: string
    exportFileType: string
    storeFilters: {
        [FilterKeys.conversions]: string[]
        [FilterKeys.newVsReturning]: string[]
        [FilterKeys.devices]: string[]
        [FilterKeys.browsers]: string[]
        [FilterKeys.geo]: string[]
        [FilterKeys.folders]: string[]
        [FilterKeys.urlTracking]: string[]
    }
}

type Response = string | BlobPart

export const useDownloadVideoAnalyticsMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Response, DownloadVideoAnalyticsMutationPayload>(
        DOWNLOAD_ANALYTICS_MUTATION,
        async ({ dateFrom, dateTo, exportFileType, storeFilters }: DownloadVideoAnalyticsMutationPayload) => {
            const hasFilters = Object.values(storeFilters).some((filter) => filter.length)
            const responseType = exportFileType === ExportType.CSV ? 'text' : 'blob'
            const buildQueryString = () => {
                let requestParams = `dateFrom=${dateFrom}&dateTo=${dateTo}`
                if (hasFilters) {
                    Object.entries(storeFilters).forEach(([key, value]) => {
                        if (value.length > 0) {
                            if (key === 'url_params') {
                                const url = window.location.href
                                const urlObj = new URL(url)
                                const urlType = urlObj.searchParams.get('url_type')
                                requestParams += `&filter.${key}.${urlType}=${value.join(',')}`
                            } else {
                                requestParams += `&filter.${key}=${value.join(',')}`
                            }
                        }
                    })
                }

                return requestParams
            }
            const paramsString = buildQueryString()

            const format = exportFileType === ExportType.CSV ? 'csv' : 'xlsx'

            return mutate(
                analyticsAPI.downloadVideoAnalytics(format, paramsString, { responseType }),
                apiLocale.analytics.download,
            )
        },
    )
}
