import { Box, ModalProps, Stack, Typography } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import FixedEmojisInText from 'design/atoms/FixedEmojisInText'
import { Modal, SubscriptionCtaSource, SubscriptionFeatureKey } from 'design/templates/Modal'
import Banner from 'design/templates/Modal/modalTemplates/FeatureLockedModal/Banner'
import Features from 'design/templates/Modal/modalTemplates/FeatureLockedModal/Features'
import PromoMedia from 'design/templates/Modal/modalTemplates/FeatureLockedModal/PromoMedia'
import { useHandleOpenSubscriptionModal } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SelectPlanStep/hooks'
import { ModalType } from 'design/templates/Modal/ModalTypes/types'
import useTariffPlans from 'hooks/user/useTariffPlans'
import { CustomerStatus, SubscriptionCategory } from 'types/Customer'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { MIXPANEL_EVENTS } from 'hooks/analytics/useMixpanel'
import { getSx } from 'styles/theme/utils'

import usePlanUsageInfo from 'design/organisms/Account/usePlanUsageInfo'
import { useCustomerQuery } from 'api/queries'
import { useActivatePlanMutation } from 'api/mutations'
import { useEffectTrackEvent } from 'hooks/analytics/useEffectTrackEvent'
import style from './FeatureLockedModal.style'

export interface FeatureLockedProps {
    title?: string
    description?: string
    source?: SubscriptionCtaSource
    modalProps?: Pick<ModalProps, 'sx'>
    promoMediaSrc?: string
    containerRef?: HTMLDivElement | null
    planCategory?: SubscriptionCategory
    highlightFeature?: SubscriptionFeatureKey
    onCloseClick?(): void
    onUpgradeClick?: () => void
}

export interface FeatureLockedOpenModalPayload extends FeatureLockedProps {
    type: ModalType.FEATURE_LOCKED
}

type FeatureLockedModalProps = FeatureLockedProps & {
    onClose: () => void
}

export const FeatureLockedModal = ({
    title = '',
    description = '',
    source = SubscriptionCtaSource.NOT_DEFINED,
    planCategory = SubscriptionCategory.pro,
    promoMediaSrc = '/img/subscription/promo-chart.png',
    containerRef,
    modalProps,
    onCloseClick,
    onUpgradeClick,
    onClose,
}: FeatureLockedModalProps) => {
    const { t } = useTranslation()

    const tariffPlans = useTariffPlans()
    const planToUpgrade = useMemo(() => tariffPlans[planCategory], [tariffPlans, planCategory])
    const { isFree } = usePlanUsageInfo()
    const { trialAutoDowngradeAfterTrialWhenUserHasNoCard } = useFeatureFlags()
    const { data: customer } = useCustomerQuery()
    const { mutate: activatePlan } = useActivatePlanMutation(undefined, false)

    const { handleOpenSubscriptionModal } = useHandleOpenSubscriptionModal()

    const handleClose = useCallback(() => {
        onClose()
        onCloseClick?.()
    }, [])

    const isInsideContainer = Boolean(containerRef)

    const handleUpgradeClick = useCallback(() => {
        if (planToUpgrade) {
            const isTrialStartedMode = Boolean(
                planToUpgrade.hasTrial && isFree && customer && customer.status !== CustomerStatus.trial,
            )

            if (trialAutoDowngradeAfterTrialWhenUserHasNoCard && planToUpgrade.hasTrial && isTrialStartedMode) {
                activatePlan({
                    apiHandle: planToUpgrade.apiHandle,
                })
            }

            handleOpenSubscriptionModal(planToUpgrade, { isTrialStartedMode })
        }

        if (isInsideContainer) return

        onUpgradeClick?.()
        handleClose()
    }, [planToUpgrade, isInsideContainer, trialAutoDowngradeAfterTrialWhenUserHasNoCard, isFree, customer])

    useEffectTrackEvent({
        eventName: MIXPANEL_EVENTS.UPGRADE_PROMO_POPUP_OPENED,
        eventData: {
            tier_recommended: planToUpgrade?.tier,
            trial_available: Boolean(planToUpgrade?.trialPeriod),
            promo_source: source,
        },
    })

    return (
        <Modal
            onClose={handleClose}
            container={containerRef}
            sx={getSx([isInsideContainer && style.mountedModal], modalProps?.sx)}
            stackProps={{ sx: [style.modalStack, isInsideContainer && style.mountedModalStack] }}
            hideBackdrop={isInsideContainer}
            width="lg"
            open
        >
            <Modal.Header onClose={isInsideContainer ? undefined : handleClose}>
                <Stack width={1} sx={style.headerContainer}>
                    <Typography variant="h4" fontWeight={600}>
                        {t(title)}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        <FixedEmojisInText text={t(description)} />
                    </Typography>
                </Stack>
            </Modal.Header>
            <Modal.Body sx={style.body}>
                <Box
                    display="grid"
                    gap={6}
                    gridTemplateColumns={`repeat(${isInsideContainer ? '10' : '8'}, 1fr)`}
                    alignItems="center"
                >
                    <PromoMedia src={promoMediaSrc} isInsideContainer={isInsideContainer} />
                    <Features
                        planName={planToUpgrade?.name}
                        features={planToUpgrade?.features}
                        containerSx={{
                            gridArea: isInsideContainer ? '1 / 8 / 2 / 11' : '1 / 6 / 2 / 9',
                        }}
                        handleClose={handleClose}
                    />
                </Box>
                <Banner planToUpgrade={planToUpgrade} onSubmit={handleUpgradeClick} />
            </Modal.Body>
        </Modal>
    )
}
