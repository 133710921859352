import { FormControlLabel, Radio as MuiRadio, RadioGroup, RadioGroupProps, RadioProps, Typography } from '@mui/material'
import { Field, FieldProps } from 'formik'
import { ReactNode, useMemo } from 'react'

import { HelpTooltip } from 'design/atoms/HelpTooltip'
import UpgradeBadge from 'design/molecules/UpgradeBadge'
import useUpgradeCallback from 'design/molecules/UpgradeBadge/useUpgradeCallback'
import { SubscriptionCtaSource } from 'design/templates/Modal'
import { CustomTypographyVariant } from 'styles/theme/typography'
import { useCheckAccess } from 'utils'
import { FeatureGroup } from 'constants/keys/featuresAccess.constants'
import { FeatureKey } from 'constants/featuresAccess.constants'

import style from './Radio.style'

type Option = {
    value: string
    label: ReactNode
    tooltip?: ReactNode
    locked?: boolean
    featureKey?: FeatureKey
    disabled?: boolean
    propsRadio?: RadioProps
}

type RadioFormProps = RadioGroupProps & {
    name: string
    options: Option[]
    disabled?: boolean
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    value?: any
    featureGroup?: FeatureGroup
    controlled?: boolean
    afterChange?: (name: string, value: string) => void
    labelVariant?: CustomTypographyVariant
    upgradeSource?: SubscriptionCtaSource
}

export const Radio = ({
    name,
    value,
    controlled,
    onChange,
    afterChange,
    disabled = false,
    labelVariant,
    featureGroup,
    options,
    upgradeSource,
    ...rest
}: RadioFormProps) => {
    const upgradeCallback = useUpgradeCallback(upgradeSource)
    const checkAccess = useCheckAccess(featureGroup)
    const optionsWithLocked = useMemo(
        () =>
            options.map((option) => ({
                ...option,
                locked: option.locked || checkAccess(option.featureKey),
            })),
        [options, upgradeSource, checkAccess],
    )

    return (
        <Field name={name}>
            {({ field, form: { setFieldValue } }: FieldProps) => (
                <RadioGroup
                    {...rest}
                    {...field}
                    value={controlled ? value : field.value}
                    sx={[disabled && { pointerEvents: 'none' }]}
                    onChange={
                        onChange ||
                        function (_, value) {
                            setFieldValue(name, value)
                            afterChange?.(name, value)
                        }
                    }
                >
                    {optionsWithLocked?.map((option) => (
                        <FormControlLabel
                            key={`${option.label}-${option.value}`}
                            value={option.value}
                            control={<MuiRadio sx={{ padding: 1.5 }} {...(option.propsRadio || {})} />}
                            label={
                                <Typography sx={style.optionWrapper}>
                                    <Typography
                                        sx={style.optionLabel}
                                        variant={labelVariant}
                                        {...(option.disabled || option.locked ? { color: 'text.disabled' } : {})}
                                    >
                                        {option.label}
                                        {option.tooltip && <HelpTooltip title={option.tooltip} />}
                                    </Typography>
                                    {option.locked && <UpgradeBadge size="xSmall" preventDefault />}
                                </Typography>
                            }
                            disabled={option.disabled || option.locked || disabled}
                            onClick={() =>
                                option.locked && upgradeCallback({ featureGroup, featureKey: option.featureKey })
                            }
                            sx={{ pl: 1.5, mr: 0 }}
                        />
                    ))}
                </RadioGroup>
            )}
        </Field>
    )
}
