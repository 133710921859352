import { useEffect } from 'react'
import { SortingRule, UseSortByInstanceProps } from 'react-table'

type SortBy = Array<SortingRule<string>>

export const loadSortingState = (sortingKey?: string) => {
    if (!sortingKey) return []

    const savedState = localStorage.getItem(sortingKey)

    return savedState ? JSON.parse(savedState) : []
}

export const saveSortingState = (sorting: SortBy, sortingKey?: string) => {
    if (!sortingKey) return

    localStorage.setItem(sortingKey, JSON.stringify(sorting))
}

interface UsePersistSortingProps {
    sortingKey?: string
    sortBy: SortBy
    setSortBy: UseSortByInstanceProps<object>['setSortBy']
}

export const usePersistSorting = ({ sortingKey, sortBy, setSortBy }: UsePersistSortingProps) => {
    useEffect(() => {
        saveSortingState(sortBy, sortingKey)
    }, [sortingKey, sortBy])

    useEffect(() => {
        setSortBy(loadSortingState(sortingKey))
    }, [sortingKey])
}
