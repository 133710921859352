import { useQuery, UseQueryOptions } from 'react-query'

import { transferAPI } from 'api/controllers'
import { GET_TRANSFER_VIDEOS } from 'api/constants'
import { GetTransferVideosResponse } from 'api/contracts/transfer/enpoints/getTransferVideos.contract'
import { ApiError } from 'api/contracts/utilitary/error'

export const useTransferVideosQuery = (
    isAuthorized: boolean,
    platform?: string,
    options?: UseQueryOptions<GetTransferVideosResponse, ApiError>,
) =>
    useQuery<GetTransferVideosResponse, ApiError>(
        [GET_TRANSFER_VIDEOS, platform],
        () => transferAPI.getVideos(String(platform)),
        {
            enabled: Boolean(platform) && isAuthorized,
            ...options,
        },
    )
