import { transferAPI } from 'api/controllers'
import { GET_AUTH_LINK } from 'api/constants'
import { TransferPlatform } from 'types/Video'
import { useInvalidateMutation } from 'api/hooks'

export const useTransferLinkQuery = () => ({
    getLink: useInvalidateMutation(GET_AUTH_LINK, async (platform: TransferPlatform) =>
        transferAPI.getPlantformAuthLink(platform),
    ),
})
