import { useQuery } from 'react-query'

import { PAYMENT_PROFILE_QUERY } from 'api/constants'
import { userAPI } from 'api/controllers'
import { CreditCard } from 'types/Auth'

export type PaymentProfileCard = {
    id: number
    isActive: boolean
    isFallback: boolean
    lastFailReason: string | null
} & CreditCard

export type PaymentProfileResponse = {
    cards: PaymentProfileCard[]
}

export const usePaymentProfileQuery = () => {
    return useQuery<PaymentProfileResponse>(PAYMENT_PROFILE_QUERY, () => userAPI.getPaymentProfile())
}
