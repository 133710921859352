import { isArray } from 'lodash'

import { seekingBarStyleKeys } from 'design/organisms/VidSettingsSidebar/StyleSettings/ControlsSection/SeekingBar'
import { PLAYBACK_ON_END } from 'design/pages/VidSettings/constants'
import { Video, VideoDetails, VideoSettings } from 'types/Video'
import {
    END_VIDEO_ACTIONS_LABELS,
    SEEKING_BAR_STYLE_LABELS,
    videoSettingsArrayBoolProperties,
    videoSettingsBoolProperties,
} from './constants'

export const getVideoProperties = (video?: Video) =>
    video
        ? {
              video_id: video.guid,
              video_duration_sec: video.length,
              isdemo_vid: video.isDemo,
          }
        : {}

const getVidSettingsSeekingbarStyle = (settings: VideoSettings) => {
    const key = seekingBarStyleKeys.find((k) => settings[k])
    const label = key ? SEEKING_BAR_STYLE_LABELS[key] : 'disabled'

    return label
}

const getStringBool = <T>(value: T): string => (value ? 'enabled' : 'disabled')

export const getAnalyticsVideoSettings = (video?: VideoDetails) =>
    video
        ? {
              vidsettings_style_seekingbar: getVidSettingsSeekingbarStyle(video.settings),
              vidsettings_endactions: END_VIDEO_ACTIONS_LABELS[video.settings[PLAYBACK_ON_END]],

              ...Object.entries(videoSettingsArrayBoolProperties).reduce(
                  (acc, [analyticsKey, settingsKey]) => ({
                      ...acc,
                      [analyticsKey]: getStringBool(video[settingsKey].length),
                  }),
                  {},
              ),

              ...Object.entries(videoSettingsBoolProperties).reduce(
                  (acc, [analyticsKey, settingsKey]) => ({
                      ...acc,
                      [analyticsKey]: isArray(settingsKey)
                          ? getStringBool(settingsKey.some((k) => video.settings[k]))
                          : getStringBool(video.settings[settingsKey]),
                  }),
                  {},
              ),
          }
        : {}
