import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Typography, Stack } from '@mui/material'

import { ReactComponent as CongratsIcon } from 'assets/img/agency/checkmark-with-confetti.svg'

import { useTrackEvent } from 'design/templates/Modal/modalTemplates/SubscriptionModal/hooks/useTrackEvent'
import { CustomerSubscriptionPlanTier, Plan } from 'types/Customer'
import { Link } from 'design/atoms/Link'
import { useOpenSupport } from 'hooks/system/useOpenSupport'
import { useCustomerSubscriptionQuery } from 'api/queries'
import { HELP_MAIL } from 'constants/brand.constants'
import { route } from 'constants/routes'

import InfoCard from './InfoCard'

type ActivationWelcomeProps = {
    oldTier?: CustomerSubscriptionPlanTier
    plan?: Plan
    onClose: () => void
}

const ActivationWelcome = ({ oldTier, plan, onClose }: ActivationWelcomeProps) => {
    const { t } = useTranslation()
    const handleOpenSupport = useOpenSupport()
    const { data: subscription } = useCustomerSubscriptionQuery()

    useTrackEvent(oldTier)

    const infoCards = useMemo(
        () => [
            {
                title: t('Training Center'),
                description: t('Check out our training videos to quickly get up to the speed.'),
                buttonText: t('Check Trainings'),
                imageSrc: '/img/asstronaut/typing.svg',
                linkProps: {
                    to: route.trainingCenter.index,
                    target: '_blank',
                    rel: 'noopener noreferrer',
                },
            },
            {
                title: t('Help Portal'),
                description: t('Explore advanced tools in Vidalytics. Visit our Help Portal.'),
                buttonText: t('Visit Help Portal'),
                imageSrc: '/img/asstronaut/flying.svg',
                linkProps: {
                    to: route.static.help.index,
                    external: true,
                    target: '_blank',
                    rel: 'noopener noreferrer',
                },
            },
            {
                title: t('Support Team'),
                description: (
                    <>
                        {t('If you ever need any help, just email us at')}{' '}
                        <Link to={`mailto:${HELP_MAIL}`}>{HELP_MAIL}</Link>
                    </>
                ),
                buttonText: t('Contact Support'),
                imageSrc: '/img/asstronaut/greeting.svg',
                buttonProps: {
                    onClick: handleOpenSupport,
                },
            },
        ],
        [],
    )

    return (
        <Stack gap={4} textAlign="center" alignItems="center">
            <CongratsIcon style={{ width: 168, height: 120 }} />
            <Stack gap={1}>
                <Typography variant="h6" mb={1} fontWeight={600}>
                    {t(`Welcome to ${plan?.name || subscription?.plan.name}!`)}
                </Typography>
                <Typography variant="subtitle2">
                    {t(
                        'Now that you have decided to up your video marketing game, make sure to check out the best features to make sure your videos perform their best.',
                    )}
                </Typography>
            </Stack>

            <Stack gap={4} direction="row">
                {infoCards.map((card) => (
                    <InfoCard key={card.title} {...card} />
                ))}
            </Stack>

            <Button variant="contained" color="primary" sx={{ alignSelf: 'flex-end' }} onClick={onClose}>
                {t('Let’s Go!')}
            </Button>
        </Stack>
    )
}

export default ActivationWelcome
