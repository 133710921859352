import { useCallback, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import Skeleton from '@mui/material/Skeleton'
import {
    map as _map,
    keys as _keys,
    reduce as _reduce,
    sortBy as _sortBy,
    isEqual as _isEqual,
    debounce as _debounce,
} from 'lodash'

import VidConditionsForm, {
    URL_PARAM_CONDITION_IDS,
    VIDEO_TAG_CONDITION_IDS,
} from 'design/organisms/VidConditions/VidConditionsForm/VidConditionsForm'
import { useVidConditionsMutation } from 'api/mutations'
import { useVideoConditionOptions, useVideoQuery } from 'api/queries'
import { VidConditionTargets, VideoGuid } from 'types/Video'

type VidConditionsChangeEvent = {
    changed: boolean
    targets: VidConditionTargets
    valid: boolean
    segmentsCount: number
}

type VidConditionsProps = {
    onChange: (event: VidConditionsChangeEvent) => void
    fallback: VideoGuid
    disabled?: boolean
}

type SavedRow = {
    target_video_id: VideoGuid
    condition_id?: number
    value?: string
}
let saved: SavedRow[] | undefined

const TM = 1000

const VidConditions = ({ onChange, fallback, disabled }: VidConditionsProps) => {
    const [isInitialLoading, setIsInitialLoading] = useState(true)
    const { data, isFetching: isFetchingVideo, refetch } = useVideoQuery(fallback)
    const { mutateAsync } = useVidConditionsMutation(fallback)
    const { data: videoConditionOptions, isLoading: isLoadingVideoConditionOptions } = useVideoConditionOptions()

    useEffect(() => {
        if (isInitialLoading && !isFetchingVideo) {
            setIsInitialLoading(false)
            const segmentsCount = _keys(data?.settings?.targeted?.segments).length
            if (segmentsCount) {
                onChange({ changed: false, valid: false, segmentsCount, targets: [] })
            }
        }
    }, [isFetchingVideo])

    useEffect(() => {
        refetch()
        saved = undefined
    }, [])

    const save = useCallback(async (value: VidConditionTargets) => {
        try {
            await mutateAsync(value)
        } catch (e) {
            Sentry.captureException(e)
        }
    }, [])

    const updateDebounced = useCallback(_debounce(save, TM), [save])

    const handleSaveDebounce = useCallback((value: VidConditionTargets) => updateDebounced(value), [updateDebounced])

    const handleChange = (targets: VidConditionTargets, valid: boolean) => {
        let mapped = _map(targets, (rule) => {
            const row: SavedRow = {
                target_video_id: rule.target_video_id,
            }

            if (
                VIDEO_TAG_CONDITION_IDS.includes(rule.condition_id) ||
                URL_PARAM_CONDITION_IDS.includes(rule.condition_id)
            ) {
                row.value = rule?.value as string
            } else {
                row.condition_id = rule.condition_id
            }

            return row
        })
        mapped = _sortBy(mapped, 'target_video_id')

        if (!saved) {
            saved = _reduce(
                data?.settings?.targeted?.segments,
                (acc: SavedRow[], { rules }) => {
                    _map(rules, (rule) => {
                        const row: SavedRow = {
                            target_video_id: rule.videoGuid as VideoGuid,
                        }

                        if (VIDEO_TAG_CONDITION_IDS.includes(rule.conditionId)) {
                            row.value = rule?.videoTag as string
                        } else if (URL_PARAM_CONDITION_IDS.includes(rule.conditionId)) {
                            row.value = rule?.urlParams as string
                        } else {
                            row.condition_id = rule.conditionId
                        }

                        acc.push(row)
                    })

                    return acc
                },
                [],
            )
            saved = _sortBy(saved, 'target_video_id')
        }

        const changed = !_isEqual(mapped, saved)

        if (changed) {
            saved = mapped
            handleSaveDebounce(targets)
        }

        onChange({
            changed,
            targets,
            valid: valid || targets?.length === 0,
            segmentsCount: targets?.length,
        })
    }

    return (
        <>
            {isInitialLoading && <Skeleton variant="rectangular" sx={{ width: 1, height: 42, borderRadius: 3 }} />}

            {!isInitialLoading && !isLoadingVideoConditionOptions && videoConditionOptions && (
                <VidConditionsForm
                    onChange={handleChange}
                    fallback={fallback}
                    segments={data?.settings?.targeted?.segments}
                    disabled={disabled}
                    videoConditionOptions={videoConditionOptions}
                />
            )}
        </>
    )
}

export default VidConditions
