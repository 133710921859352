import { useMemo, useRef, useState } from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { Formik } from 'formik'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import * as Yup from 'yup'
import { HOURS_STRING, MINUTES_STRING, secondsToTime, timeRegex, timeToSeconds } from 'utils'

import { Input } from 'design/atoms/Form'
import { TITLE } from 'design/pages/Conversions'
import {
    HOURS_INPUT_WIDTH,
    MINUTES_INPUT_MAX_WIDTH,
    MINUTES_INPUT_MIN_WIDTH,
    TIME,
} from 'design/pages/VidSettings/constants'

interface ChapterItemProps {
    guid: number
    time: number
    title?: string
    index: number
    handleRemove?: (key: number) => void
    handleChangeChapter?: (guid: number, title: string, time: number) => void
    existingTimes: number[]
    disabled: boolean
    videoLength?: string
}

const ChapterItemSchema = (timeArr: string[], videoLength: string) =>
    Yup.object().shape({
        title: Yup.string()
            .min(1, 'Chapter name is required')
            .max(50, 'Maximum character limit (50) reached for chapter title.')
            .required('Chapter name is required'),

        time: Yup.string()
            .matches(timeRegex, 'Please enter the time as "00:00" or "00:00:00')
            .test(
                'is-greater',
                'Chapter time should be less than video duration',
                (value) => timeToSeconds(value || '00:00') < timeToSeconds(videoLength),
            )
            .notOneOf(timeArr, 'You already have a chapter at this time.'),
    })

export const ChapterItem = ({
    guid,
    time,
    title,
    index,
    handleRemove,
    handleChangeChapter,
    existingTimes,
    disabled,
    videoLength,
}: ChapterItemProps) => {
    const [deleteState, setDeleteState] = useState<boolean>(false)
    const formRef = useRef<HTMLFormElement>(null)
    const defaultTimeString = useMemo(
        () => (timeToSeconds(videoLength || MINUTES_STRING) > 3600 ? HOURS_STRING : MINUTES_STRING),
        [videoLength],
    )
    return (
        <Box onMouseEnter={() => setDeleteState(true)} onMouseLeave={() => setDeleteState(false)}>
            <Formik
                initialValues={{ time: secondsToTime(time, defaultTimeString === HOURS_STRING), title }}
                validationSchema={ChapterItemSchema(
                    existingTimes.map((time) => secondsToTime(time)),
                    videoLength || MINUTES_STRING,
                )}
                onSubmit={(values, { setFieldValue }) => {
                    if (!values.time) {
                        setFieldValue(TIME, MINUTES_STRING, false)
                        values.time = defaultTimeString === HOURS_STRING ? HOURS_STRING : MINUTES_STRING
                    }
                    if (secondsToTime(time) !== values.time || title !== values.title) {
                        handleChangeChapter?.(guid, values.title || '', timeToSeconds(values.time))
                    }
                }}
                validateOnBlur={true}
                validateOnChange={true}
            >
                {({ errors, handleBlur, handleChange, submitForm, initialValues, values, setFieldValue }) => (
                    <form ref={formRef}>
                        <Stack pr={5}>
                            <Stack
                                flexDirection="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                gap={2}
                                mb={Object.keys(errors).length ? '3px' : 0}
                            >
                                <Typography variant="subtitle2" width={'15px'}>
                                    {index + 1}
                                </Typography>
                                <Input
                                    hideError
                                    fullWidth={false}
                                    disabled={disabled}
                                    onBlur={() => {
                                        if (!values.time) {
                                            setFieldValue(TIME, MINUTES_STRING)
                                            values.time =
                                                defaultTimeString === HOURS_STRING ? HOURS_STRING : MINUTES_STRING
                                        }
                                        if (values.time !== initialValues.time) {
                                            submitForm()
                                        }
                                    }}
                                    sx={{
                                        minWidth:
                                            defaultTimeString === HOURS_STRING
                                                ? HOURS_INPUT_WIDTH
                                                : MINUTES_INPUT_MIN_WIDTH,
                                        maxWidth:
                                            defaultTimeString === HOURS_STRING
                                                ? HOURS_INPUT_WIDTH
                                                : MINUTES_INPUT_MAX_WIDTH,
                                    }}
                                    onChange={handleChange}
                                    name={TIME}
                                    variant="outlined"
                                    placeholder={defaultTimeString}
                                    autoFocus={initialValues.title?.length === 0}
                                />
                                <Input
                                    hideError
                                    disabled={disabled}
                                    onBlur={(e) => {
                                        handleBlur(e)
                                        if (values.title !== initialValues.title) {
                                            submitForm()
                                        }
                                    }}
                                    name={TITLE}
                                    onChange={handleChange}
                                    variant="outlined"
                                    placeholder="Type chapter title"
                                />
                                <IconButton
                                    size="small"
                                    sx={{
                                        visibility: deleteState && !disabled ? 'visible' : 'hidden',
                                        color: '#2E3B548F',
                                        opacity: '56%',
                                        '&:hover': {
                                            background: 'none',
                                        },
                                    }}
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                    className="trash"
                                    data-testid={`deleteChapter-${guid}`}
                                    onClick={() => handleRemove?.(guid)}
                                >
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                            </Stack>
                            {errors.time && (
                                <Typography variant="caption1" color={'#D32F2F'} pl={'15px'}>
                                    {errors.time}
                                </Typography>
                            )}
                            {errors.title && (
                                <Typography variant="caption1" color={'#D32F2F'} pl={'15px'}>
                                    {errors.title}
                                </Typography>
                            )}
                        </Stack>
                    </form>
                )}
            </Formik>
        </Box>
    )
}
