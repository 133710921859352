import { SxProps, Theme } from '@mui/material'

const textField =
    (isSearching: boolean): SxProps<Theme> =>
    () => ({
        transition: 'width 0.3s',
        height: 41,
        width: 39,
        maxWidth: '50%',
        boxSizing: 'border-box',

        '& .MuiOutlinedInput-notchedOutline': {
            transition: 'border-color 0.3s',
        },

        '& .MuiInputBase-root': {
            transition: 'background-color 3s',
        },

        '& .MuiInputBase-input': {
            pl: 0,
        },

        ...(isSearching && {
            width: 375,
        }),

        ...(!isSearching && {
            overflow: 'hidden',

            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
            },

            '& .MuiInputBase-root': {
                backgroundColor: 'transparent',
            },
        }),
    })

export default {
    textField,
}
