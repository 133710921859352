import { useMemo } from 'react'

import { useCustomerSubscriptionQuery, usePlansQuery } from 'api/queries'
import { BillingMode, Plan, SubscriptionCategory } from 'types/Customer'
import { ENTERPRISE_PLAN, PLAYS_BASED_ENTERPRISE_PLAN } from '__mocks'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { isEnterprisePlan, isFreePlan, isPremiumPlan, isProPlan, isStarterPlan } from 'constants/plan.constants'

type TariffPlans = Record<SubscriptionCategory, Plan | undefined>

const useTariffPlans = (): TariffPlans => {
    const { data: apiPlans } = usePlansQuery()
    const { data: subscription } = useCustomerSubscriptionQuery()
    const billingMode = subscription?.plan?.billingMode
    const { freshUpgradeFlow } = useFeatureFlags()

    const plans = useMemo(() => {
        if (!apiPlans || !subscription?.plan) return []
        if (!freshUpgradeFlow || apiPlans.some((p) => p.id === subscription?.plan.id)) return apiPlans
        return [subscription.plan, ...apiPlans]
    }, [apiPlans, subscription, freshUpgradeFlow])

    const free = useMemo(() => plans.find((p) => isFreePlan(p)), [plans])
    const starter = useMemo(() => plans.find((p) => isStarterPlan(p)), [plans])
    const pro = useMemo(() => plans.find((p) => isProPlan(p)), [plans])
    const premium = useMemo(() => plans.find((p) => isPremiumPlan(p)), [plans])
    let enterprise = useMemo(() => plans.find((p) => isEnterprisePlan(p)), [plans])

    if (!enterprise && billingMode === BillingMode.bandWidth) enterprise = ENTERPRISE_PLAN
    if (!enterprise && billingMode === BillingMode.play) enterprise = PLAYS_BASED_ENTERPRISE_PLAN

    return { free, starter, pro, premium, enterprise }
}

export default useTariffPlans
