import { useCustomerQuery } from 'api/queries'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { CustomerStatus, Plan } from 'types/Customer'

export const useIsTrialAvailableForPlan = (plan?: Plan) => {
    const { data: customer } = useCustomerQuery()
    const { isFree } = useAccountSubscription()

    return Boolean(plan?.hasTrial && isFree && customer && customer.status !== CustomerStatus.trial)
}
