import { CSSProperties } from 'react'
import { SxProps, Theme } from '@mui/material'

const popper: SxProps = { zIndex: 1 }

const grow: CSSProperties = {
    transformOrigin: 'left top',
}

const opener: SxProps<Theme> = (theme) => ({
    p: '9px 20px 9px 20px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
})

const helpIconIdle: SxProps<Theme> = {
    mr: 0,
    color: (theme) => theme.palette.action.active,
}

const helpIconOpen: SxProps = {
    mr: 4,
}

const help: SxProps = {
    display: 'inline-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}

const opacityIdle: SxProps<Theme> = {
    transition: (theme) => `150ms ${theme.transitions.easing.easeOut}`,
    opacity: 0,
}

const helpIconActive: SxProps<Theme> = {
    color: (theme) => theme.palette.primary.main,
}

const opacityOpen: SxProps = {
    opacity: 1,
}

const menuList: SxProps = {
    py: 0,
    borderRadius: 3,
    overflow: 'hidden',
}

const paper: SxProps = {
    borderRadius: 3,
}

const sidebarNone: SxProps = {
    display: 'none',
}

const sidebarInitial: SxProps = {
    display: 'initial',
}

export default {
    popper,
    paper,
    grow,
    opener,
    helpIcon: {
        idle: helpIconIdle,
        open: helpIconOpen,
        active: helpIconActive,
    },
    opacity: {
        idle: opacityIdle,
        open: opacityOpen,
    },
    sidebar: {
        idle: sidebarNone,
        open: sidebarInitial,
    },
    help,
    menuList,
}
