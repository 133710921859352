import { useMemo } from 'react'

import { useUnathorizedFeatureFlagsQuery } from 'api/queries'

export const VID_9265 = 'VID-9265-marketing-sign-up'

const useUnathorizedFeatureFlags = () => {
    const { data: unathorizedFeatureFlags, ...rest } = useUnathorizedFeatureFlagsQuery()

    const featureFlags = useMemo(() => unathorizedFeatureFlags || {}, [unathorizedFeatureFlags])
    // const isFlagEnabled = useCallback((flag: typeof VID_4962) => Boolean(featureFlags[flag]), [featureFlags])

    return {
        queryInfo: rest,
        featureFlags,
    }
}

export default useUnathorizedFeatureFlags
