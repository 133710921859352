import { SxProps, Theme, alpha } from '@mui/material'

const root: SxProps<Theme> = {
    py: 2,
    pr: 4,
    pl: 3,
    cursor: 'pointer',

    '&:hover': {
        backgroundColor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    },
}

const rootSelected: SxProps<Theme> = {
    '&, &:hover': {
        backgroundColor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    },
}

const videoImage: SxProps = {
    width: 63,
    height: 36,
    minWidth: 63,
    objectFit: 'cover',
    borderRadius: 2,
}

export default {
    root: {
        idle: root,
        selected: rootSelected,
    },
    videoImage,
}
