import { SxProps, Theme } from '@mui/material'
import { alpha } from '@mui/material/styles'

const container: SxProps<Theme> = {
    p: 6,
    pt: 5.5,
    gap: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',

    borderRadius: 4,
    border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    background: (theme) => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
}

const chip: SxProps<Theme> = {
    position: 'absolute',
    top: -16,
    left: 24,
    py: 0.75,
    px: 2,
    borderRadius: 2,

    '.MuiChip-label': {
        p: 0,
    },
}

export default { container, chip }
