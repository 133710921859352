import { ElementType, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import BoltRoundedIcon from '@mui/icons-material/BoltRounded'

import { Link } from 'design/atoms/Link'
import { ConditionalWrapper } from 'design/molecules/ConditionalWrapper'
import UpgradeBadge from 'design/molecules/UpgradeBadge'
import { FeatureLockedOpenModalPayload, SubscriptionCtaSource } from 'design/templates/Modal'
import useFeatureFlags from 'hooks/system/useFeatureFlags'

import { style } from './Sidebar.style'

interface SidebarItemProps {
    label: string
    isInternal: boolean
    route: string
    isMobile?: boolean
    isNew?: boolean
    isLocked?: boolean
    mobileClose?(): void
    Icon: ElementType
    open: boolean
    pathname: string
    withOpenIcon?: boolean
    handleOpenIconClick?: MouseEventHandler<SVGSVGElement>
    subscriptionCtaConfig?: FeatureLockedOpenModalPayload
}

export const SidebarItem = ({
    label,
    isInternal,
    route,
    isMobile,
    isLocked,
    isNew,
    mobileClose,
    Icon,
    open,
    withOpenIcon,
    handleOpenIconClick,
    pathname,
    subscriptionCtaConfig,
}: SidebarItemProps) => {
    const { sidebarUpgradeIconStarterPlan, groupStatistic } = useFeatureFlags()
    const { t } = useTranslation()

    return (
        <Link
            key={label}
            to={route}
            external={!isInternal}
            target={!isInternal ? '_blank' : undefined}
            sx={style.itemLink}
            onClick={() => isMobile && mobileClose?.()}
        >
            <ListItem disablePadding data-tooltip={open ? undefined : label}>
                <ConditionalWrapper
                    condition={!open}
                    wrapper={(children) => (
                        <Tooltip arrow title={label} placement="right">
                            <Box flexGrow={1}>{children}</Box>
                        </Tooltip>
                    )}
                >
                    <ListItemButton sx={style.listItem.button} selected={pathname.startsWith(route)}>
                        <ListItemIcon sx={style.listItem.icon}>
                            <Icon sx={pathname.startsWith(route) && style.listItem.iconSelected} />
                            {isLocked && !open && sidebarUpgradeIconStarterPlan && (
                                <BoltRoundedIcon sx={style.upgradeIcon} />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary={label}
                            primaryTypographyProps={{
                                color: pathname.startsWith(route) ? 'primary.main' : 'text.primary',
                                variant: 'body1',
                            }}
                            sx={[style.listItem.text.idle, open && style.listItem.text.open]}
                        />
                        {isLocked && open && sidebarUpgradeIconStarterPlan && (
                            <UpgradeBadge
                                size="xSmall"
                                source={SubscriptionCtaSource.SIDEBAR}
                                config={subscriptionCtaConfig}
                            />
                        )}
                        {isNew && groupStatistic && open && (
                            <Button size="small" variant="contained" sx={style.newBtn}>
                                {t('New')}
                            </Button>
                        )}
                        {isNew && groupStatistic && !open && (
                            <Button variant="contained" sx={style.newBtnClosed}>
                                {t('New')}
                            </Button>
                        )}
                    </ListItemButton>
                </ConditionalWrapper>
            </ListItem>
            {withOpenIcon && (
                <PlayCircleIcon
                    sx={[style.drawerHandlerIcon.idle, open && style.drawerHandlerIcon.open]}
                    color="primary"
                    onClick={handleOpenIconClick}
                />
            )}
        </Link>
    )
}
