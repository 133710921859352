import Card, { CardProps } from '@mui/material/Card'
import { Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { AstronautWaitingIcon } from 'assets/index'
import { useTheme } from '@mui/material/styles'
import { ReactNode } from 'react'
import style from './AgencyDashboardCard.styles'

interface AgencyDashboardCardProps {
    isLoading?: boolean
    children?: ReactNode
    CardProps?: CardProps
}

export const AgencyDashboardCard = ({ isLoading, CardProps, children }: AgencyDashboardCardProps) => {
    const { palette } = useTheme()

    return (
        <Card {...CardProps} sx={CardProps?.sx ? { ...style.container, ...CardProps.sx } : style.container}>
            {isLoading ? (
                <>
                    <Stack gap={4} justifyContent="space-between" alignItems="center" direction="row">
                        <Skeleton variant="circular" width={40} height={40} />
                        <Stack flexGrow={1}>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="30%" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="75%" />
                        </Stack>
                        <Stack gap={1} direction="row">
                            <Skeleton variant="rounded" width={28} height={28} />
                            <Skeleton variant="rounded" width={28} height={28} />
                        </Stack>
                    </Stack>
                    <Stack gap={1} justifyContent="center" alignItems="center" flexGrow={1}>
                        <AstronautWaitingIcon style={{ fill: palette.action.disabled }} />
                    </Stack>
                </>
            ) : (
                children
            )}
        </Card>
    )
}
