import { useQuery, UseQueryOptions } from 'react-query'

import { analyticsAPI } from 'api/controllers'
import { GROUP_STATS_QUERY } from 'api/constants'
import { useAppSelector } from 'App'
import { selectAnalyticsDateRange } from 'design/pages/Analytics/AnalyticsDatePicker/analyticsDateRange.slice'
import { format } from 'date-fns'
import { AnalyticsData } from 'design/pages/Analytics/types'
import { useAnalyticsFilterParams } from 'api/hooks/useAnalyticsFilterParams'

export const useGroupStatisticsQuery = (options?: UseQueryOptions<AnalyticsData, unknown, AnalyticsData>) => {
    const dates = useAppSelector(selectAnalyticsDateRange)
    const filterStringParams = useAnalyticsFilterParams()

    const dateFrom = format(dates[0], 'yyyy-MM-dd')
    const dateTo = format(dates[1], 'yyyy-MM-dd')

    const params = [`dateFrom=${dateFrom}`, `dateTo=${dateTo}`, filterStringParams].filter(Boolean).join('&')

    return useQuery<AnalyticsData, unknown, AnalyticsData>(
        [GROUP_STATS_QUERY, params],
        () => analyticsAPI.getGroupStats(params),
        {
            ...options,
        },
    )
}
