import { useQuery, UseQueryOptions } from 'react-query'
import { VIDEO_CONDITION_OPTIONS_QUERY } from 'api/constants'
import { videoAPI } from 'api/controllers'

type VideoConditionsOption = {
    id: number
    selectLabel: string
    label: string
    value: string | null
}

type OptionCategory = {
    label: string
    options: VideoConditionsOption[]
}

type OptionList = {
    width?: OptionCategory
    isReturning?: OptionCategory
    os?: OptionCategory
    urlParams?: OptionCategory
    videoTag?: OptionCategory
    language?: OptionCategory
}

type VideoTag = {
    id: string
    title: string
    videoTitle: string
}

type Dictionaries = {
    'video-tag': VideoTag[]
}

export type VideoConditionsOptions = {
    'option-list': OptionList
    dictionaries: Dictionaries
}

export const useVideoConditionOptions = (options?: UseQueryOptions<VideoConditionsOptions>) =>
    useQuery<VideoConditionsOptions>(
        [VIDEO_CONDITION_OPTIONS_QUERY],
        async () => {
            return await videoAPI.getVidConditionOptions()
        },
        {
            ...options,
        },
    )
