import { FilterState } from 'design/pages/Analytics/types'

export const getParameterFromURL = (parameter: string): string[] => {
    const url = window.location.href
    const queryString = url.split('?')[1]

    if (!queryString) return []

    const params = queryString.split('&')
    const paramValue = params.map((param) => param.split('=')).find(([key]) => key === parameter)?.[1]

    return paramValue ? decodeURIComponent(paramValue).split(',') : []
}

export const setParameterInURL = (parameter: string, value?: string) => {
    const url = window.location.href
    const [path, queryString] = url.split('?')
    const params = queryString ? queryString.split('&') : []

    const filteredParams = params.filter((param) => !param.startsWith(`${parameter}=`))

    if (value) {
        filteredParams.push(`${parameter}=${decodeURIComponent(value).replace(/ /g, '%20')}`)
    }

    const newQueryString = filteredParams.join('&')
    const newURL = newQueryString ? `${path}?${newQueryString}` : path

    window.history.replaceState({}, '', newURL + window.location.hash)
}

export const getFiltersFromURL = (selectedFilters: FilterState) => {
    const params = new URLSearchParams(window.location.search)
    const filters: Record<string, string[]> = { ...selectedFilters }

    params.forEach((_value, key) => {
        if (key in selectedFilters) {
            filters[key] = params.get(key)?.split(',').map(decodeURIComponent) || []
        }
    })

    return filters
}

export const setFiltersInURL = (filters: FilterState) => {
    const url = window.location.href
    const [path, queryString] = url.split('?')
    let params = queryString ? queryString.split('&') : []

    Object.entries(filters).forEach(([key, values]) => {
        const decode = (value: string) => decodeURIComponent(value)
        params = params.filter((param) => !param.startsWith(`${key}=`))

        let filterParam = ''
        if (Array.isArray(values) && values.length > 0) {
            filterParam = `${key}=${values.map(decode).join(',')}`
        } else if (typeof values === 'string') {
            filterParam = `${key}=${decode(values)}`
        }
        if (filterParam) {
            params.push(filterParam)
        }
    })

    const newQueryString = params.join('&')
    const newURL = newQueryString ? `${path}?${newQueryString}` : path

    window.history.replaceState({}, '', newURL + window.location.hash)
}
