import operations from 'locales/operations'

export default {
    title: 'Change Email',
    currentEmail: 'Current Email',
    newEmail: 'New Email',
    currentPassword: 'Current password',
    currentPasswordPlaceholder: 'Enter your current password',
    emailPlaceholder: 'you@example.com',
    cancel: operations.generic.cancel,
    save: operations.generic.save,
}
