export const generateRandomPassword = () => String(crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32).slice(2)

export const stringPriceToNumber = (price?: string) => {
    if (!price) {
        return 0
    }

    return parseFloat(price.replace(/,/g, ''))
}

export const replaceBrToLineBreak = (value?: string) => value?.replace(/<br\s*\/?>/gi, '\n')

export const truncateText = (text: string, maxLength = 21) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength - 3) + '...'
    }
    return text
}

export const extractNumber = (str?: string) => {
    if (!str) return null

    const match = str.match(/\d+/)

    return match ? parseInt(match[0], 10) : null
}
