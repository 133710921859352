import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import { Box, Button, Typography, IconButton } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import CloseIcon from '@mui/icons-material/Close'
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare'

import { useLayout } from 'hooks/utilities/useLayout'
import { route } from 'constants/routes'

import style from './TemporaryMobileBanner.style'

export const BANNER_WAS_SHOWN = 'temporaryMobileBannerWasShown'

export const TemporaryMobileBanner = () => {
    const { t } = useTranslation()
    const { isMobile } = useLayout()

    const isException = [
        Boolean(useMatch(route.welcome.index)),
        Boolean(useMatch(route.upsell())),
        Boolean(useMatch(route.account.paymentProcessing)),
        Boolean(useMatch(route.account.updatePayment)),
    ].some((item) => item)

    const wasBannerShown = localStorage.getItem(BANNER_WAS_SHOWN) === 'true'
    const [isBannerOpen, setIsBannerOpen] = useState(!wasBannerShown && isMobile && !isException)

    const closeBanner = useCallback(() => {
        setIsBannerOpen(false)
        localStorage.setItem(BANNER_WAS_SHOWN, 'true')
    }, [])

    const handleShare = useCallback(async () => {
        try {
            const url = `${window.location.origin}${route.auth.signIn}`

            await navigator.share({ url })
        } catch (error) {
            console.error('There is some error during sharing link: ', error)
        }

        closeBanner()
    }, [])

    if (!isBannerOpen) {
        return null
    }

    return (
        <Box sx={style.container}>
            <Box sx={style.backdrop} onClick={closeBanner} />
            <Box sx={style.wrapper} data-testId="tempMobileBanner">
                <IconButton sx={style.close} onClick={closeBanner}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" sx={style.title}>
                    {t('Hey! Vidalytics is best experienced on desktop.')}
                </Typography>
                <Typography variant="body2" sx={style.subtitle}>
                    {t(
                        "We're working hard to bring you a great mobile experience, but in the meantime, we suggest logging into your desktop to really take advantage of all that Vidalytics has to offer 😉",
                    )}
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<ShareIcon />}
                    fullWidth
                    sx={style.button}
                    onClick={handleShare}
                    data-testId="shareTheLoginLinkButton"
                >
                    {t('Share the login link')}
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<MobileScreenShareIcon />}
                    fullWidth
                    color="tertiary"
                    onClick={closeBanner}
                    data-testId="continueWithMobileButton"
                >
                    {t('Continue with Mobile')}
                </Button>
            </Box>
        </Box>
    )
}

export default TemporaryMobileBanner
