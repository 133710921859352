import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { ColorPicker, Input } from 'design/atoms/Form'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import {
    PLAYBACK_RESUME,
    UI_OVERLAY_RESUME_BUTTON_REPLAY_TEXT,
    UI_OVERLAY_RESUME_BUTTON_RESUME_TEXT,
    UI_OVERLAY_RESUME_COLOR_BACKGROUND,
    UI_OVERLAY_RESUME_COLOR_FOREGROUND,
    UI_OVERLAY_RESUME_SHOW,
    UI_OVERLAY_RESUME_TEXT,
} from 'design/pages/VidSettings/constants'
import { ResumePlayFormData } from 'types/VidSettings'
import { replaceBrToLineBreak } from 'utils'

import './index.scss'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

export const ResumePlayContent = ({ isSmartVidGlobalSettings }: Props) => {
    const { t } = useTranslation()
    const { playerThemesSettings } = useFeatureFlags()
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )

    const {
        isLoading,
        formik: { values, setValues },
        video,
        onChange,
    } = useSectionContext<ResumePlayFormData>()

    const commonProps = {
        disabled: isLoading,
        switchProps: { size: 'small' },
    } as const

    return (
        <Box className="ResumePlayContent">
            <Box pr={2}>
                <SwitchForm
                    sx={style.container}
                    name={PLAYBACK_RESUME}
                    label={
                        <Typography
                            className="radioTitle radioTitle--without-mb"
                            variant="body2"
                            {...(isLoading || video?.funnel ? { color: 'text.disabled' } : {})}
                        >
                            {t('Resume Play')}
                        </Typography>
                    }
                    labelPlacement="start"
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    helpInfo={
                        isLoading || video?.funnel
                            ? t(
                                  'This option cannot be enabled on this video because it is being used in a Smart Vid. If you want to enable them, please delete the video from the funnel first.',
                              )
                            : t(
                                  'Your video will start from where your viewer previously left off. To change the message shown, click the ‘Advanced Options’ below.',
                              )
                    }
                    onChange={(name, value) => {
                        setValues({
                            ...values,
                            [name]: value,
                            [UI_OVERLAY_RESUME_SHOW]: value,
                        })
                        onChange()
                    }}
                    {...commonProps}
                />
            </Box>
            <Box className="wrapper">
                <SwitchForm
                    sx={style.container}
                    name={UI_OVERLAY_RESUME_SHOW}
                    label={<Typography variant="body2">{t('Resume Play Message')}</Typography>}
                    labelPlacement="start"
                    labelClassName="resumePlayMessage"
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    afterChange={onChange}
                    helpInfo={t(
                        'Gives your viewers the option to start over or not. When disabled, your video will start from wherever a returning visitor watched to last with no option to start over.',
                    )}
                    {...commonProps}
                />

                <Box sx={{ mb: 3 }}>
                    <Input
                        name={UI_OVERLAY_RESUME_TEXT}
                        label={t('Headline')}
                        inputProps={{
                            maxLength: 60,
                            value: replaceBrToLineBreak(values[UI_OVERLAY_RESUME_TEXT]),
                        }}
                        variant="outlined"
                        displayMaxLength
                        onBlur={onChange}
                        {...commonProps}
                        rows={3}
                        multiline
                    />
                </Box>
                <Box sx={{ mb: 3 }}>
                    <Input
                        name={UI_OVERLAY_RESUME_BUTTON_RESUME_TEXT}
                        label={t('Resume Text')}
                        inputProps={{
                            maxLength: 50,
                            value: replaceBrToLineBreak(values[UI_OVERLAY_RESUME_BUTTON_RESUME_TEXT]),
                        }}
                        onBlur={onChange}
                        variant="outlined"
                        rows={2}
                        multiline
                        displayMaxLength
                        {...commonProps}
                    />
                </Box>
                <Box sx={{ mb: 3 }}>
                    <Input
                        name={UI_OVERLAY_RESUME_BUTTON_REPLAY_TEXT}
                        label={t('Replay Text')}
                        inputProps={{
                            maxLength: 50,
                            value: replaceBrToLineBreak(values[UI_OVERLAY_RESUME_BUTTON_REPLAY_TEXT]),
                        }}
                        variant="outlined"
                        rows={2}
                        multiline
                        onBlur={onChange}
                        displayMaxLength
                        {...commonProps}
                    />
                </Box>
                <Box className="resumeColors">
                    <ColorPicker
                        name={UI_OVERLAY_RESUME_COLOR_FOREGROUND}
                        afterChange={onChange}
                        label={t('Text Color')}
                        prefixed
                        {...commonProps}
                    />
                    <ColorPicker
                        name={UI_OVERLAY_RESUME_COLOR_BACKGROUND}
                        label={playerThemesSettings ? t('Background Color') : t('Shadow Color')}
                        afterChange={onChange}
                        prefixed
                        {...commonProps}
                    />
                </Box>
            </Box>
        </Box>
    )
}
