import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import * as Sentry from '@sentry/react'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import * as yup from 'yup'

import { useUpdateVideoMutation } from 'api/mutations'
import { useVideoQuery } from 'api/queries'
import { Input } from 'design/atoms/Form'
import { CC_FILE_EXT } from 'design/organisms/ClosedCaptionsEditor/constants'
import useSvIds from 'design/pages/SmartVid/hooks/useSvIds'
import { UI_CLOSED_CAPTIONS_NAME } from 'design/pages/VidSettings/constants'
import { Modal } from 'design/templates/Modal'

import locale from './ClosedCaptionsRenameModal.locale'

type ClosedCaptionsRenameModalProps = {
    name: string
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

type RenameClosedCaptionsForm = {
    [UI_CLOSED_CAPTIONS_NAME]: string
}

export const validationSchema = yup.object({
    [UI_CLOSED_CAPTIONS_NAME]: yup
        .string()
        .min(3, locale.minLength3)
        .max(30, locale.maxLength30)
        .required(locale.reqired),
})

export const ClosedCaptionsRenameModal = ({ name, isOpen, setIsOpen }: ClosedCaptionsRenameModalProps) => {
    const { videoId } = useSvIds()
    const { data } = useVideoQuery(videoId)

    const { mutateAsync, isLoading } = useUpdateVideoMutation(String(videoId))

    const handleSubmit = useCallback(
        async (form: RenameClosedCaptionsForm) => {
            if (!data?.video) return

            try {
                await mutateAsync({
                    ...data,
                    settings: {
                        ...data.settings,
                        [UI_CLOSED_CAPTIONS_NAME]: `${form[UI_CLOSED_CAPTIONS_NAME]}.${CC_FILE_EXT}`,
                    },
                })
            } catch (e) {
                Sentry.captureException(e)
            } finally {
                setIsOpen(false)
            }
        },
        [data?.video],
    )

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)} stackProps={{ sx: { minHeight: 190 } }} width="sm">
            <Formik
                validationSchema={validationSchema}
                initialValues={{ [UI_CLOSED_CAPTIONS_NAME]: name }}
                onSubmit={handleSubmit}
            >
                {(props) => (
                    <>
                        <Modal.Header title={locale.title} onClose={() => setIsOpen(false)} />
                        <Modal.Body>
                            <Form>
                                <Input
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    name={UI_CLOSED_CAPTIONS_NAME}
                                    variant="outlined"
                                    inputProps={{
                                        value: props.values[UI_CLOSED_CAPTIONS_NAME],
                                    }}
                                />
                            </Form>
                        </Modal.Body>
                        <Modal.Actions>
                            <Button variant="text" onClick={() => setIsOpen(false)}>
                                {locale.cancel}
                            </Button>
                            <LoadingButton variant="contained" onClick={() => props.handleSubmit()} loading={isLoading}>
                                {locale.save}
                            </LoadingButton>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    )
}

export default ClosedCaptionsRenameModal
