import { useCallback } from 'react'
import { useIntercom } from 'react-use-intercom'

export const useOpenSupport = (callback?: () => void) => {
    const { show } = useIntercom()

    return useCallback(() => {
        show()
        callback?.()
    }, [show, callback])
}
