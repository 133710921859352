import { get as _get } from 'lodash'
import { prettifyNumber, simplifyNumber } from 'utils'
import { METRICS, metricsConfig } from 'constants/metrics.constants'

interface MetricMeta {
    transform: (value: string, options?: { simplify?: boolean; rounding?: number }) => string
    type?: boolean
}

export const metricsTransformConfig: { [key in METRICS]: MetricMeta } = {
    [METRICS.AVERAGE_PERCENT_WATCHED]: {
        transform: (value, options) => `${parseFloat(value).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.AVG_ORDER]: {
        transform: (value, options) => `${parseFloat(value).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.AVG_REVENUE_PER_PLAY]: {
        transform: (value, options) => `${parseFloat(value).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.AVG_WATCH_TIME]: {
        transform: (value, options) => `${(parseFloat(value) * 100).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.CONVERSIONS]: {
        transform: (value, options) =>
            `${options?.simplify !== false ? simplifyNumber(parseFloat(value)) : prettifyNumber(parseFloat(value))}`,
    },
    [METRICS.CONVERSION_RATE]: {
        transform: (value, options) => `${(parseFloat(value) * 100).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.CTA_CTR]: {
        transform: (value, options) => `${(parseFloat(value) * 100).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.IMPRESSIONS]: {
        transform: (value, options) => `${parseFloat(value).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.OPT_IN_RATE]: {
        transform: (value, options) => `${(parseFloat(value) * 100).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.PLAYS]: {
        transform: (value, options) =>
            `${options?.simplify !== false ? simplifyNumber(parseFloat(value)) : prettifyNumber(parseFloat(value))}`,
    },
    [METRICS.PLAY_RATE]: {
        transform: (value, options) => `${(parseFloat(value) * 100).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.REVENUE]: {
        transform: (value, options) =>
            `${
                options?.simplify !== false
                    ? simplifyNumber(parseFloat(value))
                    : prettifyNumber(parseFloat(value), _get(options, 'rounding', 0))
            }`,
    },
    [METRICS.TAGS_TRIGGERED_RATE]: {
        transform: (value, options) => `${(parseFloat(value) * 100).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.TOTAL_PLAYS]: {
        transform: (value, options) => `${parseFloat(value).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.UNIQUE_VIEWERS]: {
        transform: (value, options) => `${parseFloat(value).toFixed(_get(options, 'rounding', 2))}`,
    },
    [METRICS.UNMUTE_RATE]: {
        transform: (value, options) => `${parseFloat(value).toFixed(_get(options, 'rounding', 2))}`,
    },
}

/**
 * Decorates specific metric value with unified prefix and postfix.
 */
export const metricsDecorator = (
    metric: METRICS,
    value: string,
    options?: { simplify?: boolean; rounding?: number },
) => {
    return `${metricsConfig[metric].prefix}${metricsTransformConfig[metric].transform(value, options) || value}${
        metricsConfig[metric].postfix
    }`
}
