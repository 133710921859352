import React from 'react'
import { SvgIconProps } from '@mui/material'
import { GridValidRowModel } from '@mui/x-data-grid-pro'
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DevicesIcon from '@mui/icons-material/Devices'
import MapRoundedIcon from '@mui/icons-material/MapRounded'
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded'
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import AffilatesRounded from 'assets/icons/AffilatesRounded'

interface AnalyticsRequest {
    videos: string[] | null
    dateFrom: string
    dateTo: string
    metric: string
    filters: string | null
    interval: string
}

export interface AnalyticsVideo {
    video: VideoInfo
    total: VideoMetrics
}

interface VideoInfo {
    guid: string
    title: string
    preview: string
    videoTags: string[]
    folder: {
        id: string
        title: string
    }
}

interface VideoMetrics {
    plays: number
    playRate: number
    impressions: number
    uniquePlays: number
    unmuteRate: number
    engagement: number
    conversions: number
    conversionRate: number
    averageOrder: number
    optInRate: number
    revenue: number
    bounceRate: number
    revenuePerViewer: number
}

export interface AnalyticsData {
    request: AnalyticsRequest
    videos: AnalyticsVideo[]
    total: VideoMetrics
}

interface SelectedMetrics {
    [date: string]: {
        [guid: string]: number
    }
}
export interface AnalyticsMetricsData {
    request: AnalyticsRequest
    metrics: SelectedMetrics
}

export interface URLParamsData {
    id: string
    title: string
}

export enum FilterGroupKeys {
    CB = 'CB',
    Other = 'Other',
}

export interface FiltersOption {
    title: string
    value: string
    parent_id: string | null
    group?: FilterGroupKeys.CB | FilterGroupKeys.Other
}

export interface FolderOption {
    option: FiltersOption
    level: number
    hasChildren: boolean
    visible: boolean
}

export interface FiltersCategory {
    title: string
    options: FiltersOption[]
}

export interface AnalyticsFiltersData {
    browsers: FiltersCategory
    conversions: FiltersCategory
    devices: FiltersCategory
    folders: FiltersCategory
    geo: FiltersCategory
    new_vs_returning: FiltersCategory
    affiliate: FiltersCategory
}

export enum MetricKeys {
    plays = 'plays',
    playRate = 'play_rate',
    impressions = 'impressions',
    playsUnique = 'unique_viewers',
    unmuteRate = 'unmute_rate',
    engagement = 'avg_watched',
    conversionRate = 'conversion_rate',
    revenueAverage = 'average_order',
    revenue = 'revenue',
    revenuePerViewer = 'revenue_per_viewer',
    bounceRate = 'bounce_rate',
    conversionCount = 'conversions',
    pgOptInRate = 'opt_in_rate',
}

export enum MetricIntervals {
    hour = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
}

export enum FilterKeys {
    browsers = 'browsers',
    conversions = 'conversions',
    devices = 'devices',
    geo = 'geo',
    newVsReturning = 'new_vs_returning',
    folders = 'folders',
    urlTracking = 'url_params',
    affiliate = 'affiliate',
}

export type FilterState = { [key in FilterKeys]: string[] }

export interface InitialStateType {
    metricsMenu: {
        [key in MetricKeys]: boolean
    }
    intervalMenu: {
        [key in MetricIntervals]: boolean
    }
    filters: FilterState
    urlSelectedType: string
    selectedRows: string[]
}

export type MetricsMenuType = {
    disabled: boolean
    key: MetricKeys
    label: string
    type: string
}

export type IntervalMenuType = {
    disabled: boolean
    key: MetricIntervals
    label: string
}

export type SelectedRecord = GridValidRowModel & { color: string }

export const analyticsMetricsMenu = [
    {
        label: 'Plays',
        key: MetricKeys.plays,
        disabled: false,
        type: 'number',
    },
    {
        label: 'Play Rate',
        key: MetricKeys.playRate,
        disabled: false,
        type: 'percentage',
    },
    {
        label: 'Impressions',
        key: MetricKeys.impressions,
        disabled: false,
        type: 'number',
    },
    {
        label: 'Unique Viewers',
        key: MetricKeys.playsUnique,
        disabled: false,
        type: 'number',
    },
    {
        label: 'Unmute Rate',
        key: MetricKeys.unmuteRate,
        disabled: false,
        type: 'percentage',
    },
    {
        label: 'Avg % Watched',
        key: MetricKeys.engagement,
        disabled: false,
        type: 'percentage',
    },
    {
        label: 'Conversions',
        key: MetricKeys.conversionCount,
        disabled: false,
        type: 'number',
    },
    {
        label: 'Conversions Rate',
        key: MetricKeys.conversionRate,
        disabled: false,
        type: 'percentage',
    },
    {
        label: 'Average Order Value',
        key: MetricKeys.revenueAverage,
        disabled: false,
        type: 'currency',
    },
    {
        label: 'Opt-In Rate',
        key: MetricKeys.pgOptInRate,
        disabled: false,
        type: 'percentage',
    },
    {
        label: 'Revenue',
        key: MetricKeys.revenue,
        disabled: false,
        type: 'currency',
    },
    {
        label: 'Revenue per Viewer',
        key: MetricKeys.revenuePerViewer,
        disabled: false,
        type: 'currency',
    },
    {
        label: 'Bounce Rate',
        key: MetricKeys.bounceRate,
        disabled: false,
        type: 'percentage',
    },
]

export const analyticsIntervalMenu = [
    {
        label: 'Hours',
        key: MetricIntervals.hour,
        disabled: false,
    },
    {
        label: 'Days',
        key: MetricIntervals.day,
        disabled: false,
    },
    {
        label: 'Weeks',
        key: MetricIntervals.week,
        disabled: false,
    },
    {
        label: 'Months',
        key: MetricIntervals.month,
        disabled: false,
    },
]

export interface FilterSectionType {
    key: FilterKeys
    label: string
    icon: React.ComponentType<SvgIconProps>
    hasSearch: boolean
    hasSelectCheckbox: boolean
}

export const filterSection: FilterSectionType[] = [
    {
        key: FilterKeys.conversions,
        label: 'Converted',
        icon: MonetizationOnRoundedIcon,
        hasSearch: false,
        hasSelectCheckbox: false,
    },
    {
        key: FilterKeys.newVsReturning,
        label: 'New vs. Returning',
        icon: VisibilityIcon,
        hasSearch: false,
        hasSelectCheckbox: false,
    },
    {
        key: FilterKeys.devices,
        label: 'Device Type',
        icon: DevicesIcon,
        hasSearch: false,
        hasSelectCheckbox: false,
    },
    {
        key: FilterKeys.browsers,
        label: 'Browser',
        icon: WindowOutlinedIcon,
        hasSearch: true,
        hasSelectCheckbox: true,
    },
    {
        key: FilterKeys.geo,
        label: 'Geo',
        icon: MapRoundedIcon,
        hasSearch: true,
        hasSelectCheckbox: true,
    },
    {
        key: FilterKeys.affiliate,
        label: 'Affiliates',
        icon: AffilatesRounded,
        hasSearch: true,
        hasSelectCheckbox: true,
    },
    {
        key: FilterKeys.urlTracking,
        label: 'URL Tracking',
        icon: LinkRoundedIcon,
        hasSearch: true,
        hasSelectCheckbox: true,
    },
    {
        key: FilterKeys.folders,
        label: 'Folders',
        icon: FolderOpenRoundedIcon,
        hasSearch: true,
        hasSelectCheckbox: false,
    },
]

export const initialSelectedFilters: FilterState = {
    [FilterKeys.browsers]: [],
    [FilterKeys.conversions]: [],
    [FilterKeys.devices]: [],
    [FilterKeys.geo]: [],
    [FilterKeys.newVsReturning]: [],
    [FilterKeys.folders]: [],
    [FilterKeys.urlTracking]: [],
    [FilterKeys.affiliate]: [],
}

export const AffiliatesLabels = {
    [FilterGroupKeys.CB]: 'ClickBank',
    [FilterGroupKeys.Other]: 'Other',
}
