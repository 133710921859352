import { UseMutationOptions } from 'react-query'

import { PROCESS_UPSELL_MUTATION } from 'api/constants'
import { authAPI } from 'api/controllers'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { ProcessUpsellPayload, ProcessUpsellResponse } from 'types/Auth'

export const useProcessUpsellMutation = (
    options?: UseMutationOptions<ProcessUpsellResponse, unknown, ProcessUpsellPayload>,
) => {
    const { mutate } = useMutationToastDecorator({ showSuccessToast: false, showPendingToast: false })

    return useInvalidateMutation<ProcessUpsellResponse, ProcessUpsellPayload>(
        PROCESS_UPSELL_MUTATION,
        async (payload: ProcessUpsellPayload) => mutate(authAPI.processUpsell(payload), {}),
        options,
    )
}
