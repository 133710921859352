import { createSvgIcon } from '@mui/material'

const EditIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M10.0413 4.34962L10.987 3.404C11.0456 3.34541 11.125 3.3125 11.2079 3.3125C11.2908 3.3125 11.3702 3.34541 11.4288 3.404L12.5957 4.57056C12.6543 4.62916 12.6872 4.70863 12.6872 4.7915C12.6872 4.87436 12.6543 4.95383 12.5957 5.01243L11.6501 5.95837L10.0413 4.34962ZM9.59945 4.7915L3.98695 10.404C3.95271 10.4383 3.92692 10.4802 3.91164 10.5262L3.32851 12.2762C3.31287 12.3231 3.30861 12.3731 3.31607 12.4221C3.32354 12.471 3.34251 12.5174 3.37144 12.5576C3.40037 12.5977 3.43842 12.6305 3.48246 12.653C3.5265 12.6756 3.57527 12.6874 3.62476 12.6874C3.65834 12.6874 3.6917 12.6819 3.72351 12.6712L5.47351 12.0881C5.51948 12.0727 5.56129 12.0469 5.5957 12.0127L11.2079 6.40025L9.59945 4.7915Z"
            fill="currentColor"
        />
    </svg>,
    'EditIcon',
)

export default EditIcon
