import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Stack, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded'
import { debounce as _debounce, get } from 'lodash'

import usePageTitle from 'hooks/navigation/usePageTitle'
import {
    ExpandedVideosWithFolders,
    isExpandedVideo,
    useVideosWithFolders,
} from 'design/organisms/MyVids/hooks/useVideosWithFolders'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { VIDEO_FOLDERS_QUERY, VIDEOS_QUERY } from 'api/constants'
import { queryClient, useCustomerQuery } from 'api/queries'
import { APPCUES_EVENTS, trackAppCuesEventOnce } from 'thirdPartyServices/appCues'
import { PLAYS_LOG_THRESHOLD } from 'thirdPartyServices/appCues/constants'
import Page from 'design/templates/Page'
import SearchField from 'design/atoms/Table/Toolbar/SearchField'
import { HEADLINE } from 'design/atoms/Table/constants'
import VidsTable from 'design/organisms/MyVids/VidsTable/VidsTable'
import { CreateNewFolderModal } from 'design/templates/Modal/modalTemplates/MyVids/CreateNewFolderModal'
import { Link } from 'design/atoms/Link'
import { useFolderChildrenWithPath } from 'design/pages/MyVids'
import { Folder } from 'types/Video'
import { InputFocusHandler } from 'types/index'
import { useFolderNameChangeMutation } from 'api/mutations'
import EditableName from 'design/atoms/Table/Row/Folder/EditableName'
import { useLayout } from 'hooks/utilities/useLayout'
import { filterByTitle } from 'design/organisms/MyVids/MyVids.utils'
import { ROOT } from 'constants/api.constants'
import route from 'constants/routes'

import style from './MyVids.style'

const TM = 100

export const MyVids = withErrorBoundary(() => {
    usePageTitle('My Vids | Vidalytics')

    const { fullList, videos, videosWithFolders, isLoading, isError } = useVideosWithFolders()
    const { data: customer } = useCustomerQuery()
    const [isOpenNewFolderModal, setIsOpenNewFolderModal] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchValue, setSearchValue] = useState(searchParams.get('s') || '')
    const { path } = useFolderChildrenWithPath(videosWithFolders)
    const folderNameChangeMutation = useFolderNameChangeMutation()
    const { pathname } = useLocation()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { guid = ROOT } = useParams()
    const { isMobile } = useLayout()

    const handleAddFolder = () => setIsOpenNewFolderModal(true)

    useEffect(() => {
        queryClient.invalidateQueries(VIDEOS_QUERY)
        queryClient.invalidateQueries(VIDEO_FOLDERS_QUERY)
        setSearchValue('')
    }, [pathname])

    useEffect(() => {
        setSearchValue(searchParams.get('s') || '')
    }, [searchParams])

    useEffect(() => {
        const list = videos?.data || []
        if (!list.length) return

        if (list.some((video) => video.plays > PLAYS_LOG_THRESHOLD)) {
            trackAppCuesEventOnce(APPCUES_EVENTS.FIRST_PLAYS_RECEIVED, customer?.guid || '')
        }
    }, [videos, customer])

    const handleUploadClick = () => navigate(route.upload)
    const handleCloseNewFolderModal = () => setIsOpenNewFolderModal(false)

    const setSearchParamsDebounced = useCallback(
        _debounce((search) => {
            if (search === '') {
                const newParams = new URLSearchParams(searchParams)
                newParams.delete('s')
                setSearchParams(newParams)
                return
            }

            if (path.length > 0) {
                navigate(`${route.video.catalog}?s=${search}`)
                return
            }

            setSearchParams({ s: search })
        }, TM),
        [setSearchParams, path],
    )

    const handleSearchChange = (search: string) => {
        setSearchValue(search)
        setSearchParamsDebounced(search)
    }

    const fullListFiltered = fullList.filter((data) =>
        isExpandedVideo(data) ? data.folderGuid === guid : data.guid === guid || data.parentFolderGuid === guid,
    )

    const data: ExpandedVideosWithFolders | undefined =
        guid !== ROOT ? get(fullListFiltered[0], 'children') : fullListFiltered

    const filteredData = useMemo(() => filterByTitle(data, searchValue), [data, searchValue])

    const changeFolderName =
        (folder: Folder, title?: string): InputFocusHandler =>
        (event) => {
            const newTitle = event.target.value

            if (newTitle === title) return

            folderNameChangeMutation.mutate({
                folder: {
                    ...folder,
                    title: newTitle,
                },
            })
        }

    const folders = [{ title: 'My Vids', guid: ROOT }, ...path]

    const editableFolderIndex = folders.length > 1 ? folders.length - 1 : -1

    return (
        <Page title="My Vids">
            <Stack flexDirection="row" sx={style.header} gap={4} data-testId="conversionsPageHeader" flexWrap="wrap">
                <Stack
                    alignItems="center"
                    direction="row"
                    gap={1}
                    flexWrap="wrap"
                    sx={style.header}
                    data-testid="Breadcrumbs"
                >
                    {folders.map((folder, i) => {
                        const { guid, title } = folder

                        return (
                            <Stack
                                flexDirection="row"
                                gap={1}
                                alignItems="center"
                                key={guid}
                                data-testid="FolderBreadcrumb"
                            >
                                {editableFolderIndex === i ? (
                                    <EditableName
                                        onBlur={changeFolderName(folder as Folder, title)}
                                        initialName={title}
                                        style={style.folderName}
                                        inputStyle={{ backgroundColor: '' }}
                                        className="EditableNameHeader"
                                    />
                                ) : (
                                    <Link
                                        sx={style.folderNameHeader.link}
                                        to={guid === ROOT ? route.video.catalog : route.video.byId({ guid: guid! })}
                                    >
                                        <Typography variant="h6" component="h1">
                                            {title}
                                        </Typography>
                                    </Link>
                                )}
                                {i !== folders.length - 1 ? <span className="divider">{'>'}</span> : ''}
                            </Stack>
                        )
                    })}
                </Stack>

                {!isError && (
                    <>
                        <SearchField
                            sx={style.headerSearch}
                            searchByIds={[HEADLINE]}
                            searchByLabel={t('Search by Name or Embed Code ID')}
                            onChange={handleSearchChange}
                            value={searchValue}
                        />

                        <Stack direction="row" alignItems="flex-start" gap={4} sx={style.buttonsStack}>
                            <Box sx={style.helperButton}>
                                <Button
                                    startIcon={<CreateNewFolderRoundedIcon fontSize="small" />}
                                    variant="outlined"
                                    sx={style.addNewFolder}
                                    color="tertiary"
                                    size="medium"
                                    fullWidth
                                    onClick={handleAddFolder}
                                    data-testid="addButton"
                                >
                                    {!isMobile && <span>{t('New Folder')}</span>}
                                </Button>
                            </Box>
                            <Box sx={style.helperButton}>
                                <Button
                                    startIcon={<CloudUploadIcon fontSize="small" />}
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    fullWidth
                                    onClick={handleUploadClick}
                                >
                                    {!isMobile && <span>{t('Upload')}</span>}
                                </Button>
                            </Box>
                        </Stack>
                    </>
                )}
            </Stack>
            <VidsTable isLoading={isLoading} data={filteredData} />
            <CreateNewFolderModal
                open={isOpenNewFolderModal}
                onClose={handleCloseNewFolderModal}
                guid={guid}
                data={fullList}
            />
        </Page>
    )
})

export default MyVids
