import { RefObject, useCallback, useState } from 'react'
import { NoRowsOverlayPropsOverrides } from '@mui/x-data-grid/models/gridSlotsComponentsProps'
import {
    DataGridPro,
    DataGridProProps,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridRowSelectionModel,
    GridSortDirection,
    useGridApiRef,
} from '@mui/x-data-grid-pro'
import { Stack, StackProps, useMediaQuery, useTheme } from '@mui/material'
import { omit as _omit } from 'lodash'

import { getSx } from 'styles/theme/utils'

import { BaseIconButton, SelectedAction, SelectedActions } from './components'
import { COLUMN_HEADER_HEIGHT } from './constants'
import { NoResultsOverlay } from './components/NoResultsOverlay/NoResultsOverlay'

import style from './DataGridTable.style'
import locale from './DataGridTable.locale'

export type Props = Pick<
    DataGridProProps,
    'columns' | 'rows' | 'loading' | 'onRowSelectionModelChange' | 'rowSelectionModel'
> & {
    selectedActions?: SelectedAction[]
    pinnedColumnsOnMobile?: string[]
    noRowsConfig?: NoRowsOverlayPropsOverrides['noRows']
    propsContainer?: StackProps
    propsTable?: Partial<Omit<DataGridProProps, 'onRowSelectionModelChange'>>
    parentRef?: RefObject<HTMLDivElement>
}

export const GRID_SORT_DIRECTION = {
    ASC: 'asc' as GridSortDirection,
    DESC: 'desc' as GridSortDirection,
}

export const DataGridTable = ({
    columns,
    rows = [],
    selectedActions,
    loading,
    pinnedColumnsOnMobile,
    noRowsConfig,
    propsContainer,
    propsTable,
    onRowSelectionModelChange,
    parentRef,
    rowSelectionModel: userRowSelectionModel = [],
}: Props) => {
    const apiRef = useGridApiRef()
    const { breakpoints } = useTheme()

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>(
        userRowSelectionModel as GridRowSelectionModel,
    )

    const disableHeader = rows.length === 0 && !loading

    const isMobile = useMediaQuery(breakpoints.down('tablet'))

    const _onRowSelectionModelChange = useCallback<Required<DataGridProProps>['onRowSelectionModelChange']>(
        (newSelectionModel, details) => {
            setRowSelectionModel(newSelectionModel)
            onRowSelectionModelChange?.(newSelectionModel, details)
        },
        [onRowSelectionModelChange],
    )

    const onPaginationModelChange = useCallback<Required<DataGridProProps>['onPaginationModelChange']>(
        (...args) => {
            propsTable?.onPaginationModelChange?.(...args)

            const rect = parentRef?.current?.getBoundingClientRect()
            // Check if the element is within the viewport
            const inView =
                rect &&
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)

            if (!inView) {
                setTimeout(() => {
                    parentRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                    })
                })
            }
        },
        [parentRef, propsTable?.onPaginationModelChange],
    )

    return (
        <Stack ref={parentRef} sx={getSx(style.container, propsContainer?.sx || {})} {..._omit(propsContainer, 'sx')}>
            <DataGridPro
                data-testId="dataGridTable"
                apiRef={apiRef}
                loading={loading}
                sx={getSx(style.dataGrid(disableHeader), propsTable?.sx || {})}
                columns={columns}
                rows={rows}
                pinnedColumns={{
                    right: ['actions'],
                    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...((isMobile && pinnedColumnsOnMobile) || [])],
                }}
                slots={{
                    baseIconButton: BaseIconButton,
                    noRowsOverlay: NoResultsOverlay,
                }}
                slotProps={{
                    noRowsOverlay: {
                        noRows: noRowsConfig,
                    },
                    loadingOverlay: {
                        variant: 'skeleton',
                        noRowsVariant: 'skeleton',
                    },
                }}
                localeText={{
                    actionsCellMore: locale.actionsCellMore,
                }}
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={_onRowSelectionModelChange}
                getRowId={(row) => row.id || row.guid}
                columnHeaderHeight={COLUMN_HEADER_HEIGHT}
                disableRowSelectionOnClick
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                {...propsTable}
                onPaginationModelChange={onPaginationModelChange}
            />

            {Boolean(selectedActions) && (
                <SelectedActions
                    actions={selectedActions}
                    rowSelectionModel={rowSelectionModel}
                    onClearRowSelectionModel={() => setRowSelectionModel([])}
                />
            )}
        </Stack>
    )
}

export default DataGridTable
