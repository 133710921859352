import { SxProps } from '@mui/material'

export default {
    actions: {
        gap: 2,
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row',
        pt: 6,
    },
} satisfies Record<string, SxProps>
