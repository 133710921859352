import { UseMutationOptions, useQueryClient } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { PAYMENT_PROFILE_DELETE_MUTATION, PAYMENT_PROFILE_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { PaymentProfileResponse } from 'api/queries'

export const useDeletePaymentProfileMutation = (options?: UseMutationOptions<null, unknown, number>) => {
    const queryClient = useQueryClient()

    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, number>(
        PAYMENT_PROFILE_DELETE_MUTATION,
        async (id: number) => mutate(userAPI.deletePaymentProfile(id), apiLocale.user.deletePaymentProfile),
        {
            ...options,
            onMutate: async (id) => {
                const key = PAYMENT_PROFILE_QUERY
                await queryClient.cancelQueries(key)

                const previousData = queryClient.getQueryData<PaymentProfileResponse>(key)

                if (previousData) {
                    queryClient.setQueryData(key, {
                        ...previousData,
                        cards: previousData.cards.filter((c) => c.id !== id),
                    })
                }

                return previousData
            },
            onError: async (_, __, previousValue) => queryClient.setQueryData(PAYMENT_PROFILE_QUERY, previousValue),
            invalidate: [PAYMENT_PROFILE_QUERY],
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
