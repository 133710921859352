import Box from '@mui/material/Box'

import ClosedCaptionsZone from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsZone'
import useClosedCaptionsContent from 'design/organisms/ClosedCaptionsEditor/useClosedCaptionsContent'
import useSvIds from 'design/pages/SmartVid/hooks/useSvIds'

const ClosedCaptionsEditor = () => {
    const { videoId } = useSvIds()
    const { isLoading, content } = useClosedCaptionsContent(String(videoId))

    const hasContent = content !== null

    if (hasContent || isLoading) {
        return null
    }

    return (
        <Box px={5} pt={2.5} pb={10}>
            <ClosedCaptionsZone />
        </Box>
    )
}

export default ClosedCaptionsEditor
