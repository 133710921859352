import { Tooltip, TooltipProps } from '@mui/material'
import { ReactElement } from 'react'

import { locale } from 'locales'
import { useAgencyAccess } from 'design/pages/AgencyAccount/AgencyAccount.hooks'

export interface UpgradeBadgeTooltipProps extends Omit<TooltipProps, 'title' | 'children'> {
    children: ReactElement
    title?: TooltipProps['title']
}

const UpgradeBadgeTooltip = ({ children, ...rest }: UpgradeBadgeTooltipProps) => {
    const { agencyAccessLocked } = useAgencyAccess()

    return (
        <Tooltip
            slotProps={{
                tooltip: {
                    sx: { maxWidth: 128, textAlign: 'center' },
                },
            }}
            title={agencyAccessLocked ? locale.subscription.locked : locale.subscription.upgradeToAccess}
            arrow
            {...rest}
        >
            {children}
        </Tooltip>
    )
}

export default UpgradeBadgeTooltip
