import { createSvgIcon } from '@mui/material'

const SmartAutoPlay = createSvgIcon(
    <>
        <path d="M19.4565 5.65054C19.34 5.9178 18.9672 5.9178 18.8507 5.65054L18.4314 4.67868L17.4995 4.24134C17.2432 4.11986 17.2432 3.73112 17.4995 3.60964L18.4314 3.1723L18.8507 2.20045C18.9672 1.93318 19.34 1.93318 19.4565 2.20045L19.8759 3.1723L20.8078 3.60964C21.0641 3.73112 21.0641 4.11986 20.8078 4.24134L19.8759 4.67868L19.4565 5.65054Z" />
        <path d="M16.7656 12.5538C16.5559 13.0275 15.9152 13.0275 15.7055 12.5538L14.9716 10.853L13.3407 10.0877C12.8864 9.869 12.8864 9.20085 13.3407 8.98218L14.9716 8.21684L15.7055 6.5161C15.9152 6.04232 16.5559 6.04232 16.7656 6.5161L17.4995 8.21684L19.1303 8.98218C19.5846 9.20085 19.5846 9.869 19.1303 10.0877L17.4995 10.853L16.7656 12.5538Z" />
        <path d="M2 19.947V9.05302C2 8.2223 2.94332 7.71756 3.66979 8.16972L12.4958 13.6062C13.1681 14.0268 13.1681 14.9732 12.4958 15.3833L3.66979 20.8303C2.94332 21.2824 2 20.7777 2 19.947Z" />
    </>,
    'SmartAutoPlay',
)

export default SmartAutoPlay
