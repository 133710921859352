import { Box, Typography, Stack, Slider } from '@mui/material'
import { useMemo } from 'react'

import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import {
    UI_CONTROL_BAR_SEEK_BAR_SHOW,
    UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS,
    UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW,
    UI_CONTROL_BAR_SMART_SEEK_BAR_DESKTOP_SPEED,
    UI_CONTROL_BAR_SMART_SEEK_BAR_MOBILE_SPEED,
} from 'design/pages/VidSettings/constants'
import { SubscriptionCtaSource } from 'design/templates/Modal'
import { ControlsSectionFormData } from 'types/VidSettings'
import { FeatureGroup, VidSettingsKey } from 'constants/keys/featuresAccess.constants'
import locale from './SeekingBar.locale'

export const seekingBarStyleKeys = [
    UI_CONTROL_BAR_SEEK_BAR_SHOW,
    UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS,
    UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW,
] as const

interface Props {
    isSmartVidGlobalSettings?: boolean
}

export const SeekingBar = ({ isSmartVidGlobalSettings }: Props) => {
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )
    const {
        formik: { values, setValues, setFieldValue },
        isLoading,
        onChange,
    } = useSectionContext<ControlsSectionFormData>()

    const commonSwitchProps = {
        afterChange: onChange,
        redesign: true,
        switchProps: { size: 'small' },
    } as const

    const commonSliderProps = {
        valueLabelDisplay: 'auto',
        valueLabelFormat: (value: number) => `${value}x`,
        disabled: isLoading,
        sx: style.slider,
        shiftStep: 0.25,
        step: 0.25,
        marks: true,
        min: 0.5,
        max: 2,
    } as const

    return (
        <Section title={locale.title} indent>
            <SwitchForm
                sx={style.container}
                name={UI_CONTROL_BAR_SEEK_BAR_SHOW}
                label={<Typography variant="body2">{locale.controls.standard.label}</Typography>}
                labelPlacement="start"
                tooltipPlacement={SwitchTooltipPlacement.inside}
                helpInfo={locale.controls.standard.tooltip}
                disabled={isLoading}
                onChange={(name, value) => {
                    setValues({
                        ...values,
                        [name]: value,
                        ...(!value && {
                            [UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW]: false,
                            [UI_CONTROL_BAR_SMART_SEEK_BAR_DESKTOP_SPEED]: 1,
                            [UI_CONTROL_BAR_SMART_SEEK_BAR_MOBILE_SPEED]: 1,
                            [UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS]: false,
                        }),
                    })
                    onChange()
                }}
                {...commonSwitchProps}
            />

            <Stack sx={style.stack}>
                <SwitchForm
                    sx={style.container}
                    name={UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW}
                    label={<Typography variant="body2">{locale.controls.engageBar.label}</Typography>}
                    labelPlacement="start"
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    helpInfo={locale.controls.engageBar.tooltip}
                    disabled={isLoading || !values[UI_CONTROL_BAR_SEEK_BAR_SHOW]}
                    featureGroup={FeatureGroup.VidSettings}
                    featureKey={VidSettingsKey.RapidEngageBar}
                    upgradeSource={SubscriptionCtaSource.VID_SETTINGS}
                    {...commonSwitchProps}
                />
                {values[UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW] && (
                    <Stack sx={style.stack}>
                        <Box sx={style.sliderContainer}>
                            <Typography variant="body2">{locale.controls.desktopSpeed.label}</Typography>
                            <Slider
                                name={UI_CONTROL_BAR_SMART_SEEK_BAR_DESKTOP_SPEED}
                                value={values[UI_CONTROL_BAR_SMART_SEEK_BAR_DESKTOP_SPEED]}
                                onChange={(_event, value) => {
                                    setFieldValue(UI_CONTROL_BAR_SMART_SEEK_BAR_DESKTOP_SPEED, value)
                                    onChange()
                                }}
                                {...commonSliderProps}
                            />
                        </Box>
                        <Box sx={style.sliderContainer}>
                            <Typography variant="body2">{locale.controls.mobileSpeed.label}</Typography>
                            <Slider
                                name={UI_CONTROL_BAR_SMART_SEEK_BAR_MOBILE_SPEED}
                                value={values[UI_CONTROL_BAR_SMART_SEEK_BAR_MOBILE_SPEED]}
                                onChange={(_event, value) => {
                                    setFieldValue(UI_CONTROL_BAR_SMART_SEEK_BAR_MOBILE_SPEED, value)
                                    onChange()
                                }}
                                {...commonSliderProps}
                            />
                        </Box>
                    </Stack>
                )}
                <SwitchForm
                    sx={style.container}
                    name={UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS}
                    label={<Typography variant="body2">{locale.controls.revisit.label}</Typography>}
                    labelPlacement="start"
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    helpInfo={locale.controls.revisit.tooltip}
                    disabled={isLoading || !values[UI_CONTROL_BAR_SEEK_BAR_SHOW]}
                    featureGroup={FeatureGroup.VidSettings}
                    featureKey={VidSettingsKey.RapidEngageBar}
                    upgradeSource={SubscriptionCtaSource.VID_SETTINGS}
                    {...commonSwitchProps}
                />
            </Stack>
        </Section>
    )
}
