import mixpanel from 'mixpanel-browser'
import { useQueryClient } from 'react-query'

import { VIDEOS_QUERY, VIDEO_EMBED_QUERY } from 'api/constants'
import { videoAPI } from 'api/controllers'
import { InvalidateOn, Options, useInvalidateMutation } from 'api/hooks'
import { APPCUES_EVENTS, trackAppCuesEvent } from 'thirdPartyServices/appCues'
import { MIXPANEL_EVENTS, getVideoProperties } from 'thirdPartyServices/mixpanel'
import { VideoDetails } from 'types/Video'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'

export const useUpdateVideoMutation = (id: string, options?: Options<VideoDetails, VideoDetails, unknown>) => {
    const queryClient = useQueryClient()
    const { mutate } = useMutationToastDecorator({
        showPendingToast: false,
        showSuccessToast: false,
        showErrorToast: true,
        showForbiddenToast: false,
    })

    const replaceNewlinesWithBr = (inputString: string) => {
        return inputString?.replace(/\n/g, '<br>')
    }

    const updateDetails = (videoDetails: VideoDetails) => {
        return {
            ...videoDetails,
            settings: {
                ...videoDetails.settings,
                ui_overlay_redirect_text: replaceNewlinesWithBr(videoDetails?.settings?.ui_overlay_redirect_text),
                ui_overlay_resume_button_replay_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_resume_button_replay_text,
                ),
                ui_overlay_resume_button_resume_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_resume_button_resume_text,
                ),
                ui_overlay_resume_text: replaceNewlinesWithBr(videoDetails?.settings?.ui_overlay_resume_text),
                ui_overlay_unmute_mobile_textBottom_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_unmute_mobile_textBottom_text,
                ),
                ui_overlay_unmute_mobile_textTop_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_unmute_mobile_textTop_text,
                ),
                ui_overlay_unmute_textBottom_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_unmute_textBottom_text,
                ),
                ui_overlay_unmute_textTop_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_unmute_textTop_text,
                ),
                ui_overlay_expire_textTop_text: replaceNewlinesWithBr(
                    String(videoDetails?.settings?.ui_overlay_expire_textTop_text),
                ),
                ui_overlay_expire_textBottom_text: replaceNewlinesWithBr(
                    String(videoDetails?.settings?.ui_overlay_expire_textBottom_text),
                ),
            },
        }
    }

    return useInvalidateMutation<VideoDetails, VideoDetails>(
        [VIDEOS_QUERY, id],
        async (details: VideoDetails) => mutate(videoAPI.updateVideo(id, updateDetails(details))),
        {
            ...options,
            onSuccess: (details, ...rest) => {
                const updatedDetails = updateDetails(details)
                trackAppCuesEvent(APPCUES_EVENTS.VIDSETTINGS_CHANGED)
                mixpanel.track(MIXPANEL_EVENTS.VID_SETTINGS_CHANGED, getVideoProperties(updatedDetails.video))

                queryClient.setQueryData([VIDEOS_QUERY, id], updatedDetails)
                queryClient.invalidateQueries([VIDEO_EMBED_QUERY, id])

                options?.onSuccess?.(details, ...rest)
            },
            invalidateOn: options?.invalidateOn || InvalidateOn.NEVER,
        },
    )
}
