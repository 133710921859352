import { useMemo, useCallback, useState, useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

import { CustomerStatus } from 'types/Customer'
import { useCustomerQuery, useCustomerSubscriptionQuery } from 'api/queries'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'

// allows to make import like
// import useMixpanel, { MIXPANEL_EVENTS } from 'hooks/analytics/useMixpanel'
export { MIXPANEL_EVENTS }

type QueuedEvent = { eventName: string; data: Record<string, { [key: string]: unknown }> }
type QueuedLinkEvent = QueuedEvent & { cssSelector: string }

const useMixpanel = () => {
    const { isLoading: isCustomerLoading, isFetching: isCustomerFetching, data: customer } = useCustomerQuery()
    const {
        isLoading: isSubscriptionLoading,
        isFetching: isSubscriptionFetching,
        data: subscription,
    } = useCustomerSubscriptionQuery()

    const [queuedTrackEvents, setQueuedTrackEvents] = useState<QueuedEvent[]>([])
    const [queuedLinkEvents, setQueuedLinkEvents] = useState<QueuedLinkEvent[]>([])

    const customerData = useMemo(() => {
        const isLoading = isCustomerLoading || isCustomerFetching || isSubscriptionLoading || isSubscriptionFetching

        if (isLoading || !customer || !subscription) return null

        const { plan } = subscription
        return {
            user_id: customer.guid,
            subscription_tier: plan.tier,
            subscription_id: plan.apiHandle,
            subscription_status: subscription.status,
            subscription_istrial: subscription.status === CustomerStatus.trial,
            subscription_plan: plan.name,
        }
    }, [isCustomerLoading, isCustomerFetching, customer, isSubscriptionLoading, isSubscriptionFetching, subscription])

    // Process queued track events
    useEffect(() => {
        if (customerData && queuedTrackEvents.length > 0) {
            queuedTrackEvents.forEach(({ eventName, data }) => {
                const enrichedData = {
                    ...data,
                    ...customerData,
                    url: window.location.href,
                }
                mixpanel.track(eventName, enrichedData)
            })
            setQueuedTrackEvents([])
        }
    }, [customerData, queuedTrackEvents])

    // Process queued link events
    useEffect(() => {
        if (customerData && queuedLinkEvents.length > 0) {
            queuedLinkEvents.forEach(({ cssSelector, eventName, data }) => {
                const enrichedData = {
                    ...data,
                    ...customerData,
                    url: window.location.href,
                }
                mixpanel.track_links(cssSelector, eventName, enrichedData)
            })
            setQueuedLinkEvents([])
        }
    }, [customerData, queuedLinkEvents])

    const track = useCallback(
        (eventName: string, initialData = {}) => {
            if (!customerData) {
                setQueuedTrackEvents((prev) => [...prev, { eventName, data: initialData }])
                return
            }

            const enrichedData = {
                ...initialData,
                ...customerData,
                url: window.location.href,
            }

            mixpanel.track(eventName, enrichedData)
        },
        [customerData],
    )

    const trackLinks = useCallback(
        (cssSelector: string, eventName: string, initialData = {}) => {
            if (!customerData) {
                setQueuedLinkEvents((prev) => [...prev, { cssSelector, eventName, data: initialData }])
                return
            }

            const enrichedData = {
                ...initialData,
                ...customerData,
                url: window.location.href,
            }

            mixpanel.track_links(cssSelector, eventName, enrichedData)
        },
        [customerData],
    )

    return useMemo(() => ({ track, trackLinks }), [track, trackLinks])
}

export default useMixpanel
