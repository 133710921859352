import { Box, Divider, ListItem } from '@mui/material'
import { SyntheticEvent } from 'react'
import { useLocation } from 'react-router-dom'

import { Link } from 'design/atoms/Link'
import { EntityRow } from 'design/molecules/EntityRow'
import { ExpandedVideo } from 'design/pages/MyVids'
import { ClickHandler } from 'types'
import { useGetDetailedStatsUrl } from 'design/pages/VidStats/hooks/useGetDetailedStatsUrl'
import { route } from 'constants/routes'
import style from './VideoItem.style'

export interface VideoItemProps {
    video: ExpandedVideo
    level: number
    selectedEnitityGuid?: string
    onClick?: (e: Event | SyntheticEvent<Element, Event>, video: ExpandedVideo) => void
    compareMode?: boolean
    disableLink?: boolean
}

export const VideoItem = ({ video, level, selectedEnitityGuid, onClick, compareMode, disableLink }: VideoItemProps) => {
    const { getDetailedStatsUrl } = useGetDetailedStatsUrl()

    const handleClick: ClickHandler = (e) => {
        onClick?.(e, video)
    }

    const location = useLocation()

    if (disableLink) {
        return (
            <ListItem sx={style.root}>
                <Box onClick={handleClick}>
                    <EntityRow
                        rootSx={style.entity(level)}
                        selected={selectedEnitityGuid === video.guid}
                        videoImg={video.thumbnail?.dashboardSrc || '/img/video/no-image.png'}
                        title={video.title}
                    />
                </Box>{' '}
            </ListItem>
        )
    }

    return (
        <>
            <ListItem sx={style.root}>
                <Link
                    sx={style.link}
                    to={
                        location?.pathname?.includes('stats') && !compareMode
                            ? getDetailedStatsUrl({ videoGuid: video.guid })
                            : route.video.settings.byId({ videoGuid: video.guid })
                    }
                    onClick={handleClick}
                >
                    <EntityRow
                        rootSx={style.entity(level)}
                        selected={selectedEnitityGuid === video.guid}
                        videoImg={video.thumbnail?.dashboardSrc || '/img/video/no-image.png'}
                        title={video.title}
                    />
                </Link>
            </ListItem>
            <Divider sx={style.divider} />
        </>
    )
}
