import { VideoStartupQuality } from 'types/Player'
import { Typography } from '@mui/material'
import locale from './VideoStartupQualityContent.locale'

export const useVideoStartupQualityOptions = () => {
    const LabelWrapper = ({ label, extraInfo }: { label: string; extraInfo?: string }) => (
        <Typography variant="body2">
            {label}
            {extraInfo && (
                <Typography variant="body2" color="text.disabled" component="span">
                    {extraInfo}
                </Typography>
            )}
        </Typography>
    )

    const qualityOptions = Object.values(VideoStartupQuality)

    return qualityOptions.map((quality) => {
        const key = quality.toLowerCase() as keyof typeof locale.qualityOptions

        return {
            value: quality,
            ...locale.qualityOptions[key],
            label: (
                <LabelWrapper
                    label={locale.qualityOptions[key].label}
                    extraInfo={locale.qualityOptions[key].extraInfo}
                />
            ),
        }
    })
}
