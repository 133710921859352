import { alpha, SxProps, Theme } from '@mui/material'

export default {
    root: (theme) => ({
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        border: `1px solid ${alpha(theme.palette.primary.main, theme.palette.action.outlinedBorderOpacity)}`,
        p: 6,
        py: 5,
        gap: 6,
        borderRadius: 4,
        flex: 2,
    }),
} satisfies Record<string, SxProps<Theme>>
