import { Shadows, createTheme } from '@mui/material'

const theme = createTheme()

const shadows: Shadows = [...theme.shadows]
const shadowsToReplace = {
    1: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
    2: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    4: '0px 2px 5px 0px rgba(16, 24, 40, 0.22)',
    6: '0px 6px 14px 0px rgba(0, 0, 0, 0.16)',
    10: '0px 4px 5px 0px rgba(0, 0, 0, 0.10), 0px 10px 15px 0px rgba(0, 0, 0, 0.20)',
    24: '0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.20)',
}

Object.entries(shadowsToReplace).forEach(([key, value]) => {
    shadows[Number(key)] = value
})

export { shadows }
