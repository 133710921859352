export default {
    heading: {
        title: 'Display videos in full browser window mode to bypass browser restrictions and have more control over viewing experience.',
        tooltip:
            'In rare cases, this feature may require CSS adjustments on your page to function properly. Be sure to thoroughly test your site after enabling this option!',
        tooltipSmartVid: 'Note – on Smart Vids fullscreen works only in the focused mode.',
    },
    controls: {
        enableForDesktop: 'Enable for Desktop',
        enableForMobile: 'Enable for Mobile',
        expandOnPlay: 'Expand On Play',
        minimizeOnPause: 'Minimize On Pause',
        tooltips: {
            expandOnPlay: 'Automatically expands to full screen when play is pressed.',
            minimizeOnPause: 'Minimizes the player upon pausing the video.',
        },
    },
}
