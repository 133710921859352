import { VideoCallToActionDisplayMode } from 'types/VideoCallToAction'

export default {
    displayModeLabels: {
        [VideoCallToActionDisplayMode.customHTML]: 'Custom HTML',
        [VideoCallToActionDisplayMode.expandContainer]: 'Reserve Space',
        [VideoCallToActionDisplayMode.onTop]: 'On Top',
        [VideoCallToActionDisplayMode.reserveSpace]: 'Reserve Space',
    },
}
