import { Box, BoxProps, Stack, StackProps, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { HelpTooltip } from 'design/atoms/HelpTooltip'

interface VidSettingsSidebarSectionProps extends BoxProps {
    title?: string
    helpInfo?: ReactNode
    indent?: boolean
    stackProps?: StackProps
}

export const VidSettingsSidebarSection = (props: VidSettingsSidebarSectionProps) => {
    const { title, children, indent = false, stackProps, helpInfo } = props

    return (
        <Box mx={6} {...props}>
            {title && (
                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} fontWeight="600" mb={4}>
                    {title}
                    {helpInfo && <HelpTooltip title={helpInfo} />}
                </Typography>
            )}
            <Stack gap={1} ml={indent ? 3 : 0} {...stackProps}>
                {children}
            </Stack>
        </Box>
    )
}
