import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'
import { useAppSelector } from 'App'
import { AGENCY_GET_CLIENTS, AGENCY_GET_INFO, AGENCY_GET_INVITE_INFO, AGENCY_GET_TARIFF_INFO } from 'api/constants'
import { queryClient } from 'api/queries'
import { QueryKey } from 'react-query'
import { actAsClient, selectAccount, stopActingAsClient } from 'design/organisms/Account/account.slice'
import { route } from 'constants/routes'
import { AGENCY_CLIENT_ID } from 'constants/local-storage.constants'

const persistentQueries: QueryKey[] = [
    AGENCY_GET_INFO,
    AGENCY_GET_INVITE_INFO,
    AGENCY_GET_CLIENTS,
    AGENCY_GET_TARIFF_INFO,
]

const invalidateQueries = () =>
    queryClient.removeQueries({ predicate: (query) => !persistentQueries.includes(query.queryKey) })

export const useClientAccount = () => {
    const navigate = useNavigate()
    const { managedClientId } = useAppSelector(selectAccount)
    const dispatch = useDispatch()

    const jumpIn = useCallback((clientId: AgencyClientId) => {
        localStorage.setItem(AGENCY_CLIENT_ID, clientId.toString())
        dispatch(actAsClient({ clientId }))
        invalidateQueries()
        navigate(route.dashboard)
    }, [])

    const jumpOut = useCallback(() => {
        localStorage.removeItem(AGENCY_CLIENT_ID)
        dispatch(stopActingAsClient())
        invalidateQueries()
    }, [])

    const isClientAccountEnabled = managedClientId || localStorage.getItem(AGENCY_CLIENT_ID)

    return { isClientAccountEnabled, jumpIn, jumpOut }
}

export const useAgencyAccess = () => {
    const { isAgency, managedClientId } = useAppSelector(selectAccount)

    return { agencyAccessLocked: isAgency && !managedClientId }
}
