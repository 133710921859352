import { SxProps } from '@mui/material'
import { alpha, Theme } from '@mui/material/styles'

import { fontSx } from 'styles/theme/typography'

const idle: SxProps<Theme> = {
    color: (theme) => theme.palette.text.primary,
}

const icon: SxProps<Theme> = {
    color: (theme) => theme.palette.text.secondary,
}

const highlighted: SxProps<Theme> = {
    color: (theme) => theme.palette.primary.main,
}

const label: SxProps = fontSx.inter.semibold

const accordion: SxProps<Theme> = (theme) => ({
    '&.MuiPaper-root': {
        borderBottom: `1px solid ${theme.palette.background.cardsHover}`,
    },
})

const accordionSummary: SxProps<Theme> = {
    gap: 5,

    '.MuiAccordionSummary-content': {
        m: 0,
        gap: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}

const newBtn: SxProps<Theme> = {
    color: 'success.main',
    pointerEvents: 'none',

    '&, &:hover': {
        background: (theme) => alpha(theme.palette.success.light, theme.palette.action.selectedOpacity),
        border: 'none',
    },
}

export default {
    label,
    idle,
    icon,
    highlighted,
    accordionSummary,
    newBtn,
    accordion,
}
