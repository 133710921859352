import { Form, Formik, FormikProps } from 'formik'

import { useVideoQuery } from 'api/queries'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import ClosedCaptionsEditor from 'design/organisms/ClosedCaptionsEditor'
import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import ClosedCaptionsAdvancedContent from 'design/organisms/VidSettingsSidebar/StyleSettings/ClosedCaptionsSection/ClosedCaptionsAdvancedContent'
import useSvIds from 'design/pages/SmartVid/hooks/useSvIds'
import { CLOSED_CAPTIONS_SECTION_INITIAL_VALUES } from 'design/pages/VidSettings/constants'
import { VideoDetails } from 'types/Video'
import { ClosedCaptionsSectionFormData } from 'types/VidSettings'

interface ClosedCaptionsSectionProps {
    isSmartVidSettings?: boolean
}

const ClosedCaptionsSection = ({
    resetForm,
}: FormikProps<ClosedCaptionsSectionFormData> & ClosedCaptionsSectionProps) => {
    const { videoId } = useSvIds()

    const initForm = (details: VideoDetails) => {
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoId, {
        onSuccess: initForm,
    })

    return (
        <SettingsSectionProvider>
            <ClosedCaptionsEditor />
            <Form>
                <ClosedCaptionsAdvancedContent />
            </Form>
        </SettingsSectionProvider>
    )
}

const FormikWrapper = (props: ClosedCaptionsSectionProps) => {
    const { videoId } = useSvIds()
    const { data: video } = useVideoQuery(videoId)

    return (
        <Formik<ClosedCaptionsSectionFormData>
            initialValues={video?.settings || CLOSED_CAPTIONS_SECTION_INITIAL_VALUES}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={() => {}}
        >
            {(formikProps) => <ClosedCaptionsSection {...props} {...formikProps} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
