import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
    openSubscriptionModal,
    PlanOptions,
    setPlan,
    SubscriptionSteps,
} from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import { Plan } from 'types/Customer'

export const useHandleOpenSubscriptionModal = () => {
    const dispatch = useDispatch()

    const handleOpenSubscriptionModal = useCallback(
        (plan: Plan, options?: PlanOptions, step = SubscriptionSteps.ACTIVATION_STEP) => {
            dispatch(setPlan({ plan, options }))
            dispatch(openSubscriptionModal({ step }))
        },
        [],
    )

    return { handleOpenSubscriptionModal }
}
