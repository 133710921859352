import { useTagManagerInit } from 'thirdPartyServices/gtm'
import { useMixpanelInit } from 'thirdPartyServices/mixpanel'
import syncSiteSpect from 'thirdPartyServices/syncSiteSpect'
import { useAppCuesIdentification } from 'thirdPartyServices/appCues'
import useSetSentryUser from 'thirdPartyServices/sentry'
import useCedexisInit from 'thirdPartyServices/cedexis'
import { useVdlyInit } from 'thirdPartyServices/vdly'

export default function init3rdPartyServices() {
    useTagManagerInit()
    useMixpanelInit()
    syncSiteSpect()
    useAppCuesIdentification()
    useSetSentryUser()
    useVdlyInit()
    useCedexisInit()
}
