import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { FOLDER } from 'design/atoms/Table/constants'
import { BREADCRUMB } from 'design/pages/MyVids'
import { getFolderBreadcrumbs, getVideoBreadcrumbs } from 'design/pages/MyVids/MyVids.utils'
import useQueriesLoading from 'hooks/system/useQueriesLoading'
import useQueriesIsError from 'hooks/system/useQueriesIsError'
import { Folder, Video } from 'types/Video'
import { useVideoFoldersQuery, useVideosQuery, VideosQueryType } from 'api/queries'
import { KeyValuePair } from 'types'
import { useAppSelector } from 'App'
import { selectVideoCompareState } from 'design/pages/VidStats/components/PlayerBlock/CompareMode/videoCompare.slice'
import { ROOT } from 'constants/api.constants'

type Extension = {
    children: ExpandedVideosWithFolders
    type: string
    search: string
    breadcrumb: string
}
export type ExpandedVideo = Video & Extension
export type ExpandedFolder = Folder & Extension
export type ExpandedVideoOrFolder = ExpandedVideo | ExpandedFolder
export type ExpandedVideosWithFolders = Array<ExpandedVideoOrFolder>

export function listToTree(list: Array<Video | Folder>, folders: Folder[], currentFolderGuid?: string) {
    const listCopy = JSON.parse(JSON.stringify(list)) as ExpandedVideosWithFolders
    const map: KeyValuePair<number> = {}
    let node
    const roots: ExpandedVideosWithFolders = []

    listCopy.forEach((item, i) => {
        map[listCopy[i].guid] = i
        listCopy[i].children = []
        listCopy[i][BREADCRUMB] =
            item.type === FOLDER
                ? getFolderBreadcrumbs(item as Folder, currentFolderGuid)
                : getVideoBreadcrumbs(item as Video, folders, currentFolderGuid)
    })

    listCopy.forEach((_, i) => {
        node = listCopy[i]
        const parentId = 'parentFolderGuid' in node ? node.parentFolderGuid : node.folderGuid

        if (parentId !== ROOT && listCopy[map[parentId]]) {
            listCopy[map[parentId]].children.push(node)
        } else {
            roots.push(node)
        }
    })

    return [roots, listCopy]
}

type useVideosWithFoldersParams = {
    filter?: string
    videosFirst?: boolean
    sort?: (videos?: Video[]) => Video[] | undefined
    excludeSelectedVideo?: boolean
}

export const useVideosWithFolders = (params?: useVideosWithFoldersParams, videosQuery?: VideosQueryType) => {
    const { filter, videosFirst, sort, excludeSelectedVideo } = params || {}
    const { guid, videoGuid } = useParams()
    const { selectedVidGuid } = useAppSelector(selectVideoCompareState)
    const videosNotFiltered = useVideosQuery(videosQuery)
    const videos =
        excludeSelectedVideo || selectedVidGuid
            ? {
                  ...videosNotFiltered,
                  data: selectedVidGuid
                      ? videosNotFiltered.data?.filter(
                            (video) => video.guid !== selectedVidGuid && video.guid !== videoGuid,
                        )
                      : videosNotFiltered.data?.filter((video) => video.guid !== videoGuid),
              }
            : videosNotFiltered

    const folders = useVideoFoldersQuery()
    const isLoading = useQueriesLoading(videosNotFiltered, folders)
    const isError = useQueriesIsError(videosNotFiltered, folders)

    const [videosWithFolders, fullList] = useMemo(() => {
        if (!videos.data || !folders.data) {
            return [[], []]
        }

        const sortedVids = sort?.(videos.data) || videos.data

        if (filter) {
            const preparedFolders = [
                ...folders.data.filter((f) => f.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())),
            ]

            return listToTree(
                videosFirst ? [...sortedVids, ...preparedFolders] : [...preparedFolders, ...sortedVids],
                preparedFolders,
                guid,
            )
        }

        return listToTree(
            videosFirst
                ? [...sortedVids, ...[...folders.data].reverse()]
                : [...[...folders.data].reverse(), ...sortedVids],
            folders.data,
            guid,
        )
    }, [videos, folders, filter])

    return {
        videosWithFolders,
        videos,
        folders,
        fullList,
        isLoading,
        isError,
    }
}
