import { useQuery, UseQueryOptions } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_DISTRIBUTION_METRICS } from 'api/constants'
import {
    GetDistributionMetricsQuery,
    GetDistributionMetricsResponse,
} from 'api/contracts/dashboard/endpoints/getDistributionMetrics.contract'

export const useGetDistributionMetricsQuery = (
    query: GetDistributionMetricsQuery,
    options?: UseQueryOptions<GetDistributionMetricsResponse>,
) =>
    useQuery<GetDistributionMetricsResponse>(
        [DASHBOARD_GET_DISTRIBUTION_METRICS, query],
        () => dashboardController.getDistributionMetrics(query),
        options,
    )
