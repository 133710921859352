import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RefObject } from 'react'

import { useCustomerSubscriptionQuery } from 'api/queries'
import CreditCardForm from 'design/molecules/CreditCardForm'
import style from './PaymentInfo.style'
import CustomerCards from './CustomerCards'

type PaymentInfoProps = {
    openCardForm: boolean
    formRef: RefObject<HTMLFormElement>
    chargifyRef: RefObject<unknown>
    onAddCard: () => void
}

const PaymentInfo = ({ openCardForm, onAddCard, formRef, chargifyRef }: PaymentInfoProps) => {
    const { t } = useTranslation()

    const { data: subscription } = useCustomerSubscriptionQuery()

    const displayForm = openCardForm || !subscription?.creditCard

    return (
        <Stack sx={style.root}>
            <Typography variant="h6" fontWeight={600} textAlign="center">
                {t('Payment info')}
            </Typography>

            {displayForm ? (
                <CreditCardForm formRef={formRef} chargifyRef={chargifyRef} displayActions={false} />
            ) : (
                <CustomerCards openCardForm={openCardForm} onAddCard={onAddCard} />
            )}
        </Stack>
    )
}

export default PaymentInfo
