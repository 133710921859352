import { SxProps } from '@mui/material'

const app: SxProps = {
    minHeight: '100vh',

    '& > div': {
        minHeight: '100vh',
    },
}

export default {
    app,
}
