import { valueFormatter, valueOrDefault, currencyOrPercentageOrDefault, formatAsPercentageOrDefault } from 'utils'

export enum MetricKeys {
    plays = 'plays',
    playRate = 'playRate',
    impressions = 'impressions',
    playsUnique = 'playsUnique',
    unmuteRate = 'unmuteRate',
    engagement = 'engagement',
    conversionCount = 'conversionCount',
    conversionRate = 'conversionRate',
    revenueAverage = 'revenueAverage',
    pgOptInCount = 'pgOptInCount',
    pgOptInRate = 'pgOptInRate',
    revenue = 'revenue',
    revenuePerViewer = 'revenuePerViewer',
    bounceRate = 'bounceRate',
    unmutePlays = 'unmutePlays',
}

export type Metrics = {
    [MetricKeys.plays]?: number
    [MetricKeys.playRate]?: number
    [MetricKeys.impressions]?: number
    [MetricKeys.playsUnique]?: number | boolean
    [MetricKeys.unmuteRate]: number
    [MetricKeys.engagement]?: number
    [MetricKeys.conversionCount]?: number | boolean
    [MetricKeys.conversionRate]: number
    [MetricKeys.revenueAverage]?: number
    [MetricKeys.pgOptInCount]?: number
    [MetricKeys.pgOptInRate]: number
    [MetricKeys.revenue]?: number
    [MetricKeys.revenuePerViewer]?: number
    [MetricKeys.bounceRate]?: number
    [MetricKeys.unmutePlays]?: number
}

export type ColsVisibilityState = {
    [key in MetricKeys]: boolean
}

export type MetricsLineVisibilityState = {
    [key in MetricKeys]: boolean
}

export const StatsTableColumns = [
    {
        field: MetricKeys.plays,
        headerName: 'Plays',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.playRate,
        headerName: 'Play Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: MetricKeys.impressions,
        headerName: 'Impressions',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.playsUnique,
        headerName: 'Unique Viewers',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.unmuteRate,
        headerName: 'Unmute Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: MetricKeys.engagement,
        headerName: 'Avg % Watched',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: MetricKeys.conversionCount,
        headerName: 'Conversions',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.conversionRate,
        headerName: 'Conversions Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: MetricKeys.revenueAverage,
        headerName: 'Average Order Value',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(currencyOrPercentageOrDefault),
    },
    {
        field: MetricKeys.pgOptInCount,
        headerName: 'Opt-Ins',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.pgOptInRate,
        headerName: 'Opt-In Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: MetricKeys.revenue,
        headerName: 'Revenue',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(currencyOrPercentageOrDefault),
    },
    {
        field: MetricKeys.revenuePerViewer,
        headerName: 'Revenue per Viewer',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(currencyOrPercentageOrDefault),
    },
    {
        field: MetricKeys.bounceRate,
        headerName: 'Bounce Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: MetricKeys.unmutePlays,
        headerName: 'Unmute Plays',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
]

export interface SetColVisibilityPayload {
    key: MetricKeys
    value: boolean
}

export interface SetMetricVisibilityPayload {
    key: MetricKeys
    value: boolean
}

export enum DayOfWeek {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,
}

export enum RowTypes {
    DOMAIN = 'domain',
    SUB_DOMAIN = 'sub-domain',
}

export enum AnalyticsMetricKeys {
    dateCreated = 'dateCreated',
    dateUpdated = 'dateUpdated',
    plays = 'plays',
    playRate = 'playRate',
    impressions = 'impressions',
    playsUnique = 'uniquePlays',
    unmuteRate = 'unmuteRate',
    engagement = 'engagement',
    conversionCount = 'conversions',
    conversionRate = 'conversionRate',
    revenueAverage = 'averageOrder',
    pgOptInRate = 'optInRate',
    revenue = 'revenue',
    revenuePerViewer = 'revenuePerViewer',
    bounceRate = 'bounceRate',
}

export type AnalyticsLineVisibilityState = {
    [key in AnalyticsMetricKeys]: boolean
}

export interface SetAnalyticsVisibilityPayload {
    key: AnalyticsMetricKeys
    value: boolean
}

export const AnalyticsTableColumns = [
    {
        field: AnalyticsMetricKeys.dateCreated,
        headerName: 'Created',
        minWidth: 90,
        flex: 1,
        valueFormatter: (dateCreated: string | number | Date) =>
            dateCreated ? new Date(dateCreated).toLocaleDateString('en-US') : '-',
    },
    {
        field: AnalyticsMetricKeys.dateUpdated,
        headerName: 'Updated',
        minWidth: 90,
        flex: 1,
        valueFormatter: (dateUpdated: string | number | Date) =>
            dateUpdated ? new Date(dateUpdated).toLocaleDateString('en-US') : '-',
    },
    {
        field: AnalyticsMetricKeys.plays,
        headerName: 'Plays',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: AnalyticsMetricKeys.playRate,
        headerName: 'Play Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: AnalyticsMetricKeys.impressions,
        headerName: 'Impressions',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: AnalyticsMetricKeys.playsUnique,
        headerName: 'Unique Viewers',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: AnalyticsMetricKeys.unmuteRate,
        headerName: 'Unmute Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: AnalyticsMetricKeys.engagement,
        headerName: 'Avg % Watched',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: AnalyticsMetricKeys.conversionCount,
        headerName: 'Conversions',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: AnalyticsMetricKeys.conversionRate,
        headerName: 'Conversions Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: AnalyticsMetricKeys.revenueAverage,
        headerName: 'Average Order Value',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(currencyOrPercentageOrDefault),
    },
    {
        field: AnalyticsMetricKeys.pgOptInRate,
        headerName: 'Opt-In Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: AnalyticsMetricKeys.revenue,
        headerName: 'Revenue',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(currencyOrPercentageOrDefault),
    },
    {
        field: AnalyticsMetricKeys.revenuePerViewer,
        headerName: 'Revenue per Viewer',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(currencyOrPercentageOrDefault),
    },
    {
        field: AnalyticsMetricKeys.bounceRate,
        headerName: 'Bounce Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
]
