import { CreditCard, SignUpPlanResponse } from 'types/Auth'
import {
    ActivateCustomerPlanPayload,
    CreateIpFilterPayload,
    CreateWhitelistedDomainPayload,
    Customer,
    CustomerSubscription,
    FeatureFlags,
    GlobalEmbedScript,
    GlobalSettings,
    Invoice,
    IpFilter,
    PermissionsObject,
    Plan,
    PlanProrate,
    PlanProratePayload,
    UpdateCardInfoByTokenPayload,
    UpdateCustomerPayload,
    UpdateIpFilterPayload,
    UpdateWhitelistedDomainPayload,
    UpsellShownPayload,
    UserApiToken,
    WhitelistedDomain,
} from 'types/Customer'
import { http } from 'api/utils/http.utils'
import { Id } from 'types'

export const userAPI = {
    getCustomer: () => http.get<Customer>(`customer`),
    getPermissions: () => http.get<PermissionsObject>(`permission/user`),
    getUnathorizedFeatureFlags: () => http.get<FeatureFlags>(`fflags/unauthorized`),
    updateCustomer: (payload: Partial<Customer & UpdateCustomerPayload>) =>
        http.post<Partial<Customer & UpdateCustomerPayload>>('customer/update', payload),
    getWhitelistedDomain: () => http.get<WhitelistedDomain[]>('customer/whitelisted-domains'),
    getGlobalSettings: () => http.get<GlobalSettings>('customer/global-settings'),
    updateGlobalSettings: (payload: GlobalSettings) => http.put<GlobalSettings>('/customer/global-settings', payload),
    getInvoices: () => http.get<Invoice[]>('customer/invoices'),
    getDownloadInvoice: (id: Id) => http.get<Invoice>(`customer/invoice/${id}/download`, { responseType: 'blob' }),
    createWhitelistedDomain: (payload: CreateWhitelistedDomainPayload) =>
        http.post<CreateWhitelistedDomainPayload>(`customer/whitelisted-domains`, payload),
    updateWhitelistedDomain: (payload: UpdateWhitelistedDomainPayload) =>
        http.post<UpdateWhitelistedDomainPayload>(`customer/whitelisted-domains/${payload.domain.guid}`, payload),
    deleteWhitelistedDomain: (id: Id) => http.delete(`customer/whitelisted-domains/${id}`),
    getIpFilters: () => http.get<IpFilter[]>('customer/ip-filters'),
    createIpFilter: (payload: CreateIpFilterPayload) =>
        http.post<CreateIpFilterPayload>('customer/ip-filters', payload),
    updateIpFilter: (payload: UpdateIpFilterPayload) =>
        http.post<UpdateIpFilterPayload>(`customer/ip-filters/${payload.ipFilter.guid}`, payload),
    deleteIpFilter: (id: Id) => http.delete<Id>(`customer/ip-filters/${id}`),
    getCustomerSubscription: () => http.get<CustomerSubscription>('customer/subscription'),
    activatePlan: (payload: ActivateCustomerPlanPayload): Promise<CreditCard> =>
        http.post<ActivateCustomerPlanPayload>('customer/plan', payload),
    getPlanProrate: (payload: PlanProratePayload): Promise<PlanProrate> =>
        http.post<PlanProratePayload>('customer/plan/prorate', payload),
    getPlans: () => http.get<Plan[]>('plans'),
    getSignUpPlan: () => http.get<SignUpPlanResponse>(`customer/get-sign-up-plan`),
    getPaymentProfile: () => http.get<Plan[]>('payment-profile/all'),
    activatePaymentProfile: (id: Id) => http.post<UpdateCardInfoByTokenPayload>(`payment-profile/activate/${id}`),
    deletePaymentProfile: (id: Id) => http.delete<UpdateCardInfoByTokenPayload>(`payment-profile/delete/${id}`),
    createPaymentFallback: (payload: Pick<Plan, 'id'>) =>
        http.post<Pick<Plan, 'id'>>(`payment-profile/create-fallback`, payload),
    deletePaymentFallback: (id: Id) => http.delete<Pick<Plan, 'id'>>(`payment-profile/delete-fallback/${id}`),
    updateCardInfoByToken: (payload: UpdateCardInfoByTokenPayload) =>
        http.post<UpdateCardInfoByTokenPayload>('v2/customer/credit-card-from-token', payload),
    updateSignUpCardByToken: (payload: UpdateCardInfoByTokenPayload) =>
        http.post<UpdateCardInfoByTokenPayload>('customer/change-sign-up-credit-card', payload),
    getApiToken: () => http.get<UserApiToken>('customer/get-token'),
    generateApiToken: () => http.get<UserApiToken>('customer/token'),
    setUpsellShown: (payload: UpsellShownPayload) => http.post<UpsellShownPayload>('/customer/upsell/shown', payload),
    getGlobalScript: () => http.get<GlobalEmbedScript>('global-script/embed'),
}
