import { SxProps, Theme } from '@mui/material'

const stack: SxProps = {
    m: 'auto 0 16px 0',
}

const helpLink: SxProps = {
    p: '9px 12px 9px 20px',
    display: 'flex',
}

const supportIcon: SxProps<Theme> = {
    color: (theme) => theme.palette.primary.main,
}

const sidebarNone: SxProps = {
    display: 'none',
}

const sidebarInitial: SxProps = {
    display: 'initial',
}

export default {
    stack,
    helpLink,
    supportIcon,
    sidebar: {
        idle: sidebarNone,
        open: sidebarInitial,
    },
}
