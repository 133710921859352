export default {
    title: 'Seeking bar',
    controls: {
        standard: {
            label: 'Seeking Bar',
            tooltip:
                'If enabled, this will display a regular seeking bar that progresses at normal speed and shows the video’s duration.',
        },
        engageBar: {
            label: 'Rapid Engage Bar',
            tooltip:
                'This progress bar starts off quickly to capture viewer attention by making the video seem shorter, then gradually slows closer to the actual speed. It prevents viewers from jumping ahead in the video.',
        },
        desktopSpeed: {
            label: 'Desktop Speed',
        },
        mobileSpeed: {
            label: 'Mobile Speed',
        },
        revisit: {
            label: 'ReVisit Seeking Bar',
            tooltip: 'This seeking bar is shown only to viewers returning to your video for a second (or third) time.',
        },
    },
}
