/**
 * Values should be API response keys
 */
export enum METRICS {
    AVG_ORDER = 'averageOrder',
    AVERAGE_PERCENT_WATCHED = 'averagePercentWatched',
    AVG_REVENUE_PER_PLAY = 'avgRevenuePerPlay',
    AVG_WATCH_TIME = 'averageWatchTime',
    CTA_CTR = 'ctaCtr',
    CONVERSION_RATE = 'conversionRate',
    CONVERSIONS = 'conversions', // TODO: [VID-8699] no s letter on agency accounts type
    IMPRESSIONS = 'impressions',
    PLAY_RATE = 'playRate',
    PLAYS = 'plays',
    REVENUE = 'revenue',
    OPT_IN_RATE = 'optInRate',
    TAGS_TRIGGERED_RATE = 'tagsTriggeredRate',
    TOTAL_PLAYS = 'totalPlays', // TODO: [VID-8699] Same as plays?
    UNIQUE_VIEWERS = 'uniqueViewers',
    UNMUTE_RATE = 'unmuteRate',
}

export const metrics = [
    METRICS.AVG_ORDER,
    METRICS.AVERAGE_PERCENT_WATCHED,
    METRICS.AVG_REVENUE_PER_PLAY,
    METRICS.CONVERSION_RATE,
    METRICS.CONVERSIONS,
    METRICS.IMPRESSIONS,
    METRICS.PLAY_RATE,
    METRICS.PLAYS,
    METRICS.REVENUE,
    METRICS.TOTAL_PLAYS,
    METRICS.UNIQUE_VIEWERS,
    METRICS.UNMUTE_RATE,
] as const
export type Metrics = (typeof metrics)[number]

/**
 * Metrics used in dashboard PrimaryMetricWidget
 */
export const primaryMetrics = [METRICS.PLAYS, METRICS.PLAY_RATE, METRICS.CONVERSIONS, METRICS.REVENUE] as const
export type PrimaryMetrics = (typeof primaryMetrics)[number]

/**
 * Metrics used in dashboard MetricsHistoryCard
 */
export const metricsWithHistory = [
    METRICS.PLAYS,
    METRICS.PLAY_RATE,
    METRICS.AVG_WATCH_TIME,
    METRICS.OPT_IN_RATE,
    METRICS.CTA_CTR,
    METRICS.TAGS_TRIGGERED_RATE,
    METRICS.CONVERSION_RATE,
] as const
export type MetricsWithHistory = (typeof metricsWithHistory)[number]

interface MetricMeta {
    prefix: string
    postfix: string
}

export const metricsConfig: { [key in METRICS]: MetricMeta } = {
    [METRICS.AVERAGE_PERCENT_WATCHED]: {
        prefix: '',
        postfix: '%',
    },
    [METRICS.AVG_ORDER]: {
        prefix: '$',
        postfix: '',
    },
    [METRICS.AVG_REVENUE_PER_PLAY]: {
        prefix: '$',
        postfix: '',
    },
    [METRICS.AVG_WATCH_TIME]: {
        prefix: '',
        postfix: '%',
    },
    [METRICS.CONVERSIONS]: {
        prefix: '',
        postfix: '',
    },
    [METRICS.CONVERSION_RATE]: {
        prefix: '',
        postfix: '%',
    },
    [METRICS.CTA_CTR]: {
        prefix: '',
        postfix: '%',
    },
    [METRICS.IMPRESSIONS]: {
        prefix: '',
        postfix: '',
    },
    [METRICS.OPT_IN_RATE]: {
        prefix: '',
        postfix: '%',
    },
    [METRICS.PLAYS]: {
        prefix: '',
        postfix: '',
    },
    [METRICS.PLAY_RATE]: {
        prefix: '',
        postfix: '%',
    },
    [METRICS.REVENUE]: {
        prefix: '$',
        postfix: '',
    },
    [METRICS.TAGS_TRIGGERED_RATE]: {
        prefix: '',
        postfix: '%',
    },
    [METRICS.TOTAL_PLAYS]: {
        prefix: '',
        postfix: '',
    },
    [METRICS.UNIQUE_VIEWERS]: {
        prefix: '',
        postfix: '',
    },
    [METRICS.UNMUTE_RATE]: {
        prefix: '',
        postfix: '%',
    },
}
