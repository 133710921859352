import { UserApiToken } from 'types/Customer'
import { GENERATE_USER_API_TOKEN_QUERY, USER_API_TOKEN_QUERY } from 'api/constants'
import { userAPI } from 'api/controllers'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'

type UserApiTokenData = UserApiToken | null

export const useGenerateApiTokenMutation = () => {
    return useInvalidateMutation<UserApiTokenData, void, Error>(
        GENERATE_USER_API_TOKEN_QUERY,
        () => userAPI.generateApiToken(),
        {
            invalidate: [USER_API_TOKEN_QUERY],
            invalidateOn: InvalidateOn.SETTLED,
        },
    )
}
