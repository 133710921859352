import { endOfMonth, startOfMonth, subDays } from 'date-fns'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { StatsDateRangeState, SetDateRangePayload, StatsDateRange } from './types'

const initialState: StatsDateRangeState = {
    dates: [subDays(new Date(), 29), new Date()],
}

export enum DateRangeShortcut {
    Today = 'Today',
    Yesterday = 'Yesterday',
    Last7Days = 'Last 7 Days',
    Last30Days = 'Last 30 Days',
    ThisMonth = 'This Month',
    LastMonth = 'Last Month',
    AllTime = 'All Time',
}

export const ANALYTICS_DATA_RANGE_SHORTCUTS = 'ANALYTICS_DATA_RANGE_SHORTCUTS'

export const analyticsDateRangeSlice = createSlice({
    name: 'analyticsDateRange',
    initialState,
    reducers: {
        setAnalyticsDateRange: (state, action: PayloadAction<SetDateRangePayload>) => {
            const dateOrAction = action.payload

            if (typeof dateOrAction === 'function') {
                state.dates = dateOrAction(state.dates)
            } else {
                state.dates = dateOrAction
            }
        },
    },
})

export const { setAnalyticsDateRange } = analyticsDateRangeSlice.actions

export const selectAnalyticsDateRange = (state: RootState) => {
    const dates = state.analyticsDateRange.dates
    const valueShortcuts = localStorage.getItem(ANALYTICS_DATA_RANGE_SHORTCUTS) || ''
    const isValid = valueShortcuts in DateRangeShortcut
    if (valueShortcuts === DateRangeShortcut.Today) {
        return [new Date(), new Date()] as StatsDateRange
    }
    if (valueShortcuts === DateRangeShortcut.Yesterday) {
        return [subDays(new Date(), 1), subDays(new Date(), 1)] as StatsDateRange
    }
    if (valueShortcuts === DateRangeShortcut.Last7Days) {
        return [subDays(new Date(), 6), new Date()] as StatsDateRange
    }
    if (valueShortcuts === DateRangeShortcut.Last30Days) {
        return [subDays(new Date(), 29), new Date()] as StatsDateRange
    }
    if (valueShortcuts === DateRangeShortcut.ThisMonth) {
        return [startOfMonth(new Date()), new Date()] as StatsDateRange
    }
    if (valueShortcuts === DateRangeShortcut.LastMonth) {
        const startOfCurrentMonth = startOfMonth(new Date())
        const previousMonth = subDays(startOfCurrentMonth, 5)
        const endOfPrevMonth = endOfMonth(previousMonth)

        return [startOfMonth(previousMonth), endOfPrevMonth] as StatsDateRange
    }
    if (valueShortcuts === DateRangeShortcut.AllTime) {
        return [new Date(dates[0]), new Date()] as StatsDateRange
    }
    if (valueShortcuts && !isValid) {
        const array = JSON.parse(valueShortcuts)
        return [new Date(array[0]), new Date(array[1])] as StatsDateRange
    }

    return [new Date(dates[0]), new Date(dates[1])] as StatsDateRange
}

export * from './types'

export const analyticsDateRange = analyticsDateRangeSlice.reducer
