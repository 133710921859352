import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, SvgIcon, Typography } from '@mui/material'
import { ComponentType, SyntheticEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'

import useUpgradeCallback from 'design/molecules/UpgradeBadge/useUpgradeCallback'
import UpgradeBadge from 'design/molecules/UpgradeBadge'
import { SubscriptionCtaSource } from 'design/templates/Modal'
import { useCheckAccess } from 'utils'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { FeatureGroup, VidSettingsKey } from 'constants/keys/featuresAccess.constants'

import style from './AccordionMenu.style'

interface AccordionMenuProps {
    menu: Array<{
        label: string
        Icon: typeof SvgIcon
        Children: ComponentType
        forPro?: boolean
        locked?: boolean
        featureKey?: VidSettingsKey
        isNew?: boolean
        onUpgradeClick?: () => void
    }>
    initial?: boolean | string
}

const AccordionMenu = ({ menu, initial }: AccordionMenuProps) => {
    const { t } = useTranslation()
    const upgradeCallback = useUpgradeCallback(SubscriptionCtaSource.VID_SETTINGS)
    const [expanded, setExpanded] = useState<string | boolean>(initial ?? menu[0].label)
    const { freshUpgradeFlow } = useFeatureFlags()
    const handleChange = (panel: string, locked?: boolean) => (_: SyntheticEvent, newExpanded: boolean) => {
        if (locked) return

        setExpanded(newExpanded ? panel : false)
    }
    const checkAccess = useCheckAccess(FeatureGroup.VidSettings)

    const menuWithLocked = useMemo(
        () => menu.map((menuItem) => ({ ...menuItem, locked: menuItem.locked || checkAccess(menuItem.featureKey) })),
        [menu, checkAccess],
    )

    return (
        <>
            {menuWithLocked.map(({ label, Icon, Children, locked, isNew, featureKey, onUpgradeClick }) => (
                <Accordion
                    sx={style.accordion}
                    key={label}
                    disableGutters
                    expanded={expanded === label && !locked}
                    onChange={handleChange(label, locked)}
                    onClick={(event) => {
                        if (locked) {
                            event.preventDefault()
                            onUpgradeClick && !freshUpgradeFlow
                                ? onUpgradeClick()
                                : upgradeCallback({ featureGroup: FeatureGroup.VidSettings, featureKey })
                        }
                    }}
                >
                    <AccordionSummary
                        expandIcon={<KeyboardArrowRightRoundedIcon />}
                        sx={[style.accordionSummary, expanded === label ? style.highlighted : style.icon]}
                    >
                        <Stack direction="row" alignItems="center" gap={4}>
                            <Icon fontSize="small" />
                            <Typography
                                variant="body1"
                                component="span"
                                fontWeight={600}
                                sx={[style.label, expanded === label ? style.highlighted : style.idle]}
                            >
                                {label}
                            </Typography>
                            {isNew && (
                                <Button size="small" variant="contained" sx={style.newBtn}>
                                    {t('New')}
                                </Button>
                            )}
                        </Stack>
                        {locked && <UpgradeBadge tooltipProps={{ placement: 'left' }} preventDefault />}
                    </AccordionSummary>

                    <AccordionDetails>
                        {!locked && (
                            <Box className="mainDetails">
                                <Children />
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    )
}

export default AccordionMenu
