import { Components, Theme, alpha } from '@mui/material'

export const tooltipBackgroundColor = alpha('#182641', 0.95)

export const MuiTooltip: Components<Omit<Theme, 'components'>>['MuiTooltip'] = {
    styleOverrides: {
        tooltip: ({ theme }) => ({
            background: tooltipBackgroundColor,
            borderRadius: theme.shape.borderRadius,
            fontSize: theme.typography.caption2.fontSize,
            lineHeight: theme.typography.caption3.lineHeight,
        }),
        arrow: {
            color: tooltipBackgroundColor,
        },
    },
    defaultProps: {
        enterTouchDelay: 0,
    },
}
