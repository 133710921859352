import { SubscriptionCategory } from 'types/Customer'
import { SubscriptionCtaSource } from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import { COMMON_CONFIG } from './common'

export const smartVidsLockedModalConfig = {
    ...COMMON_CONFIG,
    source: SubscriptionCtaSource.SMART_VIDS,
    title: 'Upgrade to Unlock Smart Vids and Boost Your Video Conversions with Interactive Storytelling!',
    description:
        "You'll have 14 days to try all the best features that thousands of marketers use to drive 8 figures in sales each and every month. Which have been proven to increase conversions by at least 25%.",
    planCategory: SubscriptionCategory.premium,
    promoMediaSrc: '/img/subscription/smart-vids.png',
}

export const conversionLockedModalConfig = {
    ...COMMON_CONFIG,
    source: SubscriptionCtaSource.CONVERSIONS,
    title: 'Upgrade to Unlock Conversion Tracking!',
    description:
        'See the exact spot in your videos that makes your viewers convert. It is as easy as dropping a snippet of code on your thank you page, and, oh so powerful... 😎',
    promoMediaSrc: '/img/subscription/conversions.png',
}

export const integrationsLockedModalConfig = {
    ...COMMON_CONFIG,
    source: SubscriptionCtaSource.INTEGRATIONS,
    title: 'Upgrade to Unlock Vidalytics Integrations!',
    description:
        'Unlock Vidalytics integrations to make your life easier, automate your marketing efforts, and increase your conversions.',
    promoMediaSrc: '/img/subscription/integrations.png',
}

export const analyticsLockedModalConfig = {
    ...COMMON_CONFIG,
    source: SubscriptionCtaSource.VID_STATS,
    title: 'Upgrade to Unlock Advanced Video Analytics!',
    description:
        'Vidalytics has the most useful, actionable video marketing analytics for understanding your viewers and knowing what changes to make to your video funnels to increase conversions.',
    promoMediaSrc: '/img/subscription/analytics.png',
}

export const dashboardLockedModalConfig = {
    ...COMMON_CONFIG,
    source: SubscriptionCtaSource.DASHBOARD,
    title: 'Upgrade to Unlock Your Dashboard!',
    description:
        "You're missing out on critical information about your videos performance to help you grow your business. Upgrade to get detailed and actionable data across all your videos.",
    promoMediaSrc: '/img/subscription/dashboard.png',
}

export const addMoreUsersLockedModalConfig = {
    ...COMMON_CONFIG,
    source: SubscriptionCtaSource.USER_SETTINGS,
    title: 'Upgrade to Add More Users!',
    description:
        "You'll be able to add multiple users to your account and grant them different permissions based on the duties they perform in your business. 🎯",
}
