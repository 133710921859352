import { SxProps, Theme } from '@mui/material'

const header: SxProps<Theme> = () => ({
    pb: 5,
})

const headerSearch: SxProps<Theme> = {
    ml: 'auto',
}

const content: SxProps<Theme> = (theme) => ({
    flexGrow: 1,
    maxHeight: 1,
    overflowY: 'hidden',

    [theme.breakpoints.down('tablet')]: {
        px: 0,
    },
})

const helperButton: SxProps<Theme> = (theme) => ({
    flexBasis: '100%',

    [theme.breakpoints.up('laptop')]: {
        flexBasis: 'unset',
    },
})

export default {
    header,
    headerSearch,
    helperButton,
    content,
}
