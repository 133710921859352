import _get from 'lodash/get'
import { Components, Theme } from '@mui/material'
import { alpha } from '@mui/material/styles'

import { font, pxToRem } from 'styles/theme/typography'
import { textStyle } from 'styles/theme/utils'

export const MuiButton: Components<Omit<Theme, 'components'>>['MuiButton'] = {
    styleOverrides: {
        root: ({ theme, ownerState }) => {
            const getColor = (color?: string) => _get(theme, `palette.${color}.main`) || theme.palette.primary.main
            const color = getColor(ownerState.color)

            return {
                fontFamily: font.inter.semibold,
                fontWeight: 600,
                textTransform: 'capitalize',
                borderRadius: 12,
                gap: 8,
                minWidth: 'auto',

                '.MuiButton-endIcon': {
                    margin: 0,
                },

                '.MuiButton-startIcon': {
                    margin: 0,

                    '&>*:nth-of-type(1)': {
                        fontSize: pxToRem(16),
                    },
                },

                ...(ownerState.variant === 'contained' && {
                    '&:active': {
                        boxShadow: theme.shadows[6],
                    },
                }),
                ...((ownerState.variant === 'outlined' || ownerState.variant === 'text') && {
                    boxShadow: 'unset',
                }),
                ...(ownerState.variant === 'contrast' && {
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${alpha(color, theme.palette.action.selectedOpacity)}`,
                }),

                ...(ownerState.variant === 'badge' && {
                    color,
                    border: `1px solid transparent`,
                    background: `linear-gradient(${alpha(color, theme.palette.action.focusOpacity)}, ${alpha(
                        color,
                        theme.palette.action.focusOpacity,
                    )}), white`,

                    '&:hover': {
                        background: `linear-gradient(${alpha(color, 0.3)}, ${alpha(color, 0.3)}), white`,
                    },
                }),
            }
        },
    },

    variants: [
        {
            props: { variant: 'contained' },
            style: ({ theme }) => ({ boxShadow: theme.shadows[2] }),
        },

        {
            props: { size: 'x2Large' },
            style: { ...textStyle(18, 28), padding: '16px 28px' },
        },
        {
            props: { size: 'xLarge' },
            style: { ...textStyle(16, 24), padding: '11px 20px' },
        },
        {
            props: { size: 'large' },
            style: { ...textStyle(15, 26), padding: '9px 18px' },
        },
        {
            props: { size: 'medium' },
            style: { ...textStyle(14, 24), padding: '8px 16px' },
        },
        {
            props: { size: 'small' },
            style: { ...textStyle(13, 22), padding: '7px 14px' },
        },
        {
            props: { size: 'xSmall' },
            style: { ...textStyle(13, 22), padding: '5px 14px' },
        },
        {
            props: { size: 'small', variant: 'badge' },
            style: { ...textStyle(13, 22), padding: '5px 14px' },
        },
        {
            props: { size: 'xSmall', variant: 'badge' },
            style: { ...textStyle(13, 22), py: 0.25, px: 2 },
        },
        {
            props: { size: 'iconX2Large' },
            style: { padding: 18 },
        },
        {
            props: { size: 'iconLarge' },
            style: { padding: 12 },
        },
        {
            props: { size: 'iconMedium' },
            style: { padding: 8 },
        },
        {
            props: { size: 'iconSmall' },
            style: { padding: 5 },
        },

        {
            props: { color: 'primary', variant: 'contained' },
            style: ({ theme }) => ({ border: `1px solid ${theme.palette.primary.dark}` }),
        },
        {
            props: { color: 'secondary', variant: 'contained' },
            style: ({ theme }) => ({ border: `1px solid ${theme.palette.secondary.dark}` }),
        },
        {
            props: { color: 'error', variant: 'contained' },
            style: ({ theme }) => ({ border: `1px solid ${theme.palette.error.dark}` }),
        },
        {
            props: { color: 'warning', variant: 'contained' },
            style: ({ theme }) => ({ border: `1px solid ${theme.palette.warning.dark}` }),
        },
        {
            props: { color: 'success', variant: 'contained' },
            style: ({ theme }) => ({ border: `1px solid ${theme.palette.success.dark}` }),
        },
        {
            props: { color: 'tertiary', variant: 'outlined' },
            style: ({ theme }) => ({
                border: `1px solid ${theme.palette.common.tertiaryOutlinedBorder}`,

                '&:hover': {
                    border: `1px solid ${theme.palette.common.tertiaryOutlinedBorder}`,
                },
            }),
        },
        {
            props: { disabled: true, variant: 'contained' },
            style: { border: `1px solid` },
        },
        {
            props: { disabled: true, variant: 'outlined' },
            style: ({ theme }) => ({
                border: `1px solid rgba(${theme.palette.common.black}, ${theme.palette.action.focusOpacity}) !important`,
            }),
        },
    ],
}
