import { ReactElement, useMemo, useState } from 'react'
import { GridActionsColDef } from '@mui/x-data-grid/models/colDef/gridColDef'
import { GridActionsCellItem, GridActionsCellItemProps, GridRowId } from '@mui/x-data-grid-pro'
import { Add as AddIcon, CodeRounded, DeleteOutlineRounded, Edit as EditIcon } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import { isString } from 'lodash'

import { Conversion, ConversionWindow } from 'types/Conversion'
import { ConversionEmbedCodeModal, openConversionConfirmDeleteModal } from 'design/templates/Modal'
import { DataGridTable } from 'design/atoms/DataGridTable/DataGridTable'
import { SelectedAction } from 'design/atoms/DataGridTable/components'
import { AstronautWaitingIcon } from 'assets/index'
import { useAppDispatch } from 'App'
import { getDateAgoWithRightTimezone } from 'utils'
import { useLayout } from 'hooks/utilities/useLayout'
import { CONVERSION_COUNT, CONVERSION_WINDOW, TITLE } from 'design/pages/Conversions/Conversions.constants'
import { COLUMN } from 'constants/table.constants'
import { ConvWindowHeader } from './ConvWindowHeader/ConvWindowHeader'

import style from './ConversionsTable.style'
import locale from './ConversionsTable.locale'

export type TableProps = {
    [COLUMN.GUID]: string
    [TITLE]: string
    [CONVERSION_WINDOW]: string
    [CONVERSION_COUNT]: number
    [COLUMN.DATE_CREATED]: string
    [COLUMN.DATE_UPDATED]: string
}

type ConversionsProps = {
    conversions?: Conversion[]
    isLoading: boolean
    searchValue: string
    locked: boolean
    handleActionEdit: (conversion?: Conversion) => void
}

export const ConversionsTable = ({
    conversions,
    isLoading,
    locked,
    handleActionEdit,
    searchValue,
}: ConversionsProps) => {
    const { palette } = useTheme()
    const { isMobile } = useLayout()

    const [embedCodeConversion, setEmbedCodeConversion] = useState<Conversion | null>(null)
    const dispatch = useAppDispatch()

    const data = useMemo(() => (conversions?.length && !locked ? conversions : []), [conversions, locked])

    const openConfirmationModal = (rowSelection: readonly GridRowId[]) => {
        dispatch(
            openConversionConfirmDeleteModal({
                selectedConversions: Array.isArray(rowSelection) ? rowSelection.filter(isString) : [],
            }),
        )
    }

    const selectedActions: SelectedAction[] = [
        {
            actionClick: openConfirmationModal,
            label: locale.actions.actionDelete,
            icon: <DeleteOutlineRounded />,
            sx: style.selectedActionDelete,
        },
    ]

    const handleActionGetEmbedCode = (conversion: Conversion) => {
        setEmbedCodeConversion(conversion)
    }

    const closeEmbedCodeModal = () => {
        setEmbedCodeConversion(null)
    }

    const getActions: GridActionsColDef['getActions'] = (params) => {
        const actions: ReactElement<GridActionsCellItemProps>[] = []

        if (!isMobile) {
            actions.push(
                <GridActionsCellItem
                    key={params.id + 'get-code'}
                    icon={<CodeRounded />}
                    label={locale.actions.actionGetCode}
                    onClick={() => handleActionGetEmbedCode(params.row)}
                />,
            )
        }

        actions.push(
            <GridActionsCellItem
                key={params.id + 'edit'}
                icon={<EditIcon />}
                label={locale.actions.actionEdit}
                onClick={() => {
                    handleActionEdit(params.row)
                }}
                showInMenu
            />,
            <GridActionsCellItem
                key={params.id + 'get-code-inside-menu'}
                icon={<CodeRounded />}
                label={locale.actions.actionGetCode}
                sx={{ textTransform: 'capitalize' }}
                onClick={() => handleActionGetEmbedCode(params.row)}
                showInMenu
                divider
            />,
            <GridActionsCellItem
                key={params.id + 'delete'}
                icon={<DeleteOutlineRounded color="error" />}
                sx={style.actionDelete}
                label={locale.actions.actionDelete}
                onClick={() => {
                    openConfirmationModal([params.id.toString()])
                }}
                showInMenu
            />,
        )

        return actions
    }

    return (
        <>
            <DataGridTable
                loading={isLoading}
                columns={[
                    {
                        field: TITLE,
                        headerName: locale.columns[TITLE],
                        flex: 3,
                        minWidth: 80,
                        editable: false,
                    },
                    {
                        field: CONVERSION_WINDOW,
                        flex: 1,
                        minWidth: 160,
                        renderHeader: ConvWindowHeader,
                        valueFormatter: (value) => ConversionWindow[value],
                        sortComparator: (v1: number, v2: number) => v1 - v2,
                        editable: false,
                    },
                    {
                        field: CONVERSION_COUNT,
                        headerName: locale.columns[CONVERSION_COUNT],
                        minWidth: 80,
                        flex: 1,
                    },
                    {
                        field: COLUMN.DATE_UPDATED,
                        headerName: locale.columns[COLUMN.DATE_UPDATED],
                        minWidth: 80,
                        valueFormatter: (value) => getDateAgoWithRightTimezone(value),
                        flex: 1,
                    },
                    {
                        field: COLUMN.DATE_CREATED,
                        headerName: locale.columns[COLUMN.DATE_CREATED],
                        minWidth: 80,
                        valueFormatter: (value) => getDateAgoWithRightTimezone(value),
                        flex: 1,
                    },
                    {
                        field: 'actions',
                        type: 'actions',
                        width: isMobile ? 50 : 88,
                        resizable: false,
                        getActions,
                    },
                ]}
                rows={data}
                propsTable={{
                    filterModel: {
                        items: [{ field: TITLE, operator: 'contains', value: searchValue }],
                    },
                    checkboxSelection: true,
                    hideFooter: true,
                }}
                pinnedColumnsOnMobile={[TITLE]}
                selectedActions={selectedActions}
                noRowsConfig={{
                    title: locale.noRows.title,
                    description: locale.noRows.description,
                    icon: (
                        <AstronautWaitingIcon
                            style={{
                                width: 128,
                                height: 128,
                                fill: palette.primary.light,
                            }}
                        />
                    ),
                    action: {
                        children: locale.noRows.action,
                        onClick: () => handleActionEdit(),
                        disabled: locked,
                        startIcon: <AddIcon />,
                        testId: 'newConversionButton',
                    },
                }}
            />

            {embedCodeConversion && (
                <ConversionEmbedCodeModal conversion={embedCodeConversion} onClose={closeEmbedCodeModal} />
            )}
        </>
    )
}
