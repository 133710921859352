import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro'

export const COLUMN = {
    ID: 'id',
    GUID: 'guid',
    DATA: 'data',
    DATE_CREATED: 'dateCreated',
    DATE_UPDATED: 'dateUpdated',
    DATE_PUBLISHED: 'datePublished',
} as const

export const PAGE_SIZE_STATS = [25, 50, 100, 250, 500]

export const MAX_SELECTABLE_ROWS = 10

export const MAX_SELECTABLE_ANALYTICS_ROWS = 5

export const DEFAULT_PAGE = 0

export const DEFAULT_PAGE_SIZE = PAGE_SIZE_STATS[0]

export const DEFAULT_SORT_MODEL: GridSortModel = []

export const DEFAULT_PAGE_MODEL: GridPaginationModel = {
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
}
