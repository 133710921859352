import { CustomerSubscriptionPlanTier } from 'types/Customer'
import { useCallback, useMemo } from 'react'

import { useCustomerSubscriptionQuery } from 'api/queries'
import { PlanIndexByTier } from 'constants/plan.constants'

export const useCurrentPlan = () => {
    const { data: subscription } = useCustomerSubscriptionQuery()

    const getPlanIndexByTier = useCallback((tier: CustomerSubscriptionPlanTier | undefined) => {
        if (!tier) return -1

        return PlanIndexByTier[tier]
    }, [])

    const currentPlan = subscription?.plan
    const currentPlanIndex = useMemo(() => getPlanIndexByTier(currentPlan?.tier), [currentPlan, getPlanIndexByTier])

    return { currentPlan, currentPlanIndex }
}
