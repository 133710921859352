import { SxProps, Theme } from '@mui/material'

export default {
    modalStack: {
        p: 8,
        gap: 8,
        zIndex: (theme) => theme.zIndex.modal,
    },
    headerContainer: {
        px: 8,
        textAlign: 'center',
        gap: 4,

        '& ~ .MuiIconButton-root': {
            position: 'absolute',
            top: 0,
            right: 0,
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },

    mountedModal: {
        position: 'relative',
        top: 0,
        left: 0,
    },
    mountedModalStack: {
        maxWidth: 1200,
        m: 0,
    },
} satisfies Record<string, SxProps<Theme>>
