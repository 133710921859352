import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography'
import { Field, FieldProps } from 'formik'
import { ChangeEvent, ReactNode } from 'react'

type CheckboxFormProps = Omit<CheckboxProps, 'onChange' | 'checked'> & {
    name: string
    label: ReactNode
    formControlProps?: Omit<FormControlLabelProps, 'label' | 'control'>
    labelTypography?: TypographyProps
    afterChange?: (name: string, value: boolean) => void
    onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const CheckboxForm = ({ name, afterChange, label, formControlProps, ...rest }: CheckboxFormProps) => {
    return (
        <Field name={name} type="checkbox">
            {({ field, form: { setFieldValue } }: FieldProps) => (
                <FormControlLabel
                    {...field}
                    label={label}
                    checked={field.value}
                    onChange={(_, value) => {
                        setFieldValue(name, value)
                        afterChange?.(name, value)
                    }}
                    control={<Checkbox size="small" {...rest} />}
                    {...formControlProps}
                />
            )}
        </Field>
    )
}

export default CheckboxForm
