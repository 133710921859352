import { MouseEvent, ReactElement, ReactNode, useRef, useState } from 'react'
import {
    Box,
    Button,
    ButtonProps,
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack,
    Typography,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro'

import { useLayout } from 'hooks/utilities/useLayout'
import { useTranslation } from 'react-i18next'
import style from './SelectedActions.style'
import locale from './SelectedActions.locale'

export type SelectedAction = {
    icon: ReactElement
    label: ReactNode
    sx?: ButtonProps['sx']
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void
    actionClick?: (rowSelection: readonly GridRowId[], event: MouseEvent<HTMLButtonElement>) => void
}

type SelectedActionsProps = {
    actions?: SelectedAction[]
    rowSelectionModel: GridRowSelectionModel
    onClearRowSelectionModel: () => void
}

export const SelectedActions = ({ actions, rowSelectionModel, onClearRowSelectionModel }: SelectedActionsProps) => {
    const { isMobile } = useLayout()
    const anchorRef = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()

    if (!rowSelectionModel.length || !actions?.length) return null

    const handleToggle = () => setOpen((prev) => !prev)

    const handleClose = () => setOpen(false)

    return (
        <Box sx={style.container} data-testId="selectedActionsContainer">
            <Stack sx={style.textContainer}>
                <IconButton sx={style.clearButton} onClick={onClearRowSelectionModel}>
                    <ClearIcon />
                </IconButton>

                <Typography noWrap variant="body1" sx={style.selectedText} data-testId="selectedActionsText">
                    {rowSelectionModel.length} {locale.title}
                </Typography>
            </Stack>

            <Stack sx={style.buttonContainer} data-testId="selectedActionsButtons">
                {isMobile && actions.length >= 3 ? (
                    <>
                        <Button
                            ref={anchorRef}
                            onClick={handleToggle}
                            sx={style.selectedText}
                            endIcon={
                                <KeyboardArrowUpRoundedIcon
                                    sx={{
                                        transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                                        transition: 'transform 0.3s',
                                    }}
                                />
                            }
                        >
                            {t('Actions')}
                        </Button>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="top-start"
                            disablePortal
                            transition
                        >
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                    <Paper
                                        sx={{
                                            borderRadius: 3,
                                        }}
                                    >
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList autoFocusItem={open}>
                                                {actions.map((action, index) => {
                                                    const onClick = (event: MouseEvent<HTMLButtonElement>) => {
                                                        action.actionClick?.(rowSelectionModel, event)
                                                        action.onClick?.(event)
                                                        handleClose()
                                                    }
                                                    const isDelete = action.label === 'Delete'

                                                    return (
                                                        <>
                                                            {isDelete && <Divider sx={{ my: 1 }} />}
                                                            <MenuItem
                                                                sx={style.menuItem}
                                                                key={index}
                                                                onClick={handleClose}
                                                            >
                                                                <Button
                                                                    startIcon={action.icon}
                                                                    disableRipple
                                                                    sx={
                                                                        isDelete
                                                                            ? style.popoverItemDelete
                                                                            : style.popoverItem
                                                                    }
                                                                    onClick={onClick}
                                                                >
                                                                    {action.label}
                                                                </Button>
                                                            </MenuItem>
                                                        </>
                                                    )
                                                })}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </>
                ) : (
                    actions?.map((action, index) => {
                        const onClick = (event: MouseEvent<HTMLButtonElement>) => {
                            action.actionClick?.(rowSelectionModel, event)
                            action.onClick?.(event)
                        }

                        if (isMobile) {
                            return (
                                <IconButton key={index} sx={action.sx} onClick={onClick}>
                                    {action.icon}
                                </IconButton>
                            )
                        }

                        return (
                            <Button key={index} variant="text" startIcon={action.icon} sx={action.sx} onClick={onClick}>
                                {action.label}
                            </Button>
                        )
                    })
                )}
            </Stack>
        </Box>
    )
}
