import { buttonBaseClasses, SxProps, Theme } from '@mui/material'

const titleRowContainer: SxProps<Theme> = (theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: 4,
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('tablet')]: {
        padding: 2,
        height: 50,
    },
})

const folderIcon: SxProps<Theme> = (theme) => ({
    fontSize: 72,
    color: theme.palette.primary.light,
    [theme.breakpoints.down('tablet')]: {
        fontSize: 44,
    },
})

const imageContainer: SxProps<Theme> = (theme) => ({
    width: '86px',
    height: '60px',
    flexShrink: 0,
    [theme.breakpoints.down('tablet')]: {
        width: '45px',
        height: '26px',
        flexShrink: 0,
    },
})

const title: SxProps = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'fit-content',
    marginRight: 'auto',
    flexGrow: 1,
}

const rowActionsContainer: SxProps = {
    position: 'relative',
    right: 0,
    minWidth: '150px',
    display: 'none',
    pointerEvents: 'auto',
    justifyContent: 'flex-end',
    ml: 'auto',
}

const typographyContainer: SxProps<Theme> = (theme) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 1,
    padding: 4,
    [theme.breakpoints.down('tablet')]: {
        padding: 2,
        height: 50,
    },
})

const container: SxProps = {
    '& .MuiBadge-badge': {
        display: 'none',
    },
    [`& .${buttonBaseClasses.root}:is(:not([aria-label="More"]))`]: {
        opacity: 1 + '!important',
        pointerEvents: 'auto',
    },
    '.MuiDataGrid-row:hover .RowActionsOuter, .MuiDataGrid-row:has(.menu-open) .RowActionsOuter': {
        display: 'flex',
        pointerEvents: 'auto',
    },
}

const selectedAction: SxProps<Theme> = (theme) => ({
    color: theme.palette.primary.contrastText,
})
const actionDelete: SxProps<Theme> = (theme) => ({
    color: theme.palette.error.main,
})

export default {
    typographyContainer,
    rowActionsContainer,
    titleRowContainer,
    folderIcon,
    imageContainer,
    title,
    container,
    selectedAction,
    actionDelete,
}
