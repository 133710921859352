import { CSSProperties } from 'react'
import { SxProps, Theme } from '@mui/material'

const popper: SxProps = { zIndex: 1 }

const grow: CSSProperties = {
    transformOrigin: 'left top',
}

const opener: SxProps = {
    p: '4px 12px 4px 20px',
    cursor: 'pointer',
}

const accountIconIdle: SxProps<Theme> = {
    mr: 0,
    color: (theme) => theme.palette.action.active,
}

const accountIconOpen: SxProps = {
    mr: 4,
}

const arrowDropdown: SxProps = {
    ml: 'auto',
}

const customerName: SxProps = {
    display: 'inline-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}

const opacityIdle: SxProps<Theme> = {
    transition: (theme) => `150ms ${theme.transitions.easing.easeOut}`,
    opacity: 0,
}

const opacityOpen: SxProps = {
    opacity: 1,
}

const menuList: SxProps = {
    py: 0,
    borderRadius: 3,
    overflow: 'hidden',
}

const paper: SxProps = {
    borderRadius: 3,
}

export default {
    popper,
    paper,
    grow,
    opener,
    accountIcon: {
        idle: accountIconIdle,
        open: accountIconOpen,
    },
    arrowDropdown,
    customerName,
    opacity: {
        idle: opacityIdle,
        open: opacityOpen,
    },
    menuList,
}
