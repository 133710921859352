import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { PlayerBlockView } from 'design/pages/VidStats/types'
import { MetricKey, SetMetricPayload, MetricKeysTimeline, InitialStateType, SetMetricTimelinePayload } from './types'

const initialState: InitialStateType = {
    videoMetrics: {
        [MetricKey.heatmap]: false,
        [MetricKey.heatmapSkips]: false,
        [MetricKey.heatmapRewinds]: false,
        [MetricKey.audience]: true,
        [MetricKey.conversions]: true,
        [MetricKey.engagement]: false,
        [MetricKey.fullScreen]: false,
        [MetricKey.volume]: false,
        [MetricKey.closedCaptions]: false,
        [MetricKey.speedUps]: false,
        [MetricKey.pauses]: false,
    },
    timelineMetrics: {
        [MetricKeysTimeline.plays]: true,
        [MetricKeysTimeline.playRate]: false,
        [MetricKeysTimeline.impressions]: false,
        [MetricKeysTimeline.playsUnique]: false,
        [MetricKeysTimeline.unmuteRate]: true,
        [MetricKeysTimeline.engagement]: false,
        [MetricKeysTimeline.conversionRate]: false,
        [MetricKeysTimeline.revenueAverage]: false,
        [MetricKeysTimeline.revenue]: false,
        [MetricKeysTimeline.revenuePerViewer]: false,
        [MetricKeysTimeline.bounceRate]: false,
        [MetricKeysTimeline.conversionCount]: false,
        [MetricKeysTimeline.pgOptInRate]: false,
        [MetricKeysTimeline.pgOptInCount]: false,
        [MetricKeysTimeline.unmutePlays]: false,
    },
    playerBlockView: PlayerBlockView.videoDuration,
}

export const metricsSwitchesSliceNew = createSlice({
    name: 'metricsSwitchesNew',
    initialState,
    reducers: {
        setMetricValue: (state, action: PayloadAction<SetMetricPayload>) => {
            const { key, value } = action.payload

            state.videoMetrics[key] = value
        },
        setTimelineMetricValue: (state, action: PayloadAction<SetMetricTimelinePayload>) => {
            const { key, value } = action.payload
            state.timelineMetrics[key] = value
        },
        setPlayerBlockView: (state, action: PayloadAction<PlayerBlockView>) => {
            state.playerBlockView = action.payload
        },
    },
})

export const { setMetricValue, setTimelineMetricValue, setPlayerBlockView } = metricsSwitchesSliceNew.actions

export const selectMetricsSwitchesState = (state: RootState) => state.metricsSwitchesNew.videoMetrics
export const selectTimelineMetricsSwitchesState = createSelector(
    [(state: RootState) => state.metricsSwitchesNew.timelineMetrics],
    (timelineMetrics) => timelineMetrics,
)
export const selectAnyMetricsEnabled = (state: RootState) =>
    Object.entries(state.metricsSwitchesNew.videoMetrics).some((pair) => pair[1])
export const selectPlayerBlockView = (state: RootState) => state.metricsSwitchesNew.playerBlockView

export * from './types'

export const metricsSwitchesNew = metricsSwitchesSliceNew.reducer
