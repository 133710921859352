export default {
    controls: {
        alwaysShow: 'Always Show Control Bar',
        bigPlayButton: 'Big Play Button',
        smallPlayButton: 'Small Play Button',
        settings: 'Settings',
        quality: 'Quality',
        speed: 'Speed',
        volume: 'Volume',
        fullscreen: 'Fullscreen',
        focusedFullscreenDesktop: 'Focused Fullscreen Desktop',
        focusedFullscreenMobile: 'Focused Fullscreen Mobile',
        rewinds: 'Rewind on Seeking Bar',
        rewindsFast: 'Rewind + Fast Forward on Player',
        rewindsSkip: 'Skip / Rewind with Keyboard Arrows',
        accessibilityTooltips: 'Enable Tooltips on Player Controls',
    },
    tooltips: {
        alwaysShow:
            'This setting controls whether the control bar disappears or remains visible, applying to any seeking bar selected for the video.',
        disabledPlayButtons:
            'You have Disable Pausing enabled. Please disable it in order to display either of the play buttons.',
        fullscreen: 'Be aware that this setting will not work on your video if it is used inside a Smart Vid.',
        focusedFullscreenDesktop:
            'Make your player expand to use the browser container on desktop, and not take up the whole viewer’s screen.',
        focusedFullscreenMobile:
            'Prevent native players from taking over on mobile, and keep Vidalytics’ controls over your video.',
        svFocusedFullscreenMobile:
            "Prevent native players from taking over on mobile, and keep Vidalytics' controls over your video. Note – on Smart Vids fullscreen works only in the focused mode.",
        rewinds: 'Add a 10 second rewind button on your player’s control bar, next to the small play button.',
        rewindsFast:
            'Add 10 second rewind and fast forward buttons on top of your player that will show up on hover on desktop, and when the video is paused on mobile.',
        rewindsSkip:
            'Allow users to fast forward or rewind your video using their keyboard arrows, even if your video does not have a seeking bar enabled.',
        accessibilityTooltips: 'Show helpful tooltips on player controls, such as Play, Pause, Volume, and Fullscreen.',
    },
}
