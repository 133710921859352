import { transferAPI } from 'api/controllers'
import { CUSTOMER_QUERY, VALIDATE_AUTH } from 'api/constants'
import { TransferPlatform } from 'types/Video'
import { useInvalidateMutation } from 'api/hooks'

export const useValidateCodeQuery = () => ({
    validateLink: useInvalidateMutation(
        VALIDATE_AUTH,
        async ({ platform, code, state }: { platform: TransferPlatform; code: string; state: string }) =>
            transferAPI.validatePlatformLink(platform, code, state),
        {
            invalidate: [CUSTOMER_QUERY],
        },
    ),
})
