import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { ExtendedFile, TransferStatus } from 'types/Video'
import { UploaderState, ExtendedFiles, SetFilesPayload, UpdateFilePayload } from './types'

const initialState: UploaderState = {
    files: [],
    processedFiles: [],
}

export const uploaderSlice = createSlice({
    name: 'uploader',
    initialState,
    reducers: {
        setProcessedFiles: (state, action: PayloadAction<{ guid: string }>) => {
            const fileGuid = action.payload.guid
            if (!state.processedFiles.includes(fileGuid)) {
                const newFiles = state.processedFiles
                newFiles.push(fileGuid)
                state.processedFiles = newFiles
            }
        },
        setFiles: (state, action: PayloadAction<SetFilesPayload>) => {
            const filesOrAction = action.payload

            if (typeof filesOrAction === 'function') {
                state.files = filesOrAction(state.files)
            } else {
                state.files = filesOrAction
            }
        },
        updateFile: (state, action: PayloadAction<UpdateFilePayload>) => {
            state.files = state.files.reduce((result, current) => {
                if (current.id === action.payload.id) {
                    result.push({
                        ...current,
                        ...action.payload,
                        state: {
                            ...current.state,
                            ...action.payload.state,
                        },
                        transferFile: current.transferFile
                            ? {
                                  ...current.transferFile,
                                  status: action.payload.state?.startedProcessing
                                      ? TransferStatus.inProgress
                                      : current.transferFile.status,
                              }
                            : undefined,
                    })
                } else {
                    result.push(current)
                }

                return result
            }, [] as ExtendedFiles)
        },
    },
})

export const { setFiles, updateFile, setProcessedFiles } = uploaderSlice.actions

export const selectUploaderState = (state: RootState) => state.uploader
export const selectedStateFiles = createSelector([(state: RootState) => state.uploader.files], (files) =>
    files.filter((file: ExtendedFile) => file.state?.selected),
)

export * from './types'

export const uploader = uploaderSlice.reducer
