import { SxProps, Theme } from '@mui/material'

const formSection: SxProps<Theme> = (theme) => ({
    backgroundColor: theme.palette.background.cardsEnabled,
    border: `1px solid ${theme.palette.background.cardsHover}`,
    padding: '5px',
    margin: '20px 0',
    borderRadius: '15px',
})

export default {
    formSection,
}
