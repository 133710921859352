import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useRemoveClosedCaptionsMutation } from 'api/mutations'
import { useVideoQuery } from 'api/queries'
import useSvIds from 'design/pages/SmartVid/hooks/useSvIds'
import { Modal } from 'design/templates/Modal'
import route from 'constants/routes'

import locale from './ClosedCaptionsDeleteModal.locale'

type ClosedCaptionsDeleteModalProps = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

export const ClosedCaptionsDeleteModal = ({ isOpen, setIsOpen }: ClosedCaptionsDeleteModalProps) => {
    const { smartVidId, videoId } = useSvIds()
    const { data } = useVideoQuery(videoId)
    const navigate = useNavigate()
    const { mutateAsync: removeClosedCaptionsMutateAsync, isLoading } = useRemoveClosedCaptionsMutation({
        onSuccess: () => {
            navigate(
                smartVidId
                    ? route.smartVideo.step({ smartVidId, stepVidId: videoId })
                    : route.video.settings.byId({ videoGuid: String(videoId) }),
            )
        },
    })

    const handleDelete = useCallback(async () => {
        if (!data?.video) return

        try {
            await removeClosedCaptionsMutateAsync(data?.video)
        } catch (e) {
            Sentry.captureException(e)
        } finally {
            setIsOpen(false)
        }
    }, [data?.video])

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)} stackProps={{ sx: { minHeight: 190 } }} width="sm">
            <Modal.Header onClose={() => setIsOpen(false)} title={locale.title} />
            <Modal.Body>
                <Typography>{locale.body}</Typography>
            </Modal.Body>
            <Modal.Actions>
                <Button variant="text" onClick={() => setIsOpen(false)}>
                    {locale.cancel}
                </Button>
                <LoadingButton variant="contained" color="error" onClick={handleDelete} loading={isLoading}>
                    {locale.confirm}
                </LoadingButton>
            </Modal.Actions>
        </Modal>
    )
}

export default ClosedCaptionsDeleteModal
