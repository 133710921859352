import { Box, Typography } from '@mui/material'

import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { PLAYBACK_SMART_PAUSE, PLAYBACK_SMART_PAUSE_RESUME } from 'design/pages/VidSettings/constants'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { SmartPauseFormData } from 'types/VidSettings'

import locale from './SmartPauseContent.locale'

export const SmartPauseContent = () => {
    const { showAutoplayOnReturn } = useFeatureFlags()

    const {
        isLoading,
        formik: { values, setValues },
        onChange,
    } = useSettingsSectionContext<SmartPauseFormData>()

    const commonProps = {
        disabled: isLoading,
        switchProps: { size: 'small' },
    } as const

    return (
        <Box className="PlayOptionsContent">
            <Box pr={5} pb={2}>
                <SwitchForm
                    sx={style.container}
                    name={PLAYBACK_SMART_PAUSE}
                    label={
                        <Typography className="radioTitle radioTitle--without-mb" variant="body2">
                            {locale.title}
                        </Typography>
                    }
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    helpInfo={locale.tooltip}
                    onChange={(_, value) => {
                        setValues({
                            ...values,
                            [PLAYBACK_SMART_PAUSE]: value,
                            ...(showAutoplayOnReturn ? { [PLAYBACK_SMART_PAUSE_RESUME]: value } : {}),
                        })

                        onChange()
                    }}
                    {...commonProps}
                />
            </Box>
            <Box className="wrapper">
                {showAutoplayOnReturn && values[PLAYBACK_SMART_PAUSE] && (
                    <Box ml={4}>
                        <SwitchForm
                            sx={style.container}
                            name={PLAYBACK_SMART_PAUSE_RESUME}
                            label={<Typography variant="body2">{locale.autoplayOnReturn.title}</Typography>}
                            tooltipPlacement={SwitchTooltipPlacement.inside}
                            helpInfo={locale.autoplayOnReturn.tooltip}
                            afterChange={onChange}
                            {...commonProps}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    )
}
