import { Box, ListItem, Typography } from '@mui/material'

import CheckmarkIcon from 'assets/img/freemium/checkmarks/checkmark-blue-bold.svg'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'

import './index.scss'

const statsFeatures = [
    'Video Marketing Analytics',
    'Conversions',
    'Integrations',
    'Create Email Gates',
    'Create Tags ',
    'Integrations',
]

const StatsLockedContent = () => {
    return (
        <Box className="StatsLockedContent">
            <Box className="features">
                {statsFeatures.map((label) => (
                    <ListItem className="featuresItem" key={label}>
                        <img src={CheckmarkIcon} />
                        <Typography>{label}</Typography>
                    </ListItem>
                ))}
            </Box>

            <Box className="picture">
                <img src="/img/freemium/stats-page-preview.png" alt="Stats Page Preview" />
            </Box>
        </Box>
    )
}

export default withErrorBoundary(StatsLockedContent)
