import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Stack } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'

import { useSmartVidQuery, useVideoQuery } from 'api/queries'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { GlobalSettingsFormProvider } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import PlayerThemeSwitch from 'design/organisms/VidSettingsSidebar/StyleSettings/PlayerThemeSection/PlayerThemeSwitch'
import PlayerThemeColors from 'design/organisms/VidSettingsSidebar/StyleSettings/PlayerThemeSection/PlayerThemeColors'
import PlayerThemeStyle, {
    PLAYER_THEME_STYLE_BORDERRADIUS,
} from 'design/organisms/VidSettingsSidebar/StyleSettings/PlayerThemeSection/PlayerThemeStyle'
import { THEME_SECTION_INITIAL_VALUES, UI_THEME_STYLE_BORDER_RADIUS } from 'design/pages/VidSettings/constants'
import { ThemeSectionFormData } from 'types/VidSettings'
import { VideoDetails } from 'types/Video'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const PlayerThemeSection = ({ resetForm, isSmartVidGlobalSettings }: FormikProps<ThemeSectionFormData> & Props) => {
    const { videoGuid, smartVidId } = useParams()

    const { data: smartVid } = useSmartVidQuery(String(smartVidId), {
        enabled: Boolean(smartVidId),
    })

    const initForm = (details: VideoDetails) => {
        if (isSmartVidGlobalSettings) return
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoGuid || smartVid?.video.guid, {
        onSuccess: initForm,
    })

    const Provider = useMemo(
        () => (isSmartVidGlobalSettings ? GlobalSettingsFormProvider : SettingsSectionProvider),
        [isSmartVidGlobalSettings],
    )

    return (
        <Provider>
            <Form>
                <Stack mb={4} gap={4} divider={<Divider />}>
                    <PlayerThemeSwitch isSmartVidGlobalSettings={isSmartVidGlobalSettings} />
                    <PlayerThemeColors isSmartVidGlobalSettings={isSmartVidGlobalSettings} />
                    <PlayerThemeStyle isSmartVidGlobalSettings={isSmartVidGlobalSettings} />
                </Stack>
            </Form>
        </Provider>
    )
}

const FormikWrapper = ({ isSmartVidGlobalSettings }: Props) => {
    const validationSchema = yup.object().shape({
        [UI_THEME_STYLE_BORDER_RADIUS]: yup
            .number()
            .required(`Required. Permitted max value is ${PLAYER_THEME_STYLE_BORDERRADIUS.MAX}.`)
            .min(0, `Permitted min value is ${PLAYER_THEME_STYLE_BORDERRADIUS.MIN}.`)
            .max(16, `Permitted max value is ${PLAYER_THEME_STYLE_BORDERRADIUS.MAX}.`),
    })

    return (
        <Formik<ThemeSectionFormData>
            initialValues={THEME_SECTION_INITIAL_VALUES}
            validationSchema={validationSchema}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={() => {}}
        >
            {(props) => <PlayerThemeSection {...props} isSmartVidGlobalSettings={isSmartVidGlobalSettings} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
