/* eslint-disable @typescript-eslint/no-var-requires */
import { Suspense } from 'react'

import lazyImport from 'utils/renderers/lazyImport'
import { ScreenLoader } from 'design/atoms/ScreenLoader'

const RoutesProtected = lazyImport(() => import('App/Routes/RoutesProtected'))

function RoutesProtectedLazy() {
    return (
        <Suspense fallback={<ScreenLoader />}>
            <RoutesProtected />
        </Suspense>
    )
}

export default RoutesProtectedLazy
