import { UseQueryOptions, useQuery } from 'react-query'

import { settingsAPI } from 'api/controllers'
import { SMART_VID_TAGS_QUERY } from 'api/constants'
import { SmartVidTagsConfig } from 'types/VideoTag'
import { VideoGuid } from 'types/Video'

export const useSmartVidTagsQuery = (guid?: VideoGuid, options?: UseQueryOptions<SmartVidTagsConfig, unknown>) =>
    useQuery<SmartVidTagsConfig>([SMART_VID_TAGS_QUERY, guid], () => settingsAPI.getSmartVidTags(guid!), {
        enabled: !!guid,
        ...options,
    })

export default useSmartVidTagsQuery
