import { Box, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'

import { Feature } from 'design/templates/Modal/modalTemplates/SubscriptionModal/Feature'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import useTariffPlans from 'hooks/user/useTariffPlans'
import { OnDowngrade } from 'design/templates/Modal/modalTemplates/SubscriptionModal/SelectPlanStep/hooks'
import style from './FreePlan.style'

interface FreePlanProps {
    onDowngrade: OnDowngrade
}

export const FreePlan = ({ onDowngrade }: FreePlanProps) => {
    const { t } = useTranslation()
    const { free: freePlan } = useTariffPlans()
    const { isFree } = useAccountSubscription()

    return (
        <Stack sx={[style.root, isFree && style.currentPlan]} gap={6} p={6}>
            <Box>
                <Typography variant="h6" fontWeight={600}>
                    {freePlan?.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" mt={1}>
                    {t(freePlan?.subtitle || 'Try your ideas with our high-performing video player at no cost')}
                </Typography>
                <Stack gap={4} direction="row" mt={5}>
                    {freePlan?.features.map((feature) => (
                        <Feature key={feature} featureTitle={feature} />
                    ))}
                </Stack>
            </Box>
            <LoadingButton
                sx={{ pointerEvents: isFree ? 'none' : 'auto' }}
                variant={isFree ? 'text' : 'outlined'}
                color="tertiary"
                loading={!freePlan}
                onClick={isFree ? undefined : () => onDowngrade(freePlan!)}
            >
                {isFree ? t('Current Plan') : t('Downgrade')}
            </LoadingButton>
        </Stack>
    )
}
