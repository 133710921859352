import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useSearchParams, useNavigate, Navigate, useMatch } from 'react-router-dom'
import { LicenseInfo } from '@mui/x-license'

import RoutesAuth from 'App/Routes/RoutesAuth'
import RoutesProtectedLazy from 'App/Routes/RoutesProtectedLazy'
import Layout from 'design/templates/Layout'
import useManageIntercom from 'hooks/system/useManageIntercom'
import { ScreenLoader } from 'design/atoms/ScreenLoader'
import init3rdPartyServices from 'thirdPartyServices'
import { useCustomerQuery, queryClient } from 'api/queries'

import { PATHNAMES_WITHOUT_LAYOUT } from 'App'
import { usePlanFromParameter } from 'hooks/system/usePlanFromParameter'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { route } from 'constants/routes'
import { ACCESS_TOKEN, REFRESH_TOKEN, COOKIES_OPTIONS_AUTH, REFERRER } from 'constants/cookies.constants'
import { VIDALYTICS_DOMAIN } from 'constants/environment.constants'

LicenseInfo.setLicenseKey(String(process.env.REACT_APP_MUI_PRO_LICENSE_KEY))

const AUTH_ROUTES = [
    route.auth.signIn,
    route.auth.microsoftAuth,
    route.auth.signUp.index,
    route.auth.forgotPassword,
    route.auth.resetPassword.index,
]

export const App = () => {
    useManageIntercom()

    const [cookies, setCookie, removeCookie] = useCookies()
    const [searchParams] = useSearchParams()
    const challengeParam = searchParams.get('login_challenge')
    const isUpsell = Boolean(useMatch(route.upsell()))
    const isPaymentProcessing = Boolean(useMatch(route.account.paymentProcessing))
    const isUpdatePayment = Boolean(useMatch(route.account.updatePayment))

    const location = useLocation()
    const navigate = useNavigate()
    const hasAuthCookies = Boolean(cookies[ACCESS_TOKEN] || cookies[REFRESH_TOKEN])
    const { data: customer, isLoading: isCustomerLoading, isFetching: isCustomerFetching } = useCustomerQuery()
    const { isFake, isLoading: isSubscriptionLoading, isFetching: isSubscriptionFetching } = useAccountSubscription()

    const isLoading = isCustomerLoading || isSubscriptionLoading
    const isFetching = isCustomerFetching || isSubscriptionFetching

    useEffect(() => {
        const appReferrer = document.referrer
        if (!appReferrer) return

        const appReferrerUrl = new URL(appReferrer)
        const appReferrerDomain = appReferrerUrl.hostname.replace('www.', '')

        if (appReferrerDomain !== VIDALYTICS_DOMAIN) {
            setCookie(REFERRER, decodeURI(appReferrer), {
                path: route.home,
                domain: 'vidalytics.com',
                maxAge: 60 * 60 * 24 * 60,
            })
        }
    }, [])

    useEffect(() => {
        if (challengeParam) {
            queryClient.clear()
            removeCookie(REFRESH_TOKEN, COOKIES_OPTIONS_AUTH)
            removeCookie(ACCESS_TOKEN, COOKIES_OPTIONS_AUTH)
        }
    }, [challengeParam])

    init3rdPartyServices()
    usePlanFromParameter()

    if ((isLoading || isFetching) && customer === undefined && hasAuthCookies) {
        return <ScreenLoader />
    }

    if (isFake && !isPaymentProcessing && !isUpsell && !isUpdatePayment) {
        return <Navigate to={`${route.account.paymentProcessing}${location.search}${location.hash}`} replace />
    }

    const isAuth =
        !customer?.email ||
        customer === null ||
        PATHNAMES_WITHOUT_LAYOUT.some((pathname) => location.pathname.startsWith(pathname))

    if (!isAuth && AUTH_ROUTES.find((item) => location.pathname?.includes(item))) {
        navigate(route.home)
        return null
    }

    return (
        <>
            {isAuth && <RoutesAuth />}
            {!isAuth && (
                <Layout>
                    <RoutesProtectedLazy />
                </Layout>
            )}
        </>
    )
}
