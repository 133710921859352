import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Button, useTheme } from '@mui/material'
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material'

import { AstronautWaitingIcon, AstronautFishingIcon } from 'assets/index'
import useFolderChildrenWithPath from 'design/organisms/MyVids/hooks/useFolderChildrenWithPath'
import useVideosWithFolders from 'design/organisms/MyVids/hooks/useVideosWithFolders'
import { filterByTitle } from 'design/organisms/MyVids/MyVids.utils'
import route from 'constants/routes'

export const useMyVidsEmptyState = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { palette } = useTheme()
    const [isEmptyState, setIsEmptyState] = useState<boolean>(false)
    const { isLoading, search, fullList, isError, videosWithFolders } = useVideosWithFolders()
    const { path, children } = useFolderChildrenWithPath(videosWithFolders)

    useEffect(() => {
        if (isLoading && !isEmptyState) {
            setIsEmptyState(false)
        }

        if (isEmptyState) {
            return
        }

        if (children.length === 0 || fullList.length === 0) {
            setIsEmptyState(true)
        }
    }, [path, children, fullList])

    const handleUploadClick = () => {
        navigate(route.upload)
    }

    const filteredData = useMemo(() => filterByTitle(fullList || [], search), [fullList, search])

    if (!isEmptyState) {
        return null
    }

    if (isError) {
        return {
            description: (
                <Box sx={{ maxWidth: 420 }}>
                    <Box>
                        {t(
                            `We're currently resolving issues with retrieving data, and some information may be missing.`,
                        )}
                    </Box>
                    <Box>{t(`We're working now to fix this and will have everything back to normal soon.`)}</Box>
                </Box>
            ),
            icon: (
                <AstronautFishingIcon
                    style={{
                        width: 128,
                        height: 128,
                        fill: palette.primary.light,
                    }}
                />
            ),
        }
    }

    if (search && filteredData && !filteredData.length) {
        return {
            description: (
                <Box sx={{ maxWidth: 420 }}>
                    <Box>{t('Sorry, no videos found')}</Box>
                    <Box>{t('Try different search terms.')}</Box>
                </Box>
            ),
            icon: (
                <AstronautFishingIcon
                    style={{
                        width: 128,
                        height: 128,
                        fill: palette.primary.light,
                    }}
                />
            ),
        }
    }

    const description =
        path.length === 0 && children.length === 0
            ? t('This is where you can manage your videos, and access essential tools and settings')
            : t('This folder is currently empty.')

    return {
        description: <Box sx={{ maxWidth: 420 }}>{description}</Box>,
        icon: (
            <AstronautWaitingIcon
                style={{
                    width: 128,
                    height: 128,
                    fill: palette.primary.light,
                }}
            />
        ),
        controls: (
            <Button
                onClick={handleUploadClick}
                startIcon={<CloudUploadIcon fontSize="small" />}
                variant="contained"
                color="secondary"
                size="medium"
            >
                {t('Upload video')}
            </Button>
        ),
    }
}

export default useMyVidsEmptyState
