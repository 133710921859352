import { SxProps, Theme } from '@mui/material'
import { getPropByTheme } from 'styles/theme/utils'

const wrapper =
    (redesigned = false): SxProps<Theme> =>
    (theme) => ({
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 4,
        borderRadius: 4,
        background: theme.palette.background.paper,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: getPropByTheme('#1B24321F', '#FFF', theme.palette.mode),
        mb: 6,
        mx: redesigned ? 8 : 6,
    })

const icon: SxProps<Theme> = {
    fontSize: 60,
}

const textWrapper: SxProps<Theme> = {
    flexDirection: 'column',
    gap: 5,
    maxWidth: 357,
    textAlign: 'center',
}

const textTypography: SxProps<Theme> = {
    fontWeight: 700,
    fontSize: 14,
}

export default {
    wrapper,
    icon,
    textWrapper,
    textTypography,
}
