import { Box, Button, Divider, Typography } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useDeleteThumbnailMutation, useUpdateThumbnailMutation, useUpdateVideoMutation } from 'api/mutations'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import locale from 'design/organisms/VidSettingsSidebar/StyleSettings/StyleSettings.locale'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import {
    UI_OVERLAY_PAUSE_SOURCE,
    UI_OVERLAY_MULTI_PAUSE,
    UI_OVERLAY_PAUSE_ENABLED,
    UI_THUMBNAIL_DEFAULT_FROM,
    UI_THUMBNAIL_DEFAULT_TO,
    UI_THUMBNAIL_DEFAULT_TYPE,
    UI_THUMBNAIL_FORCE_DEFAULT,
    UI_THUMBNAIL_FULL_SIZE,
    UI_THUMBNAIL_MOBILE_FROM,
    UI_THUMBNAIL_MOBILE_SOURCE,
    UI_THUMBNAIL_MOBILE_TO,
    UI_THUMBNAIL_MOBILE_TYPE,
    UI_THUMBNAIL_VIDEO_GUID,
    UI_THUMBNAIL_MOBILE_VIDEO_GUID,
} from 'design/pages/VidSettings/constants'
import { useVidSettingsContext } from 'design/pages/VidSettings/vidSettingsContext'
import { SubscriptionCtaSource } from 'design/templates/Modal'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import useVideoPolling from 'hooks/system/useVideoPolling'
import { ThumbnailSource, ThumbnailsSectionFormData, ThumbnailTimeValues } from 'types/VidSettings'
import { ThumbnailType, VideoThumbGeneration } from 'types/Video'
import { ImageUploadPause } from 'design/organisms/VidSettingsSidebar/StyleSettings/ThumbnailsSection/ThumbnailsContent/ImageUploaderPause/ImageUploaderPause'
import { useUpdateThumbnailExitTimeMutation } from 'api/mutations/settings/useUpdateThumbnailExitTimeMutation'
import Tooltip from '@mui/material/Tooltip'
import { timeToSeconds } from 'utils/dates'
import { useVideoQuery } from 'api/queries'
import { useAppSelector } from 'App'
import { selectVideoSettingsState } from 'design/pages/VidSettings/VidSettings.slice'
import { FeatureGroup, VidSettingsKey } from 'constants/keys/featuresAccess.constants'

import { ImageUpload } from './ImageUploader/ImageUploader'

interface ThumbnailsContentProps {
    isSmartVidGlobalSettings?: boolean
}

export const ThumbnailsContent = ({ isSmartVidGlobalSettings }: ThumbnailsContentProps) => {
    const { videoGuid } = useParams()
    const { multipleExitThumbnails } = useFeatureFlags()
    const {
        setThumbnailFromVideoType,
        setThumbnailSource: setContextThumbnailSource,
        setThumbnailPauseGuid,
        pauseThumbnails,
        setPauseThumbnails,
    } = useVidSettingsContext()
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )
    const { video, onChange } = useSectionContext<ThumbnailsSectionFormData>()
    const initRef = useRef<boolean>(false)
    const { mutate, mutateAsync, isLoading } = useUpdateVideoMutation(String(video?.video?.guid || videoGuid))
    const [isTheSameMobileThumb, setIsTheSameMobileThumb] = useState<boolean>(false)
    const { selectedVideoGuid } = useAppSelector(selectVideoSettingsState)
    const [isExitThumbnail, setIsExitThumbnail] = useState<boolean>(false)
    const isExitThumbnailFromVideo = useMemo(() => !!video?.settings[UI_OVERLAY_PAUSE_SOURCE], [video])
    const isExitThumbnailEnabled = useMemo(() => !!video?.settings[UI_OVERLAY_PAUSE_ENABLED], [video])
    const desktopImageOtherVideo = video?.settings[UI_THUMBNAIL_VIDEO_GUID]
    const mobileImageOtherVideo = video?.settings[UI_THUMBNAIL_MOBILE_VIDEO_GUID]
    const { data: otherVideoData } = useVideoQuery(desktopImageOtherVideo!, { enabled: !!desktopImageOtherVideo })
    const { data: otherMobileVideoData } = useVideoQuery(mobileImageOtherVideo!, { enabled: !!mobileImageOtherVideo })
    useEffect(() => {
        if (!video) return
        if (initRef.current !== true) {
            setIsTheSameMobileThumb(
                video?.settings[UI_THUMBNAIL_FORCE_DEFAULT] ||
                    (video?.settings[UI_THUMBNAIL_MOBILE_TYPE] === ThumbnailSource.image &&
                        video?.settings[UI_THUMBNAIL_DEFAULT_TYPE] === ThumbnailSource.image &&
                        video?.settings.ui_thumbnail_default_source === video?.settings?.ui_thumbnail_mobile_source),
            )
            initRef.current = true
        }
        const multiPause = video?.settings[UI_OVERLAY_MULTI_PAUSE] ?? {}

        const multiPauseArray = Object.keys(multiPause).map((guid) => {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { to, guid: _guid, ...rest } = multiPause[guid]
            return {
                guid,
                to: to !== 0 && to ? to : timeToSeconds(video?.video?.length || '0'),
                ...rest,
            }
        })
        setPauseThumbnails(multiPauseArray ?? [])
    }, [video])

    const { videoThumbnails } = useFeatureFlags()
    const setTheSameMobileThumb = async (same: boolean) => {
        if (!video) return
        setIsTheSameMobileThumb(same)
        if (videoThumbnails) {
            await mutateAsync({
                ...video,
                settings: {
                    ...video.settings,
                    [UI_THUMBNAIL_FORCE_DEFAULT]: same,
                },
            })
        }
        if (
            video?.settings[UI_THUMBNAIL_DEFAULT_TYPE] === ThumbnailSource.image &&
            video?.settings[UI_THUMBNAIL_MOBILE_TYPE] === ThumbnailSource.image &&
            same
        ) {
            deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: ThumbnailType.mobileThumbnail })
        }
    }

    const setThumbnailSource = async ({
        type,
        value,
        preventDbUpdate,
    }: {
        type: ThumbnailType
        value?: ThumbnailSource
        preventDbUpdate?: boolean
    }) => {
        if (!video || !value) return
        setContextThumbnailSource(value)
        if (preventDbUpdate) return
        if (type === ThumbnailType.thumbnail) {
            await mutateAsync({
                ...video,
                settings: {
                    ...video.settings,
                    [UI_THUMBNAIL_DEFAULT_TYPE]: value,
                    [UI_THUMBNAIL_DEFAULT_FROM]:
                        value === ThumbnailSource.video && !video.settings[UI_THUMBNAIL_DEFAULT_FROM]
                            ? 0
                            : video.settings[UI_THUMBNAIL_DEFAULT_FROM],
                    [UI_THUMBNAIL_DEFAULT_TO]:
                        value === ThumbnailSource.video && !video.settings[UI_THUMBNAIL_DEFAULT_TO]
                            ? 10
                            : video.settings[UI_THUMBNAIL_DEFAULT_TO],
                },
            })
        } else if (type === ThumbnailType.mobileThumbnail) {
            await mutateAsync({
                ...video,
                settings: {
                    ...video.settings,
                    [UI_THUMBNAIL_MOBILE_TYPE]: value,
                    [UI_THUMBNAIL_MOBILE_FROM]:
                        value === ThumbnailSource.video && !video.settings[UI_THUMBNAIL_MOBILE_FROM]
                            ? 0
                            : video.settings[UI_THUMBNAIL_MOBILE_FROM],

                    [UI_THUMBNAIL_MOBILE_TO]:
                        value === ThumbnailSource.video && !video.settings[UI_THUMBNAIL_MOBILE_TO]
                            ? 10
                            : video.settings[UI_THUMBNAIL_MOBILE_TO],
                },
            })
        }
    }

    const setInputTime = (newTimeValues: ThumbnailTimeValues) => {
        if (!video) return

        mutate({
            ...video,
            settings: {
                ...video.settings,
                ...newTimeValues,
            },
        })
    }

    const { isLoading: isThumbnailUpdating, mutate: updateThumbnail } = useUpdateThumbnailMutation()
    const updateThumbnailExitTimeMutation = useUpdateThumbnailExitTimeMutation()
    const deleteThumbnailMutation = useDeleteThumbnailMutation()

    const videoThumbnailGeneration = useMemo(() => video?.video.videoThumbnailGeneration, [video])

    const thumbnailImage = useMemo(
        () => (otherVideoData ? otherVideoData.video.thumbnail.src : video?.video.thumbnail.src),
        [video?.video.thumbnail.src, video?.video.thumbnailType, otherVideoData],
    )
    const mobileImage = useMemo(
        () =>
            otherMobileVideoData
                ? otherMobileVideoData?.settings.ui_thumbnail_mobile_source
                : video?.settings.ui_thumbnail_mobile_source,
        [video, otherMobileVideoData],
    )
    const isSapEnabled = useMemo(() => video?.settings.playback_autoplay_enabled, [video])
    useVideoPolling(video?.video.guid as string)

    const handleFileUpload = ({
        data,
        id,
        guid,
        from,
        to,
    }: {
        data: File
        id: string
        guid?: string
        from?: number
        to?: number
    }) => {
        if (!video) {
            return
        }
        updateThumbnail({
            video: video.video,
            file: data,
            thumbnailType: id as ThumbnailType,
            guid,
            from,
            to,
        })
    }
    const handleExitTimeUpload = ({ timeFrom, timeTo, guid }: { timeFrom: number; timeTo: number; guid?: string }) => {
        if (!video) {
            return
        }
        updateThumbnailExitTimeMutation.mutate({ timeFrom, timeTo, guid })
    }

    const handleExitThumbnailSwitch = async (value: boolean) => {
        if (!video) {
            return
        }
        await mutateAsync({
            ...video,
            settings: {
                ...video.settings,
                [UI_OVERLAY_PAUSE_ENABLED]: value,
            },
        })
    }

    useEffect(() => {
        setIsExitThumbnail(isExitThumbnailFromVideo)
    }, [isExitThumbnailFromVideo])

    const handleRemoveExitThumbnail = (name: string, value: boolean) => {
        if (name === UI_OVERLAY_PAUSE_SOURCE && !value) {
            if (!video) return
            if (!video?.settings[UI_OVERLAY_PAUSE_SOURCE]) {
                setIsExitThumbnail(false)
                return
            }
            deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: ThumbnailType.customPauseScreen })
            setIsExitThumbnail(false)
        } else {
            setIsExitThumbnail(true)
        }
    }

    const handleDelete = async (id: string, sourceType?: ThumbnailSource, guid?: string) => {
        if (!video) {
            return
        }
        if (sourceType === 'video') {
            if (id === ThumbnailType.thumbnail) {
                await mutateAsync({
                    ...video,
                    settings: {
                        ...video.settings,
                        [UI_THUMBNAIL_DEFAULT_FROM]: 0,
                        [UI_THUMBNAIL_DEFAULT_TO]: 0,
                        [UI_THUMBNAIL_VIDEO_GUID]: null,
                    },
                })
            } else if (id === ThumbnailType.mobileThumbnail) {
                await mutateAsync({
                    ...video,
                    settings: {
                        ...video.settings,
                        [UI_THUMBNAIL_MOBILE_FROM]: 0,
                        [UI_THUMBNAIL_MOBILE_TO]: 0,
                        [UI_THUMBNAIL_MOBILE_VIDEO_GUID]: null,
                    },
                })
            }
            return
        } else {
            if (id === ThumbnailType.thumbnail) {
                deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: ThumbnailType.thumbnail })
                if (video?.settings.ui_thumbnail_mobile_source === video?.video.thumbnail.previewSrc) {
                    deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: ThumbnailType.mobileThumbnail })
                    setTheSameMobileThumb(true)
                }
            } else {
                deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: id as ThumbnailType, guid })
            }
        }
    }

    const defaultThumbnailTime = useMemo(
        () => ({
            from: video?.settings[UI_THUMBNAIL_DEFAULT_FROM],
            to: video?.settings[UI_THUMBNAIL_DEFAULT_TO],
        }),
        [video],
    )

    const mobileThumbnailTime = useMemo(
        () => ({
            from: video?.settings[UI_THUMBNAIL_MOBILE_FROM],
            to: video?.settings[UI_THUMBNAIL_MOBILE_TO],
        }),
        [video],
    )

    return (
        <Section mb={4}>
            <ImageUpload
                id={ThumbnailType.thumbnail}
                title={locale.uploadThumbnailTitle}
                image={thumbnailImage}
                tooltip={locale.thumbnailTooltip}
                disabled={isLoading}
                imageValidateSize={{ min: 1000, max: 2e6 }}
                onUpload={handleFileUpload}
                onDelete={handleDelete}
                isSapEnabled={isSapEnabled}
                setThumbnailSource={setThumbnailSource}
                setInputTime={setInputTime}
                setThumbnailFromVideoType={setThumbnailFromVideoType}
                thumbnailSourceType={
                    (video?.settings[UI_THUMBNAIL_DEFAULT_TYPE] ?? ThumbnailSource.image) as ThumbnailSource
                }
                time={defaultThumbnailTime}
                videoLength={video?.video?.length}
                otherVideoName={otherVideoData?.video.title}
                otherVideoGuid={selectedVideoGuid}
            />
            <Box className="switchContainer">
                <SwitchForm
                    sx={style.switchContainer}
                    name={UI_THUMBNAIL_MOBILE_SOURCE}
                    label={<Typography variant="body2">{locale.mobileSwitcher}</Typography>}
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    onChange={() => setTheSameMobileThumb(!isTheSameMobileThumb)}
                    labelPlacement="start"
                    disabled={videoThumbnailGeneration === VideoThumbGeneration.pending || isLoading}
                    switchProps={{ size: 'small' }}
                    value={isTheSameMobileThumb}
                />
            </Box>
            {!isTheSameMobileThumb && (
                <ImageUpload
                    id={ThumbnailType.mobileThumbnail}
                    title={locale.mobileThumbTitle}
                    image={mobileImage}
                    isDeletable={true}
                    tooltip={locale.mobileThumbHelpText}
                    imageValidateSize={{ min: 1000, max: 2e6 }}
                    disabled={isLoading}
                    onUpload={handleFileUpload}
                    onDelete={handleDelete}
                    setThumbnailSource={setThumbnailSource}
                    setInputTime={setInputTime}
                    isSapEnabled={isSapEnabled}
                    setThumbnailFromVideoType={setThumbnailFromVideoType}
                    thumbnailSourceType={
                        (video?.settings[UI_THUMBNAIL_MOBILE_TYPE] ?? ThumbnailSource.image) as ThumbnailSource
                    }
                    time={mobileThumbnailTime}
                    videoLength={video?.video?.length}
                    otherVideoName={otherMobileVideoData?.video.title}
                    otherVideoGuid={selectedVideoGuid}
                />
            )}
            <Divider />

            {multipleExitThumbnails ? (
                <>
                    <Box className="switchContainer">
                        <SwitchForm
                            sx={style.switchContainer}
                            name={UI_OVERLAY_PAUSE_ENABLED}
                            disabled={isLoading}
                            label={
                                <>
                                    <Typography variant="body1" fontWeight={600}>
                                        {locale.exitThumbnailLabel}
                                    </Typography>
                                    <HelpTooltip title={locale.exitThumbnailHelpText} />
                                </>
                            }
                            onChange={(_, value) => handleExitThumbnailSwitch(value)}
                            tooltipPlacement={SwitchTooltipPlacement.inside}
                            labelPlacement="start"
                            switchProps={{ size: 'small' }}
                            featureGroup={FeatureGroup.VidSettings}
                            featureKey={VidSettingsKey.ExitThumbnail}
                            upgradeSource={SubscriptionCtaSource.VID_SETTINGS}
                        />
                    </Box>

                    <Box sx={{ display: isExitThumbnailEnabled ? 'block' : 'none' }}>
                        {pauseThumbnails?.map((pause, index) => (
                            <ImageUploadPause
                                key={pause.guid}
                                guid={pause.guid}
                                title=""
                                id={ThumbnailType.customPauseScreen}
                                image={pause.source}
                                imageValidateSize={{ min: 1000, max: 2e6 }}
                                disabled={isLoading}
                                onUpload={handleFileUpload}
                                onUploadExitTime={handleExitTimeUpload}
                                onDelete={handleDelete}
                                thumbnailIndex={index}
                                setThumbnailSource={setThumbnailSource}
                                setThumbnailFromVideoType={setThumbnailFromVideoType}
                                setThumbnailPauseGuid={setThumbnailPauseGuid}
                                videoLength={video?.video?.length}
                                time={{
                                    from: pause.from,
                                    to: pause.to,
                                }}
                            />
                        ))}

                        {pauseThumbnails?.length >= 10 ? (
                            <Tooltip arrow placement="bottom" title="You can’t add more than 10 exit thumbnails.">
                                <Box sx={{ width: '50%', my: 2 }}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        disabled={
                                            isThumbnailUpdating ||
                                            pauseThumbnails.some((el) => el.guid === '') ||
                                            pauseThumbnails.length >= 10
                                        }
                                        onClick={() =>
                                            setPauseThumbnails([...pauseThumbnails, { guid: '', source: '' }])
                                        }
                                    >
                                        + Exit Thumbnail
                                    </Button>
                                </Box>
                            </Tooltip>
                        ) : (
                            <Button
                                sx={{ width: '50%', my: 2 }}
                                variant="contained"
                                color="secondary"
                                disabled={
                                    isThumbnailUpdating ||
                                    pauseThumbnails.some((el) => el.guid === '') ||
                                    pauseThumbnails.length >= 10
                                }
                                onClick={() =>
                                    setPauseThumbnails([
                                        ...pauseThumbnails,
                                        {
                                            guid: '',
                                            source: '',
                                            from: 0,
                                            to: timeToSeconds(video?.video?.length || '0'),
                                        },
                                    ])
                                }
                            >
                                + Exit Thumbnail
                            </Button>
                        )}
                    </Box>
                </>
            ) : (
                <>
                    <Box className="switchContainer">
                        <SwitchForm
                            sx={style.switchContainer}
                            name={UI_OVERLAY_PAUSE_SOURCE}
                            disabled={isLoading}
                            label={
                                <>
                                    <Typography variant="body1" fontWeight={600}>
                                        {locale.exitThumbnailLabel}
                                    </Typography>
                                    <HelpTooltip title={locale.exitThumbnailHelpText} />
                                </>
                            }
                            tooltipPlacement={SwitchTooltipPlacement.inside}
                            onChange={handleRemoveExitThumbnail}
                            labelPlacement="start"
                            switchProps={{ size: 'small' }}
                            value={isExitThumbnail}
                            featureGroup={FeatureGroup.VidSettings}
                            featureKey={VidSettingsKey.ExitThumbnail}
                            upgradeSource={SubscriptionCtaSource.VID_SETTINGS}
                        />
                    </Box>
                    {isExitThumbnail && (
                        <ImageUpload
                            title=""
                            id={ThumbnailType.customPauseScreen}
                            image={video?.settings[UI_OVERLAY_PAUSE_SOURCE]}
                            imageValidateSize={{ min: 1000, max: 2e6 }}
                            disabled={isLoading}
                            onUpload={handleFileUpload}
                            onDelete={handleDelete}
                            setThumbnailSource={setThumbnailSource}
                            setThumbnailFromVideoType={setThumbnailFromVideoType}
                            thumbnailSourceType={ThumbnailSource.image}
                            disableSource
                        />
                    )}
                </>
            )}

            <Divider />
            <Box className="switchContainer">
                <SwitchForm
                    sx={style.switchContainer}
                    name={UI_THUMBNAIL_FULL_SIZE}
                    label={<Typography variant="body2">{locale.strethLabel}</Typography>}
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    helpInfo={locale.stretchThumbnailHelpText}
                    disabled={isLoading}
                    afterChange={onChange}
                    labelPlacement="start"
                    switchProps={{ size: 'small' }}
                />
            </Box>
        </Section>
    )
}
