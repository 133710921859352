import { Form, Formik, FormikProps } from 'formik'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import * as yup from 'yup'

import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    PLAYBACK_ON_END,
    END_VIDEO_ACTION_INITIAL_VALUES,
    UI_OVERLAY_REDIRECT_URL,
} from 'design/pages/VidSettings/constants'
import { EndOfVideoActionFormData } from 'types/VidSettings'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { VideoDetails } from 'types/Video'
import { PlayerOnPlaybackEndType } from 'types/Player'
import { useVideoQuery } from 'api/queries'

import { EndOfVideoActionContent } from './EndOfVideoActionContent'

const EndOfVideoActionSection = ({ resetForm }: FormikProps<EndOfVideoActionFormData>) => {
    const { videoGuid } = useParams()

    const initForm = (details: VideoDetails) => {
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoGuid, {
        onSuccess: initForm,
    })

    return (
        <SettingsSectionProvider>
            <Form>
                <Box className="PlayOptionsSection">
                    <EndOfVideoActionContent />
                </Box>
            </Form>
        </SettingsSectionProvider>
    )
}

const FormikWrapper = () => {
    const PlayOptionsValidationSchema = yup.object({
        [UI_OVERLAY_REDIRECT_URL]: yup.string().when(PLAYBACK_ON_END, {
            is: (playbackOnEnd?: PlayerOnPlaybackEndType) => playbackOnEnd === PlayerOnPlaybackEndType.REDIRECT,
            then: yup
                .string()
                .max(2048, 'Permitted max length is 2048.')
                .required('Required. Permitted max length is 2048.')
                .test('link-protocol', 'Make sure your link contains http:// or https://', (link) =>
                    link ? link?.includes('http://') || link?.includes('https://') : false,
                )
                .url('Please, enter correct url.'),
            otherwise: yup.string(),
        }),
    })

    return (
        <Formik<EndOfVideoActionFormData>
            initialValues={END_VIDEO_ACTION_INITIAL_VALUES}
            validationSchema={PlayOptionsValidationSchema}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={() => {}}
        >
            {(props) => <EndOfVideoActionSection {...props} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
