import { useCallback, useMemo, useState } from 'react'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'

import { Modal } from 'design/templates/Modal'
import CloseButton from 'design/pages/Funnel/VideoSelection/VideoSelector/Modal/CloseButton'
import { useCustomerSubscriptionQuery } from 'api/queries'
import { useUpdateCreditCardByTokenMutation } from 'api/mutations'
import CreditCardForm from 'design/molecules/CreditCardForm'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { ModalDefaultProps } from 'design/templates/Modal/ModalTypes/types'
import locale from './CreditCardModal.locale'

export const CreditCardModal = ({ onClose }: ModalDefaultProps) => {
    const [isSubmitting, setSubmitting] = useState(false)

    const { multiplePaymentMethods } = useFeatureFlags()
    const { data: subscription, isLoading } = useCustomerSubscriptionQuery()
    const updateCreditCardByTokenMutation = useUpdateCreditCardByTokenMutation()

    const onApply = useCallback(async (token: string) => {
        await updateCreditCardByTokenMutation.mutateAsync({
            token,
        })

        onClose?.()
    }, [])

    const creditCard = useMemo(() => {
        if (multiplePaymentMethods) {
            return null
        }

        return subscription?.creditCard || null
    }, [subscription?.creditCard, multiplePaymentMethods])

    const _onClose = () => {
        if (updateCreditCardByTokenMutation.isLoading || isSubmitting) return

        onClose?.()
    }

    return (
        <Modal open onClose={_onClose}>
            <Modal.Header nodeTitle>
                <Typography variant="h7">{creditCard ? locale.edit : locale.add}</Typography>
                <CloseButton
                    onClose={() => {
                        onClose?.()
                        updateCreditCardByTokenMutation.reset()
                    }}
                />
            </Modal.Header>
            <Modal.Body>
                {isLoading && (
                    <Stack direction="row" justifyContent="center">
                        <CircularProgress />
                    </Stack>
                )}
                {!isLoading && (
                    <CreditCardForm
                        isSubmitting={isSubmitting}
                        setSubmitting={setSubmitting}
                        onApply={onApply}
                        creditCard={creditCard}
                        isAddingCreditCard={updateCreditCardByTokenMutation.isLoading}
                        actions={
                            <Button
                                onClick={() => {
                                    onClose?.()
                                    updateCreditCardByTokenMutation.reset()
                                }}
                                variant="outlined"
                                color="tertiary"
                            >
                                {locale.cancel}
                            </Button>
                        }
                    />
                )}
            </Modal.Body>
        </Modal>
    )
}
