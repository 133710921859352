import { SxProps, Theme } from '@mui/material'
import { linearProgressClasses } from '@mui/material/LinearProgress'

const root: SxProps<Theme> = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(258px, 1fr))',
    gap: 4,
}

const progress: SxProps<Theme> = {
    height: 24,
    borderRadius: 2,
    width: 1,

    [`&.${linearProgressClasses.colorPrimary}, &.MuiLinearProgress-colorWarning, &.MuiLinearProgress-colorError`]: {
        backgroundColor: (theme) => theme.palette.divider,
    },
}

const link: SxProps<Theme> = {
    color: 'secondary.main',

    '&:hover': {
        color: 'secondary.main',
        textDecoration: 'underline',
    },
}

export default {
    root,
    progress,
    link,
    paper: {
        p: 4,
        position: 'relative',
    },
} satisfies Record<string, SxProps<Theme>>
