import { Box } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { useParams } from 'react-router-dom'
import _noop from 'lodash/noop'

import { useVideoQuery } from 'api/queries'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { RESUME_PLAY_INITIAL_VALUES } from 'design/pages/VidSettings/constants'
import { VideoDetails } from 'types/Video'
import { ResumePlayFormData } from 'types/VidSettings'

import { ResumePlayContent } from './ResumePlayContent'

const ResumePlaySection = ({ resetForm }: FormikProps<ResumePlayFormData>) => {
    const { videoGuid } = useParams()

    const initForm = (details: VideoDetails) => {
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoGuid, {
        onSuccess: initForm,
    })

    return (
        <SettingsSectionProvider>
            <Form>
                <Box className="PlayOptionsSection">
                    <ResumePlayContent />
                </Box>
            </Form>
        </SettingsSectionProvider>
    )
}

const FormikWrapper = () => {
    return (
        <Formik<ResumePlayFormData> initialValues={RESUME_PLAY_INITIAL_VALUES} onSubmit={_noop}>
            {(props) => <ResumePlaySection {...props} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
