import { SetPlan } from 'design/templates/Modal'
import { SubscriptionSteps } from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import { Plan } from 'types/Customer'
import useFeatureFlags from 'hooks/system/useFeatureFlags'

export type OnDowngrade = (plan: Plan) => void

export const useHandleDowngradeClick = (setSelectedPlan: SetPlan) => {
    const { trialAutoDowngradeAfterTrialWhenUserHasNoCard } = useFeatureFlags()

    const handleDowngradeClick = (plan: Plan) => {
        setSelectedPlan(plan, {
            downgrade: true,
            ...(plan?.isFree || trialAutoDowngradeAfterTrialWhenUserHasNoCard
                ? { step: SubscriptionSteps.CONFIRM_DOWNGRADE }
                : {}),
        })
    }

    return { handleDowngradeClick }
}
