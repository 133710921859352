import { createSvgIcon } from '@mui/material'

const Resume = createSvgIcon(
    <>
        <path d="M10 6.81976V17.1798C10 17.9698 10.87 18.4498 11.54 18.0198L19.68 12.8398C20.3 12.4498 20.3 11.5498 19.68 11.1498L11.54 5.97976C10.87 5.54976 10 6.02976 10 6.81976Z" />
        <path d="M6.5 18C7.325 18 8 17.2286 8 16.2857V7.71429C8 6.77143 7.325 6 6.5 6C5.675 6 5 6.77143 5 7.71429V16.2857C5 17.2286 5.675 18 6.5 18Z" />
    </>,
    'Resume',
)

export default Resume
