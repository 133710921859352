import { Box, Stack } from '@mui/material'

import { getRgbaStyle, hexToRgb } from 'utils/colors'
import { ColorPickerProps } from 'design/atoms/Form/ColorPicker/ColorPicker'
import { useColorPickerContext } from 'design/atoms/Form/ColorPicker/ColorPicker.context'
import { PERCENTAGE_ADAPTER } from 'design/atoms/Form/ColorPicker/Inputs/Opacity'
import style from './Preview.style'

type PreviewProps = Pick<ColorPickerProps, 'opacityMode'> & {
    disabled?: boolean
}

export const Preview = ({ opacityMode, disabled = false }: PreviewProps) => {
    const { localColor, localOpacity } = useColorPickerContext()

    if (opacityMode) {
        const color = { ...hexToRgb(localColor), a: Number(localOpacity) / PERCENTAGE_ADAPTER }

        return (
            <Stack sx={style.opacityMode.container}>
                <Box
                    sx={[
                        style.color.idle,
                        style.opacityMode.common,
                        style.opacityMode.color,
                        disabled && style.color.disabled,
                        { backgroundColor: getRgbaStyle(color, false) },
                    ]}
                />
                <Box
                    sx={[
                        style.color.idle,
                        style.opacityMode.common,
                        style.opacityMode.colorWithOpacity,
                        disabled && style.color.disabled,
                        { backgroundColor: getRgbaStyle(color) },
                    ]}
                />
            </Stack>
        )
    }

    return <Box sx={[style.color.idle, disabled && style.color.disabled, { backgroundColor: localColor }]} />
}
