import {
    Box,
    MenuItem,
    MenuItemProps,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
    Stack,
    SxProps,
    Theme,
    Typography,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import { Field, FieldAttributes, FieldProps } from 'formik'
import { ReactNode, useMemo, useState } from 'react'

import { ImgWithErrorHandler } from 'design/atoms/ImgWithErrorHandler'
import { linesLimit } from 'styles/common'
import { getSx } from 'styles/theme/utils'

import { HelpTooltip } from 'design/atoms/HelpTooltip'
import UpgradeBadge from 'design/molecules/UpgradeBadge'
import useUpgradeCallback from 'design/molecules/UpgradeBadge/useUpgradeCallback'
import { SubscriptionCtaSource } from 'design/templates/Modal'
import { useCheckAccess } from 'utils'
import { FeatureGroup } from 'constants/keys/featuresAccess.constants'
import { FeatureKey } from 'constants/featuresAccess.constants'
import style from './Select.style'

export interface SelectOption {
    label: string | number | ReactNode
    value: MenuItemProps['value']
    img?: string
    disabled?: boolean
    tooltip?: ReactNode
    locked?: boolean
    featureKey?: FeatureKey
}

export interface SelectProps {
    afterChange?: (name: string, value: string) => void
    className?: string
    errorMessage?: string
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    fieldAttributes?: FieldAttributes<any>
    label?: string | ReactNode
    name: string
    featureGroup?: FeatureGroup
    options: SelectOption[]
    renderFooter?: ReactNode
    withImage?: boolean
    placeholderProps?: SxProps<Theme>
    wrapperSx?: SxProps<Theme>
    selectProps?: MuiSelectProps
    upgradeSource?: SubscriptionCtaSource
}

export const Select = ({
    name,
    options,
    errorMessage,
    fieldAttributes,
    afterChange,
    label,
    className,
    renderFooter,
    withImage = false,
    selectProps,
    wrapperSx,
    placeholderProps,
    upgradeSource,
    featureGroup,
}: SelectProps) => {
    const [open, setOpen] = useState(false)
    const checkAccess = useCheckAccess(featureGroup)
    const upgradeCallback = useUpgradeCallback(upgradeSource)

    const handleOpen: MuiSelectProps['onOpen'] = (event) => {
        if (selectProps?.disabled) return

        setOpen(true)
        selectProps?.onOpen?.(event)
    }
    const handleClose: MuiSelectProps['onClose'] = (event) => {
        setOpen(false)
        selectProps?.onClose?.(event)
    }

    const optionsWithLocked = useMemo(
        () =>
            options.map((option) => ({
                ...option,
                locked: option.locked || checkAccess(option.featureKey),
            })),
        [options, checkAccess],
    )

    const renderOptions = useMemo(
        () =>
            optionsWithLocked.map(({ label, value, img, disabled, tooltip, locked, featureKey }, index) => (
                <MenuItem
                    key={`${value}-${index}`}
                    value={value}
                    disabled={disabled && !locked}
                    onMouseDown={(event) => {
                        if (!locked) return

                        event.preventDefault()
                        upgradeCallback({ featureGroup, featureKey })
                        handleClose(event)
                    }}
                >
                    {withImage ? (
                        <Stack flexDirection="row" gap={2} width={1}>
                            {img && <ImgWithErrorHandler src={img} alt={label as string} sx={style.img.main} />}
                            <Typography sx={{ whiteSpace: 'normal', ...linesLimit(1), ml: !img ? '50px' : 0 }}>
                                {label}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={1}>
                            <Typography sx={[style.textLabel, Boolean(locked) && style.textLabelLocked]} noWrap>
                                {label}
                                {tooltip && <HelpTooltip title={tooltip} />}
                            </Typography>
                            {locked && <UpgradeBadge size="xSmall" preventDefault />}
                        </Stack>
                    )}
                </MenuItem>
            )),
        [options],
    )

    const labelId = useMemo(() => `select-label__${label}`, [label])

    return (
        <Box className={className} sx={getSx(selectProps?.fullWidth ? { width: 1 } : {}, wrapperSx)}>
            <Field name={name} {...fieldAttributes}>
                {({ field, form: { setFieldValue } }: FieldProps) => (
                    <Stack sx={{ position: 'relative' }}>
                        {label && (
                            <InputLabel htmlFor={labelId} onClick={handleOpen}>
                                {label}
                            </InputLabel>
                        )}
                        {withImage && (
                            <Typography
                                onClick={handleOpen}
                                sx={[
                                    style.img.placeholder,
                                    Boolean(selectProps?.disabled) && style.img.placeholderDisabled,
                                    ...getSx(placeholderProps ?? {}),
                                ]}
                            />
                        )}
                        <FormControl disabled={selectProps?.disabled} error={selectProps?.error || !!errorMessage}>
                            <MuiSelect
                                {...field}
                                {...selectProps}
                                open={selectProps?.open ?? open}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                labelId={labelId}
                                inputProps={{ ...selectProps?.inputProps, id: labelId }}
                                onChange={(e) => {
                                    void setFieldValue(name, e.target.value)

                                    if (afterChange) {
                                        afterChange(name, e.target.value as string)
                                    }
                                }}
                            >
                                {renderOptions}
                                {renderFooter}
                            </MuiSelect>
                            {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
                        </FormControl>
                    </Stack>
                )}
            </Field>
        </Box>
    )
}
