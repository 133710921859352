import { SxProps } from '@mui/material'

const optionLabel: SxProps = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
}

const optionWrapper: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 1,
    width: 1,
}

export default {
    optionLabel,
    optionWrapper,
}
