import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { MetricKeys, MetricIntervals, FilterKeys, InitialStateType } from './types'

const initialState: InitialStateType = {
    metricsMenu: {
        [MetricKeys.plays]: true,
        [MetricKeys.playRate]: false,
        [MetricKeys.impressions]: false,
        [MetricKeys.playsUnique]: false,
        [MetricKeys.unmuteRate]: false,
        [MetricKeys.engagement]: false,
        [MetricKeys.conversionRate]: false,
        [MetricKeys.revenueAverage]: false,
        [MetricKeys.revenue]: false,
        [MetricKeys.revenuePerViewer]: false,
        [MetricKeys.bounceRate]: false,
        [MetricKeys.conversionCount]: false,
        [MetricKeys.pgOptInRate]: false,
    },
    intervalMenu: {
        [MetricIntervals.hour]: false,
        [MetricIntervals.day]: true,
        [MetricIntervals.week]: false,
        [MetricIntervals.month]: false,
    },
    filters: {
        [FilterKeys.conversions]: [],
        [FilterKeys.newVsReturning]: [],
        [FilterKeys.devices]: [],
        [FilterKeys.browsers]: [],
        [FilterKeys.geo]: [],
        [FilterKeys.folders]: [],
        [FilterKeys.urlTracking]: [],
        [FilterKeys.affiliate]: [],
    },
    urlSelectedType: '',
    selectedRows: [],
}

export const analyticsActionsSlice = createSlice({
    name: 'analyticsActions',
    initialState,
    reducers: {
        setMetricMenuValue: (state, action: PayloadAction<string>) => {
            const selectedKey = action.payload

            Object.keys(state.metricsMenu).forEach((key) => {
                state.metricsMenu[key as MetricKeys] = key === selectedKey
            })
        },
        setIntervalMenuValue: (state, action: PayloadAction<string>) => {
            const selectedKey = action.payload

            Object.keys(state.intervalMenu).forEach((key) => {
                state.intervalMenu[key as MetricIntervals] = key === selectedKey
            })
        },
        setFiltersValue: (state, action: PayloadAction<Record<FilterKeys, string[]>>) => {
            state.filters = action.payload
        },
        setSelectedPrepopulate: (state, action) => {
            state.selectedRows = action.payload
        },
        setURLTypeValue: (state, action: PayloadAction<string>) => {
            state.urlSelectedType = action.payload
        },
    },
})

export const { setMetricMenuValue, setIntervalMenuValue, setFiltersValue, setSelectedPrepopulate, setURLTypeValue } =
    analyticsActionsSlice.actions

export const selectMetricsMenuSwitchesState = (state: RootState) => state.analyticsActions.metricsMenu
export const selectIntervalMenuSwitchesState = (state: RootState) => state.analyticsActions.intervalMenu
export const selectFiltersState = (state: RootState) => state.analyticsActions.filters
export const selectPrepopulateState = (state: RootState) => state.analyticsActions.selectedRows
export const selectURLTypeState = (state: RootState) => state.analyticsActions.urlSelectedType

export const analyticsActions = analyticsActionsSlice.reducer
