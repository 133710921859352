export default {
    errors: {
        accessDenied: 'Your access to this page is forbidden.',
        emptyTitle: 'Please, enter a title',
        file: {
            chooseImage: 'Choose an image',
            sizeMax: (size: string) => `The maximum allowed file size is ${size}`,
            typeAllowed: (whitelist: string[]) => `Allowed file types are: ${whitelist.join(', ')}`,
        },
        generic: 'Something went wrong.',
    },
    callToAction: {
        refresh: 'Please, refresh the page.',
        contactUs: {
            neutral: 'Contact us',
            forHelp: 'Please, contact us for help',
        },
    },
    info: {
        copied: 'Copied to clipboard!',
        loading: 'Loading',
        loadingCompleted: 'Loading completed',
        saving: 'Saving...',
        saved: 'Saved',
    },
    entity: {
        accept: {
            inProgress: (entity: string) => `Accepting ${entity}`,
            failed: (entity: string) => `Failed to accept ${entity}`,
            completed: (entity: string) => `${entity} accepted`,
        },
        activation: {
            inProgress: (entity: string) => `Activating ${entity}`,
            failed: (entity: string) => `Failed to activate ${entity}`,
            completed: (entity: string) => `${entity} activated`,
        },
        cancel: {
            inProgress: (entity: string) => `Cancelling ${entity}`,
            failed: (entity: string) => `Failed to cancel ${entity}`,
            completed: (entity: string) => `${entity} cancelled`,
        },
        change: {
            inProgress: (entity: string) => `Changing ${entity}`,
            failed: (entity: string) => `Failed to change ${entity}`,
            completed: (entity: string) => `${entity} changed`,
        },
        confirm: {
            inProgress: (entity: string) => `Confirming ${entity}`,
            failed: (entity: string) => `Failed to confirm ${entity}`,
            completed: (entity: string) => `${entity} confirmed`,
        },
        copying: {
            inProgress: (entity: string) => `Copying ${entity}`,
            failed: (entity: string) => `Failed to copy ${entity}`,
            completed: (entity: string) => `${entity} copied`,
        },
        create: {
            inProgress: (entity: string) => `Creating ${entity}`,
            failed: (entity: string) => `Failed to create ${entity}`,
            completed: (entity: string) => `${entity} created`,
        },
        delete: {
            inProgress: (entity: string) => `Deleting ${entity}`,
            failed: (entity: string) => `Failed to delete ${entity}`,
            completed: (entity: string) => `${entity} deleted`,
        },
        discard: {
            inProgress: (entity: string) => `Discarding ${entity}`,
            failed: (entity: string) => `Failed to discard ${entity}`,
            completed: (entity: string) => `${entity} discarded`,
        },
        download: {
            inProgress: (entity: string) => `Downloading ${entity}`,
            failed: (entity: string) => `Failed to download ${entity}`,
            completed: (entity: string) => `${entity} downloaded`,
        },
        load: {
            inProgress: (entity: string) => `Loading ${entity}`,
            failed: (entity: string) => `Failed to load ${entity}`,
            completed: (entity: string) => `${entity} loaded`,
        },
        publish: {
            inProgress: (entity: string) => `Publishing ${entity}`,
            failed: (entity: string) => `Failed to publish ${entity}`,
            completed: (entity: string) => `${entity} published`,
        },
        remove: {
            inProgress: (entity: string) => `Removing ${entity}`,
            failed: (entity: string) => `Failed to remove ${entity}`,
            completed: (entity: string) => `${entity} removed`,
        },
        rename: {
            inProgress: (entity: string) => `Renaming ${entity}`,
            failed: (entity: string) => `Failed to rename ${entity}`,
            completed: (entity: string) => `${entity} renamed`,
        },
        replace: {
            inProgress: (entity: string) => `Replacing ${entity}`,
            failed: (entity: string) => `Failed to replace ${entity}`,
            completed: (entity: string) => `${entity} replaced`,
        },
        republish: {
            inProgress: (entity: string) => `Republishing ${entity}`,
            failed: (entity: string) => `Failed to republish ${entity}`,
            completed: (entity: string) => `${entity} republished`,
        },
        resend: {
            inProgress: (entity: string) => `Resending ${entity}`,
            failed: (entity: string) => `Failed to resend ${entity}`,
            completed: (entity: string) => `${entity} resent`,
        },
        reset: {
            inProgress: (entity: string) => `Resetting ${entity}`,
            failed: (entity: string) => `Failed to reset ${entity}`,
            completed: (entity: string) => `${entity} reset`,
        },
        revoke: {
            inProgress: (entity: string) => `Revoking ${entity}`,
            failed: (entity: string) => `Failed to revoke ${entity}`,
            completed: (entity: string) => `${entity} revoked`,
        },
        save: {
            inProgress: (entity: string) => `Saving ${entity}`,
            failed: (entity: string) => `Failed to save ${entity}`,
            completed: (entity: string) => `${entity} saved`,
        },
        send: {
            inProgress: (entity: string) => `Sending ${entity}`,
            failed: (entity: string) => `Failed to send ${entity}`,
            completed: (entity: string) => `${entity} sent`,
        },
        update: {
            inProgress: (entity: string) => `Updating ${entity}`,
            failed: (entity: string) => `Failed to update ${entity}`,
            completed: (entity: string) => `${entity} updated`,
        },
        withdraw: {
            inProgress: (entity: string) => `Withdrawing ${entity}`,
            failed: (entity: string) => `Failed to withdraw ${entity}`,
            completed: (entity: string) => `${entity} withdrawn`,
        },
        transfer: {
            failed: (entity: string) => `Failed to transfer ${entity}`,
            completed: (entity: string) => `${entity} Transfer started`,
        },
        tokens: {
            failed: (entity: string) => `Failed to save ${entity}`,
            completed: (entity: string) => `${entity} saved successfully`,
        },
    },
}
